import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const certificateHealth = {
  name: "Atestado Sanitário",
  title: "Atestado Sanitário",
  gender: "male",
  fields: {
    details: {
      label: "Resenha detalhada",
      type: "textarea",
      required: true,
      defaultPreview: _.range(50).fill("_").join(""),
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
    note: {
      label: "Observações",
      type: "textarea",
      props: {
        rows: 2,
        style: { minWidth: "100%" },
      },
    },
  },
  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },
  content: `
    <p>Atesto para os devidos fins que foi por mim examinado nesta data o animal identificado neste documento, o qual apresentou bom estado geral de saúde durante o exame clínico, e que se encontram atendidas as medidas sanitárias definidas pelo(s) Serviço(s) Médico-Veterinário(s) Oficial(is), quando aplicável:</p>
    <hr />
    <br />
    <p><strong>Resenha detalhada:</strong></p>
    <p>{{{details}}}</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}


    {{#note}}
      <br />
      <br />
      <p><strong>Observações:</strong></p>
      <p>{{{.}}}</p>
    {{/note}}
  `.trim(),
};
