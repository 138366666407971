import styled from 'styled-components'
import { BoxColumn } from '../styles'

export const Container = styled(BoxColumn)``

export const Ratings = styled.div`
  display: flex;
  gap: 12px;
  flex: 1;
  width: 100%;
  margin: 40px 12px 12px 12px;
  justify-content: flex-start;
  align-items: flex-end;
`
export const Rating = styled.div`
  height: 100%;
  flex: 1;
  width: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  color: #ffffff;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  p {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: inherit;
    text-align: center;
  }
  em {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: inherit;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 58px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    transition: all 250ms cubic-bezier(0.6, -0.71, 0.44, 1.9);
  }
  &.detractor {
    background: #f26581;
    &::before {
      content: '😡';
    }
  }
  &.passive {
    background: #faa924;
    &::before {
      content: '😐';
    }
  }
  &.promoter {
    background: #04ce7a;
    &::before {
      content: '😍';
    }
  }
  &:hover {
    &::before {
      top: -8px;
      text-shadow: 0px 10px 13px rgba(0, 0, 0, 0.4);
      transform: translate(-50%, -50%) scale(1.05);
    }
  }
`

export const Note = styled.div`
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  width: 30%;
  height: 100%;
  gap: 10px;
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
    margin: 0;
  }
  span {
    color: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
  }
  em {
    color: #666666;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
  }
`
