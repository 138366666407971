import React, { useContext } from 'react'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { PrescriptionContext } from '../../../providers/prescription'

import { ReactComponent as DeleteIcon } from './image/delete.svg'
import { ReactComponent as EditIcon } from './image/edit.svg'

import {
  Button,
  Card,
  Description,
  IconCalendar,
  RowCard,
  Title,
  DivButton,
} from './styles'

function SummaryPrescription({ summary, appointmentId }) {
  const { deletePrescriptions, setNewPrescription, setPrescriptionId } =
    useContext(PrescriptionContext)



  return (
    <>
      <Title>Resumo</Title>
      {summary?.map((item) => (
        <Card key={item.prescriptionId}>
          <RowCard>
            <IconCalendar>
              <div
                style={{ display: 'flex', alignItems: 'center', width: '80%' }}
              >
                <TextSnippetIcon /> Receita
              </div>
              <div
                style={{ width: '20%', display: 'flex', justifyContent: 'end' }}
              >
                <EditIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPrescriptionId(item.prescriptionId)
                    setNewPrescription(3)
                  }}
                />
                <DeleteIcon
                  style={{ marginRight: '-3px', cursor: 'pointer' }}
                  onClick={() =>
                    deletePrescriptions(appointmentId, item.prescriptionId)
                  }
                />
              </div>
            </IconCalendar>
          </RowCard>
          <div
            style={{
              overflow: 'auto',
              height: '80px',
              padding: '3px',
              marginTop: '5px',
            }}
          >
            {item.medicines?.map((medicine, index) => (
              <RowCard key={medicine._id}>
                <Description index={index}>{medicine?.medicineName},</Description>
              </RowCard>
            ))}
          </div>

          <RowCard style={{ display: 'flex', justifyContent: 'center' }}>
            <DivButton>
              <Button color="#00567E" onClick={() => window.open(item.pdfFile)}>
                Visualizar PDF
              </Button>
              <Button
                color="#25D366"
                onClick={() =>
                  window.open(
                    `https://wa.me/55${item.customerCellphone}?text=Segue o Receituário da consulta  ${item.fileUrl}`,
                    '_blank',
                  )
                }
              >
                WhatsApp
              </Button>
            </DivButton>
          </RowCard>
        </Card>
      ))}
    </>
  )
}

export default SummaryPrescription
