import { AccordionProvider } from "./Context";
import { Container } from "./styles";
import { useAccordion } from "./useAccordion";

export function AccordionRoot({ children, name, options, ...rest }) {
  name = `accordion-${name}`;

  const setAccordion = useAccordion((st) => st.actions.setAccordion);
  const setOpen = useAccordion((st) => st.actions.setOpen);

  setAccordion(name, options);

  const accordion = useAccordion((st) => st.data.accordions[name]);

  return (
    <AccordionProvider accordionName={name}>
      <Container
        {...rest}
        type="multiple"
        defaultValue={accordion.defaultOpen}
        onValueChange={(items) => {
          setOpen(name, items);
        }}
        value={accordion?.open || []}
      >
        {children}
      </Container>
    </AccordionProvider>
  );
}
