import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0px;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: calc(33.33% - 10px);

  & input[type='radio'] {
    position: relative;
    padding-left: 25px;
    margin-right: 10px;
    cursor: pointer;
  }

  & input[type='radio']::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -3px;
    top: -3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #ccd219;
    background-color: #fff;
  }

  & input[type='radio']:checked::before {
    background-color: #ccd219;
  }
`
