import { Value, Portal } from "@radix-ui/react-select";

import { ChevronDown, ChevronUp } from "lucide-react";

import { useState } from "react";
import {
  Container,
  Fieldset,
  Trigger,
  Content,
  TriggerIcon,
  Viewport,
  Legend,
  Space,
  ToUp,
  ToDown,
} from "./styles";

export function SelectRoot({
  children,
  label,
  value,
  style,
  index = 30,
  defaultOpen = false,
  onChange = () => null,
  ...rest
}) {
  const [open, setOpen] = useState(defaultOpen);
  value = !value ? null : value;

  return (
    <Container
      {...rest}
      value={value}
      onValueChange={(e) => {
        onChange(e);
      }}
      open={open}
      onOpenChange={setOpen}
    >
      <Fieldset {...rest} style={style} className={open ? "open" : ""}>
        {label && <Space data-selected={value}>{label}</Space>}
        {label && (
          <Legend
            data-selected={value}
            className={open && value ? "active" : ""}
          >
            {label}
          </Legend>
        )}
        <Trigger>
          <Value placeholder={<Legend className="hidden">{label}</Legend>} />
          <TriggerIcon className={open ? "open" : ""}>
            <ChevronDown size={20} />
          </TriggerIcon>
        </Trigger>
      </Fieldset>

      <Portal style={{ zIndex: index }}>
        <Content position="popper" alignOffset={-1}>
          <ToUp>
            <ChevronUp />
          </ToUp>
          <Viewport>{children}</Viewport>
          <ToDown>
            <ChevronDown />
          </ToDown>
        </Content>
      </Portal>
    </Container>
  );
}
