import { create } from "zustand";
import { produce } from "immer";
import uuid from "react-uuid";
import slugify from "slugify";

export const useTabs = create((set) => ({
  data: {
    tabs: {},
  },
  actions: {
    setTabs: (name, tabs) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tabs[name]) {
            const newTabList = [];

            tabs.forEach((tab, i) => {
              newTabList.push({
                id: `${name}-${uuid()}`,
                title: tab,
                slug: slugify(tab, { lower: true }),
                active: !i,
              });
            });
            draft.data.tabs[name] = newTabList;
          }
        })
      ),
    setActive: (name, updateTab) =>
      set((state) =>
        produce(state, (draft) => {
          const allTabs = state.data.tabs;
          if (!allTabs[name]) return null;

          if (typeof updateTab === "string") {
            draft.data.tabs[name] = state.data.tabs[name].map((tab) => {
              if (tab.slug === updateTab) {
                return { ...tab, active: true };
              }
              return { ...tab, active: false };
            });
            return;
          }

          draft.data.tabs[name] = state.data.tabs[name].map((tab, i) => {
            if (i === updateTab) {
              return { ...tab, active: true };
            }
            return { ...tab, active: false };
          });
        })
      ),
  },
}));
