function formatRules(plans) {
  return plans.map((plan) => ({
    type: plan.name,
    factor: plan.factor || 1,
  }))
}

export const formatBody = {
  rules: (plans) => formatRules(plans),
}
