import { WhatsApp, CallRounded, Block } from '@material-ui/icons'

import { Container } from './styles'

export function ActionIcon({ button }) {
  switch (button) {
    case 'whatsapp':
      return <WhatsApp />
    case 'other-channels':
      return <CallRounded />
    case 'block-contact':
      return <Block />

    default:
      return <p>erro</p>
  }
}

export function ActionIconStyled({ button }) {
  return (
    <Container className={button}>
      <ActionIcon button={button} />
    </Container>
  )
}
