import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styles from './DatePicker.module.css'

function MonthView({ dateActive, getSelectedDay, color }) {
  const [selectedDate, setSelectedDate] = useState(null)
  const themColor = color || 'rgb(204, 210, 25)'
  const selectedStyle = {
    borderRadius: '0.7rem',
    color: themColor,
    fontWeight: 'bold',
  }

  const getStyles = (day) => (day === selectedDate ? selectedStyle : null)

  const getId = (day) => (day === selectedDate ? 'selectedMonth' : '')
  const onDateClick = (day) => {
    setSelectedDate(day)
    if (getSelectedDay) {
      getSelectedDay(
        new Date(dateActive.getFullYear(), day, dateActive.getDate()),
      )
    }
  }

  const renderDays = () => {
    const months = [
      { name: 'Janeiro', value: 0 },
      { name: 'Fevereiro', value: 1 },
      { name: 'Março', value: 2 },
      { name: 'Abril', value: 3 },
      { name: 'Maio', value: 4 },
      { name: 'Junho', value: 5 },
      { name: 'Julho', value: 6 },
      { name: 'Agosto', value: 7 },
      { name: 'Setembro', value: 8 },
      { name: 'Outubro', value: 9 },
      { name: 'Novembro', value: 10 },
      { name: 'Dezembro', value: 11 },
      { name: 'Janeiro', value: 0 },
    ]
    const monthss = []

    for (let j = 0; j < 12; j += 1) {
      monthss.push(
        <div
          id={`${getId(months[j]?.value)}`}
          className={styles.monContainer}
          key={months[j]?.value}
          style={getStyles(months[j]?.value)}
          onClick={() => onDateClick(months[j]?.value)}
        >
          <span className={styles.monthText}>{months[j]?.name}</span>
        </div>,
      )
    }

    return (
      <div id="container" className={styles.dateListScrollable}>
        {monthss}
      </div>
    )
  }

  useEffect(() => {
    if (dateActive) setSelectedDate(parseInt(moment(dateActive).format('M') - 1, 10))
  }, [dateActive])

  useEffect(() => {
    const startScrollIntoView = (view) => {
      view.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'nearest',
      })
    };

    const view = document.getElementById('selectedMonth')
    if (view) startScrollIntoView(view)
  }, [])




  return <>{renderDays()}</>
}

export { MonthView }
