import { VETERINARY_VIEW_ATTENDING } from "../../../../store/service/veterinary/constants";

import { usePet } from "../../../../store/global/usePet";
import { useCare } from "../../../../store/service/veterinary/useCare";

import { PetCard } from "../../../../components/ui/PetCard";
import { getVeterinaryCareStatusPlaceholder } from "../../../../services/functions";

import { ServicesNotSaved } from "../components/alerts/ServicesNotSaved";
import { SendBudget } from "../components/alerts/SendBudget";

import { Actions } from "./Actions";

import { StatusPlaceholder, ActionsBox } from "./styles";

function Header() {

  const careStatus = useCare(st => st.data.care.status)
  const careNote = useCare(st => st.data.care.note)

  const pet = usePet(st => st.data.pet)

  const isAttending = VETERINARY_VIEW_ATTENDING.includes(careStatus)
  const careStatusPlaceholder = getVeterinaryCareStatusPlaceholder(careStatus)

  const showMessage = careStatus !== 'waitingStart' && careStatus !== 'checkIn' && careStatus !== 'finished'

  if (!pet.id) return null

  return (
    <div
      style={{
        display: "flex",
        gap: "14px",
        flexWrap: "wrap",
      }}
    >
      <PetCard.Root
        noAction
        size="small"
        direction="column"
        style={{
          maxWidth: "fit-content",
          minWidth: "initial",
        }}
      >
        <PetCard.Attributes full attributes={pet.attributes} show={["Nome"]} />
        <PetCard.Avatar url={pet.photo} attributes={pet.attributes} />
      </PetCard.Root>
      <PetCard.Root
        data-full
        noAction
        direction="row-reverse"
        size="small"
        title="Informações"
        style={{
          minWidth: "400px",
        }}
      >
        <PetCard.Badges show={pet.badges} direction="column" />
        <PetCard.Attributes
          attributes={pet.attributes}
          show={[
            "Raça",
            "PetPeso",
            "Castrado",
            "Sexo",
            "Nascimento",
            "Idade",
            "Pelagem",
          ]}
        />
      </PetCard.Root>
      <PetCard.Root data-full noAction size="small" title="Observação">
        <PetCard.Note title>{careNote || pet.note}</PetCard.Note>
      </PetCard.Root>
      <PetCard.Root data-full noAction size="small" title="Tutor">
        <PetCard.Attributes
          direction="column"
          attributes={pet.attributes}
          show={["Tutor", "TutorCpf", "TutorPhone"]}
        />
      </PetCard.Root>
      <PetCard.Root
        fit
        noAction
        data-full
        center
        direction="column"
        size="small"
        style={{ maxWidth: "300px" }}
      >
        <ActionsBox>
          {showMessage && <StatusPlaceholder>{careStatusPlaceholder}</StatusPlaceholder>}
          {!!isAttending && <Actions />}
        </ActionsBox>
      </PetCard.Root>

      <SendBudget />
      <ServicesNotSaved />
    </div>
  );
}

export default Header;
