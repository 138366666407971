import _ from "lodash";
import { Save, Undo2 } from "lucide-react";

import { Input } from "../../../../../../components/ui/Form/Input";
import { Action } from "../../../../../../components/ui/Action";

import { VeterinaryPrescriptionController } from "../../../../../../services/controllers/VeterinaryCare";
import { usePrescription } from "../../../../../../store/service/veterinary/usePrescription";

import { MedicineForm } from "./MedicineForm";
import { FindMedicine } from "./FindMedicine";
import { MedicinePresentation } from "./MedicinePresentation";

import { Container, FormActions, FormGroup } from "./styles";

export function PrescriptionForm() {
  const isUpdated = usePrescription((st) => st.data.prescription.updated);
  const medicines = usePrescription((st) => st.data.prescription.medicines)


  const isLoading = usePrescription((st) => st.data.isLoading);
  const name = usePrescription((st) => st.data.prescription.name);

  const hasMedicines = _.isEmpty(medicines)

  const CLVetPrescription = VeterinaryPrescriptionController();

  return (
    <Container>
      <Input.Root
        label="Nome"
        value={name}
        onChange={CLVetPrescription.setName}
      />
      <FormGroup style={{ zIndex: "5" }}>
        <FindMedicine />
        <MedicinePresentation />
      </FormGroup>
      <MedicineForm />
      <FormActions>
        <Action.Root
          outline
          status="danger"
          type="button"
          onClick={() => CLVetPrescription.closeDrawer()}
        >
          <Action.Icon icon={Undo2} />
          Cancelar
        </Action.Root>
        <Action.Root
          status="success"
          type="button"
          disabled={isUpdated || hasMedicines}
          isLoading={isLoading}
          onClick={CLVetPrescription.savePrescription}
        >
          <Action.Icon isLoading={isLoading} icon={Save} />
          Salvar Receita
        </Action.Root>
      </FormActions>
    </Container>
  );
}
