
import { useContext } from '../Context';
import { useBlockTable } from '../useBlockTable';
import { Header } from './Header';

import { Lines } from './Lines';

export function BlockTableBlocks() {
  const { tableName } = useContext()
  const blocks = useBlockTable(st => st.data.tables[tableName].blocks)

  if (!blocks) return null

  return blocks.map((block) => (
    <div key={block.id} className="border rounded-md overflow-hidden flex flex-col w-full min-w-fit shadow-md">
      <Header {...block} />
      <Lines {...block} />
    </div>
  ))
}
