import { toast } from "react-toastify";

import api from "../../api";
import { formatResponses } from "../../formatResponses/veterinaryCare";

export default class VeterinaryAttachmentModel {
  constructor(orderId) {
    this._orderId = orderId;
  }

  findAttachments = async () => {
    try {
      const { data } = await api.get(`service-order/appointment/${this._orderId}/attachments`);

      const formattedAttachments = formatResponses.attachments(
        data.attachments
      );

      return formattedAttachments;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  deleteAttachment = async (attachmentId) => {
    try {
      const { data } = await api.delete(`service-order/appointment/${this._orderId}/attachment/${attachmentId}`);

      const formattedAttachments = formatResponses.attachments(data.attachments);

      return formattedAttachments;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  sendAttachment = async (formData) => {
    try {
      const { data } = await api.post(
        `service-order/appointment/${this._orderId}/attachment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const formattedAttachments = formatResponses.attachments(
        data.attachments
      );

      return formattedAttachments;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
