import { toast } from "react-toastify";

import API from "../../api";
import { format } from "../../formatResponses/subscriptions";


export default class SubscriptionCalculatorModel {
  calculate = async (subscribers) => {
    try {
      const { data } = await API.post(`/dashboard/dre-assinatura/calculadora`,{
        "assinantes":subscribers
      });
      const formattedResults = format.resultCalculation(data.resultado);

      return formattedResults
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  };
}
