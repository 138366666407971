import { messageSendAttachment } from "./draMei/sendAttachment";
import { messageSendRequest } from "./draMei/sendRequest";
import { messageSendPrescription } from "./draMei/sendPrescription";
import { messageSendProgrammedVaccination } from "./draMei/sendProgrammedVaccination";

import { messageSendCheckInSchedulingSummary } from "./draMei/sendCheckInSchedulingSummary";
import { messageSendScheduledSchedulingSummary } from "./draMei/sendScheduledSchedulingSummary";
import { messageSendCheckoutSchedulingSummary } from "./draMei/sendCheckoutSchedulingSummary";
import { messageSendFinishedSchedulingSummary } from "./draMei/sendFinishedSchedulingSummary";
import { messageSendWaitingStartSchedulingSummary } from "./draMei/sendWaitingStartSchedulingSummary";



export const wppTemplates = {
  draMei: {
    sendAttachment: messageSendAttachment,
    sendRequest: messageSendRequest,
    sendPrescription: messageSendPrescription,
    sendProgrammedVaccination: messageSendProgrammedVaccination,
    sendScheduledSchedulingSummary: messageSendScheduledSchedulingSummary,
    sendWaitingStartSchedulingSummary: messageSendWaitingStartSchedulingSummary,
    sendCheckInSchedulingSummary: messageSendCheckInSchedulingSummary,
    sendFinishedSchedulingSummary: messageSendFinishedSchedulingSummary,
    sendCheckoutSchedulingSummary: messageSendCheckoutSchedulingSummary,
  },
};
