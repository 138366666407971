import VeterinaryCareClass from "./VeterinaryCare";
import VeterinaryRecordClass from "./VeterinaryRecord";
import VeterinaryProtocolClass from "./VeterinaryProtocol";
import VeterinaryVaccineClass from "./VeterinaryVaccine";
import VeterinaryServiceClass from "./VeterinaryService";
import VeterinaryAttachmentClass from "./VeterinaryAttachment";
import VeterinaryPrescriptionClass from "./VeterinaryPrescription";
import VeterinaryRequestClass from "./VeterinaryRequest";
import VeterinaryHistoryClass from "./VeterinaryHistory";

let MVetCare = null;
let MVetRecord = null;
let MVetProtocol = null;
let MVetVaccine = null;
let MVetService = null;
let MVetAttachment = null;
let MVetPrescription = null;
let MVetRequest = null;
let MVetHistory = null;

export function VeterinaryCareModel(orderId) {
  if (!MVetCare && !orderId) {
    throw new Error("Models - VeterinaryCareModel: orderId não informado!");
  }

  if (MVetCare && !orderId) {
    return MVetCare;
  }
  if (MVetCare && MVetCare._orderId === orderId) {
    return MVetCare;
  }

  MVetCare = new VeterinaryCareClass(orderId);
  return MVetCare;

}

export function VeterinaryRecordModel(orderId) {
  if (!MVetRecord && !orderId) {
    throw new Error("Models - VeterinaryRecordModel: orderId não informado!");
  }

  if (MVetRecord && !orderId) {
    return MVetRecord;
  }
  if (MVetRecord && MVetRecord._orderId === orderId) {
    return MVetRecord;
  }

  MVetRecord = new VeterinaryRecordClass(orderId);
  return MVetRecord;
}

export function VeterinaryProtocolModel() {
  if (MVetProtocol) {
    return MVetProtocol;
  }

  MVetProtocol = new VeterinaryProtocolClass();
  return MVetProtocol;
}

export function VeterinaryVaccineModel(orderId) {

  if (!MVetVaccine && !orderId) {
    throw new Error("Models - VeterinaryVaccineModel: orderId não informado!");
  }

  if (MVetVaccine && !orderId) {
    return MVetVaccine;
  }
  if (MVetVaccine && MVetVaccine._orderId === orderId) {
    return MVetVaccine;
  }

  MVetVaccine = new VeterinaryVaccineClass(orderId);
  return MVetVaccine;

}

export function VeterinaryServiceModel(orderId) {
  if (!MVetService && !orderId) {
    throw new Error("Models - VeterinaryServiceModel: orderId não informado!");
  }

  if (MVetService && !orderId) {
    return MVetService;
  }
  if (MVetService && MVetService._orderId === orderId) {
    return MVetService;
  }

  MVetService = new VeterinaryServiceClass(orderId);
  return MVetService;
}

export function VeterinaryAttachmentModel(orderId) {

  if (!MVetAttachment && !orderId) {
    throw new Error("Models - VeterinaryAttachmentModel: orderId não informado!");
  }

  if (MVetAttachment && !orderId) {
    return MVetAttachment;
  }
  if (MVetAttachment && MVetAttachment._orderId === orderId) {
    return MVetAttachment;
  }

  MVetAttachment = new VeterinaryAttachmentClass(orderId);
  return MVetAttachment;
}

export function VeterinaryPrescriptionModel(orderId) {
  if (!MVetPrescription && !orderId) {
    throw new Error("Models - VeterinaryPrescriptionModel: orderId não informado!");
  }

  if (MVetPrescription && !orderId) {
    return MVetPrescription;
  }
  if (MVetPrescription && MVetPrescription._orderId === orderId) {
    return MVetPrescription;
  }

  MVetPrescription = new VeterinaryPrescriptionClass(orderId);
  return MVetPrescription;
}

export function VeterinaryRequestModel(orderId) {
  if (!MVetRequest && !orderId) {
    throw new Error("Models - VeterinaryRequestModel: orderId não informado!");
  }

  if (MVetRequest && !orderId) {
    return MVetRequest;
  }
  if (MVetRequest && MVetRequest._orderId === orderId) {
    return MVetRequest;
  }

  MVetRequest = new VeterinaryRequestClass(orderId);
  return MVetRequest;
}

export function VeterinaryHistoryModel() {
  if (MVetHistory) {
    return MVetHistory;
  }

  MVetHistory = new VeterinaryHistoryClass();
  return MVetHistory;
}
