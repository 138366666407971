import { InputType } from "./InputType";
import { InputLabel } from "./inputLabel";

import { Container, InputBox } from "./styles";

function createFocusOut() {
  if (!document.getElementById("focusOut")) {
    const focusOutInput = document.createElement("input");
    focusOutInput.type = "radio";
    focusOutInput.id = "focusOut";

    focusOutInput.style.position = "absolute";
    focusOutInput.style.left = "-100%";
    focusOutInput.style.top = "-100%";

    document.body.appendChild(focusOutInput);
  }
}

export function InputRoot({ label, style, ...rest }) {
  let unusualElement = null;

  if (rest.mask) {
    unusualElement = InputType.mask;
  }
  if (rest.pickDate) {
    unusualElement = InputType.date;
  }
  if (rest.money) {
    unusualElement = InputType.money;
  }
  if (rest.suggestions) {
    unusualElement = InputType.suggestion;
  }
  if (rest.number) {
    unusualElement = InputType.number;
  }

  createFocusOut()

  const InputElement = unusualElement || InputType.text;

  return (
    <Container
      aria-disabled={rest.disabled}
      style={style}
      date={!!rest.pickDate}
      className="input"
    >
      <InputBox date={!!rest.pickDate} suggestions={!!rest.suggestions?.length || !!rest.custom}>
        <InputElement {...rest} />
        {label && <InputLabel>{label}</InputLabel>}
      </InputBox>
    </Container>
  );
}
