import { ItemText } from '@radix-ui/react-select'
import { Option as Container } from './styles'

export function SelectOption({ children, ...rest }) {
  return (
    <Container {...rest}>
      <ItemText>{children}</ItemText>
    </Container>
  )
}
