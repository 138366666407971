import React, { useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

// Material ui - core
import { MenuItem, Menu } from '@material-ui/core'
import { Modal, Box } from '@mui/material'
import Skeleton from '@material-ui/lab/Skeleton'
import moment from 'moment'
import BasePage from './basePage'
import { getChecklist } from '../services/checklist'

// Material ui - lab

// Componente
import Main from '../components/organisms/MainCheckinClinica'
import AgendaModal from '../components/molecules/AgendaModal'

import API from '../services/api'
import 'moment/locale/pt-br'
import { PrescriptionProvider } from '../providers/prescription'

moment.locale('pt-br')

const ITEM_HEIGHT = 48
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
  avatar: {
    backgroundColor: '#00567e',
    fontSize: '50px',
    height: '90px',
    width: '90px',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: '#ccc',
    marginBottom: '-10px',
  },
  pet: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  total: {
    textAlign: 'center',
    fontWeight: '400',
    display: 'block',
    fontSize: '18px',
    marginBottom: '-15px',
  },
  button: {
    backgroundColor: '#CCD219',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#CCD219',
    },
  },
  buttonEnd: {
    border: '1px solid #00567e',
    backgroundColor: '#fff',
    color: '#00567e',
    '&:hover': {
      backgroundColor: '#0097a9',
      border: '1px solid #0097a9',
      color: '#fff',
    },
  },
  price: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    textAlign: 'center',
    width: '100%',
    fontWeight: '700',
    display: 'block',
    padding: '10px',
    fontSize: '36px',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
  },
  customerDetails: {
    fontSize: '14px',
    color: '#878c93',
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#00567e',
    '&:hover': {
      backgroundColor: '#0097a9',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

function Resume({ order, serviceOn, setServiceOn, setCurrentTab }) {
  const { orderId } = useParams()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [agendaModal, setAgendaModal] = React.useState(null)
  const [serviceModal, setServiceModal] = React.useState(false)
  const [disabledButton, setDisabledButton] = React.useState(false)

  const classes = useStyles()
  const getAttribute = (name) =>
    order?.pet?.attributes?.filter((attr) => attr.label === name)[0]?.value

  const checkIn = async (id) => {
    setDisabledButton(true)
    try {
      await API.post(`service-order/appointment/${id}/check-in`, {
        checkin: { checklist: [] },
      })
      history.push('/agenda')
      setDisabledButton(false)
    } catch (error) {
      setDisabledButton(false)
    }
  }

  const finish = async (id, body) => {
    setDisabledButton(true)
    try {
      await API.post(
        `service-order/appointment/${id}/veterinary-appointment-status`,
        body,
      )
      history.push('/agenda')
      setDisabledButton(false)
    } catch (error) {
      setDisabledButton(false)
    }
  }

  const sentToVo = async (id) => {
    setDisabledButton(true)
    try {
      await API.post(`service-order/appointment/${id}/send-to-vo`)
      history.push('/agenda')
      setDisabledButton(false)
    } catch (error) {
      setDisabledButton(false)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const closeModal = () => {
    setAgendaModal(false)
  }

  const handleCancel = async () => {
    try {
      await API.post(`service-order/appointment/${order?.id}/cancel`)

      setTimeout(() => {
        history.push('/agenda')
      }, 1500)
    } catch (err) {
      console.log(err)
    }
  }

  const bodyService = {
    status: 'FINALIZADO',
  }

  const handleServiceValidation = (orderId, body) => {
    if (order?.services?.length === 0) {
      setServiceOn(true)
    } else {
      finish(orderId, body)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid item container xs={12} spacing={2} style={{ textAlign: 'center' }}>
      <Modal
        open={serviceOn}
        className={classes.modal}
        onClose={() => setServiceOn(!serviceOn)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ width: 200 }} className={classes.paper}>
          <p id="child-modal-description">
            Você tem certeza que quer finalizar o agendamento sem serviço
            adicionado?
          </p>
          <Button onClick={() => finish(orderId, bodyService)}>Sim</Button>
          <Button
            onClick={() => {
              setServiceOn(!serviceOn)
              setCurrentTab(4)
            }}
          >
            Não
          </Button>
        </Box>
      </Modal>
      <AgendaModal
        close={closeModal}
        open={agendaModal}
        handleClose={handleCancel}
        id={order?.id}
        serviceModal={serviceModal}
        date={order?.date}
        url="/agenda"
      />
      <Grid item xs={12} className={classes.avatarContainer}>
        <Avatar className={classes.avatar}>
          {order?.pet?.name.substr(0, 1)}
        </Avatar>
      </Grid>
      <Grid item xs={12}>
        <span className={classes.pet}>
          {order?.pet ? order?.pet?.name : <Skeleton />}
        </span>
      </Grid>

      <Grid item xs={12} className={classes.title}>
        Espécie
      </Grid>
      <Grid item xs={12}>
        {getAttribute('Espécie') ? getAttribute('Espécie') : <Skeleton />}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Porte
      </Grid>
      <Grid item xs={12}>
        {getAttribute('Porte') ? getAttribute('Porte') : <Skeleton />}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Peso
      </Grid>
      <Grid item xs={12}>
        {getAttribute('Peso') ? getAttribute('Peso') : 'Sem peso cadastrado'}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Raça
      </Grid>
      <Grid item xs={12}>
        {getAttribute('Raça') || getAttribute('Raça') === '' ? (
          getAttribute('Raça')
        ) : (
          <Skeleton />
        )}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Nascimento
      </Grid>
      <Grid item xs={12}>
        {order?.pet ? order?.pet?.birthdate : <Skeleton />}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        Observações
      </Grid>
      <Grid item xs={12}>
        {order?.comment}
      </Grid>

      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12}>
          <span className={classes.customerDetails}>
            {order?.customer ? <>{order?.customer?.name}</> : <Skeleton />}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.customerDetails}>
            {order?.customer ? <>{order?.customer?.cpf}</> : <Skeleton />}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.customerDetails}>
            {order?.customer ? (
              <>{order?.customer?.phoneNumber}</>
            ) : (
              <Skeleton />
            )}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid item xs={12}>
        <Grid item xs={12}>
          <span className={classes.customerDetails}>
            {order?.customer ? (
              <>
                Plano de Saúde:
                {order?.customer?.healthPlan}
              </>
            ) : (
              <Skeleton />
            )}
          </span>
        </Grid>
      </Grid>
      {order?.status !== 'ORÇAMENTO ENVIADO' && (
        <>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CustomButton
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              disabled={disabledButton}
              onClick={() => {
                if (order?.status === 'AGENDADO') {
                  checkIn(orderId)
                }
                if (order?.status === 'EM ANDAMENTO') {
                  const body = {
                    status: 'FINALIZADO',
                  }
                  handleServiceValidation(orderId, body)
                }
                if (order?.status === 'FINALIZADO') {
                  sentToVo(orderId)
                }
              }}
            >
              {(order?.status === 'AGENDADO' ||
                order?.status === 'VACINA PROGRAMADA') &&
                'INFORMAR CHEGADA DO CLIENTE'}
              {order?.status === 'EM ANDAMENTO' && 'FINALIZAR ATENDIMENTO'}
              {order?.status === 'FINALIZADO' && 'ENVIAR ORÇAMENTO PARA PDV'}
            </CustomButton>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              className={classes.buttonEnd}
              aria-describedby={id}
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Mais opções
            </Button>
            <Menu
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              keepMounted
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '26ch',
                },
              }}
            >
              <MenuItem
                key="cancelar"
                onClick={() => {
                  setAgendaModal(true)
                  setServiceModal(false)
                }}
              >
                Cancelar Agendamento
              </MenuItem>
              <MenuItem
                key="modificar"
                onClick={() => {
                  setAgendaModal(true)
                  setServiceModal(true)
                }}
              >
                Modificar Data
              </MenuItem>
            </Menu>
          </Grid>
        </>
      )}
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          className={classes.buttonEnd}
          disableElevation
          variant="contained"
          color="primary"
          onClick={() => {
            history.goBack()
          }}
        >
          Voltar
        </Button>
      </Grid>
    </Grid>
  )
}

function CheckinPage({ location }) {
  const { orderId } = useParams()
  const { search } = useLocation()
  const { state } = location

  const employeeQuery = new URLSearchParams(search).get('employeeId')
  const employeeId = employeeQuery || state?.employeeId

  const [order, setOrder] = useState(null)
  const [checklist, setChecklist] = useState([])
  const [loading, setLoading] = React.useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [serviceOn, setServiceOn] = React.useState(false)

  const handleGetOrder = async () => {
    const { data } = await API.get(`service-order/appointment/${orderId}`)
    setOrder(data)
  }

  const handleChecklist = async () => {
    const { items } = await getChecklist()
    setChecklist(items)
  }

  useEffect(() => {
    handleGetOrder()
    handleChecklist()
    window.scrollTo(0, 0)
  }, [])

  return (
    <PrescriptionProvider>
      <BasePage
        pageTitle={`Prontuário Digital - ${moment(order?.date).format(
          'DD/MM/YYYY',
        )} às ${order?.startAt}`}
      >
        <Grid
          container
          spacing={3}
          style={{
            background: '#ffffff',
            borderRadius: '7px',
            minHeight: '100vh',
          }}
        >
          <Grid item xs={2} style={{ borderRight: '1px solid #f6f6f6' }}>
            <Resume
              order={order}
              handleGetOrder={handleGetOrder}
              loading={loading}
              setServiceOn={setServiceOn}
              serviceOn={serviceOn}
              setCurrentTab={setCurrentTab}
            />
          </Grid>
          <Grid item xs={10}>
            <Main
              order={order}
              checklist={checklist}
              setOrder={setOrder}
              employeeId={employeeId}
              handleGetOrderUpdate={handleGetOrder}
              setLoading={setLoading}
              loading={loading}
              setServiceOn={setServiceOn}
              serviceOn={serviceOn}
              setCurrentTab={setCurrentTab}
              currentTab={currentTab}
            />
          </Grid>
        </Grid>
      </BasePage>
    </PrescriptionProvider>
  )
}

export default CheckinPage
