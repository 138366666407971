import { AlertTriangle } from "lucide-react";

import { Tooltip } from "../../../../../../../components/ui/Tooltip";

import { Container, TipContent, TipMessage } from "./styles";

export function NewMedicineWarning() {
  return (
    <Container>
      <TipContent>
        <Tooltip.Root
          name="new-medicine-warning"
          side="top"
          zIndex={30}
          sideOffset={20}
        >
          <TipMessage>
            <span>Este medicamento ainda não foi adicionado à receita.</span>
            <span>
              Esta é uma pré-visualização. Para incluir o medicamento na
              receita, clique em "Adicionar".
            </span>
          </TipMessage>
        </Tooltip.Root>
        <Tooltip.Trigger tooltipName="new-medicine-warning">⚠️</Tooltip.Trigger>
      </TipContent>
    </Container>
  );
}
