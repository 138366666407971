import { ListChecks } from "lucide-react";
import { Accordion } from "../../../../../../components/ui/Accordion";

export function HistoryViewServices() {
  return (

    <Accordion.Item
      title="Serviços"
      animate="dance"
      icon={ListChecks}
     disabled
    >
      <Accordion.Content>HistoryViewServices
      </Accordion.Content>
    </Accordion.Item>
  );
}
