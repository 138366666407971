import { Children, cloneElement, useEffect } from "react";
import { useQueryParam } from 'use-query-params';

import { TabsHeader } from "./TabsHeader";
import { TabsTab } from "./TabsTab";
import { useTabs } from "./useTabs";
import { Container, ContentBox } from "./styles";


export function TabsRoot({ children, name, tabs, ...rest }) {
  const [tab] = useQueryParam(name);

  const setTabs = useTabs((st) => st.actions.setTabs);
  const setActive = useTabs((st) => st.actions.setActive);

  setTabs(name, tabs);

  const allTabsList = useTabs((st) => st.data.tabs);
  const tabsList = allTabsList[name];

  const childrenProps = Children.map(children, (child) =>
    cloneElement(child, { name })
  );

  useEffect(() => {
    const activeTab = _.find(tabsList, { active: true });
    if (!!tab && activeTab.slug !== tab) setActive(name, tab)
  }, [tab])

  return (
    <Container {...rest} style={{ zIndex: "16" }}>
      <TabsHeader>
        {tabsList.map((tab) => (
          <TabsTab key={tab.id} name={name}>
            {tab.title}
          </TabsTab>
        ))}
      </TabsHeader>
      <ContentBox>{childrenProps}</ContentBox>
    </Container>
  );
}
