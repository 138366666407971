import styled from 'styled-components'

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;

`
