import { useEffect } from 'react'
import { useCrm } from '../../../hooks/CrmContext'
import { Stage } from './Stage'
import { Container } from './styles'

export function StageList() {
  const { findList, getStages } = useCrm()
  const stages = getStages()

  useEffect(() => {
    findList()
  }, [findList])

  return (
    <Container>
      {!!stages && stages.map((stage) => <Stage key={stage.name} {...stage} />)}
    </Container>
  )
}
