import _ from "lodash";

import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const certificateVaccination = {
  name: "Atestado de Vacinação",
  title: "Atestado de Vacinação",
  gender: "male",
  fields: {
    vaccinationAgainst: {
      label: "Vacinação contra",
      type: "text",
      required: true,
      defaultPreview: "______________",
    },
    vaccineTradeName: {
      label: "Nome comercial da vacina",
      type: "text",
      required: true,
      defaultPreview: "______________",
      props: {
        style: { minWidth: "260px" },
      },
    },
    matchNumber: {
      label: "Número da partida",
      type: "text",
      required: true,
      defaultPreview: "______________",
    },
    manufacturer: {
      label: "Fabricante",
      type: "text",
      required: true,
      defaultPreview: "______________",
    },
    manufacturingDate: {
      label: "Data de fabricação",
      type: "text",
      required: true,
      defaultPreview: "__/__/____",
      props: {
        mask: "99/99/9999",
      },
    },
    expirationDate: {
      label: "Data de expiração",
      type: "text",
      required: true,
      defaultPreview: "__/__/____",
      props: {
        mask: "99/99/9999",
      },
    },
    note: {
      label: "Observações",
      type: "textarea",
      props: {
        rows: 2,
        style: { minWidth: "100%" },
      },
    },
  },
  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },
  content: `
    <p>Atesto para os devidos fins que o animal identificado neste documento foi vacinado por mim nesta data, conforme informações abaixo:</p>
    <br />
    <p><strong>Vacinação contra:</strong> {{vaccinationAgainst}}</p>
    <p><strong>Nome comercial da vacina:</strong> {{vaccineTradeName}}</p>
    <p><strong>Número da partida:</strong> {{matchNumber}}</p>
    <p><strong>Fabricante:</strong> {{manufacturer}}</p>
    <p><strong>Data de fabricação:</strong> {{manufacturingDate}}</p>
    <p><strong>Data de validade:</strong> {{expirationDate}}</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}

    {{#note}}
      <br />
      <br />
      <p><strong>Observações:</strong></p>
      <p>{{{.}}}</p>
    {{/note}}
  `.trim(),
};
