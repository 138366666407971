import styled from 'styled-components'

import { Grid, MenuItem, TextField } from '@material-ui/core'

export const Container = styled(Grid)`
  gap: 24px;
`
export const Box = styled(Grid)`
  background: #fff;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &.MuiGrid-grid-xs-6 {
    flex-basis: calc(50% - 12px);
  }
`

export const BoxColumn = styled(Box)`
  flex-direction: column;
`

export const Item = styled(Grid)`
  padding: 12px;
`

export const Select = styled(TextField)``

export const Option = styled(MenuItem)`
  margin-bottom: 20px;
`
export const Title = styled.h2`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #666666;
  margin: 0;
  padding: 12px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
  .alert {
    font-size: 12px;
    color: #faa924;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
