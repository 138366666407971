import { create } from "zustand";
import { produce } from "immer";
import _ from "lodash";
import moment from "moment";

const defaultData = {
  months: [],
  config:{
    loading: false,
    saving: false,
    period: moment().format('MM/YYYY'),
    unsaved:{
      expenses:[],
      goals:[]
    },
    expenses:{},
    goals:{}
  }
};

export const useDre = create((set) => ({
  data: defaultData,
  actions: {
    setShowMonths: (total) =>
      set((state) =>
        produce(state, (draft) => {
          if(!total) return
            draft.data.months = [];

          _.range(total).forEach((i) => {
            draft.data.months.push(moment().subtract(i, 'months').format('YYYY-MM'))
          })
        })
      ),
    setConfigLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
           draft.data.config.loading = status
        })
      ),
    setConfigSaving: (status) =>
      set((state) =>
        produce(state, (draft) => {
           draft.data.config.saving = status
        })
      ),
    clearConfig: () =>
      set((state) =>
        produce(state, (draft) => {
           draft.data.config = defaultData.config
        })
      ),
    setExpenses: (expense, period) =>
      set((state) =>
        produce(state, (draft) => {
          if(!expense || !period) return

          if(!state.data.config.expenses[period]){
            draft.data.config.expenses[period] = {}
          }

          _.forEach(expense,(value, key)=>{
            if(!state.data.config.expenses[period]) return

            if(!state.data.config.expenses[period][key]){
              draft.data.config.expenses[period][key] = { value: 0, default: 0 }
            }

            draft.data.config.expenses[period][key] = {...state.data.config.expenses[period][key],...value}
          })
        })
      ),
    setGoals: (goal, period) =>
      set((state) =>
        produce(state, (draft) => {
          if(!goal || !period) return

          if(!state.data.config.goals[period]){
            draft.data.config.goals[period] = {}
          }

          _.forEach(goal,(value, key)=>{
            if(!state.data.config.goals[period]) return

            if(!state.data.config.goals[period][key]){
              draft.data.config.goals[period][key] = {value: 0, default: 0}
            }

            draft.data.config.goals[period][key] = {...state.data.config.goals[period][key],...value}
          })
        })
      ),
    setPeriod: (period) =>
      set((state) =>
        produce(state, (draft) => {
          if(!period) return
          draft.data.config.period = period
        })
      ),
    setUnsaved: (type, period) =>
      set((state) =>
        produce(state, (draft) => {
          if(!period || !type) return
          if(state.data.config.unsaved[type].includes(period)) return

          draft.data.config.unsaved[type].push(period)
        })
      ),
    setSaved: (type, period) =>
      set((state) =>
        produce(state, (draft) => {
          if(!period || !type) return
          if(!state.data.config.unsaved[type].includes(period)) return

          _.pull(draft.data.config.unsaved[type], period);
        })
      ),
    },
}));
