import styled from 'styled-components'

export const ListMedicines = styled.div`
  padding-bottom: 30px;
  padding-top: 10px;
`

export const DivTitleMedicines = styled.div`
  display: flex;
  align-items: center;

  & svg {
    color: #000;
  }
`

export const TitleMedicines = styled.h2`
  color: #252525;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  width: 95%;
`

export const DescriptionMedicines = styled.h2`
  color: #ccc;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  margin: 0;
`

export const Title = styled.h2`
  color: #252525;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
`

export const Link = styled.div`
  color: #7aa6bb;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    margin-right: 5px;
  }
`

export const Input = styled.input`
  width: 100%;
  padding: 20px;
  border: 1px solid;
  text-align: center;
  border-left: none;
  font-size: 18px;
  border-right: none;
  color: #00567e;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #00567e;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #00567e;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #00567e;
  }
`

export const ItemMedicine = styled.div`
  border-bottom: 1px dashed #ccc;
  padding-bottom: 20px;
  padding-top: 20px;

  &:first-child {
    padding-top: 0px;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const CheckBoxItem = styled.div`
  & div {
    color: #666666;
    font-weight: 500;
    padding-bottom: 10px;
  }

  & label {
    position: relative;
    padding-right: 8px;
    padding-left: 2px;
    top: -2px;
    color: #666666;
  }
`

export const Row = styled.div`
  padding: 8px 0;

  & textarea {
    display: block;
  }

  & span {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #5d31db;
  }
`

export const RowButton = styled.div`
  padding: 20px 0;
  text-align: right;

  & button {
    width: 90px;
    background-color: transparent;
    border: 1px solid #ccd219;
    padding: 10px;
    border-radius: 3px;
    font-weight: 600;
    color: #666666;
    cursor: pointer;

    &:last-child {
      margin-left: 20px;
      background-color: #ccd219;
      color: #fff;
    }
  }
`

export const ListNames = styled.div`
  height: 350px;
  overflow: auto;
  padding: 10px 0px;
`

export const ListNamesItens = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;

  & svg {
    font-size: 35px;
    padding-right: 10px;
    width: 5%;
  }
`

export const InputType = styled.input`
  padding: 8px 7px;
  border-radius: 3px;
  border: 1px solid;
  width: 68.5%;

  &:focus-visible {
    box-shadow: 0 0 5px #00567e;
    border: none;
    background: transparent;
    border-color: transparent;
  }

  &:focus {
    box-shadow: 0 0 5px #00567e;
    outline: none;
    border: 1px solid #00567e !important;
  }
`

export const InputTypeName = styled.input`
  padding: 8px 7px;
  border-radius: 3px;
  border: 1px solid;
  width: 72%;
  margin-bottom: 8px;

  &:focus-visible {
    box-shadow: 0 0 5px #00567e;
    border: none;
    background: transparent;
    border-color: transparent;
  }

  &:focus {
    box-shadow: 0 0 5px #00567e;
    outline: none;
    border: 1px solid #00567e !important;
  }
`

export const StyleText = styled.p`
  width: 100%;
  padding: 15px;
  text-align: center;
  border-left: none;
  font-size: 18px;
  border-right: none;
  color: #00567e;
`
