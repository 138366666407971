import { memo } from "react";
import Mustache from "mustache"
import { useDre } from "../../../../../store/subscription/useDre";

import { Alert } from "../../../../../components/ui/Alert";

import { SubscriptionDREController } from "../../../../../services/controllers/subscription";
import { joinToSentenceList } from "../../../../../services/functions";
import { dre } from "../../../../../services/templates/subscription/dre";

function monthMerge(monthList) {

}

export const UnsavedConfigurations = () => {
  const CLDre = SubscriptionDREController()
  const unsavedExpensesPeriods = useDre(st => st.data.config.unsaved.expenses)
  const unsavedGoalsPeriods = useDre(st => st.data.config.unsaved.goals)

  const unsavedExpenses = {
    show: !!unsavedExpensesPeriods.length,
    monthText: unsavedExpensesPeriods.length > 1 ? 'dos meses' : 'do mês',
    monthList: joinToSentenceList(unsavedExpensesPeriods),
  }
  const unsavedGoals = {
    show: !!unsavedGoalsPeriods.length,
    monthText: unsavedGoalsPeriods.length > 1 ? 'dos meses' : 'do mês',
    monthList: joinToSentenceList(unsavedGoalsPeriods),
  }

  const expensesMessage = Mustache.render(dre.configurations.unsavedExpenses, unsavedExpenses)
  const goalsMessage = Mustache.render(dre.configurations.unsavedGoals, unsavedGoals)

  return (
    <Alert.Root name={CLDre._unsavedConfigurationsAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Dados não Salvos</Alert.Title>
        <Alert.Description>
          <div dangerouslySetInnerHTML={{ __html: expensesMessage }} />
          <div dangerouslySetInnerHTML={{ __html: goalsMessage }} />
          <p>Tem certeza de que deseja sair sem salvá-las?</p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm
            onClick={() => {
              CLDre.closeConfigurationDrawer(true);
            }}
          >
            Sim, sair
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
}
