import { getSubscriberStatusColor } from "../../../../../services/functions";

import { useSubscribers } from "../../../../../store/subscription/useSubscribers";

import { Container, StatusBadge } from "./styles";

export function SubscriptionDetailsTitle(){
  const details = useSubscribers(st=>st.data.details)
  const background = getSubscriberStatusColor(details.status)

  return (
    <>
      <Container>Detalhes da Assinatura <StatusBadge background={background}>{details.status}</StatusBadge></Container>
    </>
  )
}
