import _ from "lodash";

import { getPetAttribute } from "../../functions";

import { usePet } from "../../../store/customer/usePet";
import { useSchedule } from '../../../store/schedule/useSchedule'


import { ScheduleModel, PetModel } from "../../models";

export default class PetController {

  isUpdatedScheduler = useSchedule.getState().actions.isUpdated;

  constructor() {
    this.MPet = PetModel();
    this.MSchedule = ScheduleModel();
  }

  attributeOptions = (attribute) => {
    const petAttributes = usePet.getState().data.attributes;

    const { data } = getPetAttribute(petAttributes, attribute);
    return data;
  };

  setPetData = (newPetData) => {
    if (newPetData.weight) {
      newPetData.weight = newPetData.weight.replace(/[^0-9,]/g, "");
    }

    this.isUpdatedScheduler(false)
    this.MPet.setPet(newPetData);
  };

  findWeightHistory = _.once(async () => {
    await this.MSchedule.findWeightHistory();
  });
}
