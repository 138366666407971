import { DrawerContent } from './DrawerContent'
import { DrawerControls } from './DrawerControls'
import { DrawerFooter } from './DrawerFooter'
import { DrawerHeader } from './DrawerHeader'
import { DrawerRoot } from './DrawerRoot'
import { useDrawer } from './useDrawer'

export const Drawer = {
  Root: DrawerRoot,
  Controls: DrawerControls,
  Header: DrawerHeader,
  Content: DrawerContent,
  Footer: DrawerFooter,
  store: useDrawer,
}
