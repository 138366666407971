import { rgba } from 'polished'
import styled, { css } from 'styled-components'

export const Avatar = styled.div`
  font-weight: bold;
  background: ${(props) => props.theme.colors.scale[500]};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.gray[300]};
  box-shadow: 1px 1px 5px ${(props) => rgba(props.theme.colors.black, 0.3)};
  position: relative;
`

export const Position = styled.em`
  position: absolute;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.background};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.gray[700]};
  box-shadow: 1px 1px 5px ${(props) => rgba(props.theme.colors.black, 0.3)};

  ${(props) =>
    props.position === 1 &&
    css`
      background: ${(props) => props.theme.colors.ranking.gold};
      color: ${(props) => props.theme.colors.black};
    `}

  ${(props) =>
    props.position === 2 &&
    css`
      background: ${(props) => props.theme.colors.ranking.silver};
      color: ${(props) => props.theme.colors.black};
    `}

    ${(props) =>
    props.position === 3 &&
    css`
      background: ${(props) => props.theme.colors.ranking.bronze};
      color: ${(props) => props.theme.colors.black};
    `}
`

export const PositionTooltip = styled.div`
  padding: 5px 10px;
`
export const PositionBox = styled.div`
  position: relative;
  cursor: default;
  left: -20px;
  top: -30px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Name = styled.p`
  margin: 0;
  font-size: 18px;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Store = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 100%;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Container = styled.div`
  display: flex;
  gap: 10px;
  height: 50px;
  ${(props) =>
    props.size === 'small' &&
    css`
      height: 40px;
      min-width: fit-content;
      ${Avatar} {
        width: 40px;
        height: 40px;
        font-size: 16px;
      }
      ${Name} {
        font-size: 16px;
      }
    `}

  ${(props) =>
    props.size === 'mini' &&
    css`
      height: 20px;
      align-items: center;
      ${PositionBox} {
        left: initial;
        top: initial;
        width: 25px;
        height: 25px;
      }
      ${Name} {
        font-size: 14px;
      }
    `}
`
