import { useContext } from "react"
import { useLocation } from 'react-router-dom'

import { Play } from 'lucide-react'
import { AuthContext } from "../../../../providers/auth"
import { PrintButton } from "../Printer/checklist/PrintButton"
import { Action } from "../../../ui/Action"
import { PrinterIcon } from "lucide-react"

export function ChecklistPrint({ onClick: callbackFn = () => { }, beforeClick = () => { }, token, ...rest }) {
  const { search } = useLocation()
  const dev = new URLSearchParams(search).get('dev')
  const { modules } = useContext(AuthContext)

  const browserPrinter = !!modules?.printer?.schedule
  const printerAssistent = !!modules?.printerV2?.schedule

  const ChecklistPrintButton = printerAssistent ? PrintButton : Action.Root;

  if (!browserPrinter && !printerAssistent) return null

  const printAction = () => {
    if (browserPrinter && !printerAssistent) {
      window.open(
        `https://printer.petlandbrasil.com.br/?token=${token}`,
        'popup',
        'width=350, height=255, top=100, left=110, scrollbars=no',
      );
    }
    callbackFn();
  }

  return (
    <ChecklistPrintButton {...rest} beforeClick={beforeClick} onClick={printAction}>
      <Action.Icon icon={PrinterIcon} />
      IMPRIMIR CHECKLIST
    </ChecklistPrintButton>
  )
}
