import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  container: {
    padding: '30px 0',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#666666',
    flex: '1',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#666666',
    flex: '1',
    margin: '10px 0',
  },
  table: {
    margin: '60px 0 0 0',
    width: '700px',
  },
  header: {
    border: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 20px',
  },
  item: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemNumber: {
    flex: 1,
    maxWidth: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemDate: {
    flex: 1,
    maxWidth: '150px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  line: {
    border: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 20px',
    flexWrap: 'wrap',
  },
  button: {
    border: 'none',
    background: 'none',
    color: '#666666',
    cursor: 'pointer',
    position: 'relative',
  },
  itemButton: {
    flex: 1,
    maxWidth: '50px',
  },
  downArrow: {
    width: '15px',
    height: '15px',
    position: 'absolute',
    transform: 'translate(-50%, -100%) rotate(45deg)',
    left: '50%',
    top: '50%',
    borderBottom: '2px solid #666666',
    borderRight: '2px solid #666666',
  },
  upArrow: {
    width: '15px',
    height: '15px',
    position: 'absolute',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
    left: '50%',
    top: '50%',
    borderBottom: '2px solid #666666',
    borderRight: '2px solid #666666',
  },
  acordion: {
    width: '100%',
    maxHeight: '0',
    overflow: 'hidden',
  },
  openAcordion: {
    width: '100%',
    maxHeight: '100%',
    marginTop: '12px',
    overflow: 'hidden',
  },
  textAcrodion: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#666666',
    flex: '1',
    margin: '14px 0',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#666666',
  },
  drawerBox: {
    maxWidth: '1020px',
    overflowX: 'hidden',
    padding: '0 40px',
    height: '100%',
  },
  itemTag: {
    flex: '1',
    maxWidth: '70px',
    fontWeight: 'bold',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px 0 0',
    color: '#ffffff',
  },
}))
