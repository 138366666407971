import BasePage from '../basePage'

import { Filter } from './Filter'
import { NetPromote } from './NetPromote'
import { Stars } from './Stars'
import { Comments } from './Comments'

import { Container } from './styles'

import { AssessmentsProvider } from '../../hooks/AssessmentsContext'
import { CommentsProvider } from '../../hooks/CommentsContext'

export function Avaliacoes() {
  return (
    <BasePage pageTitle="Avaliações">
      <AssessmentsProvider>
        <Container container spacing={3}>
          <Filter />
          <NetPromote />
          <Stars />
          <CommentsProvider>
            <Comments />
          </CommentsProvider>
        </Container>
      </AssessmentsProvider>
    </BasePage>
  )
}
