import styled from 'styled-components'
import { BoxColumn } from '../styles'

export const Container = styled(BoxColumn)``

export const StartsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  flex: 1;
  width: 100%;
`
