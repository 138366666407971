import { toast } from 'react-toastify'
import API from '../api'
import { format } from '../formatResponses/plan'
import { usePlans } from '../../store/subscription/usePlans'
import { formatBody } from '../formatBody/plan'

export class Plans {
  setPlans = usePlans.getState().actions.setPlans

  setPlanFactor = usePlans.getState().actions.setPlanFactor

  fixPlansFactor = usePlans.getState().actions.fixPlansFactor

  undoPlansFactor = usePlans.getState().actions.undoPlansFactor

  clearPlans = usePlans.getState().actions.clear

  async findPlans(force = false) {
    try {
      const { data } = await API.get('/subscriptions/commissions/rules')

      const formatedPlans = format.plans(data.storePlans, data.config)

      if (force) this.clearPlans()

      this.setPlans(formatedPlans)
      return formatedPlans
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: 'colored' })
      return false
    }
  }

  setFactor = (planId, factor) => {
    if (!planId) {
      toast.error('Informe um plano', {
        theme: 'colored',
      })
      return false
    }

    this.setPlanFactor(planId, factor)
  }

  async saveRules() {
    try {
      const newRules = usePlans.getState().data.plans
      if (!newRules) {
        toast.error(
          'Nenhum plano encontrado, atualize a página e tente novamente!',
          { theme: 'colored' },
        )
        return false
      }

      const subscriptionRules = formatBody.rules(newRules)

      const { data } = await API.post('/subscriptions/commissions/rules', {
        subscriptionRules,
      })

      this.fixPlansFactor()

      toast.success(data.message, { theme: 'colored' })
      return data
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: 'colored' })
      return false
    }
  }

  undoRules() {
    this.undoPlansFactor()
  }
}
