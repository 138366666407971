import { create } from "zustand";
import { produce } from "immer";
import { persist } from "zustand/middleware";
import _ from "lodash";

export const useTable = create()(
  persist(
    (set) => ({
      data: {
        tables: [],
        columns: {},
      },
      actions: {
        setTable: ({
          name,
          config = false,
          action = false,
          customActions = false,
        }) =>
          set((state) =>
            produce(state, (draft) => {
              const index = state.data.tables.findIndex(
                (table) => table.name === name
              );
              if (!~index)
                draft.data.tables.push({ name, config, action, customActions });
              if (
                !!~index &&
                !_.isEqual(
                  { name, config, action, customActions },
                  state.data.tables[index]
                )
              ) {
                draft.data.tables.splice(index, 1);
                draft.data.tables.push({ name, config, action, customActions });
              }
            })
          ),
        setColumns: (table, columns) =>
          set((state) =>
            produce(state, (draft) => {
              const allColumns = state.data.columns;

              if (!allColumns || !allColumns[table]) {
                draft.data.columns[table] = columns;
                return;
              }

              const currentColumns = allColumns[table].map(
                ({ name, size }) => ({ name, size })
              );
              const newColumns = columns.map(({ name, size }) => ({
                name,
                size,
              }));

              if (
                JSON.stringify(currentColumns) !== JSON.stringify(newColumns)
              ) {
                draft.data.columns[table] = newColumns;
              }
            })
          ),
        updateColumns: (table, columns) =>
          set((state) =>
            produce(state, (draft) => {
              const allColumns = state.data.columns;

              if (!allColumns || !allColumns[table]) {
                draft.data.columns[table] = columns;
                return;
              }
              if (
                JSON.stringify(allColumns[table]) !== JSON.stringify(columns)
              ) {
                draft.data.columns[table] = columns;
              }
            })
          ),
      },
    }),
    {
      name: "@AgendaPetland-tables",
      partialize: (st) => ({ data: st.data }),
    }
  )
);

export const get = {
  columns: (columns, status = "*") => {
    if (!columns) return [{ name: "insira a lista de colunas", size: "" }];

    return columns.filter((colun) => {
      if (status === "hidden") return colun.hidden;
      if (status === "visible") return !colun.hidden;
      return colun;
    });
  },
  table: (tables, tableName) => tables.find(({ name }) => name === tableName),
};
