import { Input } from "../../../components/ui/Form/Input";
import { PrintImage } from "../../../services/builders/PrintImage";

export function Printer() {
  PrintImage.init()
  let position = JSON.parse(localStorage.getItem('@PETLAND/printer-position'))
  let size = JSON.parse(localStorage.getItem('@PETLAND/printer-size'))

  return (
    <div className="w-full flex flex-col gap-4 p-6">
      <div>
        <h2 className="text-xl font-semibold">Configurações de impressão</h2>
      </div>
      <div className="flex flex-col gap-3">
        <h2 className="text-lg w-full">Posição</h2>
        <div className="flex gap-4 items-center">
          <Input.Root
            money
            noSuffix
            decimals={0}
            label="Cima"
            style={{ maxWidth: "150px" }}
            defaultValue={position.cima}
            onChange={(cima) =>
              PrintImage.setPosition({ cima })
            }
          />
          <Input.Root
            money
            noSuffix
            decimals={0}
            label="Baixo"
            style={{ maxWidth: "150px" }}
            defaultValue={position.baixo}
            onChange={(baixo) =>
              PrintImage.setPosition({ baixo })
            }
          />
          <Input.Root
            money
            noSuffix
            decimals={0}
            label="Esquerda"
            style={{ maxWidth: "150px" }}
            defaultValue={position.esquerda}
            onChange={(esquerda) =>
              PrintImage.setPosition({ esquerda })
            }
          />
          <Input.Root
            money
            noSuffix
            decimals={0}
            label="Direita"
            style={{ maxWidth: "150px" }}
            defaultValue={position.direita}
            onChange={(direita) =>
              PrintImage.setPosition({ direita })
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h2 className="text-lg w-full">Tamanho</h2>
        <div className="flex gap-4 items-center">
          <Input.Root
            money
            size
            noSuffix
            label="Altura mínima"
            style={{ maxWidth: "150px" }}
            defaultValue={size}
            onChange={(size) =>
              PrintImage.setSize(size)
            }
          />
        </div>
      </div>
    </div >
  )
}
