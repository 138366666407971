import { Editor } from "../../../../../../../../components/ui/Form/Editor";

import { VeterinaryPrescriptionController } from "../../../../../../../../services/controllers/VeterinaryCare";
import { usePrescription } from "../../../../../../../../store/service/veterinary/usePrescription";

import { FormGroup } from "../../styles";

export function FreeDescription() {
  const CLVetPrescription = VeterinaryPrescriptionController();
  const medicineProps = usePrescription((st) => st.data.prescription.newMedicine);

  const isDisabled = !medicineProps.presentation;

  return (
    <FormGroup>
      <Editor
        label="Posologia"
        disabled={isDisabled}
        content={medicineProps.freeText}
        onChange={(freeText) =>
          CLVetPrescription.setNewMedicineProps({ freeText })
        }
      />
    </FormGroup>
  );
}

