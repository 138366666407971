import {
  getTotalDosesWithFrequencyInDuration,
  getVaccineDuration,
  getVaccineFrequency,
} from "../../../../../../../services/functions";

import { useVaccine } from "../../../../../../../store/service/veterinary/useVaccine";
import { WORD_VARIATIONS } from "../../../../../../../styles/constant";

import { Description as Container } from "../styles";

export function Description() {
  const frequency = useVaccine((st) => st.data.newProtocolValues.frequency);
  const frequencyPeriod = useVaccine(
    (st) => st.data.newProtocolValues.frequencyPeriod
  );
  const duration = useVaccine((st) => st.data.newProtocolValues.duration);
  const durationPeriod = useVaccine(
    (st) => st.data.newProtocolValues.durationPeriod
  );

  const durationData = { duration, durationPeriod };
  const frequencyData = { frequency, frequencyPeriod };

  const totalDoses = getTotalDosesWithFrequencyInDuration(
    durationData,
    frequencyData
  );

  const frequencyPlural = frequency === 1 ? 1 : 2;
  const durationPlural = duration === 1 ? 1 : 2;
  const totalDosesPlural = totalDoses === 1 ? 1 : 2;

  const periodWord = getVaccineFrequency(frequencyPeriod);
  const durationWord = getVaccineDuration(durationPeriod);

  return (
    <Container>
      {!!frequency && (
        <p>
          <span>
            Aplicar uma dose a cada
            <strong> {frequency}</strong>
            {frequencyPeriod && <strong> {periodWord[frequencyPlural]}</strong>}
          </span>
          {!!duration && (
            <>
              <span> por</span>
              <strong> {duration}</strong>
              {durationPeriod && (
                <strong> {durationWord[durationPlural]}</strong>
              )}
            </>
          )}
          .
        </p>
      )}
      {!isNaN(totalDoses) && (
        <p>
          Total de doses:{" "}
          <strong>
            {totalDoses} {WORD_VARIATIONS.dose[totalDosesPlural]}
          </strong>
        </p>
      )}
    </Container>
  );
}
