import { useCountUp } from 'use-count-up'
import CountUp from 'react-countup'

import { useAssessments } from '../../../hooks/AssessmentsContext'
import { getNumber, perc2color } from '../../../services/functions'
import { Title } from '../styles'
import { Container, Note, Rating, Ratings } from './styles'

export function NetPromote() {
  const { getNps, getNpsScores } = useAssessments()
  const nps = getNps()
  const npsScores = getNpsScores()

  const { value: npsScoresStore } = useCountUp({
    isCounting: !!npsScores,
    end: npsScores.store,
    duration: 4,
    decimalPlaces: 2,
  })

  return (
    <Container item xs={6}>
      <Title>Net Promote Score</Title>
      <Ratings>
        <Rating className="detractor">
          <p>
            Quantidade: <CountUp end={nps && nps.detractors.quantity} />{' '}
          </p>
          <em>
            <CountUp end={nps && getNumber(nps.detractors.percentage)} />%
          </em>
        </Rating>
        <Rating className="passive">
          <p>
            Quantidade: <CountUp end={nps && nps.neutral.quantity} />{' '}
          </p>
          <em>
            <CountUp end={nps && getNumber(nps.neutral.percentage)} />%
          </em>
        </Rating>
        <Rating className="promoter">
          <p>
            Quantidade: <CountUp end={nps && nps.promoters.quantity} />{' '}
          </p>
          <em>
            <CountUp end={nps && getNumber(nps.promoters.percentage)} />%
          </em>
        </Rating>

        <Note
          color={perc2color({
            center: 75,
            percent: npsScoresStore,
          })}
        >
          <p>NPS DA SUA LOJA</p>
          <span>
            <CountUp
              decimals={2}
              duration={4}
              decimal=","
              end={npsScores ? npsScores.store : 0}
            />{' '}
          </span>
          <em>
            NPS Petland Brasil: {Math.round(npsScores && npsScores.franchisor)}
          </em>
        </Note>
      </Ratings>
    </Container>
  )
}
