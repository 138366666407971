import { useSubscribers } from '../../../../../store/subscription/useSubscribers'
import { ChangePlanActions } from './ChangePlanActions'

import { Container, Actions } from './styles'

export function SubscriptionDetailsPlans(){
  const availablePlans = useSubscribers(st=>st.data.availablePlans)


  if(!availablePlans) return null

  return (
    <Container>
      <Actions>
        {availablePlans.map((availablePlan)=>(
          <ChangePlanActions plan={availablePlan} />
        ))}
      </Actions>
    </Container>
  )
}
