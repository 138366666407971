import { useContext } from "../../Context"
import { useBlockTable } from "../../useBlockTable"

export function Columns({id, blockId}){
  const { tableName } = useContext()
  const columns = useBlockTable(st => st.data.tables[tableName].columns)
  const blockDetailsColumns = useBlockTable(st => st.data.tables[tableName].blockDetailsColumns)

  if(!columns || !blockDetailsColumns) return null

  return(
      <div className="flex-1 w-full flex items-center">
         {columns.map((column) => (
           <span key={column} className="flex items-center px-2 min-w-[150px] w-full flex-1 justify-start">{blockDetailsColumns[blockId][column][id].value}</span>
          ))}
         <u className='flex h-6 w-6 pointer-events-none'/>
      </div>
  )
}
