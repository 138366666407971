
import { FaWhatsapp } from "react-icons/fa";

import { VETERINARY_CANCEL_STATUS, VETERINARY_RESET_STATUS } from "../../../../../store/service/veterinary/constants";

import { useUser } from "../../../../../store/global/useUser";
import { useCare } from "../../../../../store/service/veterinary/useCare";

import { VeterinaryCareController } from "../../../../../services/controllers/VeterinaryCare";

import { Action } from "../../../../../components/ui/Action";

import { ActionsLine } from "./styles";

export function Actions() {
  const CLVetCare = VeterinaryCareController()

  const userGroup = useUser(st => st.data.user.group);
  const isLoading = useCare(st => st.data.isLoading)
  const careStatus = useCare(st => st.data.care.status)

  const isVeterinary = userGroup?.type === 'veterinary' || false;

  const isRestartable = VETERINARY_RESET_STATUS.includes(careStatus)
  const isCancellable = VETERINARY_CANCEL_STATUS.includes(careStatus)

  return (
    <>
      {careStatus === 'waitingStart' && <Action.Root disabled={isLoading || !isVeterinary} full onClick={() => CLVetCare.updateCareStatus('start-atttending')}>Iniciar Atendimento</Action.Root>}
      {careStatus === 'checkIn' && <Action.Root disabled={isLoading || !isVeterinary} full onClick={() => CLVetCare.finishAtttending()}>Finalizar Atendimento</Action.Root>}
      {careStatus === 'finished' && <Action.Root disabled={isLoading || !isVeterinary} custom="#59602c" full onClick={CLVetCare.sendBudget}>Enviar Orçamento</Action.Root>}

      <ActionsLine>
        {isCancellable && (
          <Action.Root full outline status="danger" type="button"
            disabled={isLoading}
            onClick={
              async () => {
                await CLVetCare.rollBackCareStatus('cancel')
              }
            }>
            Cancelar Agendamento
          </Action.Root>
        )}
        {isRestartable && (
          <Action.Root full outline type="button" status="danger"
            disabled={isLoading}
            onClick={async () => await CLVetCare.rollBackCareStatus('reset')}
          >
            Voltar para <strong>Agendado</strong>
          </Action.Root>
        )}
        <Action.Root status="success" full onClick={CLVetCare.sendCareSummary}>
          <Action.Icon icon={FaWhatsapp} size={18} /> Enviar por WhatsApp
        </Action.Root>
      </ActionsLine>
    </>
  );
}
