import { RefreshCw } from "lucide-react";

import { formatMoney } from "../../../../../../services/functions";

import { useSubscribers } from "../../../../../../store/subscription/useSubscribers";

import { SubscriberController } from "../../../../../../services/controllers/subscription";

import { Action } from "../../../../../../components/ui/Action";

export function ChangePlanActionsChange({ plan }){
  const isCanceled = useSubscribers(st=>st.data.details.isCanceled)
  const isPaused = useSubscribers(st=>st.data.details.isPaused)

  const CSubscriber = SubscriberController();

  return (
    <Action.Root
      full
      status="400"
      type="button"
      disabled={isCanceled || isPaused}
      style={{ height: '42px' }}
      name={`change-plan-${plan.id}`}
      title={`R$ ${formatMoney(plan.price)}`}
      onClick={()=> CSubscriber.changeSubscriberPlan(plan)}
    >
      <Action.Icon icon={RefreshCw} size={18} />
      <strong>{plan.description}</strong>
    </Action.Root>
  )
}
