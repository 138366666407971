import API from './api'

export const newCupom = async (payload, setError, setOpenModal) => {
  try {
    const { data } = await API.post('coupons/new', payload)
    if (data) setError(false)

    return data
  } catch (err) {
    setError(true)
    setOpenModal(err?.response?.data?.friendly_message)
  }
}

export const newCupomEdit = async (
  idCupom,
  payload,
  setError,
  setOpenModal,
) => {
  try {
    const { data } = await API.put(`coupons/${idCupom}/update`, payload)
    if (data) setError(false)

    return data
  } catch (err) {
    setError(true)
    setOpenModal(err?.response?.data?.friendly_message)
  }
}

export const getCupom = async (value = true) => {
  const { data } = await API.get(`coupons?active=${value}`)

  return data
}

export const getCategories = async () => {
  const { data } = await API.get('services/categories/search')

  return data
}

export const getCupomEdit = async (id) => {
  const { data } = await API.get(`coupons/${id}`)

  return data
}

export const activeCupom = async (
  id,
  active,
  setError,
  setOpenModal,
  setCupom,
) => {
  try {
    const { data } = await API.put(`coupons/${id}/status`, {
      active,
    })
    if (data) {
      setError(false)
      setOpenModal(data?.message)
      setTimeout(async () => {
        const value = await getCupom()
        setCupom(value?.results)
      }, 1000)
    }

    return data
  } catch (err) {
    setError(true)
    setOpenModal(err?.response?.data?.friendly_message)
  }
}
