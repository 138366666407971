import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from '../services/api'

// Componentes
import Employee from '../components/organisms/Employee'

function Profissionais() {
  const history = useHistory()
  const [prof, setProf] = useState()
  const [accessProfile, setAccessProfile] = useState()
  const [commission] = useState()

  const getProf = async (val = 'ATIVO', getDrivers = false) => {
    try {
      const { data } = await API.get(
        `employees/search?getDrivers=${getDrivers}&${
          val === 'allStatus' ? '' : `status=${val}`
        }`,
      )
      setProf(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getAccess = async () => {
    try {
      const { data } = await API.get('employees/groups/search')
      setAccessProfile(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getProf()
    getAccess()
  }, [])

  const [stateValue, setStateValue] = useState(0)

  return (
    <>
      {stateValue === 0 && (
        <Employee
          prof={prof}
          accessProfile={accessProfile}
          commission={commission}
          stateValue={setStateValue}
          getProf={getProf}
          getAccess={getAccess}
        />
      )}
      {stateValue === 1 && history.push('/profissionais/new')}
      {stateValue === 2 && history.push('/profissionais/profile/new')}
      {stateValue === 3 && history.push('/profissionais/new?motorista')}
    </>
  )
}

export default Profissionais
