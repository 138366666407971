
import { twMerge } from 'tailwind-merge'

export function CardContentList({ className, ...rest }) {
  return (
    <ul
      className={twMerge('flex flex-col gap-1 text-xs', className)}
      {...rest}
    />
  )
}
