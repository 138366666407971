import { toast } from "react-toastify";

import API from "../../api";
import { format } from "../../formatResponses/subscriptions";


export default class SubscriptionPricingModel {
  findTable = async () => {
    try {
      const { data } = await API.get(`/dashboard/dre-assinatura/precificacao`);
      const formattedPricing = format.pricing(data.resultado);

      return formattedPricing
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  };
}
