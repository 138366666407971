import _ from 'lodash'
import { toast } from 'react-toastify'

import API from '../api'

import { useCustomer } from '../../store/customer/useCustomer'
import { usePet } from '../../store/customer/usePet'
import { formatBody } from '../formatBody/schedule'
import { Schedule } from "./schedule";

export class Customer {
  setCustomerData = useCustomer((st) => st.actions.setCustomerData)

  constructor(CPet = false) {
    this.data = {
      id: null,
      cpf: null,
      name: null,
      cellphone: null,
      telephone: null,
      email: null,
    }

    this.Pet = CPet
    this.Schedule = new Schedule()
  }

  setCustomer(customerData) {
    this.data = { ...this.data, ...customerData }

    if (customerData.id) {
      this.Pet.setCustomerId(customerData.id)
    }
    this.setCustomerData(this.data)
  }

  async saveNewCustomer() {
    const { customer } = useCustomer.getState().data
    const { pet } = usePet.getState().data
    const petRequiredErrors = {
      name: 'Nome',
      birthdate: 'Nascimento',
      specie: 'Espécie',
      fur: 'Pelagem',
      weight: 'Peso',
      size: 'Porte',
      breed: 'Raça',
      gender: 'Sexo',
      castrated: 'Castrado',
    }

    const petEmptyValues = _.keys(_.pickBy(pet, _.isEmpty))
    const petRequiredFields = _.compact(
      petEmptyValues.map((key) => petRequiredErrors[key]),
    ).join(', ')

    if (!customer.lastname || customer.lastname.length <= 3) {
      toast.error('Informe nome completo do Cliente!', { theme: 'colored' })
      return false
    }
    if (petRequiredFields.length) {
      toast.error(
        `Os campos ${petRequiredFields} são necessários para cadastrar o novo Pet!`,
        { theme: 'colored' },
      )
      return false
    }

    if (!pet.name) {
      toast.error('Informe os Espécie do Pet!', { theme: 'colored' })
      return false
    }

   const formattedBody =  formatBody.newCustomer({customer})

    const body = _.omitBy(formattedBody, _.isEmpty)

    try {
      const { data } = await API.post('/customers/new', body)

      const newCustomer = {
        id: data.customerId,
        cpf: data.cpf,
        name: customer.name,
        cellphone: customer.cellphone,
        email: customer.email,
      }

      this.setCustomer(newCustomer)

      return newCustomer
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: 'colored' })
      return false
    }
  }

  async findCustomer() {
    try {
      const { data } = await API.get(`customers/search?cpf=${this.data.cpf}`)
      if (!data.results.length) {
        toast.error('Cliente não encontrado', { theme: 'colored' })
        return null
      }

      const customer = {
        id: data.results[0].id,
        cpf: data.results[0].cpf,
        name: data.results[0].name,
        cellphone: data.results[0].telephone,
        email: data.results[0].email,
      }

      this.setCustomer(customer)
      return customer
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: 'colored' })
      return null
    }
  }
}
