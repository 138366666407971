import { useEffect } from "react";

import { useProtocols } from "../../../../store/protocols/useProtocols";

import { ProtocoloHistoric } from "./ProtocoloHistoric";
import { VaccineListItem } from "./VaccineListItem";

import { Container, Title, Empty, Vaccines, VaccineList } from "./styles";
import { useVaccines } from "../../../../store/protocols/useVaccines";

export function Protocols() {
  const {
    protocolList,
    petId,
    actions: { findProtocols, clearProtocols },
  } = useProtocols();

  const { vaccinesList } = useVaccines();

  useEffect(() => {
    if (petId) {
      findProtocols();
    }
  }, [petId, findProtocols]);

  useEffect(() => {
    clearProtocols(vaccinesList);
  }, [clearProtocols, vaccinesList]);

  return (
    <Container>
      <Title>Protocolos Ativos</Title>
      {!protocolList && <Empty>Carregando protocolos...</Empty>}
      {!!protocolList && !protocolList.length && (
        <Empty>Nenhum protocolo adicionado</Empty>
      )}

      {!!protocolList && (
        <Vaccines>
          <VaccineList>
            {protocolList.map((protocol) => (
              <VaccineListItem {...protocol} key={protocol.id} />
            ))}
          </VaccineList>
          <ProtocoloHistoric />
        </Vaccines>
      )}
    </Container>
  );
}
