import { createContext, useContext as useReactContext } from "react";

const RequestFieldsContext = createContext({});

function RequestFieldsProvider({ children, ...rest }) {
  return (
    <RequestFieldsContext.Provider value={rest}>
      {children}
    </RequestFieldsContext.Provider>
  );
}

function useContext() {
  const context = useReactContext(RequestFieldsContext);
  if (!context) {
    throw new Error("use must be used within an Provider");
  }
  return context;
}

export { useContext, RequestFieldsProvider };
