import _ from "lodash";
import Mustache from "mustache";

import { SCHEDULES_STATUS_NEXT } from "../../../store/schedule/constants";
import { WHATSAPP_SEND_MESSAGE_BASE } from "../../../styles/constant";
import { clearNumber, getScheduleMessageTemplateByStatus } from "../../functions";

import { usePet } from "../../../store/global/usePet";
import { useHistory } from "../../../store/service/veterinary/useHistory";
import { useRecord } from "../../../store/service/veterinary/useRecord";
import { useVaccine } from "../../../store/service/veterinary/useVaccine";
import { useService } from "../../../store/service/veterinary/useService";
import { useRequest } from "../../../store/service/veterinary/useRequest";
import { useAttachment } from "../../../store/service/veterinary/useAttachment";
import { usePrescription } from "../../../store/service/veterinary/usePrescription";

import { useCare } from '../../../store/service/veterinary/useCare'

import { VeterinaryCareModel } from "../../models/VeterinaryCare";

import { MedicineController, PetController } from "../global";

import { Alert } from "../../../components/ui/Alert";
import { wppTemplates } from "../../templates/wpp";
import { useMerchant } from "../../../store/global/useMerchant";
import { formatTemplateData } from "../../formatTemplateData/veterinay";

export default class VeterinaryCareController {
  _ID = null
  _name = 'veterinary-care'
  _tabsName = `${this._name}-tabs`
  _sendBudgetAlertName = `${this._name}-send-budget-alert`;
  _servicesNotSavedAlertName = `${this._name}-services-not-saved-alert`;

  _tabsLabels = {
    services: "Serviços",
    record: "Prontuário",
    attachment: "Anexos",
    protocols: "Vacinas",
    prescription: "Receituário",
    request: "Requisições",
    history: "Histórico",
  }

  _department = 'Clínica Veterinária'

  alertIsOpen = Alert.store.getState().actions.isOpen;

  clearPet = usePet.getState().actions.clearPet
  clearRecord = useRecord.getState().actions.clearRecord
  clearHistory = useHistory.getState().actions.clearHistory
  clearVaccine = useVaccine.getState().actions.clearVaccine
  clearServices = useService.getState().actions.clearServices
  clearRequests = useRequest.getState().actions.clearRequests
  clearAttachments = useAttachment.getState().actions.clearAttachments
  clearPrescriptions = usePrescription.getState().actions.clearPrescriptions

  setCare = useCare.getState().actions.setCare
  clearCare = useCare.getState().actions.clearCare
  setCareValues = useCare.getState().actions.setCareValues
  setLoadingStatus = useCare.getState().actions.setLoadingStatus

  constructor(id) {
    this._ID = id;
    this._medicinesId = `${this._name}_${this._ID}_medicines`;

    this.MVetCare = VeterinaryCareModel(this._ID);
    this.CMedicine = MedicineController(this._medicinesId);

    this.clearCareData()
  }

  init = _.once(async () => {
    await this.findCare();

    this.CMedicine.init();
  });

  clearCareData = _.once(() =>{
      this.clearCare()
      this.clearPet()
      this.clearRecord()
      this.clearHistory()
      this.clearVaccine()
      this.clearServices()
      this.clearRequests()
      this.clearAttachments()
      this.clearPrescriptions()
  })

  updateCareStatus = async (status) => {
    this.setLoadingStatus(true)

    const currentStatus = useCare.getState().data.care.status

    await this.MVetCare.changeCareStatus(status)

    const newCareStatus = SCHEDULES_STATUS_NEXT[currentStatus]

    this.setLoadingStatus(false)
    this.setCareValues({ status: newCareStatus })
  };

  rollBackCareStatus = async (status) => {
    this.setLoadingStatus(true)

    await this.MVetCare.changeCareStatus(status)

    window.location.pathname = "/agenda"
  };


  findCare = async () => {
    this.setLoadingStatus(true)

    const care = await this.MVetCare.findCare()

    PetController(care.pet).startPet()

    this.setCare(care)
    this.setLoadingStatus(false)
  };

  sendCareSummary = () => {

    const merchant = useMerchant.getState().data.merchant;
    const pet = usePet.getState().data.pet;
    const care = useCare.getState().data.care
    const ownerPhoneNumber = clearNumber(pet.owner.phone);

    const messageTemplate = getScheduleMessageTemplateByStatus(care.status)
    const messageContent  = wppTemplates.draMei[messageTemplate]?.content || ''

    const careSummaryMessageData = formatTemplateData.sendCareSummary({
      pet,
      care,
      merchant,
      veterinarian: care.veterinarian,
    });

    const message = Mustache.render(
      messageContent,
      careSummaryMessageData
    );

    const wppMessageUrl = WHATSAPP_SEND_MESSAGE_BASE.replace(
      "[PHONE_NUMBER]",
      ownerPhoneNumber
    ).replace("[MESSAGE]", message);
    window.open(wppMessageUrl, "_blank");
  };

  finishAtttending = async (force = false) => {

     const services = useService.getState().data.services
     const someServiceNotSaved = _.find(services, { status: 'new' });

     if (!!someServiceNotSaved && !force) {
       this.alertIsOpen(this._servicesNotSavedAlertName, true);
       return;
     }

     this.updateCareStatus('finish-atttending')
  };

  sendBudget = async (force = false) => {
     this.setLoadingStatus(true)

     if (!force) {
       this.alertIsOpen(this._sendBudgetAlertName, true);
       return;
     }

    await this.MVetCare.changeCareStatus('send-budget')
    this.setCareValues({ status: "budgetSent" })

    window.location.pathname = "/agenda"

  };
}
