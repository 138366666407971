import { Type } from "lucide-react";

import { useContext } from "../Context";

import { MenuButton, MenuGroup } from "./styles";

export function MenuText() {
  const { editor } = useContext();

  return (
    <MenuGroup>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive("paragraph") ? "is-active" : ""}
      >
        <Type size={18} />
      </MenuButton>
    </MenuGroup>
  );
}
