import { create } from "zustand";
import { produce } from "immer";
import uuid from "react-uuid";

export const useDrawer = create((set) => ({
  data: {
    drawers: {},
  },
  actions: {
    setDrawer: (name, drawer) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.drawers[name]) {
            draft.data.drawers[name] = {
              id: `drawer-${uuid()}`,
              open: drawer.open || false,
              direction: drawer.direction || "left",

            };
          }
        })
      ),
    isOpen: (name, open) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.drawers[name]) return;
          draft.data.drawers[name].open = open;
        })
      ),
  },
}));
