import { useState } from "react";

import { formatName, getEmployeeTypeName } from "../../../../../services/functions";

import {
  ProcedureController,
  ScheduleController,
} from "../../../../../services/controllers/scheduling";
import { useSchedule } from "../../../../../store/schedule/useSchedule";

import { Steps } from "../../../../ui/Steps";
import { Input } from "../../../../ui/Form/Input";
import { Select } from "../../../../ui/Form/Select";
import { TextArea } from "../../../../ui/Form/TextArea";

import { Actions } from "../../Actions";
import { SelectedPetCard } from "../../../components/SelectedPetCard";

import { Container, FormContent } from "./styles";

export function CheckData() {
  const CLScheduling = ScheduleController();
  const CLProcedure = ProcedureController();

  const [disableEmployee, setDisableEmployee] = useState(true);

  const details = useSchedule((st) => st.data.details);
  const sellers = useSchedule((st) => st.data.customer.sellers);
  const procedure = useSchedule((st) => st.data.procedure);
  const employees = useSchedule((st) => st.data.customer.employees);

  const setActive = Steps.store((st) => st.actions.setActive);

  return (
    <Steps.Content name={CLScheduling.stepsName} step="Confira os dados">
      <Container onSubmit={CLScheduling.saveNew}>
        <SelectedPetCard />
        <FormContent>
          <Input.Root
            label="Data"
            pickDate
            value={procedure.date}
            onChange={(date) => {
              setDisableEmployee(false);
              CLProcedure.setDate(date);
              CLScheduling.reFindEmployees();
            }}
          />
          <Input.Root
            label="Hora de início"
            mask="99:99"
            value={procedure.time}
            onChange={CLProcedure.setTime}
          />
          <Input.Root
            label="Procedimento"
            disabled
            defaultValue={procedure.data?.name || ""}
          />
          <Select.Root
            label={getEmployeeTypeName()}
            disabled={disableEmployee}
            value={procedure.employee?.id}
            onChange={CLProcedure.setEmployee}
          >
            {employees.map((employee) => (
              <Select.Option key={employee.id} value={employee.id}>
                {formatName(employee.name)}
              </Select.Option>
            ))}
          </Select.Root>
          <Select.Root
            label="Vendedor(a)"
            value={details.seller}
            onChange={(seller) => CLScheduling.setDetails({ seller })}
            styles={{ minWidth: "140px" }}
          >
            {sellers.map((seller) => (
              <Select.Option key={seller.id} value={seller.id}>
                {formatName(seller.name)}
              </Select.Option>
            ))}
          </Select.Root>
          <TextArea.Root
            label="observação"
            rows={5}
            value={details.observation}
            onChange={(observation) =>{
              CLScheduling.setDetails({ observation })
            }}
          />
        </FormContent>
        <Actions
          next="Salvar Agendamento"
          backAction={() => {
            setActive(CLScheduling.stepsName, 1);
          }}
        />
      </Container>
    </Steps.Content>
  );
}
