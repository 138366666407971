import { Pets, Vaccines } from "@mui/icons-material";
import BasePage from "../basePage";

import { useThemes } from "../../providers/theme";
import { ScheduleProvider } from "../../hooks/ScheduleContext";

import { Head } from "./Head";
import { Header } from "./Header";
import { ScheduleContent } from "./ScheduleContent";

import { Container, Schedules } from "./styles";
import { Scheduling } from "../../components/Scheduling";

export function Schedule() {
  const { theme } = useThemes();

  const Title =
    theme === "petland" ? (
      <span>
        <Pets /> Banho e Tosa
      </span>
    ) : (
      <span>
        <Vaccines /> Clínica Veterinária
      </span>
    );

  return (
    <BasePage
      pageTitle={Title}
      extendStyles={{
        main: {
          maxHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
        <Scheduling />
      <ScheduleProvider>
        <Container className={theme}>
          <Header />
          <Schedules>
            <Head />
            <ScheduleContent />
          </Schedules>
        </Container>
      </ScheduleProvider>
    </BasePage>
  );
}
