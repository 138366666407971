import moment from 'moment'

import { useSchedule } from '../../../hooks/ScheduleContext'

import { AddBlockButton } from './Buttons/AddBlockButton'
import { CalendarOptionsButton } from './Buttons/CalendarOptionsButton'
import { PreSchedulingButton } from './Buttons/PreSchedulingButton'
import { TaxiDogButton } from './Buttons/TaxiDogButton'
import { ServiceQueueButton } from './Buttons/ServiceQueueButton'

import {
  Container,
  Controls,
  DateBox,
  ButtonBox,
  ActionButton,
  Title,
} from './styles'
import { Slider } from './Slider'
import { HeaderDateSelect } from './HeaderDateSelect'
import { useThemes } from '../../../providers/theme'
import SelectGap from './SelectGap'

export function Header() {
  const { updateConfig, getConfig } = useSchedule()
  const { theme } = useThemes()

  const config = getConfig()

  const setToday = () => {
    const today = moment().format('YYYY-MM-DD')
    if (today === config.date) return
    updateConfig({ date: today })
  }

  return (
    <Container>
      <HeaderDateSelect />
      <Controls>
        <DateBox>
          <ActionButton size="100px" onClick={setToday}>
            Hoje
          </ActionButton>
          <Title>{moment(config.date).format('LL')}</Title>
          <Slider />
          <SelectGap />
        </DateBox>
        <ButtonBox>
          {theme === 'draMei' && <ServiceQueueButton />}
          {theme === 'petland' && <TaxiDogButton />}
          {theme === 'petland' && <AddBlockButton />}
          {theme === 'petland' && <PreSchedulingButton />}
          {theme === 'petland' && <CalendarOptionsButton />}
        </ButtonBox>
      </Controls>
    </Container>
  )
}
