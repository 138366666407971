import { TextAreaLabel } from "./TextAreaLabel";
import { Container, TextArea, TextAreaBox } from "./styles";

export function TextAreaRoot({
  children,
  label,
  style,
  onChange: onChangeTrigger = () => null,
  ...rest
}) {
  return (
    <Container aria-disabled={rest.disabled} style={style}>
      <TextAreaBox>
        <TextArea
          {...rest}
          onChange={(e) => onChangeTrigger(e.target.value)}
          placeholder=" "
        />
        <TextAreaLabel>{label}</TextAreaLabel>
      </TextAreaBox>
    </Container>
  );
}
