import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-content: flex-start;
`;

export const Title = styled.h3`
  width: 100%;
  display: flex;
  gap: 8px;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.scale[500]};
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PreviewContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;
