import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import { DRE_EXPENSES, DRE_GOALS } from "../../../store/subscription/constants";

import { useDre } from "../../../store/subscription/useDre";

import { BlockTable } from "../../../components/ui/BlockTable";
import { Drawer } from "../../../components/ui/Drawer";
import { Alert } from "../../../components/ui/Alert";

import { SubscriptionDREModel } from "../../models/subscription";
import { clearNumber } from "../../functions";
import { useIndicators } from "../../../store/subscription/useIndicators";


export default class SubscriptionDREController {
  setShowMonths = useDre.getState().actions.setShowMonths

  setPeriod = useDre.getState().actions.setPeriod
  setGoals = useDre.getState().actions.setGoals
  setExpenses = useDre.getState().actions.setExpenses

  clearConfig = useDre.getState().actions.clearConfig
  setConfigLoading = useDre.getState().actions.setConfigLoading
  setConfigSaving = useDre.getState().actions.setConfigSaving
  setConfigUnsaved = useDre.getState().actions.setUnsaved
  setConfigSaved = useDre.getState().actions.setSaved

  setIndicatorSubscribers = useIndicators.getState().actions.setSubscribers
  setIndicatorInvoicing = useIndicators.getState().actions.setInvoicing
  setIndicatorCancellations = useIndicators.getState().actions.setCancellations
  setIndicatorMargin = useIndicators.getState().actions.setMargin

  Alert = Alert.store.getState().actions
  Drawer = Drawer.store.getState().actions;
  BlockTable = BlockTable.store.getState().actions

  constructor() {
    this._name = "subscription-dre";
    this._blockTableName = this._name + '-block-table';
    this._configurationDrawerName = this._name + '-configuration-drawer';
    this._configurationTabsName = this._name + '-configuration-tabs';

    this._unsavedConfigurationsAlertName = `${this._name}-unsaved-configurations-alert`;
    this._unsavedGoalsAlertName = `${this._name}-unsaved-goals-alert`;

    this.MSubscriptionDRE = SubscriptionDREModel()
  }

  init = _.once(async (totalMonths = 3) => {
    this.setTotalMonths(totalMonths)
    this.BlockTable.register(this._blockTableName)
  })

  openConfigurationDrawer = () => {
    this.Drawer.isOpen(this._configurationDrawerName, true);
  };

  closeConfigurationDrawer = (force = false) => {
    const unsavedExpenses = useDre.getState().data.config.unsaved.expenses;
    const unsavedGoals = useDre.getState().data.config.unsaved.goals;
    const unsaved = _.union(unsavedExpenses, unsavedGoals);

    if (unsaved.length && !force) {
      this.Alert.isOpen(this._unsavedConfigurationsAlertName, true);
      return;
    }

    this.clearConfig()
    this.Drawer.isOpen(this._configurationDrawerName, false);
  };

  buildTable = (monthsDRE) => {
    if (!monthsDRE) return

    this.BlockTable.clearBlocks(this._blockTableName)
    this.buildTableBlock(monthsDRE.blocks)
    this.buildTableColumns(monthsDRE.columns)
    this.buildTableBlockColumns(monthsDRE.blocksColumns)
    this.buildTableBocksDetails(monthsDRE.blocksDetails)
    this.buildTableBocksDetailsColumns(monthsDRE.blocksDetailsColumns)
  };

  buildTableBlock = (blocks) => {
    _.forEach(blocks, (block) => {
      this.BlockTable.addBlocks(this._blockTableName, {
        id: block.id,
        type: block.type,
        value: block.name
      })
    });
  }

  buildTableColumns = (columns) => {
    this.BlockTable.addColumns(this._blockTableName, columns)
  }

  buildTableBlockColumns = (blocksColumn) => {
    _.forEach(blocksColumn, (columns, blockId) => {
      this.BlockTable.addBlockColumns(this._blockTableName, blockId, columns)
    });
  }

  buildTableBocksDetails = (bocksDetails) => {
    _.forEach(bocksDetails, (details, blockId) => {
      this.BlockTable.addBlockDetails(this._blockTableName, blockId, details)
    });
  }

  buildTableBocksDetailsColumns = (blocksDetailsColumns) => {
    _.forEach(blocksDetailsColumns, (detailColumns, blockId) => {
      this.BlockTable.addBlockDetailsColumns(this._blockTableName, blockId, detailColumns)
    });
  }

  setTotalMonths = async (total) => {
    this.setShowMonths(total)

    await this.getMonthsDRE()
  }

  getMonthsDRE = async () => {
    const monthsDRE = await this.findMonths()
    this.buildTable(monthsDRE)
  }

  findMonths = async (month = false) => {
    const monthList = !!month ? [month] : useDre.getState().data.months
    if (_.isEmpty(monthList)) return

    const monthsDRE = await this.MSubscriptionDRE.findMonths(monthList);
    return monthsDRE
  };

  findExpenses = async () => {
    const month = useDre.getState().data.config.period
    if (_.size(clearNumber(month)) !== 6) return
    if (!moment(month, 'MM/YYYY').isValid()) return

    const period = moment(month, 'MM/YYYY').format('YYYY-MM')

    this.setConfigLoading(true)

    const expenses = await this.MSubscriptionDRE.findExpenses(period);
    this.setExpenses(expenses, month)
    this.setConfigLoading(false)
  };

  findGoals = async () => {
    const month = useDre.getState().data.config.period
    if (_.size(clearNumber(month)) !== 6) return
    if (!moment(month, 'MM/YYYY').isValid()) return

    const period = moment(month, 'MM/YYYY').format('YYYY-MM')

    this.setConfigLoading(true)
    const goals = await this.MSubscriptionDRE.findGoals(period);

    this.setGoals(goals, month)
    this.setConfigLoading(false)
  };

  setConfigExpense = (expense, value) => {
    const period = useDre.getState().data.config.period

    const newExpense = {}
    newExpense[expense] = { value }

    this.setConfigUnsaved('expenses', period)
    this.setExpenses(newExpense, period)
  }

  setConfigGoal = (goal, value) => {
    const period = useDre.getState().data.config.period

    const newGoal = {}
    newGoal[goal] = { value }

    this.setConfigUnsaved('goals', period)
    this.setGoals(newGoal, period)
  }

  setDefaultGoals = () => {
    const period = useDre.getState().data.config.period
    const goals = useDre.getState().data.config.goals[period]

    const defaultGoals =  _.mapValues(goals, goal => ({ value: goal.default }));

    this.setGoals(defaultGoals, period)
    this.savePeriodGoalsConfig()
  }

  setDefaulExpenses = () => {
    const period = useDre.getState().data.config.period
    const expenses = useDre.getState().data.config.expenses[period]

    const defaultExpenses =  _.mapValues(expenses, expense => ({ value: expense.default }));

    this.setExpenses(defaultExpenses, period)
    this.savePeriodExpensesConfig()
  }

  setConfigPeriod = (period) => {
    this.setPeriod(period)
  }

  savePeriodExpensesConfig = async (e) => {
    if (!!e) e.preventDefault()
    this.setConfigSaving(true)

    const dreMonths = useDre.getState().data.months

    const configPeriod = useDre.getState().data.config.period
    const configExpenses = useDre.getState().data.config.expenses[configPeriod]

    const period = moment(configPeriod, 'MM/YYYY').format('YYYY-MM')

    const response = await Promise.all(
      Object.entries(configExpenses)
        .map(async ([key, values]) =>
          await this.MSubscriptionDRE.savePeriodExpenseConfig({
            period,
            value:values.value,
            id: DRE_EXPENSES[key].id,
          })
        )
    )

    this.setConfigSaving(false)

    if (!_.every(response, res => !!res)) {
      toast.error('Erro ao cadastrar despesas, tente novamente.', { theme: "colored" });
      return
    }

    if (dreMonths.includes(period)) this.getMonthsDRE()

    this.setConfigSaved('expenses', moment(period, 'YYYY-MM').format('MM/YYYY'))
    toast.success('Despesas cadastradas com sucesso.', { theme: "colored" });
  }

  savePeriodGoalsConfig = async (e) => {
    if (e) e.preventDefault()
    this.setConfigSaving(true)

    const configPeriod = useDre.getState().data.config.period
    const configGoals = useDre.getState().data.config.goals[configPeriod]

    const period = moment(configPeriod, 'MM/YYYY').format('YYYY-MM')

    const response = await Promise.all(
      Object.entries(configGoals)
        .map(async ([key, values]) =>
          await this.MSubscriptionDRE.savePeriodGoalConfig({
            period,
            value:values.value,
            id: DRE_GOALS[key].id,
          })
        )
    )

    this.setConfigSaving(false)

    if (!_.every(response, res => !!res)) {
      toast.error('Erro ao cadastrar metas, tente novamente.', { theme: "colored" });
      return
    }

    this.setIndicatorSubscribers(false)
    this.setIndicatorInvoicing(false)
    this.setIndicatorCancellations(false)
    this.setIndicatorMargin(false)

    this.setConfigSaved('goals', moment(period, 'YYYY-MM').format('MM/YYYY'))
    toast.success('Metas cadastradas com sucesso.', { theme: "colored" });
  }
}
