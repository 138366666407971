import React from 'react'
import NewPrescription from '../../molecules/NewPrescription'

import { Container } from './styles'

function Prescription({ appointmentId }) {
  return (
    <Container>
      <NewPrescription appointmentId={appointmentId} />
    </Container>
  )
}

export default Prescription
