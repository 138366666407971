import React, { useEffect } from 'react'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import API from '../services/api'
import BasePage from './basePage'
import { newCustomer } from '../services/customers'
import {clearNumber } from '../services/functions'

// Components
import ModalType from '../components/atoms/Modal'

function NovoCliente() {
  const history = useHistory()
  const { register, handleSubmit, unregister, watch, setValue, control } =
    useForm({})
  const [validateCpf, setValidateCpf] = React.useState(true)
  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [errorEmail, setErrorEmail] = React.useState(true)

  const [stateButtom, setStateButtom] = React.useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const { cpf, postalCode } = watch()

  const onSubmit = async (data) => {
    setStateButtom(true)
    const {
      cpf,
      firstname,
      lastname,
      email,
      cellphone,
      telephone,
      city,
      postalCode,
      street,
      complement,
      number,
      neighborhood,
      uf,
    } = data

    const payload = {
      cpf,
      firstname,
      lastname,
      email,
      cellphone,
      telephone,
      address: {
        city,
        postalCode,
        street,
        complement,
        number,
        neighborhood,
        uf,
      },
    }

    const valResult = await newCustomer(payload)

    if (valResult?.status === 201) {
      unregister()
      setError(false)
      history.push(`/clientes/resumo/${valResult?.data?.customerId}`)
    } else {
      setOpenModal(valResult?.data?.friendly_message)
      setError(true)
      setStateButtom(false)
    }
  }

  const handleCpf = async (val) => {
    try {
      const { data } = await API.post('customers/isCpfAvailable', {
        cpf: val,
      })
      setValidateCpf(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (cpf?.length > 10) {
      handleCpf(cpf)
    }
  }, [cpf])

  useEffect(()=>{
    const postalCodeNumbers = clearNumber(postalCode)
    if(!!postalCodeNumbers) onBlurCep(postalCodeNumbers)

  },[postalCode])

  function onBlurCep(cep) {
    if (cep?.length !== 8) {
      return
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue('street', data.logradouro)
        setValue('neighborhood', data.bairro)
        setValue('city', data.localidade)
        setValue('uf', data.uf)
      })
  }

  const regex = {
    maskEmail: (value) => {
      if (value) {
        const emailPattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return emailPattern.test(value)
      }
      return true
    },
  }

  return (
    <>
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        modalDelete={false}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasePage pageTitle="Novo Cliente">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h3>Dados Pessoais</h3>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  defaultValue=""
                  as={
                    <InputMask mask="999.999.999-99">
                      {(inputProps) => (
                        <TextField
                          name="cpf"
                          label="CPF"
                          fullWidth
                          variant="outlined"
                          required
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  }
                  name="cpf"
                />
                {!validateCpf && (
                  <p style={{ color: 'red' }}>Cliente já cadastrado.</p>
                )}
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="firstname"
                  label="Nome"
                  variant="outlined"
                  inputRef={register}
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="lastname"
                  label="Sobrenome"
                  variant="outlined"
                  inputRef={register}
                  required
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  defaultValue=""
                  as={
                    <InputMask mask="(99) 99999-9999">
                      {(inputProps) => (
                        <TextField
                          fullWidth
                          name="cellphone"
                          label="Telefone principal"
                          variant="outlined"
                          required
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  }
                  name="cellphone"
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  control={control}
                  defaultValue=""
                  as={
                    <InputMask mask="(99) 99999-9999">
                      {(inputProps) => (
                        <TextField
                          fullWidth
                          name="telephone"
                          label="Telefone de recado"
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  }
                  name="telephone"
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  placeholder="seuemail@seuemail.com"
                  type="email"
                  inputRef={register}
                  required={!errorEmail}
                  error={!errorEmail}
                  onBlur={({ target }) =>
                    setErrorEmail(regex.maskEmail(target.value))
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <h3>Endereço</h3>
            </Grid>

            <Grid container item spacing={3}>
              <Grid item xs={6}>
              <Controller
                control={control}
                as={
                  <InputMask mask="99999-999">
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="postalCode"
                        label="CEP"
                        variant="outlined"
                        required
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                }
                name="postalCode"
              />
              </Grid>
            </Grid>

            <Grid container item spacing={3}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="street"
                  label="Rua"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="number"
                  label="Número"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="complement"
                  label="Complemento"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="neighborhood"
                  label="Bairro"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="city"
                  label="Cidade"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="uf"
                  label="Estado"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item xs={3} style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={stateButtom}
                  style={{
                    background: '#CCD219',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    fontWeight: '700',
                    width: '138px',
                    marginRight: '20px',
                  }}
                >
                  Salvar
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push('/clientes/')}
                  style={{
                    border: '1px solid #CCD219',
                    color: '#CCD219',
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    width: '120px',
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </BasePage>
      </form>
    </>
  )
}

export default NovoCliente
