import { ShowerHead } from 'lucide-react'
import { Card } from './Card'
import { Bathtub } from '@phosphor-icons/react'
import { usePrinter } from '../../../../../../store/schedule/usePrinter'
import _ from 'lodash'
import { formatName } from '../../../../../../services/functions'

export function Service() {
  const servicesList = usePrinter(st => st.data.services)

  return (
    <>
      <div className="flex flex-col gap-4 w-full">
        {Object.entries(servicesList).map(([key, services]) => (
          <Card.Content key={key}>
            <Card.ContentTitle>
              <Bathtub className="h-4 w-4" weight="bold" />
              <span className="leading-none w-full">{formatName(services[0].employeeName)}</span>
            </Card.ContentTitle>
            <Card.ContentList className="gap-2 pt-2">
              {services.map((service) => (
                <Card.ContentListItem key={service.id} className="items-center gap-2 w-full flex pl-2">
                  <ShowerHead className="h-4 w-4" />
                  <span className="leading-none flex-1 w-full">{formatName(service.serviceName)}</span>
                </Card.ContentListItem>
              ))}
            </Card.ContentList>
          </Card.Content>
        ))}
      </div>
      <Card.Separator className="bg-black" />
    </>
  )
}
