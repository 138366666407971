import _ from "lodash";
import { toast } from "react-toastify";
import { usePet } from "../../store/customer/usePet";
import API from "../api";
import { formatBody } from "../formatBody/schedule";
import { format } from "../formatResponses/schedule";
import { useCustomer } from "../../store/customer/useCustomer";

export class Pet {
  setPetData = usePet((st) => st.actions.setPetData);

  setAttributesValues = usePet((st) => st.actions.setAttributesValues);

  constructor() {
    this.customerId = null;
  }

  setPet(petData) {
    this.setPetData(petData);
  }

  setCustomerId(id) {
    this.customerId = id;
  }

  async saveNewPet() {
    const { pet } = usePet.getState().data;
    const { customer } = useCustomer.getState().data;

    try {
      const { data } = await API.post("/pets/new", {
        customerId: customer.id,
        pet: formatBody.pet(pet),
      });
      const lastPetData = _.last(data.updated.petsObj);

      const lastPet = {
        pet: lastPetData,
        customer,
      };

      return format.pets([formatBody.lastPet(lastPet)]);
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async findPet(id) {
    try {
      const { data } = await API.get(`/pet/${id}`);
      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async findAttributes(attributes = false) {
    const defaultFindAttributes = [
      "Espécie",
      "Sexo",
      "Porte",
      "Raça",
      "Pelagem",
      "Castrado",
    ];
    const findAttributes = attributes || defaultFindAttributes;

    const findAttributesURI = findAttributes.map((attribute) =>
      API.get(`pet-attributes/${attribute}/values`)
    );

    try {
      const data = await Promise.all(findAttributesURI);

      const petAttributes = findAttributes.map((attribute, i) => ({
        label: attribute,
        data: data[i].data.label.values,
      }));

      this.setAttributesValues(petAttributes);
      return petAttributes;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }
}
