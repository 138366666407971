import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  opacity: 0;
  justify-content: space-between;
  background: #ccd219;
  border-radius: 7px;
  padding: 10px;
  color: #ffffff;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 250ms linear;
  transform: translate(-20%, 0);
  flex-wrap: wrap;
  &.active {
    transform: translate(0, 0);
    opacity: 1;
  }
`
export const PetInformationList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

export const PetInformationLine = styled.p`
  margin: 0;
  &.between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

export const PetTags = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  width: 100px;
  gap: 5px;
`
export const PetTag = styled.span`
  background: #ffffff;
  border-radius: 10px;
  color: #ccd219;
  font-weight: 400;
  font-size: 12px;
  padding: 0 5px;
`
export const PetInformationFooter = styled.div`
  margin-top: 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
`
