import { formatDistanceToNow } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

import { ActivityDescription, ActivityLabel, Container } from './styles'

export function Activity({ description, eventDate, userName }) {
  const activityDateToNow = formatDistanceToNow(new Date(eventDate), {
    locale: ptBR,
    addSuffix: true,
  })

  return (
    <Container>
      <ActivityLabel>
        <span>
          <strong>{userName}</strong> <em>{activityDateToNow}</em>
        </span>
      </ActivityLabel>
      <ActivityDescription disabled value={description} />
    </Container>
  )
}
