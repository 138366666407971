import _ from "lodash";
import { useParams } from "react-router-dom";

import BasePage from "../../basePage";

import { useCare } from "../../../store/service/veterinary/useCare";

import {
  VeterinaryAttachmentController,
  VeterinaryCareController,
  VeterinaryPrescriptionController,
  VeterinaryRequestController,
  VeterinaryServiceController,

} from "../../../services/controllers/VeterinaryCare";

import { Tabs } from "../../../components/ui/Tabs";

import Header from "./Header";
import { VeterinaryRecord } from "./VeterinaryRecord";
import { VeterinaryRequests } from "./VeterinaryRequests";
import { VeterinaryAttachments } from "./VeterinaryAttachments";
import { VeterinaryHistory } from "./VeterinaryHistory";
import { VeterinaryPrescription } from "./VeterinaryPrescription";
import { VeterinaryServices } from "./VeterinaryServices";
import { VeterinaryVaccines } from "./VeterinaryVaccines";

import { Title } from "./Title";

export function AtendimentoClinica() {
  const { orderId } = useParams();

  const CLVetCare = VeterinaryCareController(orderId)

  VeterinaryRequestController(orderId, CLVetCare._tabsLabels.request);
  VeterinaryAttachmentController(orderId, CLVetCare._tabsLabels.attachment);
  VeterinaryPrescriptionController(orderId, CLVetCare._tabsLabels.prescription, CLVetCare._medicinesId);
  VeterinaryServiceController(orderId, CLVetCare._tabsLabels.services);

  const care = useCare(st => st.data.care)

  const tabLabels = _.values(CLVetCare._tabsLabels)

  CLVetCare.init()

  if (!care) return null

  return (
    <BasePage
      pageTitle={<Title />}
      extendStyles={{
        main: {
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
      <Header />
      <Tabs.Root
        name={CLVetCare._tabsName}
        tabs={tabLabels}
      >
        <Tabs.Content tabName="Prontuário" style={{ height: "100%" }}>
          <VeterinaryRecord />
        </Tabs.Content>
        <Tabs.Content tabName="Vacinas" style={{ height: "100%" }}>
          <VeterinaryVaccines />
        </Tabs.Content>
        <Tabs.Content tabName="Anexos" style={{ height: "100%" }}>
          <VeterinaryAttachments />
        </Tabs.Content>
        <Tabs.Content tabName="Histórico" style={{ height: "100%" }}>
          <VeterinaryHistory />
        </Tabs.Content>
        <Tabs.Content tabName="Serviços" style={{ height: "100%" }}>
          <VeterinaryServices />
        </Tabs.Content>
        <Tabs.Content tabName="Receituário" style={{ height: "100%" }}>
          <VeterinaryPrescription />
        </Tabs.Content>
        <Tabs.Content tabName="Requisições" style={{ height: "100%" }}>
          <VeterinaryRequests />
        </Tabs.Content>
      </Tabs.Root>
    </BasePage>
  );
}
