import { VACCINATION_STATUS_FIELDS } from "../../../../../../../store/service/veterinary/constants";

import { VeterinaryVaccineController } from "../../../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../../../components/ui/Form/Input";

export function VaccineScheduledDate({ vaccine }) {
  const CLVetVaccine = VeterinaryVaccineController();

  if (!VACCINATION_STATUS_FIELDS[vaccine.status].includes("scheduledDate")) {
    return vaccine.scheduledDate || "";
  }

  return (
    <Input.Root
      label="Programação"
      mask="99/99/9999"
      value={vaccine?.scheduledDate}
      onChange={(scheduledDate) => {
        CLVetVaccine.setAssignedVaccineFieldsValue(vaccine.id, {
          scheduledDate,
        });
      }}
    />
  );
}
