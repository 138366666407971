import styled from 'styled-components'

export const DescriptionContentList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  li {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px 8px;
    & > * {
      flex: 1;
    }

    &:nth-child(odd) {
      background: ${(props) => props.theme.colors.gray[50]};
    }
  }
`

export const DescriptionContentFromToContent = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  flex: 1;
`

export const DescriptionContentToFrom = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 100%;
  flex: 1;
`

export const DescriptionContentToFromTo = styled.span`
  color: ${(props) => props.theme.colors.gray[400]};
  position: relative;
  &::before {
    content: '';
    width: 180%;
    height: 1px;
    background: currentColor;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`
export const DescriptionContentToFromArrow = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.gray[700]};
`

export const DescriptionContentToFromFrom = styled.span`
  color: ${(props) => props.theme.colors.scale[500]};
  font-weight: bold;
`
