import { SummaryBox } from './SummaryBox'
import { SummaryIcon } from './SummaryIcon'
import { SummaryRoot } from './SummaryRoot'
import { SummaryStatusIcon } from './SummaryStatusIcon'
import { SummarySubTitle } from './SummarySubTitle'
import { SummaryText } from './SummaryText'
import { SummaryTextContent } from './SummaryTextContent'
import { SummaryTitle } from './SummaryTitle'

export const Summary = {
  Root: SummaryRoot,
  Box: SummaryBox,
  Icon: SummaryIcon,
  StatusIcon: SummaryStatusIcon,
  TextContent: SummaryTextContent,
  Title: SummaryTitle,
  Text: SummaryText,
  SubTitle: SummarySubTitle,
}
