import { Dog } from "lucide-react";
import { HeaderIcon } from "./styles";

export function AccordionHeaderIcon({ icon: Icon = Dog, ...rest }) {
  return (
    <HeaderIcon {...rest}>
      <Icon size={22} />
    </HeaderIcon>
  );
}
