import styled from "styled-components";

export const Container = styled.header`
  grid-area: header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 100%;
  gap: 12px;
  padding: 0 12px;
`;

export const HeaderListStyle = styled.div``;

export const HeaderActions = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;
