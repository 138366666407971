export const MockAvailablesTimes = {
  availableTimes: [
    { time: '08:00', available: false },
    { time: '08:05', available: false },
    { time: '08:10', available: false },
    { time: '08:15', available: false },
    { time: '08:20', available: false },
    { time: '08:25', available: false },
    { time: '08:30', available: false },
    { time: '08:35', available: false },
    { time: '08:40', available: false },
    { time: '08:45', available: false },
    { time: '08:50', available: false },
    { time: '08:55', available: false },
    { time: '09:00', available: false },
    { time: '09:05', available: false },
    { time: '09:10', available: false },
    { time: '09:15', available: false },
    { time: '09:20', available: false },
    { time: '09:25', available: false },
    { time: '09:30', available: false },
    { time: '09:35', available: false },
    { time: '09:40', available: false },
    { time: '09:45', available: false },
    { time: '09:50', available: false },
    { time: '09:55', available: false },
    { time: '10:00', available: false },
    { time: '10:05', available: false },
    { time: '10:10', available: false },
    { time: '10:15', available: false },
    { time: '10:20', available: false },
    { time: '10:25', available: false },
    { time: '10:30', available: false },
    { time: '10:35', available: false },
    { time: '10:40', available: false },
    { time: '10:45', available: false },
    { time: '10:50', available: false },
    { time: '10:55', available: false },
    { time: '11:00', available: false },
    { time: '11:05', available: false },
    { time: '11:10', available: false },
    { time: '11:15', available: false },
    { time: '11:20', available: false },
    { time: '11:25', available: false },
    { time: '11:30', available: false },
    { time: '11:35', available: false },
    { time: '11:40', available: false },
    { time: '11:45', available: false },
    { time: '11:50', available: false },
    { time: '11:55', available: false },
    { time: '12:00', available: false },
    { time: '12:05', available: false },
    { time: '12:10', available: false },
    { time: '12:15', available: false },
    { time: '12:20', available: false },
    { time: '12:25', available: false },
    { time: '12:30', available: false },
    { time: '12:35', available: false },
    { time: '12:40', available: false },
    { time: '12:45', available: false },
    { time: '12:50', available: false },
    { time: '12:55', available: false },
    { time: '13:00', available: false },
    { time: '13:05', available: false },
    { time: '13:10', available: false },
    { time: '13:15', available: false },
    { time: '13:20', available: false },
    { time: '13:25', available: false },
    { time: '13:30', available: false },
    { time: '13:35', available: false },
    { time: '13:40', available: false },
    { time: '13:45', available: false },
    { time: '13:50', available: false },
    { time: '13:55', available: false },
    { time: '14:00', available: false },
    { time: '14:05', available: false },
    { time: '14:10', available: false },
    { time: '14:15', available: false },
    { time: '14:20', available: false },
    { time: '14:25', available: false },
    { time: '14:30', available: false },
    { time: '14:35', available: false },
    { time: '14:40', available: false },
    { time: '14:45', available: false },
    { time: '14:50', available: false },
    { time: '14:55', available: false },
    { time: '15:00', available: false },
    { time: '15:05', available: false },
    { time: '15:10', available: false },
    { time: '15:15', available: false },
    { time: '15:20', available: false },
    { time: '15:25', available: false },
    { time: '15:30', available: false },
    { time: '15:35', available: false },
    { time: '15:40', available: false },
    { time: '15:45', available: false },
    { time: '15:50', available: false },
    { time: '15:55', available: false },
    { time: '16:00', available: false },
    { time: '16:05', available: false },
    { time: '16:10', available: false },
    { time: '16:15', available: false },
    { time: '16:20', available: false },
    { time: '16:25', available: false },
    { time: '16:30', available: false },
    { time: '16:35', available: false },
    { time: '16:40', available: false },
    { time: '16:45', available: false },
    { time: '16:50', available: false },
    { time: '16:55', available: false },
    { time: '17:00', available: false },
    { time: '17:05', available: false },
    { time: '17:10', available: false },
    { time: '17:15', available: false },
    { time: '17:20', available: false },
    { time: '17:25', available: false },
    { time: '17:30', available: false },
    { time: '17:35', available: false },
    { time: '17:40', available: false },
    { time: '17:45', available: false },
    { time: '17:50', available: false },
    { time: '17:55', available: false },
    { time: '18:00', available: false },
    { time: '18:05', available: false },
    { time: '18:10', available: false },
    { time: '18:15', available: false },
    { time: '18:20', available: false },
    { time: '18:25', available: false },
    { time: '18:30', available: false },
    { time: '18:35', available: false },
    { time: '18:40', available: false },
    { time: '18:45', available: false },
    { time: '18:50', available: false },
    { time: '18:55', available: false },
    { time: '19:00', available: false },
    { time: '19:05', available: false },
    { time: '19:10', available: false },
    { time: '19:15', available: false },
    { time: '19:20', available: false },
    { time: '19:25', available: false },
    { time: '19:30', available: false },
    { time: '19:35', available: false },
    { time: '19:40', available: false },
    { time: '19:45', available: false },
    { time: '19:50', available: false },
    { time: '19:55', available: false },
    { time: '20:00', available: false },
    { time: '20:05', available: false },
    { time: '20:10', available: false },
    { time: '20:15', available: false },
    { time: '20:20', available: false },
    { time: '20:25', available: false },
    { time: '20:30', available: false },
    { time: '20:35', available: false },
    { time: '20:40', available: false },
    { time: '20:45', available: false },
    { time: '20:50', available: false },
    { time: '20:55', available: false },
    { time: '21:00', available: false },
  ],
}
