import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import API from '../services/api'
import { randomIntFromInterval } from '../services/functions'

const CrmContext = createContext({})

function CrmProvider({ children }) {
  const [tab, setTab] = useState('customersList')
  const [detailsCard, setDetailsCard] = useState(false)
  const [detailsCardId, setDetailsCardId] = useState(false)
  const [activities, setActivities] = useState(false)
  const [detailsmodalOpen, setDetailsmodalOpen] = useState(false)
  const [cards, setCards] = useState(false)
  const [stages, setStages] = useState(false)
  const listName = 'last-7-days'
  const [stageActions, setStageActions] = useState(false)

  const colorList = [
    [
      // "#006F71",
      // "#00A1A4",
      // "#1AD4D7",
      // "#3EFBFF",
      '#00A1A4',
    ],
    [
      // "#6A2F00",
      // "#9D4500",
      // "#D05D02",
      '#FF811E',
    ],
  ]

  const updateTab = useCallback((value) => {
    setTab(value)
  }, [])

  const getTab = useCallback(() => tab, [tab])

  const formatPetsInCards = (cards) =>
    cards.map((card) => {
      const pets = card.pets.map((pet) => pet.name)
      return { ...card, pets }
    })

  const addColorStage = (stages) =>
    stages.map((stage, index) => {
      const totalColors = colorList.length
      const colorBlock = index % totalColors
      const colorIndex = randomIntFromInterval(0, colorList[0].length - 1)
      const color = colorList[colorBlock][colorIndex]

      return { ...stage, color }
    })

  const findList = useCallback(async () => {
    const { data } = await API.get(`crm/kanbans/cards?listName=${listName}`)

    setCards(formatPetsInCards(data.cards))
    setStages(addColorStage(data.stages))
    setStageActions(data.stages[0].stageActions)
  }, [listName])

  const getStages = useCallback(() => stages, [stages])

  const getCards = useCallback(
    (code = false) => {
      if (code !== false) {
        return cards.filter((card) => card.stageCode === code)
      }
      return cards
    },
    [cards],
  )

  const getDetailsmodalOpen = useCallback(
    () => detailsmodalOpen,
    [detailsmodalOpen],
  )

  const closeDetailsmodalOpen = useCallback(() => {
    setDetailsmodalOpen(false)
  }, [setDetailsmodalOpen])

  const getDetailsCard = useCallback(() => detailsCard, [detailsCard])

  const updateCard = useCallback(
    (cardId, newData) => {
      setCards((prevCards) =>
        prevCards.map((card) => {
          if (cardId === card.cardId) {
            return { ...card, ...newData }
          }
          return card
        }),
      )
    },
    [setCards],
  )

  const findDetailsCard = useCallback(async () => {
    setDetailsCard(false)
    const { data } = await API.get(`crm/kanbans/card/${detailsCardId}`)

    setDetailsCard(data)
  }, [detailsCardId])

  const viewDetails = useCallback((cardId) => {
    setDetailsCardId(cardId)
    setDetailsmodalOpen(true)
  }, [])

  const findActivities = useCallback(async () => {
    setActivities(false)
    const { data } = await API.get(
      `crm/kanbans/card/${detailsCardId}/activities`,
    )
    setActivities(data)
  }, [detailsCardId])

  const updateActivities = useCallback(async () => {
    setActivities(false)
    const { data } = await API.get(
      `crm/kanbans/card/${detailsCardId}/activities`,
    )
    setActivities(data)
  }, [detailsCardId])

  const getActivities = useCallback(() => activities, [activities])

  const getDetailsCardId = useCallback(() => detailsCardId, [detailsCardId])

  const getCardById = useCallback(
    (id) => !!cards && cards.find((card) => card.cardId === id),
    [cards],
  )

  const getStageActions = useCallback(() => stageActions, [stageActions])

  const sendEvent = useCallback(
    async (eventBody, cardId = false) => {
      const id = cardId !== false ? cardId : detailsCardId
      const card = getCardById(id)

      await API.post(`crm/kanbans/card/${id}/event`, eventBody)

      if (eventBody.data.channel === 'whatsapp') {
        window
          .open(
            `https://web.whatsapp.com/send?phone=55${card.cellphone}&text=`,
            'WhatsApp',
          )
          .focus()
      }
    },
    [detailsCardId, getCardById],
  )

  useEffect(() => {
    if (detailsCardId) {
      findDetailsCard()
      findActivities()
    }
  }, [detailsCardId, findDetailsCard, findActivities])

  return (
    <CrmContext.Provider
      value={{
        updateTab,
        getTab,
        findList,
        getStages,
        getCards,
        updateCard,
        viewDetails,
        getDetailsmodalOpen,
        closeDetailsmodalOpen,
        findDetailsCard,
        getDetailsCard,
        getActivities,
        getDetailsCardId,
        sendEvent,
        updateActivities,
        getCardById,
        getStageActions,
      }}
    >
      {children}
    </CrmContext.Provider>
  )
}

function useCrm() {
  const context = useContext(CrmContext)
  if (!context) {
    throw new Error('useCrm must be used within an CrmProvider')
  }
  return context
}

export { useCrm, CrmProvider }
