import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  isWaiting: false,
  royaltyStatement: [],
  pagination: {
    page: 0,
    totalPages: 0,
    perPage: 10,
    totalDocuments: 0,
  },
};

export const useRoyaltyStatements = create((set) => ({
  data: defaultData,
  actions: {
    setRoyaltyStatement: (royaltyStatements, pagination) =>
      set((state) =>
        produce(state, (draft) => {
          const page =
            typeof pagination === "number" ? pagination : pagination.page;

          draft.data.royaltyStatement[page] = royaltyStatements;
          draft.data.pagination = pagination;

          draft.data.isLoading = false;
          draft.data.isWaiting = false;
        })
      ),
    setPaginationPage: (page) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pagination.page = page;
        })
      ),
    setPaginationPerPage: (perPage) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pagination.page = 0;
          draft.data.pagination.perPage = perPage;
          draft.data.royaltyStatement = [];
        })
      ),

    setLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isLoading = status;
          draft.data.isWaiting = status;
        })
      ),

    setWaiting: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isWaiting = status;
        })
      ),

    clearRoyaltyStatement: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.royaltyStatement = [];
        })
      ),
    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),
  },
}));
