import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  Container: {
    background: '#ffffff',
    border: '2px solid #ccc',
    borderRadius: '9px',
    padding: '8px 16px',
  },
  Title: {
    margin: '0',
    fontSize: '24px',
    color: ' #666666',
  },
  Line: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontSize: '16px',
  },
  StrongLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}))
