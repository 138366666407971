import { useContext } from "react"

import { Play } from 'lucide-react'
import { AuthContext } from "../../../../providers/auth"
import { PrintButton } from "../Printer/checklist/PrintButton"
import { Action } from "../../../ui/Action"

export function StartService({ onClick: callbackFn = () => { }, beforeClick = () => { }, token, ...rest }) {

  const { modules } = useContext(AuthContext)
  const browserPrinter = !!modules?.printer?.schedule
  const printerAssistent = !!modules?.printerV2?.schedule

  const StartServiceButton = printerAssistent ? PrintButton : Action.Root;

  const printAction = () => {
    if (browserPrinter && !printerAssistent) {
      window.open(
        `https://printer.petlandbrasil.com.br/?token=${token}`,
        'popup',
        'width=350, height=255, top=100, left=110, scrollbars=no',
      );
    }
    callbackFn();
  }

  return (
    <StartServiceButton {...rest} beforeClick={beforeClick} onClick={printAction}>
      <Action.Icon icon={Play} />
      INICIAR ATENDIMENTO
    </StartServiceButton>
  )
}
