import _ from "lodash";
import { useMedicine } from "../../../store/global/useMedicine";

import { FuseBuilder } from "../../builders";
import { MedicineModel } from "../../models/global";

export default class MedicineController {
  _name = 'medicines'
  _searchMedicineName = `${this._name}_search`
  _searchPresentationName = `${this._name}_search_presentation`
  _searchLimit = 10

  _findProps = {
    text: '',
    page: 0,
    perPage: 9999999999,
  }

  _searchSuggestionParser={
    id: 'id',
    value: 'name',
    presentations: 'presentations'
  }

  _searchOptions = {
    minMatchCharLength: 1,
    threshold: 0.03,
  }

  _searchMedicineOptions = {
    ...this._searchOptions,
    keys: [
      {
        name: 'name',
        weight: 2
      },
      {
        name: 'presentations',
        weight: 1
      }
    ]

  }

  _searchPresentationOptions = {
    ...this._searchOptions,
    minMatchCharLength: 1
  }

  setLoadingStatus = useMedicine.getState().actions.setLoadingStatus;

  setMedicines = useMedicine.getState().actions.setMedicines;
  setMedicineData = useMedicine.getState().actions.setMedicineData;
  setFoundMedicines = useMedicine.getState().actions.setFoundMedicines;
  setFoundPresentation = useMedicine.getState().actions.setFoundPresentation;

  constructor(id, props = {}) {
    this._ID = id

    this._findProps = _.pickBy({
      ...this._findProps,
      ...props
    }, item => !!item);

    this.BMedicineFuse = FuseBuilder(this._searchMedicineName, [], this._searchMedicineOptions);
    this.BPresentationFuse = FuseBuilder(this._searchPresentationName, [], this._searchPresentationOptions);

    this.MMedicine = MedicineModel();
  }

  init = _.once(async () => {
    await this.getMedicines()
  });

  getMedicines = async () => {
    this.setLoadingStatus(true)

    const medicines = await this.MMedicine.findMedicines(this._findProps);

    this.BMedicineFuse.setList(medicines)
    this.BMedicineFuse.init()

    this.setLoadingStatus(false)
  };

  deselectMedicine = () => {
    this.setMedicineData();
    this.setFoundPresentation()
  }

  selectMedicine = (medicineId) => {
    if (!medicineId) return

    const foundMedicines = useMedicine.getState().data.foundMedicines
    const medicineData = _.find(foundMedicines, ['id', medicineId]);

    this.BPresentationFuse.setList(medicineData.presentations)
    this.BPresentationFuse.init()

    this.setMedicineData(medicineData);
    this.setFoundPresentation(medicineData.presentations)

    return medicineData
  }

  selectCustomMedicine = (name) => {
    if (!name) return

    this.BPresentationFuse.setList([])
    this.BPresentationFuse.init()

    this.setMedicineData({ name });
    this.setFoundPresentation([])
  }

  searchMedicine = (term) => {
    const result = this.BMedicineFuse.search(term, this._searchLimit)
    this.setFoundMedicines(_.map(result, 'item'))
  }

  searchPresentation = (term) => {
    const result = this.BPresentationFuse.search(term)

    if (!_.isEmpty(result)) {
      this.setFoundPresentation(_.map(result, 'item'))
      return
    }

    const medicineData = useMedicine.getState().data.medicineData
    this.setFoundPresentation(medicineData.presentations)
  }
}
