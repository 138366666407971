import qs from "qs";
import { toast } from "react-toastify";

import api from "../../api";

import { formatResponses } from "../../formatResponses/veterinaryCare";
import { formatBody } from "../../formatBody/veterinaryCare";
import { getDepartmentValueName } from "../../functions";

export default class VeterinaryServiceModel {
  _department = "veterinary";

  constructor(orderId) {
    this._orderId = orderId;
  }

  setDepartment = (department) => {
    this._department = department;
  };

  getServices = async (petId) => {
    const servicesQuery = qs.stringify({
      pet: petId,
      department: getDepartmentValueName(this._department),
    });

    try {
      const { data } = await api.get(`/services?${servicesQuery}`);
      const formattedServices = formatResponses.services(data.results);

      return formattedServices;
    } catch (error) {
      console.error(error.response.data.friendly_message);
      return false;
    }
  };

  saveServices = async (services) => {
    if (!this._orderId) return false;
    try {
      const body = formatBody
        .services(services)
        .filter((item) => item.serviceId);

      const { data } = await api.post(`/dra-mei/appointment/${this._orderId}`, {
        services: body,
      });

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  deleteServices = async (serviceId) => {
    if (!this._orderId) return false;

    try {
      const { data } = await api.delete(`/dra-mei/appointment/${this._orderId}/remove-service/${serviceId}`, {
        serviceId
      });

      return data
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
