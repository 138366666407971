import { Save, Syringe, Undo2 } from "lucide-react";

import { formatName, getArticles } from "../../../../../../services/functions";

import { VeterinaryVaccineController } from "../../../../../../services/controllers/VeterinaryCare";

import { useVaccine } from "../../../../../../store/service/veterinary/useVaccine";
import { usePet } from "../../../../../../store/global/usePet";

import { Action } from "../../../../../../components/ui/Action";
import { Input } from "../../../../../../components/ui/Form/Input";
import { Select } from "../../../../../../components/ui/Form/Select";

import { Container, Title, FormActions, FormGroup } from "./styles";

export function NewVaccineForm() {
  const CLVetVaccine = VeterinaryVaccineController();

  const pet = usePet((st) => st.data.pet);

  const isUpdated = useVaccine((st) => st.data.updated);
  const isLoading = useVaccine((st) => st.data.loading);
  const isLoadingApply = useVaccine((st) => st.data.loadingApply);

  const vaccinesList = useVaccine((st) => st.data.vaccinesList);
  const vaccineValues = useVaccine((st) => st.data.vaccineValues);

  const protocolName = useVaccine((st) => st.data.newProtocolValues.name);

  const vaccineLabList = useVaccine((st) => st.data.vaccineLabList);
  const vaccineProtocolList = useVaccine((st) => st.data.vaccineProtocolList);

  if (!pet.id) return null

  const articles = getArticles(pet?.petAttributes.gender);
  const formTitle = `Novo Protocolo de Vacinação ${articles[1]} ${pet?.name}`;

  const newProtocolName = protocolName || "+ Criar Novo";

  const vaccineProtocols =
    vaccineProtocolList[vaccineValues.vaccineId] || false;

  return (
    <Container onSubmit={CLVetVaccine.assignVaccinationProtocol}>
      <Title>
        <Syringe /> {formTitle}
      </Title>
      <FormGroup>
        <Input.Root
          pickDate
          label="Primeira Aplicação"
          value={vaccineValues.date}
          onChange={(date) => {
            CLVetVaccine.setVaccineFieldsValue({ date });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Select.Root
          label="Vacina"
          value={vaccineValues.vaccineId}
          onChange={(vaccineId) => {
            CLVetVaccine.getVaccinesLabs(vaccineId);
            CLVetVaccine.getVaccinesProtocols(vaccineId);
            CLVetVaccine.setVaccineFieldsValue({ vaccineId });
          }}
        >
          {!!vaccinesList &&
            vaccinesList.map((vaccine) => (
              <Select.Option key={vaccine.id} value={vaccine.id}>
                {formatName(vaccine.name)}
              </Select.Option>
            ))}
        </Select.Root>
        <Input.Root
          label="Lote"
          value={vaccineValues.batch}
          onChange={(batch) => {
            CLVetVaccine.setVaccineFieldsValue({ batch });
          }}
        />
        <Select.Root
          label="Laboratório"
          value={vaccineValues.lab}
          disabled={!vaccineValues.vaccineId}
          onChange={(lab) => {
            CLVetVaccine.getVaccinesLabs();
            CLVetVaccine.setVaccineFieldsValue({ lab });
          }}
        >
          {!!vaccineLabList &&
            vaccineLabList.map((lab) => (
              <Select.Option key={lab} value={lab}>
                {formatName(lab)}
              </Select.Option>
            ))}
        </Select.Root>
      </FormGroup>
      <FormGroup>
        <Select.Root
          label="Protocolo"
          value={vaccineValues.protocolId}
          disabled={!vaccineValues.vaccineId}
          onChange={(protocolId) =>
            CLVetVaccine.setVaccineFieldsValue({ protocolId })
          }
        >
          <Select.Group>
            <Select.Option value="+">{newProtocolName}</Select.Option>
          </Select.Group>
          {!!vaccineProtocols &&
            vaccineProtocols.map((protocol) => (
              <Select.Option key={protocol.id} value={protocol.id}>
                {formatName(protocol.name)}
              </Select.Option>
            ))}
        </Select.Root>
      </FormGroup>
      <FormActions>
        <Action.Root
          outline
          type="button"
          status="danger"
          onClick={() => CLVetVaccine.closeNewVaccinationProtocolDrawer()}
        >
          <Action.Icon icon={Undo2} />
          Cancelar
        </Action.Root>
        <div>
          <Action.Root
            outline
            status="success"
            disabled={isUpdated || isLoading || isLoadingApply}
          >
            <Action.Icon icon={Save} isLoading={isLoading} />
            Salvar
          </Action.Root>
        </div>
      </FormActions>
    </Container>
  );
}
