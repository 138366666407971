import { Popover } from '../Popover'

import { ConfigContent as Container } from './styles'

export function ConfigContent({ children, name }) {
  const setPopover = Popover.store((st) => st.actions.setPopover)
  setPopover(name)

  return (
    <Popover.Root
      name={name}
      side="bottom"
      sideOffset={10}
      collisionPadding={22}
    >
      <Container>{children}</Container>
    </Popover.Root>
  )
}
