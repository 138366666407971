import qs from "qs";
import moment from "moment";

import { toast } from "react-toastify";

import api from "../../../api";

import { formatResponse } from "../../../formatResponses/reportVaccinations";

export default class ReportVaccinationsModel {
  getVaccinations = async (filter) => {
    const queryFilter = qs.stringify({
      fromDate: moment(filter.dateStart, "DD/MM/YYYY").format("YYYY-MM-DD"),
      toDate: moment(filter.dateEnd, "DD/MM/YYYY").format("YYYY-MM-DD"),
    });

    try {
      const { data } = await api.get(`vaccinations?${queryFilter}`);

      const formattedVaccinations = formatResponse.vaccinations(
        data.vaccinations
      );
      return formattedVaccinations;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  getVaccines = async () => {
    try {
      const { data } = await api.get("vaccines");

      const formattedVaccines = formatResponse.vaccines(data.vaccines);
      return formattedVaccines;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  getProtocols = async (vaccineId) => {
    try {
      const { data } = await api.get(`vaccine/${vaccineId}/protocols`);

      const formattedProtocols = formatResponse.protocols(data.protocols);
      return formattedProtocols;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  cancelVaccinations = async (vaccination) => {
    try {
      const { data } =
        await api.post(`record/${vaccination.recordId}/vaccination/${vaccination.id}/cancel
      `);

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
