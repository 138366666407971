import CountUp from 'react-countup'

import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons'
import { useAssessments } from '../../../../hooks/AssessmentsContext'

import {
  Container,
  StartsTagLike,
  StartsTagLikeBox,
  StartsTagName,
} from './styles'

export function StartsTag() {
  const { getTags } = useAssessments()
  const tags = getTags()

  return (
    <Container>
      {tags &&
        tags.map((tag) => (
          <li key={tag.tag}>
            <StartsTagName>{tag.tag}</StartsTagName>
            <StartsTagLikeBox>
              <StartsTagLike>
                <ThumbUpAltOutlined fontSize="inherit" />
                <span>
                  (
                  <CountUp end={tag.positiveCounter} />)
                </span>
              </StartsTagLike>
              <StartsTagLike>
                <ThumbDownAltOutlined fontSize="inherit" />
                <span>
                  (
                  <CountUp end={tag.negativeCounter} />)
                </span>
              </StartsTagLike>
            </StartsTagLikeBox>
          </li>
        ))}
    </Container>
  )
}
