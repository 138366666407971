import styled from 'styled-components'

export const Container = styled.div`
  width: 150px;
  .Mui-focused {
    color: ${(props) => props.theme.colors.primary}!important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary}!important;
  }
`
