import { InputTypeDate } from "./InputTypeDate";
import { InputTypeMask } from "./InputTypeMask";
import { InputTypeMoney } from "./InputTypeMoney";
import { InputTypeNumber } from "./InputTypeNumber";
import { InputTypeSuggestion } from "./InputTypeSuggestion";
import { InputTypeText } from "./InputTypeText";

export const InputType = {
  text: InputTypeText,
  mask: InputTypeMask,
  date: InputTypeDate,
  money: InputTypeMoney,
  number: InputTypeNumber,
  suggestion: InputTypeSuggestion,
};
