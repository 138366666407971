import styled, { css, keyframes } from "styled-components";
import { ChevronRight } from "lucide-react";
import { rgba } from "polished";

import { Header as RadixAccordionHeader } from "@radix-ui/react-accordion";

const IconDanceAnimation = keyframes`
  0%, 100%, 80% { transform:rotate(0deg)}
  85%{ transform:rotate(-10deg)}
  95%{ transform:rotate(10deg)}
`;

const IconPulseAnimation = keyframes`
    50% {
        opacity: .5
    }
`;

const IconHeartAnimation = keyframes`
  0%, 100%, 80%{ transform: scale(1)}
  85%{ transform: scale(1.15)}
  90%{ transform: scale(1.1)}
  95%{ transform: scale(1.2)}
`;

export const Container = styled(RadixAccordionHeader)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin: 0 0 10px 0;
  position: relative;
  width: 100%;
  color: ${(props) => props.theme.colors.scale[500]};
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  .button {
    display: flex;
    outline: none;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    font-size: 22px;
    padding: 0 0 4px 10px;
    color: currentColor;
    font-size: 22px;
    font-weight: bold;
    background: none;
    border: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid ${(props) => props.theme.colors.scale[400]};
    border-bottom: 1px solid
      ${(props) => rgba(props.theme.colors.scale[500], 0.5)};
  }
  &[data-disabled]{
    color: ${(props) => props.theme.colors.gray[400]};

    .button { cursor: default; }
    &::after { border-color: ${(props) => props.theme.colors.gray[300]}; }
  }
`;

export const HeaderChevron = styled(ChevronRight)`
  transition: all ${(props) => props.theme.animation.delay.fast} ease;
  width: fit-content;
  &.open {
    transform: rotate(90deg);
  }
  &.close {
    transform: rotate(0deg);
  }
`;

export const HeaderText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 18px;
`;

export const HeaderIcon = styled.span`
  display: flex;
  ${(props) =>
    props.animate === "dance" &&
    css`
      animation: ${IconDanceAnimation} 18s ease-in-out infinite;
    `}
  ${(props) =>
    props.animate === "pulse" &&
    css`
      animation: ${IconPulseAnimation} 18s ease-in-out infinite;
    `}
  ${(props) =>
    props.animate === "heart" &&
    css`
      animation: ${IconHeartAnimation} 5s ease-in-out infinite;
    `}
`;
