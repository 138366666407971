import styled, { keyframes } from 'styled-components'
import { Content, Arrow as RadixArrow, Close } from '@radix-ui/react-popover'

const slideUpFade = keyframes`
    from {
        opacity: 0;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`
export const Button = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: ${(props) => props.theme.colors.primary};
  height: 30px;
  transition: all 250ms ease;
  background: none;
  border: 1px solid transparent;
  &:hover {
    background: ${(props) => props.theme.colors.scale[50]};
    border-color: ${(props) => props.theme.colors.primary};
  }
  svg {
    font-size: 20px;
  }
`

export const Container = styled(Content)`
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  z-index: 20;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  &[data-state='open'] {
    animation-name: ${slideUpFade};
  }
`

export const Arrow = styled(RadixArrow)`
  fill: ${(props) => props.theme.colors.background};
`

export const Item = styled(Close)`
  font-size: 13px;
  line-height: 1;
  color: ${(props) => props.theme.colors.primary};
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 50px;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  border: none;
  background: transparent;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  transition: background 150ms ease;
  &:hover {
    color: ${(props) => props.theme.colors.background};
    background: ${(props) => props.theme.colors.primary};
  }
  &:last-child {
    border-bottom: none;
  }
  & > span {
    position: relative;
    z-index: 1;
  }
`
