import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  groups: [],
  role: false,
  user: false,
};

export const useUser = create((set) => ({
  data: defaultData,
  actions: {
    setUser: (user = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!user) return;
          draft.data.user = user
        })
      ),
    setUserGroups: (groups = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!groups) return;
          draft.data.groups = groups;
        })
      ),
    updateUserRole: (role = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!role) return;
          draft.data.role = role;
        })
      ),
    updateUser: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!values) return;
          if (!state.data.user) draft.data.user = {}

          Object.keys(values).forEach((userKey) => {
            draft.data.user[userKey] = values[userKey];
          });
        })
      ),
  },
}));
