import { Tooltip } from '../../../../components/ui/Tooltip'
import { limtShowNumber } from '../../../../services/functions'
import { Position, PositionBox, PositionTooltip } from './styles'

export function EmployeePosition({ value, tip }) {
  const tooltipName = `${tip}-${value}`
  const { setTooltip, isOpen } = Tooltip.store((st) => st.actions)

  setTooltip(tooltipName)

  return (
    <PositionBox tip>
      {!!tip && (
        <Tooltip.Root name={tooltipName} side="left" sideOffset={12}>
          <PositionTooltip>{value}º</PositionTooltip>
        </Tooltip.Root>
      )}
      <Position
        position={value}
        onMouseEnter={() => {
          isOpen({ name: tooltipName, open: true })
        }}
        onMouseLeave={() => {
          isOpen({ name: tooltipName, open: false })
        }}
      >
        {limtShowNumber(value, 99)}º
      </Position>
    </PositionBox>
  )
}
