import { BellRinging, Bookmarks, Star } from "phosphor-react";
import { PetBadges as Container } from "./styles";

export function PetCardBadges({ show, ...rest }) {
  if (!show) return null;
  if (!show?.alreadyClient && !show?.subscription && !show?.packages)
    return null;

  return (
    <Container {...rest}>
      {!!show?.alreadyClient && <Star size={18} weight="fill" />}
      {!!show?.subscription && <BellRinging size={18} weight="fill" />}
      {!!show?.packages && <Bookmarks size={18} weight="fill" />}
    </Container>
  );
}
