import React from 'react'
import styles from './styles'

function Option({ title, name, option, optionsState }) {
  const [options, setOptios] = optionsState

  const { box, header, line, inputRadio } = styles()

  const changeValue = (value) => {
    const newOptions = { ...options }
    newOptions[name] = value
    setOptios(newOptions)
  }

  return (
    <div className={box}>
      <h2 className={header}>{title}</h2>
      {option.map(({ item, value }) => (
        <label className={line} key={value}>
          <input
            type="radio"
            name={name}
            checked={options[name] === value}
            onChange={(e) => {
              changeValue(e.target.value)
            }}
            className={inputRadio}
            value={value}
          />
          {item}
        </label>
      ))}
    </div>
  )
}

export default Option
