import { useAccordion } from "../useAccordion";

import { useContext } from "../Context";

import { Container, HeaderText, HeaderChevron } from "./styles";
import { memo, useEffect } from "react";

export function AccordionHeaderRoot({
  children,
  itemValue,
  asChild = false,
  disabled = false,
  accordionName = false,
  ...rest
}) {
  const context = useContext();
  const Component = asChild ? Slot : "button";

  const toggleOpen = useAccordion((st) => st.actions.toggle);

  accordionName = !!accordionName ? accordionName : context.accordionName;

  const open = useAccordion((st) => st.data.accordions[accordionName].open);
  const isOpen = open.includes(itemValue);

  return (
    <Container {...rest}>
      <Component
        {...rest}
        className='button'
        onClick={() => !disabled && toggleOpen(accordionName, itemValue)}
      >
        <HeaderText>{children}</HeaderText>
        <HeaderChevron className={isOpen && !disabled ? "open" : "close"} />
      </Component>
    </Container>
  );
}
