import React from "react";

import { formatMoney } from "../../../../services/functions";


import { useRoyaltyStatements } from "../../../../store/reports/useRoyaltyStatements";

import { RoyaltyStatementController } from "../../../../services/controllers/reports";

import { Table } from "../../../../components/ui/Table";
import { Pagination } from "../../../../components/ui/Pagination";

export function RoyaltyStatementTable() {
  const CRoyaltyStatement = RoyaltyStatementController();

  const page = useRoyaltyStatements((st) => st.data.pagination.page);
  const isWaiting = useRoyaltyStatements((st) => st.data.isWaiting);
  const paginationData = useRoyaltyStatements((st) => st.data.pagination);

  const royaltyStatement = useRoyaltyStatements((st) => st.data.royaltyStatement);

  return (
    <Table.Root name={CRoyaltyStatement.tableName} config>
      <Table.Header
        columns={[
          "Data:100px",
          "Adquirente",
          "Valor:150px",
          "Bandeira Cartão:250px",
        ]}
      />

      <Table.Content isLoading={isWaiting}>
        {!!royaltyStatement[page] &&
          royaltyStatement[page].map((statement) => (
            <Table.Line key={statement.id}>
              <Table.Item>{statement.createdAt}</Table.Item>
              <Table.Item>{statement.acquirer.name}</Table.Item>
              <Table.Item>
                R$ {formatMoney(statement.netAmount)}
              </Table.Item>
              <Table.Item>{statement.payment.name}</Table.Item>
            </Table.Line>
          ))}
      </Table.Content>
      <Pagination.Root
        name={CRoyaltyStatement.paginationName}
        totalPages={paginationData.totalPages - 1}
        currentPage={paginationData.page}
        onChange={(page) => CRoyaltyStatement.setPage(page)}
      >
        <Pagination.Back />
        <Pagination.Pages />
        <Pagination.Forward />
      </Pagination.Root>
    </Table.Root>
  );
}
