import { Input } from "../../../../../../../../components/ui/Form/Input";
import { Select } from "../../../../../../../../components/ui/Form/Select";
import { TextArea } from "../../../../../../../../components/ui/Form/TextArea";

import { VeterinaryPrescriptionController } from "../../../../../../../../services/controllers/VeterinaryCare";
import { usePrescription } from "../../../../../../../../store/service/veterinary/usePrescription";

import {
  MEDICINE_DURATION,
  MEDICINE_FREQUENCY,
  MEDICINE_MEASURE,
} from "../../../../../../../../store/service/veterinary/constants";

import { FormGroup } from "../../styles";

export function SelfDescription() {
  const CLVetPrescription = VeterinaryPrescriptionController();
  const medicineProps = usePrescription((st) => st.data.prescription.newMedicine);

  const isContinuous = medicineProps.durationPeriod === "continuous";
  const isDisabled = !medicineProps.presentation;

  return (
    <>
      <FormGroup>
        <Input.Root
          money
          noSuffix
          decimals={1}
          label="Dosagem"
          disabled={isDisabled}
          value={medicineProps.dose}
          onChange={(dose) => CLVetPrescription.setNewMedicineProps({ dose })}
        />
        <Select.Root
          label="Medida"
          style={{ minWidth: "160px" }}
          disabled={isDisabled}
          value={medicineProps.measure}
          onChange={(measure) =>
            CLVetPrescription.setNewMedicineProps({ measure })
          }
        >
          {Object.keys(MEDICINE_MEASURE).map((measureKey) => (
            <Select.Option value={measureKey} key={measureKey}>
              {MEDICINE_MEASURE[measureKey][0]}
            </Select.Option>
          ))}
        </Select.Root>
      </FormGroup>
      <FormGroup>
        <Input.Root
          money
          noSuffix
          decimals={0}
          label="Frequência"
          disabled={isDisabled}
          value={medicineProps.frequency}
          onChange={(frequency) =>
            CLVetPrescription.setNewMedicineProps({ frequency })
          }
        />
        <Select.Root
          label="Período"
          disabled={isDisabled}
          value={medicineProps.frequencyPeriod}
          onChange={(frequencyPeriod) =>
            CLVetPrescription.setNewMedicineProps({ frequencyPeriod })
          }
        >
          {Object.keys(MEDICINE_FREQUENCY).map((frequencyKey) => (
            <Select.Option
              value={frequencyKey}
              key={`frequency-${frequencyKey}`}
            >
              {MEDICINE_FREQUENCY[frequencyKey][0]}
            </Select.Option>
          ))}
        </Select.Root>
      </FormGroup>
      <FormGroup>
        <Input.Root
          money
          noSuffix
          decimals={0}
          label="Duração"
          disabled={isDisabled || isContinuous}
          value={isContinuous ? "uso Contínuo" : medicineProps.duration}
          onChange={(duration) =>
            CLVetPrescription.setNewMedicineProps({ duration })
          }
        />

        <Select.Root
          label="Período"
          disabled={isDisabled}
          value={medicineProps.durationPeriod}
          onChange={(durationPeriod) =>
            CLVetPrescription.setNewMedicineProps({ durationPeriod })
          }
        >
          {Object.keys(MEDICINE_DURATION).map((durationKey) => (
            <Select.Option value={durationKey} key={`duration-${durationKey}`}>
              {MEDICINE_DURATION[durationKey][0]}
            </Select.Option>
          ))}
        </Select.Root>
      </FormGroup>
      <FormGroup>
        <TextArea.Root
          label="Observação"
          rows={5}
          disabled={isDisabled}
          value={medicineProps.note}
          onChange={(note) => CLVetPrescription.setNewMedicineProps({ note })}
        />
      </FormGroup>
    </>
  );
}
