import React, { createContext, useContext, useState } from 'react'
import uuid from 'react-uuid'
import API from '../services/api'

const PackgeContext = createContext({})

function PackgeProvider({ children }) {
  const [promotionId, setPromotionId] = useState(null)
  const [department, setDepartment] = useState('')
  const [promotionName, setPromotionName] = useState('')
  const [services, setServices] = useState([])
  const [discountType, setDiscountType] = useState('fixed_value')
  const [discountAmount, setDiscountAmount] = useState(0)

  const getPackageName = () => promotionName

  const setPackageName = (value) => {
    setPromotionName(value)
  }

  const getPackageServices = () => services
  const setPackageServices = (value) => {
    setServices(value)
  }

  const getPackageTotalServicesAmont = () =>
    services.reduce((acc, value) => {
      if (value.bonus) {
        return acc
      }
      return acc + value.originalPrice * value.quantity
    }, 0)

  const getPackageDiscount = () => discountAmount

  const setPackageDiscount = (value) => {
    setDiscountAmount(value)
  }

  const getPackageTotalDiscount = () =>
    discountType === 'fixed_value'
      ? discountAmount
      : getPackageTotalServicesAmont() * (discountAmount / 100)

  const setPackageDiscountType = (value) => {
    setDiscountType(value)
  }

  const getPackageDiscountType = () => discountType
  const getPackageFinalValue = () => {
    const finalValue =
      getPackageTotalServicesAmont() - getPackageTotalDiscount()
    return finalValue > 0 ? finalValue : 0
  }

  const addEmpityPackageService = () => {
    setServices((prev) => [
      ...prev,
      {
        serviceInputId: uuid(),
        bonus: false,
        name: '',
        originalPrice: '',
        price: '',
        quantity: 1,
      },
    ])
  }

  const removePackageService = (serviceInputId) => {
    const newServiceList = services.filter(
      (service) => service.serviceInputId !== serviceInputId,
    )
    setServices(newServiceList)
  }

  const recalculatePackageServiceValue = (serviceInputId) => {
    setServices((prev) =>
      prev.map((service) => {
        if (service.serviceInputId === serviceInputId) {
          const recalculatedValue = service.bonus
            ? 0
            : service.originalPrice * service.quantity
          return {
            ...service,
            price: recalculatedValue,
          }
        }
        return service
      }),
    )
  }

  const updatePackageService = (serviceInputId, updateData) => {
    setServices((prev) =>
      prev.map((service) => {
        if (service.serviceInputId === serviceInputId) {
          return {
            ...service,
            ...updateData,
          }
        }
        return service
      }),
    )
  }

  const splitGiftsOfServices = () => {
    const clearServices = services.map(({ id, quantity, bonus }) => ({
      serviceId: id,
      quantity,
      bonus,
    }))
    const giftsList = clearServices.filter((service) => service.bonus)
    const gifts = giftsList.map((service) => ({
      ...service,
      discountInPercentage: 100,
    }))
    const serviceConditions = clearServices.filter((service) => !service.bonus)

    return { gifts, serviceConditions }
  }

  const getPackage = async (id) => {
    const { data } = await API.get(`packages/rule/${id}`)
    console.log(data)
    const serviceList = data.services.map((service) => ({
      ...service,
      serviceInputId: uuid(),
    }))

    setPromotionId(data.package_id)
    setDepartment(data.department)
    setDiscountAmount(parseInt(data.package_discount.discountAmount, 10))
    setDiscountType(data.package_discount.discountType)
    setPackageName(data.promotionName)
    setPackageServices(serviceList)
  }

  const updatePackage = async (callback = false) => {
    const { gifts, serviceConditions } = splitGiftsOfServices()

    const cb = !callback ? () => null : (e) => callback(e)

    await API.put(`packages/rules/${promotionId}`, {
      promotionName,
      department,
      active: true,
      gifts,
      serviceConditions,
      discount: {
        discountType,
        discountAmount,
      },
    })

    if (callback) {
      cb(true)
    }
  }

  return (
    <PackgeContext.Provider
      value={{
        getPackage,
        getPackageName,
        setPackageName,
        getPackageServices,
        setPackageServices,
        getPackageDiscount,
        setPackageDiscount,
        getPackageDiscountType,
        setPackageDiscountType,
        addEmpityPackageService,
        updatePackageService,
        removePackageService,
        recalculatePackageServiceValue,
        getPackageTotalServicesAmont,
        getPackageTotalDiscount,
        getPackageFinalValue,
        updatePackage,
      }}
    >
      {children}
    </PackgeContext.Provider>
  )
}

function usePackge() {
  const context = useContext(PackgeContext)
  if (!context) {
    throw new Error('usePackge must be used within an PackgeProvider')
  }
  return context
}

export { usePackge, PackgeProvider }
