import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'

import { TextField, Grid, Button, Divider } from '@material-ui/core'
import API from '../../../services/api'

import Modal from '../../../components/atoms/Modal'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function Dados() {
  const classes = useStyles()
  const [store, setStore] = useState(false)
  const [openModal, setOpenModal] = useState(null)

  const { register, control, handleSubmit } = useForm()

  const closeModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    const getStore = async () => {
      const result = await API.get('store')
      setStore(result.data)
    }
    if (!store) {
      getStore()
    }
  }, [])

  const onSubmit = async (data) => {
    const {
      uf,
      cidade,
      cep,
      logradouro,
      numero,
      complemento,
      bairro,
      tel,
      whatsapp,
    } = data

    const updateData = {
      ...store,
      location: {
        uf,
        city: cidade,
        postalCode: cep,
        street: logradouro,
        number: numero,
        complement: complemento,
        neighborhood: bairro,
      },
      contact: {
        phoneNumber: tel,
        whatsapp,
      },
    }

    try {
      const result = await API.post('store', updateData)
      setOpenModal(result.data.message)
    } catch (err) {
      console.log(err)
    }
  }

  if (!store) {
    return null
  }
  return (
    <>
      <Modal
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        verification={false}
      />
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} xs={7}>
          <Grid item xs={12}>
            <h2>{store.name}</h2>
          </Grid>

          <Grid item xs={5}>
            <TextField
              fullWidth
              id="CNPJ"
              name="cnpj"
              inputRef={register}
              label="CNPJ"
              variant="outlined"
              placeholder=""
              defaultValue={store.cnpj}
              disabled
            />
          </Grid>
        </Grid>
        <Grid spacing={3} container xs={12}>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
            <h3>LOCALIZAÇÃO</h3>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={7}>
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="uf"
              name="uf"
              inputRef={register}
              label="UF"
              variant="outlined"
              placeholder=""
              defaultValue={store.location.uf}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="cidade"
              name="cidade"
              inputRef={register}
              label="Cidade"
              variant="outlined"
              placeholder=""
              defaultValue={store.location.city}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              control={control}
              defaultValue={store.location.postalCode}
              name="cep"
              as={
                <InputMask mask="99999-999">
                  {() => (
                    <TextField
                      fullWidth
                      id="cep"
                      name="cep"
                      inputRef={register}
                      label="CEP"
                      variant="outlined"
                      placeholder=""
                    />
                  )}
                </InputMask>
              }
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="logradouro"
              name="logradouro"
              inputRef={register}
              label="LOGRADOURO"
              variant="outlined"
              placeholder=""
              defaultValue={store.location.street}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="numero"
              name="numero"
              inputRef={register}
              label="NÚMERO"
              variant="outlined"
              placeholder=""
              defaultValue={store.location.number}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="complemento"
              name="complemento"
              inputRef={register}
              label="COMPLEMENTO"
              variant="outlined"
              placeholder=""
              defaultValue={store.location.complement}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="bairro"
              name="bairro"
              inputRef={register}
              label="BAIRRO"
              variant="outlined"
              placeholder=""
              defaultValue={store.location.neighborhood}
            />
          </Grid>
        </Grid>
        <Grid spacing={3} container xs={12}>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
            <h3>CONTATO</h3>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={7}>
          <Grid item xs={5}>
            <Controller
              control={control}
              defaultValue={store.contact.phoneNumber}
              name="tel"
              as={
                <InputMask mask="(99) 9999-9999">
                  {() => (
                    <TextField
                      fullWidth
                      id="tel"
                      name="tel"
                      inputRef={register}
                      label="TELEFONE"
                      variant="outlined"
                      placeholder=""
                    />
                  )}
                </InputMask>
              }
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              control={control}
              defaultValue={store.contact.whatsapp}
              name="whatsapp"
              as={
                <InputMask mask="(99) 99999-9999">
                  {(inputProps) => (
                    <TextField
                      fullWidth
                      name="whatsapp"
                      label="WHATSAPP"
                      variant="outlined"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              }
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>
        </Grid>
      </form>
    </>
  )
}

export default Dados
