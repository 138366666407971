import { CustomerController } from '../../../../../../../services/controllers/scheduling'
import { useCustomer } from '../../../../../../../store/customer/useCustomer'

import { Input } from '../../../../../../ui/Form/Input'

import { Container } from './styles'

export function FindClient() {
  const CLCustomer = CustomerController()
  const customer = useCustomer((st) => st.data.customer)

  return (
    <Container>
      <Input.Root
        label="CPF"
        mask="999.999.999-99"
        style={{ minWidth: '170px' }}
        value={customer.cpf}
        onChange={(cpf) => {
          cpf = cpf.replace(/\D/g, '')
          CLCustomer.setCustomerData({ cpf })
          if (cpf.length === 11) {
            CLCustomer.findCustomer()
          }
        }}
      />
    </Container>
  )
}
