import { SubscriptionDetails } from "../Drawer/SubscriptionDetails";
import { AlertCancelSubscription } from "./AlertCancelSubscription";
import { AlertChangeCard } from "./AlertChangeCard";
import { AlertChangePlan } from "./AlertChangePlan";
import { AlertCycleAppointments } from "./AlertCycleAppointments";
import { AlertPauseSubscription } from "./AlertPauseSubscription";

export function Alerts() {
  return (
    <>
      <AlertCancelSubscription />
      <AlertChangeCard />
      <AlertChangePlan />
      <AlertCycleAppointments />
      <SubscriptionDetails />
      <AlertPauseSubscription />
    </>
  )
}
