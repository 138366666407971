import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    overflowY: 'auto',
    width: '100%',
    margin: '0',
    paddingBottom: '60px',
  },
  footer: {
    padding: '30px',
    background: '#D7D7D7',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancel: {
    background: '#FFFFFF',
    border: '1px solid #CCD219',
    borderRadius: '5px',
    color: '#CCD219',
    padding: '15px 40px',
    margin: '0 10px',
    cursor: 'pointer',
  },
  save: {
    background: '#CCD219',
    border: '1px solid #CCD219',
    borderRadius: '5px',
    color: '#ffffff',
    padding: '15px 40px',
    margin: '0 10px',
    cursor: 'pointer',
  },
  disabled: {
    background: '#ccc',
    border: '1px solid #ccc',
    borderRadius: '5px',
    color: '#999999',
    padding: '15px 40px',
    margin: '0 10px',
  },
}))
