import { createContext, useContext as useReactContext } from "react";

const Context = createContext({});

function AccordionProvider({ children, ...rest }) {
  return <Context.Provider value={rest}>{children}</Context.Provider>;
}

function useContext() {
  const context = useReactContext(Context);
  if (!context) {
    throw new Error("use must be used within an Provider");
  }
  return context;
}

export { useContext, AccordionProvider };
