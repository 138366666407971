import { useContext } from "../Context";
import { useListPreview } from "../useListPreview";

import { ListContentPlaceholder } from "./ListContentPlaceholder";

import { ListContentSmallGrid } from "./ListContentSmallGrid";
import { ListContentTable } from "./ListContentTable";
import { ListContentBigGrid } from "./ListContentBigGrid";

export function ListContent() {
  const { listPreviewName } = useContext();

  const listStyle = useListPreview((st) => st.data.lists[listPreviewName].listStyle);
  const totalItens = useListPreview((st) => st.data.lists[listPreviewName].data.length);

  if (!totalItens) return <ListContentPlaceholder />;
  if (listStyle === "smallGrid") return <ListContentSmallGrid />;
  if (listStyle === "bigGrid") return <ListContentBigGrid />;
  if (listStyle === "list") return <ListContentTable />;

  return null;
}
