import { RecordsContentsBodyExamination } from "./RecordsContentsBodyExamination";
import { RecordsContentsDiagnosis } from "./RecordsContentsDiagnosis";
import { RecordsContentsGeneralInformation } from "./RecordsContentsGeneralInformation";
import { RecordsContentsGeneralPhysicalExamination } from "./RecordsContentsGeneralPhysicalExamination";
import { RecordsContentsVitalSigns } from "./RecordsContentsVitalSigns";

export const RecordsContents = {
  VitalSigns: RecordsContentsVitalSigns,
  BodyExamination: RecordsContentsBodyExamination,
  Diagnosis: RecordsContentsDiagnosis,
  GeneralInformation: RecordsContentsGeneralInformation,
  GeneralPhysicalExamination: RecordsContentsGeneralPhysicalExamination,
};
