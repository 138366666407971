import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  border-radius: 10px;
  overflow: hidden;
  grid-row: span 2 / span 2;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 210px);
`

export const Concorrentes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
  padding: 20px;
`

export const ConcorrenteGoal = styled.div`
  max-width: 100%;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-items: center;
  flex-direction: column;
`
export const ConcorrenteGoalPercent = styled.span`
  display: block;
`
export const ConcorrenteGoalBar = styled.div`
  height: 12px;
  background: ${(props) => props.theme.colors.gray[200]};
  border-radius: 999999px;
  width: 100%;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    left: 0;
    top: 0;
    display: flex;
    height: 100%;
    background: #0f0;
    border-radius: 999999px;
    width: ${(props) => props.total}%;
    transition: all 250ms linear;
  }
`
export const Concorrente = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-end;
`
