import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

// material.ui
import {
  TextField,
  Grid,
  Divider,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import BasePage from '../../../pages/basePage'
import API from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function NewAccessProfile() {
  const history = useHistory()
  const { register, handleSubmit } = useForm()

  const [sw, setSwitch] = useState(false)
  const handleChangeSw = () => {
    setSwitch(!sw)
  }

  const onSubmit = async (data) => {
    try {
      await API.post('employees/groups/new', {
        name: data.nome,
        description: data.description,
        active: sw,
      })
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const classes = useStyles()

  return (
    <BasePage pageTitle="Novo Perfil do Acesso">
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="name"
              label="Nome"
              name="nome"
              inputRef={register}
              variant="outlined"
              fullWidth
              placeholder="Nome Completo"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="description"
              label="Descrição"
              name="description"
              inputRef={register}
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              defaultValue="Descrição do perfil de acesso"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={sw}
                  inputRef={register}
                  onChange={handleChangeSw}
                  color="primary"
                  name="ativo"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Ativo"
            />
          </Grid>
        </Grid>

        <Divider className={classes.bottom} />

        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push('/profissionais')}
            style={{
              border: '1px solid #CCD219',
              color: '#CCD219',
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '120px',
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </form>
    </BasePage>
  )
}

export default NewAccessProfile
