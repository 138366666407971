import { ScheduleController } from "../../../../../services/controllers/scheduling";
import { Steps } from "../../../../ui/Steps";
import { Tabs } from "../../../../ui/Tabs";

import { FindClient } from "./Client/FindClient";
import { Pets } from "./Client/Pets";
import { New } from "./New";

export function SelectCustomer() {
  const CLScheduling = ScheduleController();

  return (
    <Steps.Content name={CLScheduling.stepsName} step="Selecione o cliente">
      <Tabs.Root
        name={`${CLScheduling.schedulingName}-tabs`}
        tabs={["Cliente", "Novo Cliente", "Novo Pet"]}
      >
        <Tabs.Content tabName="Cliente">
          <FindClient />
          <Pets />
        </Tabs.Content>
        <Tabs.Content tabName="Novo Cliente">
          <New.Client />
        </Tabs.Content>
        <Tabs.Content tabName="Novo Pet">
          <New.Pet />
        </Tabs.Content>
      </Tabs.Root>
    </Steps.Content>
  );
}
