import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 15px 0;

  & input {
    padding: 15px 14px;
    color: #666666;
  }
`

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
`
