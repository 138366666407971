import _ from 'lodash'
import { PrintImage } from '../../../../../services/builders/PrintImage'
import { Action } from '../../../../ui/Action'
import { useCallback, useMemo } from 'react'

export function PrintButton({ onClick: callbackFn = () => { }, beforeClick = () => { }, children, ...rest }) {
  const printImage = useMemo(() => new PrintImage({
    querySelector: '[role="print-page"]',
    maxWidth: 572,
    multiple: 8
  }), [])

  const printAction = useMemo(() => _.debounce(async () => {
    beforeClick();
    await printImage.printFn()
    callbackFn()
  }, 1000), [printImage, callbackFn])

  return (
    <div className='inline-flex'>
      <Action.Root
        {...rest}
        onClick={() => printAction()}
      >
        {children}
      </Action.Root>
    </div>
  )
}
