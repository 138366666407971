import { memo } from "react";
import _ from "lodash";
import { Ban, RotateCcw, Trash2 } from "lucide-react";

import { VACCINATION_PROTOCOL_STATUS_ACTIONS } from "../../../../../../store/service/veterinary/constants";
import { isAbleByStatus } from "../../../../../../services/functions";

import { useUser } from "../../../../../../store/global/useUser";
import { useVaccine } from "../../../../../../store/service/veterinary/useVaccine";
import { useCare } from "../../../../../../store/service/veterinary/useCare";

import { VeterinaryVaccineController } from "../../../../../../services/controllers/VeterinaryCare";

import { Action } from "../../../../../../components/ui/Action";

import { Actions } from "../styles";

export const VaccinationActions = memo(({ assignedProtocol }) => {
  const CLVetVaccine = VeterinaryVaccineController();

  const userGroup = useUser(st => st.data.user.group);
  const careStatus = useCare(st => st.data.care.status)
  const assignedVaccines = useVaccine((st) => st.data.assignedVaccines);

  if (!assignedProtocol) return null;

  const isAble = isAbleByStatus(careStatus,CLVetVaccine._name)

  const someApplied = _.some(assignedVaccines[assignedProtocol.id], {
    status: "applied",
  });

  const statusActions =
    VACCINATION_PROTOCOL_STATUS_ACTIONS[assignedProtocol.status];

  const isVeterinary = userGroup?.type === 'veterinary' || false;

  const disableResume = !statusActions.includes("resume");
  const disableInterrupt = !statusActions.includes("interrupt") || !someApplied;
  const disableDelete = !statusActions.includes("delete") || someApplied;

  return (
    <Actions>
      <Action.Root
        status="600"
        type="button"
        disabled={!isVeterinary || disableInterrupt || !isAble}
        onClick={() => CLVetVaccine.interruptVaccinationProtocol()}
      >
        <Action.Icon icon={Ban} size={22} />
        Interromper
      </Action.Root>
      <Action.Root
        add
        type="button"
        status="warning"
        animateHover="reverseSpin"
        disabled={!isVeterinary || disableResume || !isAble}
        onClick={() => CLVetVaccine.resumeVaccinationProtocol()}
      >
        <Action.Icon icon={RotateCcw} size={22} />
        Retomar
      </Action.Root>
      <Action.Root
        status="danger"
        type="button"
        disabled={!isVeterinary || disableDelete || !isAble}
        onClick={() => CLVetVaccine.removeVaccinationProtocol()}
      >
        <Action.Icon icon={Trash2} size={22} />
        Excluir
      </Action.Root>
    </Actions>
  );
});
