import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import API from '../services/api'

// Componentes
import Service from '../components/organisms/Service'
import NewCategory from '../components/organisms/NewCategory'

function Servicos() {
  const [stateValue, setStateValue] = useState(0)
  const [getService, setGetService] = useState()
  const [serviceFilter, setServiceFilter] = useState()
  const [categories, setCategories] = useState()
  const history = useHistory()

  const handleGetService = (query) => {
    const serviceResult = getService.filter(
      (el) => el.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
    )
    return setServiceFilter(serviceResult)
  }

  const getServices = async (text = '') => {
    try {
      const { data } = await API.get(`services/search?text=${text}`)
      setGetService(data.results)
      setServiceFilter(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getCategories = async () => {
    try {
      const { data } = await API.get('services/categories/search')
      setCategories(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getServices()
    getCategories()
  }, [])

  return (
    <>
      {stateValue === 0 && (
        <Service
          service={serviceFilter}
          category={categories}
          stateValue={setStateValue}
          getServices={getServices}
          getCategories={getCategories}
          handleGetService={handleGetService}
        />
      )}
      {stateValue === 1 && history.push('/servicos/new', { categories })}
      {stateValue === 2 && <NewCategory stateValue={setStateValue} />}
    </>
  )
}

export default Servicos
