import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import {
  Alert,
  AlertTitle,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material'
import API from '../../../../services/api'
import { Container, Label, CancelButton, SaveButton, TextError } from './styles'
import { BlockEmployeeContext } from '../../../../providers/blockEmployee'
import DateInput from './DateInput'
import Options from './Options'
import CheckBox from './CheckBox'
import OfficeHours from './OfficeHours'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 25px;
`

const DescriptionInput = styled.textarea`
  border: 1px solid gray;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 1rem;
  resize: vertical;
`

function DescriptionField({ label, value, onChange }) {
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <DescriptionInput value={value} onChange={onChange} />
    </InputWrapper>
  )
}

function TemporaryBlock({ setBlock, getCalendar }) {
  const {
    employeeId,
    setEmployeeId,
    setToEmployeeId,
    postBlockEmployee,
    postBlockToEmployee,
    isChecked,
    setIsChecked,
    date,
    setDate,
    dates,
    setDates,
    description,
    setDescription,
    selectedOption,
    valid,
    selectedDays,
    error,
    success,
    setSuccess,
  } = useContext(BlockEmployeeContext)
  const [employee, setEmployee] = useState([])

  const handleDateChange = (value) => {
    setDates(value)
  }

  const today = new Date()
  const maxDate = new Date()
  maxDate.setDate(today.getDate() + 60)
  const minDate = new Date()
  minDate.setDate(today.getDate() + 2)

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  function handleCheckboxChange() {
    setIsChecked(!isChecked)
  }

  function handleChange(event) {
    setDate(event.target.value)
  }

  const getProf = async () => {
    try {
      const { data } = await API.get(
        'employees/search?status=ATIVO&executesService=true',
      )
      const results = data?.results?.filter(
        (item) => item.department === 'Banho e Tosa',
      )
      setEmployee(results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getProf()
  }, [])

  return (
    <Container>
      <Snackbar
        Snackbar
        open={success}
        onClose={() => {
          setSuccess(false)
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="success">
            <AlertTitle>Sucesso</AlertTitle>
            Bloqueio criado com sucesso!
          </Alert>
        </Stack>
      </Snackbar>

      <TextField
        id="name"
        select
        fullWidth
        label="Profissional"
        variant="outlined"
        required
        onChange={({ target }) => setEmployeeId(target.value)}
      >
        {employee
          // .filter((time) => time.available)
          ?.map((option) => (
            <MenuItem
              key={option?.employeeId}
              value={option?.employeeId}
              selected={option?.employeeId}
            >
              {option.name}
            </MenuItem>
          ))}
      </TextField>

      <Options />
      {selectedOption === 'specific-date' ? (
        <TextField
          id="date"
          label="Data"
          type="date"
          style={{ width: '48%', marginRight: '4%' }}
          value={date}
          variant="outlined"
          size="small"
          onChange={handleChange}
          InputProps={{
            style: { padding: '5px 0px', color: '#666666' },
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      ) : (
        <div>
          <DateInput
            startDate={dates.start}
            endDate={dates.end}
            onChange={handleDateChange}
          />
          <p
            style={{
              margin: '0',
              marginTop: '-20px',
              color: '#000000',
              fontWeight: '400',
              fontSize: '14px',
            }}
          >
            limite de 60 dias e limite de 2 dias
          </p>
        </div>
      )}

      {selectedOption === 'week-day' && <CheckBox />}

      <OfficeHours validation={isChecked} />

      <label style={{ color: '#666666' }}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        BLOQUEAR O DIA INTEIRO
      </label>

      <DescriptionField
        label="Descrição"
        value={description}
        onChange={handleDescriptionChange}
      />

      {error?.availableEmployees?.length > 0 ? (
        <div>
          <TextError>
            {error?.friendly_message} <br />
            Deseja alterar os agendamentos para outro profissional?
          </TextError>
          <TextField
            id="toEmployeeId"
            select
            fullWidth
            label="Profissional"
            variant="outlined"
            style={{ width: '48%', marginRight: '4%' }}
            onChange={({ target }) => {
              setToEmployeeId(target.value)
            }}
          >
            {error?.availableEmployees
              // .filter((time) => time.available)
              ?.map((option) => (
                <MenuItem
                  key={option?._id}
                  value={option?._id}
                  selected={option?._id}
                >
                  {option.name}
                </MenuItem>
              ))}
          </TextField>

          <CancelButton
            style={{ padding: '19px 38px' }}
            onClick={() => postBlockToEmployee(getCalendar, setBlock)}
          >
            ALTERAR
          </CancelButton>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CancelButton onClick={() => setBlock(false)}>CANCELAR</CancelButton>
          {selectedOption === 'specific-date' && (
            <SaveButton
              onClick={() => {
                postBlockEmployee(getCalendar, setBlock)
              }}
              disabled={!employeeId}
            >
              SALVAR BLOQUEIO
            </SaveButton>
          )}
          {selectedOption === 'specific-period' && (
            <SaveButton
              onClick={() => postBlockEmployee(getCalendar, setBlock)}
              disabled={
                !employeeId || dates.start === '' || dates.end === '' || !valid
              }
            >
              SALVAR BLOQUEIO
            </SaveButton>
          )}
          {selectedOption === 'week-day' && (
            <SaveButton
              onClick={() => {
                postBlockEmployee(getCalendar, setBlock)
              }}
              disabled={
                !employeeId ||
                dates.start === '' ||
                dates.end === '' ||
                !valid ||
                selectedDays.length === 0
              }
            >
              SALVAR BLOQUEIO
            </SaveButton>
          )}
        </div>
      )}
    </Container>
  )
}

export default TemporaryBlock
