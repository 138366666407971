import { get, useTable } from "./useTable";

import {
  TableConfigItem,
  TableConfigCheck,
  TableConfigCheckIndicator,
  TableConfigLabel,
} from "./styles";
import { useTableContext } from "./TableContext";
import _ from "lodash";

export function TableConfig() {
  const { tableName } = useTableContext();
  const data = useTable((st) => st.data);
  const updateColumns = useTable((st) => st.actions.updateColumns);

  const columnsList = get.columns(data.columns[tableName]);

  const toggleColumn = (name) => {
    const updatedColumns = columnsList.map((column) => {
      if (column.name === name) {
        return { ...column, hidden: !column.hidden };
      }
      return column;
    });

    updateColumns(tableName, updatedColumns);
  };

  const showerCount = _.size(_.filter(columnsList, column => !column.hidden && !!column.name));
  const oneShow = showerCount === 1


  return (
    <>
      {columnsList
        .filter((column) => !_.isEmpty(column.name.trim()))
        .map((column) => (
          <TableConfigItem key={`config-${tableName}-${column.name}`}>
            <TableConfigCheck
              id={`${tableName}-${column.name}`}
              disabled={oneShow && !column.hidden}
              checked={!column.hidden}
              onCheckedChange={() => {
                toggleColumn(column.name);
              }}
            >
              <TableConfigCheckIndicator />
            </TableConfigCheck>
            <TableConfigLabel htmlFor={`${tableName}-${column.name}`}>
              {column.name}
            </TableConfigLabel>
          </TableConfigItem>
        ))}
    </>
  );
}
