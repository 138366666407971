import { X } from "lucide-react";
import { DrawerControl } from "./DrawerControl";
import { useDrawer } from "../useDrawer";

export function DrawerControlClose({ callback, ...rest }) {
  const isOpen = useDrawer((st) => st.actions.isOpen);

  const closeDrawer = () => {
    if (callback) {
      callback();
      return;
    }
    isOpen(rest.name, false);
  };

  return (
    <DrawerControl {...rest} onClick={closeDrawer}>
      <X size={20} />
    </DrawerControl>
  );
}
