import moment from "moment";

import { formatName } from '../functions'

function formatRoyalty(royalty) {
  const acquirer = {
    taxId: !!royalty?.acquirer? royalty.acquirer : '',
    name: !!royalty?.acquirer? formatName(royalty.acquirer.name) : '',
    nickname: !!royalty?.acquirer? formatName(royalty.acquirer.nickname) : '',
  }

  const payment = {
    name: !!royalty?.paymentScheme? formatName(royalty.paymentScheme.name) : '',
    code: !!royalty?.paymentScheme? royalty.paymentScheme.code : '',
    type: !!royalty?.paymentScheme? royalty.paymentScheme.type : '',
  }

  return {
    id: royalty._id,
    createdAt: moment(royalty.settlementDate).format('DD/MM/YYYY'),
    amountToPay: royalty?.amountToPay || 0,
    netAmount: royalty?.netAmount || 0,
    acquirer,
    payment
  };
}

function formatPagination(pagination) {
  return {
    page: pagination.pagina ,
    totalPages: pagination.totalPaginas,
    perPage: pagination.porPagina,
    totalDocuments: pagination.totalDocumentos,
    full: pagination,
  };
}

export const formatResponse = {
  pagination: (pagination) => formatPagination(pagination),
  royalty: (royalty) => formatRoyalty(royalty),
  royalties: (royalties) => {
    if (!royalties.length) return [];
    return royalties.map(formatRoyalty);
  },
};
