import { Dog } from "lucide-react";

import { BadgeIcon as Container } from "./styles";

export function BadgeIcon({ children, icon: Icon = Dog, ...rest }) {
  return (
    <Container>
      <Icon size={18} {...rest} />
    </Container>
  );
}
