import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  filterBox: {
    display: 'flex',
    paddingBottom: '20px',
    borderBottom: '1px solid #666666',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    marginBottom: '20px',
  },
  filterButton: {
    background: '#CCD219',
    borderRadius: '3px',
    border: '1px solid #CCD219',
    color: '#ffffff',
    padding: '14px 0',
    height: '40px',
    '&:hover': {
      background: '#CCD219',
      opacity: '0.6',
    },
  },
}))
