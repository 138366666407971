import { memo } from "react";
import _ from "lodash";

import { Alert } from "../../../../../components/ui/Alert";

import { formatName, getArticles } from "../../../../../services/functions";

import { usePet } from "../../../../../store/global/usePet";
import { useVaccine } from "../../../../../store/service/veterinary/useVaccine";

import { VeterinaryVaccineController } from "../../../../../services/controllers/VeterinaryCare";

export const RemoveVaccinationProtocol = memo(() => {
  const CLVetVaccine = VeterinaryVaccineController();

  const pet = usePet((st) => st.data.pet);
  const protocolId = useVaccine((st) => st.data.showVaccinationProtocol);
  const assignedProtocols = useVaccine((st) => st.data.assignedProtocols);

  if (!pet.id) return null

  const articles = getArticles(pet?.petAttributes.gender);
  const protocol = _.find(assignedProtocols, { id: protocolId });

  return (
    <Alert.Root name={CLVetVaccine._removeVaccinationProtocolAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Exclusão de Protocolo de Vacinação</Alert.Title>
        <Alert.Description>
          <p>
            Tem certeza de que deseja
            <strong className="highlight"> Excluir </strong>o protocolo de
            vacinação de
            <strong> {formatName(protocol?.name)} </strong>
            {articles[1]} <strong> {formatName(pet.name)}</strong>?
          </p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm
            onClick={() => CLVetVaccine.removeVaccinationProtocol(true)}
          >
            Sim, Excluir
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
