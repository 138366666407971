import styled, { css, keyframes } from "styled-components";
import { darken, lighten, rgba } from "polished";

const iconHoverAnimate = keyframes`
  19%{ transform: scale(1) }
  32%{ transform: scale(1.1) }
  40%{  transform: scale(.9) }
  55%{  transform: scale(1.05) }
  58%{  transform: scale(.95) }
60%{  transform: scale(1) }
`;

export const BadgeText = styled.em`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 16px;
  line-height: 0;
`;

export const BadgeIcon = styled.em`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
`;

export const Container = styled.span`
  --border-color: ${(props) => rgba(props.theme.colors.gray[600], 0.3)};
  --base-color: ${(props) => props.theme.colors.scale[500]};

  ${(props) =>
    props.status &&
    css`
      --base-color: ${(props) => props.theme.colors.scale[props.status]};
    `}

  ${(props) =>
    props.status === "danger" &&
    css`
      --base-color: ${(props) => props.theme.colors.error};
    `}

  ${(props) =>
    props.status === "warning" &&
    css`
      --base-color: ${(props) => darken(.025, props.theme.colors.warning)};
    `}

  ${(props) =>
    props.status === "success" &&
    css`
      --base-color: ${(props) => props.theme.colors.success};
    `}

  ${(props) =>
    props.status === "finished" &&
    css`
      --base-color: ${(props) => props.theme.colors.finished};
    `}



  ${(props) =>
    props.color &&
    css`
      --base-color: ${props.color};
    `}

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  min-height: 28px;
  border-radius: 5px;
  cursor: default;
  transform: scale(0.98);

  background: var(--base-color);
  border: 1px solid var(--border-color);

  transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 3px 3px 5px;

  em{
    color: ${(props) => props.theme.colors.background};
    font-weight: normal;
  }

  &[disabled] {
    background: ${(props) => props.theme.colors.gray[300]};
  }

  &:hover:not([disabled]) {
    ${BadgeIcon} {
      animation: ${iconHoverAnimate}
        ${(props) => props.theme.animation.delay.lazy} ease-in-out forwards;
    }
  }

  ${(props) =>
    props.outline &&
    css`
      border-color: var(--base-color);
      background: ${(props) => props.theme.colors.background};

      em{ color: var(--base-color); }

      &[disabled] {
        background: ${(props) => props.theme.colors.background};
        border-color: ${(props) => props.theme.colors.gray[300]};
        em{color: ${(props) => props.theme.colors.gray[300]};}
      }
    `}

  ${(props) =>
    props.size === "small" &&
    css`
      padding: 0px 4px;
      ${BadgeText} {
        font-size: 14px;
      }
    `}
  ${(props) =>
    props.type === "text" &&
    css`
      padding: 0px 4px;
      border-color: transparent;
      background: transparent;
      box-shadow: none;
      em{ color: var(--base-color); }

      &[disabled] {
        background: transparent;
        border-color: transparent;
        em{ color: ${(props) => props.theme.colors.gray[300]}; }
      }
    `}
`;
