import _ from "lodash";
import { useHistory } from "react-router-dom";

import { ScheduleController } from "../../../services/controllers/scheduling";
import { useSchedule } from "../../../store/schedule/useSchedule";

import { formatMoney, formatName, getEmployeeTypeName } from "../../../services/functions";

import { Input } from "../../ui/Form/Input";
import { Table } from "../../ui/Table";

import { SelectedPetCard } from "../components/SelectedPetCard";
import { Actions } from "../components/Actions";

import { Container, Content, TotalAmount, FieldsBox } from "./styles";

export function BudgetSent() {
  const history = useHistory();

  const CLScheduling = ScheduleController();

  const schedule = useSchedule((st) => st.data);
  const sellers = useSchedule((st) => st.data.customer.sellers);
  const procedureServices = useSchedule((st) => st.data.procedure.services);

  const seller = _.find(sellers, { id: schedule.details.seller });
  const totalAmount = _.sumBy(schedule.procedure.services, 'price');

  return (
    <Container>
      <SelectedPetCard />
      <Content >
        <FieldsBox>
          <Input.Root
            label="Data"
            value={schedule.procedure.date}
            style={{ maxWidth: "300px" }}
            disabled
          />
          <Input.Root
            label="Hora de início"
            mask="99:99"
            value={schedule.procedure.time}
            style={{ minWidth: "130px" }}
            disabled
          />
          <Input.Root
            label={getEmployeeTypeName()}
            value={formatName(schedule.procedure.employee?.name)}
            style={{ minWidth: "325px" }}
            disabled
          />
          <Input.Root
            label="Procedimento"
            value={formatName(schedule.procedure.data?.name)}
            style={{ minWidth: "240px" }}
            disabled
          />
          <Input.Root
            label="Vendedor(a)"
            value={formatName(seller?.name || "")}
            styles={{ minWidth: "140px" }}
            disabled
          />
          <Table.Root
            name="schedule-services-table"
            style={{ minWidth: "100%", height: "auto", maxHeight: "100%" }}
          >
            <Table.Header columns={["Serviços", "Valor"]} />
            <Table.Content
              style={{
                overflowY: "auto",
                maxHeight: "calc(100vh - 702px)",
                minHeight: "200px",
              }}
            >
              {procedureServices.map((service) => (
                <Table.Line key={service.serviceId} id={service.serviceId}>
                  <Table.Item>{formatName(service.serviceName)}</Table.Item>
                  <Table.Item>R$ {formatMoney(service.price)}</Table.Item>
                </Table.Line>
              ))}
            </Table.Content>
          </Table.Root>

          <TotalAmount><strong>Valor Total: </strong> <span>R$ {formatMoney(totalAmount)}</span></TotalAmount>

        </FieldsBox>
        <Actions reset
          actions={[{
            text: "Ver atendimento",
            onClick: () => history.push(`/clinica/atendimento/${schedule.appointmentId}`)
          }]}
        />
      </Content>
    </Container >
  );
}
