import { ptBR } from "date-fns/locale";
import { CalendarDays } from "lucide-react";
import moment from "moment";
import { InputDate, CalendarBox, Calendar } from "../styles";
import { InputIcon } from "../InputIcon";
import { dateFormatedToDB } from "../../../../../services/functions";

export function InputTypeDate({ value, onChange = () => null, ...rest }) {
  return (
    <>
      <InputDate {...rest} placeholder=" " value={value} readOnly />
      <InputIcon Icon={CalendarDays} />
      <CalendarBox>
        <Calendar
          mode="single"
          selected={new Date(`${dateFormatedToDB(value)} 00:00:00`)}
          onSelect={(date) => {
            onChange(moment(date).format("DD/MM/YYYY"));
          }}
          locale={ptBR}
        />
      </CalendarBox>
    </>
  );
}
