import _ from "lodash";

import { useHistory } from "../../../store/service/veterinary/useHistory";

import { VeterinaryHistoryModel } from "../../models/VeterinaryCare";

import { Drawer } from "../../../components/ui/Drawer";

import VeterinaryRecordController from "./VeterinaryRecord";
import VeterinaryAttachmentController from "./VeterinaryAttachment";
import VeterinaryPrescriptionController from "./VeterinaryPrescription";

export default class VeterinaryHistoryController {
  _name = "veterinary-history";

  _drawerName = `${this._name}-view-drawer`;
  _attachmentListPreviewName = `${this._name}-preview-list`;

  _historyProcedures = {}

  drawerIsOpen = Drawer.store.getState().actions.isOpen;

  setHistoryList = useHistory.getState().actions.setHistory;
  setHistoryLoading = useHistory.getState().actions.setLoadingStatus;

  setSelectHistoryProcedure = useHistory.getState().actions.setSelectHistoryProcedure;
  setHistoryProcedureData = useHistory.getState().actions.setHistoryProcedureData;

  setSelectProcedureAttachment = useHistory.getState().actions.setSelectProcedureAttachment;
  setSelectProcedureRequest = useHistory.getState().actions.setSelectProcedureRequest;
  setSelectProcedurePrescription = useHistory.getState().actions.setSelectProcedurePrescription;

  constructor(orderId) {
    this._orderId = orderId;

    this.MVetHistory = VeterinaryHistoryModel();
  }

  CVetProcedure = (procedureId) => {
    if (!this._historyProcedures[procedureId]) {
      this._historyProcedures[procedureId] = {
        CRecord: new VeterinaryRecordController(procedureId, false),
        CAttachment: new VeterinaryAttachmentController(procedureId, false),
        CPrescription: new VeterinaryPrescriptionController(procedureId, false),
      }
    }
    return this._historyProcedures[procedureId];
  };

  init = _.once(() => {
    this.findScheduleHistory();
  });

  openDrawer = (procedureId) => {
    this.selectProcedure(procedureId)
    this.findProcedureData(procedureId)
    this.drawerIsOpen(this._drawerName, true);
  };

  closeDrawer = () => {
    this.drawerIsOpen(this._drawerName, false);
    this.selectProcedure()
  };

  selectProcedure = (procedureId = false) => {
    this.setSelectHistoryProcedure(procedureId)
  };

  selectProcedureAttachment = (attachment) => {
    const selectProcedure = useHistory.getState().data.selectHistoryProcedure;

    this.setSelectProcedureAttachment(attachment, selectProcedure);
  };

  selectProcedureRequest = (request) => {
    const selectProcedure = useHistory.getState().data.selectHistoryProcedure;

    this.setSelectProcedureRequest(request, selectProcedure);
  };

  selectProcedurePrescription = (prescription) => {
    const selectProcedure = useHistory.getState().data.selectHistoryProcedure;

    this.setSelectProcedurePrescription(prescription, selectProcedure);
  };

  runProcedureAction = async (Controller, Action, ...args) => {
    const selectProcedure = useHistory.getState().data.selectHistoryProcedure;

    return await this._historyProcedures[selectProcedure][Controller][Action](...args)

  }

  findScheduleHistory = async () => {
    const response = await this.MVetHistory.findHistory(this._orderId);

    if (!response) return;

    this.setHistoryList(response);
  };

  findProcedureData = async (procedureId = false) => {
    if (!procedureId) return

    const existsProcedureData = useHistory.getState().data.historyProcedureData[procedureId];

    if (!!existsProcedureData) return

    const { CRecord, CAttachment, CPrescription } = this.CVetProcedure(procedureId)

    this.setHistoryLoading(true)

    const record = await CRecord.getRecord()
    const attachments = await CAttachment.getAttachments()
    const prescriptions = await CPrescription.getPrescriptions()

    if (record) this.setHistoryProcedureData(procedureId, { record })
    if (attachments) {
      this.setHistoryProcedureData(procedureId, { attachments });
      CAttachment.getThumbs()
    }
    if (prescriptions) {
      this.setHistoryProcedureData(procedureId, { prescriptions })
      CPrescription.getThumbs(true)
    }

    // this.setHistoryLoading(false)
  };
}
