import React, { useEffect } from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core'

import AddressForm from './AddressForm'
import { useTaxiDog } from '../../../../hooks/TaxiDogContext'

import { useStyles } from './styles'

function TaxiDog({
  customer,
  clear = false,
  close,
  setTaxiDogData,
  defautlt = false,
}) {
  const { Container, input, saveButton, cancelButton } = useStyles()

  const {
    findDrivers,
    getDrivers,
    setTaxiDogInfo,
    getTaxiDogInfo,
    clearTaxiDog,
  } = useTaxiDog()

  const drivers = getDrivers()
  const taxiDogInfo = getTaxiDogInfo()

  useEffect(() => {
    findDrivers()
  }, [])

  useEffect(() => {
    if (defautlt) {
      setTaxiDogInfo(defautlt)
    }
  }, [defautlt])

  return (
    <Grid spacing={2} className={Container}>
      <Grid spacing={2} item xs={12}>
        <TextField
          fullWidth
          select
          label="MOTORISTA"
          variant="outlined"
          className={input}
          value={`${taxiDogInfo.driverId}+${taxiDogInfo.driverName}`}
          onChange={(e) => {
            setTaxiDogInfo({
              driverId: e.target.value.split('+')[0],
              driverName: e.target.value.split('+')[1],
            })
          }}
        >
          {drivers.map(({ driverId, name }) => (
            <MenuItem key={driverId} value={`${driverId}+${name}`}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid spacing={2} item xs={12}>
        <TextField
          fullWidth
          select
          label="Taxi Dog"
          variant="outlined"
          className={input}
          value={taxiDogInfo.taxiDogType}
          onChange={(e) => {
            setTaxiDogInfo({ taxiDogType: e.target.value })
          }}
        >
          <MenuItem value="Somente Entrega">Somente Entrega</MenuItem>
          <MenuItem value="Somente Retirada">Somente Retirada</MenuItem>
          <MenuItem value="Retirada e Entrega">Retirada e Entrega</MenuItem>
        </TextField>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={9}>
          <TextField
            fullWidth
            select
            label="RECEBIMENTO PELO MOTORISTA"
            variant="outlined"
            className={input}
            defaultValue={taxiDogInfo.driverPickupType}
            onChange={(e) => {
              setTaxiDogInfo({ driverPickupType: e.target.value })
            }}
          >
            <MenuItem value="Sim, na entrega">Sim, na entrega</MenuItem>
            <MenuItem value="Sim, na retirada">Sim, na retirada</MenuItem>
            <MenuItem value="Não">Não</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Valor"
            variant="outlined"
            className={input}
            value={taxiDogInfo.total}
            onChange={(e) => {
              setTaxiDogInfo({ total: e.target.value.replace(',', '.') })
            }}
            InputLabelProps={{
              shrink: !!taxiDogInfo.total,
            }}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} item xs={12}>
        <Grid item xs={12}>
          <List>
            {!!customer.customerDefaultAddress && (
              <ListItem style={{ padding: '0px' }}>
                <ListItemIcon style={{ minWidth: '0px' }}>
                  <Checkbox
                    edge="start"
                    value="1"
                    tabIndex={-1}
                    disableRipple
                    checked={!!taxiDogInfo.useCustomerAddress}
                    onChange={() => {
                      setTaxiDogInfo({
                        useCustomerAddress: !taxiDogInfo.useCustomerAddress,
                      })
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <span>USAR ENDEREÇO DE CADASTRO</span>
                </ListItemText>
              </ListItem>
            )}
            {taxiDogInfo.taxiDogType === 'Retirada e Entrega' && (
              <ListItem style={{ padding: '0px' }}>
                <ListItemIcon style={{ minWidth: '0px' }}>
                  <Checkbox
                    edge="start"
                    value="2"
                    tabIndex={-1}
                    disableRipple
                    defaultChecked={taxiDogInfo.sameAddress}
                    onChange={() => {
                      setTaxiDogInfo({ sameAddress: !taxiDogInfo.sameAddress })
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <span>ENTREGA E RETIRADA NO MESMO ENDEREÇO</span>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>

      {(taxiDogInfo.taxiDogType === 'Retirada e Entrega' ||
        taxiDogInfo.taxiDogType === 'Somente Retirada') && (
        <AddressForm
          type="pickupAddress"
          title="ENDEREÇO RETIRADA"
          address={taxiDogInfo.pickupAddress}
          useDefaultAdress={taxiDogInfo.useCustomerAddress}
          defaultAdress={customer.customerDefaultAddress}
        />
      )}

      {(taxiDogInfo.taxiDogType === 'Retirada e Entrega' ||
        taxiDogInfo.taxiDogType === 'Somente Entrega') && (
        <AddressForm
          type="deliveryAddress"
          title="ENDEREÇO DE ENTREGA"
          address={taxiDogInfo.deliveryAddress}
          useDefaultAdress={taxiDogInfo.sameAddress}
          defaultAdress={
            taxiDogInfo.taxiDogType === 'Somente Entrega'
              ? customer.customerDefaultAddress
              : taxiDogInfo.pickupAddress
          }
        />
      )}

      <Grid
        container
        spacing={2}
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      >
        <Grid item xs={5}>
          <Button
            fullWidth
            color="secondary"
            size="large"
            type="submit"
            justify="center"
            alignItems="center"
            className={cancelButton}
            onClick={() => {
              clear && clearTaxiDog()
              close()
            }}
          >
            CANCELAR
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            fullWidth
            color="secondary"
            size="large"
            type="submit"
            justify="center"
            alignItems="center"
            className={saveButton}
            onClick={() => {
              setTaxiDogData(taxiDogInfo)
              close()
            }}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TaxiDog
