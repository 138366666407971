import RoyaltyStatementClass from "./RoyaltyStatement";

let MRoyaltyStatement = null;

export function RoyaltyStatementModel() {
  if (MRoyaltyStatement) {
    return MRoyaltyStatement;
  }

  MRoyaltyStatement = new RoyaltyStatementClass();
  return MRoyaltyStatement;
}
