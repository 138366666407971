import React, { useEffect, useState, useCallback } from 'react'
import API from '../services/api'

export const PetsContext = React.createContext({})

export function PetsProvider({ children }) {
  const [customer, setCustomer] = useState(null)
  const [petList, setPetList] = useState([])

  const getPets = useCallback(async () => {
    if (customer) {
      try {
        const { data } = await API.get(`customer/${customer}/pets`)
        setPetList(data.pets)
      } catch (err) {
        console.log('Erro:', err)
      }
    }
  }, [customer])

  useEffect(() => {
    getPets()
  }, [getPets, customer])

  return (
    <PetsContext.Provider value={{ setCustomer, getPets, petList }}>
      {children}
    </PetsContext.Provider>
  )
}

export const usePets = () => React.useContext(PetsContext)
