import { ChevronLeft } from "lucide-react";

import { usePagination } from "./usePagination";

import { PaginationBack as ButtonBack } from "./styles";
import { usePaginationContext } from "./PaginationContext";

export function PaginationBack() {
  const { paginationName } = usePaginationContext();

  const setCurrentPage = usePagination((st) => st.actions.setCurrentPage);
  const currentPage =
    usePagination((st) => st.data.pagination[paginationName]?.currentPage) || 0;

  const toStart = () => {
    setCurrentPage(paginationName, 0);
  };

  const toPrevious = () => {
    if (currentPage - 1 < 0) return;
    setCurrentPage(paginationName, currentPage - 1);
  };

  return (
    <>
      <ButtonBack
        onClick={toStart}
        disabled={currentPage === 0}
        outline
        data-direction="left"
      >
        <ChevronLeft size={20} />
        <ChevronLeft size={20} />
      </ButtonBack>
      <ButtonBack
        onClick={toPrevious}
        disabled={currentPage === 0}
        outline
        data-direction="left"
      >
        <ChevronLeft size={20} />
      </ButtonBack>
    </>
  );
}
