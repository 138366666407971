import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    padding: '12px',
    margin: '8px 0',
    background: '#F6F6F6',
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignitems: 'flex-start',
    flex: '1',
  },
  filter: {
    margin: '0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '26px',
    lineHeight: '30px',
    letterSpacing: '0.4px',
    color: '#666666',
    margin: '12px 12px 0 012px',
  },
  filterTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '0.4px',
    color: '#666666',
    width: '100%',
  },
  filterPart: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: ' 0.4px',
    color: '#666666',
  },
  filterItem: {
    padding: '6px 0',
  },
}))
