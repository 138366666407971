import { StyleSheet } from "@react-pdf/renderer";

export const container = StyleSheet.create({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "14px",
  width: "100%",
  flexWrap: "wrap",
})
