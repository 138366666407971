import { get } from "./useTable";

import { TableItem as Container } from "./styles";

export function TableItem({ children, ...rest }) {
  return (
    <Container {...rest}>
      <span>{children}</span>
    </Container>
  );
}
