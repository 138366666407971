import { useHistory } from "../../../../../../store/service/veterinary/useHistory";

import { InformationBlock as Container, Highlight, Line, Title } from "../styles";

export function GeneralPhysicalExamination() {
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const procedureData = useHistory((st) => st.data.historyProcedureData);

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedureRecord = selectedProcedure?.record

  return (
    <Container>
      <Title>Exame Físico Geral</Title>
      <Line>
        <p>
          <Highlight>Ouvidos: </Highlight> {procedureRecord?.ears || '-'}
        </p>
      </Line>
      <Line>
        <p>
          <Highlight>Mucosas: </Highlight> {procedureRecord?.mucous || '-'}
        </p>
      </Line>
      <Line>
        <p>
          <Highlight>Hidratação: </Highlight> {procedureRecord?.hydration || '-'}
        </p>
      </Line>
      <Line>
        <p>
          <Highlight>Linfonodos: </Highlight> {procedureRecord?.lymphNodes || '-'}
        </p>
      </Line>
      <Line>
        <p>
          <Highlight>Pele/Pelo: </Highlight> {procedureRecord?.skin || '-'}
        </p>
      </Line>
    </Container>
  );
}
