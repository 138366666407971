import _ from "lodash";
import slugify from "slugify";
import Mustache from "mustache";
import { toast } from "react-toastify";

import { WHATSAPP_SEND_MESSAGE_BASE } from "../../../styles/constant";
import { MEDICINE_FIELDS, PRESCRIPTION_FIELDS } from "../../../store/service/veterinary/constants";

import { wppTemplates } from "../../templates/wpp";

import { clearNumber, promiseDelay } from "../../functions";

import { formatTemplateData } from "../../formatTemplateData/veterinay";
import { formatPreviewList } from "../../formatPreviewList/veterinaryCare";

import { useCare } from "../../../store/service/veterinary/useCare";
import { usePrescription } from "../../../store/service/veterinary/usePrescription";
import { usePet } from "../../../store/global/usePet";

import { VeterinaryPrescriptionModel } from "../../models/VeterinaryCare";

import { Drawer } from "../../../components/ui/Drawer";
import { Alert } from "../../../components/ui/Alert";
import { PDF } from "../../../components/PDF";

import { MedicineController } from "../global";
import { veterinaryCareWarnings } from "../../templates/warnings/veterinaryCare";

export default class VeterinaryPrescriptionController {
  _name = "veterinary-prescription";
  _label = 'Receituário';
  _medicinesId = `${this._name}_medicines`

  _saveInStore = true;

  _previewListName = `${this._name}-list`;
  _drawerName = `${this._name}-drawer`;
  _incompleteAlertName = `${this._name}-incomplete-alert`;

  _pdfFileName = `${this._name}-pdf`;

  _requiredPrescriptionValues = [
    "name",
  ];

  _requiredMedicineValues = {
    self: [
      "dose",
      "measure",
      "frequency",
      "frequencyPeriod",
      "duration",
      "durationPeriod",
      "pharmacyType",
      "usage",
    ],
    free: ["name", "freeText", "pharmacyType", "usage"],
  };

  _defaultMedicine = {
    presentation: "",
    fillStyle: "self",
    freeText: "",
    dose: "",
    measure: null,
    frequency: "",
    frequencyPeriod: null,
    duration: "",
    durationPeriod: null,
    note: "",
    pharmacyType: "veterinary",
    usage: null,
    quantity: "",
  };

  PDFPrescription = PDF.prescription

  drawerIsOpen = Drawer.store.getState().actions.isOpen;
  alertIsOpen = Alert.store.getState().actions.isOpen;

  isUpdated = usePrescription.getState().actions.isUpdated;
  setLoadedThumbnailsStatus = usePrescription.getState().actions.setLoadedThumbnailsStatus;
  setLoadingStatus = usePrescription.getState().actions.setLoadingStatus;

  setPrescriptionsList = usePrescription.getState().actions.setPrescriptions;
  updatePrescriptionsList = usePrescription.getState().actions.updatePrescriptions;

  setNewMedicine = usePrescription.getState().actions.setNewMedicine;
  addMedicine = usePrescription.getState().actions.addMedicine;
  addMedicines = usePrescription.getState().actions.addMedicines;
  removeMedicine = usePrescription.getState().actions.removeMedicine;

  setPrescriptionID = usePrescription.getState().actions.setPrescriptionID;
  setPrescriptionName = usePrescription.getState().actions.setPrescriptionName;
  setSelectedPrescription = usePrescription.getState().actions.setSelectPrescription;

  changePrescriptionPath = usePrescription.getState().actions.changePrescriptionPath;
  changePrescriptionStatus = usePrescription.getState().actions.changePrescriptionStatus;

  removePrescription = usePrescription.getState().actions.removePrescription;
  returnPrescription = usePrescription.getState().actions.returnPrescription;
  excludePrescription = usePrescription.getState().actions.deletePrescription;

  constructor(orderId, saveInStore = null, label = false, medicinesId = false) {
    this._orderId = orderId;

    this._saveInStore = saveInStore

    this._label = label || this._label
    this._medicinesId = medicinesId || this._medicinesId

    this._warnings = veterinaryCareWarnings.prescription
    this._sendPrescriptionTemplate = wppTemplates.draMei.sendPrescription;

    this.setNewMedicine(this._defaultMedicine);

    this.MVetPrescription = VeterinaryPrescriptionModel(this._orderId);

    this.CMedicine = MedicineController(this._medicinesId);
  }

  init = _.once(() => {
    this.getPrescriptions();
  });

  selectPrescription = (prescription) => {
    this.setSelectedPrescription(prescription);
  };

  setName = (name) => {
    this.setPrescriptionName(name.trim());
    this.isUpdated(false);
  };

  openDrawer = () => {
    this.drawerIsOpen(this._drawerName, true);
  };

  closeDrawer = (force = false) => {
    const isUpdated = usePrescription.getState().data.prescription.updated;

    if (!isUpdated && !force) {
      this.alertIsOpen(this._incompleteAlertName, true);
      return;
    }
    this.drawerIsOpen(this._drawerName, false);
    this.clearNewMedicine();
    this.addMedicines();
    this.setPrescriptionID()
    this.setName("");
    this.isUpdated(true);
  };

  validateMedicine = () => {
    const newMedicineData = usePrescription.getState().data.prescription.newMedicine;

    let requiredValues =
      this._requiredMedicineValues[newMedicineData.fillStyle];

    if (newMedicineData.durationPeriod === "continuous") {
      requiredValues = _.without(requiredValues, "duration");
    }

    const invalidFields = _.filter(
      requiredValues,
      (item) => !newMedicineData[item]
    );

    invalidFields.forEach((error, i) => {
      setTimeout(() => {
        toast.error(
          `O campo ${MEDICINE_FIELDS[error][1]} é obrigatório para adicionar um novo medicamento`,
          { theme: "colored" }
        );
      }, 200 * i);
    });

    return !invalidFields.length;
  };

  validatePrescription = () => {
    const prescriptionData = usePrescription.getState().data.prescription;
    const requiredValues = this._requiredPrescriptionValues;

    const invalidFields = _.filter(
      requiredValues,
      (item) => !prescriptionData[item]
    );

    invalidFields.forEach((error, i) => {
      setTimeout(() => {
        toast.error(`O campo ${PRESCRIPTION_FIELDS[error][1]} é obrigatório para cadastrar uma receita`, { theme: "colored" });
      }, 200 * i);
    });

    if (_.isEmpty(prescriptionData.medicines)) {
      toast.error(`Adicione pelo menos um medicamento!`, { theme: "colored", });
      return false;
    }

    return !invalidFields.length;
  };

  getPrescriptionWarningsContent = (warningName) => {
    const pet = usePet.getState().data.pet;

    if (!pet.id) return

    const warningData = formatTemplateData.registrationWarnings(pet);

    return Mustache.render(
      this._warnings[warningName],
      warningData
    );
  }

  getThumbs = async (force = false) => {
    const isLoadedThumbnails = usePrescription.getState().data.loadedThumbnails;
    const prescriptions = usePrescription.getState().data.prescriptions;

    const prescriptionsWithoutThumb = _.filter(prescriptions, prescription => !prescription.thumb && prescription.path);

    if (isLoadedThumbnails) return
    if (!force && !prescriptionsWithoutThumb.length) return

    this.setLoadedThumbnailsStatus(true)

    const withThumbs = await formatPreviewList.thumbs(_.values(prescriptionsWithoutThumb));

    this.updatePrescriptionsList(withThumbs);

    this.setLoadedThumbnailsStatus(false)

    return true
  };

  getPrescriptions = async () => {
    const response = await this.MVetPrescription.findPrescriptions();

    if (!response) return;

    const formattedPrescriptions = formatPreviewList.prescriptions(response);

    if (this._saveInStore) this.setPrescriptionsList(formattedPrescriptions);

    this.getThumbs()

    return formattedPrescriptions
  };

  setNewMedicineProps = (props) => {
    if (!!props.fillStyle) {
      this.setNewMedicine({ quantity: '' });
    }

    this.setNewMedicine(props);

    this.isUpdated(false);
  };

  setNewCustomMedicineProps = (name) => {
    this.setNewMedicine({...this._defaultMedicine, presentation:name});

    this.isUpdated(false);
  };

  clearNewMedicine = () => {
    this.CMedicine.deselectMedicine()
    this.setNewMedicine(this._defaultMedicine);
  };

  deleteMedicine = (medicineId) => {
    this.removeMedicine(medicineId);
    this.isUpdated(false);
  };

  setMedicineDefaults = (medicine) => {

    if (_.isEmpty(medicine.presentations)) {
      this.setNewMedicineProps({
        presentation: medicine.name || "",
      });
    }

    this.setNewMedicineProps({
      name: medicine.name || "",
    });

    this.setNewMedicineProps({
      note: medicine.suggestions.note || "",
    });
  };

  saveMedicine = () => {
    if (!this.validateMedicine()) return;
    this.addMedicine();
    this.clearNewMedicine();
    this.isUpdated(true);
  };

  savePrescription = async () => {
    if (!this.validatePrescription()) return;
    const prescription = usePrescription.getState().data.prescription;
    let response = false;

    this.setLoadingStatus(true);

    if (!prescription.id) response = await this.saveNewPrescription(prescription)
    if (prescription.id) {
      await this.updatePrescription(prescription)
      response = prescription
    }

    this.setLoadingStatus(false);

    this.sendPrescriptionFile(response.id)
    this.closeDrawer(true)
  }

  saveNewPrescription = async (prescription) => {
    const response = await this.MVetPrescription.savePrescription(prescription)

    if (!response) return response;

    const formattedPrescription = formatPreviewList.prescription(response);
    this.setPrescriptionsList(formattedPrescription, false);

    toast.success(`A Receita ${formattedPrescription.name} foi cadastrada!`, { theme: "colored" });
    return response
  }

  updatePrescription = async (prescription) => {
    const response = await this.MVetPrescription.updatePrescription(prescription)
    if (!response) return response;

    const formattedPrescription = formatPreviewList.prescription(response);

    this.setPrescriptionsList(formattedPrescription, false);

    toast.success(`A Receita ${formattedPrescription.name} foi Atualizada!`, { theme: "colored" });

    return response
  }

  resendPrescription = async (prescriptionId) => {
    if(!prescriptionId) return

    const prescription = usePrescription.getState().data.prescriptions[prescriptionId];

    this.setPrescriptionID(prescription.id)
    this.setPrescriptionName(prescription.name)
    this.addMedicines(prescription.medicines)

    this.sendPrescriptionFile(prescription.id)
  }


  sendPrescriptionFile = async (id = false) => {
    const newPrescription = usePrescription.getState().data.prescription;
    const prescriptionId = id || usePrescription.getState().data.selectPrescription.id;

    this.changePrescriptionStatus(prescriptionId, 'generating')

    const fileName = slugify(newPrescription.name)

    const file = await PDF.prescription.getFormData(fileName)

    const filePath = await this.MVetPrescription.sendPrescriptionFile(prescriptionId, file);

    if (!filePath) {
      this.changePrescriptionStatus(prescriptionId, 'error')
      return
    }

    this.changePrescriptionPath(prescriptionId, filePath)

    await promiseDelay(2000)
    await this.getThumbs()

    this.changePrescriptionStatus(prescriptionId, 'generated')

  }

  sendMessage = (that = false) => {
    const care = useCare.getState().data.care
    const pet = usePet.getState().data.pet;
    const prescription = that || usePrescription.getState().data.selectPrescription;

    const ownerPhoneNumber = clearNumber(pet.owner.phone);

    const prescriptionMessageData = formatTemplateData.sendPrescription({
      pet,
      prescription,
      veterinarian: care.veterinarian,
    });

    const message = Mustache.render(
      this._sendPrescriptionTemplate.content,
      prescriptionMessageData
    );

    const wppMessageUrl = WHATSAPP_SEND_MESSAGE_BASE.replace(
      "[PHONE_NUMBER]",
      ownerPhoneNumber
    ).replace("[MESSAGE]", message);

    window.open(wppMessageUrl, "_blank");
  };

  editPrescription = () => {
    const selectPrescription = usePrescription.getState().data.selectPrescription

    this.setPrescriptionID(selectPrescription.id)
    this.setPrescriptionName(selectPrescription.name)
    this.addMedicines(selectPrescription.medicines)
    this.openDrawer()
  };

  deletePrescription = async () => {
    const selectPrescription = usePrescription.getState().data.selectPrescription;

    this.removePrescription(selectPrescription.id);

    const response = await this.MVetPrescription.deletePrescription(selectPrescription.id);

    if (!response) {
      this.returnPrescription(selectPrescription.id);
      return;
    }

    this.excludePrescription(selectPrescription.id);
  };
}
