import styled, { css } from "styled-components";
import { rgba } from "polished";

const HighlightStyle = css`
  height: 100%;
  position: absolute;
  padding: 0 10px;
  width: 100%;
  background: ${(props) => rgba(props.theme.colors.scale[200], 0.2)};
  color: ${(props) => props.theme.colors.primary};
  display: none;
  justify-content: flex-start;
  align-items: center;
  border: none;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
  outline: none;
  text-decoration: none;
`;

export const Highlight = styled.a`
  ${HighlightStyle}
`;
export const HighlightButton = styled.button`
  ${HighlightStyle}
`;

export const Container = styled.span`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[50]};
  height: 20px;
  background: transparent;
  transition: all 250ms linear;
  position: absolute;
  top: calc(20px * ${(props) => props.index});
  &:last-child {
    border: none;
  }
  &:hover {
    cursor: pointer;
    ${Highlight}, ${HighlightButton} {
      display: flex;
    }
  }

  ${(props) =>
    props.isHover &&
    css`
      ${Highlight}, ${HighlightButton} {
        background: ${(props) => rgba(props.theme.colors.scale[400], 0.2)};
        border: 2px dotted ${(props) => props.theme.colors.scale[400]};
        display: flex;
      }
    `}
  ${(props) =>
    props.canDrop &&
    css`
      z-index: 1;
    `}

    ${(props) =>
    props.disabled &&
    css`
      background-size: 10px 10px;
      border: none;
      background-image: linear-gradient(
        45deg,
        transparent 46%,
        ${(props) => props.theme.colors.gray[200]} 49%,
        ${(props) => props.theme.colors.gray[200]} 51%,
        transparent 55%
      );
      background-color: ${(props) => rgba(props.theme.colors.gray[50], 0.2)};
    `}
`;
