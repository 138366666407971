import _ from "lodash";

import { useMerchant } from "../../../store/global/useMerchant";
import { MerchantModel } from "../../models/global";

export default class MerchantController {
  _default = {
    name: "",
    cnpj: "",
    location: {
      uf: "",
      city: "",
      postalCode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
    },
    planTypes: [],
    modules: [],
    contact: {
      phoneNumber: "",
      whatsapp: "",
    },
  };

  setMerchantValues = useMerchant.getState().actions.setMerchant;

  constructor() {
    this.MMerchant = MerchantModel();
  }

  init = _.once(() => {
    this.load();
  });

  load = () => {
    this.setMerchantValues(this._default);
    this.getStore();
  };

  getStore = async () => {
    const merchant = await this.MMerchant.findMerchant();

    this.setMerchantValues(this._default);
    if (!!merchant) this.setMerchantValues(merchant);
  };
}
