export const messageSendProgrammedVaccination = {
  name: "Mensagem de Vacinação Programada",
  gender: "female",
  content: `
  %0AOlá *{{tutorName}}* 🐶,
  %0A%0AVamos agendar a vacinação {{petArticle}} *{{petName}}*? 💉
  %0A%0A*Local:* {{storeName}}
  %0A*Endereço:* {{storeAddress}}
  %0A%0A%0AFique à vontade para tirar dúvidas ou se precisar de alguma assistência. Estamos aqui para ajudar!
  %0A%0AAtenciosamente,
  %0A_{{storeName}}_ 🩺 🐾
  `.trim(),
};
