import styled, { css, keyframes } from "styled-components";
import { darken, rgba } from "polished";

import { Table } from "../../../../../../components/ui/Table";

const removeAnimate = keyframes`
  0%{}
  20%{
    opacity: 1;
  }
  70%{
    opacity: 0;
  }
  90%{
    transform: translateX(-105%);
  }
  100%{
    max-height: 0px
  }
`;

export const Container = styled(Table.Line)`
  background: ${(props) =>
    rgba(props.theme.colors.warning, props.warning / 30)};
  color: ${(props) => props.theme.colors.gray[800]};
  height: 55px;

  ${(props) =>
    props.scheduleStatus === "delayed" &&
    css`
      background: ${(props) => rgba(props.theme.colors.error, 0.15)};
      color: ${(props) => props.theme.colors.error};
      font-weight: bold;
      &:hover {
        background: ${(props) => rgba(props.theme.colors.error, 0.2)}!important;
      }
    `}
  ${(props) =>
    props.vaccineStatus === "applied" &&
    css`
      background: ${(props) => rgba(props.theme.colors.success, 0.15)};
      color: ${(props) => props.theme.colors.success};
      font-weight: bold;
      &:hover {
        background: ${(props) =>
          rgba(props.theme.colors.success, 0.2)}!important;
      }
    `};

  ${(props) =>
    props.vaccineStatus === "editing" &&
    css`
      background: ${(props) => rgba(props.theme.colors.scale[300], 0.15)};
      color: ${(props) => props.theme.colors.scale[300]};
      font-weight: bold;
      &:hover {
        background: ${(props) =>
          rgba(props.theme.colors.scale[300], 0.2)}!important;
      }
    `};

  ${(props) =>
    props.vaccineStatus === "reprogramming" &&
    css`
      background: ${(props) => rgba(props.theme.colors.scale[300], 0.15)};
      color: ${(props) => props.theme.colors.scale[300]};
      font-weight: bold;
      &:hover {
        background: ${(props) =>
          rgba(props.theme.colors.scale[300], 0.2)}!important;
      }
    `};
  ${(props) =>
    props.vaccineStatus === "applying" &&
    css`
      background: ${(props) => rgba(props.theme.colors.success, 0.05)};
      color: ${(props) => darken(0.3, props.theme.colors.success)};
      font-weight: normal;
      &:hover {
        background: ${(props) =>
          rgba(props.theme.colors.success, 0.1)}!important;
      }
    `};

  ${(props) =>
    props.vaccineStatus === "removed" &&
    css`
      overflow: hidden;
      pointer-events: none;
      max-height: 100px;
      background: ${(props) => rgba(props.theme.colors.error, 0.3)};
      animation: ${removeAnimate} ${(props) => props.theme.animation.delay.slow}
        ease-in-out forwards;
    `};
`;

export const ActionsItem = styled(Table.Item)`
  span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    & > * {
      min-width: fit-content;
    }
  }
`;
