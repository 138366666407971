import { PawPrint } from '@phosphor-icons/react'

export function SignatureCustomer() {

  return (
    <div className="mt-14 flex w-full flex-col items-center justify-center text-sm">
      <div className="flex w-full gap-1">
        <PawPrint className="h-4 w-4" weight="bold" />
        <span className="w-full flex-1 border-b border-black" />
      </div>
      Assinatura do Tutor / Responsável
    </div>
  )
}
