import styled from "styled-components";

export const FreeTextContent = styled.div`
  width: 100%;
  * {
    margin-top: 0;
    margin-bottom: 0;
  }
  p {
    min-height: 16px;
  }
`;

export const SelfContent = styled.p`
  margin: 0;
`;
