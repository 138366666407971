import { Root, Trigger, Portal } from '@radix-ui/react-popover'

import { EditOutlined } from '@material-ui/icons'

import { useVaccines } from '../../../../../store/protocols/useVaccines'

import { Button, Container, Item, Arrow } from './styles'

function EditButton({ vaccine }) {
  const {
    actions: { updateState, update },
  } = useVaccines()

  const buttons = [
    {
      name: 'Editar',
      action: () => {
        updateState(vaccine.id, 'edit')
      },
      statusList: ['applied', 'scheduled', 'remove'],
    },
    {
      name: 'Remover',
      action: () => {
        update(vaccine.id, {
          applicationDate: '',
          actionState: 'remove',
          status: 'scheduled',
        })
      },
      statusList: ['applied'],
    },
    {
      name: 'Aplicar Vacina',
      action: () => {
        updateState(vaccine.id, 'apply')
      },
      statusList: ['expired', 'scheduled', 'remove'],
    },
    {
      name: 'Reprogramar',
      action: () => {
        updateState(vaccine.id, 'reschedule')
      },
      statusList: ['expired', 'scheduled', 'remove'],
    },
    {
      name: 'Cancelar',
      action: () => {
        update(vaccine.id, {
          applicationDate: '',
          actionState: 'cancel',
          status: 'cancel',
        })
      },
      statusList: ['expired', 'scheduled', 'cancel', 'remove'],
    },
  ]

  return (
    <Root>
      <Trigger asChild>
        <Button>
          <EditOutlined />
        </Button>
      </Trigger>
      <Portal>
        <Container>
          <Arrow />
          {buttons
            .filter((button) => button.statusList.includes(vaccine.status))
            .map((button) => (
              <Item onClick={button.action} key={button.name}>
                <span>{button.name}</span>
              </Item>
            ))}
        </Container>
      </Portal>
    </Root>
  )
}

export default EditButton
