import React from 'react'
import { Controller, useForm } from 'react-hook-form'

// Material ui
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Alert from '@mui/material/Alert'
import { Snackbar, FormGroup, Checkbox } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress'

import Box from '@mui/material/Box'

// Style
import moment from 'moment'
import { useStyles } from '../styles'

// moment
import API from '../../../../services/api'
import 'moment/locale/pt-br'

moment.locale('pt-br')

function Steps1({ handleStep, valueDrawer = false, getEmployees = false }) {
  const classes = useStyles()

  const [service, setService] = React.useState([])
  const { control, getValues, handleSubmit } = useForm()
  const [value, setValue] = React.useState('')
  const [calculationBasis, setCalculationBasis] = React.useState('')
  const [bonusServicesOption, setBonusServicesOption] = React.useState('')
  const [startFromDate, setStartFromDate] = React.useState()
  const [message, setMessage] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(false)
  const [disableButton, setDisableButton] = React.useState(false)

  const getService = async () => {
    try {
      const { data } = await API.get('employees/commissions/store-config')
      setService(data)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleService = (checkedId) => {
    const { service_id: ids } = getValues()
    const newIds = ids?.includes(checkedId)
      ? ids?.filter((id) => id !== checkedId)
      : [...(ids ?? []), checkedId]
    return newIds
  }

  const onSubmit = async (data) => {
    setDisableButton(true)
    const payload = {
      calculationBasis,
      bonusServicesOption,
      servicesToInclude: data.service_id,
      startFromDate: moment(startFromDate).format('YYYY-MM-DD'),
    }

    try {
      const result = await API.post(
        'employees/commissions/store-config',
        payload,
      )

      if (result?.status === 201 && !valueDrawer) handleStep(1)
      if (result?.status === 201 && valueDrawer) getEmployees()

      if (result?.status === 201) {
        setMessage(result?.data?.message)
        setDisableButton(false)
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message)
      setMessage('error')
      setDisableButton(false)
      console.log('Erro:', err)
    }
  }

  React.useEffect(() => {
    if (service?.startFromDate)
      setValue(moment(service?.startFromDate).format())
  }, [service])

  React.useEffect(() => {
    getService()
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {service?.calculationBasis && (
        <>
          <Paper className={classes.paper} style={{ padding: '5px' }}>
            <div className={classes.card}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.header}
              >
                Base de cálculo - Líquido | Bruto
              </Typography>

              <Paper variant="outlined" square className={classes.body}>
                <Typography variant="body2" color="text.secondary">
                  <Controller
                    name="calculationBasis"
                    render={(props) => (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={service?.calculationBasis}
                          name="radio-buttons-group"
                          onChange={(event, value) =>
                            props.onChange(setCalculationBasis(value))
                          }
                        >
                          <FormControlLabel
                            value="Líquido"
                            control={<Radio />}
                            label="Valor líquido"
                            aria-labelledby="teste"
                            className={classes.titleRadio}
                          />
                          <FormLabel
                            id="teste"
                            className={classes.descriptionLabel}
                          >
                            A comissão será calculada com base no valor da
                            venda, depois de abatidos os descontos.
                          </FormLabel>
                          <FormControlLabel
                            className={classes.titleRadio}
                            value="Bruto"
                            control={<Radio />}
                            label="Valor bruto"
                          />
                          <FormLabel
                            id="teste"
                            className={classes.descriptionLabel}
                          >
                            A comissão será calculada com base no preço tabelado
                            do serviço. Não serão abatidos os descontos.
                          </FormLabel>
                        </RadioGroup>
                      </FormControl>
                    )}
                    control={control}
                  />
                </Typography>
              </Paper>
            </div>

            <div className={classes.card}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.header}
              >
                Base de cálculo - Serviços bonificados em pacote
              </Typography>

              <Paper variant="outlined" square className={classes.body}>
                <Typography variant="body2" color="text.secondary">
                  <Controller
                    name="bonusServicesOption"
                    render={(props) => (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={
                            service?.bonusServicesHaveOriginalPrice
                              ? 'Preço original'
                              : 'Preço do pacote'
                          }
                          name="radio-buttons-group"
                          onChange={(event, value) =>
                            props.onChange(setBonusServicesOption(value))
                          }
                        >
                          <FormControlLabel
                            value="Preço original"
                            name="Preço original"
                            className={classes.titleRadio}
                            control={
                              <Radio
                                value="Preço original"
                                name="Preço original"
                              />
                            }
                            label="Preço original"
                            aria-labelledby="teste"
                          />
                          <FormLabel
                            id="teste"
                            className={classes.descriptionLabel}
                          >
                            A comissão será calculada com base no preço tabelado
                            do serviço. Não será abatido o desconto da
                            bonificação.
                          </FormLabel>
                          <FormControlLabel
                            className={classes.titleRadio}
                            value="Preço do pacote"
                            name="Preço do pacote"
                            control={<Radio />}
                            label="Respeitar preço do pacote"
                          />
                          <FormLabel
                            id="teste"
                            className={classes.descriptionLabel}
                          >
                            Serviços bonificados no pacote não geram comissão.
                          </FormLabel>
                        </RadioGroup>
                      </FormControl>
                    )}
                    control={control}
                  />
                </Typography>
              </Paper>
            </div>

            <div className={classes.card}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.header}
              >
                Base de cálculo - Inclusão de serviços
              </Typography>

              <Paper variant="outlined" square className={classes.body}>
                <Box sx={{ display: 'flex' }}>
                  <Controller
                    name="service_id"
                    render={(props) => (
                      <FormGroup className={classes.check}>
                        {service?.servicesToInclude?.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() =>
                                  props.onChange(handleService(item))
                                }
                              />
                            }
                            key={item}
                            label={item}
                          />
                        ))}
                      </FormGroup>
                    )}
                    control={control}
                  />
                </Box>
              </Paper>
            </div>

            <div className={classes.card}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.header}
              >
                Data de início
              </Typography>

              <Paper
                variant="outlined"
                square
                className={classes.body}
                style={{ padding: '23px' }}
              >
                <Typography variant="body2" color="text.secondary">
                  <Controller
                    name="startFromDate"
                    render={() => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={value}
                          defaultValue={service?.startFromDate}
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                            setValue(newValue)
                            setStartFromDate(newValue)
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                    control={control}
                  />
                </Typography>
                <Typography
                  style={{
                    color: '#666666',
                    fontSize: '16px',
                    marginTop: '10px',
                  }}
                >
                  <FormLabel id="teste">
                    A data de início define a partir de quando as comissões em
                    aberto são calculadas
                  </FormLabel>
                </Typography>
              </Paper>
            </div>
          </Paper>

          <Stack
            spacing={2}
            direction="row"
            justifyItems="center"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableButton}
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '400',
                width: '120px',
                marginRight: '20px',
              }}
            >
              {disableButton ? (
                <CircularProgress
                  style={{ color: '#fff', width: '30px', height: '30px' }}
                />
              ) : (
                <>{valueDrawer ? 'Salvar' : 'Avançar'}</>
              )}
            </Button>
          </Stack>

          <Snackbar
            open={message}
            autoHideDuration={3000}
            onClose={() => {
              setMessage(false)
            }}
          >
            <Alert severity={message === 'error' ? 'error' : 'success'}>
              {message === 'error' ? errMsg : message}
            </Alert>
          </Snackbar>
        </>
      )}
    </form>
  )
}

export default Steps1
