import PetControllerClass from "./PetController";
import CustomerControllerClass from "./CustomerController";
import ProcedureControllerClass from "./ProcedureController";
import ScheduleControllerClass from "./ScheduleController";

let CLSchedule = null;
let CLCustomer = null;
let CLPet = null;
let CLProcedure = null;

export function ScheduleController(schedulingName, ...args) {
  if (!CLSchedule && !schedulingName) {
    throw new Error(
      "Controllers - ScheduleController: schedulingName não informado!"
    );
  }
  if (CLSchedule && !schedulingName) {
    return CLSchedule;
  }
  if (CLSchedule && CLSchedule.schedulingName === schedulingName) {
    return CLSchedule;
  }

  CLSchedule = new ScheduleControllerClass(schedulingName, ...args);

  return CLSchedule;
}

export function CustomerController() {
  if (CLCustomer) {
    return CLCustomer;
  }
  CLCustomer = new CustomerControllerClass();
  return CLCustomer;
}

export function PetController() {
  if (CLPet) {
    return CLPet;
  }
  CLPet = new PetControllerClass();

  return CLPet;
}

export function ProcedureController() {
  if (CLProcedure) {
    return CLProcedure;
  }
  CLProcedure = new ProcedureControllerClass();

  return CLProcedure;
}
