import _ from "lodash";

import { SubscriptionDREController } from "../../../../services/controllers/subscription";

import { BlockTable } from "../../../../components/ui/BlockTable";

export function DashboardDRE() {
  const CLDre = SubscriptionDREController()
  CLDre.init()

  return (
    <div className="w-full flex flex-col gap-4 flex-[9]">
      <BlockTable.Root name={CLDre._blockTableName} />
    </div>
  )
}
