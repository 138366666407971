import { Text, View, Image } from '@react-pdf/renderer';

import { formatCellPhoneNumber, getDepartmentName } from '../../../../services/functions';

import { useMerchant } from '../../../../store/global/useMerchant';

import {
  container,
  line,
  highlight,
  storeDataContent,
  storeData,
  storeName,
  storeLogo,
  img,
} from './styles';


const department = getDepartmentName()
const headerLogo = `/img/${department}/logo-dark.png`

export function HeaderStore() {
  const merchant = useMerchant.getState().data.merchant


  return (
    <View style={container}>
      <View style={storeData}>
        <Text style={storeName}>{merchant.name.fullName}</Text>
        <View style={storeDataContent}>
          <Text style={line} >
            {merchant.location.street}, {merchant.location.number}
            {merchant.location?.complement && <Text> / {merchant.location.complement}</Text>}
          </Text>
          <Text style={line}>{merchant.location.neighborhood}, {merchant.location.city} / {merchant.location.uf}, {merchant.location.postalCode}</Text>
        </View>
        <View>
          {merchant.contact.phone && <Text style={line}><Text style={highlight}>Telefone: </Text>{formatCellPhoneNumber(merchant.contact.phone)}</Text>}
          {merchant.contact.whatsapp && <Text style={line}><Text style={highlight}>Whatsapp: </Text>{formatCellPhoneNumber(merchant.contact.whatsapp)}</Text>}
        </View>
      </View>
      <View style={storeLogo} >
        <Image style={img} src={headerLogo} alt="" />
      </View>
    </View>
  )
}
