import { FaWhatsapp } from "react-icons/fa";
import { CalendarPlus, ClipboardX, Eye } from "lucide-react";

import { VACCINATION_STATUS_ACTIONS } from "../../../../../store/reports/veterinary/constants";

import { ReportVaccinationsController } from "../../../../../services/controllers/veterinary/reports";

import { Action } from "../../../../../components/ui/Action";

import { Container } from "./styles";
import { OldDrawer } from "../../OldDrawer";
import { useState } from "react";

export function VaccinationActions({ vaccination }) {
  const oldDrawerState = useState(false);

  const CLReportVaccinations = ReportVaccinationsController();

  return (
    <>
      <Container>
        {VACCINATION_STATUS_ACTIONS[vaccination.status]?.includes("edit") && (
          <Action.Root
            outline
            type="button"
            name={`${vaccination.id}-create-schedule`}
            title="Criar agendamento"
            onClick={() => oldDrawerState[1]("CRIAR AGENDAMENTO")}
          >
            <Action.Icon icon={CalendarPlus} size={18} />
          </Action.Root>
        )}

        {!VACCINATION_STATUS_ACTIONS[vaccination.status]?.includes("edit") && (
          <Action.Root
            outline
            type="button"
            name={`${vaccination.id}-see-schedule`}
            title="Ver agendamento"
            disabled={
              !VACCINATION_STATUS_ACTIONS[vaccination.status]?.includes("see")
            }
            onClick={() => oldDrawerState[1]("VER AGENDAMENTO")}
          >
            <Action.Icon icon={Eye} size={18} />
          </Action.Root>
        )}

        <Action.Root
          outline
          type="button"
          status="danger"
          name={`${vaccination.id}-cancel`}
          title="Cancelar"
          disabled={
            !VACCINATION_STATUS_ACTIONS[vaccination.status]?.includes("cancel")
          }
          onClick={() => CLReportVaccinations.cancelVaccinations(vaccination)}
        >
          <Action.Icon icon={ClipboardX} size={18} />
        </Action.Root>

        <Action.Root
          outline
          type="button"
          status="success"
          name={`${vaccination.id}-send-whatsApp`}
          title="Enviar para WhatsApp"
          disabled={
            !VACCINATION_STATUS_ACTIONS[vaccination.status]?.includes("send")
          }
          onClick={() => CLReportVaccinations.sendMessage(vaccination)}
        >
          <Action.Icon icon={FaWhatsapp} size={18} />
        </Action.Root>
      </Container>
      <OldDrawer
        title={oldDrawerState[0]}
        drawerState={oldDrawerState}
        vaccinationId={vaccination.id}
        recordId={vaccination.recordId}
      />
    </>
  );
}
