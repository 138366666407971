import React, { useState } from 'react'

export const MainCheckinContext = React.createContext({})

export function MainCheckinProvider({ children }) {
  const [openModalCheckin, setOpenModalCheckin] = useState(false)

  return (
    <MainCheckinContext.Provider
      value={{ openModalCheckin, setOpenModalCheckin }}
    >
      {children}
    </MainCheckinContext.Provider>
  )
}

export const useMainCheckin = () => React.useContext(MainCheckinContext)
