import qs from 'qs'
import { ThemeContext } from 'styled-components'

import moment from 'moment'
import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSchedule } from '../../../../hooks/ScheduleContext'
import { DatePicker } from '../../../../components/organisms/DatePicker/DatePicker'

import { Container } from './styles'

export function HeaderDateSelect() {
  const location = useLocation()
  const history = useHistory()
  const queryString = location.search.replace('?', '')
  const { date } = qs.parse(queryString)

  const theme = useContext(ThemeContext)
  const { updateConfig, getConfig } = useSchedule()

  const config = getConfig()

  const updateDate = (newDate) => {
    const date = moment(newDate).format('YYYY-MM-DD')
    updateConfig({ date })
  }

  useEffect(() => {
    if (date) {
      updateDate(date)
      history.push('/agenda')
    }
  }, [date])

  return (
    <Container>
      <DatePicker
        selectDate={new Date(`${config.date} 00:00:00`)}
        labelFormat="yyy"
        startDate={new Date('2020-05-06')}
        color={theme.colors.scale[400]}
        hover={theme.colors.scale[600]}
        days={3000}
        dateActive={new Date(`${config.date} 00:00:00`)}
        setDateActive={updateDate}
        marked={[
          {
            date: new Date(2020, 9, 3),
            marked: true,
            style: {
              color: '#ff0000',
              padding: '2px',
              fontSize: 12,
            },
            text: '1x',
          },
          {
            date: new Date(2026, 9, 4),
            marked: true,
            text: '5x',
          },
        ]}
      />
    </Container>
  )
}
