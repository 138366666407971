import styled, { css } from 'styled-components'
import { darken } from 'polished'
import InputMask from 'react-input-mask'

import {
  Content,
  Arrow as RadixArrow,
  Close as RadixClose,
} from '@radix-ui/react-popover'

export const Container = styled(Content)`
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  width: 400px;
  z-index: 21;
  @media (max-height: 760px) {
    &.scheduled {
      width: 650px;
    }
  }
`

export const Arrow = styled(RadixArrow)`
  fill: ${(props) => props.theme.colors.background};
`
export const CloseButton = styled(RadixClose)`
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${(props) => props.theme.colors.scale[500]};
  position: absolute;
  border: none;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 100ms linear;
  &:hover {
    transform: scale(1.2);
    color: ${(props) => props.theme.colors.scale[800]};
  }
  &:active {
    transform: scale(0.85);
    color: ${(props) => props.theme.colors.scale[400]};
  }
`
export const PopoverContent = styled.div``

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  gap: 20px;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[500]};
`

export const HeaderAvatar = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
  font-size: 40px;
  font-weight: bold;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.background};
  background: ${(props) => props.theme.colors.scale[400]};
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const ProfileBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
`

export const HeaderTitle = styled.h2`
  font-size: 20px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.black};
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-transform: capitalize;
  display: flex;
  gap: 10px;
  color: ${(props) => props.theme.colors.gray[700]};
  svg {
    font-size: 20px;
  }
  &.subTitle {
    font-size: 14px;
    line-height: 13px;
    color: ${(props) => props.theme.colors.gray[500]};
    font-weight: normal;
    svg {
      font-size: 16px;
    }
  }
`

export const Information = styled.div`
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-areas:
    'h d'
    's s'
    'a a'
    'f f';
  grid-template-columns: 1fr 100px;
`

export const InformationHeader = styled.div`
  grid-area: h;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const InformationTime = styled.p`
  margin: 0 0 10px 0;
  font-size: 16px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 17px;
  color: ${(props) => props.theme.colors.gray[400]};
`

export const InformationService = styled.div`
  grid-area: s;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  p {
    font-size: 16px;
    margin: 0;
    padding-left: 10px;
    position: relative;
    color: ${(props) => props.theme.colors.black};
    &:first-letter {
      text-transform: uppercase;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 5px;
      height: 5px;
      transform: translateY(-50%);
      border-radius: 50%;
      background: currentcolor;
    }
  }
`

export const InformationData = styled.div`
  grid-area: d;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
`

export const InformationDataBadge = styled.span`
  background: ${(props) => props.theme.colors.highlights[500]};
  color: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border-radius: 10px;
`

export const InformationDataValue = styled.p`
  margin: 20px 0 0 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
`

export const InformationEdit = styled.div`
  grid-area: f;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  padding: 20px 10px 0 10px;
  border-top: 1px solid ${(props) => props.theme.colors.gray[300]};
  &.scheduled {
    @media (max-height: 760px) {
      display: inline-grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        't c'
        'e c'
        'a c';
    }
  }
`

export const InformationEditLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  flex: 1;
  grid-area: t;
  @media (max-height: 760px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const InformationEditLineCalendar = styled(InformationEditLine)`
  justify-content: center;
  grid-area: c;
`
export const InformationEditActionLine = styled(InformationEditLine)`
  grid-area: a;
  margin: 0;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 10px;
`

export const InformationEditInput = styled(InputMask)`
  display: flex;
  flex: 1;
  width: 100%;
`

const button = css`
  background: ${(props) => props.theme.colors.success};
  color: ${(props) => props.theme.colors.background};
  height: 40px;
  width: 100%;
  display: flex;
  flex: 1;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background 150ms linear;

  &:hover {
    background: ${(props) => darken(0.1, props.theme.colors.success)};
  }
  &:active {
    transform: scale(0.97);
  }
  &:focus {
    position: relative;
  }
`

export const ActionButton = styled.button`
  ${button}
`

export const CancelButton = styled(RadixClose)`
  ${button}
  background: ${(props) => props.theme.colors.error};
  &:hover {
    background: ${(props) => darken(0.1, props.theme.colors.error)};
  }
`

export const ThemeButton = styled.button`
  ${button}
  background: ${(props) => props.theme.colors.scale[props.scale]};
  &:hover {
    background: ${(props) =>
      darken(0.1, props.theme.colors.scale[props.scale])};
  }
`
