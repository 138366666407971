
import React from "react";
import CountUp from "react-countup";
import { UserCheck, PiggyBank, AlertTriangle, Percent} from "lucide-react";
import { Summary } from "../../../../components/ui/Summary";
import { SubscriptionIndicatorController } from "../../../../services/controllers/subscription";
import { useIndicators } from "../../../../store/subscription/useIndicators";
import { formatMoney } from "../../../../services/functions";


export function DashboardSummary(){
  const {
    subscribers,
    invoicing,
    cancellations,
    margin,
  } = useIndicators(st => st.data)

  const CLSubscriptionIndicator = SubscriptionIndicatorController()
   CLSubscriptionIndicator.init()

  if(!subscribers) CLSubscriptionIndicator.getSubscribers()
  if(!invoicing) CLSubscriptionIndicator.getInvoicing()
  if(!cancellations) CLSubscriptionIndicator.getCancellations()
  if(!margin) CLSubscriptionIndicator.getMargin()

  return (
    <Summary.Root>
      <Summary.Box status={subscribers.assinantes >=subscribers.meta ? 'up' : 'down' }>
        <Summary.Icon icon={UserCheck} />
        <Summary.TextContent>
          <Summary.Title size='big'>
            <CountUp
              end={subscribers.assinantes || 0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Total de Assinantes</Summary.Text>
          <Summary.SubTitle>Meta: {subscribers.meta}</Summary.SubTitle>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box status={invoicing.receitaTotal >= invoicing.meta ? 'up' : 'down' }>
        <Summary.Icon icon={PiggyBank} />
        <Summary.TextContent>
          <Summary.Title size='big'>
            R${" "}
            <CountUp
              end={invoicing.receitaTotal || 0}
              separator="."
              decimals={2}
              decimal=","
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Faturamento</Summary.Text>
          <Summary.SubTitle>Meta: R$ {formatMoney(invoicing.meta)}</Summary.SubTitle>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box status={cancellations.cancelamentos <= cancellations.meta ? 'up' : 'down' }>
        <Summary.Icon icon={AlertTriangle} />
        <Summary.TextContent>
          <Summary.Title size='big'>
            <CountUp
              end={cancellations.cancelamentos || 0}
              decimals={0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Cancelamentos</Summary.Text>
          <Summary.SubTitle>Limite: {cancellations.meta}</Summary.SubTitle>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box status={margin.margem >= margin.meta  ? 'up' : 'down' }>
        <Summary.Icon icon={Percent} />
        <Summary.TextContent>
          <Summary.Title size='big'>
            <CountUp
              end={margin.margem || 0}
              decimals={0}
              preserveValue
              useEasing
            />%
          </Summary.Title>
          <Summary.Text>Margem</Summary.Text>
          <Summary.SubTitle>Referência: {margin.meta * 100}%</Summary.SubTitle>
        </Summary.TextContent>
      </Summary.Box>
    </Summary.Root>
  )
}
