import styled from "styled-components";
import { rgba } from "polished";
import { BubbleMenu } from "@tiptap/react";

export const Menu = styled(BubbleMenu)`
  background: ${(props) => props.theme.colors.background};
  width: fit-content;
  border: 1px solid ${(props) => props.theme.colors.scale[200]};
  border-radius: 5px;
  padding: 3px;
  display: flex;
  gap: 3px;
  box-shadow: 3px 3px 7px 2px
    ${(props) => rgba(props.theme.colors.gray[800], 0.4)};
`;

export const MenuGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuButton = styled.button`
  background: ${(props) => props.theme.colors.background};
  border: none;
  display: flex;
  padding: 6px 8px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.scale[600]};
  transition: all ${(props) => props.theme.animation.delay.normal};
  border-left: 1px solid ${(props) => props.theme.colors.scale[100]};
  background: ${(props) => rgba(props.theme.colors.scale[100], 0.4)};
  &:first-child {
    border-left: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &.is-active,
  &:hover:not(:disabled) {
    background: ${(props) => props.theme.colors.scale[300]};
    color: ${(props) => props.theme.colors.background};
  }
  &:disabled {
    cursor: default;
    filter: grayscale(1);
  }
`;
