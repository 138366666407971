import { useSubscribers } from "../../../../store/subscription/useSubscribers";

import { SubscriberController } from "../../../../services/controllers/subscription";

import { Alert } from "../../../../components/ui/Alert";

export function AlertChangePlan(){
  const details = useSubscribers(st => st.data.details)

  const CSubscriber = SubscriberController();

  if(!details) return null

  return (
      <Alert.Root name={CSubscriber.changeSubscriptionPlanAlertName}>
        <Alert.Content>
          <Alert.Title>Troca de Plano</Alert.Title>
          <Alert.Description>
            <span>Ao clicar em <strong className="highlight">Enviar SMS/Email</strong>, será enviada uma mensagem para o cliente {details.customer.name.fullName}. O que irá acontecer:</span>
          </Alert.Description>
          <ul>
            <li>Cliente irá receber por SMS e por Email um link para <strong className="highlight">trocar o plano da Assinatura</strong>.</li>
            <li>Quando entrar nesse link, o cliente deverá confirmar a solicitação da troca de plano.</li>
            <li>A troca é irreversível ate a próxima cobrança.</li>
            <li>O link expira em até 24 horas.</li>

         </ul>
          <Alert.Actions>
           <Alert.Cancel>Cancelar</Alert.Cancel>
            <Alert.Confirm
              onClick={() =>CSubscriber.changeSubscriberPlan(false,true)}
            >
              Enviar SMS/Email
            </Alert.Confirm>
          </Alert.Actions>
        </Alert.Content>
      </Alert.Root>
  )
}
