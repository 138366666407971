import _ from "lodash";
import moment from "moment";
import Mustache from "mustache";

import { getPaginationProps, splitListToPages } from "../../../functions";

import { useVaccinations } from "../../../../store/reports/veterinary/useVaccinations";
import { useMerchant } from "../../../../store/global/useMerchant";

import { ReportVaccinationsModel } from "../../../models/veterinary/reports";

import { wppTemplates } from "../../../templates/wpp";

export default class ReportVaccinationsController {
  _defaultFilter = {
    dateStart: moment().startOf("month"),
    dateEnd: moment(),
    status: "*",
    vaccine: "*",
    protocol: "*",
    customerName: "",
    petName: "",
  };

  setVaccinations = useVaccinations.getState().actions.setVaccinations;
  setFilteredVaccinations = useVaccinations.getState().actions.setFilteredVaccinations;
  setPagedVaccinations = useVaccinations.getState().actions.setPagedVaccinations;
  setVaccinationsFilter = useVaccinations.getState().actions.setVaccinationsFilter;
  setVaccinationsFilterProps = useVaccinations.getState().actions.setVaccinationsFilterProps;

  setPagination = useVaccinations.getState().actions.setPagination;
  setReFetchStatus = useVaccinations.getState().actions.setReFetchStatus;
  setLoadingStatus = useVaccinations.getState().actions.setLoadingStatus;
  setWaitingStatus = useVaccinations.getState().actions.setWaitingStatus;

  constructor() {
    this._name = "veterinary-report-vaccinations";
    this._tableListName = `${this._name}-table`;
    this._tableListPaginationName = `${this._tableListName}-pagination`;

    this._sendProgrammedVaccinationTemplate =
      wppTemplates.draMei.sendProgrammedVaccination;

    this.MReportVaccinations = ReportVaccinationsModel();
  }

  init = _.once(() => {
    this.setFilter(this._defaultFilter);
    this.findVaccines();
    this.findVaccinations();
  });

  setFilterReFetch = (filterValue) => {
    this.setReFetchStatus(true);
    this.setFilter(filterValue);
  };

  setFilter = (filterValue) => {
    this.setVaccinationsFilter(filterValue);
  };

  setPage = (page) => {
    this.setPagination({ page });
  };

  splitPages = () => {
    const { perPage } = getPaginationProps(this._tableListPaginationName);

    const filteredVaccinations =
      useVaccinations.getState().data.filteredVaccinations;

    const pages = splitListToPages(filteredVaccinations, perPage);
    const totalPages = _.size(pages);

    this.setPagination({ totalPages: totalPages - 1 });

    this.setPagedVaccinations(pages);
  };

  findVaccines = async () => {
    this.setVaccinationsFilterProps({ vaccines: false });
    this.setFilter({ vaccine: "*" });

    const vaccines = await this.MReportVaccinations.getVaccines();

    if (!!vaccines) this.setVaccinationsFilterProps({ vaccines });
  };

  findProtocols = async (vaccineId) => {
    this.setVaccinationsFilterProps({ protocols: false });
    this.setFilter({ protocol: "*" });

    if (!vaccineId) return;

    const protocols = await this.MReportVaccinations.getProtocols(vaccineId);

    if (!!protocols) this.setVaccinationsFilterProps({ protocols });
  };

  findVaccinations = async () => {
    this.setWaitingStatus(true);

    const filter = useVaccinations.getState().data.filter;

    const vaccinations = await this.MReportVaccinations.getVaccinations(filter);

    this.setWaitingStatus(false);
    if (!!vaccinations) this.setVaccinations(vaccinations);

    this.filterVaccinationList();
  };

  filterVaccinationList = () => {
    const vaccinations = useVaccinations.getState().data.vaccinations;
    const filter = useVaccinations.getState().data.filter;

    this.setFilteredVaccinations([]);

    if (!vaccinations || !vaccinations.length) return;

    const filteredVaccinations = _.filter(vaccinations, (vaccination) => {
      return _.every([
        _.includes(
          vaccination.customer.name.filterString,
          filter.customerName.toLowerCase().replace(/[^a-z0-9]/g, "")
        ),
        _.includes(
          vaccination.pet.filterString,
          filter.petName.toLowerCase().replace(/[^a-z0-9]/g, "")
        ),
        vaccination.status === filter.status || filter.status === "*",
        vaccination.vaccine.id === filter.vaccine || filter.vaccine === "*",
        vaccination.protocol.id === filter.protocol || filter.protocol === "*",
      ]);
    });

    const sortedVaccinations = _.orderBy(
      filteredVaccinations,
      (vaccination) => moment(vaccination.date, "DD/MM/YYYY"),
      "asc"
    );

    this.setFilteredVaccinations(sortedVaccinations);
    this.splitPages();
  };

  filterVaccinations = async (e) => {
    if (e) e.preventDefault();
    this.setLoadingStatus(true);

    const reFetch = useVaccinations.getState().data.reFetch;

    if (reFetch) await this.findVaccinations();

    this.setPagination({ page: 0 });
    this.setReFetchStatus();
    this.filterVaccinationList();
    this.setLoadingStatus(false);
  };

  sendMessage = (sendVaccination) => {
    const merchant = useMerchant.getState().data.merchant;

    const programmedVaccinationData = {
      tutorName: sendVaccination.customer.name.first,
      petArticle: "do(a)",
      petName: sendVaccination.pet.name,
      storeName: merchant.name,
      storeAddress: `${merchant.location.street}, ${merchant.location.number} - ${merchant.location.neighborhood} - ${merchant.location.city}`,
    };

    const message = Mustache.render(
      this._sendProgrammedVaccinationTemplate.content,
      programmedVaccinationData
    );

    window.open(
      `https://web.whatsapp.com/send?phone=+55${sendVaccination.customer.phone}&text=${message}`,
      "_blank"
    );
  };

  cancelVaccinations = async (cancelVaccination) => {
    const vaccinations = useVaccinations.getState().data.vaccinations;

    const vaccinationsRemoved = vaccinations.map((vaccination) => {
      if (vaccination.id !== cancelVaccination.id) return vaccination;
      return {
        ...vaccination,
        status: "canceled",
      };
    });

    this.setVaccinations(vaccinationsRemoved);
    this.filterVaccinationList();

    const response = await this.MReportVaccinations.cancelVaccinations(
      cancelVaccination
    );

    if (!response) {
      this.setVaccinations(vaccinations);
      this.filterVaccinationList();
    }
  };
}
