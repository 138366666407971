import _ from "lodash";
import qs from "qs";
import { toast } from "react-toastify";

import API from "../api";


import { useSchedule } from "../../store/schedule/useSchedule";

import { format } from "../formatResponses/schedule";

import { useCustomer } from "../../store/customer/useCustomer";
import { usePet } from "../../store/customer/usePet";

export class Schedule {
  setDetailsData = useSchedule((st) => st.actions.setDetails);
  setSchedulePet = useSchedule((st) => st.actions.setPet);
  setProcedureData = useSchedule((st) => st.actions.setProcedure);
  setAttendingHistory = useSchedule((st) => st.actions.setAttendingHistory);
  setSchedulePetWeightHistory = useSchedule(
    (st) => st.actions.setPetWeightHistory
  );

  setAppointmentId = useSchedule((st) => st.actions.setAppointment);
  setDisabledStatus = useSchedule((st) => st.actions.setDisabledEdit);
  setAppointmentDateTime = useSchedule((st) => st.actions.setDateTime);

  setServiceToRemove = useSchedule((st) => st.actions.setServiceToRemove);
  setServiceToAdd = useSchedule((st) => st.actions.setServiceToAdd);

  setPetLits = useSchedule((st) => st.actions.setPets);
  setProcedureList = useSchedule((st) => st.actions.setProcedures);
  setEmployeesList = useSchedule((st) => st.actions.setEmployees);
  setServicesList = useSchedule((st) => st.actions.setServices);

  constructor() {
    this.pets = [];
    this.pet = null;
    this.procedure = null;
    this.dateTime = null;
  }

  setPets(pets) {
    this.pets = pets || [];
    this.setPetLits(this.pets);
  }

  pushPets(pets) {
    this.setPetLits([...this.pets, ...pets]);
  }

  setPet(pet) {
    this.pet = pet;
    this.setSchedulePet(pet);
  }

  setAppointment(appointmentId) {
    this.setAppointmentId(appointmentId);
  }

  setProcedures(procedures) {
    this.setProcedureList(procedures);
  }

  setEmployees(employees) {
    this.setEmployeesList(employees);
  }

  setServices(services) {
    this.setServicesList(services);
  }

  setDetails(details) {
    this.setDetailsData(details);
  }

  setProcedure(procedure) {
    if (procedure.data) {
      const procedureData = this.selectProcedureById(procedure.data.id);
      procedure.data = { ...procedure.data, ...procedureData };
    }

    this.setProcedureData(procedure);
  }

  selectProcedureById(procedureId) {
    const procedures = useSchedule.getState().data.customer.procedures;
    const procedureData = procedures.find(({ id }) => id === procedureId);

    return procedureData;
  }

  selectEmployeeById(employeeId) {
    const employees = useSchedule.getState().data.customer.employees;
    const employeeData = employees.find(({ id }) => id === employeeId);

    return employeeData;
  }

  setDisabledEdit(status) {
    this.setDisabledStatus(status);
  }

  async findPets() {
    const { customer } = useCustomer.getState().data;
    const { pet } = usePet.getState().data;

    const find = {
      cpf: customer.cpf,
      customerName: customer.name,
      cellphone: customer.cellphone,
      petName: pet.name,
    };

    const findQuery = qs.stringify(_.omitBy(find, _.isEmpty));

    if (!findQuery) {
      toast.error(
        "Por favor, preencha pelo menos um dos campos para iniciar a busca pelo Pet!",
        { theme: "colored" }
      );
      this.setPets([]);
      return [];
    }

    // try {
    const { data } = await API.get(`/pets/search?${findQuery}`);
    const pets = format.pets(data.results);

    this.setPets(pets);
    return pets;
    // } catch (error) {
    //   cm
    //   toast.error(error.response.data.friendly_message, { theme: "colored" });
    //   this.setPets([]);
    // }
  }

  async findWeightHistory() {
    const petId = useSchedule.getState().data.pet?.id;
    if (!petId) return;

    try {
      const { data } = await API.get(`pet/${petId}/weight-history`);

      const formattedWeightHistory = format.petWeightHistory(data.result);

      this.setSchedulePetWeightHistory(formattedWeightHistory);
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  updateServices(services) {
    const procedure = useSchedule.getState().data?.procedure;
    const removeServices = _.difference(procedure.services, services);
    const addServices = _.difference(services, procedure.services);

    this.setServiceToRemove({ add: removeServices });
    this.setServiceToAdd({ add: addServices });

    const newProcedure = {
      ...procedure,
      services,
    };
    this.setProcedure(newProcedure);
  }

  async findProcedures(MDepartment) {
    const customerProcedures = useSchedule.getState().data.customer.procedures;
    const procedure = useSchedule.getState().data?.procedure;

    if (!MDepartment) { return customerProcedures }

    const procedures = await MDepartment.findProcedures();
    this.setProcedures(procedures);

    if (procedure && procedure.name) {
      this.setProcedure(procedure);
    }
    return procedures;
  }

  async findEmployees(MDepartment) {
    const customerEmployees = useSchedule.getState().data.customer.employees;
    if (!MDepartment) { return customerEmployees }

    const employees = await MDepartment.findEmployees();

    this.setEmployees(employees);
    return employees;
  }

  async findServices(MDepartment) {
    const customerServices = useSchedule.getState().data.customer.services;
    if (!MDepartment) { return customerServices }

    const services = await MDepartment.findServices();

    this.setServices(format.services(services));
    return services;
  }

  async saveNew(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }
    const newScheduleResponse = await MDepartment.saveNew()

    if (!newScheduleResponse) return false

    this.setAppointment(newScheduleResponse.appointmentId)
    return newScheduleResponse;
  }

  async find(MDepartment) {
    const { appointmentId } = useSchedule.getState().data;
    if (!appointmentId) return;

    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    const { pet, seller, procedure } = await MDepartment.find();

    this.setPet(pet);
    this.setDetails({ seller });
    this.setProcedure(procedure);
  }

  async update(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.update();
  }

  async checkIn(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.checkIn();
  }

  async checkOut(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.checkOut();
  }

  async delete(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.delete();
  }

  async reset(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.reset();
  }

  async startAttending(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.startAttending();
  }

  async finishAttending(MDepartment) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.finishAttending();
  }

  async removeService(MDepartment, serviceId) {
    if (!MDepartment) {
      throw new Error("Informe o model de Departamento");
    }

    return await MDepartment.serviceRemove(serviceId);
  }
}
