import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  box: {
    background: '#F6F6F6',
    width: '500px',
    borderRadius: '12px',
    overflow: 'hidden',
    display: 'flex',
    paddingBottom: '10px',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  header: {
    background: '#CCD219',
    marginTop: '0',
    padding: '5px 20px',
    color: '#ffffff',
    fontWeight: 'normal',
    fontSize: '18px',
  },
  line: {
    padding: '0 20px',
    color: '#666666',
    marginBottom: '10px',

    cursor: 'pointer',
  },
  inputRadio: {
    marginRight: '10px',
  },
}))
