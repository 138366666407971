import {
  FileType,
  FileImage,
  FileQuestion,
  FileText,
  ClipboardEdit,
  Syringe,
  FileSpreadsheet,
} from "lucide-react";

export function ListPreviewIcon({ type, ...rest }) {
  switch (type) {
    case 'xls':
      return <FileSpreadsheet {...rest} />;
    case 'doc':
      return <FileType {...rest} />;
    case 'img':
      return <FileImage {...rest} />;
    case 'pdf':
      return <FileText {...rest} />;
    case 'html':
      return <ClipboardEdit {...rest} />;
    case 'vaccine':
      return <Syringe {...rest} />;
    case 'unidentified':
      return <FileQuestion {...rest} />;
    default:
      return <FileQuestion {...rest} />;
  }
}


