import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Material ui - style
import { makeStyles } from '@material-ui/core/styles'

// Material ui - core
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from '@material-ui/core'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'

// Material ui - icones
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import API from '../../../services/api'

import ModalType from '../../atoms/Modal'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 300,
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
  title: {
    fontSize: '20px',
    textAlign: 'center',
  },
  divider: {
    margin: '35px -5px',
  },
}))

const ITEM_HEIGHT = 48

function PreScheduling({ service, getServices }) {
  const history = useHistory()
  const page = 0
  const rowsPerPage = 10
  const [appAppointments, setAppAppointments] = useState(false)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, service?.length - page * rowsPerPage)

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(false)
  const [error, setError] = React.useState(false)

  const onDecrement = (key) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(key)
  }

  const postActive = async (id, active) => {
    try {
      const data = await API.post(`service/${id}`, {
        active,
      })
      data?.satus === 201 && getServices()
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleClose = (id, active) => {
    setAnchorEl(null)
    setOpen(false)
    postActive(id, active)
  }

  useEffect(() => {
    async function getQueue() {
      try {
        const { data } = await API.get('reports/app-appointments')
        setAppAppointments(data?.results)
      } catch (err) {
        console.log('Erro:', err)
      }
    }
    getQueue()
  }, [])

  const confirmPreSchedule = async (id) => {
    try {
      const result = await API.post(`service-order/appointment/${id}/confirm`)
      setOpenModal(result?.data?.message)
      setError(false)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <Grid container spacing={3} style={{ width: '100%' }}>
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />

      <Grid item xs={12} spacing={3} style={{ padding: '40px' }}>
        <Typography
          gutterBottom
          variant="h5"
          component="h3"
          className={classes.title}
        >
          Pré Agendamento
        </Typography>

        <Divider className={classes.divider} />

        <TableContainer component={Paper} elevation={0}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 140 }}>Data</TableCell>
                <TableCell align="center">Hora</TableCell>
                <TableCell align="center">Cliente</TableCell>
                <TableCell align="center">Pet</TableCell>
                <TableCell align="center">Serviços</TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  Profissional
                </TableCell>
                <TableCell align="center">Duração</TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>

            {/* SKELETON */}
            {appAppointments ? (
              <TableBody>
                {appAppointments
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row?.id}>
                      <TableCell component="th" scope="row">
                        {row?.date}
                      </TableCell>
                      <TableCell align="center">{row?.startsAt}</TableCell>
                      <TableCell align="center">{row?.customerName}</TableCell>
                      <TableCell align="center">{row?.petName}</TableCell>
                      <TableCell align="center">{row?.services[0]}</TableCell>
                      <TableCell align="center">{row?.employee}</TableCell>
                      <TableCell align="center">{row?.duration} min</TableCell>
                      <TableCell align="center">
                        <>
                          <IconButton
                            aria-label="more"
                            aria-controls={row.id}
                            aria-haspopup="true"
                            onClick={onDecrement(row.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={row.id}
                            anchorEl={anchorEl}
                            keepMounted
                            open={row.id === open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem
                              key="ativo"
                              selected="ativo"
                              onClick={() => {
                                history.push(
                                  `/banho-tosa/check-in/order/${row?.orderId}`,
                                )
                              }}
                            >
                              Ver detalhes
                            </MenuItem>
                            <MenuItem
                              key="inativo"
                              selected="inativo"
                              onClick={() => confirmPreSchedule(row?.id)}
                            >
                              Confirmar
                              <br /> agendamento
                            </MenuItem>
                          </Menu>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default PreScheduling
