import { Container, HiddenInput } from "./styles";

export function FileRoot({
  children,
  field = "file_name",
  onChange: onChangeTrigger = () => null,
  ...rest
}) {
  const changeFile = (file, field = "file_name") => {
    if (!file) return;

    const formData = new FormData();
    const preview = URL.createObjectURL(file);

    formData.append("file", file);
    formData.append(field, file.name);

    onChangeTrigger({ formData, preview });
  };

  return (
    <Container>
      {children}
      <HiddenInput
        {...rest}
        onChange={(e) => changeFile(e.target.files[0], field)}
        type="file"
      />
    </Container>
  );
}
