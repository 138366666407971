import _ from 'lodash'
import qs from 'qs'

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import useSWR, { mutate } from 'swr'

import moment from 'moment'
import { toast } from 'react-toastify'
import { produce } from 'immer'

import { useThemes } from '../providers/theme'
import API from '../services/api'
import { secondsToHms, sizeBetween } from '../services/functions'
import { CALENDAR, CONFIGS } from '../styles/constant'

const ScheduleContext = createContext({})

function ScheduleProvider({ children }) {
  const { theme } = useThemes()
  const filerOptions = JSON.parse(localStorage.getItem('@PETLAND/filerOptions'))

  const [config, setConfig] = useState({
    department: CONFIGS[theme]?.department,
    links: CONFIGS[theme]?.links,
    date: moment(new Date()).format('YYYY-MM-DD'),
  })

  const [filter, setFilter] = useState({
    employeesWorkingOnly: filerOptions?.profissionais || false,
    statusFilter: filerOptions?.status || '*',
    visualByAppointments: filerOptions?.agendamentos || false,
  })

  const [exitSchedules, setExitSchedules] = useState(false)
  const [exitBlocks, setExitBlocks] = useState(false)
  const [employees, setEmployees] = useState(false)
  const [schedules, setSchedules] = useState(false)
  const [blocks, setBlocks] = useState(false)
  const [hours, setHours] = useState(false)

  const formatEmployees = useCallback((data) => {
    const employees = data.employees || data
    setEmployees(
      employees.map((employee) => {
        const id = employee.id || employee._id
        return {
          ...employee,
          id,
        }
      }),
    )
  }, [])

  const servcideNameFormat = useCallback(
    (serviceFullName) =>
      serviceFullName.split(' | ').map((service) => {
        const serviceName = service.split(' ')
        const formatName = `${serviceName[0]
          .charAt(0)
          .toUpperCase()}${serviceName[0].slice(1).toLowerCase()}`
        if (formatName === 'Tosa') {
          return `${formatName} ${serviceName[1].toLowerCase()}`
        }
        return formatName
      }),
    [],
  )

  const formatSchedules = useCallback(
    (data) => {
      const schedules = data.appointments || data.results
      setSchedules(
        schedules
          .map((schedule) => {
            const id = schedule.serviceOrderId
            const { date } = config
            const duration = schedule.finishesAt - schedule.startsAt
            const status =
              schedule.channel === 'APP' &&
                !schedule.confirmedByStore &&
                schedule.status === 'AGENDADO'
                ? 'PRE-AGENDADO'
                : schedule.status
            const totalServices = schedule.serviceName
              ? schedule.serviceName.split('|').length
              : 1
            const services = schedule.serviceName
              ? schedule.serviceName.split(' | ')
              : []
            const petFirstName = schedule.petName
              ? schedule.petName.split(' ')[0]
              : schedule.petName
            const customerFirstName = schedule.customerName
              ? schedule.customerName.split(' ')[0]
              : schedule.customerName
            const servicesFirstName = schedule.serviceName
              ? servcideNameFormat(schedule.serviceName)
              : ['']

            return {
              ...schedule,
              id,
              date,
              duration,
              status,
              services,
              totalServices,
              petFirstName,
              servicesFirstName,
              customerFirstName,
              type: 'schedule',
            }
          })
          .sort((a, b) => (a.startsAt > b.startsAt ? 1 : -1)),
      )
    },
    [config.date, servcideNameFormat],
  )

  const formatBlocks = useCallback((data) => {
    const blocks = data.blockedHours || data.results
    setBlocks(
      blocks.map((block) => {
        const id = block.blockId
        const status = CALENDAR.status.BLOQUEIO.key
        const duration = block.finishesAt - block.startsAt
        return {
          ...block,
          id,
          duration,
          status,
          type: 'block',
        }
      }),
    )
  }, [])

  const formatHours = useCallback((hours) => {
    const openGap = hours.finishesAt - hours.startsAt
    const totalDisplay = Math.ceil(openGap / hours.displayGap) + 2
    const displayGaps = Array(totalDisplay)
      .fill()
      .map((n, i) => {
        const display = i * hours.displayGap + hours.startsAt - 60 * 60
        const totalGaps = Math.ceil(hours.displayGap / hours.gap)
        const gaps = Array(totalGaps)
          .fill()
          .map((n, i) => i * hours.gap + display)
        return { display, gaps }
      })
    setHours({
      ...hours,
      displayGaps,
      openGap,
      totalDisplay,
    })
  }, [])

  const { data: swrEmployees, isLoading: employeesIsLoading } = useSWR(
    `calendar/employees?${qs.stringify({
      ..._.omit(config, 'links'),
      ...filter,
    })}`,
    (url) => API.get(url).then((res) => res.data),
  )
  const { data: swrHours, isLoading: hoursIsLoading } = useSWR(
    `calendar/store-hours?${qs.stringify({
      ..._.omit(config, 'links'),
      ...filter,
    })}`,
    (url) => API.get(url).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
  const { data: swrAppointments, isLoading: appointmentsIsLoading } = useSWR(
    `calendar/appointments?${qs.stringify({
      ..._.omit(config, 'links'),
      ...filter,
    })}`,
    (url) => API.get(url).then((res) => res.data),
  )
  const { data: swrBlocked, isLoading: blockedIsLoading } = useSWR(
    `calendar/employees-blocked-hours?${qs.stringify({
      ..._.omit(config, 'links'),
      ...filter,
    })}`,
    (url) => API.get(url).then((res) => res.data),
  )

  useEffect(() => {
    if (!employeesIsLoading) {
      formatEmployees(swrEmployees)
    }
  }, [employeesIsLoading, swrEmployees, formatEmployees])

  useEffect(() => {
    if (!appointmentsIsLoading) {
      formatSchedules(swrAppointments)
      setExitSchedules(false)
    }
  }, [appointmentsIsLoading, formatSchedules, swrAppointments])

  useEffect(() => {
    if (!blockedIsLoading) {
      formatBlocks(swrBlocked)
      setExitBlocks(false)
    }
  }, [blockedIsLoading, formatBlocks, swrBlocked])

  useEffect(() => {
    if (!hoursIsLoading) {
      formatHours({ ...CALENDAR.public.time, ...swrHours.storeHours[0] })
    }
  }, [formatHours, hoursIsLoading, swrHours])

  const validateScheuleHours = useCallback(
    (schedule) => {
      let error = false

      if (!schedule.startsAt || !schedule.finishesAt) {
        toast.error('Informe um Horário', { theme: 'colored' })
        error = true
      }

      if (schedule.startsAt > 86399) {
        toast.error('Horário Inicial inexistente', { theme: 'colored' })
        error = true
      }

      if (schedule.finishesAt > 86399) {
        toast.error('Horário Final inexistente', { theme: 'colored' })
        error = true
      }

      if (schedule.startsAt < hours.start) {
        toast.error(
          'Horário Inicial, fora do horário de funcionamento da loja ',
          { theme: 'colored' },
        )
        error = true
      }

      if (schedule.finishesAt > hours.finishesAt) {
        toast.error(
          'Horário Final, fora do horário de funcionamento da loja ',
          { theme: 'colored' },
        )
        error = true
      }

      if (schedule.startsAt > schedule.finishesAt) {
        toast.error('Horário Inicial não pode ser maior que o Final', {
          theme: 'colored',
        })
        error = true
      }
      if (schedule.startsAt === schedule.finishesAt) {
        toast.error('Os Horários não podem sem iguais', { theme: 'colored' })
        error = true
      }

      return error
    },
    [hours],
  )

  const formtScheduleToSend = useCallback((oldSchedule, newSchedule) => {
    const date = newSchedule.date || oldSchedule.date
    const employeeId = newSchedule.employeeId || oldSchedule.employeeId
    const roomId = newSchedule.roomId || oldSchedule.roomId
    const status = newSchedule.status || oldSchedule.status
    const startsAt = secondsToHms(
      newSchedule.startsAt || oldSchedule.startsAt,
    ).formated
    const finishesAt = secondsToHms(
      newSchedule.finishesAt || oldSchedule.finishesAt,
    ).formated

    return {
      date,
      employeeId,
      roomId,
      status,
      startsAt,
      finishesAt,
    }
  }, [])

  const gapIsAble = useCallback(
    (gap, employee) => {
      if (!hours) return
      if (hours.startsAt > gap || hours.finishesAt <= gap) return false

      const employeeShiftIsAble = !!employee.shifts.find(
        (employeeShift) =>
          gap >= employeeShift.startsAt && gap < employeeShift.finishesAt,
      )
      if (!employeeShiftIsAble) return false

      return true
    },
    [hours],
  )

  /// ----- Updates
  const updateScheduleStatus = useCallback(
    async (status, newSchedule) => {
      const oldSchedule = schedules.find(
        (schedule) => schedule.id === newSchedule.id,
      )

      setSchedules(
        produce(schedules, (draft) => {
          const index = draft.findIndex((d) => d.id === oldSchedule.id)
          draft[index] = { ...draft[index], status }
        }),
      )

      const url = CALENDAR.status[theme][status].url.replace(
        '{ID}',
        newSchedule.appointmentOrderId,
      )
      try {
        await API.post(url, { checkin: { checklist: [] }, comment: '' })
      } catch (err) {
        setSchedules(
          produce(schedules, (draft) => {
            const index = draft.findIndex((d) => d.id === oldSchedule.id)
            draft[index] = { ...draft[index], status: oldSchedule.status }
          }),
        )
        toast.error(err.response.data.friendly_message, {
          theme: 'colored',
        })
      }
    },
    [schedules, theme],
  )

  const setGhostSchedule = useCallback(
    (ghost) => {
      const ghostDefault = {
        appointmentOrderId: 'ghost_appointmentOrderId',
        customerFirstName: 'Tutor',
        customerName: 'Tutor',
        date: null,
        duration: 3600,
        startsAt: 32400,
        finishesAt: 36000,
        employeeId: null,
        id: 'ghost_id',
        isRecurring: false,
        petFirstName: 'Pet',
        petName: 'Pet',
        serviceName: '-',
        serviceOrderId: 'ghost_serviceOrderId',
        services: [],
        servicesFirstName: [],
        totalServices: 1,
        status: 'GHOST',
        type: 'schedule',
      }

      if (!schedules.length) {
        setSchedules([{ ...ghostDefault, ...ghost }])
        return
      }

      const ghostIndex = schedules.findIndex(
        (schedule) => schedule.id === 'ghost_id',
      )

      if (!~ghostIndex) {
        setSchedules(
          produce(schedules, (draft) => {
            draft.push({ ...ghostDefault, ...ghost })
          }),
        )
        return
      }

      setSchedules(
        produce(schedules, (draft) => {
          draft[ghostIndex] = { ...schedules[ghostIndex], ...ghost }
        }),
      )
    },
    [schedules],
  )

  const deleteGhostSchedule = useCallback(() => {
    const ghostIndex = schedules.findIndex(
      (schedule) => schedule.id === 'ghost_id',
    )
    if (!~ghostIndex) return

    setSchedules(
      produce(schedules, (draft) => {
        draft.splice(ghostIndex, 1)
      }),
    )
  }, [schedules])

  const updateFilter = useCallback((newData) => {
    setFilter((prev) => ({ ...prev, ...newData }))
  }, [])

  const updateConfig = useCallback((newData) => {
    setExitSchedules(true)
    setExitBlocks(true)
    setConfig((prev) => ({ ...prev, ...newData }))
  }, [])

  const revalidate = useCallback((key) => {
    mutate(key)
  }, [])

  const updateHours = useCallback(
    (value) => {
      const newData = [{ ...hours, ...value }]
      mutate(
        `calendar/store-hours?${qs.stringify({
          ..._.omit(config, 'links'),
          ...filter,
        })}`,
        { storeHours: newData },
        { revalidate: false },
      )
    },
    [config, hours, filter],
  )

  const deletBlock = useCallback(async (block, newData) => {
    const employeeId = block.employeeId || block.roomId
    await API.delete(
      `calendar/blocked-times?employeeId=${employeeId}&blockedId=${block.id}`,
    )
    return { blockedHours: newData }
  }, [])

  const removeBlock = useCallback(
    (block) => {
      const newData = produce(blocks, (draft) => {
        const index = draft.findIndex((d) => d.id === block.id)
        draft.splice(index, 1)
      })

      mutate(
        `calendar/employees-blocked-hours?${qs.stringify({
          ..._.omit(config, 'links'),
          ...filter,
        })}`,
        deletBlock(block, newData),
        {
          revalidate: false,
          optimisticData: { blockedHours: newData },
        },
      )
    },
    [blocks, config, deletBlock, filter],
  )

  const saveSchedule = useCallback(
    async ({ scheduleId, newValue, scheduleData, newData }) => {
      try {
        const body = formtScheduleToSend(scheduleData, newValue)
        await API.post(`service-order/${scheduleId}`, body)

        return { appointments: newData }
      } catch (err) {
        toast.error(err.response.data.friendly_message, {
          theme: 'colored',
        })
      }
    },
    [formtScheduleToSend],
  )

  const patchSchedule = useCallback(
    (scheduleId, newValue) => {
      if (validateScheuleHours(newValue)) return
      const scheduleData = schedules.find(
        (schedule) => schedule.id === scheduleId,
      )

      const newData = produce(schedules, (draft) => {
        const index = draft.findIndex((d) => d.id === scheduleData.id)
        const startsAt = newValue.startsAt || scheduleData.startsAt
        const finishesAt = newValue.finishesAt || scheduleData.finishesAt
        const duration = finishesAt - startsAt
        draft[index] = { ...draft[index], ...newValue, duration }
        if (draft[index].date !== scheduleData.date) {
          draft.splice(index, 1)
        }
      })

      mutate(
        `calendar/appointments?${qs.stringify({
          ..._.omit(config, 'links'),
          ...filter,
        })}`,
        saveSchedule({
          scheduleId,
          newValue,
          scheduleData,
          newData,
        }),
        {
          revalidate: false,
          optimisticData: { appointments: newData },
        },
      )
    },
    [config, filter, saveSchedule, schedules, validateScheuleHours],
  )

  /// ----- Gets
  const getEmployees = useCallback(() => employees, [employees])

  const getEmployeeById = useCallback(
    (employeeId) => {
      const list = employees
      return list.find((employee) => employeeId === employee.id)
    },
    [employees],
  )

  const getSchedules = useCallback(() => schedules, [schedules])

  const getBlocks = useCallback(() => blocks, [blocks])

  const getHours = useCallback(() => hours, [hours])

  const getFilter = useCallback(() => filter, [filter])

  const getExit = useCallback(
    () => ({ exitBlocks, exitSchedules }),
    [exitBlocks, exitSchedules],
  )

  const getConfig = useCallback(() => config, [config])

  const getBubbleInformation = useCallback(
    (bubble) => {
      const gapFromStart = (bubble.startsAt - hours.startsAt) % hours.gap
      const bubbleGaps = hours ? bubble.duration / hours.gap : 0
      const bubbleSize = bubbleGaps * 20
      const bubbleBlockGapFromStart = bubble.display
        ? ((bubble.startsAt - bubble.display) / hours.gap) * 20
        : 0
      const bubbleGapFromStart = (gapFromStart / hours.gap) * 20
      const bubbleType = sizeBetween(CALENDAR.sizes, bubbleSize)

      return {
        gapFromStart,
        bubbleGaps,
        bubbleSize,
        bubbleGapFromStart,
        bubbleType,
        bubbleBlockGapFromStart,
      }
    },
    [hours],
  )

  const getBubblesByEmployeeId = useCallback(({ employeeId, bubbles }) => {
    if (!bubbles.length) return []
    return bubbles.filter((bubble) => bubble.employeeId === employeeId)
  }, [])

  const getBubblesByRoomId = useCallback(({ roomId, bubbles }) => {
    if (!bubbles.length) return []
    return bubbles.filter((bubble) => bubble.roomId === roomId)
  }, [])

  const getBubbleById = useCallback(
    (id) => {
      const scheduleBubble =
        schedules.find((schedule) => schedule.id === id) || {}
      const blockBubble = blocks.find((block) => block.id === id) || {}

      const bubble = { ...scheduleBubble, ...blockBubble }

      return bubble
    },
    [blocks, schedules],
  )

  const getEmployeeByBubbleId = useCallback(
    (id) => {
      const schedule = schedules.find((schedule) => schedule.id === id)
      if (schedule) {
        return (
          employees.find((employee) => employee.id === schedule.employeeId) ||
          employees.find((employee) => employee.id === schedule.roomId)
        )
      }
      const block = blocks.find((block) => block.id === id)
      if (block) {
        return (
          employees.find((employee) => employee.id === block.employeeId) ||
          employees.find((employee) => employee.id === block.roomId)
        )
      }
    },
    [blocks, employees, schedules],
  )

  const getBubblesBetweenBubbleGap = useCallback(
    (bubble) => {
      if (!hours) return []
      const bubbleStart = bubble.startsAt - hours.startsAt
      const gapLine = bubbleStart / hours.gap
      const bubbleGap = hours.gap * gapLine + hours.startsAt

      const bubbles = [...schedules, ...blocks]

      const employeeBubbles = getBubblesByEmployeeId({
        employeeId: bubble.employeeId,
        bubbles,
      })
      const roomBubbles = getBubblesByRoomId({
        roomId: bubble.employeeId,
        bubbles,
      })

      return [...employeeBubbles, ...roomBubbles].filter((employeeBubble) => {
        const blockStart = bubbleGap
        const blockEnd = bubbleGap + hours.gap - 1
        return (
          employeeBubble.startsAt >= blockStart &&
          employeeBubble.startsAt < blockEnd
        )
      })
    },
    [hours, schedules, blocks, getBubblesByEmployeeId, getBubblesByRoomId],
  )

  const getBubblesBetweenBlock = useCallback(
    ({ blockGap, bubbles }) => {
      if (!hours) return []

      return bubbles.filter((bubble) => {
        const blockStart = blockGap
        const blockEnd = blockGap + hours.displayGap
        return bubble.startsAt >= blockStart && bubble.startsAt < blockEnd
      })
    },
    [hours],
  )

  useEffect(() => {
    const calendarFilter = qs.stringify({
      ..._.omit(config, 'links'),
      ...filter,
    })

    localStorage.setItem('@PETLAND/calendar-filter', calendarFilter)
  }, [config, filter])


  return (
    <ScheduleContext.Provider
      value={{
        patchSchedule,
        revalidate,
        setGhostSchedule,
        deleteGhostSchedule,
        updateScheduleStatus,
        updateConfig,
        updateHours,
        updateFilter,
        removeBlock,
        getEmployees,
        getExit,
        getEmployeeById,
        getHours,
        getFilter,
        getConfig,
        getSchedules,
        getBlocks,
        getBubblesByEmployeeId,
        getEmployeeByBubbleId,
        getBubblesByRoomId,
        getBubblesBetweenBubbleGap,
        getBubblesBetweenBlock,
        gapIsAble,
        getBubbleById,
        getBubbleInformation,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  )
}

function useSchedule() {
  const context = useContext(ScheduleContext)
  if (!context) {
    throw new Error('useSchedule must be used within an ScheduleProvider')
  }
  return context
}

export { useSchedule, ScheduleProvider }
