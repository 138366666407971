import { StepContentBox as Container } from "./styles";
import { useSteps } from "./useSteps";

export function StepsContent({ children, name, step }) {
  const allStepsList = useSteps((st) => st.data.steps);
  const stepsList = allStepsList[name];
  const that = stepsList.find((stepData) => stepData.title === step);

  const contentStatus = that.status === 1 ? "active" : "inactive";

  return <Container className={contentStatus}>{children}</Container>;
}
