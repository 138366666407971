import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import { Container, ActionButton, ActionCheck } from './styles'
import { useCrm } from '../../../../hooks/CrmContext'
import { ActionIcon } from '../../ActionIcon'

export function Actions({ actions }) {
  const { sendEvent, getDetailsCard, updateCard, getDetailsCardId } = useCrm()
  const { optout } = getDetailsCard()
  const detailsCardId = getDetailsCardId()

  const [optInOut, setOptInOut] = useState(!optout)

  const toggleOpt = () => {
    if (optInOut) {
      sendEvent({ eventName: 'opt-in' })
      updateCard(detailsCardId, { optout: false })
      setOptInOut(false)
    } else {
      sendEvent({ eventName: 'opt-out' })
      updateCard(detailsCardId, { optout: true })
      setOptInOut(true)
    }
  }

  const contact = (action) => {
    sendEvent(action.eventData)

    if (action.goToStage > 0) {
      updateCard(detailsCardId, { stageCode: action.goToStage })
    }
  }

  useEffect(() => {
    setOptInOut(optout)
  }, [optout])

  return (
    <Container>
      {!!actions &&
        actions.map((action) => {
          if (action.button === 'block-contact') {
            return (
              <ActionCheck key={action.name} onClick={toggleOpt}>
                {optInOut ? <CheckBox /> : <CheckBoxOutlineBlank />}
                {action.name}
              </ActionCheck>
            )
          }
          return (
            <ActionButton
              key={action.name}
              className={action.button}
              onClick={() => contact(action)}
            >
              <ActionIcon {...action} />
              {action.name}
            </ActionButton>
          )
        })}
    </Container>
  )
}
