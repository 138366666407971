import { toast } from "react-toastify";

import api from "../../api";
import { formatResponses } from "../../formatResponses/veterinaryCare";
import { formatBody } from "../../formatBody/veterinaryCare";

export default class VeterinaryRequestModel {
  constructor(orderId) {
    this._orderId = orderId;
  }

  findRequests = async () => {
    try {
      const { data } = await api.get(`/requests/${this._orderId}`);

      const formattedRequests = formatResponses.requests(data);

      return formattedRequests;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  deleteRequest = async (requestId) => {
    try {
      await api.delete(`request/${requestId}`);

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  sendRequestFile = async (requestId, formData) => {
    try {
      const { data } = await api.put(
        `request/${requestId}/pdf`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return data.pdfFile

    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveRequest = async (request) => {
    if (!request) return;
    try {
      const formattedValues = formatBody.request(request);
      const { data } = await api.post(`request/${this._orderId}`, formattedValues);

      const formattedRequest = formatResponses.request(data);

      return formattedRequest;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
