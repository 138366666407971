import {
  Event,
  AssignmentTurnedIn,
  Shower,
  DeleteForever,
  Error,
  PhoneAndroid,
  Vaccines,
  Healing,
} from '@mui/icons-material'

import { useThemes } from '../../../../../../../../providers/theme'
import { calendarStatusIfTheme } from '../../../../../../../../services/functions'

import { Container } from './styles'

export function BubbleIcon({ status, hiddenDescription = false }) {
  const { theme } = useThemes()
  const statusByTheme = calendarStatusIfTheme(theme, status)

  const statusIcon = (code) => {
    switch (code) {
      case 'scheduled':
        return <Event />
      case 'checkIn':
        if (theme === 'petland') {
          return <Shower />
        }
        return <Healing />
      case 'checkout':
        return <AssignmentTurnedIn />
      case 'blocked':
        return <DeleteForever style={{ fontSize: 'inherit' }} />
      case 'preScheduled':
        return <PhoneAndroid />
      case 'scheduledVaccine':
        return <Vaccines />
      default:
        return <Error />
    }
  }

  return (
    <Container>
      {statusIcon(statusByTheme?.code || 'checkIn')}
      {!hiddenDescription && <span>{statusByTheme.name}</span>}
    </Container>
  )
}
