
import { VeterinaryHistoryController } from "../../../../../services/controllers/VeterinaryCare";

import { Drawer } from "../../../../../components/ui/Drawer";
import { Accordion } from "../../../../../components/ui/Accordion";

import { HistoryViewRecord } from "./HistoryViewRecord";
import { HistoryViewVaccines } from "./HistoryViewVaccines";
import { HistoryViewServices } from "./HistoryViewServices";
import { HistoryViewRequests } from "./HistoryViewRequests";
import { HistoryViewAttachments } from "./HistoryViewAttachments";
import { HistoryViewPrescriptions } from "./HistoryViewPrescriptions";

import { Container } from "./styles";
import { useHistory } from "../../../../../store/service/veterinary/useHistory";

export function ViewHistoryCareDrawer() {
  const CLVetHistory = VeterinaryHistoryController();

  return (
    <>
      <Drawer.Root
        name={CLVetHistory._drawerName}
        style={{ width: "90vw" }}
        direction="left"
      >
        <Drawer.Controls   controls={{ close: CLVetHistory.closeDrawer }} />
        <Drawer.Header>Histórico de Atendimentos</Drawer.Header>
        <Drawer.Content>
          <Container>
            <Accordion.Root
              name="veterinary-care-history"
              options={{
                type: "multiple"
              }}
            >
              <HistoryViewRecord />
              <HistoryViewVaccines />
              <HistoryViewAttachments />
              <HistoryViewServices />
              <HistoryViewRequests />
              <HistoryViewPrescriptions />
            </Accordion.Root>
          </Container>
        </Drawer.Content>
        <Drawer.Footer />
      </Drawer.Root >
    </>
  );
}
