import Pica from 'pica'
import { toPng } from 'html-to-image'
import { printAssistentSocket } from '../sockets/print-assistent'

export class PrintImage {
  constructor(props) {
    this._querySelector = props.querySelector
    this._maxWidth = props.maxWidth
    this._multiple = props.multiple ?? 1
    this._bgColor = props.bgColor ?? '#FFFFFF'

    this._minHeight = localStorage.getItem('@PETLAND/printer-size')
    this._endSpace = localStorage.getItem('@PETLAND/printer-endSpace')

    if (!JSON.parse(localStorage.getItem('@PETLAND/printer-position'))) {
      localStorage.setItem('@PETLAND/printer-position', JSON.stringify({ cima: 0, baixo: 0, esquerda: 0, direita: 0 }))
    }

    if (this._minHeight === null) {
      this._minHeight = 600
      localStorage.setItem('@PETLAND/printer-size', this._minHeight)
    }

    if (this._endSpace === null) {
      localStorage.setItem('@PETLAND/printer-endSpace', 2)
    }
  }
  static init() {
    this._minHeight = localStorage.getItem('@PETLAND/printer-size')
    this._endSpace = localStorage.getItem('@PETLAND/printer-endSpace')

    if (!JSON.parse(localStorage.getItem('@PETLAND/printer-position'))) {
      localStorage.setItem('@PETLAND/printer-position', JSON.stringify({ cima: 0, baixo: 0, esquerda: 0, direita: 0 }))
    }

    if (this._endSpace === null) {
      localStorage.setItem('@PETLAND/printer-endSpace', 2)
    }
    if (this._minHeight === null) {
      this._minHeight = 600
      localStorage.setItem('@PETLAND/printer-size', this._minHeight)
    }
  }

  static position() {
    let position = JSON.parse(localStorage.getItem('@PETLAND/printer-position'))

    if (!position) {
      position = { cima: 0, baixo: 0, esquerda: 0, direita: 0 }
      localStorage.setItem('@PETLAND/printer-position', JSON.stringify(position))
    }

    return `${position.cima}px ${position.direita}px ${position.baixo}px ${position.esquerda}px`
  }

  static setPosition(data = {}) {
    let position = JSON.parse(localStorage.getItem('@PETLAND/printer-position')) || { cima: 0, baixo: 0, esquerda: 0, direita: 0 }
    const newPosition = { ...position, ...data }

    localStorage.setItem('@PETLAND/printer-position', JSON.stringify(newPosition))
  }

  static setSize(minHeight) {
    this._minHeight = +minHeight
    localStorage.setItem('@PETLAND/printer-size', this._minHeight)
  }

  getCanvas(width, height) {
    const canvas = document.createElement('canvas')

    if (width) canvas.width = width
    if (height) canvas.height = height

    const ctx = canvas.getContext('2d')
    if (!ctx) throw Error('Erro ao gerar canvas')

    return [canvas, ctx]
  }

  createImgBySrc(src) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = (err) => reject(err)

      img.src = src
    })
  }

  createImgByCanvas(canvas) {
    const image = new Image()
    image.src = canvas.toDataURL()

    return image
  }

  async createImg(data) {
    if (data instanceof HTMLCanvasElement) return this.createImgByCanvas(data)

    return await this.createImgBySrc(data)
  }

  async geImage(raw) {
    if (raw instanceof HTMLImageElement) return raw

    return await this.createImg(raw)
  }

  async resize(imageElement) {
    const pica = new Pica()

    const newWidth = this._maxWidth
    const newHeight = imageElement.height * (this._maxWidth / imageElement.width)

    const [resizeCanvas] = this.getCanvas(newWidth, newHeight)
    await pica.resize(imageElement, resizeCanvas)

    const paddedWidth = Math.ceil(newWidth / this._multiple) * this._multiple
    const baseHeight = Math.ceil(newHeight / this._multiple) * this._multiple
    const paddedHeight = baseHeight > +this._minHeight ? baseHeight : +this._minHeight

    const [canvas, ctx] = this.getCanvas(paddedWidth, paddedHeight)
    const xOffset = Math.floor((paddedWidth - resizeCanvas.width) / 2)

    ctx.fillStyle = this._bgColor
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(resizeCanvas, xOffset, 0)

    return canvas
  }

  async processImage(raw) {
    const imageElemet = await this.geImage(raw)
    const canvas = await this.resize(imageElemet)

    return this.createImgByCanvas(canvas)
  }

  async getBase64(image) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;

      ctx.drawImage(image, 0, 0);

      return canvas.toDataURL('image/png');

    });
  }

  async printFn() {
    const pages = document.querySelectorAll(this._querySelector)
    const screenElements = Array.from(pages)

    for (const screenElement of screenElements) {
      const imageInstance = await toPng(screenElement, {
        backgroundColor: "#FFFFFF",
        pixelRatio: 2
      })

      const imageElement = await this.processImage(imageInstance);
      const image = imageElement.src.replace(/^data:image\/[a-z]+;base64,/, '');

      await printAssistentSocket.send({
        action: 'ASSISTENT:PRINT',
        payload: {
          image,
        }
      });

    }
  }
}
