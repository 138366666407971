import React, { useContext } from 'react'

// Material ui - style
import { makeStyles, withStyles } from '@material-ui/core/styles'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'
import Pagination from '@material-ui/lab/Pagination'

// Material.ui
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  Paper,
  TableCell,
  TableBody,
  Grid,
  Button,
  Box,
} from '@material-ui/core'

// moment
import moment from 'moment'
import SnackbarComponent from '../../atoms/Snackbar'
import { ReportContext } from '../../../providers/report'
import 'moment/locale/pt-br'

moment.locale('pt-br')

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 700,
    border: '1px solid #ccd219',
  },
  tableContainer: {
    marginTop: '20px',
  },
  pagination: {
    marginTop: '20px',
  },
  filter: {
    margin: '30px 0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
    border: '1px solid #ccd219',
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #ccd219',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
    '& h6': {
      marginBottom: '-8px',
      color: '#000',
    },
    '& p': {
      margin: '0px',
      fontSize: '12px',
    },
  },
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ccd219',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
    lineHeight: '42px',
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function RelatorioPets({
  pagination,
  reportCustomers,
  setReportCustomers,
  getReportsCustomers,
}) {
  const classes = useStyles()
  const { postExport, setDisabledBtn, disabledBtn } = useContext(ReportContext)

  const handleChangePage = (event, newPage) => {
    setReportCustomers(null)
    getReportsCustomers(newPage)
  }

  return (
    <>
      <SnackbarComponent />
      <Grid container spacing={3} className={classes.filter}>
        <Grid item xs={5}>
          {/* <Controller
                        control={control}
                        defaultValue=""
                        name={`time`}
                        as={({ onChange, value }) => {
                            return (
                                <TextField
                                    fullWidth
                                    select
                                    value={value}
                                    label="Tempo do cliente"
                                    variant="outlined"
                                    onChange={e => {
                                        setValueClient(e.target.value);
                                        onChange(e.target.value);
                                    }}
                                >
                                    <MenuItem
                                        key="0-30"
                                        value="0-30"
                                    >
                                        Retornou há menos de 1 mês
                                    </MenuItem>
                                    <MenuItem
                                        key="30-60"
                                        value="30-60"
                                    >
                                        Ultima visita entre 30 e 60 dias
                                    </MenuItem>
                                    <MenuItem
                                        key="90-180"
                                        value="90-180"
                                    >
                                        Ultima visita entre 90 e 180 dias
                                    </MenuItem>
                                    <MenuItem
                                        key="180-5000"
                                        value="180-5000"
                                    >
                                        Ultima visita há mais de 180 dias
                                    </MenuItem>
                                </TextField>
                            )
                        }}
                    /> */}
        </Grid>
        <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: '100%' }}
          >
            <Box justifyContent="flex-end">
              {/* <Button
                                variant="contained"
                                onClick={() => handleClick()}
                                color="primary"
                                style={{
                                    background: "#CCD219",
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    fontWeight: "700",
                                    width: "138px",
                                    marginRight: "20px"
                                }}
                            >
                                Filtrar
                            </Button> */}
              <Button
                variant="outlined"
                color="primary"
                disabled={disabledBtn}
                onClick={() => {
                  postExport('pet')
                  setDisabledBtn(true)
                }}
                style={{
                  border: '1px solid #CCD219',
                  color: '#CCD219',
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  width: '120px',
                }}
              >
                {disabledBtn ? 'Exportando...' : 'Exportar'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.tableContainer}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
          tabIndex={-1}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ width: 150 }}>Nome</StyledTableCell>
              <StyledTableCell>Pets</StyledTableCell>
              <StyledTableCell style={{ width: 120 }} align="center">
                Telefone
              </StyledTableCell>
              <StyledTableCell align="center">
                Total Agendamento
              </StyledTableCell>
              <StyledTableCell align="center">Última Visita</StyledTableCell>
              <StyledTableCell align="center">Dias de ausência</StyledTableCell>
              <StyledTableCell align="center">Ticket Médio</StyledTableCell>
              <StyledTableCell align="center">Receita Total</StyledTableCell>
              <StyledTableCell align="center">Tem Pacote?</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {reportCustomers ? (
            <TableBody>
              {reportCustomers?.map((row) => (
                <StyledTableRow key={row.petNam}>
                  <StyledTableCell component="th" scope="row">
                    {row?.customerName}
                  </StyledTableCell>
                  <StyledTableCell>{row.petName}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.cellphone}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.totalAppointments}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {moment(row.lastAppointment).format('DD-MM-YYYY')}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.daysSinceLastAppointment}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.averageTicket?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.totalSales?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.hasPackage}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ justifyContent: 'center' }}
        className={classes.pagination}
      >
        {pagination && (
          <Pagination
            count={pagination.total_pages}
            variant="outlined"
            onChange={handleChangePage}
          />
        )}
      </Grid>
    </>
  )
}

export default RelatorioPets
