import _ from "lodash";
import Mustache from "mustache";
import { mutate } from "swr"

import { SCHEDULES_STATUS_NEXT } from "../../../store/schedule/constants";

import {
    clearNumber,
  dateFormatedToDB,
  getScheduleMessageTemplateByStatus,
  getSchedulingDepartmentStatusColor,
  getSchedulingStatusTitle,
  promiseDelay
} from "../../functions";

import {
  CustomerModel,
  MerchantModel,
  PetModel,
  ScheduleModel,
  VeterinaryModel,
} from "../../models";

import { useCustomer } from "../../../store/customer/useCustomer";
import { usePet } from "../../../store/customer/usePet";
import { useSchedule } from "../../../store/schedule/useSchedule";

import { Alert } from "../../../components/ui/Alert";
import { Gradient } from "../../../components/ui/Animation/Gradient";
import { Drawer } from "../../../components/ui/Drawer";
import { Steps } from "../../../components/ui/Steps";
import { useMerchant } from "../../../store/global/useMerchant";
import { formatTemplateData } from "../../formatTemplateData/veterinay";
import { WHATSAPP_SEND_MESSAGE_BASE } from "../../../styles/constant";
import { wppTemplates } from "../../templates/wpp";

export default class ScheduleController {
  setActive = Steps.store.getState().actions.setActive;
  alertIsOpen = Alert.store.getState().actions.isOpen;
  drawerIsOpen = Drawer.store.getState().actions.isOpen;
  gradientUpdateColors = Gradient.store.getState().actions.updateColors;
  gradientAnimateStart = Gradient.store.getState().actions.animateStart;
  gradientAnimateReset = Gradient.store.getState().actions.animateReset;

  setStatus = useSchedule.getState().actions.setStatus;
  setScheduleDrawerTitle = useSchedule.getState().actions.setTitle;
  isUpdated = useSchedule.getState().actions.isUpdated;

  clearCustomer = useCustomer.getState().actions.clear;
  clearSchedule = useSchedule.getState().actions.clear;
  clearPet = usePet.getState().actions.clear;

  constructor(schedulingName, department, calendarFilter) {
    this.schedulingName = schedulingName;
    this.department = department;
    this.calendarFilter = calendarFilter || false

    this.blocked = false;

    this.status = "newSchedule";
    this.nextStatus = SCHEDULES_STATUS_NEXT[this.status];

    this.incompleteAlertName = `${this.schedulingName}-incomplete`;
    this.sendBudgetAlertName = `${this.schedulingName}-send-budget`;

    this.stepsName = `${this.schedulingName}-steps`;
    this.drawerName = `${this.schedulingName}-drawer`;
    this.drawerGradientName = `${this.drawerName}-gradient`;
    this.drawerGradientHeaderName = `${this.drawerGradientName}::Header`;
    this.drawerGradientFooterName = `${this.drawerGradientName}::Footer`;

    this.MDepartment = VeterinaryModel();
    this.MMerchant = MerchantModel();

    this.MSchedule = ScheduleModel();
    this.MPet = PetModel();
    this.MCustomer = CustomerModel();

    this.updateColors();
  }

  drawerClose = async (force = false) => {
    const drawer = Drawer.store.getState().data.drawers[this.drawerName];
    if (!drawer.open) return true;

    const isUpdated = useSchedule.getState().data.updated;


    if (!force && !isUpdated) {
      this.alertIsOpen(this.incompleteAlertName, true);
      return;
    }

    this.drawerIsOpen(this.drawerName, false);

    await promiseDelay(300);
    return true;
  };

  drawerOpen = () => {
    this.drawerIsOpen(this.drawerName, true);
  };

  updateTitle = () => {
    const scheduleData = useSchedule.getState().data;
    const titleTemplate = getSchedulingStatusTitle(this.status)

    const title = Mustache.render(
      titleTemplate,
      { id: scheduleData.appointmentId }
    );

    this.setScheduleDrawerTitle(title)
  }

  setCalendarFilter = (calendarFilter) => {
    this.calendarFilter = calendarFilter
  }

  setStartStatus = (newStatus) => {
    this.status = newStatus;
    this.nextStatus = SCHEDULES_STATUS_NEXT[this.status];

    this.setStatus(newStatus);
    this.updateTitle()

    return this;
  };

  setDisabledEdit = (status) => {
    this.MSchedule.setDisabledEdit(status);
    return this;
  };

  setAppointment = (id) => {
    this.MSchedule.setAppointment(id);
    return this;
  };

  startAnimate = async () => {
    this.gradientAnimateStart(this.drawerGradientHeaderName);
    this.gradientAnimateStart(this.drawerGradientFooterName);

    return await promiseDelay(1000);
  };

  resetAnimate = () => {
    this.gradientAnimateReset(this.drawerGradientHeaderName);
    this.gradientAnimateReset(this.drawerGradientFooterName);
  };

  updateColors = () => {
    this.gradientUpdateColors(this.drawerGradientHeaderName, [
      getSchedulingDepartmentStatusColor(this.status),
      getSchedulingDepartmentStatusColor(this.nextStatus),
    ]);
    this.gradientUpdateColors(this.drawerGradientFooterName, [
      getSchedulingDepartmentStatusColor(this.status),
      getSchedulingDepartmentStatusColor(this.nextStatus),
    ]);

    this.resetAnimate()
  };

  init = async () => {
    this.isUpdated(true)
    this.updateColors();

    await Promise.all([
      this.findProcedures(),
      this.MMerchant.findSellers(),
      this.MPet.findAttributes(),
      this.MSchedule.findEmployees(this.MDepartment),
    ]);
    await this.find();

    this.MSchedule.findServices(this.MDepartment)

    this.updateTitle()
    this.drawerOpen();
    this.setDisabledEdit(true);
  };

  find = async () => {
    await this.MSchedule.find(this.MDepartment);
  };

  findProcedures = async () => {
    await this.MSchedule.findProcedures(this.MDepartment);
    return this;
  };

  destroy = async () => {
    await this.drawerClose(true);
    this.clearPet();
    this.clearCustomer();
    this.clearSchedule();
    this.setActive(this.stepsName, 0);
    this.isUpdated(true)
  };

  reFindEmployees = async () => {
    const { procedure } = useSchedule.getState().data;

    await this.MSchedule.findEmployees(this.MDepartment);

    this.MSchedule.setProcedure({ ...procedure, employee: null });
  };

  setDetails = (details) => {
    this.MSchedule.setDetails(details);
    return this;
  };

  revalidateCalendar = () => {
  };

  toNext = async () => {
    this.status = this.nextStatus

    this.updateTitle()

    this.setStatus(this.status);
    await this.startAnimate();

    this.updateColors()
    this.nextStatus = SCHEDULES_STATUS_NEXT[this.status];
    this.updateColors()

    this.isUpdated(true)
    this.updateTitle()
  }

  saveNew = async (e) => {
    if (e) e.preventDefault();


    // const appointment = {
        //   status,
        //   appointmentOrderId: "temp",
        //   serviceOrderId: "temp-0",
        //   startsAt: data.procedure.startsAt,
        //   finishesAt: data.procedure.finishesAt,
        //   serviceName: "Cirurgia",
        //   customerName: data.pet.formattedAttributes.owner.full,
        //   petName: data.pet.formattedAttributes.name,
        //   isRecurring: false,
        //   employeeName: data.procedure.employee.name,
        //   roomId: data.procedure.employee.id,
        //   employeeId: data.procedure.employee.id
        // }

    const newScheduleData = await mutate(`calendar/appointments?${this.calendarFilter}`, this.MSchedule.saveNew(this.MDepartment), { populateCache: false })

    if (!newScheduleData) return

    this.toNext()
  };

  sendCareSummary =  () => {
    const status = useSchedule.getState().data.status;

    const messageTemplate = getScheduleMessageTemplateByStatus(status)
    const messageContent  = wppTemplates.draMei[messageTemplate]?.content || ''

    const merchant = useMerchant.getState().data.merchant;
    const schedule = useSchedule.getState().data
    const ownerPhoneNumber = clearNumber(schedule.pet.formattedAttributes.ownerPhone);


    const care ={
      date: dateFormatedToDB( schedule.procedure.date),
      hour:schedule.procedure.time
    }

    const pet = {
      name:schedule.pet.formattedAttributes.name,
      owner: {
        name:{
          firstName: schedule.pet.formattedAttributes.owner.first,
          lastName: schedule.pet.formattedAttributes.owner.last,
          fullName: schedule.pet.formattedAttributes.owner.full,
        }
      },
      petAttributes: schedule.pet.formattedAttributes
    }

    const veterinarian = schedule.procedure.employee

    const careSummaryMessageData = formatTemplateData.sendCareSummary({
      pet,
      care,
      merchant,
      veterinarian,
    });

    const message = Mustache.render(
      messageContent,
      careSummaryMessageData
    );

    const wppMessageUrl = WHATSAPP_SEND_MESSAGE_BASE.replace(
      "[PHONE_NUMBER]",
      ownerPhoneNumber
    ).replace("[MESSAGE]", message);
    window.open(wppMessageUrl, "_blank");


  };


  update = async (e) => {
    if (e) e.preventDefault();
    await this.MSchedule.update(this.MDepartment);
  };

  delete = async (e) => {
    if (e) e.preventDefault();
    const isRemoved = await mutate(`calendar/appointments?${this.calendarFilter}`, this.MSchedule.delete(this.MDepartment), { populateCache: false })

    if (isRemoved) this.destroy();
  };

  reset = async (e) => {
    if (e) e.preventDefault();
    const isReseated = await mutate(`calendar/appointments?${this.calendarFilter}`, this.MSchedule.reset(this.MDepartment), { populateCache: false })

    if (isReseated) this.destroy();
  };

  checkIn = async (e) => {
    if (e) e.preventDefault();

    const checkInDone = await mutate(`calendar/appointments?${this.calendarFilter}`, this.MSchedule.checkIn(this.MDepartment), { populateCache: false })

    if (!checkInDone) return

    this.toNext()
  };

  startAttending = async (e) => {
    if (e) e.preventDefault();

    const isStarted = await mutate(`calendar/appointments?${this.calendarFilter}`, this.MSchedule.startAttending(this.MDepartment), { populateCache: false })

    if (!isStarted) return

    this.toNext()
  };

  finishAttending = async (e) => {
    if (e) e.preventDefault();

    const isFinished = await mutate(`calendar/appointments?${this.calendarFilter}`, this.MSchedule.finishAttending(this.MDepartment), { populateCache: false })

    if (!isFinished) return

    this.toNext()
  };

  sendBudget = async (e, reviewed = false) => {
    if (e) e.preventDefault();

    this.alertIsOpen(this.sendBudgetAlertName, true);


    if (!reviewed) return
    await this.MSchedule.update(this.MDepartment)

    const checkoutDone = await mutate(`calendar/appointments?${this.calendarFilter}`, this.MSchedule.checkOut(this.MDepartment), { populateCache: false })

    if (!checkoutDone) return

    this.toNext()
  };

  removeService = async (serviceId) => {
    await this.MSchedule.removeService(this.MDepartment, serviceId);
  };
}
