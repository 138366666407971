import API from './api'

export const getPets = async (
  cpf = '',
  nome = '',
  pet = '',
  cellphone = '',
) => {
  let data
  try {
    data = await API.get(
      `pets/search?${cpf !== '' ? `cpf=${cpf}&` : ''}${
        nome !== '' ? `customerName=${nome}&` : ''
      }${pet !== '' ? `petName=${pet}&` : ''}${
        cellphone !== '' ? `cellphone=${cellphone}` : ''
      }`,
    )
  } catch ({ response }) {
    console.log(response)
  }

  let {
    data: { results = [] },
  } = data

  results = results.map((result) => ({
    label: result?.pet?.name,
    id: result?.customer?.id,
    alreadyClient: result?.alreadyClient,
    pet: {
      id: result?.pet?.id,
      subscriptionImgUrl: result?.pet?.subscriptionImgUrl,
      subscriptionStatus: result?.pet?.subscriptionStatus,
      name: result?.pet?.name,
      petPhoto: result?.pet?.petPhoto,
      comments: result?.pet?.comments,
      specie: result?.pet?.attributes?.filter(
        (attribute) => attribute?.label === 'Espécie',
      )[0]?.value,
      size: result?.pet?.attributes?.filter(
        (attribute) => attribute?.label === 'Porte',
      )[0]?.value,
      breed: result?.pet?.attributes?.filter(
        (attribute) => attribute?.label === 'Raça',
      )[0]?.value,
      coat: result?.pet?.attributes?.filter(
        (attribute) => attribute?.label === 'Pelagem',
      )[0]?.value,
      subscription: result.subscription.petSubscription || false,
      futureProtocolVaccinations: result.futureProtocolVaccinations || false,
    },
    packages: result?.packages?.services,
    owner: result?.customer?.firstname,
    customerDefaultAddress: result?.customer?.customerDefaultAddress,
    email: result?.customer?.email,
    cpf: result?.customer?.cpf,
    phone: result?.customer?.phoneNumber,
    fullName: result?.customer?.fullName,
  }))

  return results
}

export const newPet = async (customerId, pet) => {
  try {
    const payload = {
      customerId,
      pet,
    }
    const result = await API.post('pets/new', payload)
    return result
  } catch (err) {
    return err?.response
  }
}

export const getAttribute = async (attribute) => {
  const {
    data: {
      label: { values = [] },
    },
  } = await API.get(`pet-attributes/${attribute}/values`)

  return values
}

export const getPetAttributes = async () => {
  const Espécie = await getAttribute('Espécie')
  const Sexo = await getAttribute('Sexo')
  const Porte = await getAttribute('Porte')
  const Raça = await getAttribute('Raça')
  const Pelagem = await getAttribute('Pelagem')
  const Castrado = await getAttribute('Castrado')

  return {
    Espécie,
    Sexo,
    Porte,
    Raça,
    Pelagem,
    Castrado,
  }
}
