export const messageSendRequest = {
  name: "Mensagem de envio de Requisição",
  gender: "female",
  content: `
  %0AOlá *{{tutorName}}* 🐶,
  %0A%0A*{{veterinaryName}}* da *{{storeName}}* enviou {{requestArticle1}} *{{requestName}}* {{petArticle}} *{{petName}}*, gerado em *{{{date}}}*. Você pode conferir {{requestArticle2}} acessando o link abaixo:
  %0A%0A{{{requestPath}}}
  %0A%0ASe precisar de alguma assistência ou tiver dúvidas, estamos à disposição.
  %0A%0AAtenciosamente,
  %0A*{{veterinaryName}}*
  %0A_{{storeName}}_ 🩺 🐾
  `.trim(),

};
