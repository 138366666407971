import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  & > button {
    max-width: min-content;
  }
`

export const BoxActions = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  flex: 1;
  gap: 8px;
  justify-content: flex-end;
  align-items: stretch;
  & > button {
    min-width: fit-content;
    width: 100%;
  }
`
