import { useHistory } from "react-router-dom";
import {
  AccessAlarm,
  Bookmarks,
  Notifications,
  Person,
  Pets,
  PhoneIphone,
  Repeat,
} from "@mui/icons-material";

import { VETERINARY_VIEW_ATTENDING } from "../../../../../../../store/service/veterinary/constants";
import { useThemes } from "../../../../../../../providers/theme";
import { useSchedule } from "../../../../../../../hooks/ScheduleContext";
import { ScheduleController } from "../../../../../../../services/controllers/scheduling";
import {
  calendarStatusIfTheme,
  secondsToHms,
} from "../../../../../../../services/functions";
import { useSchedule as scheduleStore } from "../../../../../../../store/schedule/useSchedule";
import { useUser } from "../../../../../../../store/global/useUser";

import { BubbleProvider } from "./BubbleProvider";

import { Container, Pet, Badge, Time, Services } from "./styles";

export function Schedule({ id, width }) {
  const CLScheduling = ScheduleController();

  const { theme } = useThemes();
  const history = useHistory();

  const { getBubbleById, getBubbleInformation, getExit, getConfig } =
    useSchedule();

  const userGroup = useUser(st => st.data.user.group);
  const { setOld } = scheduleStore((st) => st.actions);

  const { exitSchedules } = getExit();
  const config = getConfig();
  const bubble = getBubbleById(id);

  const statusByTheme = calendarStatusIfTheme(theme, bubble.status);

  const { bubbleType, bubbleGapFromStart } = getBubbleInformation(bubble);

  const scheduleLink = config.links.checkIn + '/' + bubble.appointmentOrderId

  const openDrawer = async () => {

    await CLScheduling.destroy();

    CLScheduling.setStartStatus(statusByTheme.code);
    CLScheduling.setAppointment(bubble.appointmentOrderId);

    setOld(scheduleLink);
    CLScheduling.init();
  };

  return (
    <BubbleProvider {...{ statusByTheme, id }}>
      <Container
        className={`${bubbleType} ${exitSchedules ? "exit" : ""}`}
        top={bubbleGapFromStart}
        width={width}
        status={statusByTheme?.code || "checkIn"}
        onDoubleClick={() => {
          if (theme === "petland") {
            history.push(scheduleLink);
          }
          if (theme === "draMei") {
            if (userGroup.type === 'veterinary') {
              if (VETERINARY_VIEW_ATTENDING.includes(statusByTheme.code)) {
                history.push(`/clinica/atendimento/${bubble.appointmentOrderId}`);
                return
              }
            }
            openDrawer();
          }
        }}
      >
        <Time>
          <AccessAlarm />
          <span>{secondsToHms(bubble.startsAt).formated}</span>{" "}
          <span>-{secondsToHms(bubble.finishesAt).formated}</span>
        </Time>
        <Pet>
          <span>
            <Pets />
            <em>{bubble.petFirstName.toLowerCase()}</em>
          </span>
          <span>
            <Person />
            <em>{bubble.customerFirstName.toLowerCase()}</em>
          </span>
        </Pet>
        <Badge status={statusByTheme?.code || "checkIn"}>
          {bubble.isRecurring && <Repeat />}
          {bubble.isPackage && <Bookmarks />}
          {bubble.channel === "APP" && <PhoneIphone />}
          {bubble.isSubscription && <Notifications />}
        </Badge>
        <Services status={statusByTheme?.code || "checkIn"}>
          <p>{bubble.servicesFirstName.join(", ")}</p>
        </Services>
      </Container>
    </BubbleProvider>
  );
}
