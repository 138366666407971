import {
  Root as PopoverRoot,
  Trigger as PopoverTrigger,
} from '@radix-ui/react-popover'
import {
  Provider as TooltipProvider,
  Root as TooltipRoot,
  Trigger as TooltipTrigger,
} from '@radix-ui/react-tooltip'

import { Tooltip } from './Tooltip'
import { SchedulePopover } from './SchedulePopover'

export function BubbleProvider({ children, ...props }) {
  const { statusByTheme, id, serviceOrderId } = props

  return (
    <TooltipProvider>
      <TooltipRoot>
        <PopoverRoot>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>{children}</PopoverTrigger>
          </TooltipTrigger>
          <Tooltip id={id} key={`tooltip-${serviceOrderId}`} />
          {statusByTheme?.code !== 'checkout' && (
            <SchedulePopover
              id={id}
              key={`schedulePopover-${serviceOrderId}`}
            />
          )}
        </PopoverRoot>
      </TooltipRoot>
    </TooltipProvider>
  )
}
