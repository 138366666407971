export const SCHEDULES_STATUS = [
  'newSchedule',
  'scheduled',
  'waitingStart',
  'checkIn',
  'finished',
  'checkout',
]

export const SCHEDULES_STATUS_NEXT = {
  newSchedule: "scheduled",
  scheduled: "waitingStart",
  waitingStart: "checkIn",
  checkIn: "finished",
  finished: "checkout",
  checkout: "newSchedule",
};

export const SCHEDULES_STATUS_MESSAGE = {
  newSchedule: {
    value: "Novo agendamento",
    color: "#00A1A4",
  },
  scheduled: {
    value: "Agendado",
    color: "#00A1A4",
  },
  waitingStart: {
    value: "A guardando Atendimento...",
    color: "#AD781A",
  },
  checkIn: {
    value: "Em Atendimento...",
    color: "#C64D21",
  },
  finished: {
    value: "Atendimento Finalizado ",
    color: "#3A8E00",
  },
  checkout: {
    value: "Orçamento enviado",
    color: "#00A1A4",
  },
};


export const SCHEDULE_STATUS_TITLE = {
  newSchedule: "Novo Agendamento",
  scheduled: "Agendamento #{{id}}",
  waitingStart: "Agendamento #{{id}} - Aguardando atendimento",
  checkIn: "Agendamento #{{id}} - Em Atendimento",
  finished: "Agendamento #{{id}} - Atendimento finalizado",
  checkout: "Agendamento #{{id}} - Orçamento enviado",
}


export const SCHEDULE_BILLING_ERRORS = {
  '*': 'Cobrança não autorizada pelo banco emissor.',
  '51': "Saldo insuficiente.",
}
