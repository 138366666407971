import { StyleSheet } from "@react-pdf/renderer";

import { getCurrentThemeStyle } from "../../../../services/functions";

const theme = getCurrentThemeStyle()

export const container = StyleSheet.create({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  textAlign: "right",
  height:"100%",
  fontSize: "10px",
  margin: "0",
  marginBottom: "auto",
  color: theme.colors.scale[700],
})

export const date = StyleSheet.create({
  fontSize: "10px",
  fontFamily: "Helvetica",
  color: theme.colors.scale[700],
})
