import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DirectionsCar from '@material-ui/icons/DirectionsCar'

// Material ui - core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  IconButton,
  Tooltip,
  CircularProgress,
  Snackbar,
} from '@material-ui/core'

// Material ui - icones
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import DeleteForever from '@material-ui/icons/DeleteForever'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'
import MuiAlert from '@material-ui/lab/Alert'

import moment from 'moment'
import ModalType from '../../atoms/Modal'
import { addItemOrder } from '../../../services/order'
import { getServices } from '../../../services/services'

import { TaxiDogProvider } from '../../../hooks/TaxiDogContext'
import TaxiDog from '../Agendamento/TaxiDog'
import DrawerType from '../../atoms/Drawer'
import API from '../../../services/api'
import 'moment/locale/pt-br'
import { AuthContext } from '../../../providers/auth'
import _ from 'lodash'
import { usePrinter } from '../../../store/schedule/usePrinter'

moment.locale('pt-br')

const useStyles = makeStyles(() => ({
  title: {
    color: '#ccc',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
  },
  espaco: {
    margin: '15px 0',
  },
  discount: {
    marginBottom: '-10px',
    textDecoration: 'line-through',
    color: '#878c93',
    fontSize: '11px',
  },
  assinante: {
    fontSize: '8px',
    background: '#f50057',
    color: '#fff',
    padding: '3px 6px',
    borderRadius: '37px',
    marginLeft: '20px',
  },
  buttonWhatsApp: {
    color: '#fff',
    fontSize: '12px',
    borderRadius: '7px',
    border: '0',
    backgroundColor: '#25d366',
    '&:hover': {
      backgroundColor: '#1dba58',
      border: 0,
      color: '#fff',
    },
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function Checkin({
  order,
  checklist,
  selectedCheckList,
  setSelectedCheckList,
  employeeId,
  setValueDescription,
  handleGetOrderUpdate,
  setLoadingService,
  loadingService,
  setLoading,
  loading,
}) {

  const classes = useStyles()
  const { modules } = useContext(AuthContext);

  const [showAddServices, setShowAddServices] = useState(false)
  const [services, setServices] = useState([])
  const servicesLoading = true
  const [selectedServices, setSelectedServices] = useState([])
  const [agendaModal, setAgendaModal] = React.useState(false)

  const [idDelete, setIdDelete] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [titleModal, setTitleModal] = React.useState(null)
  const [addPackage, setAddPackage] = React.useState(null)
  const [whatsAppList, setWhatsAppList] = React.useState(null)
  const [openTaxidogDrawer, setOpenTaxidogDrawer] = useState(false)

  // State Cupom
  const [activeCupom, setActiveCupom] = React.useState(false)
  const [valueCupom, setValueCupom] = React.useState(false)
  const [errCupom, setErrCupom] = React.useState(false)
  const [successCupom, setSuccessCupom] = React.useState(false)
  const [valueInfoCupom, setValueInfoCupom] = React.useState(false)

  function compareService(data) {
    const arrayFields = order?.services.map((item) => item.serviceId)
    const res = data.filter((item) => !arrayFields.includes(item.id))

    setServices(res)
  }

  async function handleServices() {
    const data = await getServices(order?.pet?.id)
    compareService(data)
  }

  useEffect(() => {
    if (order) handleServices()
  }, [order])

  useEffect(() => {
    usePrinter.getState().actions.setNewComment(null)
  }, [])

  const handleTextDescription = async (text) => {
    setValueDescription(text?.toString())
    usePrinter.getState().actions.setNewComment(text?.toString() || null)
    try {
      await API.put(`service-order/appointment/${order?.id}/add-note`, {
        comment: text?.toString(),
      })
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleNewServices = async () => {
    setLoadingService(true)
    const services = [
      {
        serviceId: selectedServices?.id,
        employeeId,
        durationInMinutes: selectedServices?.defaultDurationInMinutes,
        status: 'AGENDADO',
      },
    ]

    const data = await addItemOrder(order.appointmentOrderId, services)
    if (data?.status === 201) {
      setShowAddServices(false)
      setSelectedServices([])
      setOpenModal(data?.data?.message)
      setError(false)
      handleGetOrderUpdate()
    }

    setTimeout(() => {
      setLoadingService(false)
    }, 5000)
  }

  // Deletar Serviço
  const handleDelete = async (id) => {
    setTitleModal('Cancelar Serviço')
    setAgendaModal('Você tem certeza que deseja cancelar esse serviço?')
    setError(true)
    setIdDelete(id)
  }

  const handleAddPackage = async (id) => {
    setAgendaModal(
      'Você tem certeza que deseja associar o serviço a um novo pacote?',
    )
    setError(false)
    setAddPackage(id)
  }

  const closeModal = () => {
    setAgendaModal(false)
    setOpenModal(false)
    setTitleModal(null)
  }

  useEffect(() => {
    if (selectedServices?.id) handleNewServices()
  }, [selectedServices])

  const checklistWhatsApp = () => {
    const checkedList = checklist
      .filter((itemA) => selectedCheckList.find((itemB) => itemA._id === itemB))
      .map((val) => val.label)
    setWhatsAppList(checkedList)
  }

  useEffect(() => {
    if (selectedCheckList) checklistWhatsApp()
  }, [selectedCheckList])

  //
  const postDelete = async () => {
    setLoadingService(true)

    try {
      const result = await API.delete(`service-order/${idDelete}`)

      if (result?.status === 201) {
        closeModal()
        handleGetOrderUpdate()

        setTimeout(() => {
          setLoadingService(false)
        }, 5000)
      }
    } catch (err) {
      console.log('Erro:', err)
    }
  }
  //

  const postAddPackage = async () => {
    setLoadingService(true)

    try {
      const result = await API.post(`service-order/${addPackage}/bind-package`)
      if (result?.status === 201) {
        closeModal()
        handleGetOrderUpdate()

        setTimeout(() => {
          setLoadingService(false)
        }, 5000)
      }
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleRemoveCupom = async () => {
    setLoading(true)

    if (!order?.coupon) {
      setActiveCupom(false)
      setValueCupom(false)
      setErrCupom(false)
    }

    if (order?.coupon) {
      try {
        const result = await API.post('coupons/remove', {
          appointmentId: order?.id,
          code: valueCupom,
        })
        if (result?.status === 201) {
          setValueInfoCupom('Cupom removido com sucesso!')
          setSuccessCupom(true)

          handleGetOrderUpdate()
          setOpenModal(result?.data?.message)
          setError(false)
          handleGetOrderUpdate()

          setTimeout(() => {
            closeModal()
            setLoadingService(false)
            setLoading(false)

            setActiveCupom(false)
            setValueCupom(false)
            setErrCupom(false)
          }, 3000)
        }
      } catch (err) {
        setOpenModal(err?.response?.data?.friendly_message)
        setLoading(false)
        setError(true)
      }
    }
  }

  const handleCupom = async () => {
    setLoading(true)
    try {
      const result = await API.post('coupons/apply', {
        appointmentId: order?.id,
        channel: 'LOJA',
        code: valueCupom,
        petId: '',
        customerId: '',
        servicesId: '',
        appointmentDate: '',
      })

      if (result?.status) {
        setValueInfoCupom('Cupom inserido com sucesso!')
        setSuccessCupom(true)
        handleGetOrderUpdate()
      }

      setTimeout(() => {
        setLoading(false)
      }, 3000)

      setErrCupom(false)
    } catch (err) {
      setErrCupom(err?.response?.data?.friendly_message)
      setLoading(false)
    }
  }

  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      if (valueCupom) handleCupom()
      target.preventDefault()
    }
  }

  const updateTaxiDog = async (data) => {
    await API.post(`taxi-dog/appointment/${order.appointmentOrderId}`, data)
    setValueInfoCupom('Taxi Dog Atualizado ')
    setSuccessCupom(true)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const removeTaxidog = async (data) => {
    await API.delete(`taxi-dog/appointment/${order.appointmentOrderId}`, data)
    setValueInfoCupom('Taxi Dog Removiodo ')
    setSuccessCupom(true)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  useEffect(() => {
    if (order?.coupon) setValueCupom(order?.coupon)
  }, [order?.coupon])

  return (
    <TaxiDogProvider>
      <ModalType
        open={(agendaModal && true) || (openModal && true)}
        close={closeModal}
        title={titleModal}
        titleDescription={agendaModal || openModal}
        handleClick={
          agendaModal ===
            'Você tem certeza que deseja associar o serviço a um novo pacote?'
            ? postAddPackage
            : postDelete
        }
        err={error}
        oneButton={false}
        verification={agendaModal}
      />
      <DrawerType
        open={openTaxidogDrawer}
        setDrowerOpen={setOpenTaxidogDrawer}
        titleHeader="EDITAR TAXI DOG"
        anchor="right"
        content={
          <TaxiDog
            defautlt={order?.taxiDog}
            customer={{
              ...order?.customer,
              customerDefaultAddress: order?.customerDefaultAddress,
            }}
            setTaxiDogData={(data) => {
              updateTaxiDog(data)
            }}
            close={() => {
              setOpenTaxidogDrawer(false)
            }}
          />
        }
      />

      <div>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="medium" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Serviço</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Profissional</TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>

              {order?.services && !loadingService ? (
                <TableBody>
                  {order?.services.map((row) => (
                    <TableRow key={row.serviceName}>
                      <TableCell component="th" scope="row">
                        <div
                          style={{
                            justifyContent: 'left',
                            alignItems: 'center',
                          }}
                        >
                          {row.serviceName}
                          {row.subscriptionStatus && (
                            <span className={classes.assinante}>
                              ASSINATURA
                            </span>
                          )}
                          {row.hasPackage && (
                            <span className={classes.assinante}>PACOTE</span>
                          )}
                          {row.hasPackage && (
                            <p
                              style={{
                                marginTop: '0px',
                                marginBottom: '0px',
                                fontSize: '10px',
                                color: '#CCD219',
                              }}
                            >
                              Serviço com pacote disponível
                            </p>
                          )}
                        </div>
                      </TableCell>

                      <TableCell align="center">
                        {row?.discount === 0 ? (
                          Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(row?.totalPrice)
                        ) : (
                          <>
                            <p className={classes.discount}>
                              {Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(row?.originalPrice)}
                            </p>

                            <p>
                              {Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(row?.totalPrice)}
                            </p>
                          </>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.employeeName}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <IconButton
                          style={{ color: 'red' }}
                          aria-label="delete"
                          onClick={() => handleDelete(row.id)}
                        >
                          <DeleteForever />
                        </IconButton>

                        {row.hasPackage && (
                          <Tooltip
                            title="Associar o serviço a um novo pacote."
                            aria-label="add"
                          >
                            <IconButton
                              aria-label="add"
                              onClick={() => handleAddPackage(row.id)}
                            >
                              <LoyaltyIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </div>

      <div className={classes.espaco}>
        <Grid item xs={12}>
          <Button
            color="secondary"
            startIcon={<AddCircleIcon />}
            onClick={() => setShowAddServices(true)}
            style={{ fontSize: '12px', fontWeight: 'bold' }}
          >
            Adicionar mais serviços
          </Button>
        </Grid>
      </div>

      {showAddServices && (
        <Grid item xs={12}>
          <Autocomplete
            options={services}
            loading={servicesLoading}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <>
                {option.internalCode} -{option.name} R$
                {option.price}
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Serviços adicionais"
                placeholder="Procurar serviços adicionais"
                margin="normal"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              setSelectedServices(value)
            }}
          />
        </Grid>
      )}

      <div className={classes.espaco}>
        <Grid item xs={12}>
          <Button
            startIcon={<ConfirmationNumberIcon />}
            onClick={() => setActiveCupom(true)}
            style={{ fontSize: '12px', fontWeight: 'bold' }}
          >
            Cupom
          </Button>
        </Grid>
      </div>

      {(order?.coupon || activeCupom) && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          style={{
            marginBottom: '15px',
          }}
        >
          <Grid item xs={7}>
            <TextField
              id="cupom"
              type="text"
              label="Cupom"
              name="cupom"
              variant="outlined"
              fullWidth
              defaultValue={order?.coupon}
              disabled={order?.coupon}
              placeholder="Insira aqui seu cupom de desconto..."
              onKeyPress={handleKeyPress}
              onChange={({ target }) => setValueCupom(target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: loading && <CircularProgress />,
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#CCD219', color: '#fff' }}
              fullWidth
              onClick={() => handleCupom()}
            >
              APLICAR
            </Button>
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f50057', color: '#fff' }}
              onClick={() => handleRemoveCupom()}
            >
              REMOVER CUPOM
            </Button>
          </Grid>
        </Grid>
      )}

      {errCupom && (
        <Grid item xs={7}>
          <Alert severity="error">{errCupom}</Alert>
        </Grid>
      )}

      <Button
        startIcon={<DirectionsCar />}
        onClick={() => setOpenTaxidogDrawer(true)}
        style={{ fontSize: '12px', fontWeight: 'bold', color: '#EFD90F' }}
      >
        {!order?.taxiDog?.driverPickupType
          ? 'Adicionar Taxi Dog'
          : 'Editar Taxi Dog'}
      </Button>
      {order?.taxiDog?.driverPickupType && (
        <>
          <p style={{ margin: '0 0 0 6px' }}>
            <strong>Motorista: </strong> {order?.taxiDog?.driverName}
          </p>
          <p style={{ margin: '0 0 0 6px' }}>
            <strong>Valor: </strong>
            {order?.taxiDog?.total <= 0 && (
              <span
                style={{
                  background: '#f00',
                  borderRadius: '7px',
                  padding: '0px 10px',
                  color: '#fff',
                  fontWeight: 'bold',
                }}
              >
                Pacote
              </span>
            )}
            {order?.taxiDog?.total > 0 &&
              Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(order?.taxiDog?.total)}
          </p>
          <p style={{ margin: '0 0 0 6px' }}>
            <strong>Receber: </strong> {order?.taxiDog?.driverPickupType}
          </p>
          <p style={{ margin: '0 0 0 6px' }}>
            <strong>Tipo: </strong> {order?.taxiDog?.taxiDogType}
          </p>
          <p
            onClick={() => {
              removeTaxidog()
            }}
            style={{
              margin: '0 0 0 6px',
              color: '#F00E0E',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            REMOVER TAXI DOG
          </p>
        </>
      )}

      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        style={{
          marginBottom: '15px',
          width: '100%',
        }}
        className={classes.espaco}
      >
        <Grid item xs={8} className={classes.subtitle}>
          Checklist
        </Grid>
        {selectedCheckList?.length > 0 ? (
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Link
              style={{ textDecoration: 'none' }}
              to={{
                pathname: `https://wa.me/55${order?.customer.phoneNumber
                  .replace(/\D/g, '')
                  .trim()}?text=Ol%C3%A1%20${order?.customer.name
                    .split(' ')
                    .slice(0, 1)
                    .join(
                      ' ',
                    )},%20segue%20o%20resumo%20do%20seu%20Checklist:%0A%0A${whatsAppList?.map(
                      (item) => `%20${item}`,
                    )}%20Serviços: %0A%0A${order?.services.map(
                      (item) => `%20${item.serviceName}.`,
                    )}`,
              }}
              target="_blank"
            >
              <Button
                endIcon={<WhatsAppIcon />}
                type="submit"
                color="secondary"
                size="large"
                className={classes.buttonWhatsApp}
              >
                Enviar Checklist
              </Button>
            </Link>
          </Grid>
        ) : (
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Button
              endIcon={<WhatsAppIcon />}
              disabled
              type="submit"
              color="secondary"
              size="large"
              className={classes.buttonWhatsApp}
            >
              Enviar Checklist
            </Button>
          </Grid>
        )}

        <Grid xs={12}>
          <List>
            {checklist.map((item) => (
              <ListItem key={item._id} style={{ padding: '0px' }}>
                <ListItemIcon style={{ minWidth: '0px' }}>
                  <Checkbox
                    edge="start"
                    value={item._id}
                    tabIndex={-1}
                    disableRipple
                    onChange={(event, checked) => {
                      const currentValues = !checked
                        ? selectedCheckList.filter(
                          (item) => item !== event.target.value,
                        )
                        : [...selectedCheckList, event.target.value]

                      usePrinter.getState().actions.setChecklist(_.filter(checklist, (item) => _.includes(currentValues, item._id)))
                      setSelectedCheckList(currentValues)
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <span>{item.label}</span>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>

        {order && (
          <Grid item xs={12}>
            <TextField
              id="description"
              name="description"
              label="Descrição"
              defaultValue={order?.comment}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                usePrinter.getState().actions.setNewComment(e.target.value?.toString() || null)
              }}
              onBlur={({ target }) => handleTextDescription(target.value)}
            />
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={successCupom}
        autoHideDuration={3000}
        onClose={() => {
          setSuccessCupom(false)
        }}
      >
        <Alert severity="success">{valueInfoCupom}</Alert>
      </Snackbar>
    </TaxiDogProvider>
  )
}

export default Checkin
