import styled from 'styled-components'

export const Sidebar = styled.div`
  position: ${(props) => (props.closeMenu ? 'sticky' : 'fixed')};
  top: 0;
  left: 0;
  height: 100vh;
  width: ${(props) => (props.closeMenu ? '78px' : '305px')};
  background: ${(props) => props.theme.colors.background};
  z-index: 99;
  transition: all 0.5s ease;
  border-right: 1px solid ${(props) => props.theme.colors.primary};
  & .nav-links {
    & li {
      & a {
        & .link_name {
          color: ${(props) => props.theme.colors.primary};
        }
      }
      & .sub-menu {
        & .link_name {
          color: ${(props) => props.theme.colors.primary};
        }
        & a {
          color: ${(props) => props.theme.colors.primary};
        }
      }
      & .link_SubMenu {
        & .link_name {
          color: ${(props) => props.theme.colors.primary};
        }
        & a {
          color: ${(props) => props.theme.colors.primary};
        }
      }
      & i {
        color: ${(props) => props.theme.colors.primary};
      }
      & svg {
        height: 20px;
        min-width: 78px;
        text-align: center;
        line-height: 50px;
        color: ${(props) => props.theme.colors.primary};
        font-size: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }
  }

  & .logo-details {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;

    & img {
      color: #fff;
      height: 50px;
      min-width: 50px;
      text-align: center;
      line-height: 50px;
      margin-left: 14px;
    }

    & .logo_name {
      font-size: 35px;
      color: ${(props) => props.theme.colors.primary};
      margin-left: 10px;
      font-weight: 600;
      transition: 0.3s ease;
      transition-delay: 0.1s;
      border: none;
    }
  }
`

export const Text = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.background};
`
