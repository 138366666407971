import React, { useState } from 'react'

import API from '../../../../../services/api'

import styles from './styles'

function Edit({ data }) {
  const {
    packageId,
    serviceId,
    serviceName,
    quantityAvailable,
    quantitySold,
    closeUpdate,
  } = data
  console.log('🚀 ~ file: index.js:15 ~ Edit ~ data:', data)

  const [quantity, setQuantity] = useState(quantityAvailable)
  const [commentary, setCommentary] = useState(false)

  const {
    container,
    title,
    tableEdit,
    editTitle,
    tableBlock,
    inputItem,
    textArea,
    capiton,
    footer,
    cancel,
    save,
    disabled,
  } = styles()

  const updatePackage = async () => {
    try {
      await API.post(`/package-quantity/${serviceId}`, {
        packageId,
        serviceId,
        to: quantity,
        commentary,
      })
      closeUpdate()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className={container}>
        <h2 className={title}>
          {' '}
          Serviço:
          {serviceName}
        </h2>
        <div className={tableEdit}>
          <div className={tableBlock}>
            <h2 className={title}>Vendido</h2>
            <input
              className={inputItem}
              type="text"
              value={quantitySold}
              disabled
            />
          </div>
          <div className={tableBlock}>
            <h2 className={editTitle}>Disponível</h2>
            <input
              className={inputItem}
              type="text"
              onChange={(e) => {
                setQuantity(e.target.value < 0 ? 0 : e.target.value)
              }}
              value={quantity}
            />
          </div>
        </div>
        <textarea
          className={textArea}
          placeholder="Descreva o motivo da alteração"
          onChange={(e) => {
            setCommentary(e.target.value)
          }}
        />
        <em className={capiton}>Mínimo 5 caracteres</em>
        <em className={capiton}>Máximo 250 caracteres</em>
      </div>
      <div className={footer}>
        <button
          className={cancel}
          onClick={() => {
            closeUpdate()
          }}
        >
          Cancelar
        </button>
        <button
          className={
            quantity !== '' &&
            parseInt(quantity, 10) <= quantitySold &&
            parseInt(quantity, 10) !== quantityAvailable
              ? commentary.length >= 5 && commentary.length <= 250
                ? save
                : disabled
              : disabled
          }
          onClick={() => {
            updatePackage()
          }}
        >
          Salvar
        </button>
      </div>
    </>
  )
}

export default Edit
