import { RefreshCcw } from "lucide-react";

import { useMedicine } from "../../../../../../../store/global/useMedicine";
import { usePrescription } from "../../../../../../../store/service/veterinary/usePrescription";

import { VeterinaryPrescriptionController } from "../../../../../../../services/controllers/VeterinaryCare";
import { MedicineController } from "../../../../../../../services/controllers/global";

import { Input } from "../../../../../../../components/ui/Form/Input";
import { Action } from "../../../../../../../components/ui/Action";

import { Container } from "./styles";

export function MedicinePresentation() {
  const CLVetPrescription = VeterinaryPrescriptionController();
  const CLMedicine = MedicineController(CLVetPrescription._medicinesId);

  const presentation = usePrescription((st) => st.data.prescription.newMedicine.presentation);
  const medicineData = useMedicine((st) => st.data.medicineData);
  const foundPresentation = useMedicine((st) => st.data.foundPresentation);

  if (!medicineData) return null

  return (
    <Container>
      <Input.Root
        defaultFocused
        label="Apresentação e concentração"
        defaultValue={presentation}
        suggestions={foundPresentation}
        onSelected={(presentation) => CLVetPrescription.setNewMedicineProps({ presentation })}
        onChange={
          (presentation) => {
            CLMedicine.searchPresentation(presentation);
            CLVetPrescription.setNewMedicineProps({ presentation })
          }
        }
      />
      <Action.Root
        add
        type="button"
        status={300}
        className="reFindMedicine"
        onClick={() => {
          CLVetPrescription.clearNewMedicine()
          CLMedicine.deselectMedicine()
        }}
      >
        <Action.Icon icon={RefreshCcw} />
      </Action.Root>
    </Container>
  );

}
