import _ from "lodash";
import uuid from "react-uuid";

import { FilePlus } from "lucide-react";

import { TableItem } from "./TableItem";
import { useTable, get } from "./useTable";

import { Config } from "../Config";
import { TableConfig } from "./TableConfig";
import { useTableContext } from "./TableContext";

import { TableHeader as Container, TableHeaderButton } from "./styles";

export function TableHeader({ columns = [] }) {
  const { tableName } = useTableContext();
  const data = useTable((st) => st.data);
  const setColumns = useTable((st) => st.actions.setColumns);

  const table = get.table(data.tables, tableName);
  const columnsList = get.columns(data.columns[tableName], "*");

  setColumns(
    tableName,
    columns.map((column) => {
      if (!column) return { name: "", size: "" };
      const [name, size, hidden] = column.split(":");

      return {
        id:uuid(),
        name,
        size: size || "1fr",
        hidden: !!hidden || false,
      };
    })
  );

  const lastColumn = _.last(columnsList);
  const columnsSize = columnsList
    .filter((column) => !_.isEmpty(column.name.trim()))
    .map((column) => (!column.hidden ? column.size : "0fr"))
    .join(" ");

  const columnsStyle = `${columnsSize} ${table?.config && table?.customActions? "minmax(40px, 1fr)" : table?.config ? "40px" : "" } ${table?.customActions ? lastColumn.size : ""} ${table?.action ? "70px" : "" }`;

  return (
    <Container columns={columnsStyle}>
      {columnsList
        .filter((column) => !_.isEmpty(column.name.trim()))
        .filter((column) => column.name)
        .map((column) => (
          <TableItem
            key={`${tableName}-${column.id}`}
            tableName={tableName}
            style={{ maxWidth: column.size }}
          >
            {column.name.replace(/#/g, "")}
          </TableItem>
        ))}
      {!!table?.config && (
        <Config name={`${tableName}-config`} twoColunms={!!table?.customActions}>
          <TableConfig tableName={tableName} />
        </Config>
      )}
      {!!table?.action && (
        <TableHeaderButton type="button" data-hidden={table.action?.add === 'hidden'} disabled={!table.action?.add} onClick={table.action?.add}>
          <FilePlus size={20} />
        </TableHeaderButton>
      )}
    </Container>
  );
}
