import { useEffect, useRef } from "react";
import _ from "lodash";

import { formatSuggestions } from "../../../../../../services/functions";

import { SuggestionsItem } from "./SuggestionsItem";

import { InputText, SuggestionsContainer } from "../../styles";

export function InputTypeSuggestion({
  name,
  onChange: onChangeTrigger = () => null,
  onSelected = () => null,
  suggestions = [],
  defaultFocused = false,
  show = false,
  parser = {},
  custom,
  ...rest
}) {
  const suggestionSelected = useRef(null);
  const suggestionInputRef = useRef(null);

  const formattedSuggestions = formatSuggestions(suggestions, parser)
  const suggestionList = !!show? _.take(formattedSuggestions, show): formattedSuggestions

  useEffect(() => {
    if (defaultFocused) suggestionInputRef.current.focus()
  }, []);

  return (
    <>
      <InputText
        {...rest}
        placeholder=" "
        ref={suggestionInputRef}
        onChange={(e) => {
          onChangeTrigger(e.target.value)
        }}
        onBlur={(e) => {
          if (!e.target.value && !!suggestionSelected.current) {
            suggestionInputRef.current.value = suggestionSelected.current;
          }
        }}
      />
      <SuggestionsContainer>
        {suggestionList.map((suggestion) => (
          <SuggestionsItem
            key={suggestion.id}
            suggestion={suggestion}
            onClick={(suggestion) => {
              suggestionInputRef.current.blur();
              const root = document.getElementById("focusOut");
              root.focus();

              onSelected(suggestion.id);

              suggestionInputRef.current.value =
                suggestionSelected.current =
                suggestion.value;
            }}
          />
        ))}
        {custom}
      </SuggestionsContainer>
    </>
  );
}
