import styled, { css } from "styled-components";
import { rgba } from "polished";

import { Table } from "../../../../../components/ui/Table";


export const Container = styled.div`
  width: 100%;
`;

export const Line = styled(Table.Line)`

  &[data-status="new"], &[data-status="empty"] {
    background: ${(props) => rgba(props.theme.colors.warning, 0.2)};
  }
  &[data-package="true"] {
    background: ${(props) => rgba(props.theme.colors.scale[200], 0.2)};
  }
`;
