import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  container: {
    padding: '30px 0',
    minWidth: '570px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#666666',
    flex: '1',
  },
  tableEdit: {
    display: 'flex',
  },
  editTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#666666',
    flex: '1',
  },
  tableBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: '25px',
  },
  inputItem: {
    fontSize: '18px',
    textAlign: 'center',
    borderRadius: '5px',
    width: '60px',
    padding: '7px',
    border: '1px solid #666666',
  },
  textArea: {
    width: '100%',
    borderRadius: '5px',
    marginTop: '20px',
    height: '130px',
    maxHeight: '130px',
    minHeight: '130px',
    padding: '10px',
  },
  capiton: {
    width: '100%',
    color: '#999999',
    display: 'block',
    fontStyle: 'normal',
    fontSize: '13px',
  },
  footer: {
    padding: '30px',
    background: '#D7D7D7',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancel: {
    background: '#FFFFFF',
    border: '1px solid #CCD219',
    borderRadius: '5px',
    color: '#CCD219',
    padding: '15px 40px',
    margin: '0 10px',
    cursor: 'pointer',
  },
  save: {
    background: '#CCD219',
    border: '1px solid #CCD219',
    borderRadius: '5px',
    color: '#ffffff',
    padding: '15px 40px',
    margin: '0 10px',
    cursor: 'pointer',
  },
  disabled: {
    background: '#ccc',
    border: '1px solid #ccc',
    borderRadius: '5px',
    color: '#999999',
    padding: '15px 40px',
    margin: '0 10px',
  },
}))
