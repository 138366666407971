import { useRef } from 'react'
import { useParams } from 'react-router-dom'

import {
  Block,
  Check,
  DeleteForeverOutlined,
  SettingsBackupRestore,
  Close,
} from "@material-ui/icons";
import moment from "moment";
import { MenuItem, TextField } from "@material-ui/core";
import EditButton from "../EditButton";

import {
  Container,
  ProtocolActionButton,
  ProtocolButtonBox,
  ProtocoloHistoricList,
  ProtocoloHistoricListContent,
  ProtocoloHistoricListContentItem,
  ProtocoloHistoricListHeader,
  ProtocoloHistoricEditInput,
  ProtocoloHistoricEditInputMask,
  ProtocoloHistoricListHeaderItem,
  ProtocoloHistoricTitle,
  Button,
} from "./styles";
import { useVaccines } from "../../../../../store/protocols/useVaccines";
import { useProtocols } from "../../../../../store/protocols/useProtocols";
import { dateFormatedToDB } from "../../../../../services/functions";

export function ProtocoloHistoric() {
  const vaccinationDateRef = useRef(null)
  const applyDateRef = useRef(null)
  const laboratoryRef = useRef(null)
  const batchRef = useRef(null)
  const { orderId } = useParams()

  const {
    protocolList,
    active,
    actions: { interruptToggle, exclude },
  } = useProtocols();

  const {
    vaccinesList,
    actions: { updateState, update },
  } = useVaccines();

  const activeProtocol =
    !!protocolList && protocolList.find((protocol) => protocol.id === active);
  const vaccines =
    !!vaccinesList && !!activeProtocol
      ? vaccinesList.filter(
        (vaccine) => vaccine.protocolId === activeProtocol.id
      )
      : [];
  const appliedVaccine = vaccines.find(
    (vaccine) => vaccine.status === "applied"
  );

  const updateValues = (defaultValue) => {
    const apply =
      defaultValue.actionState === "apply" ? { status: "applied" } : {};

    const newValues = {
      vaccinationDate: vaccinationDateRef.current
        ? dateFormatedToDB(vaccinationDateRef.current.value)
        : dateFormatedToDB(defaultValue.vaccinationDateFormated),
      applicationDate: applyDateRef.current
        ? dateFormatedToDB(applyDateRef.current.value)
        : dateFormatedToDB(defaultValue.applicationDateFormated),
      laboratoryName: laboratoryRef.current
        ? laboratoryRef.current.value
        : defaultValue.laboratoryName,
      batch: batchRef.current ? batchRef.current.value : defaultValue.batch,
      ...apply,
      appointmentOrderId: orderId
    }
    update(defaultValue.id, newValues);
  };

  if (!activeProtocol) {
    return null;
  }

  return (
    <Container>
      <ProtocoloHistoricTitle>
        <h4>
          {activeProtocol.name} <span>{activeProtocol.description}</span>
        </h4>
        <ProtocolButtonBox>
          {!!appliedVaccine && !activeProtocol.interrupted && (
            <ProtocolActionButton
              className="base"
              onClick={() => {
                interruptToggle(activeProtocol.id);
              }}
            >
              <Block />
              Interromper
            </ProtocolActionButton>
          )}
          {!!activeProtocol.interrupted && (
            <ProtocolActionButton
              className="success"
              onClick={() => {
                interruptToggle(activeProtocol.id);
              }}
            >
              <SettingsBackupRestore />
              Retomar
            </ProtocolActionButton>
          )}
          {!appliedVaccine && (
            <ProtocolActionButton
              className="error"
              onClick={() => {
                exclude(activeProtocol.id);
              }}
            >
              <DeleteForeverOutlined />
              Excluir
            </ProtocolActionButton>
          )}
        </ProtocolButtonBox>
      </ProtocoloHistoricTitle>
      <ProtocoloHistoricList interrupted={activeProtocol.interrupted}>
        <ProtocoloHistoricListHeader>
          <ProtocoloHistoricListHeaderItem>
            Programação
          </ProtocoloHistoricListHeaderItem>
          <ProtocoloHistoricListHeaderItem>
            Aplicação
          </ProtocoloHistoricListHeaderItem>
          <ProtocoloHistoricListHeaderItem>
            Laboratório
          </ProtocoloHistoricListHeaderItem>
          <ProtocoloHistoricListHeaderItem>
            Lote
          </ProtocoloHistoricListHeaderItem>
          <ProtocoloHistoricListHeaderItem>
            Ações
          </ProtocoloHistoricListHeaderItem>
        </ProtocoloHistoricListHeader>
        {vaccines
          .filter((vaccine) => vaccine.status !== "cancel")
          .map((vaccine) => (
            <ProtocoloHistoricListContent
              key={vaccine.id}
              className={vaccine.status}
              expired={vaccine.expired}
            >
              <ProtocoloHistoricListContentItem>
                {["default", "edit", "apply"].includes(vaccine.actionState) &&
                  vaccine.vaccinationDateFormated}
                {["reschedule"].includes(vaccine.actionState) && (
                  <ProtocoloHistoricEditInputMask
                    mask="99/99/9999"
                    defaultValue={vaccine.vaccinationDateFormated}
                    ref={vaccinationDateRef}
                  />
                )}
              </ProtocoloHistoricListContentItem>
              <ProtocoloHistoricListContentItem>
                {["default", "edit", "apply"].includes(vaccine.actionState) &&
                  vaccine.applicationDateFormated}
                {["apply"].includes(vaccine.actionState) && (
                  <ProtocoloHistoricEditInputMask
                    mask="99/99/9999"
                    defaultValue={
                      vaccine.applicationDateFormated ||
                      moment().format("DD/MM/YYYY")
                    }
                    ref={applyDateRef}
                  />
                )}
              </ProtocoloHistoricListContentItem>
              <ProtocoloHistoricListContentItem>
                {["default", "reschedule"].includes(vaccine.actionState) &&
                  vaccine.laboratoryName}
                {["edit", "apply"].includes(vaccine.actionState) && (
                  <TextField
                    fullWidth
                    select
                    name="gap"
                    size="small"
                    variant="outlined"
                    defaultValue={vaccine.laboratoryName}
                    onChange={(e) => {
                      laboratoryRef.current = { value: e.target.value };
                    }}
                  >
                    {vaccine.laboratories.map((laboratory) => (
                      <MenuItem key={laboratory} value={laboratory}>
                        {laboratory}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </ProtocoloHistoricListContentItem>
              <ProtocoloHistoricListContentItem>
                {["default", "reschedule"].includes(vaccine.actionState) &&
                  vaccine.batch}
                {["edit", "apply"].includes(vaccine.actionState) && (
                  <ProtocoloHistoricEditInput
                    ref={batchRef}
                    defaultValue={vaccine.batch}
                    variant="outlined"
                  />
                )}
              </ProtocoloHistoricListContentItem>
              <ProtocoloHistoricListContentItem className="center">
                {!activeProtocol.interrupted && (
                  <>
                    {["default"].includes(vaccine.actionState) && (
                      <EditButton vaccine={vaccine} />
                    )}
                    {["edit", "reschedule", "apply"].includes(
                      vaccine.actionState
                    ) && (
                        <Button
                          className="confirm"
                          onClick={() => {
                            updateValues(vaccine);
                            updateState(vaccine.id, "default");
                          }}
                        >
                          <Check />
                        </Button>
                      )}
                    {["edit", "reschedule", "apply"].includes(
                      vaccine.actionState
                    ) && (
                        <Button
                          className="error"
                          onClick={() => {
                            updateState(vaccine.id, "default");
                          }}
                        >
                          <Close />
                        </Button>
                      )}
                  </>
                )}
              </ProtocoloHistoricListContentItem>
            </ProtocoloHistoricListContent>
          ))}
      </ProtocoloHistoricList>
    </Container>
  );
}
