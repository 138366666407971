import { Dog } from "lucide-react";

import { Tooltip } from "../Tooltip";

import { TimeLineBadge as Container, TimeLineBadgeTipContent } from "./styles";

export function TimeLineBadge({ id, name, icon: Icon = Dog, show }) {
  if (!show) return null;
  return (
    <Container>
      <Tooltip.Root zIndex={16} name={id} side="top" sideOffset={12}>
        <TimeLineBadgeTipContent>{name}</TimeLineBadgeTipContent>
      </Tooltip.Root>
      <Tooltip.Trigger tooltipName={id}>
        <Icon size={20} />
      </Tooltip.Trigger>
    </Container>
  );
}
