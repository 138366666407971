import React, { useEffect, useState } from 'react'

export const AuthContext = React.createContext({})

export function AuthProvider({ children }) {
  const [user, setUser] = useState({})
  const [modules, setModules] = useState({})
  const [vetUser, setVetUser] = useState('')
  const userStorage = localStorage.getItem('modules')
  const vet = localStorage.getItem('VET')

  useEffect(() => {
    if (userStorage) {
      setModules(JSON.parse(userStorage))
    } else {
      setModules({})
    }

    if (vet) {
      setVetUser(vet)
    } else {
      setVetUser('')
    }
  }, [userStorage, vet])

  return (
    <AuthContext.Provider
      value={{
        modules,
        setModules,
        vetUser,
        setUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)
