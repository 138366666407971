import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: 100% 0%;
  transition: all ${props => props.theme.animation.delay.fast} ease-in-out;
  ${props => props.showNewProtocol && css`
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  `}
`;

export const Preview = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-content: flex-start;
`;

export const Title = styled.h3`
  width: 100%;
  display: flex;
  gap: 8px;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.scale[500]};
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PreviewSeparator = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  margin: 0;
  border-top: 1px solid ${(props) => props.theme.colors.scale[300]};
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[500]};
`;

export const PreviewContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;
