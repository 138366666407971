import { getVaccinationStatusName } from "../../../../services/functions";

import { useVaccinations } from "../../../../store/reports/veterinary/useVaccinations";
import { ReportVaccinationsController } from "../../../../services/controllers/veterinary/reports";

import { Pagination } from "../../../../components/ui/Pagination";
import { Table } from "../../../../components/ui/Table";

import { VaccinationActions } from "./VaccinationActions";

import { Container, TableLine } from "./styles";

export function VaccinationList() {
  const isWaiting = useVaccinations((st) => st.data.isWaiting);
  const pagination = useVaccinations((st) => st.data.pagination);
  const pagedVaccinations = useVaccinations((st) => st.data.pagedVaccinations);

  const CLReportVaccinations = ReportVaccinationsController();

  const vaccinations = pagedVaccinations[pagination.page];

  return (
    <Container>
      <Table.Root
        config
        customActions
        name={CLReportVaccinations._tableListName}
      >
        <Table.Header
          columns={[
            "Data",
            "Cliente",
            "Pet",
            "Vacina",
            "Protocolo",
            "Status",
            " :180px",
          ]}
        />
        <Table.Content isLoading={isWaiting}>
          {!!vaccinations &&
            vaccinations.map((vaccination) => (
              <TableLine status={vaccination.status} key={vaccination.id}>
                <Table.Item>{vaccination.date}</Table.Item>
                <Table.Item>{vaccination.customer.name.full}</Table.Item>
                <Table.Item>{vaccination.pet.name}</Table.Item>
                <Table.Item>{vaccination.vaccine.name}</Table.Item>
                <Table.Item>{vaccination.protocol.name}</Table.Item>
                <Table.Item>
                  {getVaccinationStatusName(vaccination.status)[0]}
                </Table.Item>
                <Table.Item>
                  <VaccinationActions vaccination={vaccination} />
                </Table.Item>
              </TableLine>
            ))}
        </Table.Content>
        <Pagination.Root
          name={CLReportVaccinations._tableListPaginationName}
          totalPages={pagination.totalPages}
          currentPage={pagination.page}
          onChange={(page) => CLReportVaccinations.setPage(page)}
        >
          <Pagination.Back />
          <Pagination.Pages />
          <Pagination.Forward />
        </Pagination.Root>
      </Table.Root>
    </Container>
  );
}
