import styled from 'styled-components'

export const SelectedPetInformations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const PetInformations = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`
