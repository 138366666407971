
import { PetCardAttributeIcon } from "./PetCardAttributeIcon";
import { PetCardAttributeSuffix } from "./PetCardAttributeSuffix";

import { PetAttribute as Container, PetAttributeValue } from "./styles";

export function PetCardAttribute({ children, label, noIcon = false }) {

  return (
    <Container className={label}>
      {!noIcon && <PetCardAttributeIcon label={label} value={children} />}
      <PetAttributeValue>
        {children}
        <PetCardAttributeSuffix label={label} />
      </PetAttributeValue>
    </Container>
  );
}
