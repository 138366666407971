import _ from "lodash";

import {
  CustomerController,
  ScheduleController,
} from "../../../../../../services/controllers/scheduling";

import { Tabs } from "../../../../../ui/Tabs";
import { Steps } from "../../../../../ui/Steps";

import { Actions } from "../../../Actions";

import { Pet } from "./Pet";
import { FindClient } from "./FindClient";

import { Container, Separator } from "./styles";

export function NewPet() {
  const CLCustomer = CustomerController();
  const CLScheduling = ScheduleController();

  const setTab = Tabs.store((st) => st.actions.setActive);
  const setActive = Steps.store((st) => st.actions.setActive);

  const sendNewPet = async (e) => {
    await CLCustomer.sendNewPet(e);
    _.delay(() => setActive(CLScheduling.stepsName, 1), 200);
    _.delay(() => setTab(`${CLScheduling.schedulingName}-tabs`, 0), 200);
  };

  return (
    <Container onSubmit={sendNewPet}>
      <FindClient />
      <Separator />
      <Pet />
      <Actions show={["cancel", "next"]} next="Cadastrar" />
    </Container>
  );
}
