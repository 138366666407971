import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import Skeleton from '@material-ui/lab/Skeleton'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@material-ui/core'
import API from '../../services/api'
import BasePage from '../basePage'

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 300,
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#00567e',
    '&:hover': {
      backgroundColor: '#0097a9',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

function FilaAtendimento() {
  const history = useHistory()
  const [queueList, setQueueList] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = React.useState(0)

  const classes = useStyles()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, 0 - page * rowsPerPage)

  useEffect(() => {
    async function getQueue() {
      try {
        const { data } = await API.post('service-order/appointments/queue', {
          date: moment().format('YYYY-MM-DD'),
        })
        setQueueList([...data.queue])
      } catch (err) {
        console.log('Erro:', err)
      }
    }
    getQueue()
  }, [])

  return (
    <BasePage pageTitle="Fila de Atendimento">
      <TableContainer component={Paper} elevation={0}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 160 }}>Tutor</TableCell>
              <TableCell align="center">Pet</TableCell>
              <TableCell align="center">Hora</TableCell>
              <TableCell align="center">Chegada</TableCell>
              <TableCell align="center">Veterinário</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          {queueList ? (
            <TableBody>
              {queueList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.customerName}>
                    <TableCell component="th" scope="row">
                      {row.customerName}
                    </TableCell>
                    <TableCell align="center">{row.petName}</TableCell>
                    <TableCell align="center">{row.startsAt}</TableCell>
                    <TableCell align="center">
                      {row.arrivalHour
                        ? moment(row.arrivalHour).format('LT')
                        : 'CD ya'}
                    </TableCell>
                    <TableCell align="center">{row.employee}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="center">
                      <CustomButton
                        fullWidth
                        color="secondary"
                        size="smail"
                        type="submit"
                        justify="center"
                        alignItems="center"
                        className={classes.button}
                        onClick={() => {
                          history.push(
                            `/clinica/check-in/order/${row.appointmentId}`,
                            {
                              employeeId: row.employeeId,
                            },
                          )
                        }}
                      >
                        Atender
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </BasePage>
  )
}

export default FilaAtendimento
