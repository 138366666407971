import { Storefront } from '@phosphor-icons/react'

export function SignatureStore() {
  return (
    <div className="mt-14 flex w-full flex-col items-center justify-center text-xs">
      <div className="flex w-full gap-1">
        <Storefront className="h-4 w-4" weight="bold" />
        <span className="w-full flex-1 border-b border-black" />
      </div>
      Assinatura da loja
    </div>
  )
}
