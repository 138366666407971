export const phosphorIconsPaths = {
  "ph-female": [
    [
      "rect",
      {
        width: "256",
        height: "256",
        stroke: '',
      }],
    [
      "circle",
      {
        cx: "128",
        cy: "96",
        r: "72",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "22",
      }],
    ["line", {
      x1: "128",
      y1: "168",
      x2: "128",
      y2: "240",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],
    ["line", {
      x1: "88",
      y1: "208",
      x2: "168",
      y2: "208",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }]
  ],
  "ph-male": [
    [
      "rect",
      {
        width: "256",
        height: "256",
        stroke: '',
      }],
    [
      "circle",
      {
        cx: "104",
        cy: "152",
        r: "72",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "22",

      }],
    ["line", {
      x1: "154.91",
      y1: "101.09",
      x2: "216",
      y2: "40",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],
    [
      "polyline",
      {
        points: "168 40 216 40 216 88",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "22",
      }],
  ],
  "ph-plugs": [
    ["rect", {
      width: "256",
      height: "256",
      stroke: '',
    }],
    ["line", {
      x1: "144",
      y1: "144",
      x2: "120",
      y2: "168",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],
    ["line", {
      x1: "144",
      y1: "144",
      x2: "120",
      y2: "168",
      fill: "#231f20"
    }],
    ["line", {
      x1: "112",
      y1: "112",
      x2: "88",
      y2: "136",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],
    ["line", {
      x1: "112",
      y1: "112",
      x2: "88",
      y2: "136",
      fill: "#231f20"
    }],

    ["line", {
      x1: "64",
      y1: "112",
      x2: "144",
      y2: "192",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],
    ["line", {
      x1: "58.06",
      y1: "197.94",
      x2: "24",
      y2: "232",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],
    ["path", {
      d: "M132,180l-29,29a24,24,0,0,1-33.94,0L47,186.91A24,24,0,0,1,47,153l29-29",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],

    ["line", {
      x1: "112",
      y1: "64",
      x2: "192",
      y2: "144",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],

    ["line", {
      x1: "197.94",
      y1: "58.06",
      x2: "232",
      y2: "24",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],

    ["path", {
      d: "M180,132l29-29a24,24,0,0,0,0-33.94L186.91,47A24,24,0,0,0,153,47L124,76",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22",
    }],
  ],
  "ph-plugs-connected": [
    ["rect", {
      width: "256",
      height: "256",
      stroke: '',
    }],
    ["rect", {
      x: "63.03",
      y: "88.4",
      width: "129.94",
      height: "79.2",
      rx: "24",
      transform: "translate(-53.02 128) rotate(-45)",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "88",
      y1: "88",
      x2: "168",
      y2: "168",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "232",
      y1: "24",
      x2: "173.94",
      y2: "82.06",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "82.06",
      y1: "173.94",
      x2: "24",
      y2: "232",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "96",
      y1: "32",
      x2: "104",
      y2: "56",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "32",
      y1: "96",
      x2: "56",
      y2: "104",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "200",
      y1: "152",
      x2: "224",
      y2: "160",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "152",
      y1: "200",
      x2: "160",
      y2: "224",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
  ],
  "ph-identification-card": [
    ["rect", {
      width: "256",
      height: "256",
      stroke: '',
    }],
    ["line", {
      x1: "152",
      y1: "112",
      x2: "192",
      y2: "112",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["line", {
      x1: "152",
      y1: "144",
      x2: "192",
      y2: "144",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["rect", {
      x: "32",
      y: "48",
      width: "192",
      height: "160",
      rx: "8",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["circle", {
      cx: "96",
      cy: "120",
      r: "24",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }],
    ["path", {
      d: "M64,168c3.55-13.8,17.09-24,32-24s28.46,10.19,32,24",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "22"
    }]
  ]
}

