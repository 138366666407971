import { Alert } from '../../../../components/ui/Alert'
import { PlanController } from '../../../../services/controllers/subscription'
import { formatMoney } from '../../../../services/functions'
import { usePlans } from '../../../../store/subscription/usePlans'

import { WarningMessage } from './styles'

export function SaveRules() {
  const CPlan = PlanController()

  const plans = usePlans((st) => st.data.plans)

  return (
    <Alert.Root name={CPlan.saveRulesAlertName}>
      <Alert.Content style={{ maxWidth: '600px' }}>
        <Alert.Title>Alteração da Quantidade de Banhos</Alert.Title>
        <Alert.Description>
          <span>
            Você está prestes a alterar a quantidade de banhos pelos quais o
            valor da assinatura será dividido. Esta ação afetará diretamente o
            valor base do banho de assinatura e, consequentemente, influenciará
            o valor das comissões.
          </span>
          <span>
            <strong>
              Esta nova configuração não será aplicada retroativamente aos
              banhos de assinatura que já foram finalizados. No entanto, afetará
              todos os banhos de assinatura que ainda não foram concluídos até o
              momento da confirmação.
            </strong>
          </span>
        </Alert.Description>
        <Alert.DescriptionContent.List>
          {!!plans &&
            plans
              .filter((plan) => plan.factor !== plan.oldFactor)
              .map((plan) => (
                <li key={plan.id}>
                  <strong style={{ maxWidth: '130px' }}>
                    Plano {plan.name}:
                  </strong>
                  <Alert.DescriptionContent.ToFrom
                    from={formatMoney(plan.oldFactor, 2)}
                    to={formatMoney(plan.factor || 1, 2)}
                  />
                </li>
              ))}
        </Alert.DescriptionContent.List>
        <WarningMessage>
          ⚠️ Tem certeza de que deseja confirmar essa alteração?
        </WarningMessage>
        <Alert.Actions>
          <Alert.Cancel onClick={CPlan.undoRules}>Cancelar</Alert.Cancel>
          <Alert.Confirm onClick={CPlan.saveRules}>
            Sim, Atualizar valores
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  )
}
