import { StyleSheet } from "@react-pdf/renderer";

export const container = StyleSheet.create({
  position: "absolute",
  height: "100%",
  width: "100%",
  top: '40%',
  left: '30%',
  opacity: ".05",
})

export const mark = StyleSheet.create({
  height: "100%",
  width: "100%",
  objectFit: "contain"
})
