import ReactGA from 'react-ga4';

export const initGA = () => {
  console.log('GA init');
  ReactGA.initialize(process.env.REACT_APP_GA_ID); 

}

export const logPageView = () => {
  console.log(`Logging pageview for ${window.location.pathname}`);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
}