import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% + 32px);
  overflow-x: scroll;
`

export const Schedules = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  border-radius: 5px 5px 0 0;
  transition: max-height 250ms linear;
`
