function getFieldsFormat(data) {
  if (!data) return {};
  const fieldsFormat = {};

  Object.keys(data).forEach((field) => {
    if (data[field].format) {
      fieldsFormat[field] = data[field].format;
    }
  });

  return fieldsFormat;
}
function getPartialsFields(data) {
  const partialsFields = {};
  if (!data?.partials) return {};

  Object.keys(data.partials).forEach((key) => {
    if (!!data.partials[key].partial?.fields) {
      partialsFields[key] = data.partials[key].partial.fields;
    }
  });

  return partialsFields;
}

function getPartialsFieldsFormat(data) {
  const partialsFields = getPartialsFields(data);
  let partialsFieldsFormat = {};

  Object.keys(partialsFields).forEach((key) => {
    const formattedPartialField = getFieldsFormat(partialsFields[key]);
    partialsFieldsFormat = {
      ...partialsFieldsFormat,
      ...formattedPartialField,
    };
  });

  return partialsFieldsFormat;
}

export const functionGet = {
  fieldsFormat: getFieldsFormat,
  partialsFields: getPartialsFields,
  partialsFieldsFormat: getPartialsFieldsFormat,
};
