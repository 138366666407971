import { addDays } from 'date-fns'
import React from 'react'
import styles from './DatePicker.module.css'
import { DateView } from './DateView'
import { MonthView } from './MonthView'
import { YearView } from './YearView'

function DatePicker(props) {
  const startDate = props.startDate || new Date()
  const hoverColor = props.hover || 'rgb(204, 210, 25)'
  const lastDate = addDays(startDate, props.days || 360)

  return (
    <>
      <div className={styles.container} style={{ '--date-hover': hoverColor }}>
        <YearView {...props} />
      </div>

      <div className={styles.container} style={{ '--date-hover': hoverColor }}>
        <MonthView {...props} type="month" />
      </div>

      <div className={styles.container} style={{ '--date-hover': hoverColor }}>
        <DateView {...props} startDate={startDate} lastDate={lastDate} />
      </div>
    </>
  )
}

export { DatePicker }
