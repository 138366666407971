import { useParams } from "react-router-dom";

import { isAbleByStatus } from "../../../../services/functions";
import { VeterinaryHistoryController } from "../../../../services/controllers/VeterinaryCare";

import { useCare } from "../../../../store/service/veterinary/useCare";

import { ViewHistoryCareDrawer } from "./ViewHistoryCareDrawer";
import { HistoryTimeLine } from "./HistoryTimeLine";

import { Container } from "./styles";

export function VeterinaryHistory() {
  const { orderId } = useParams();
  const careStatus = useCare(st => st.data.care.status)

  const CLVetHistory = VeterinaryHistoryController(orderId);

  const isAble = isAbleByStatus(careStatus,CLVetHistory._name)

  if(!isAble) return null

  CLVetHistory.init()

  return (
    <>
      <Container>
        <HistoryTimeLine />
      </Container>
      <ViewHistoryCareDrawer />
    </>
  );
}
