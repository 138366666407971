import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// Material.ui
import { Backdrop, Grid, Button, Modal, Fade } from '@material-ui/core'

// Material.ui - icones
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
  span: {
    fontSize: '90px',
    color: '#ccd219',
  },
  spanErr: {
    fontSize: '90px',
    color: 'red',
  },
  btnModal: {
    margin: '10px',
    background: '#CCD219',
    padding: '8px 25px',
    '&:hover': {
      background: '#CCD219',
      opacity: '0.6',
    },
  },
}))

function ModalType(props) {
  const {
    open = false,
    eventClick,
    titleDescription,
    close,
    err,
    verification = false,
    handleClick,
    title,
    oneButton,
    disabled = false,
    view,
    content,
  } = props
  const classes = useStyles()
  return (
    <>
      {view ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={close}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            style: { backdropFilter: 'blur(3px)' },
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>{content}</div>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={close}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            style: { backdropFilter: 'blur(3px)' },
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <span id="transition-modal-icon">
                {err ? (
                  <CancelIcon className={classes.spanErr} />
                ) : (
                  <CheckCircleOutlineIcon className={classes.span} />
                )}
              </span>
              <p id="transition-modal-title">{title}</p>
              <p id="transition-modal-description">{titleDescription}</p>

              {verification && (
                <>
                  {oneButton ? (
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Button
                        disabled={disabled}
                        variant="contained"
                        type="submit"
                        color="primary"
                        onClick={() => eventClick()}
                      >
                        {oneButton}
                      </Button>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      style={{ justifyContent: 'center' }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className={classes.btnModal}
                        onClick={() => handleClick()}
                      >
                        Sim
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className={classes.btnModal}
                        onClick={() => close()}
                      >
                        Não
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </div>
          </Fade>
        </Modal>
      )}
    </>
  )
}

export default ModalType
