import React, { useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { DeleteForever } from '@material-ui/icons'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
} from '@material-ui/core'

import { usePackge } from '../../../../../../hooks/EditPackageContext'

import { getServicesByName } from '../../../../../../services/services'

function Service({ service }) {
  const [AutocompleteServices, setAutocompleteServices] = useState([])
  const {
    updatePackageService,
    removePackageService,
    recalculatePackageServiceValue,
  } = usePackge()

  const handleServices = async (value) => {
    const result = await getServicesByName(value)
    setAutocompleteServices(result)
  }

  const quantityValidade = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0
    }
    updatePackageService(service.serviceInputId, {
      quantity: Number(e.target.value),
    })
    recalculatePackageServiceValue(service.serviceInputId)
  }

  const toogleBonus = () => {
    updatePackageService(service.serviceInputId, { bonus: !service.bonus })
    recalculatePackageServiceValue(service.serviceInputId)
  }

  return (
    <Grid container spacing={3} key={service.serviceInputId}>
      <Grid item xs={4}>
        <Autocomplete
          id="package"
          disableClearable
          required
          options={AutocompleteServices}
          loading
          defaultValue={service.name && { id: service.id, name: service.name }}
          getOptionLabel={(option) => option.name}
          onChange={(e, data) => {
            updatePackageService(service.serviceInputId, {
              id: data.id,
              name: data.name,
              originalPrice: data.price,
              price: data.price,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Serviço"
              style={{ margin: '0px' }}
              placeholder="Digite o nome do serviço"
              margin="normal"
              variant="outlined"
              onChange={(e) => {
                handleServices(e.target.value)
              }}
            />
          )}
        />
      </Grid>
      <TextField
        type="hidden"
        id="unitPrice"
        defaultValue={service.originalPrice}
        name="unitPrice"
      />
      <Grid item xs={2}>
        <TextField
          id="price"
          required
          label="Valor"
          value={service.price}
          disabled
          variant="outlined"
          fullWidth
          type="number"
          name="price"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="qtd"
          required
          label="Quantidades"
          defaultValue={service.quantity}
          variant="outlined"
          fullWidth
          type="number"
          name="qtd"
          onChange={quantityValidade}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <FormControlLabel
          control={<Checkbox checked={service.bonus} onClick={toogleBonus} />}
          label="bônus?"
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          type="button"
          onClick={() => {
            removePackageService(service.serviceInputId)
          }}
        >
          <DeleteForever />
        </Button>
      </Grid>
    </Grid>
  )
}

export default Service
