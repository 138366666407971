import { formatMoney } from "../../../../../../services/functions";
import { useHistory } from "../../../../../../store/service/veterinary/useHistory";

import { InformationBlock as Container, Highlight, Line, Title } from "../styles";

export function VitalSigns() {
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const procedureData = useHistory((st) => st.data.historyProcedureData);

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedureRecord = selectedProcedure?.record

  const fc = procedureRecord?.fc ? `${procedureRecord.fc} bpm` : '-';
  const fr = procedureRecord?.fr ? `${procedureRecord.fr} mpm` : '-';
  const tpc = procedureRecord?.tpc ? `${procedureRecord.tpc} seg` : '-';
  const temperature = procedureRecord?.temperature ? `${formatMoney(procedureRecord.temperature, 1)} ºC` : '-';

  return (
    <Container>
      <Title>Sinais Vitais</Title>
      <Line>
        <p><Highlight>FC:</Highlight> {fc}</p>
      </Line>
      <Line>
        <p><Highlight>FR:</Highlight> {fr}</p>
      </Line>
      <Line>
        <p><Highlight> TPC:</Highlight> {tpc}</p>
      </Line>
      <Line>
        <p><Highlight>Temperatura:</Highlight> {temperature}</p>
      </Line>
    </Container>
  );
}
