import { create } from 'zustand'
import { produce } from 'immer'
import uuid from 'react-uuid'

export const useSteps = create((set) => ({
  data: {
    steps: {},
  },
  actions: {
    setSteps: (name, steps) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.steps[name]) {
            const newStepList = []
            steps.forEach((step, i) => {
              newStepList.push({
                id: `${name}-${uuid()}`,
                number: i + 1,
                title: step,
                status: i === 0 ? 1 : 0,
              })
            })
            draft.data.steps[name] = newStepList
          }
        }),
      ),
    setActive: (name, updateStep) =>
      set((state) =>
        produce(state, (draft) => {
          const allSteps = state.data.steps
          if (!allSteps[name]) return null

          if (typeof updateStep === 'string') {
            const stepIndex = state.data.steps[name].findIndex(
              (step) => step.title === updateStep,
            )

            draft.data.steps[name] = state.data.steps[name].map((step, i) => {
              if (i === stepIndex) {
                return { ...step, status: 1 }
              }
              if (i < stepIndex) {
                return { ...step, status: 2 }
              }
              if (i > stepIndex) {
                return { ...step, status: 0 }
              }
              return step
            })
            return
          }

          draft.data.steps[name] = state.data.steps[name].map((step, i) => {
            if (i === updateStep) {
              return { ...step, status: 1 }
            }
            if (i < updateStep) {
              return { ...step, status: 2 }
            }
            if (i > updateStep) {
              return { ...step, status: 0 }
            }
            return step
          })
        }),
      ),
  },
}))
