import { Minimize2 } from 'lucide-react'
import { DrawerControl } from './DrawerControl'

export function DrawerControlMinimize(props) {
  return (
    <DrawerControl {...props}>
      <Minimize2 size={20} />
    </DrawerControl>
  )
}
