import { create } from "zustand";
import { produce } from "immer";

const defaultMedicine = {
  isLoading: true,
  medicines: false,
  foundMedicines: [],
  foundPresentation: [],
  medicineData: false,
};

export const useMedicine = create((set) => ({
  data: defaultMedicine,
  actions: {
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),
    setMedicines: (medicines = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.medicines = medicines;
        })
      ),
    setFoundMedicines: (medicines = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.foundMedicines = medicines;
        })
      ),
    setMedicineData: (medicineData = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.medicineData = medicineData;
        })
      ),
    setFoundPresentation: (presentation = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.foundPresentation = presentation;
        })
      ),
  },
}));

