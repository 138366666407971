import React from 'react'
import Agendamento from '../components/organisms/Agendamento'

function AgendamentoPage({ location }) {
  const { state } = location

  return <Agendamento {...state} />
}

export default AgendamentoPage
