import {
  Ban,
  CalendarCheck,
  CalendarClock,
  CalendarDays,
  ClipboardCheck,
  ClipboardSignature,
  FileCheck2,
  FileClock,
  FileX2,
  Syringe,
  XCircle,
  AlertCircle
} from "lucide-react";

export const VACCINE_START_WARNING_DAYS = 5;

export const VETERINARY_CARE_STATUS = {
  'agendado': 'scheduled',
  'aguardando atendimento': 'waitingStart',
  'em atendimento': 'checkIn',
  'em andamento': 'checkIn',
  'finalizado': 'finished',
  'orçamento enviado': 'budgetSent',
}

export const VETERINARY_CARE_STATUS_NAME = {
  'scheduled': 'agendado',
  'waitingStart': 'aguardando atendimento',
  'checkIn': 'em atendimento',
  'finished': 'finalizado',
  'budgetSent': 'orçamento enviado',
}

export const VETERINARY_CARE_STATUS_PLACEHOLDER = {
  'scheduled': 'O Paciente ainda não chegou',
  'waitingStart': 'O Paciente esta aguardando',
  'checkIn': 'em atendimento',
  'finished': 'Esse atendimento já foi finalizado',
  'budgetSent': 'Esse atendimento já encerrado',
}

export const VETERINARY_RESET_STATUS = ['waitingStart', 'checkIn', 'finished', 'budgetSent'];
export const VETERINARY_CANCEL_STATUS = ['scheduled'];
export const VETERINARY_VIEW_ATTENDING = ['waitingStart', 'checkIn', 'finished'];

export const VETERINARY_CARE_ABLE_STATUS = {
  'veterinary-record': ['checkIn', 'finished', 'budgetSent'],
  'veterinary-request': ['checkIn', 'finished', 'budgetSent'],
  'veterinary-vacines': ['checkIn', 'finished', 'budgetSent'],
  'veterinary-history': ['scheduled', 'waitingStart', 'checkIn', 'finished', 'budgetSent'],
  'veterinary-services': [ 'checkIn', 'finished'],
  'veterinary-attachment': ['waitingStart', 'checkIn', 'finished', 'budgetSent'],
  'veterinary-prescription': [ 'checkIn', 'finished', 'budgetSent'],
}

export const MEDICINE_PHARMACY_TYPE = {
  veterinary: ["Veterinária", "Veterinária", "Veterinárias"],
  human: ["Humana", "Humana", "Humanas"],
  compounding: ["Manipulação", "de Manipulação", "de Manipulações"],
};

export const MEDICINE_MEASURE = {
  capsules: ["Cápsulas", "cápsula", "cápsulas"],
  pills: ["Comprimidos", "comprimido", "comprimidos"],
  dragee: ["Drágeas", "drágea", "drágeas"],
  doses: ["Doses", "dose", "doses"],
  grams: ["Gramas (g)", "g (Grama)", "g (Gramas)"],
  drops: ["Gotas", "gota", "gotas"],
  milligrams: ["Miligramas (mg)", "mg (Miligrama)", "mg (Miligramas)"],
  milliliters: ["Mililitros (mL)", "mL (Mililitro)", "mL (Mililitros)"],
};

export const MEDICINE_FREQUENCY = {
  hour: ["Horas", "Hora", "Horas"],
  day: ["Dias", "Dia", "Dias"],
  week: ["Semanas", "Semana", "Semanas"],
  month: ["Meses", "Mês", "Meses"],
};

export const MEDICINE_DURATION = {
  hour: ["Horas", "Hora", "Horas"],
  day: ["Dias", "Dia", "Dias"],
  week: ["Semanas", "Semana", "Semanas"],
  month: ["Meses", "Mês", "Meses"],
  continuous: ["Contínuo", "Contínuo", "Contínuo"],
};

export const VACCINE_FREQUENCY = {
  day: ["Dia", "Dia", "Dias"],
  week: ["Semana", "Semana", "Semanas"],
  month: ["Mês", "Mês", "Meses"],
  year: ["Ano", "Ano", "Anos"],
};

export const VACCINE_DURATION = {
  day: ["Dia", "Dia", "Dias"],
  week: ["Semana", "Semana", "Semanas"],
  month: ["Mês", "Mês", "Meses"],
  year: ["Ano", "Ano", "Anos"],
};

export const MEDICINE_USAGE = {
  oral: ["Oral", "Oral", "Orais"],
  topic: ["Tópico", "Tópico", "Tópicos"],
  ophthalmic: ["Oftálmico", "Oftálmico", "Oftálmicos"],
  otological: ["Otológico", "Otológico", "Otológicos"],
};

export const MEDICINE_FIELDS = {
  name: ["Nome", "Nome", "Nomes"],
  fillStyle: [
    "Estilo de Preenchimento",
    "Estilo de Preenchimento",
    "Estilos de Preenchimento",
  ],
  freeText: ["Posologia", "Posologia", "Posologias"],
  dose: ["Dosagem", "Dosagem", "Dosagens"],
  measure: ["Medida", "Medida", "Medidas"],
  frequency: ["Frequência", "Frequência", "Frequências"],
  frequencyPeriod: [
    "Período da Frequência",
    "Período da Frequência",
    "Períodos da Frequência",
  ],
  duration: ["Duração", "Duração", "Durações"],
  durationPeriod: [
    "Período de Duração",
    "Período de Duração",
    "Períodos de Duração",
  ],
  note: ["Observação", "Observação", "Observações"],
  pharmacyType: ["Tipo de Farmácia", "Tipo de Farmácia", "Tipos de Farmácia"],
  usage: ["Uso", "Uso", "Usos"],
  quantity: ["Quantidade", "Quantidade", "Quantidades"],
};


export const PRESCRIPTION_FIELDS = {
  name: ["Nome", "Nome", "Nomes"],
  medicines: ["Medicamentos", "Medicamento", "Medicamentos"],
}
export const REQUEST_TYPES = {
  attestation: ["Atestado", "Atestado", "Atestados"],
  declaration: ["Declaração", "Declaração", "Declarações"],
  term: ["Termo", "Termo", "Termos"],
};

export const VACCINE_FIELDS = {
  date: ["Data", "Data", "Datas"],
  vaccineId: ["Vacina", "Vacina", "Vacinas"],
  batch: ["Lote", "Lote", "Lotes"],
  lab: ["Laboratório", "Laboratório", "Laboratórios"],
  protocolId: ["Protocolo", "Protocolo", "Protocolos"],
};

export const VACCINE_PROTOCOL_FIELDS = {
  name: ["Nome", "Nome", "Nomes"],
  species: ["Espécie", "Espécie", "Espécies"],
  frequency: ["Frequência", "Frequência", "Frequências"],
  frequencyPeriod: [
    "Período da Frequência",
    "Período da Frequência",
    "Períodos da Frequência",
  ],
  duration: ["Duração", "Duração", "Durações"],
  durationPeriod: [
    "Período de Duração",
    "Período de Duração",
    "Períodos de Duração",
  ],
};

export const VACCINATION_PROTOCOL_STATUS = {
  vencida: "delayed",
  agendada: "scheduled",
  programada: "scheduled",
  interrompido: "interrupted",
  aplicada: "finished",
};

export const VACCINE_STATUS = {
  aplicada: "applied",
  agendado: "scheduled",
  cancelado: "canceled",
  "vacina programada": "scheduled",
  finalizado:"aplicada",
  "orçamento enviado": "aplicada",
  "em andamento": "scheduled",
};

export const VACCINATION_PROTOCOL_STATUS_NAME = {
  delayed: ["Atrasado", "Atrasados", "Atrasada", "Atrasadas"],
  scheduled: ["Programado", "Programados", "Programada", "Programadas"],
  interrupted: [
    "Interrompido",
    "Interrompidos",
    "Interrompida",
    "Interrompidas",
  ],
  finished: ["Finalizado", "Finalizados", "Finalizada", "Finalizadas"],
  editing: ["Editando", "Editando", "Editando", "Editando"],
  applying: ["Aplicando", "Aplicando", "Aplicando", "Aplicando"],
  reprogramming: [
    "Reprogramando",
    "Reprogramando",
    "Reprogramando",
    "Reprogramando",
  ],
  error: [ "Erro", "Erros", "Erro", "Erros" ],
};

export const CARE_STATUS = {
  "AGENDADO": "scheduled",
  "AGUARDANDO ATENDIMENTO": "waitingStart",
  "EM ATENDIMENTO": "started",
  "EM ANDAMENTO": "started",
  "ATENDIMENTO FINALIZADO": "finished",
  "ORÇAMENTO ENVIADO": "budgetSent"
};

export const VACCINATION_PROTOCOL_STATUS_ICON = {
  delayed: CalendarClock,
  scheduled: CalendarCheck,
  interrupted: Ban,
  finished: ClipboardCheck,
  editing: ClipboardSignature,
  applying: Syringe,
  reprogramming: CalendarDays,
  error: AlertCircle,
};

export const VACCINATION_PROTOCOL_STATUS_COLOR = {
  delayed: "danger",
  scheduled: "success",
  interrupted: "600",
  finished: "finished",
  editing: "warning",
  applying: "success",
  reprogramming: "300",
};

export const VACCINATION_PROTOCOL_STATUS_ACTIONS = {
  delayed: ["interrupt", "delete"],
  scheduled: ["interrupt", "delete"],
  interrupted: ["resume"],
  finished: [],
  editing: [],
  applying: [],
  reprogramming: [],
};

export const VACCINATION_STATUS_ACTIONS = {
  scheduled: ["apply", "edit", "reprogram", "cancel"],
  applied: ["edit", "remove"],
  editing: ["save", "back"],
  applying: ["save", "back"],
  reprogramming: ["save", "back"],
  removed: [],
  canceled: [],
};

export const VACCINATION_STATUS_FIELDS = {
  scheduled: [],
  applied: [],
  canceled: [],
  editing: ["batch", "lab"],
  applying: ["applyDate", "batch", "lab"],
  reprogramming: ["scheduledDate"],
  removed: [],
};

export const RECORD_FIELDS_GROUP = {
  generalInformation: ["weight", "anamnesis"],
  vitalSigns: ["fc", "fr", "tpc", "temperature"],
  generalPhysicalExamination: [
    "ears",
    "mucousMembranes",
    "hydration",
    "lymphNodes",
    "skin",
  ],
  bodyExamination: ["chest", "abdomen", "thoracicLimbs", "pelvicLimbs"],
  diagnosis: ["diagnosis"],
};

export const PDF_GENERATION_STATUS_ICON = {
  generated: FileCheck2,
  notGenerated: FileX2,
  generating: FileClock,
  error: XCircle,
};

export const PDF_GENERATION_STATUS_NAME = {
  generated: ["Gerado", "Gerados"],
  notGenerated: ["Não Gerado", "Não Gerados"],
  generating: ["Gerando", "Gerando"],
  error: ["Erro", "Erro"],
};

export const PDF_GENERATION_STATUS_COLOR = {
  generated: "success",
  notGenerated: "warning",
  generating: "300",
  error: "danger",
};


export const RECORD_FIELDS = {
  weight: ["Peso", "Peso", "Pesos"],
  anamnesis: ["Anamnese", "Anamnese", "Anamneses"],

  fc: ["FC", "FC", "FC"],
  fr: ["FR", "FR", "FR"],
  tpc: ["TPC", "TPC", "TPC"],
  temperature: ["Temperatura", "Temperatura", "Temperaturas"],

  ears: ["Ouvidos", "Ouvido", "Ouvidos"],
  mucous: ["Mucosas", "Mucosa", "Mucosas"],
  hydration: ["Hidratação", "Hidratação", "Hidratações"],
  lymphNodes: ["Linfonodos", "Linfonodo", "Linfonodos"],
  skin: ["Pele/Pelo", "Pele/Pelo", "Peles/Pelos"],

  chest: ["Tórax", "Tórax", "Tórax"],
  abdomen: ["Abdômen", "Abdômen", "Abdomens"],
  thoracicLimbs: ["Membros Torácicos", "Membro Torácico", "Membros Torácicos"],
  pelvicLimbs: ["Membros Pélvicos", "Membro Pélvico", "Membros Pélvicos"],

  diagnosis: ["Diagnóstico", "Diagnóstico", "Diagnósticos"],
};


export const SERVICE_STATUS = {
  utilizado: "used",
  ativo: "active",
};


export const PREVIEW_LIST_PLACEHOLDER = {
  'veterinary-attachments-list': `
              <p>📁 Sem anexos por aqui ainda!</p>
              <p>Que tal adicionar um toque especial ao atendimento {{article}} <strong>{{petName}}</strong>?</p>
              <p>Você pode adicionar fotos, relatórios ou outros documentos úteis para o atendimento! 🐾🩺</p>
            `.trim(),
  'veterinary-request-list': `
                <p>📁 Sem requisições por aqui ainda!</p>
                <p>Ops, parece que não temos nenhuma requisição cadastrada para {{article1}} <strong>{{petName}}</strong> em nossos sistema.</p>
                <p>Para garantir que tenhamos todas as informações necessárias sobre sua saúde, se existirem requisições não cadastradas que tal criá-las? Assim, podemos manter o histórico {{article2}} <strong>{{petName}}</strong> atualizado! 🐾🩺</p>
              `.trim(),
  'veterinary-vacines-list': `
              <p>📁 Sem protocolos de vacinação por aqui ainda!</p>
              <p>Ops, parece que não temos nenhum protocolo de vacinação registrado para {{article}} <strong>{{petName}}</strong>.</p>
              <p>Para garantir o melhor cuidado para {{our}} {{buddy}}, que tal enviar as informações sobre as vacinas anteriores? Assim, podemos manter nossos registros atualizados. 🐾🩺</p>
            `.trim(),
  'veterinary-prescription-list': `
              <p>📁 Sem receitas por aqui ainda!</p>
              <p>Parece que não encontramos nenhuma receita cadastrada para {{article1}} <strong>{{petName}}</strong> em nossos sistema.</p>
              <p>Para garantir que tenhamos todas as informações necessárias sobre sua saúde, que tal criar uma receita nova? Estamos aqui para garantir que {{article2}} <strong>{{petName}}</strong> receba o melhor cuidado possível! 🐾🩺</p>
            `.trim(),
}
