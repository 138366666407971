import { Gear } from "@phosphor-icons/react";
import { Container, ConfigAction, Box } from "./styles";

import { Popover } from "../Popover";
import { ConfigContent } from "./ConfigContent";

export function Config({ children, name, empty, inverse, twoColunms }) {
  const popoverToogle = Popover.store((st) => st.actions.toogle);

  if (empty) return <Container />;
  const gridColumn = twoColunms ? 'span 2 / span 2' : 'span 1 / span 1';

  return (
    <Container style={{ gridColumn }}>
      <Box>
        <ConfigAction
          inverse={inverse}
          onClick={() => {
            popoverToogle(name);
          }}
        >
          <Gear weight="fill" size={20} />
        </ConfigAction>
        <ConfigContent name={name}>{children}</ConfigContent>
      </Box>
    </Container>
  );
}
