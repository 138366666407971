import moment from "moment";

import { useContext } from "../../Context";
import { useListPreview } from "../../useListPreview";

import { ItemContent } from "../ItemContent";

import {
  Container,
  BulletMenu,
  Item,
  Action,
  Image,
  Title,
  Header,
  InformationBox,
  Information,
} from "./styles";

export function ListContentBigGrid() {
  const { listPreviewName, customAction } = useContext();

  const toggleSelected = useListPreview((st) => st.actions.toggleSelected);

  const viewAction = !!customAction.open
    ? ({ item, name }) => customAction.open({ item, name }, toggleSelected)
    : toggleSelected;

  const selected = useListPreview(
    (st) => st.data.lists[listPreviewName].selected
  );
  const listData = useListPreview((st) => st.data.lists[listPreviewName].data);

  return (
    <Container>
      {!!listData &&
        listData.map((item) => (
          <Item
            key={item.id}
            data-selected={selected?.id === item.id}
            onClick={() => viewAction({ item, name: listPreviewName })}
          >
            <Header>
              {item.bigThumb && <Image src={item.bigThumb} />}
              <Action>
                <BulletMenu />
              </Action>
            </Header>
            <InformationBox>
              <Title>{item.fullName}</Title>
              <Information>

                {_.keys(item?.informationList).map((information) => (
                  <ItemContent

                    type="text"
                    label={information}
                    key={`${listPreviewName}-grid-${item.id}-${information}`}
                    content={item.informationList[information]}
                  />
                ))}
              </Information>
            </InformationBox>
          </Item>
        ))}
    </Container>
  );
}
