import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { logout } from '../services/auth'

function Logout() {
  const [logged, setLogged] = useState(true)

  useEffect(() => {
    logout()
    window.setTimeout(() => {
      setLogged(false)
    }, 5000)
  }, [])

  return logged ? <h1>Você foi deslogado!</h1> : <Redirect to="/" />
}

export default Logout
