import { Dog } from 'lucide-react'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`

export const SummaryIcon = styled(Dog)`
  color: currentColor;
  position: relative;
  z-index: 1;
`

export const SummaryStatusIcon = styled(Dog)`
  color: currentColor;
  position: relative;
  z-index: 1;
`

export const SummaryTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  gap: 2px;
`
export const SummaryTitle = styled.h2`
  margin: 0;
  color: currentColor;
  position: relative;
  z-index: 1;
  ${(props) => props.size === 'big' &&`
     font-size: 22px;
     font-weight: bold;
  `}
`
export const SummaryText = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.gray[800]};
  position: relative;
  z-index: 1;
`
export const SummarySubTitle = styled.em`
  margin: 0;
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray[400]};
  position: relative;
  z-index: 1;
`

export const SummaryBox = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  color: ${(props) => props.theme.colors.scale[600]};
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  flex: 1;
  padding: 20px;
  gap: 20px;
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 0px 6px 10px;
  position: relative;
  &[data-status='up'] {
    background: linear-gradient(90deg, ${(props) => rgba(props.theme.colors.success, 0)} 70%, ${(props) => rgba(props.theme.colors.success, .3)} 100%);
    ${SummaryStatusIcon} {
      color: ${(props) => props.theme.colors.success};
    }
  }
  &[data-status='down'] {
    background: linear-gradient(90deg, ${(props) => rgba(props.theme.colors.error, 0)} 70%, ${(props) => rgba(props.theme.colors.error, .3)} 100%);
    ${SummaryStatusIcon} {
     color: ${(props) => props.theme.colors.error};
    }
  }

  ${(props) =>
    props.waiting &&
    css`
      overflow: hidden;
      border-color: ${(props) => props.theme.colors.gray[500]};
      background: ${(props) => rgba(props.theme.colors.gray[500], 0.2)};
      user-select: none;
      cursor: not-allowed;
      &::before {
        content: '⚠️ Em Construção';
        box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 1px 1px
          4px;
        position: absolute;
        background: ${(props) => props.theme.colors.scale[500]};
        left: 0;
        top: 0;
        font-weight: bold;
        padding: 5px 10px;
        color: ${(props) => props.theme.colors.background};
        font-size: 12px;
        border-radius: 0 0 5px 0;
      }

      ${SummaryTitle} {
        color: ${(props) => props.theme.colors.gray[500]};
      }
      ${SummaryIcon} {
        color: ${(props) => props.theme.colors.gray[500]};
      }
    `}
`
