import React from 'react'
import { Controller, useForm } from 'react-hook-form'

// Material ui
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Alert from '@mui/material/Alert'
import { Snackbar } from '@material-ui/core'

// Material ui icones
import SettingsIcon from '@mui/icons-material/Settings'

// Componentes
import { styled } from '@mui/material/styles'
import moment from 'moment'
import DrawerType from '../../../atoms/Drawer/index'
import Steps1 from './step1'
import Steps2 from './step2'

// Style
import { useStyles } from '../styles'

// moment
import API from '../../../../services/api'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(204, 210, 25)',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const arraySkeleton = [0, 1, 2, 3, 4, 5, 6]

function Steps3({
  handleStep,
  setInformationStep,
  setFirstDay,
  setLastDay,
  resultSearchService,
  resultSearchEmployees,
  activeStep,
}) {
  const classes = useStyles()
  const { control } = useForm()
  const [employees, setEmployees] = React.useState([])
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [valueDrawer, setValueDrawer] = React.useState(0)
  const [message, setMessage] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(false)
  const [disableButton, setDisableButton] = React.useState(false)
  const date = new Date()
  const [firstDay, setFirstDayy] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  )
  const [lastDay, setLastDayy] = React.useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  )

  const handleChange = (event, newValue) => {
    setValueDrawer(newValue)
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const getEmployees = async () => {
    try {
      const { data } = await API.get(
        `employees/commissions/opened?fromDate=${moment(firstDay).format(
          'YYYY-MM-DD',
        )}&toDate=${moment(lastDay).format('YYYY-MM-DD')}`,
      )
      setEmployees(data)
      setDisableButton(false)
    } catch (err) {
      setDisableButton(false)
      console.log('Erro:', err)
    }
  }

  const filterData = async () => {
    setDisableButton(true)
    if (firstDay && lastDay) getEmployees()
  }

  const postStatus = async (value, id) => {
    setEmployees([])
    try {
      const result = await API.post(`employee/${id}/is-comissioned`, {
        status: value,
      })
      if (result?.status === 200) getEmployees()
      if (result?.status === 201 || result?.status === 200) {
        setMessage(result?.data?.message)
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message)
      setMessage('error')
      getEmployees()
      console.log('Erro:', err)
    }
  }

  const handleClick = (id) => {
    setFirstDay(firstDay)
    setLastDay(lastDay)
    handleStep(3)
    setInformationStep(id)
  }

  React.useEffect(() => {
    getEmployees()
  }, [])

  return (
    <>
      <DrawerType
        open={drawerOpen}
        setDrowerOpen={setDrawerOpen}
        titleHeader="Editar Gestão de comissão"
        content={
          <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={valueDrawer}
              onChange={handleChange}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab label="Configurações gerais" {...a11yProps(0)} />
              <Tab label="Comissões" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={valueDrawer} index={0}>
              <Steps1
                handleStep={handleStep}
                valueDrawer
                getEmployees={getEmployees}
              />
            </TabPanel>
            <TabPanel value={valueDrawer} index={1}>
              <Steps2
                handleStep={handleStep}
                resultSearchService={resultSearchService}
                resultSearchEmployees={resultSearchEmployees}
                activeStep={activeStep}
              />
            </TabPanel>
          </Box>
        }
      />

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Tooltip title="Editar Gestão de comissão">
            <Button
              color="primary"
              style={{
                fontSize: '13px',
                color: '#ccd219',
                border: '1px solid',
                backgroundColor: '#fff',
                padding: '6px',
                marginRight: '22px',
              }}
              onClick={() => setDrawerOpen(true)}
            >
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container className={classes.filter}>
        {firstDay && (
          <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              style={{ marginRight: '20px' }}
              id="inicio"
              label="Inicio"
              type="date"
              defaultValue={moment(firstDay).format('YYYY-MM-DD')}
              onChange={({ target }) => setFirstDayy(target.value)}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span style={{ marginRight: '20px' }}> até </span>
            <TextField
              id="fim"
              label="Fim"
              type="date"
              defaultValue={moment(lastDay).format('YYYY-MM-DD')}
              onChange={({ target }) => setLastDayy(target.value)}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        )}
        <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: '100%' }}
          >
            <Box justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => filterData()}
                color="primary"
                disabled={disableButton}
                style={{
                  background: '#CCD219',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  fontWeight: '700',
                  width: '138px',
                  marginRight: '20px',
                }}
              >
                Filtrar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell align="center">
                  Comissão Aberta
                </StyledTableCell>
                <StyledTableCell align="center">Comissionado</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.length > 0
                ? employees?.map((item) => (
                    <StyledTableRow key={item.employeeId}>
                      <StyledTableCell component="th" scope="row">
                        {item.employeeName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.openedCommissionSum}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Controller
                          name="status"
                          render={(props) => (
                            <FormGroup>
                              <Switch
                                {...label}
                                checked={
                                  item?.isCommissioned
                                    ? item?.isCommissioned
                                    : false
                                }
                                defaultChecked={
                                  item?.isCommissioned
                                    ? item?.isCommissioned
                                    : false
                                }
                                onChange={(event, value) =>
                                  props.onChange(
                                    postStatus(value, item.employeeId),
                                  )
                                }
                              />
                            </FormGroup>
                          )}
                          control={control}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.status}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ cursor: 'pointer' }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleClick(item.employeeId)}
                          color="primary"
                          disabled={disableButton}
                          style={{
                            background: '#CCD219',
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            fontWeight: '700',
                            width: '138px',
                            marginRight: '20px',
                          }}
                        >
                          Ver detalhes
                        </Button>
                      </StyledTableCell>
                      {/* {
                        item.openedCommissionSum === 0 ?
                          <StyledTableCell align="center">Sem comissão aberta</StyledTableCell> :

                      } */}
                    </StyledTableRow>
                  ))
                : arraySkeleton.map((item) => (
                    <StyledTableRow key={item}>
                      <StyledTableCell component="th" scope="row">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Snackbar
        open={message}
        autoHideDuration={3000}
        onClose={() => {
          setMessage(false)
        }}
      >
        <Alert severity={message === 'error' ? 'error' : 'success'}>
          {message === 'error' ? errMsg : message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Steps3
