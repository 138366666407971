import { Bot, FlaskConical, PawPrint, PencilLine, Plus, Trash2, User } from "lucide-react";

import { MEDICINE_USAGE } from "../../../../../../../store/service/veterinary/constants";

import { VeterinaryPrescriptionController } from "../../../../../../../services/controllers/VeterinaryCare";
import { usePrescription } from "../../../../../../../store/service/veterinary/usePrescription";

import { ToggleGroup } from "../../../../../../../components/ui/Form/ToggleGroup";
import { Select } from "../../../../../../../components/ui/Form/Select";
import { Input } from "../../../../../../../components/ui/Form/Input";
import { Action } from "../../../../../../../components/ui/Action";

import { FormGroup } from "../styles";

import { SelfDescription } from "./SelfDescription";
import { FreeDescription } from "./FreeDescription";
import { MedicineList } from "./MedicineList";

import { Container, FormActions } from "./styles";

export function MedicineForm() {
  const CLVetPrescription = VeterinaryPrescriptionController();
  const medicineProps = usePrescription((st) => st.data.prescription.newMedicine);

  const isSelf = medicineProps.fillStyle === "self";
  const isContinuous = medicineProps.durationPeriod === "continuous";
  const isDisabled = !medicineProps.presentation;

  return (
    <Container>
      <FormGroup style={{ width: "fit-content", marginBottom: "12px" }}>
        <ToggleGroup.Root
          disabled={isDisabled}
          value={medicineProps.fillStyle}
          onValueChange={(fillStyle) =>
            CLVetPrescription.setNewMedicineProps({ fillStyle })
          }
        >
          <ToggleGroup.Item value="self">
            <Bot /> Automático
          </ToggleGroup.Item>
          <ToggleGroup.Item value="free">
            <PencilLine /> Texto livre
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </FormGroup>

      {isSelf && <SelfDescription />}
      {!isSelf && <FreeDescription />}

      <FormGroup style={{ width: "fit-content", marginBottom: "12px" }}>
        <ToggleGroup.Root
          label="Tipo de Farmácia"
          disabled={isDisabled}
          defaultValue={medicineProps.pharmacyType}
          onValueChange={(pharmacyType) =>
            CLVetPrescription.setNewMedicineProps({ pharmacyType })
          }
        >
          <ToggleGroup.Item value="veterinary">
            <PawPrint size={22} /> Veterinária
          </ToggleGroup.Item>
          <ToggleGroup.Item value="human">
            <User size={22} /> Humana
          </ToggleGroup.Item>
          <ToggleGroup.Item value="compounding">
            <FlaskConical size={22} /> Manipulação
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </FormGroup>
      <FormGroup>
        <Select.Root
          label="Uso"
          disabled={isDisabled}
          value={medicineProps.usage}
          onChange={(usage) => CLVetPrescription.setNewMedicineProps({ usage })}
        >
          {Object.keys(MEDICINE_USAGE).map((usageKey) => (
            <Select.Option value={usageKey} key={usageKey}>
              {MEDICINE_USAGE[usageKey][0]}
            </Select.Option>
          ))}
        </Select.Root>
        <Input.Root
          label="Quantidade"
          money={isSelf}
          noSuffix
          decimals={0}
          disabled={isDisabled || (isContinuous && isSelf)}
          value={
            isContinuous && isSelf ? "uso Contínuo" : medicineProps.quantity
          }
          onChange={(quantity) =>
            CLVetPrescription.setNewMedicineProps({ quantity })
          }
        />
      </FormGroup>

      <MedicineList />

      <FormActions>
        <Action.Root
          outline
          type="button"
          disabled={isDisabled}
          onClick={() => CLVetPrescription.saveMedicine()}
        >
          <Action.Icon icon={Plus} />
          Adicionar
        </Action.Root>
      </FormActions>
    </Container>
  );
}
