import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Material ui - style
import { makeStyles, withStyles } from '@material-ui/core/styles'

// Material ui - core
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Grid,
  InputBase,
  Button,
} from '@material-ui/core'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'

// Material ui - icones
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import BasePage from '../../../pages/basePage'
import ModalType from '../../atoms/Modal'
import API from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    padding: 5,
    flex: 1,
  },
  table: {
    minWidth: 300,
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#ccd219',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#a0a510',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

const ITEM_HEIGHT = 48

function Service({
  service,
  category,
  stateValue,
  getServices,
  getCategories,
  handleGetService,
}) {
  const [value, setValue] = useState('0')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const history = useHistory()
  const [disabledButton, setDisabledButton] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, service?.length - page * rowsPerPage)

  const handleChangeIndex = (event, value) => setValue(value)

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(null)

  const onDecrement = (key) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(key)
  }

  const postActive = async (id, active) => {
    try {
      const data = await API.post(`service/${id}`, {
        active,
      })
      data?.satus === 201 && getServices()
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleClose = (id, active) => {
    setAnchorEl(null)
    setOpen(false)
    postActive(id, active)
  }

  const postActiveCategory = async (id, active) => {
    try {
      const data = await API.post(`services/category/${id}`, {
        active,
      })
      data?.status === 201 && getCategories()
    } catch (err) {
      console.log('Erro:', err)
    }
  }
  const handleCloseCategory = (id, active) => {
    setAnchorEl(null)
    setOpen(false)
    postActiveCategory(id, active)
  }

  const updatePrices = async () => {
    setDisabledButton(true)
    try {
      const result = await API.put('services/update-prices')
      setOpenModal(result?.data.message)
      if (result?.status === 201) {
        getServices()
        setDisabledButton(false)
      }
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  return (
    <BasePage pageTitle={parseInt(value, 10) === 0 ? 'Serviços' : 'Categorias'}>
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
        aria-label="disabled tabs example"
        style={{
          boxShadow: '0 6px 20px rgb(0 0 0 / 10%)',
          borderRadius: '7px',
        }}
      >
        <Tab label="SERVIÇOS" value="0" />
        <Tab label="CATEGORIAS" value="1" />
      </Tabs>

      {parseInt(value, 10) === 0 && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <Paper component="form" className={classes.root} elevation={0}>
                <InputBase
                  className={classes.input}
                  placeholder="Nome"
                  onChange={({ target }) => handleGetService(target.value)}
                />
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <CustomButton
                onClick={() => updatePrices()}
                disabled={disabledButton}
                fullWidth
                type="submit"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
              >
                Sincronizar Preço
              </CustomButton>
            </Grid>

            {/* <Grid item xs={3}>
                <CustomButton
                    onClick={() => stateValue(1)}
                    fullWidth
                    type="submit"
                    color="secondary"
                    size="large"
                    justify="center"
                    alignItems="center"
                >
                    Novo Serviço
                </CustomButton>
            </Grid> */}
          </Grid>

          <TableContainer component={Paper} elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="center">Grupo</TableCell>
                  <TableCell align="center">Área</TableCell>
                  <TableCell align="center">Preço</TableCell>
                  <TableCell align="center">Tempo</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>

              {/* SKELETON */}
              {service ? (
                <TableBody>
                  {service
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.category}</TableCell>
                        <TableCell align="center">{row.department}</TableCell>
                        <TableCell align="center">
                          {row?.price?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </TableCell>
                        <TableCell align="center">
                          {row.defaultDurationInMinutes}
                        </TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">
                          <>
                            <IconButton
                              aria-label="more"
                              aria-controls={row.id}
                              aria-haspopup="true"
                              onClick={onDecrement(row.id)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id={row.id}
                              anchorEl={anchorEl}
                              keepMounted
                              open={row.id === open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              <MenuItem
                                key="ativo"
                                selected="ativo"
                                onClick={() => handleClose(row.id, true)}
                              >
                                Ativo
                              </MenuItem>
                              <MenuItem
                                key="inativo"
                                selected="inativo"
                                onClick={() => handleClose(row.id, false)}
                              >
                                Inativo
                              </MenuItem>
                              <MenuItem
                                key="editar"
                                selected="editar"
                                onClick={() =>
                                  history.push(`/servicos/editar/${row.id}`, {
                                    categories: category,
                                    id: row.id,
                                  })
                                }
                              >
                                Editar
                              </MenuItem>
                            </Menu>
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={service?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
      {parseInt(value, 10) === 1 && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <Paper component="form" className={classes.root} elevation={0}>
                <InputBase
                  className={classes.input}
                  placeholder="Nome da Categoria"
                />
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <CustomButton
                onClick={() => stateValue(2)}
                fullWidth
                type="submit"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
              >
                Nova Categoria
              </CustomButton>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="center">Comissão</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {category?.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.commission}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="center">
                      <>
                        <IconButton
                          aria-label="more"
                          aria-controls={row.id}
                          aria-haspopup="true"
                          onClick={onDecrement(row.id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={row.id}
                          anchorEl={anchorEl}
                          keepMounted
                          open={row.id === open}
                          onClose={handleCloseCategory}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                          <MenuItem
                            key="ativo"
                            selected="ativo"
                            onClick={() => handleCloseCategory(row.id, true)}
                          >
                            Ativo
                          </MenuItem>
                          <MenuItem
                            key="inativo"
                            selected="inativo"
                            onClick={() => handleCloseCategory(row.id, false)}
                          >
                            Inativo
                          </MenuItem>
                        </Menu>
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </BasePage>
  )
}

export default Service
