import colors from'tailwindcss/colors'
import { UserCheck } from "lucide-react";

import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import { useIndicators } from '../../../../store/subscription/useIndicators';
import { formatMoney } from '../../../../services/functions';

export function PotentialSubscribers(){
  const potential = useIndicators(st => st.data.potential)
  const data = [{
      name: 'Clientes',
      'Clientes 90 dias': potential?.clientes90Dias || 0,
      'Assinantes': potential?.assinantes || 0
  }];


  return (
    <div className="border rounded-md overflow-hidden flex flex-col flex-[2] w-full min-w-[350px] shadow-md space-y-4 h-full py-2 px-4">
      <div className="flex flex-col gap-4 w-full px-4 pt-2">
        <h4 className="text-xl text-scale-500 w-full font-bold flex items-center"><UserCheck className="mr-2 w-6 h-6" />Potencial Assinantes</h4>
        <p className="flex flex-col w-full">
          <span className="text-base text-zinc-400">
            Alcance mais de <strong>{potential?.potencialAssinantes||0} novos assinantes</strong> neste mês!
          </span>
          <span className="text-base text-zinc-400">Potencial <strong>R$ {formatMoney(potential?.potencialReceita||0)} em receita</strong> recorrente</span>
        </p>
      </div>
      <div className="flex w-full h-[300px]">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data} style={{ fontSize:12 }}>
            <XAxis dataKey="name" stroke='#888' axisLine={false} tickLine={false}  />
            <YAxis stroke='#888' axisLine={false} tickLine={false} />
            <Tooltip />
            <Legend verticalAlign="top" height={36}/>
            <CartesianGrid vertical={false} className='stroke-zinc-300/50'/>
            <Bar dataKey="Clientes 90 dias" fill={colors.orange[500]}  barSize={30} />
            <Bar dataKey="Assinantes" fill={colors.emerald[500]} barSize={30} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
