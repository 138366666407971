import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const termRemovalDeadBodyConsent = {
  name: "Termo de Consentimento para Retirada de Corpo",
  title:
    "Termo de Consentimento Livre e Esclarecido Para Retirada de Corpo de Animal em Óbito",
  gender: "male",
  fields: {
    address: {
      label: "Local do Óbito",
      type: "text",
      required: true,
      defaultPreview: _.range(30).fill("_").join(""),
      props: {
        style: { minWidth: "100%" },
      },
    },
    deathDate: {
      label: "Data do Óbito",
      type: "text",
      required: true,
      defaultPreview: "__/__/____",
      props: {
        pickDate: true,
      },
    },
    deathTime: {
      label: "Hora do Óbito",
      type: "text",
      required: true,
      defaultPreview: "__:__",
      props: {
        mask: "99:99",
      },
    },
    deathCause: {
      label: "Causa Mortis",
      type: "textarea",
      required: true,
      defaultPreview: _.range(40).fill("_").join(""),
      props: {
        rows: 5,
        style: { minWidth: "100%" },
      },
    },
    details: {
      label: "Resenha detalhada",
      type: "textarea",
      required: true,
      defaultPreview: _.range(20).fill("_").join(""),
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
    doctorNote: {
      label: "Observações do(a) Médico(a)-Veterinário(a)",
      type: "textarea",
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
    tutorNote: {
      label: "Observações do tutor(a)/proprietário(a)/responsável",
      type: "textarea",
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
  },
  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },
  content: `
    <p>Declaro para os devidos fins, que, nesta ocasião, retiro o cadáver do animal identificado neste documento, que veio a óbito na
    localidade <span>{{address}}</span> às <span>{{deathTime}}</span> horas do dia <span>{{deathDate}}</span>, cujo óbito, provocado
    pela provável causa mortis {{{deathCause}}} foi constatado pelo médico-veterinário que
    subscreve a presente, e que recebi esclarecimentos quanto à necessidade de dar tratamento respeitoso e destinação
    ambiental adequada ao cadáver, em respeito às normas ambientais.</p>
    <hr />
    <br />
    <p><strong>Resenha detalhada:</strong></p>
    <p>{{{details}}}</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}

    {{#doctorNote}}
      <br />
      <br />
      <p><strong>Observações do(a) Médico(a)-Veterinário(a):</strong></p>
      <p>{{{.}}}</p>
    {{/doctorNote}}
    {{#tutorNote}}
      <br />
      <br />
      <p><strong>Observações do tutor(a)/proprietário(a)/responsável:</strong></p>
      <p>{{{.}}}</p>
    {{/tutorNote}}
  `.trim(),
};
