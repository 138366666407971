import styled from 'styled-components'
import { Grid } from '@mui/material'

export const Container = styled(Grid)`
  & .MuiCardHeader-content {
    text-align: end;

    & span:nth-child(1) {
      color: ${(props) => props.color};
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
    }

    & span:nth-child(2) {
      color: #cccccc;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
`
