import React, { useEffect, useState } from 'react'
import API from '../services/api'

export const DashboardContext = React.createContext({})

export function DashboardProvider({ children }) {
  const [chart, setChart] = useState([])
  const [panel, setPanel] = useState([])
  const [ranking, setRanking] = useState([])
  const [selectOrder, setSelectOrder] = useState('quantity')

  async function postRanking() {
    setRanking([])
    try {
      const {
        data: { results },
      } = await API.post('dashboard', {
        metrics: ['profissionais'],
        department: 'Banho e Tosa',
        type: 'ranking-employees',
        orderBy: selectOrder,
      })
      setRanking(results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  async function postPanel() {
    try {
      const {
        data: { results },
      } = await API.post('dashboard', {
        metrics: [
          'faturamento',
          'agendamentos-hoje',
          'agendamentos-futuros',
          'total-servicos',
        ],
        department: 'Banho e Tosa',
        type: 'panel',
        period: 'today',
      })
      setPanel(results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  async function postChart() {
    try {
      const {
        data: { results },
      } = await API.post('dashboard', {
        department: 'Banho e Tosa',
        type: 'chart',
        period: 'last-6-months',
      })
      setChart(results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }
  useEffect(() => {
    postRanking()
  }, [selectOrder])

  useEffect(() => {
    postPanel()
    postChart()
  }, [])

  return (
    <DashboardContext.Provider
      value={{
        chart,
        panel,
        ranking,
        setSelectOrder,
        selectOrder,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboard = () => React.useContext(DashboardContext)
