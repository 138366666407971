import _ from "lodash";
import { TableItem } from "./TableItem";
import { TableLine } from "./TableLine";

import { useTable } from "./useTable";
import { useTableContext } from "./TableContext";
import { Empty } from "../Empty";

export function TableLoading({ lines }) {
  const { tableName } = useTableContext();
  const data = useTable((st) => st.data);
  const columns = data.columns[tableName];

  return (
    <>
      {_.range(0, lines).map((line) => (
        <TableLine key={line.toString()} tableName={tableName}>
          {columns.map(({ name }) => (
            <TableItem key={name}>
              <Empty.Line />
            </TableItem>
          ))}
        </TableLine>
      ))}
    </>
  );
}
