import { Alert } from "../../../../../../components/ui/Alert";
import { VeterinaryCareController } from "../../../../../../services/controllers/VeterinaryCare";
import { dateFormat, formatMoney, formatName } from "../../../../../../services/functions";
import { usePet } from "../../../../../../store/global/usePet";
import { useCare } from "../../../../../../store/service/veterinary/useCare";
import { useService } from "../../../../../../store/service/veterinary/useService";

import {
  SummaryDetail,
  SummaryDetails,
  SummaryServicesTable,
  SummaryServicesTableHeadLine,
  SummaryServicesTableLine,
  SummaryTitle,
  SummaryServicesTotalAmount,
  WarningText
} from "./styles";

export function SendBudget() {
  const CLVetCare = VeterinaryCareController()

  const pet = usePet(st => st.data.pet)
  const care = useCare(st => st.data.care)
  const services = useService((st) => st.data.services);

  const totalAmount = _.sumBy(Object.values(services), 'price')

  return (
    <Alert.Root  name={CLVetCare._sendBudgetAlertName}>
      <Alert.Content>
        <Alert.Title>Enviar Orçamento #{CLVetCare._ID}</Alert.Title>
        <Alert.Description>
          <p>
            Por favor, confirme a finalização do agendamento número #{CLVetCare._ID}. Esta
            ação não pode ser desfeita. Certifique-se de que todas as
            informações estão corretas antes de prosseguir.
          </p>
        </Alert.Description>
        <div>
          <SummaryTitle>Detalhes do Agendamento:</SummaryTitle>
          <SummaryDetails>
            <SummaryDetail><strong>Nome do Pet: </strong>{formatName(pet.name || '')}</SummaryDetail>
            <SummaryDetail>
              <strong>Tutor: </strong>
              {formatName(pet.owner.name.full || '')}
            </SummaryDetail>
            <SummaryDetail>
              <strong>Data do Agendamento: </strong>
              {dateFormat(care.date)}
            </SummaryDetail>
            <SummaryDetail>
              <strong>Hora: </strong>
              {care.hour}
            </SummaryDetail>
            <SummaryDetail>
              <strong>Veterinário: </strong>
              {formatName(care.veterinarian.name)}
            </SummaryDetail>
          </SummaryDetails>

          <SummaryTitle>Serviços Agendados:</SummaryTitle>

          <SummaryServicesTable>
            <SummaryServicesTableHeadLine>
              <span>Nome do Serviço</span>
              <span>Valor Original</span>
              <span>Valor Cobrado</span>
            </SummaryServicesTableHeadLine>
            {!!services &&
              Object.entries(services).map(([key, service]) => (
              <SummaryServicesTableLine key={key}>
                <span>{formatName(service.name)}</span>
                <span>R$ {formatMoney(service.originalPrice)}</span>
                <span>R$ {formatMoney(service.price)}</span>
              </SummaryServicesTableLine>
            ))}
          </SummaryServicesTable>
          <SummaryServicesTotalAmount><strong>Valor Total: </strong> <span>R$
          {formatMoney(totalAmount)}
          </span></SummaryServicesTotalAmount>

          <WarningText>
            Aviso Importante: Após a finalização, o orçamento será enviado para
            o Varejo Online (VO) e não poderá mais ser alterado.
          </WarningText>
        </div>

        <Alert.Actions>
          <Alert.Cancel>Cancelar</Alert.Cancel>
          <Alert.Confirm onClick={()=>CLVetCare.sendBudget(true)}>Tudo certo, enviar orçamento</Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
}
