import {  FilePlus2 } from "lucide-react";

import { getArticles, getPreviewListPlaceholder, isAbleByStatus } from "../../../../services/functions";

import { usePet } from "../../../../store/global/usePet";
import { useCare } from "../../../../store/service/veterinary/useCare";
import { usePrescription } from "../../../../store/service/veterinary/usePrescription";

import { VeterinaryPrescriptionController } from "../../../../services/controllers/VeterinaryCare";

import { Action } from "../../../../components/ui/Action";
import { ListPreview } from "../../../../components/ui/ListPreview";

import { IncompletePrescription } from "./Alerts/IncompletePrescription";
import { NewPrescriptionDrawer } from "./NewPrescriptionDrawer";
import { PreviewActions } from "./PreviewActions";

export function VeterinaryPrescription() {
  const CLVetPrescription = VeterinaryPrescriptionController();

  const careStatus = useCare(st => st.data.care.status)
  const isAble = isAbleByStatus(careStatus,CLVetPrescription._name)

  const pet = usePet(st => st.data.pet);
  const prescriptions = usePrescription((st) => st.data.prescriptions);

  const prescriptionList = _.values(prescriptions);

  const articles = getArticles(pet.petAttributes?.gender);

  const placeholderValues = {
    article1: articles[0],
    article2: articles[1],
    petName: pet.name
  }

  CLVetPrescription.init();


  return (
    <>
      <ListPreview.Root
        name={CLVetPrescription._previewListName}
        list={prescriptionList}
        placeholder={getPreviewListPlaceholder(CLVetPrescription._previewListName, placeholderValues)}
        onSelected={(prescription) => CLVetPrescription.selectPrescription(prescription)}
        options={{
          listStyle: "list",
          selected: null,
        }}
      >
        <ListPreview.Header>
          <Action.Root disabled={!isAble} onClick={CLVetPrescription.openDrawer}>
            <Action.Icon icon={FilePlus2} />
            Nova
          </Action.Root>
        </ListPreview.Header>
        <ListPreview.Info>
          <ListPreview.InfoActions style={{ justifyContent: "flex-end" }}>
            <PreviewActions />
          </ListPreview.InfoActions>
          <ListPreview.InfoContent />
          <ListPreview.InfoData />
        </ListPreview.Info>
      </ListPreview.Root>
      <NewPrescriptionDrawer />
      <IncompletePrescription />
    </>
  );
}
