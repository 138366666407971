import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px 10px;
  & > div {
    flex: 0 1 0%;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
`
export const TotalAmount = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  gap: 8px;
  margin: 4px 0 0 0;
  padding: 0px 8px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.scale[500]};
  font-weight: bold;
  strong{
    font-weight: normal;
    color: ${(props) => props.theme.colors.gray[900]};
    font-size:14px;
  }
`

export const FieldsBox = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  & > * {
    min-width: fit-content;
    flex: 1;
  }
`
