import { Minus, Type } from "lucide-react";

import { useContext } from "../Context";

import { MenuButton, MenuGroup } from "./styles";

export function MenuFormat() {
  const { editor } = useContext();

  return (
    <MenuGroup>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        <Minus size={18} />
      </MenuButton>
    </MenuGroup>
  );
}
