import React from 'react'
import { Tab, Tabs } from '@material-ui/core'
import BasePage from '../../../pages/basePage'

import Content from './Content'
import { PackgeProvider } from '../../../hooks/EditPackageContext'

function EditPackage() {
  return (
    <BasePage pageTitle="Editar Pacote">
      <Tabs
        value="0"
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
      >
        <Tab label="DADOS GERAIS" value="0" />
      </Tabs>
      <PackgeProvider>
        <Content />
      </PackgeProvider>
    </BasePage>
  )
}

export default EditPackage
