import { useAnimation } from "framer-motion";

import { Container } from "./styles";
import { useGradient } from "./useGradient";

export function GradientRoot({
  name,
  startColor = "transparent",
  endColor = "transparent",
}) {
  const gradientControl = useAnimation()

  const setGradient = useGradient((st) => st.actions.setGradient);
  const setControl = useGradient((st) => st.actions.setControl);

  const gradients = useGradient((st) => st.data.gradients);

  setGradient(name, { colors: [startColor, endColor] });
  setControl(name, gradientControl)

  const gradient = gradients[name];

  return (
    <Container
      variants={{
        init: { left: 0 },
        end: { left: '-200%' }
      }}
      initial="init"
      animate={gradientControl}
      transition={{ duration: .5, delay: 0 }}
      bg={gradient?.colors}
    />
  );
}
