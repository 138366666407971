import { usePet } from "../../../../../store/global/usePet";
import { CalendarDays, CalendarClock, CalendarX, CalendarCheck } from "lucide-react";

import { PetCard } from "../../../../../components/ui/PetCard";
import { Attribute, Container } from "./styles";
import { useSubscribers } from "../../../../../store/subscription/useSubscribers";

export function SubscriptionDetailsHeader (){
  const details = useSubscribers(st=>st.data.details)
  const pet = usePet(st => st.data.pet)

  return (
    <Container>
      <PetCard.Root
        noAction
        size="small"
        direction="column"
        style={{
          maxWidth: "fit-content",
          minWidth: "initial",
        }}
      >
        <PetCard.Attributes full attributes={pet.attributes} show={["Nome"]} />
        <PetCard.Avatar url={pet.photo} attributes={pet.attributes} />
      </PetCard.Root>
      <PetCard.Root
        data-full
        noAction
        direction="row-reverse"
        size="small"
        title="Informações"
        style={{
          minWidth: "400px",
        }}
      >
        <PetCard.Badges show={pet.badges} direction="column" />
        <PetCard.Attributes
          attributes={pet.attributes}
          show={[
            "Raça",
            "PetPeso",
            "Castrado",
            "Sexo",
            "Nascimento",
            "Idade",
            "Pelagem",
          ]}
        />
      </PetCard.Root>
      <PetCard.Root data-full noAction size="small" title="Tutor">
        <PetCard.Attributes
          direction="column"
          attributes={pet.attributes}
          show={["Tutor", "TutorCpf", "TutorPhone"]}
        />
      </PetCard.Root>

      <PetCard.Root data-full noAction size="small" title="Assinatura" direction="column" style={{minWidth: '390px'}}>

        <Attribute>
          <CalendarDays size={18}/>
          <span>Data da Assinatura: <strong>{details.createdAt}</strong></span>
        </Attribute>
        <Attribute>
          <CalendarDays size={18}/>
          <span>Ciclo ativo: <strong>{details.validAt}</strong></span>
        </Attribute>
        <Attribute>
          <CalendarClock size={18}/>
          <span>Próxima Cobrança: <strong>{details.nextBillingAt}</strong></span>
        </Attribute>
        <Attribute>
          <CalendarX  size={18}/>
          <span>Data do Cancelamento: <strong>{details.canceledAt}</strong></span>
        </Attribute>
        <Attribute>
          <CalendarCheck size={18}/>
          <span>Válida até: <strong>{details.validUntilDate}</strong></span>
        </Attribute>
      </PetCard.Root>
    </Container>
  )
}
