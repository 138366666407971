import Balancer from "react-wrap-balancer";
import { Card } from "../components/Card";

import { Header } from "../components/Header";
import { SchedulingHeader } from "../components/SchedulingHeader";
import { Signature } from "../components/Signature";

export function TermsService() {

  return (
    <>
      <Header.Simple />
      <Card.Root>
        <Card.Main>
          <Card.Content className="flex items-center justify-center bg-black mb-4 rounded-md pt-2">
            <p className="text-4xl font-bold text-white pb-1">Termo</p>
            <SchedulingHeader noDate />
          </Card.Content>
          <Card.Content className="flex flex-col">
            <p>Autorizo o pet da minha responsabilidade a ser submetido a procedimento de banho e tosa, estando ciente dos eventuais riscos e complicações, previamente esclarecidos, não tendo me restado dúvidas, a exemplo de contaminação por doença infectocontagiosa, alterações osteoarticulares, alergias ou outras alterações cutâneas, síncope ou desmaio, alterações cardio respiratórias e morte súbita.</p>
            <p>Desde já, em caso de risco iminente, autorizo condutas médico veterinárias necessárias para preservar a saúde, a vida e o bem-estar do meu animal.</p>
          </Card.Content>
          <Card.Content>
            <Signature.Customer />
          </Card.Content>
        </Card.Main>
      </Card.Root>
    </>
  )
}
