import styled from "styled-components";

import { Table } from "../../../Table";

export const Container = styled.main`
  grid-area: content;
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 0 12px;
`;

export const IconContainer = styled(Table.Item)`
  color: ${(props) => props.theme.colors.scale[500]};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Name = styled(Table.Item)`
  color: ${(props) => props.theme.colors.gray[700]};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
`;

export const ActionContainer = styled(Table.Item)`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
`;
