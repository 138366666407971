import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  plans: false,
};

export const usePlans = create((set) => ({
  data: defaultData,
  actions: {
    setPlans: (plans) =>
      set((state) =>
        produce(state, (draft) => {
          if (state.data.plans) return;

          draft.data.plans = plans;
        })
      ),

    setPlanFactor: (planId, factor) =>
      set((state) =>
        produce(state, (draft) => {
          const index = state.data.plans.findIndex(
            (plan) => plan.id === planId
          );
          if (!~index) return;
          draft.data.plans[index].factor = factor;
        })
      ),
    fixPlansFactor: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.plans = state.data.plans.map((plan) => ({
            ...plan,
            oldFactor: plan.factor,
          }));
        })
      ),

    undoPlansFactor: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.plans = state.data.plans.map((plan) => ({
            ...plan,
            factor: plan.oldFactor,
          }));
        })
      ),

    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),
  },
}));
