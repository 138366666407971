import { useMemo } from "react";

import { MacScrollbar } from 'mac-scrollbar';
import { useMeasure } from "react-use";
import { twMerge } from 'tailwind-merge'

import 'mac-scrollbar/dist/mac-scrollbar.css';

import { BlockTableProvider } from "./Context";

import { BlockTableBlocks } from "./BlockTableBlocks";

import { useBlockTable } from './useBlockTable';



export function BlockTableRoot({name, defaultValues = {}, ...rest}){
  const [boxRef, {  width: boxWidth }] = useMeasure();
  const [contentRef, {  width: consnteWidth }] = useMeasure();

  const register = useBlockTable.getState().actions.register

  const scrollClass = useMemo(()=> boxWidth<consnteWidth ? "pb-4" : "pb-2" ,[boxWidth, consnteWidth])

  register(name, defaultValues)

  return (
    <BlockTableProvider tableName={name}>
      <div ref={boxRef} className={twMerge("w-full flex flex-col gap-4", rest.className)} >
        <MacScrollbar>
          <div ref={contentRef} className={twMerge("w-full flex flex-col gap-4 min-w-fit ",scrollClass)}>
            <BlockTableBlocks />
          </div>
        </MacScrollbar>
      </div>
    </BlockTableProvider>
    )
}
