import { MoveRight } from 'lucide-react'
import {
  DescriptionContentFromToContent as Container,
  DescriptionContentToFromTo,
  DescriptionContentToFromArrow,
  DescriptionContentToFromFrom,
} from './styles'

export function DescriptionContentToFrom({ from, to }) {
  return (
    <Container>
      <DescriptionContentToFromTo>{from}</DescriptionContentToFromTo>
      <DescriptionContentToFromArrow>
        <MoveRight size={18} />
      </DescriptionContentToFromArrow>
      <DescriptionContentToFromFrom>{to}</DescriptionContentToFromFrom>
    </Container>
  )
}
