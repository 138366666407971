import styled from 'styled-components'

export const Container = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 3;
  list-style: none;
  margin: 0;
  gap: 12px;
  padding: 0;
`

export const StartsChartText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000;
`

export const StartsChartBar = styled.span`
  background: #ccc;
  height: 20px;
  width: 100%;
  flex: 1;
  border-radius: 5px;
  display: flex;
  position: relative;
  overflow: hidden;
  border: 1px solid #ffffff;
  transition: all 250ms linear;
  ::before {
    content: '';
    background: #ccd219;
    transition: all 250ms linear;
    width: ${(props) => props.percent}%;
    transition: all 250ms linear;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const StartsChartTotal = styled.span`
  width: 40px;
`

export const StartsChartItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 12px;
  color: #000000;
  &:hover {
    color: #ccd219;
    ${StartsChartBar} {
      border-color: #ccd219;
    }
    ${StartsChartText},${StartsChartTotal} {
      text-decoration: underline;
    }
  }
`
