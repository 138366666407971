import qs from "qs";
import { toast } from "react-toastify";

import api from "../../api";

import { formatResponse } from "../../formatResponses/global";

export default class MedicineModel {
  findMedicines = async (findProps = {}) => {

    const searchQuery = qs.stringify(findProps)

    try {
      const { data } = await api.get(`medicines/search?${searchQuery}`);

      const formattedMerchant = formatResponse.medicines(data);
      return formattedMerchant;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}

