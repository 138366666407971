import { StyleSheet } from "@react-pdf/renderer";

import { getCurrentThemeStyle } from "../../../../services/functions";

const theme = getCurrentThemeStyle()

export const container = StyleSheet.create({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-start",
})

export const electronicSignature = StyleSheet.create({
  justifyContent: "flex-start",
  alignItems: "baseline",
  textAlign: "right",
  fontSize: "8px",
  fontFamily: "Helvetica",
  margin: "0 0 4px 0",
  color: theme.colors.gray[400],
})

export const doctorInfo = StyleSheet.create({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  gap: "4px",
})

export const doctorName = StyleSheet.create({
  justifyContent: "flex-start",
  alignItems: "baseline",
  textAlign: "right",
  fontSize: "10px",
  fontFamily: 'Helvetica-Bold',
  margin: "0 0 4px 0",
  color: theme.colors.gray[600],
})

export const doctorIdType = StyleSheet.create({
  justifyContent: "flex-start",
  alignItems: "baseline",
  textAlign: "right",
  fontSize: "10px",
  fontFamily: "Helvetica",
  margin: "0 0 4px 0",
  color: theme.colors.gray[500],
})

export const doctorId = StyleSheet.create({
  justifyContent: "flex-start",
  alignItems: "baseline",
  textAlign: "right",
  fontSize: "10px",
  fontFamily: "Helvetica",
  margin: "0 0 4px 0",
  color: theme.colors.scale[600],
})
