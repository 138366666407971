import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  TaxiDogItem: {
    borderBottom: '1px solid #666666',
    marginBottom: '20px',
    marginTop: '40px',
    padding: '10px 0',
  },
  TaxiDogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  TaxiDogText: { color: '#666666' },
  TaxiDogLine: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '100px',
    marginBottom: '5px',
  },
}))
