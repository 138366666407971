import React from 'react'
import BasePage from '../../../pages/basePage'
import TableEmployee from '../TableEmployee'

// import { Container } from './styles';

function Line() {
  return (
    <BasePage pageTitle="Horário de Trabalho">
      <TableEmployee />
    </BasePage>
  )
}

export default Line
