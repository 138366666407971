import { InvoiceModel } from "../../models/subscription";
import { clearNumber, dateFormatedToDB } from "../../functions";

export default class InvoiceController {
  constructor() {
    this.name = "subscriptions-invoices";
    this.tableName = `${this.name}-table`;
    this.paginationName = `${this.name}-pagination`;

    this.MInvoice = InvoiceModel();

    this.init();
  }

  init = async () => {
    Promise.all([this.findSummary(), this.findInvoices()]);
  };

  findSummary = async () => {
    await this.MInvoice.findSummary();
  };

  findInvoices = async () => {
    await this.MInvoice.findInvoices();
  };

  setPage = (page) => {
    this.MInvoice.setPage(page);
  };

  setFilter = (filterData) => {
    const filter = _.pickBy(
      {
        dateFrom: dateFormatedToDB(filterData.dateFrom),
        dateTo: dateFormatedToDB(filterData.dateTo),
      },
      _.identity
    );

    if (filterData.type) {
      filter.type = filterData.type;
    }

    if (filterData.cpf !== undefined) {
      filter.cpf = clearNumber(filterData.cpf);
    }

    this.MInvoice.setFilter(filter);
  };

  filterInvoices = async (e) => {
    if (e) e.preventDefault();
    await this.MInvoice.filterInvoices();
  };

  reportExport = () => {
    this.MInvoice.export();
  };
}
