import _ from "lodash";

import { Download, FileUp, Trash2 } from "lucide-react";
import { FaWhatsapp } from "react-icons/fa";

import { getArticles, getPreviewListPlaceholder, isAbleByStatus } from "../../../../services/functions";

import { useCare } from "../../../../store/service/veterinary/useCare";
import { useAttachment } from "../../../../store/service/veterinary/useAttachment";

import { VeterinaryCareController, VeterinaryAttachmentController } from "../../../../services/controllers/VeterinaryCare";

import { ListPreview } from "../../../../components/ui/ListPreview";
import { Action } from "../../../../components/ui/Action";
import { File } from "../../../../components/ui/Form/File";
import { usePet } from "../../../../store/global/usePet";
import { Tabs } from "../../../../components/ui/Tabs";

export function VeterinaryAttachments() {
  const CLVetCare = VeterinaryCareController();
  const CLVetAttachment = VeterinaryAttachmentController();

  const careStatus = useCare(st => st.data.care.status)
  const isAble = isAbleByStatus(careStatus,CLVetAttachment._name)

  const pet = usePet(st => st.data.pet);

  const isLoading = useAttachment((st) => st.data.isLoading);
  const attachments = useAttachment((st) => st.data.attachments);
  const selectAttachment = useAttachment((st) => st.data.selectAttachment);

  const veterinaryCareTabs = Tabs.store(st => st.data.tabs[CLVetCare._tabsName])

  const isActiveTab = !!veterinaryCareTabs.find(tab => tab.title === CLVetAttachment._label && tab.active);

  const attachmentsList = _.values(attachments);

  CLVetAttachment.init();

  if (isActiveTab) CLVetAttachment.getThumbs();

  const articles = getArticles(pet.petAttributes?.gender);

  const placeholderValues = {
    article: articles[1],
    petName: pet.name
  }

  return (
    <ListPreview.Root
      name={CLVetAttachment._previewListName}
      list={attachmentsList}
      placeholder={getPreviewListPlaceholder(CLVetAttachment._previewListName, placeholderValues)}
      onSelected={(attachment) => CLVetAttachment.selectAttachment(attachment)}
      options={{
        selected: null,
        listStyle: "bigGrid",
      }}
    >
      <ListPreview.Header>
        <File.Root
          disabled={!isAble}
          onChange={(file) => {
            CLVetAttachment.sendAttachment(file.formData);
          }}
        >
          <Action.Root
            asChild
            isLoading={isLoading}
            disabled={!isAble}
            style={{ minWidth: "100px" }}
          >
            <span>
              <Action.Icon isLoading={isLoading} icon={FileUp} size={22} />
            </span>
          </Action.Root>
        </File.Root>
      </ListPreview.Header>
      <ListPreview.Info>
        <ListPreview.InfoActions style={{ justifyContent: "flex-end" }}>
          <Action.Root
            type="button"
            title="Baixar Anexo"
            name="download-attachment"
            download={{
              path: selectAttachment?.path,
              name: selectAttachment?.slug,
            }}
          >
            <Action.Icon icon={Download} size={22} />
          </Action.Root>

          <Action.Root
            type="button"
            status="success"
            title="Enviar por WhatsApp"
            name="send-attachment"
            onClick={() => CLVetAttachment.sendMessage()}
          >
            <Action.Icon icon={FaWhatsapp} size={22} />
          </Action.Root>
          <Action.Root
            type="button"
            status="danger"
            title="Excluir Anexo"
            name="delete-attachment"
            onClick={() => CLVetAttachment.deleteAttachment()}
          >
            <Action.Icon icon={Trash2} size={22} />
          </Action.Root>
        </ListPreview.InfoActions>
        <ListPreview.InfoContent />
        <ListPreview.InfoData />
      </ListPreview.Info>
    </ListPreview.Root>
  );
}
