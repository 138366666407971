import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const SelectContainer = styled.div`
  padding: 10px 20px;
`

export const Label = styled.label`
  padding: 8px 0px 0px 0px;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  margin-bottom: 10px;
  padding-right: 20px;
`
