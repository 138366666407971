import { SummaryStatusIcon } from './SummaryStatusIcon'
import { SummaryBox as Container } from './styles'

export function SummaryBox({ children,  ...rest }) {
  return (
    <Container {...rest} data-status={rest.status} >
      {children}
      <SummaryStatusIcon status={rest.status} />
    </Container>
  )
}
