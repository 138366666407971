
import { twMerge } from 'tailwind-merge'

export function CardHeaderTitle({ className, ...rest }) {
  return (
    <div
      className={twMerge(
        'flex rounded-md bg-black px-4 py-2 text-sm font-semibold tracking-wider text-white',
        className,
      )}
      {...rest}
    />
  )
}
