const configurations = {}

configurations.unsavedExpenses = `{{#show}}<p>Você modificou as despesas {{monthText}} <strong>{{monthList}}</strong>, porém elas não foram salvas no sistema.</p>{{/show}}`
configurations.unsavedGoals = `{{#show}}<p>Você modificou as metas {{monthText}} <strong>{{monthList}}</strong>, porém elas não foram salvas no sistema.</p>{{/show}}`

export const dre = Object.freeze({
  configurations
})



