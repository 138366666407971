import { toast } from "react-toastify";

import API from "../../api";
import { format } from "../../formatResponses/subscriptions";

export default class SubscriptionDREModel {

  findMonths = async (monsths = []) => {
    try {
      const { data } = await API.get(`/dashboard/dre-assinatura/buscar-dres?periodos=${monsths.join(',')}`);

      const formattedMonths = format.dre(data.dres);
      return formattedMonths
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  findGoals = async (monsth) => {
    try {
      const { data } = await API.get(`/dashboard/dre-assinatura/metas/default?periodos=${monsth}`);

      const formattedMonths = format.dre(data.dres);
      return formattedMonths
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  findExpenses = async (monsth) => {
    try {
      const { data } = await API.get(`/dashboard/dre-assinatura/despesas?periodo=${monsth}`);
      const formattedExpenses = format.customExpenses(data.despesas);

      return formattedExpenses
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  findGoals = async (monsth) => {
    try {
      const { data } = await API.get(`/dashboard/dre-assinatura/metas?periodo=${monsth}`);
      const formattedGoals = format.customGoals(data);

      return formattedGoals
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  savePeriodExpenseConfig = async (newExpenseConfig) => {
    try {
      await API.post(`/dashboard/dre-assinatura/despesa`, {
         periodo: newExpenseConfig.period,
         despesa: newExpenseConfig.id,
         valor: newExpenseConfig.value,
      });

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  savePeriodGoalConfig = async (newGoalConfig) => {
    try {
      await API.post(`/dashboard/dre-assinatura/meta`, {
         periodo: newGoalConfig.period,
         meta: newGoalConfig.id,
         valor: newGoalConfig.value,
      });

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
