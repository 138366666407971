import React, { useEffect, useState } from 'react'
import moment from 'moment'
import API from '../services/api'
// moment
import 'moment/locale/pt-br'

moment.locale('pt-br')

export const BlockEmployeeContext = React.createContext({})

export function BlockEmployeeProvider({ children }) {
  const [employeeId, setEmployeeId] = useState(false)
  const [toEmployeeId, setToEmployeeId] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [dates, setDates] = useState({ start: '', end: '' })
  const [description, setDescription] = useState('')
  const [horarioInicio, setHorarioInicio] = useState('08:00')
  const [horarioFim, setHorarioFim] = useState('18:00')
  const [valid, setValid] = useState(true)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [selectedOption, setSelectedOption] = React.useState('specific-date')
  const [daysSelected, setDaysSelected] = useState({
    SEGUNDA: false,
    TERÇA: false,
    QUARTA: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  })

  const selectedDays = Object.keys(daysSelected).filter(
    (day) => daysSelected[day],
  )

  function getCurrentDate() {
    const now = new Date()
    const year = now.getFullYear()
    const month = `${now.getMonth() + 1}`.padStart(2, '0')
    const day = `${now.getDate()}`.padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  const [date, setDate] = React.useState(getCurrentDate())

  async function postBlockEmployee(getCalendar, setBlock) {
    let payload = ''

    if (selectedOption === 'specific-date') {
      const newPayload = {
        dateFilterType: selectedOption,
        dateFilter: {
          date: moment(date).format('DD/MM/YYYY'),
          startsAt: isChecked ? '08:00' : horarioInicio,
          finishesAt: isChecked ? '18:00' : horarioFim,
          blockWholeDay: isChecked,
        },
        description,
      }

      payload = newPayload
    } else if (selectedOption === 'specific-period') {
      const newPayload = {
        dateFilterType: selectedOption,
        dateFilter: {
          initialDate: moment(dates.start).format('DD/MM/YYYY'),
          finalDate: moment(dates.end).format('DD/MM/YYYY'),
          startsAt: isChecked ? '08:00' : horarioInicio,
          finishesAt: isChecked ? '18:00' : horarioFim,
          blockWholeDay: isChecked,
        },
        description,
      }

      payload = newPayload
    } else if (selectedOption === 'week-day') {
      const newPayload = {
        dateFilterType: selectedOption,
        dateFilter: {
          initialDate: moment(dates.start).format('DD/MM/YYYY'),
          finalDate: moment(dates.end).format('DD/MM/YYYY'),
          weekDays: selectedDays,
          startsAt: isChecked ? '08:00' : horarioInicio,
          finishesAt: isChecked ? '18:00' : horarioFim,
          blockWholeDay: isChecked,
        },
        description,
      }

      payload = newPayload
    }

    try {
      const data = await API.post(`employee/${employeeId}/block-hours`, payload)

      if (data.status === 201) {
        setSuccess(true)
        getCalendar(moment().format('YYYY-MM-DD'))
        setTimeout(() => {
          setBlock(false)
        }, 1000)
      }
    } catch (err) {
      setSuccess(false)
      setError(err?.response?.data)
    }
  }

  async function postBlockToEmployee(getCalendar, setBlock) {
    let payload = ''

    if (selectedOption === 'specific-date') {
      const newPayload = {
        dateFilterType: selectedOption,
        dateFilter: {
          date: moment(date).format('DD/MM/YYYY'),
          startsAt: isChecked ? '08:00' : horarioInicio,
          finishesAt: isChecked ? '18:00' : horarioFim,
          blockWholeDay: isChecked,
        },
        description,
        toEmployeeId,
      }

      payload = newPayload
    } else if (selectedOption === 'specific-period') {
      const newPayload = {
        dateFilterType: selectedOption,
        dateFilter: {
          initialDate: moment(dates.start).format('DD/MM/YYYY'),
          finalDate: moment(dates.end).format('DD/MM/YYYY'),
          startsAt: isChecked ? '08:00' : horarioInicio,
          finishesAt: isChecked ? '18:00' : horarioFim,
          blockWholeDay: isChecked,
        },
        description,
        toEmployeeId,
      }

      payload = newPayload
    } else if (selectedOption === 'week-day') {
      const newPayload = {
        dateFilterType: selectedOption,
        dateFilter: {
          initialDate: moment(dates.start).format('DD/MM/YYYY'),
          finalDate: moment(dates.end).format('DD/MM/YYYY'),
          weekDays: selectedDays,
          startsAt: isChecked ? '08:00' : horarioInicio,
          finishesAt: isChecked ? '18:00' : horarioFim,
          blockWholeDay: isChecked,
        },
        description,
        toEmployeeId,
      }

      payload = newPayload
    }

    try {
      const data = await API.post(
        `employee/${employeeId}/transfer-sos`,
        payload,
      )

      if (data.status === 201) {
        setSuccess(true)
        getCalendar(moment().format('YYYY-MM-DD'))
        setTimeout(() => {
          setBlock(false)
        }, 1000)
      }
    } catch (err) {
      console.log('Erro:', err?.response?.data)
    }
  }

  useEffect(() => {
    setError(false)
    setSuccess(false)
  }, [selectedOption])

  return (
    <BlockEmployeeContext.Provider
      value={{
        employeeId,
        setEmployeeId,
        postBlockEmployee,
        setSelectedOption,
        selectedOption,
        isChecked,
        setIsChecked,
        date,
        setDate,
        dates,
        setDates,
        description,
        setDescription,
        horarioInicio,
        setHorarioInicio,
        horarioFim,
        setHorarioFim,
        daysSelected,
        setDaysSelected,
        valid,
        setValid,
        selectedDays,
        postBlockToEmployee,
        toEmployeeId,
        setToEmployeeId,
        error,
        success,
        setSuccess,
      }}
    >
      {children}
    </BlockEmployeeContext.Provider>
  )
}

export const useBlockEmployee = () => React.useContext(BlockEmployeeContext)
