import styled from "styled-components";

export const ContentLabel = styled.span`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px;
  width: calc(100% - 24px);
  color: ${(props) => props.theme.colors.gray[700]};
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[100]};
  &:last-child{
    border: none;
  }
  strong{
    font-weight: normal;
    color: ${(props) => props.theme.colors.scale[500]};
  }
`;
