import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 12px;
`

export const Title = styled.h2`
  color: ${props => props.theme.colors.scale[500]};
  margin: 0;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  &>*{
    min-width: fit-content;
    flex: 1;
  }
`

export const FormActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  & > * {
    width: fit-content;
  }
  & > div{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    gap: 16px;
  }
`;
