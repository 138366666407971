import { produce } from "immer";
import { create } from "zustand";

import { getPaginationProps } from "../../../services/functions";

const defaultData = {
  show: 0,
  perPage: 0,
  currentPage: 0,
  totalPages: 0,
  totalDocuments: 0,
  pageEmitter: false,
};

export const usePagination = create((set) => ({
  data: {
    pagination: {},
  },
  actions: {
    setPagination: (name, config) =>
      set((state) =>
        produce(state, (draft) => {

          if (!!state.data.pagination[name]) return;

          const defaultPagination = getPaginationProps(name);

          draft.data.pagination[name] = {
            ...defaultData,
            ...defaultPagination,
            ...config,
            pageEmitter: false,
          };
        })
      ),
    updatePaginationProps: (name, props) =>
      set((state) =>
        produce(state, (draft) => {
          const allPagination = state.data.pagination;
          if (!allPagination[name]) return false;
          const pagination = state.data.pagination[name];

          draft.data.pagination[name] = {
            ...pagination,
            ...props,
          };
        })
      ),

    setCurrentPage: (name, page) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data.pagination[name]) return;
          draft.data.pagination[name].pageEmitter = page;
        })
      ),
  },
}));
