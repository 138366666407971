import React, { useEffect, useState } from 'react'
import moment from 'moment'
import API from '../services/api'

// moment
import 'moment/locale/pt-br'
import { getDepartmentName } from '../services/functions'

moment.locale('pt-br')

export const ReportContext = React.createContext({})

export function ReportProvider({ children }) {
  const defaultDepartment = getDepartmentName() === 'Dra.Mei'?'Clínica Veterinária':'Banho e tosa'
  const date = new Date()
  const primeiroDia = moment(
    new Date(date.getFullYear(), date.getMonth(), 1),
  ).format('YYYY-MM-DD')

  const [downloads, setDownloads] = useState([])
  const [department, setDepartment] = useState(defaultDepartment)

  // CONST RELATORIO AGENDAMENTOS
  const [infoRepoart, setInfoRepoart] = useState(null)
  const [firstData, setFirstData] = useState(primeiroDia)
  const [deadLine, setDeadLine] = useState(null)
  const [lastData, setLastData] = useState(null)
  const [paginationAgenda, setPaginationAgenda] = useState(null)
  const [infoPanel, setInfoPanel] = useState(null)
  const [profissional, setProfissional] = useState(null)
  const [employeeId, setEmployeeId] = useState(null)
  const [status, setStatus] = useState(null)
  const [cpf, setCpf] = useState(null)

  // SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [messageExport, setMessageExport] = useState(null)
  const [disabledBtn, setDisabledBtn] = useState(false)

  const handleClose = () => {
    setOpenSnackbar(false)
    setMessageExport(null)

    setTimeout(() => {
      setDisabledBtn(false)
    }, 6000)
  }

  async function getProf() {
    try {
      const { data } = await API.get('employees/search?status=ATIVO')
      setProfissional(data?.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  async function getDownloads() {
    try {
      const {
        data: { downloads },
      } = await API.get('reports/downloads')
      setDownloads(downloads)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  async function postExport(value) {
    try {
      const {
        data: { message },
      } = await API.post('reports/export', {
        filters: {},
        type: {
          name: value,
          format: 'xlxs',
        },
      })
      setMessageExport(message)
      if (message) {
        setOpenSnackbar(true)
        getDownloads()
      }
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  async function getReports(currentePage = 0) {

    try {
      const { data } = await API.post('reports', {
        filters: {
          dateFrom: moment(firstData).format('YYYY-MM-DD'),
          dateTo: lastData || moment(date).format('YYYY-MM-DD'),
          status: status === 'TODOS' ? null : status,
          cpf,
          employee: employeeId === 'TODOS' ? null : employeeId ,
          department,
          // department: !department ? defaultDepartment: department,
        },
        type: {
          name: 'appointments',
          format: 'json',
        },
        pagination: {
          currentPage: currentePage,
          perPage: 20,
        },
      })

      setInfoRepoart(data?.results)
      setPaginationAgenda(data?.pagination)
      setInfoPanel(data?.panel)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getReports()
    getProf()
  }, [])

  useEffect(() => {
    setDeadLine(
      moment(firstData, 'YYYY-MM-DD').add(60, 'days').format('YYYY-MM-DD'),
    )
  }, [firstData])

  useEffect(() => {
    getDownloads()
  }, [])

  return (
    <ReportContext.Provider
      value={{
        downloads,
        deadLine,
        firstData,
        setFirstData,
        lastData,
        setLastData,
        infoRepoart,
        paginationAgenda,
        infoPanel,
        setDepartment,
        getReports,
        department,
        getDownloads,
        profissional,
        setEmployeeId,
        employeeId,
        setStatus,
        setCpf,
        cpf,
        status,
        setInfoRepoart,
        postExport,
        handleClose,
        openSnackbar,
        messageExport,
        disabledBtn,
        setDisabledBtn,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

export const useDashboard = () => React.useContext(ReportContext)
