import { getSubscriberStatusColor, getSubscriberStatusText } from "../../../../services/functions"

import { StatusDot, Container } from "./styles"

export function Status({ children }) {

  const background = getSubscriberStatusColor(children)

  return (
    <Container>
      <StatusDot style={{ background }} /> {children}
    </Container>
  )
}
