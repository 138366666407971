import { Svg, Line, Polyline, Polygon, Path, Rect, Circle, Ellipse, View } from '@react-pdf/renderer';

import lucideIcons from 'lucide-static/icon-nodes.json';
import { phosphorIconsPaths } from './phosphorIconsPaths';

function SvgElement({ element, ...rest }) {
  switch (element) {
    case 'line': return <Line {...rest} />
    case 'polyline': return <Polyline {...rest} />
    case 'polygon': return <Polygon {...rest} />
    case 'path': return <Path {...rest} />
    case 'rect': return <Rect {...rest} />
    case 'circle': return <Circle {...rest} />
    case 'ellipse': return <Ellipse {...rest} />

    default: return null
  }

}

const isPhosphor = /^ph-/;

export function PdfIcon({ iconName = 'dog', size = 24, style = {} }) {
  const color = style.color || 'black'

  const iconProps = {
    paths: lucideIcons[iconName],
    viewBox: "0 0 24 24"
  }

  if (isPhosphor.test(iconName)) {
    iconProps.paths = phosphorIconsPaths[iconName]
    iconProps.viewBox = "0 0 256 256"
  }


  return (
    <View style={style}>
      <Svg viewBox={iconProps.viewBox} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ display: 'flex', width: '100%', height: '100%', objectFit: 'cover' }}>
        {iconProps.paths.map(([element, props], index) => (
          <SvgElement
            key={index}
            element={element}
            stroke={color}
            {...props}
          />
        ))}
      </Svg>
    </View>
  );
}



