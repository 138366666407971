import { Slot } from "@radix-ui/react-slot";

import { Tooltip } from "../Tooltip";

import { Container, Button, TipContent } from "./styles";

export function ActionRoot({
  children,
  asChild,
  title,
  name = false,
  download = false,
  isLoading = false,
  ...rest
}) {
  const El = asChild ? Slot : "button";
  let clickAction = () => null;

  if (isLoading) rest.disabled = true;

  const downloadAction = () => {
    if (!download.path) return;

    const link = document.createElement("a");

    link.href = download.path;
    link.download = download.name || "downloaded_file";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!!download) clickAction = downloadAction;

  return (
    <Container>
      {!!name && !!title && (
        <Tooltip.Root name={name} side="top" sideOffset={26} zIndex={112}>
          <TipContent>{title}</TipContent>
        </Tooltip.Root>
      )}
      <Tooltip.Trigger tooltipName={name} asChild>
        <Button as={El} onClick={clickAction} {...rest}>
          {children}
        </Button>
      </Tooltip.Trigger>
    </Container>
  );
}
