import _ from 'lodash'

import { PlanController } from '../../../services/controllers/subscription'
import { formatMoney } from '../../../services/functions'

import { usePlans } from '../../../store/subscription/usePlans'

import { Input } from '../../../components/ui/Form/Input'
import { Action } from '../../../components/ui/Action'

import { SaveRules } from './Alerts/SaveRules'

import {
  Container,
  Title,
  PlanTable,
  PlanTableHead,
  PlanTableHeadItem,
  PlanTableBody,
  PlanTableLine,
  PlanTableItem,
  PlanTableHeadItemDescription,
  PlanTableHeadItemTitle,
  Content,
  PlanConfiguration,
  PlanConfigurationList,
  PlanConfigurationItem,
  PlanConfigurationDescription,
  Warning,
  Actions,
} from './styles'

export function Configurations() {
  const plans = usePlans((st) => st.data.plans)

  const CPlan = PlanController()
  CPlan.findPlans()

  const disabledSave = !_.some(plans, (plan) => plan.factor !== plan.oldFactor)

  return (
    <>
      <Container>
        <Title>Valores dos Banhos Individuais</Title>
        <Content>
          <PlanConfiguration>
            <PlanConfigurationDescription>
              Cada banho terá seu valor calculado com base na assinatura, o qual
              será determinado dividindo o valor total da assinatura por uma
              quantidade de banhos:
            </PlanConfigurationDescription>
            <PlanConfigurationList>
              {!!plans &&
                plans.map((plan) => (
                  <PlanConfigurationItem key={plan.id}>
                    <span className="fix">Plano {plan.name} dividir por</span>
                    <Input.Root
                      value={plan.factor}
                      money
                      noSuffix
                      decimals={2}
                      onChange={(factor) => {
                        CPlan.setFactor(plan.id, factor)
                      }}
                    />
                    <span>banhos</span>
                  </PlanConfigurationItem>
                ))}
            </PlanConfigurationList>
            <Actions>
              <Warning>
                ATENÇÃO - Essa regra será válida apenas para agendamentos
                finalizados a partir de hoje.
              </Warning>
              <Action.Root
                type="button"
                disabled={disabledSave}
                onClick={CPlan.saveRulesAlert}
              >
                Salvar
              </Action.Root>
            </Actions>
          </PlanConfiguration>
          <PlanTable>
            <PlanTableHead totalPlans={plans.length}>
              <PlanTableHeadItem />
              {!!plans &&
                plans.map((plan) => (
                  <PlanTableHeadItem key={plan.id}>
                    <PlanTableHeadItemTitle>{plan.name}</PlanTableHeadItemTitle>
                    <PlanTableHeadItemDescription>
                      Valor
                    </PlanTableHeadItemDescription>
                    <PlanTableHeadItemDescription>
                      Banho
                    </PlanTableHeadItemDescription>
                  </PlanTableHeadItem>
                ))}
            </PlanTableHead>
            <PlanTableBody totalPlans={plans.length}>
              <PlanTableLine>
                {!!plans &&
                  plans[0].sizes.map((size) => (
                    <PlanTableItem key={size.id}>
                      <h3>{size.name}</h3>
                    </PlanTableItem>
                  ))}
              </PlanTableLine>
              {!!plans &&
                plans.map((plan) => (
                  <PlanTableLine key={plan.id}>
                    {plan.sizes.map((size) => (
                      <PlanTableItem key={size.id}>
                        <p>
                          <strong>R$ {formatMoney(size.value)}</strong>
                        </p>
                        <p>R$ {formatMoney(size.value / (plan.factor || 1))}</p>
                      </PlanTableItem>
                    ))}
                  </PlanTableLine>
                ))}
            </PlanTableBody>
          </PlanTable>
        </Content>
      </Container>
      <SaveRules />
    </>
  )
}
