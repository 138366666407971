import { EmployeeAvatar } from './Avatar'
import { EmployeeInformationSubTitle } from './EmployeeInformationSubTitle'
import { EmployeeInformationTitle } from './EmployeeInformationTitle'
import { EmployeeInformations } from './EmployeeInformations'
import { EmployeePosition } from './EmployeePosition'
import { EmployeeRoot } from './EmployeeRoot'

export const Employee = {
  Root: EmployeeRoot,
  Position: EmployeePosition,
  Avatar: EmployeeAvatar,
  Informations: EmployeeInformations,
  InformationTitle: EmployeeInformationTitle,
  InformationSubTitle: EmployeeInformationSubTitle,
}
