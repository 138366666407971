import React, { useEffect, useState } from 'react'

import moment from 'moment'
import styles from './DatePicker.module.css'

// moment
import 'moment/locale/pt-br'

moment.locale('pt-br')

function YearView({ dateActive, getSelectedDay, color }) {
  const [selectedDate, setSelectedDate] = useState(null)
  const themColor = color || 'rgb(204, 210, 25)'
  const selectedStyle = {
    color: themColor,
    fontWeight: 'bold',
  }

  const getStyles = (day) => (day === selectedDate ? selectedStyle : null)

  const getId = (day) => (day === selectedDate ? 'selectedd' : '')

  const onDateClick = (day) => {
    setSelectedDate(day)
    if (getSelectedDay) {
      getSelectedDay(new Date(day, dateActive.getMonth(), dateActive.getDate()))
    }
  }
  const renderDays = () => {
    const year = []
    const start = 1995
    const end = 3050

    for (let j = start; j < end; j += 1) {
      year.push(
        <div
          id={`${getId(j)}`}
          className={styles.yearContainer}
          style={getStyles(j)}
          key={j}
          onClick={() => onDateClick(j)}
        >
          {j}
        </div>,
      )
    }

    return (
      <div id="container" className={styles.dateListScrollable}>
        {year}
      </div>
    )
  }

  useEffect(() => {
    if (dateActive) {
      setSelectedDate(parseInt(moment(dateActive).format('yyy'), 10))
    }
  }, [dateActive])

  useEffect(() => {
    const startScrollIntoView = (view) => {
      view.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'nearest',
      })
    };

    const view = document.getElementById('selectedd')
    if (view) startScrollIntoView(view)
  }, [])


  return <>{renderDays()}</>
}

export { YearView }
