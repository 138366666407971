import { Bone } from 'lucide-react'
import { Icon as Container } from './styles'

export function InputIcon({ Icon = Bone }) {
  return (
    <Container>
      <Icon size={20} />
    </Container>
  )
}
