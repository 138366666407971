import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, MenuItem, Switch, Grid, Button } from '@material-ui/core'
import API from '../../../services/api'

import Modal from '../../../components/atoms/Modal'

import Feriados from './Feriados'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))
const hours = [
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
]
const weekDayList = [
  {
    id: 'monday',
    name: 'Segunda-Feira',
  },
  {
    id: 'tuesday',
    name: 'Terça-Feira',
  },
  {
    id: 'wednesday',
    name: 'Quarta-Feira',
  },
  {
    id: 'thursday',
    name: 'Quinta-Feira',
  },
  {
    id: 'friday',
    name: 'Sexta-Feira',
  },
  {
    id: 'saturday',
    name: 'Sábado',
  },
  {
    id: 'sunday',
    name: 'Domingo',
  },
]

function HorarioFuncionamento() {
  const classes = useStyles()
  const { register, control, handleSubmit } = useForm()
  const [shoppingHours, setShoppingHours] = useState(false)

  const [openModal, setOpenModal] = useState(null)

  const closeModal = () => {
    setOpenModal(false)
  }

  const onSubmit = async (data) => {
    const shoppingHours = weekDayList.map((day) => ({
      active: data[`status-${day.id}`],
      startsAt: data[`incio-${day.id}`],
      stopsAt: data[`fim-${day.id}`],
      weekDay: day.id,
    }))

    const result = await API.post('store/shopping-hours', { shoppingHours })
    setOpenModal(result.data.message)
  }

  useEffect(() => {
    const getHours = async () => {
      const result = await API.get('store/shopping-hours')
      setShoppingHours(result.data.shoppingHours)
    }

    if (!shoppingHours) {
      getHours()
    }
  }, [])

  if (!shoppingHours) {
    return null
  }

  return (
    <>
      <Modal
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        verification={false}
      />
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} xs={8}>
          <Grid item xs={3}>
            <h3>Dia da Semana</h3>
          </Grid>
          <Grid item xs={3}>
            <h3>Status</h3>
          </Grid>
          <Grid item xs={6}>
            <h3>Horário de Funcionamento</h3>
          </Grid>
        </Grid>
        {shoppingHours.map((day, index) => (
          <Grid container spacing={3} xs={8} key={day.weekDay}>
            <Grid item xs={3}>
              <p>{weekDayList[index].name}</p>
            </Grid>
            <Grid item xs={3}>
              <Switch
                defaultChecked={day.active}
                color="primary"
                name={`status-${day.weekDay}`}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                defaultValue={day.startsAt}
                name={`incio-${day.weekDay}`}
                as={
                  <TextField fullWidth select label="INICIO" variant="outlined">
                    {hours.map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                defaultValue={day.stopsAt}
                name={`fim-${day.weekDay}`}
                as={
                  <TextField fullWidth select label="FIM" variant="outlined">
                    {hours.map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid>
          </Grid>
        ))}
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>
        </Grid>
      </form>
      <Feriados />
    </>
  )
}

export default HorarioFuncionamento
