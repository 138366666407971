import { useState } from 'react'

import { useSchedule } from '../../../../hooks/ScheduleContext'

import DrawerType from '../../../../components/atoms/Drawer'
import TaxiDog from '../../../../components/organisms/Schedule/TaxiDog'

import { ActionButton } from '../styles'

export function TaxiDogButton() {
  const [isOpen, setIsOpen] = useState(false)

  const { getConfig } = useSchedule()
  const config = getConfig()
  return (
    <>
      <DrawerType
        open={isOpen}
        setDrowerOpen={setIsOpen}
        titleHeader="TAXI DOG"
        anchor="left"
        content={<TaxiDog date={`${config.date} 00:00:00`} />}
      />
      <ActionButton onClick={() => setIsOpen(true)}>Taxi Dog</ActionButton>
    </>
  )
}
