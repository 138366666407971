import { useEffect, useState } from "react";
import { FileDown, UploadCloud } from "lucide-react";
import useSWR from "swr";

import API from "../../../services/api";

import { Table } from "../../../components/ui/Table";
import { Action } from "../../../components/ui/Action";

import { formatMoney, stringDateFormat } from "../../../services/functions";
import { Container } from "./styles";

export function Fechamento() {
  const [statements, setStatements] = useState([]);

  const { data, isLoading } = useSWR("subscriptions/store/statement", (url) =>
    API.get(url).then((res) => res.data)
  );

  useEffect(() => {
    if (!isLoading) {
      setStatements(data.statements);
    }
  }, [data, isLoading]);

  const disable = (e, isDisable) => isDisable && e.preventDefault();

  return (
    <Container>
      <Table.Root name="assinaturas-fechamento" config>
        <Table.Header
          columns={[
            "Data de pgto",
            "Data inicio",
            "Data fim",
            "Frequência",
            "Descrição",
            "Mensalidades",
            "Valor de repasse",
            "Nota Fiscal:1fr",
            "Baixar Planilha:1fr",
          ]}
        />
        <Table.Content>
          {statements.map((statement) => (
            <Table.Line key={statement._id}>
              <Table.Item>{stringDateFormat(statement.paymentDate)}</Table.Item>
              <Table.Item>{stringDateFormat(statement.periodStart)}</Table.Item>
              <Table.Item>{stringDateFormat(statement.periodEnd)}</Table.Item>
              <Table.Item>{statement.frequency}</Table.Item>
              <Table.Item>{statement?.description || "Banho"}</Table.Item>
              <Table.Item>{statement.baths}</Table.Item>
              <Table.Item>
                R$
                {formatMoney(statement.amount)}
              </Table.Item>
              <Table.Item>
                <Action.Root
                  outline={statement?.description !== "Mensalidade"}
                  asChild
                  disabled={statement?.description === "Mensalidade"}
                >
                  <a
                    onClick={(e) =>
                      disable(e, statement?.description === "Mensalidade")
                    }
                    href="https://app.pipefy.com/portals/assinatura"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Action.Icon icon={UploadCloud} />
                    Nota Fiscal
                  </a>
                </Action.Root>
              </Table.Item>
              <Table.Item>
                <Action.Root outline asChild>
                  <a
                    href={statement.spreedsheet}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Action.Icon icon={FileDown} />
                    Baixar planilha
                  </a>
                </Action.Root>
              </Table.Item>
            </Table.Line>
          ))}
        </Table.Content>
      </Table.Root>
    </Container>
  );
}
