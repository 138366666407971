import { useQueryParam } from 'use-query-params';

import { Tab as Container } from "./styles";
import { useTabs } from "./useTabs";

export function TabsTab({ children, name }) {
  const [_, setTab] = useQueryParam(name);

  const allTabsList = useTabs((st) => st.data.tabs);
  const tabsList = allTabsList[name];
  const tabData = tabsList.find((tab) => tab.title === children);

  return (
    <Container
      type="button"
      className={tabData.active ? "active" : ""}
      onClick={() => {
        setTab(tabData.slug)
      }}
    >
      {children}
    </Container>
  );
}
