import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import { AuthProvider } from './providers/auth'
import { MainCheckinProvider } from './providers/mainCheckin'
import { ThemesProvider } from './providers/theme'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <MainCheckinProvider>
      <ThemesProvider>
        <App />
      </ThemesProvider>
    </MainCheckinProvider>
  </AuthProvider>
);

reportWebVitals();
