import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { usePackge } from '../../../../../hooks/EditPackageContext'

import Service from './Service'

function Services() {
  const { getPackageServices, addEmpityPackageService } = usePackge()
  const services = getPackageServices()

  return (
    <>
      {services.map((service) => (
        <Service key={service.serviceInputId} service={service} />
      ))}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            color="secondary"
            startIcon={<AddCircle />}
            onClick={addEmpityPackageService}
            style={{ fontSize: '12px', fontWeight: 'bold' }}
          >
            ADICIONAR MAIS SERVIÇOS
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default Services
