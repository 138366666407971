import _ from "lodash";
import {
  formatMedicineQuantity,
  getMedicinePharmacyType,
  getMedicineUsage,
} from "../../../../../../services/functions";

import { usePrescription } from "../../../../../../store/service/veterinary/usePrescription";

import { DosageInformation } from "./DosageInformation";
import { NewMedicineWarning } from "./NewMedicineWarning";

import {
  Container,
  PrescriptionTitle,
  PrescriptionListItem,
  PrescriptionSubTitle,
  PrescriptionSubTitleSeparator,
  PrescriptionMedicine,
  PrescriptionDosage,
  PrescriptionNote,
} from "./styles";

export function PrescriptionContent() {
  const medicines = usePrescription((st) => st.data.prescription.medicines);
  const newMedicine = usePrescription((st) => st.data.prescription.newMedicine);

  const medicinesByUsage = _.groupBy([...medicines, newMedicine], "usage");

  return Object.keys(medicinesByUsage).map((usageKey) => (
    <Container key={usageKey}>
      {getMedicineUsage(usageKey)[0] && (
        <PrescriptionTitle>
          uso {getMedicineUsage(usageKey)[0]}
        </PrescriptionTitle>
      )}
      {medicinesByUsage[usageKey].map((medicine) => (
        <PrescriptionListItem
          new={medicine.new && medicine.presentation}
          key={`${usageKey}-${medicine.id}`}
        >
          {medicine.new && medicine.presentation && <NewMedicineWarning />}
          {medicine.presentation && (
            <PrescriptionSubTitle>
              {getMedicinePharmacyType(medicine.pharmacyType)}
              <PrescriptionSubTitleSeparator />
              <strong>{formatMedicineQuantity(medicine)}</strong>
            </PrescriptionSubTitle>
          )}
          <PrescriptionMedicine>{medicine.presentation}</PrescriptionMedicine>
          <PrescriptionDosage>
            <DosageInformation medicineProps={medicine} />
          </PrescriptionDosage>
          {medicine.note && medicine.fillStyle === "self" && (
            <PrescriptionNote>
              <strong>OBS: </strong>
              {medicine.note}
            </PrescriptionNote>
          )}
        </PrescriptionListItem>
      ))}
    </Container>
  ));
}

