import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  flex: 0 1 0%;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  & > * {
    min-width: fit-content;
    flex: 1;
  }
`
