import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const HistoryInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 8px 32px;
`

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  width: fit-content;
  padding: 20px 0;
  &:first-child{
    padding-top: 0;
  }
`

export const Title = styled.h3`
  width: 100%;
  display: flex;
  gap: 8px;
  margin: 0 0 4px 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  color: ${(props) => props.theme.colors.scale[600]};

`;

export const Line = styled.div`
  padding-left: 12px;
  p{
    margin: 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray[500]};
  }
`;

export const Highlight = styled.strong`
  font-weight: bold;
  color: ${(props) => props.theme.colors.scale[800]};
`

export const HtmlContent = styled.div`
  margin-top: 4px;
  width: 100%;
  padding: 0 0 0 8px;

  h1{ font-size: 24px; }
  h2{ font-size: 22px; }
  h3{ font-size: 20px; }

  h1,  h2,  h3{
    color: ${(props) => props.theme.colors.gray[800]};
    margin: 0;
  }

  ul{
    margin: 0;
    padding-left: 16px;
  }
  p{
    color: ${(props) => props.theme.colors.gray[500]};
    margin: 0;
    font-size: 14px;
    min-height: 20px;
  }
  strong{
    color: ${(props) => props.theme.colors.gray[900]};
  }


`

export const FluidGrid = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  ${InformationBlock}{
    flex: 1;
    min-width: fit-content;
    padding: 0;

  }
`
