import uuid from "react-uuid";
import { clearNumber, dateFormat, dateFormatedToDB, formatMoney, formatName, getScheduleCycleBillingErrorMessage, getSchedulingDepartmentStatusCode, getSubscriberStatusColor, getSubscriberStatusText, splitName, stringDateFormat } from "../functions";
import moment from "moment";
import slugify from "slugify";
import _ from "lodash";
import { BATH_TEXT } from "../../styles/constant";
import { DRE_EXPENSES, DRE_GOALS, DRE_RESPONSE_BLOCKS } from "../../store/subscription/constants";

function formatInvoice(invoice) {

  const split = {
    active: !!invoice?.split ? invoice.split.active : false,
    affiliateId: !!invoice?.split ? invoice.split.affiliateId : '',
    amount: !!invoice?.split ? invoice.split.amount : 0,
  }

  return {
    split,
    id: invoice.id || uuid(),
    billingAt: invoice.billingAt,
    paidAmount: invoice.paidAmount,
    paidNet: clearNumber(invoice.paidNet.toFixed(2)),
    fee: clearNumber(invoice.fee.toFixed(2)),
    description: invoice.description,
    customer: {
      name: invoice.customerName,
      cpf: invoice.cpf,
      pet: invoice.petName,
      planType: invoice.planType,
    },
    full: invoice,
  };
}

function formatAvailablePlan(availablePlan) {
  return {
    id: availablePlan._id,
    cluster: availablePlan.cluster,
    code: availablePlan.code,
    description: availablePlan.description,
    price: availablePlan.price,
  };
}

function formatCycle(cycle) {
  const customerName = splitName(`${cycle.customerFirstName} ${cycle.customerLastName}`)

  return {
    id: cycle._id,
    appointment: {
      date: dateFormat(cycle.appointmentDate),
    },
    customer: {
      name: customerName
    },
    pet: {
      id: cycle.petId,
      name: formatName(cycle.petName)
    },
    service: {
      status: getSchedulingDepartmentStatusCode(cycle.status),
      name: formatName(cycle.serviceName),
      price: {
        original: cycle.originalPrice,
        totalPrice: cycle.totalPrice,
      },
    },
    subscription: {
      planId: cycle.subscriptionPlanId,
    },
  };
}

function formatSubscriber(subscribe) {
  const saller = !!subscribe.seller ? subscribe.seller.name : '-'

  const lastCycle = _.maxBy(subscribe.cycle, (cycle) => moment(cycle.billingAt));
  const avaliableBath = !!lastCycle ? lastCycle.quantityAvailable || 0 : 0;
  const avaliableBathText = avaliableBath === 1 ? BATH_TEXT[0] : BATH_TEXT[1]

  const isCanceled = !!subscribe?.canceled_at || subscribe?.subscriptionStatus === "Cancelado"
  const isPaused = subscribe?.subscriptionStatus === "Pausado"

  const createdAt = stringDateFormat(subscribe.createdAt)
  const canceledAt = !!subscribe?.canceled_at ? stringDateFormat(subscribe.canceled_at) : '-'
  const nextBillingAt = !isCanceled ? stringDateFormat(subscribe.nextBillingAt) : '-'
  const validUntilDate = !!subscribe?.validUntilDate ? moment(stringDateFormat(subscribe.validUntilDate), 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY') : '-'

  const validAt = !!subscribe?.validUntilDate ? validUntilDate : moment(nextBillingAt, 'DD/MM/YYYY').subtract(1, 'days').format('DD/MM/YYYY')

  // stringDateFormat(subscribe.nextBillingAt)
  const customerName = splitName(subscribe.customerFullName)


  const cart = {
    firstDigits: !!subscribe.card && subscribe.card.firstSixDigits || '',
    lastDigits: !!subscribe.card && subscribe.card.lastFourDigits || '',
    brand: !!subscribe.card && subscribe.card.brand || '',
  }


  const cycles = subscribe.cycle.map((cycle) => ({
    id: cycle._id,
    billingAt: stringDateFormat(cycle.billingAt),
    message: cycle.status === 'paid' ? '-' : getScheduleCycleBillingErrorMessage(cycle.acquirerMessage),
    paidAmount: formatMoney(+cycle.paidAmount / 100),
    chargeId: cycle.chargeId,
    cycle: cycle.cycle,
    invoiceId: cycle.invoiceId,
    quantityAvailable: cycle.quantityAvailable,
    status: {
      color: getSubscriberStatusColor(cycle.status),
      text: getSubscriberStatusText(cycle.status)
    },
  }))

  return {
    id: subscribe._id || uuid(),
    status: subscribe.subscriptionStatus,
    cart,
    validAt,
    cycles,
    saller,
    createdAt,
    canceledAt,
    isCanceled,
    isPaused,
    nextBillingAt,
    validUntilDate,
    customer: {
      id: subscribe?.customerId || '',
      name: customerName,
      cpf: subscribe.cpf,
    },
    pet: {
      id: subscribe.petId,
      name: formatName(subscribe.petName),
      size: subscribe.validPetSizes,
    },
    plan: {
      id: subscribe?.planId || '',
      price: subscribe.price,
      type: subscribe.planType,
      price: subscribe.price,
      avaliableBath,
      avaliableBathText
    },

  };
}

function formatPagination(pagination) {
  return {
    page: pagination.currentPage,
    totalPages: pagination.totalPages,
    perPage: pagination.limit,
    totalDocuments: pagination.totalDocuments,
    full: pagination,
  };
}

function formatResultCalculation(result) {
  return {
    expenses: result.despesas,
    revenue: result.receita,
    result: result.resultado,
  };
}

function formatPricing(data) {
  const table = _.mapValues(_.groupBy(data.tabela, 'porte'), arr => _.sortBy(arr, 'plano'));

  return {
    recommendation: data.fatorRecomendacao,
    percentageCorrect: data.porcentagemCorretos,
    table
  };
}

function formatCustomExpenses(customExpenses) {
  const expenses = {}

  _.forEach(DRE_EXPENSES, (expense, key) => {
    const customExpense = customExpenses.find((data) => data.nomeDespesa === expense.id)
    expenses[key] = {
      value: customExpense.valorDespesa || '',
      default: customExpense.default || '',
    }
  })

  return expenses
}

function formatCustomGoals(customGoals) {
  const goals = {}

  _.forEach(DRE_GOALS, (goal, key) => {
    const customGoal = _.find(customGoals, (data) => data.nomeMeta === goal.id)
    goals[key] = {
      value: customGoal.valorMeta || '',
      default: customGoal.default || '',
    }
  })

  return goals
}

function formatDre(data) {
  let columns = []

  const blocks = {}
  const blocksColumns = {}

  const blocksDetails = {}
  const blocksDetailsColumns = {}

  const dres = _.concat([], data)

  const makeColumn = (key, dre) => {
    let columnValue = key

    _.forEach(blocks, (block) => {
      if (!!block.column) {
        const columnData = dre[block.column]
        columnValue = _.isNumber(columnData) ? columnData : _.sumBy(columnData, 'valor');
      }

      blocksColumns[block.id][key] = {
        id: `${block.id}-${key}`,
        value: block.format(columnValue)
      }
    })
  }

  const makeBlockDetailsColumn = (key, dre) => {
    _.forEach(blocks, (block) => {
      if (!!block.column && _.isArray(dre[block.column])) {
        blocksDetailsColumns[block.id][key] = {}

        _.forEach(dre[block.column], (detail) => {
          const id = slugify(detail.nome, { lower: true })

          blocksDetailsColumns[block.id][key][id] = {
            id: `${block.id}-${key}-${id}`,
            value: block.format(detail.valor)
          }
        })
      }
    })
  }

  const makeBlockDetails = (dre) => {
    _.forEach(blocks, (block) => {
      if (!!block.column && _.isArray(dre[block.column])) {
        blocksDetails[block.id] = []

        _.forEach(dre[block.column], (detail) => {
          const id = slugify(detail.nome, { lower: true })

          blocksDetails[block.id].push({
            id,
            title: detail.nome
          })
        })
      }
    })
  }

  _.forEach(DRE_RESPONSE_BLOCKS, (value, id) => {
    const block = { id, ...value }

    blocks[id] = block
    blocksColumns[id] = {}
    blocksDetailsColumns[id] = {}
  })

  _.forEach(dres, (dre) => {
    const key = dateFormatedToDB(stringDateFormat(dre.data))
    columns = _.union(columns, [key]);

    makeBlockDetails(dre)

    makeColumn(key, dre)
    makeBlockDetailsColumn(key, dre)
  })

  return {
    blocks: _.omitBy(blocks, _.isEmpty),
    columns: _.compact(columns),
    blocksColumns: _.omitBy(blocksColumns, _.isEmpty),
    blocksDetails: _.omitBy(blocksDetails, _.isEmpty),
    blocksDetailsColumns: _.omitBy(blocksDetailsColumns, _.isEmpty),
  }
}

export const format = {
  pagination: (pagination) => formatPagination(pagination),
  invoice: (invoice) => formatInvoice(invoice),
  invoices: (invoices) => {
    if (!invoices.length) return [];
    return invoices.map(formatInvoice);
  },
  subscriber: (subscriber) => formatSubscriber(subscriber),
  subscribers: (subscribers) => {
    if (!subscribers.length) return [];
    return subscribers.map(formatSubscriber);
  },
  cycle: (cycle) => formatCycle(cycle),
  cycles: (cycles) => {
    if (!cycles.length) return [];
    return cycles.map(formatCycle);
  },
  availablePlan: (availablePlan) => formatAvailablePlan(availablePlan),
  availablePlans: (availablePlans) => {
    if (!availablePlans.length) return [];
    return availablePlans.map(formatAvailablePlan);
  },
  resultCalculation: (result) => formatResultCalculation(result),
  pricing: (data) => formatPricing(data),
  dre: (dre) => formatDre(dre),
  customExpenses: (expenses) => formatCustomExpenses(expenses),
  customGoals: (goals) => formatCustomGoals(goals)
};
