import { AccordionContent } from "./AccordionContent";
import { AccordionHeader } from "./AccordionHeader";
import { AccordionItem } from "./AccordionItem";
import { AccordionRoot } from "./AccordionRoot";

export const Accordion = {
  Root: AccordionRoot,
  Item: AccordionItem,
  Header: AccordionHeader,
  Content: AccordionContent,
};
