import { formatName, splitName } from '../functions'

export const formatBody = {
  pet: (pet) => {
    if (!pet) return pet
    return {
      name: pet.name || null,
      birthdate: pet.birthdate || null,
      comments: pet.observation || null,
      attributes: {
        Castrado: pet.castrated || null,
        Espécie: pet.specie || null,
        Raça: pet.breed || null,
        Sexo: pet.gender || null,
        Peso: pet.weight || null,
        Porte: pet.size || null,
        Pelagem: pet.fur || null,
        Status: pet.status || null,
      },
    }
  },

  newCustomer: (data) => {
    if (!data) return false
    const customer =  data.customer

    return {
      "cellphone": customer.cellphone,
      "cpf": customer.cpf,
      "email": customer.email,
      "telephone" : customer.telephone,
      "firstname" : formatName(customer.firstname),
      "lastname": formatName(customer.lastname),
      "name":formatName(customer.name),
    }
  },
  lastPet: (petData) => {
    if (!petData) return petData
    const { firstname, lastname } = splitName(petData.customer.name)


    return {
      alreadyClient: true,
      packages: { services: [] },
      subscription: {},
      futureProtocolVaccinations: [],
      customer: {
        id: petData.customer.id,
        cpf: petData.customer.cpf,
        fullName: petData.customer.name,
        firstname,
        lastname,
        phoneNumber: petData.customer.cellphone,
        email: petData.customer.email,
      },
      pet: {
        // eslint-disable-next-line no-underscore-dangle
        id: petData.pet._id,
        name: petData.pet.name,
        comments: petData.pet.comments,
        attributes: petData.pet.attributes.map((attribute) => ({
          label: attribute.attributeLabel,
          value: attribute.value,
        })),
      },
    }
  },
}
