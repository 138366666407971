import React from 'react'

import InputMask from 'react-input-mask'
import { Controller } from 'react-hook-form'

import { TextField } from '@material-ui/core'

function TextMask({ control, item, register }) {
  return (
    <Controller
      control={control}
      defaultValue={item.value}
      name={item.key}
      as={
        <InputMask mask={item.mask} disabled={!!item.disabled}>
          {() => (
            <TextField
              fullWidth
              id={item.key}
              name={item.key}
              inputRef={register}
              label={item.label}
              variant="outlined"
              required={item.required}
              placeholder=""
              onInput={
                item.numberOnly &&
                ((e) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9.]/g, '')
                    .replace(/(\..*)\./g, '$1')
                })
              }
            />
          )}
        </InputMask>
      }
    />
  )
}

export default TextMask
