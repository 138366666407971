import { formatMoney, formatName, getEmployeeTypeName } from "../../../../../services/functions";

import { useSchedule } from "../../../../../store/schedule/useSchedule";

import { ScheduleController } from "../../../../../services/controllers/scheduling";

import { Alert } from "../../../../ui/Alert";

import {
  SummaryDetail,
  SummaryDetails,
  SummaryServicesTable,
  SummaryServicesTableHeadLine,
  SummaryServicesTableLine,
  SummaryTitle,
  SummaryServicesTotalAmount,
  WarningText
} from "./styles";

export function SendBudget() {
  const CLScheduling = ScheduleController();

  const schedule = useSchedule((st) => st.data);

  const employeeTypeName = getEmployeeTypeName()
  const totalAmount = _.sumBy(schedule.procedure.services, 'price');

  return (
    <Alert.Root name={CLScheduling.sendBudgetAlertName}>
      <Alert.Content>
        <Alert.Title>Enviar Orçamento #{schedule.appointmentId}</Alert.Title>
        <Alert.Description>
          <p>
            Por favor, confirme a finalização do agendamento número #{schedule.appointmentId}. Esta
            ação não pode ser desfeita. Certifique-se de que todas as
            informações estão corretas antes de prosseguir.
          </p>
        </Alert.Description>
        <div>
          <SummaryTitle>Detalhes do Agendamento:</SummaryTitle>
          <SummaryDetails>
            <SummaryDetail>
              <strong>Nome do Pet: </strong>
              {formatName(schedule.pet?.formattedAttributes.name || '')}
            </SummaryDetail>
            <SummaryDetail>
              <strong>Tutor: </strong>
              {formatName(schedule.pet?.formattedAttributes.owner.full || '')}
            </SummaryDetail>
            <SummaryDetail>
              <strong>Data do Agendamento: </strong>
              {schedule.procedure.date}
            </SummaryDetail>
            <SummaryDetail>
              <strong>Hora: </strong>
              {schedule.procedure.time}
            </SummaryDetail>
            <SummaryDetail>
              <strong>{formatName(employeeTypeName)}: </strong>
              {formatName(schedule.procedure.employee?.name)}
            </SummaryDetail>
          </SummaryDetails>

          <SummaryTitle>Serviços Agendados:</SummaryTitle>

          <SummaryServicesTable>
            <SummaryServicesTableHeadLine>
              <span>Nome do Serviço</span>
              <span>Valor Original</span>
              <span>Valor Cobrado</span>
            </SummaryServicesTableHeadLine>
            {schedule.procedure.services.map((service) => (
              <SummaryServicesTableLine key={service.id}>
                <span>{formatName(service.serviceName)}</span>
                <span>R$ {formatMoney(service.originalPrice)}</span>
                <span>R$ {formatMoney(service.price)}</span>
              </SummaryServicesTableLine>
            ))}
          </SummaryServicesTable>
          <SummaryServicesTotalAmount><strong>Valor Total: </strong> <span>R$ {formatMoney(totalAmount)}</span></SummaryServicesTotalAmount>

          <WarningText>
            Aviso Importante: Após a finalização, o orçamento será enviado para
            o Varejo Online (VO) e não poderá mais ser alterado.
          </WarningText>
        </div>

        <Alert.Actions>
          <Alert.Cancel>Cancelar</Alert.Cancel>
          <Alert.Confirm onClick={() => CLScheduling.sendBudget(false, true)}>Tudo certo, enviar orçamento</Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
}
