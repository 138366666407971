import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  pet: false,
};

export const usePet = create((set) => ({
  data: defaultData,
  actions: {
    setPet: (pet = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!pet) return;
          if (!state.data.pet) draft.data.pet = {}

          Object.keys(pet).forEach((petKey) => {
            if (!state.data.pet[petKey]) {
              draft.data.pet[petKey] = pet[petKey];
            }
          });

          draft.data.pet = { ...state.data.pet, ...pet };
        })
      ),
    clearPet: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pet = false
        })
      ),
    updatePet: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!values) return;
          if (!state.data.pet) draft.data.pet = {}

          Object.keys(values).forEach((petKey) => {
            draft.data.pet[petKey] = values[petKey];
          });
        })
      ),
    setPetServicesPackage: (department, services = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!services) return;

          draft.data.pet.packages[department] = services
        })
      ),
  },
}));
