import { Trash2 } from "lucide-react";

import { VeterinaryPrescriptionController } from "../../../../../../../../services/controllers/VeterinaryCare";
import { usePrescription } from "../../../../../../../../store/service/veterinary/usePrescription";

import { Action } from "../../../../../../../../components/ui/Action";

import { Container } from "./styles";

export function MedicineList() {
  const CLVetPrescription = VeterinaryPrescriptionController();
  const medicines = usePrescription((st) => st.data.prescription.medicines);

  if (!medicines.length) return null;

  return (
    <Container>
      {medicines.map((medicine) => (
        <Action.Root
          outline
          remove
          type="button"
          key={medicine.id}
          onClick={() => CLVetPrescription.deleteMedicine(medicine.id)}
        >
          {medicine.name}
          <Action.Icon icon={Trash2} />
        </Action.Root>
      ))}
    </Container>
  );
}
