import _ from "lodash";
import { X } from "lucide-react";

import { Action } from "../../Action";

import { useContext } from "../Context";
import { useListPreview } from "../useListPreview";

import { Container, Header, HeaderTitle } from "./styles";

export function ListPreviewInfo({ children, ...rest }) {
  const { listPreviewName, customAction } = useContext();

  const setPreview = useListPreview((st) => st.actions.setPreview);
  const toggleSelected = useListPreview((st) => st.actions.toggleSelected);

  const viewAction = !!customAction.close
    ? ({ item, name }) => customAction.close({ item, name }, toggleSelected)
    : toggleSelected;

  const selected = useListPreview(
    (st) => st.data.lists[listPreviewName].selected
  );

  setPreview(listPreviewName);

  return (
    <Container {...rest}>
      <Header>
        <HeaderTitle>{selected?.fullName || selected?.name}</HeaderTitle>
        <Action.Root
          outline
          style={{ maxWidth: "fit-content" }}
          onClick={() => viewAction({ item: null, name: listPreviewName })}
        >
          <Action.Icon icon={X} />
        </Action.Root>
      </Header>
      {children}
    </Container>
  );
}
