import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px 10px;
  & > button {
    max-width: fit-content;
  }

  & > div {
    flex: 0 1 0%;
  }
`;

export const FormContent = styled.div`
  min-width: 100%;
  flex: 0 1 0%;
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
`;
