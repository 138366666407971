import React, { useEffect } from 'react'
import InputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { editCustomer } from '../services/customers'

// Components
import ModalType from '../components/atoms/Modal'
import { clearNumber } from '../services/functions'

function EditCliente({ customerData }) {
  const { customerId, customer } = customerData

  const { register, handleSubmit, unregister, setValue, control, watch } = useForm({
    defaultValues: {
      street: customer?.personalData?.address?.street,
      uf: customer?.personalData?.address?.uf,
      city: customer?.personalData?.address?.city,
      neighborhood: customer?.personalData?.address?.neighborhood,
    },
  })
  const postalCode = watch('postalCode')

  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)

  const [stateButtom, setStateButtom] = React.useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const onSubmit = async (data) => {
    setStateButtom(true)
    const {
      cpf,
      firstname,
      lastname,
      email,
      cellphone,
      telephone,
      city,
      postalCode,
      street,
      complement,
      number,
      neighborhood,
      uf,
    } = data

    const payload = {
      cpf,
      firstname,
      lastname,
      email,
      cellphone,
      telephone,
      address: {
        city,
        postalCode,
        street,
        complement,
        number,
        neighborhood,
        uf,
      },
    }

    const valResult = await editCustomer(payload, customerId)
    if (valResult?.status === 201) {
      unregister()
      setError(false)
      customerData.callBack()
    } else {
      setOpenModal(valResult?.data?.friendly_message)
      setError(true)
      setStateButtom(false)
      customerData.callBack()
    }
  }


  useEffect(()=>{
    const postalCodeNumbers = clearNumber(postalCode)
    if(!!postalCodeNumbers && customer?.personalData?.address?.postalCode !== postalCodeNumbers){
      onBlurCep(postalCodeNumbers)
    }
  },[postalCode])

  function onBlurCep(cep) {
    if (cep?.length !== 8) return

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue('street', data.logradouro)
        setValue('neighborhood', data.bairro)
        setValue('city', data.localidade)
        setValue('uf', data.uf)
      })
  }

  return (
    <>
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        modalDelete={false}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3>Dados Pessoais</h3>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={customer?.personalData?.cpf}
                as={({ value }) => (
                  <TextField
                    id="cpf"
                    label="CPF"
                    value={value}
                    variant="outlined"
                    fullWidth
                    required
                    inputRef={register}
                    disabled
                  />
                )}
                name="cpf"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.firstname}
                name="firstname"
                label="Nome"
                variant="outlined"
                inputRef={register}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.lastname}
                name="lastname"
                label="Sobrenome"
                variant="outlined"
                inputRef={register}
                required
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue={customer?.personalData?.cellphone}
                as={
                  <InputMask mask="(99) 99999-9999">
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="cellphone"
                        label="Telefone principal"
                        variant="outlined"
                        required
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                }
                name="cellphone"
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue={customer?.personalData?.telephone}
                as={
                  <InputMask mask="(99) 99999-9999">
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="telephone"
                        label="Telefone de recado"
                        variant="outlined"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                }
                name="telephone"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.email}
                name="email"
                label="E-mail"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <h3>Endereço</h3>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={customer?.personalData?.address?.postalCode}
                as={
                  <InputMask mask="99999-999">
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="postalCode"
                        label="CEP"
                        variant="outlined"
                        required
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                }
                name="postalCode"
              />

              {/* <TextField
                fullWidth
                defaultValue={customer?.personalData?.address?.postalCode}
                name="postalCode"
                label="CEP"
                variant="outlined"
                onBlur={onBlurCep}
                inputRef={register}
              /> */}
            </Grid>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.address?.street}
                name="street"
                label="Rua"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.address?.number}
                name="number"
                label="Número"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.address?.complement}
                name="complement"
                label="Complemento"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.address?.neighborhood}
                name="neighborhood"
                label="Bairro"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.address?.city}
                name="city"
                label="Cidade"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                defaultValue={customer?.personalData?.address?.uf}
                name="uf"
                label="Estado"
                variant="outlined"
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6} style={{ display: 'flex' }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={stateButtom}
                style={{
                  background: '#CCD219',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  fontWeight: '700',
                  width: '138px',
                  marginRight: '20px',
                }}
              >
                Salvar
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  customerData.callBack()
                }}
                style={{
                  border: '1px solid #CCD219',
                  color: '#CCD219',
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  width: '120px',
                }}
              >
                fechar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default EditCliente
