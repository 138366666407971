import { memo } from "react";
import { SuggestionsItem as Container } from "../../styles";

export const SuggestionsItem = memo(({
  suggestion,

  onClick: onChangeTrigger = () => null,
}) => {

  return (
    <Container
      type="button"
      onClick={() => onChangeTrigger(suggestion)}
    >
      {suggestion.value}
    </Container>
  );
})
