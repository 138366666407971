import { memo } from "react";
import _ from "lodash";


import { formatName, getArticles } from "../../../../../services/functions";

import { useVaccine } from "../../../../../store/service/veterinary/useVaccine";
import { usePet } from "../../../../../store/global/usePet";

import { VeterinaryVaccineController } from "../../../../../services/controllers/VeterinaryCare";

import { Alert } from "../../../../../components/ui/Alert";


export const ApplyVaccineAfterToday = memo(() => {
  const CLVetVaccine = VeterinaryVaccineController();

  const pet = usePet((st) => st.data.pet);
  const protocolId = useVaccine((st) => st.data.showVaccinationProtocol);
  const vaccineAlertId = useVaccine((st) => st.data.showVaccineAlert);

  const assignedProtocols = useVaccine((st) => st.data.assignedProtocols);
  const vaccines = useVaccine((st) => st.data.assignedVaccines[protocolId]);

  if (!vaccines) return null;

  const articles = getArticles(pet?.petAttributes.gender);
  const protocol = _.find(assignedProtocols, { id: protocolId });
  const vaccine = _.find(vaccines, { id: vaccineAlertId });

  return (
    <Alert.Root name={CLVetVaccine._applyVaccineAfterTodayAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Antecipação de Vacinação</Alert.Title>
        <Alert.Description>
          <p>
            Tem certeza de que deseja
            <strong className="highlight"> Antecipar </strong>a aplicação da
            <strong> {formatName(protocol?.name)} </strong>
            {articles[1]}
            <strong> {formatName(pet.name)} </strong>
            programada para<strong> {vaccine?.scheduledDate}</strong>?
          </p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm
            onClick={() =>
              CLVetVaccine.saveChangesVaccineValues(vaccineAlertId, true)
            }
          >
            Sim, Antecipar
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
