import styled, { css } from "styled-components";

import { DayPicker } from "react-day-picker";
import ReactInputMask from "react-input-mask";
import { rgba } from "polished";

export const Space = styled.legend`
  visibility: hidden;
  padding: 0 5px;
  width: 100%;
  max-width: fit-content;
  height: 0px;
  font-size: 14px;
  line-height: 1;
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Legend = styled.span`
  position: absolute;
  padding: 0;
  top: -10px;
  left: 14px;
  font-size: 14px;
  transform: scale(1);
  transform-origin: left center;
  color: ${(props) => props.theme.colors.gray[500]};
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Icon = styled.span`
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${(props) => props.theme.colors.gray[500]};
`;

export const Input = css`
  outline-style: none;
  width: 100%;
  font-size: 16px;
  border: none;
  color: ${(props) => props.theme.colors.gray[600]};
  background: transparent;

  &:placeholder-shown {
    & ~ ${Space} {
      padding: 0;
      max-width: 0;
    }
    & ~ ${Legend} {
      left: 15px;
      top: calc(100% - 29px);
      transform: scale(1.12);
      color: ${(props) => props.theme.colors.gray[400]};
    }
  }
  &:focus {
    & ~ ${Space} {
      padding: 0 5px;
      width: 100%;
      max-width: fit-content;
    }
    & ~ ${Legend} {
      top: -10px;
      left: 14px;
      transform: scale(1);
      color: ${(props) => props.theme.colors.scale[500]};
    }
  }
`;

export const InputText = styled.input`
  ${Input}
`;
export const InputDate = styled.input`
  ${Input}
`;

export const InputMask = styled(ReactInputMask)`
  ${Input}
`;

export const CalendarBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  max-height: 0;
  align-items: flex-start;
  transition: all ${(props) => props.theme.animation.delay.slow}
    cubic-bezier(0.4, 0, 0.2, 1);
`;

export const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  gap: 4px;
  overflow: hidden;
  margin-top: 0px;
  max-height: 0px;
  width: 100%;
  padding: 0 8px;
  position: absolute;
  left: -1px;
  top: calc(100% - 3px);
  width: calc(100% + 2px);
  border: 1px solid;
  border-top: none;
  border-radius: 0 0 5px 5px;
  border-color: transparent;
  background: transparent;
  overflow-x: auto;
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
`;

export const SuggestionsItem = styled.button`
  min-width: 100%;
  border: none;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.scale[500]};
  transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;
  &:hover {
    padding-left: 14px;
    background: ${(props) => props.theme.colors.scale[300]};
    color: ${(props) => props.theme.colors.background};
  }
`;

export const InputBox = styled.fieldset`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 8px;
  margin: 0;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.scale[500]};
  background: ${(props) => props.theme.colors.background};
  width: 100%;
  z-index: 2;

  &[data-error="true"] {
    border-color: ${(props) => props.theme.colors.error};
  }
  ${(props) =>
    props.date &&
    css`
      z-index: 3;
    `}

  &:focus-within {
    ${(props) =>
      props.date &&
      css`
        box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px
          5px;
      `}
    ${CalendarBox} {
      height: 100%;
      max-height: fit-content;
    }
    ${InputDate} {
      opacity: 0;
    }
    ${(props) =>
      props.suggestions &&
      css`
        box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px
          5px;

        ${SuggestionsContainer} {
          max-height: 100vh;
          padding: 8px;
          background: ${(props) => props.theme.colors.background};
          border-color: ${(props) => props.theme.colors.scale[500]};
          box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px
            5px;
        }
      `}
  }
`;

export const Calendar = styled(DayPicker)`
  margin: 0;
  .rdp-caption_label {
    text-transform: capitalize;
    color: ${(props) => props.theme.colors.scale[500]};
  }

  .rdp-day_today:not(.rdp-day_outside):not(.rdp-day_selected) {
    color: ${(props) => props.theme.colors.scale[500]};
  }
  .rdp-day_selected {
    background-color: ${(props) => props.theme.colors.scale[500]};
    &:hover {
      background-color: ${(props) => props.theme.colors.scale[600]};
    }
  }
  .rdp-head_cell {
    color: ${(props) => props.theme.colors.gray[700]};
  }
  .rdp-button {
    color: ${(props) => props.theme.colors.gray[600]};
    &.rdp-day_selected {
      color: ${(props) => props.theme.colors.background};
    }
  }
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${(props) => props.theme.colors.scale[300]};
    color: ${(props) => props.theme.colors.background};
  }
  .rdp-button:focus-visible:not([disabled]) {
    background-color: transparent;
    border: transparent;
  }
`;

export const Container = styled.label`
  display: block;
  position: relative;
  width: 100%;
  height: 40px;

  &[aria-disabled="true"] {
    ${InputBox} {
      border-color: ${(props) => props.theme.colors.gray[200]};
      pointer-events: none;
      cursor: default;
      background: ${(props) => rgba(props.theme.colors.gray[50], 0.2)};
      background: ${(props) =>
        `linear-gradient(180deg, ${rgba(
          props.theme.colors.gray[50],
          0
        )} 0%, ${rgba(props.theme.colors.gray[100], 0.8)} 100%)`};
    }
  }
  ${(props) =>
    props.date &&
    css`
      max-width: 300px;
    `}
`;
