import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  padding-right: 12px;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    height: 90%;
    width: 1px;
    transform: translateY(-50%);
    background: linear-gradient(
      transparent,
      ${(props) => props.theme.colors.scale[400]},
      transparent
    );
    transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  & > * {
    min-width: fit-content;
    flex: 1;
  }
`;

export const FormActions = styled.div`
  margin-top: 20px;
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  & > * {
    width: fit-content;
  }
`;
