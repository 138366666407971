import styled from 'styled-components';

import { Table } from '../../../../../components/ui/Table';

export const Quantity = styled(Table.Item)`
  span{
    display: flex;
      justify-content: center;
  }
`;
