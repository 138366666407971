import { rgba } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0;
  height: 60px;
  padding: 0 5px;
`;
export const Tab = styled.button`
  flex: 1;
  padding: 10px 20px 40px 20px;
  position: relative;
  border-radius: 5px 5px 0 0;
  min-width: max-content;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  transform: translateY(20px);
  color: ${(props) => props.theme.colors.scale[600]};
  background: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  border-bottom: none;
  transform: translateY(35px);
  transition: all cubic-bezier(0.63, 1.47, 0.79, 1.31)
    ${(props) => props.theme.animation.delay.fast};
  outline: none;
  background: ${(props) => rgba(props.theme.colors.scale[300], 0.1)};
  &:not(.active):hover {
    background: ${(props) => rgba(props.theme.colors.scale[300], 0.3)};
    transform: translateY(15px);
  }
  &:not(.active):active {
    transform: translateY(1px);
    padding-bottom: 0;
    background: ${(props) => props.theme.colors.background};
  }
  &.active {
    transition-delay: 50ms;
    background: ${(props) => props.theme.colors.background};
    padding-bottom: 15px;
    transform: translateY(1px);
    z-index: 2;
  }
`;

export const ContentBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  z-index: 3;
  background: ${(props) => props.theme.colors.background};
  border-top: 1px solid ${(props) => props.theme.colors.scale[500]};
`;

export const Content = styled.div`
  width: 100%;
  max-height: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 100px;
  padding: 30px 0;
  overflow: hidden;
  gap: 20px;
  background: ${(props) => props.theme.colors.background};
  z-index: 1;
  opacity: 0;
  transition: all linear ${(props) => props.theme.animation.delay.fast};
  &.active {
    z-index: 2;
    opacity: 1;
    top: 0;
    overflow: initial;
    max-height: fit-content;
  }
`;
