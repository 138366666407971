import {
  TimeLineDescription as Container,
  TimeLineDescriptionText,
  TimeLineDescriptionTitle,
} from "./styles";

export function TimeLineDescription({ children, title }) {
  return (
    <Container>
      {title && <TimeLineDescriptionTitle>{title}</TimeLineDescriptionTitle>}
      {children && (
        <TimeLineDescriptionText>{children}</TimeLineDescriptionText>
      )}
    </Container>
  );
}
