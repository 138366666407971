import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

// Modal

// Material ui - style
import { makeStyles, withStyles } from '@material-ui/core/styles'

// Serviços

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'

// Material ui
import {
  Grid,
  Button,
  Box,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TextField,
  MenuItem,
  Menu,
  IconButton,
} from '@material-ui/core'

// Material ui - icones
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { getCupom, activeCupom } from '../../../services/cupom'
import ModalType from '../../atoms/Modal'
import BasePage from '../../../pages/basePage'

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 700,
    border: '1px solid #ccd219',
  },
  tableContainer: {
    marginTop: '20px',
  },
  pagination: {
    marginTop: '20px',
  },
  filter: {
    margin: '30px 0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
    border: '1px solid #ccd219',
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #ccd219',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
    '& h6': {
      marginBottom: '-8px',
      color: '#000',
    },
    '& p': {
      margin: '0px',
      fontSize: '12px',
    },
  },
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ccd219',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
    lineHeight: '42px',
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const ITEM_HEIGHT = 48

function Cupom() {
  const history = useHistory()
  const classes = useStyles()
  const { control } = useForm()
  const [cupom, setCupom] = React.useState(false)

  const [open, setOpen] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)

  const closeModal = () => {
    setOpenModal(false)
  }

  const postActive = async (id, active) => {
    await activeCupom(id, active, setError, setOpenModal, setCupom)
  }

  const onDecrement = (key) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(key)
  }

  const handleClose = (id, active) => {
    setAnchorEl(null)
    setOpen(false)
    postActive(id, active)
  }

  const handleActiveCupom = async (active) => {
    const value = await getCupom(active)
    setCupom(value?.results)
  }

  React.useEffect(() => {
    const init = async () => {
      const value = await getCupom()
      setCupom(value?.results)
    }

    init()
  }, [])

  return (
    <BasePage pageTitle="Cupom">
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />

      <Grid container spacing={3} className={classes.filter}>
        <Grid item xs={2}>
          <Controller
            control={control}
            defaultValue="ATIVO"
            name="status"
            as={({ onChange, value }) => (
              <TextField
                fullWidth
                select
                value={value}
                label="STATUS"
                variant="outlined"
                onChange={(e) => {
                  onChange(e.target.value)
                }}
              >
                <MenuItem
                  key="ATIVO"
                  value="ATIVO"
                  onClick={() => handleActiveCupom(true)}
                >
                  ATIVO
                </MenuItem>
                <MenuItem
                  key="INATIVO"
                  value="INATIVO"
                  onClick={() => handleActiveCupom(false)}
                >
                  INATIVO
                </MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: '100%' }}
          >
            <Box justifyContent="flex-end">
              {/* <Button
                                variant="contained"
                                onClick={() => handleClick()}
                                color="primary"
                                style={{
                                    background: "#CCD219",
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    fontWeight: "700",
                                    width: "138px",
                                    marginRight: "20px"
                                }}
                            >
                                Filtrar
                            </Button> */}
              <a href="/cupom/new" style={{ textDecoration: 'none' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    border: '1px solid #CCD219',
                    color: '#CCD219',
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    width: '120px',
                  }}
                >
                  Novo Cupom
                </Button>
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.tableContainer}
      >
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
          tabIndex={-1}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ width: 250 }}>Nome</StyledTableCell>
              <StyledTableCell align="center">Código</StyledTableCell>
              <StyledTableCell align="center">Validade</StyledTableCell>
              <StyledTableCell align="center">Departamento</StyledTableCell>
              <StyledTableCell align="center">Desconto</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center" />
            </StyledTableRow>
          </TableHead>

          {cupom ? (
            cupom.map((item) => (
              <TableBody key={item.couponId}>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {item?.promotionName}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item?.code}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.validToDate}
                  </StyledTableCell>
                  <StyledTableCell align="center" />
                  <StyledTableCell align="center">
                    {item?.discount}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.active ? 'ATIVO' : 'INATIVO'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <>
                      <IconButton
                        aria-label="more"
                        aria-controls={item.couponId}
                        aria-haspopup="true"
                        onClick={onDecrement(item.couponId)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={item.couponId}
                        anchorEl={anchorEl}
                        open={item.couponId === open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                          },
                        }}
                      >
                        <MenuItem
                          key="ativo"
                          onClick={() => handleClose(item.couponId, true)}
                        >
                          Ativo
                        </MenuItem>
                        <MenuItem
                          key="inativo"
                          onClick={() => handleClose(item.couponId, false)}
                        >
                          Inativo
                        </MenuItem>
                        <MenuItem
                          key="editar"
                          onClick={() =>
                            history.push(`/cupom/editar/${item?.couponId}`, {
                              idCupom: item.couponId,
                            })
                          }
                        >
                          Editar
                        </MenuItem>
                      </Menu>
                    </>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </BasePage>
  )
}

export default Cupom
