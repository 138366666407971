import _ from "lodash";
import { create } from "zustand";
import { produce } from "immer";

import { getVaccinationProtocolStatusBadge } from "../../../services/functions";

const defaultProtocol = {
  updated: true,
  loading: false,
  name: "",
  species: null,
  frequency: "",
  frequencyPeriod: null,
  duration: "",
  durationPeriod: null,
};

const defaultData = {
  updated: true,
  loading: false,
  loadingApply: false,

  showNewProtocol: false,

  vaccineValues: false,
  newProtocolValues: defaultProtocol,
  assignedOldVaccineValues: {},

  vaccinesList: false,
  vaccineLabList: false,
  vaccineProtocolList: {},

  showVaccineAlert: false,
  showVaccinationProtocol: false,

  assignedProtocols: false,
  assignedVaccines: false,
};

export const useVaccine = create((set) => ({
  data: defaultData,
  actions: {
    isUpdated: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.updated = status;
        })
      ),
    isLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.loading = status;
        })
      ),

    clearVaccine: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.loadingApply = status;
        })
      ),
    isApplyLoading: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        })
      ),

    setProtocolUpdated: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.newProtocolValues.updated = status;
        })
      ),
    setProtocolLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.newProtocolValues.loading = status;
        })
      ),
    setShowNewProtocol: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.newProtocol = status;
        })
      ),
    setShowVaccineAlert: (vaccineId = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.showVaccineAlert = vaccineId;
        })
      ),
    setVaccinesList: (list = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.vaccinesList = list;
        })
      ),
    setVaccineLabList: (list = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.vaccineLabList = list;
        })
      ),
    setVaccineProtocolList: (vaccine, list = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.vaccineProtocolList[vaccine] = list;
        })
      ),
    setVaccineValues: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          const currentValues = state.data.vaccineValues;

          if (!values) {
            draft.data.newProtocolValues = false;
            draft.data.newProtocolValues = defaultProtocol;
            return;
          }

          if (values.protocolId === "+") {
            draft.data.newProtocol = true;
          }
          if (values.protocolId !== "+") {
            draft.data.newProtocol = false;
            draft.data.newProtocolValues = defaultProtocol;
          }

          draft.data.vaccineValues = { ...currentValues, ...values };
        })
      ),

    setProtocolValues: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          const currentValues = state.data.newProtocolValues;

          if (!values) {
            draft.data.newProtocolValues = defaultProtocol;
            return;
          }

          draft.data.newProtocolValues = { ...currentValues, ...values };
        })
      ),

    setShowVaccinationProtocol: (protocol) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.showVaccinationProtocol = protocol;
        })
      ),

    setAssignedProtocols: (assignedProtocols) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.assignedProtocols = assignedProtocols;
        })
      ),

    setAssignedProtocolStatus: (protocolId, status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.assignedProtocols = state.data.assignedProtocols.map(
            (protocol) => {
              if (protocol.id !== protocolId) return protocol;
              if (protocol.status === status) return protocol;
              return {
                ...protocol,
                status,
                informationList: {
                  ...protocol.informationList,
                  Status: getVaccinationProtocolStatusBadge(status),
                },
              };
            }
          );
        })
      ),

    removeAssignedProtocol: (protocolId) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.assignedProtocols = state.data.assignedProtocols.filter(
            (protocol) => protocol.id !== protocolId
          );
        })
      ),

    setAssignedVaccines: (assignedVaccines) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.assignedVaccines = assignedVaccines;
        })
      ),

    setVaccineStatus: (vaccineId, status, back = false) =>
      set((state) =>
        produce(state, (draft) => {
          const selectedProtocol = state.data.showVaccinationProtocol;

          draft.data.assignedVaccines[selectedProtocol] =
            state.data.assignedVaccines[selectedProtocol].map((vaccine) => {
              if (vaccine.id !== vaccineId) return vaccine;
              return {
                ...vaccine,
                status,
                back,
              };
            });
        })
      ),
    updateAssignedOldVaccineValues: (vaccineId) =>
      set((state) =>
        produce(state, (draft) => {
          const selectedProtocol = state.data.showVaccinationProtocol;
          const vaccines = state.data.assignedVaccines[selectedProtocol];
          const vaccine = _.find(vaccines, { id: vaccineId });

          draft.data.assignedOldVaccineValues[vaccineId] = vaccine;
        })
      ),

    isUpdatedVaccine: (vaccineId, updated) =>
      set((state) =>
        produce(state, (draft) => {
          const selectedProtocol = state.data.showVaccinationProtocol;
          const vaccines = state.data.assignedVaccines[selectedProtocol];

          draft.data.assignedVaccines[selectedProtocol] = vaccines.map(
            (vaccine) => {
              if (vaccine.id !== vaccineId) return vaccine;
              return { ...vaccine, updated };
            }
          );
        })
      ),

    setAssignedVaccineValues: (vaccineId, values = false) =>
      set((state) =>
        produce(state, (draft) => {
          const selectedProtocol = state.data.showVaccinationProtocol;
          const vaccinesValues = state.data.assignedVaccines[selectedProtocol];
          const oldValues = state.data.assignedOldVaccineValues[vaccineId];

          if (!oldValues) {
            draft.data.assignedOldVaccineValues[vaccineId] = _.find(
              vaccinesValues,
              {
                id: vaccineId,
              }
            );
          }

          draft.data.assignedVaccines[selectedProtocol] = vaccinesValues.map(
            (vaccine) => {
              if (vaccine.id !== vaccineId) return vaccine;

              if (!values) {
                delete draft.data.assignedOldVaccineValues[vaccineId];

                return { ...vaccine, ...oldValues };
              }

              return { ...vaccine, ...values };
            }
          );
        })
      ),
  },
}));
