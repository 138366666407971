import API from './api'

export const getServices = async (id, department = false) => {
  const petQuert = `pet=${id}`
  const departmentQuert = department ? `&department=${department}` : ''

  const {
    data: { results = [] },
  } = await API.get(`services?${petQuert}${departmentQuert}`)

  return results
}

export const encodeUrlParans = (content) => encodeURIComponent(btoa(JSON.stringify(content)))
export const decodeUrlParans = (content) => JSON.parse(atob(decodeURIComponent(content)))

export const getServicesByName = async (id) => {
  const {
    data: { results = [] },
  } = await API.get(`services/search?text=${id}`)

  return results
}
