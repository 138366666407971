import React, { useEffect, useState, useContext } from 'react'
import { TextField } from '@material-ui/core'
import { differenceInDays } from 'date-fns'
import { BlockEmployeeContext } from '../../../../../providers/blockEmployee'
import { Container, ErrorMessage, Label } from './styles'

function DateInput({ label, startDate, endDate, onChange }) {
  const { valid, setValid } = useContext(BlockEmployeeContext)
  const [start, setStart] = useState(startDate || '')
  const [end, setEnd] = useState(endDate || '')

  useEffect(() => {
    if (start && end) {
      const diff = differenceInDays(new Date(end), new Date(start))
      setValid(diff <= 60 && diff >= 2)
    }
  }, [start, end])

  const handleStartChange = (e) => {
    setStart(e.target.value)
    onChange({ start: e.target.value, end })
  }

  const handleEndChange = (e) => {
    setEnd(e.target.value)
    onChange({ start, end: e.target.value })
  }

  return (
    <Container>
      <Label>{label}</Label>
      <div>
        <TextField
          id="date"
          label="Inicio"
          type="date"
          style={{ width: '48%', marginRight: '4%' }}
          value={start}
          variant="outlined"
          size="small"
          onChange={handleStartChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="date"
          label="Fim"
          type="date"
          style={{ width: '48%' }}
          value={end}
          variant="outlined"
          size="small"
          onChange={handleEndChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      {!valid && <ErrorMessage>Período inválido!</ErrorMessage>}
    </Container>
  )
}

export default DateInput
