import { FaWhatsapp } from "react-icons/fa";
import { Download, FilePlus2, Trash2 } from "lucide-react";

import { getArticles, getPreviewListPlaceholder, isAbleByStatus } from "../../../../services/functions";

import { useCare } from "../../../../store/service/veterinary/useCare";
import { usePet } from "../../../../store/global/usePet";
import { useRequest } from "../../../../store/service/veterinary/useRequest";

import { VeterinaryCareController, VeterinaryRequestController } from "../../../../services/controllers/VeterinaryCare";

import { Tabs } from "../../../../components/ui/Tabs";
import { Action } from "../../../../components/ui/Action";
import { ListPreview } from "../../../../components/ui/ListPreview";

import { IncompleteRequest } from "./Alerts/IncompleteRequest";
import { NewRequestDrawer } from "./NewRequestDrawer";

export function VeterinaryRequests() {
  const CLVetCare = VeterinaryCareController();
  const CLVetRequest = VeterinaryRequestController();

  const careStatus = useCare(st => st.data.care.status)
  const isAble = isAbleByStatus(careStatus,CLVetRequest._name)

  const pet = usePet(st => st.data.pet);
  const requests = useRequest((st) => st.data.requests);
  const selectRequest = useRequest((st) => st.data.selectRequest);

  const veterinaryCareTabs = Tabs.store(st => st.data.tabs[CLVetCare._tabsName])

  const requestsList = _.values(requests);
  const isActiveTab = !!veterinaryCareTabs.find(tab => tab.title === CLVetRequest._label && tab.active);

  const articles = getArticles(pet.petAttributes?.gender);

  const placeholderValues = {
    article1: articles[0],
    article2: articles[1],
    petName: pet.name
  }

  if (isActiveTab) CLVetRequest.getThumbs();

  CLVetRequest.init()

  return (
    <>
      <ListPreview.Root
        name={CLVetRequest._previewListName}
        list={requestsList}
        placeholder={getPreviewListPlaceholder(CLVetRequest._previewListName, placeholderValues)}
        onSelected={(request) => CLVetRequest.selectRequest(request)}
        options={{
          listStyle: "list",
          selected: null,
        }}
      >
        <ListPreview.Header>
          <Action.Root onClick={CLVetRequest.openDrawer} disabled={!isAble}>
            <Action.Icon icon={FilePlus2} />
            Nova
          </Action.Root>
        </ListPreview.Header>
        <ListPreview.Info>
          <ListPreview.InfoActions style={{ justifyContent: "flex-end" }}>
            <Action.Root
              type="button"
              name="download-request"
              title="Baixar Requisição"
              download={{
                path: selectRequest?.path,
                name: selectRequest?.slug,
              }}
            >
              <Action.Icon icon={Download} size={22} />
            </Action.Root>
            <Action.Root
              type="button"
              status="success"
              name="send-request"
              title="Enviar por WhatsApp"
              onClick={() => CLVetRequest.sendMessage()}
            >
              <Action.Icon icon={FaWhatsapp} size={22} />
            </Action.Root>
            <Action.Root
              type="button"
              status="danger"
              name="delete-request"
              title="Excluir Requisição"
              disabled={!isAble}
              onClick={() => CLVetRequest.deleteRequest()}
            >
              <Action.Icon icon={Trash2} size={22} />
            </Action.Root>
          </ListPreview.InfoActions>
          <ListPreview.InfoContent />
          <ListPreview.InfoData />
        </ListPreview.Info>
      </ListPreview.Root>
      <NewRequestDrawer />
      <IncompleteRequest />
    </>
  );
}
