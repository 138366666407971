import { dateFormat } from '../../../../../../services/functions'
import { usePrinter } from '../../../../../../store/schedule/usePrinter'
import { Card } from '../components/Card'

export function SchedulingHeader({ noDate = false }) {
  const schedule = usePrinter(st => st.data.schedule)

  return (
    <Card.Header>
      <Card.HeaderTitle className="uppercase">
        Agendamento
        <span className="ml-auto font-bold">{schedule.orderId}</span>
      </Card.HeaderTitle>
      {!noDate && <Card.HeaderDescription>{dateFormat(schedule.date || '')} às {schedule.startAt}</Card.HeaderDescription>}
    </Card.Header>
  )
}
