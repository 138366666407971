import { useThemes } from "../../../../providers/theme";

import { useListPreview } from "../useListPreview";
import { useContext } from "../Context";

import { Image } from "./styles";
import { getUniqType } from "../../../../services/functions";

export function ListPreviewContent({ fit = 'contain' }) {
  const { theme } = useThemes();
  const { listPreviewName } = useContext();

  const selected = useListPreview((st) => st.data.lists[listPreviewName].selected);

  if (selected?.thumb) {
    return <Image src={selected?.thumb} alt={selected?.name} fit={fit} />
  }

  switch (selected?.type) {
    case 'xls':
      return <Image src={`/placeholder/${theme}/preview-xls.png`} alt={selected?.name} fit={fit} />
    case 'doc':
      return <Image src={`/placeholder/${theme}/preview-doc.png`} alt={selected?.name} fit={fit} />
    case 'img':
      return <Image src={selected?.path} alt={selected?.name} fit={fit} />;
    case 'pdf':
      return <Image src={`/placeholder/${theme}/preview-pdf.png`} alt={selected?.name} fit={fit} />
    case 'html':
      return <ClipboardEdit {...rest} fit={fit} />;
    case 'unidentified':
      return <Image src={`/placeholder/${theme}/preview-attachment.png`} alt={selected?.name} fit={fit} />
    default:
      return <Image src={`/placeholder/${theme}/preview-attachment.png`} alt={selected?.name} fit={fit} />
  }

}
