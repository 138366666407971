import Mustache from "mustache";
import { Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import { useRequest } from '../../../../../store/service/veterinary/useRequest';

import { VeterinaryRequestController } from '../../../../../services/controllers/VeterinaryCare';

import { templatesFunctions } from '../../../../../services/templates/functions';

import { HTMLContentStyle, container, content, title } from './styles';


export function Request() {
  const CLVetRequest = VeterinaryRequestController();

  const requestData = useRequest.getState().data.request.requestData;
  const values = useRequest.getState().data.request.requestValues;

  const fields = {
    ...templatesFunctions.get.partialsFieldsFormat(requestData),
    ...templatesFunctions.get.fieldsFormat(requestData.fields),
  };

  const addBreakLines = templatesFunctions.addBreakLines(values);

  const formattedValues = {
    ...CLVetRequest._defaultRequest,
    ...templatesFunctions.format(fields, addBreakLines),
  };

  const contentData = `
    ${HTMLContentStyle}
    ${Mustache.render(requestData.content || '', formattedValues, CLVetRequest._partialsContent)}
  `;

  return (
    <View style={container}>
      <Text style={title}>{requestData.title}</Text>
      <View style={content}>
        <Html debug>
          {contentData}
        </Html>
      </View>
    </View>
  )
}
