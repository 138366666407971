import { Cancel as RadixCancel } from '@radix-ui/react-alert-dialog'

import { Action } from '../Action'

export function AlertCancel({ children, ...rest }) {
  return (
    <RadixCancel asChild>
      <Action.Root outline status="danger" {...rest}>
        {children}
      </Action.Root>
    </RadixCancel>
  )
}
