import { ItemContentType } from "./ItemContentType";
import { ContentLabel } from "./styles";

export function ItemContentStyle(props) {
  if (!props.label) return <ItemContentType  {...props} />

  return (
    <ContentLabel>
      <strong>{props.label}:</strong> <ItemContentType  {...props} />
    </ContentLabel>
  )
}
