import React, { useContext } from 'react'
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { DashboardContext } from '../../../providers/dashboard'
import BasePage from '../../../pages/basePage'
import ModuleMetrics from '../../molecules/ModuleMetrics'

import { Container } from './styles'
import ProfileDashboard from '../../molecules/ProfileDashboard'
import ChartDashboard from '../../molecules/ChartDashboard'

function Dashboard() {
  const { panel, ranking, setSelectOrder, selectOrder } =
    useContext(DashboardContext)

  return (
    <BasePage pageTitle="Resumo">
      <Container container spacing={3}>
        <Grid xs={8} container spacing={3} item>
          {panel.map((item) => (
            <ModuleMetrics key={item?.id} itens={Object.values(item)} />
          ))}
          <ChartDashboard />
        </Grid>

        <Grid xs={4} item>
          <Paper>
            <div style={{ display: 'flex', width: '100%' }}>
              <h2 style={{ width: '75%', paddingLeft: '14px' }}>
                Ranking Profissionais
              </h2>
              <select
                name="Quantidade"
                id="Quantidade"
                style={{ height: '35px', marginTop: '15px' }}
                onChange={(event) => setSelectOrder(event.target.value)}
              >
                <option value="quantity">Quantidade</option>
                <option value="revenue">Faturamento</option>
              </select>
            </div>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 220 }} align="left">
                    Profissional
                  </TableCell>
                  <TableCell align="left">Último Mês</TableCell>
                  <TableCell align="left">Mês Atual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ranking?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="left">
                      <ProfileDashboard item={item} />
                    </TableCell>
                    <TableCell align="left">
                      {selectOrder === 'quantity'
                        ? item?.lastQuantity
                        : item?.lastRevenue?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                    </TableCell>
                    <TableCell align="left">
                      {selectOrder === 'quantity'
                        ? item?.currentQuantity
                        : item?.currentRevenue?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!ranking?.length && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  padding: '15px',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>
      </Container>
    </BasePage>
  )
}

export default Dashboard
