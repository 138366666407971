import { ScheduleController } from "../../../../services/controllers/scheduling";
import { Alert } from "../../../ui/Alert";

export function IncompleteSchedule() {
  const CLScheduling = ScheduleController();

  return (
    <Alert.Root name={CLScheduling.incompleteAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Agendamento não Finalizado</Alert.Title>
        <Alert.Description>
          <p>
            Lembre-se de que o agendamento ainda não foi finalizado. Se você
            sair agora, todos os dados inseridos serão perdidos. Por favor,
            certifique-se de concluir o agendamento para garantir que as
            informações sejam salvas corretamente.
          </p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm
            onClick={() => {
              CLScheduling.drawerClose(true);
            }}
          >
            Sim, sair
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
}
