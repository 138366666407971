import qs from "qs";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import API from "../../api";
import { Report } from "../report";

import { format } from "../../formatResponses/subscriptions";
import { useInvoice } from "../../../store/subscription/useInvoice";

export class Invoice {
  setSummary = useInvoice.getState().actions.setSummary;
  setInvoice = useInvoice.getState().actions.setInvoice;

  clearInvoice = useInvoice.getState().actions.clearInvoice;

  setLoading = useInvoice.getState().actions.setLoading;
  setWaiting = useInvoice.getState().actions.setWaiting;
  setFilterData = useInvoice.getState().actions.setFilter;
  setPaginationPage = useInvoice.getState().actions.setPaginationPage;

  constructor() {
    this.name = "subscriptions-invoices";

    this.dashboardType = "invoices";
    this.reportName = `${this.name}-report`;

    this.MReport = new Report(this.reportName);

    this.startDate = moment().startOf("month").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");

    this.setFilterData({
      dateFrom: this.startDate,
      dateTo: this.endDate,
      summaryType: this.dashboardType,
    });

    this.setReportFilter({
      dateFrom: this.startDate,
      dateTo: this.endDate,
    });
  }

  setFilter(filterProps) {
    this.setFilterData(filterProps);
    this.setReportFilter(filterProps);
  }

  setReportFilter(filterProps) {
    this.MReport.setFilter({
      cpf: filterProps.cpf,
      dateFrom: filterProps.dateFrom,
      dateTo: filterProps.dateTo,
      description: filterProps.type,
    });
  }

  export() {
    this.MReport.download();
  }
  async setPage(page) {
    const pageExists = useInvoice.getState().data.invoice[page];
    this.setPaginationPage(page);

    if (!pageExists) await this.findInvoices();
  }

  async findSummary() {
    try {
      const query = this.queryParams();
      const { data } = await API.get(`/subscriptions/store/dashboard?${query}`);

      this.setSummary({
        totalInputs: data.dashboard.entradas,
        totalFees: data.dashboard.fee,
        totalChargeBacks: Math.abs(data.dashboard.estornos),
        netValue: data.dashboard.liquido,
      });
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async findInvoices() {
    await this.getInvoices();
  }

  async filterInvoices() {
    this.setLoading(true);
    this.setPaginationPage(0);
    this.clearInvoice();
    await this.getInvoices();
    await this.findSummary();
    this.setLoading(false);
  }

  async getInvoices() {
    try {
      this.setWaiting(true);
      const query = this.queryParams();

      const { data } = await API.get(`subscriptions/store/invoice?${query}`);

      const formattedInvoices = format.invoices(data.invoices);
      const pagination = format.pagination(data.pagination);

      this.setInvoice(formattedInvoices, pagination);
      this.setWaiting(false);
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      this.setWaiting(false);
      return null;
    }
  }

  queryParams() {
    const filter = useInvoice.getState().data.filter;
    const pagination = useInvoice.getState().data.pagination;

    const objectParams = _.pickBy(
      {
        cpf: filter.cpf || null,
        dateFrom: filter.dateFrom || null,
        dateTo: filter.dateTo || null,
        type: filter.summaryType,
        description: filter.type || "Todos",
        page: pagination.page || "0",
        perPage: pagination.perPage || 10,
      },
      _.identity
    );

    return qs.stringify(objectParams);
  }
}
