import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Skeleton from '@material-ui/lab/Skeleton'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import API from '../../services/api'
import BasePage from '../basePage'

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 300,
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
}))

function PreAgendamento() {
  const ITEM_HEIGHT = 48

  const history = useHistory()
  const page = 0
  const rowsPerPage = 10
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(null)
  const [appAppointments, setAppAppointments] = useState(false)

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, 0 - page * rowsPerPage)

  const classes = useStyles()

  const onDecrement = (key) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(key)
  }

  const postActive = async (id, active) => {
    try {
      await API.post(`service/${id}`, {
        active,
      })
    } catch (err) {
      console.log('Erro:', err)
    }
  }
  const handleClose = (id, active) => {
    setAnchorEl(null)
    setOpen(false)
    postActive(id, active)
  }

  const confirmPreSchedule = async (id) => {
    try {
      await API.post(`service-order/appointment/${id}/confirm`)
    } catch (err) {
      console.log(err)
    }
  }
  const onDoubleClickEvent = (data) => {
    const { orderId, employeeId } = data

    // Não deixa iniciar agendamento em eventos bloqueados
    return orderId
      ? history.push(`/banho-tosa/check-in/order/${data.id}`, {
          employeeId,
        })
      : false
  }

  useEffect(() => {
    async function getQueue() {
      try {
        const { data } = await API.get('reports/app-appointments')
        setAppAppointments(data?.results)
      } catch (err) {
        console.log('Erro:', err)
      }
    }
    getQueue()
  }, [])
  return (
    <BasePage pageTitle="Pré Agendamento">
      <TableContainer component={Paper} elevation={0}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 140 }}>Data</TableCell>
              <TableCell align="center">Hora</TableCell>
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Pet</TableCell>
              <TableCell align="center">Serviços</TableCell>
              <TableCell style={{ width: 160 }} align="center">
                Profissional
              </TableCell>
              <TableCell align="center">Duração</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>

          {appAppointments ? (
            <TableBody>
              {appAppointments
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell component="th" scope="row">
                      {row?.date}
                    </TableCell>
                    <TableCell align="center">{row?.startsAt}</TableCell>
                    <TableCell align="center">{row?.customerName}</TableCell>
                    <TableCell align="center">{row?.petName}</TableCell>
                    <TableCell align="center">{row?.services[0]}</TableCell>
                    <TableCell align="center">{row?.employee}</TableCell>
                    <TableCell align="center">{row?.duration} min</TableCell>
                    <TableCell align="center">
                      <>
                        <IconButton
                          aria-label="more"
                          aria-controls={row.id}
                          aria-haspopup="true"
                          onClick={onDecrement(row.id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={row.id}
                          anchorEl={anchorEl}
                          keepMounted
                          open={row.id === open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                          <MenuItem
                            key="ativo"
                            selected="ativo"
                            onClick={() =>
                              onDoubleClickEvent({
                                id: row?.orderId,
                                orderId: row?.orderId,
                                employeeId: row?.employeeId,
                              })
                            }
                          >
                            Ver detalhes
                          </MenuItem>
                          <MenuItem
                            key="inativo"
                            selected="inativo"
                            onClick={() => confirmPreSchedule(row?.id)}
                          >
                            Confirmar
                            <br /> agendamento
                          </MenuItem>
                        </Menu>
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </BasePage>
  )
}

export default PreAgendamento
