import { getAcronymName } from '../../../../services/functions'
import { Avatar } from './styles'

export function EmployeeAvatar({ children, name }) {
  return (
    <Avatar>
      {children}
      {getAcronymName(name)}
    </Avatar>
  )
}
