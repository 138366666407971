import { useMemo } from "react";
import _ from "lodash";
import { Save, Undo2 } from "lucide-react";

import { DRE_EXPENSES } from "../../../../../../../store/subscription/constants";
import { clearNumber,groupByProperty } from "../../../../../../../services/functions";

import { useDre } from "../../../../../../../store/subscription/useDre";

import { SubscriptionDREController } from "../../../../../../../services/controllers/subscription";

import { Input } from "../../../../../../../components/ui/Form/Input";
import { Action } from "../../../../../../../components/ui/Action";
import { Expense } from "./Expense";


export function ConfigureInformationExpenses() {
  const CLDre = SubscriptionDREController()

  const period = useDre(st => st.data.config.period)
  const isLoading = useDre(st => st.data.config.loading)
  const isSaving = useDre(st => st.data.config.saving)
  const expenses = useDre(st => st.data.config.expenses)

  const validDate = _.size(clearNumber(period)) === 6

  if(!expenses[period] && validDate) CLDre.findExpenses()

  const expensesGroups = useMemo(() => groupByProperty(DRE_EXPENSES), [DRE_EXPENSES])

  return (
    <form className="flex flex-wrap px-4 gap-y-4 gap-x-2 w-full" onSubmit={CLDre.savePeriodExpensesConfig}>
      <div className="w-full mb-4">
        <div className="flex-1 w-full max-w-[300px]">
          <Input.Root
            label="Período"
            mask='99/9999'
            value={period}
            onChange={CLDre.setConfigPeriod}
          />
        </div>
      </div>
      <div className="flex w-full flex-col gap-8">
        {Object.entries(expensesGroups).map(([key, expensesGroup]) =>(
          <div className="flex flex-wrap gap-y-4 gap-x-2 w-full">
            <p className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">{key}</p>
            {Object.entries(expensesGroup).map(([key, expense]) =>(
              <Expense
                key={key}
                id={key}
                data={expense}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="flex w-full mt-4 items-center justify-between">
        <Action.Root
          outline
          status="danger"
          type="button"
          disabled={isLoading || isSaving}
          onClick={CLDre.setDefaulExpenses}
        >
          <Action.Icon icon={Undo2} />
          Usar valores padrão
        </Action.Root>
        <Action.Root disabled={isLoading || isSaving}>
          <Action.Icon icon={Save} isLoading={isSaving}/>
          {isSaving ? "Aplicando": "Aplicar"}
        </Action.Root>
      </div>
    </form>
  );
}
