import { autoFormatMedicineDosageInformation } from "../../../../../../../services/functions";
import { FreeTextContent } from "./styles";

export function Free(medicine) {
  const freeTextContent = autoFormatMedicineDosageInformation(medicine);

  return (
    <FreeTextContent dangerouslySetInnerHTML={{ __html: freeTextContent }} />
  );
}
