import { rgba } from "polished";
import styled from "styled-components";

export const Space = styled.legend`
  visibility: hidden;
  padding: 0 5px;
  width: 100%;
  max-width: fit-content;
  height: 0px;
  font-size: 14px;
  line-height: 1;
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Legend = styled.span`
  position: absolute;
  padding: 0;
  top: -10px;
  left: 14px;
  font-size: 14px;
  transform: scale(1);
  transform-origin: left center;
  color: ${(props) => props.theme.colors.gray[500]};
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
`;

export const TextArea = styled.textarea`
  outline-style: none;
  width: 100%;
  min-height: 20px;
  font-size: 16px;
  resize: vertical;
  border: none;
  margin: 0;
  padding: 4px 8px;
  line-height: 150%;
  background: transparent;
  color: ${(props) => props.theme.colors.gray[600]};
  &:placeholder-shown {
    & ~ ${Space} {
      padding: 0;
      max-width: 0;
    }
    & ~ ${Legend} {
      left: 15px;
      top: calc(100% - 29px);
      transform: scale(1.12);
      color: ${(props) => props.theme.colors.gray[400]};
    }
  }
  &:focus {
    & ~ ${Space} {
      padding: 0 5px;
      width: 100%;
      max-width: fit-content;
    }
    & ~ ${Legend} {
      top: -10px;
      left: 14px;
      transform: scale(1);
      color: ${(props) => props.theme.colors.scale[500]};
    }
  }
`;

export const TextAreaBox = styled.fieldset`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 8px;
  margin: 0;
  border-radius: 5px;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.scale[500]};
  width: 100%;
`;
export const Container = styled.label`
  position: relative;
  width: 100%;
  min-width: 100%;
  &[aria-disabled="true"] {
    ${TextAreaBox} {
      border-color: ${(props) => props.theme.colors.gray[200]};
      pointer-events: none;
      cursor: not-allowed;
      background: ${(props) => rgba(props.theme.colors.gray[50], 0.2)};
      background: ${(props) =>
        `linear-gradient(180deg, ${rgba(
          props.theme.colors.gray[50],
          0
        )} 0%, ${rgba(props.theme.colors.gray[100], 0.8)} 100%)`};
    }
  }
`;
