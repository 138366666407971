import { toast } from "react-toastify";

import api from "../../api";

import { formatResponses } from "../../formatResponses/veterinaryCare";

import VeterinaryRecordModel from "./VeterinaryRecord";
import VeterinaryAttachmentModel from "./VeterinaryAttachment";

export default class VeterinaryHistoryModel {

  findHistory = async (orderId) => {
    if (!orderId) return;

    try {
      const { data } = await api.get(`service-order/appointment/${orderId}/history`);

      const formattedHistories = formatResponses.histories(data.history);

      return formattedHistories;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

}
