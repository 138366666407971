import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  paper: {
    marginTop: '10px',
    background: '#FFF',
    padding: '20px',
  },
  card: {
    marginBottom: '20px',
  },
  header: {
    background: '#CCD219',
    color: '#FFF',
    borderRadius: '5px 5px 0px 0px',
    fontWeight: '500',
    fontSize: '18px !important',
    marginTop: '20px !important',
    padding: '5px 25px',
  },
  body: {
    padding: '5px 25px',
    borderRadius: '0px 0px 5px 5px',
    border: '1px solid #F6F6F6 !important',
    background: '#F6F6F6 !important',
  },
  filter: {
    margin: '30px 0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
    border: '1px solid #ccd219',
  },
  filterStep: {
    margin: '0px 0px 30px',
    padding: '15px',
    background: '#fff',
    border: '1px solid #CCD219',
    borderRadius: '0px 0px 7px 7px',
  },
  statusSelect: {
    '& div': {
      padding: '8px',
    },
  },
  descriptionLabel: {
    color: '#666666',
    fontSize: '16px',
    marginLeft: '31px',
    marginBottom: '20px',
  },
  titleRadio: {
    '& .MuiFormControlLabel-label': {
      color: '#666666',
      fontSize: '16px',
      fontWeight: '700',
    },
  },
  accordionNumber: {
    '& input': {
      padding: '10px',
      width: '80px',
    },
  },
  accordionDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  tituloDrawer: {
    fontSize: '14px !important',
    color: '#0000008F !important',
    fontWeight: '700 !important',
  },
  subTitleDrawer: {
    fontSize: '14px !important',
    color: '#0000008F !important',
    fontWeight: '300 !important',
  },
}))
