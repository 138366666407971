import _ from "lodash";
import Mustache from "mustache";

import { wppTemplates } from "../../templates/wpp";

import { clearNumber } from "../../functions";
import { formatTemplateData } from "../../formatTemplateData/veterinay";
import { formatPreviewList } from "../../formatPreviewList/veterinaryCare";

import { VeterinaryAttachmentModel } from "../../models/VeterinaryCare";

import { usePet } from "../../../store/global/usePet";
import { useAttachment } from "../../../store/service/veterinary/useAttachment";

import { WHATSAPP_SEND_MESSAGE_BASE } from "../../../styles/constant";
import { useCare } from "../../../store/service/veterinary/useCare";

export default class VeterinaryAttachmentController {
  _name = 'veterinary-attachment';
  _label = 'Anexos';

  _previewListName = `${this._name}-list`;

  _saveInStore = true;

  setAttachmentsList = useAttachment.getState().actions.setAttachments;
  setSelectedAttachment = useAttachment.getState().actions.setSelectAttachment;

  setLoadingStatus = useAttachment.getState().actions.setLoadingStatus;
  setLoadedThumbnailsStatus = useAttachment.getState().actions.setLoadedThumbnailsStatus;

  removeAttachment = useAttachment.getState().actions.removeAttachment;
  returnAttachment = useAttachment.getState().actions.returnAttachment;
  excludeAttachment = useAttachment.getState().actions.deleteAttachment;


  constructor(orderId, saveInStore = null, label = false) {
    this._orderId = orderId;

    if (saveInStore !== null) this._saveInStore = !!saveInStore

    this._label = label || this._label

    this._sendAttachmentTemplate = wppTemplates.draMei.sendAttachment;

    this.MVetAttachment = VeterinaryAttachmentModel(this._orderId);
  }

  init = _.once(() => {
    this.getAttachments();
  });

  selectAttachment = (attachment) => {
    this.setSelectedAttachment(attachment);
  };


  sendMessage = (that = false) => {
    const care = useCare.getState().data.care
    const pet = usePet.getState().data.pet;
    const attachment = that || useAttachment.getState().data.selectAttachment;

    const ownerPhoneNumber = clearNumber(pet.owner.phone);

    const attachmentMessageData = formatTemplateData.sendAttachment({
      pet,
      attachment,
      veterinarian: care.veterinarian,
    });

    const message = Mustache.render(
      this._sendAttachmentTemplate.content,
      attachmentMessageData
    );

    const wppMessageUrl = WHATSAPP_SEND_MESSAGE_BASE.replace(
      "[PHONE_NUMBER]",
      ownerPhoneNumber
    ).replace("[MESSAGE]", message);

    window.open(wppMessageUrl, "_blank");
  };

  getThumbs = async () => {
    const isLoadedThumbnails = useAttachment.getState().data.loadedThumbnails;
    const attachments = useAttachment.getState().data.attachments

    if (isLoadedThumbnails || !attachments) return
    this.setLoadedThumbnailsStatus(true)


    const withThumbs = await formatPreviewList.thumbs(_.values(attachments));

    if (this._saveInStore) this.setAttachmentsList(withThumbs);

    return withThumbs
  };

  getAttachments = async () => {
    const response = await this.MVetAttachment.findAttachments();

    if (!response) return;

    const formattedAttachments = formatPreviewList.attachments(response);

    if (this._saveInStore) this.setAttachmentsList(formattedAttachments);

    return formattedAttachments
  };

  sendAttachment = async (formData) => {
    this.setLoadingStatus(true);

    const response = await this.MVetAttachment.sendAttachment(formData);

    this.setLoadingStatus(false);
    if (!response) return;

    const formattedAttachments = await formatPreviewList.attachments(response);
    this.setAttachmentsList(formattedAttachments);
  };

  deleteAttachment = async () => {
    const selectAttachment = useAttachment.getState().data.selectAttachment;

    this.removeAttachment(selectAttachment.id);

    const response = await this.MVetAttachment.deleteAttachment(selectAttachment.id);

    if (!response) {
      this.returnAttachment(selectAttachment.id);
      return;
    }

    this.excludeAttachment(selectAttachment.id);
  };
}
