import { useRequest } from "../../../../../../../store/service/veterinary/useRequest";
import { VeterinaryRequestController } from "../../../../../../../services/controllers/VeterinaryCare";

import { REQUEST_TYPES } from "../../../../../../../store/service/veterinary/constants";

import { Select } from "../../../../../../../components/ui/Form/Select";

import { FormGroup } from "../styles";

export function RequestSelect() {
  const CLVetRequest = VeterinaryRequestController();
  const requestPath = useRequest((st) => st.data.request.requestPath);

  const requestList = CLVetRequest._templates;

  return (
    <FormGroup>
      <Select.Root
        label="Tipo de Requisição"
        value={requestPath}
        onChange={CLVetRequest.setRequestType}
      >
        {Object.keys(requestList).map((requestType) => (
          <Select.Group key={requestType}>
            <Select.Title>{REQUEST_TYPES[requestType][2]}</Select.Title>
            {Object.keys(requestList[requestType]).map((request) => (
              <Select.Option key={request} value={`${requestType}.${request}`}>
                {requestList[requestType][request].name}
              </Select.Option>
            ))}
          </Select.Group>
        ))}
      </Select.Root>
    </FormGroup>
  );
}
