import _ from "lodash";

import { PetCardAttribute } from "./PetCardAttribute";
import { PetAttributes as Container } from "./styles";

export function PetCardAttributes({
  attributes,
  show = false,
  noIcon = false,
  ...rest
}) {
  if (!attributes) return null;

  attributes = _.orderBy(attributes, ['label'])

  if (show) {
    attributes = _.orderBy(
      attributes,
      attribute => show.indexOf(attribute.label)
    ).filter((attribute) => show.includes(attribute.label))
  }

  return (
    <Container {...rest}>
      {attributes.map(({ label, value }) => (
        <PetCardAttribute label={label} key={label} noIcon={noIcon}>
          {value}
        </PetCardAttribute>
      ))}
    </Container>
  );
}
