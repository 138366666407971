import { Root, Portal, Provider } from "@radix-ui/react-tooltip";

import { useTooltip } from "./useTooltip";

import { Container, Arrow, Anchor } from "./styles";

export function TooltipRoot({
  children,
  name,
  side = "left",
  zIndex = 2,
  ...rest
}) {
  const { tooltips, status } = useTooltip((st) => st.data);
  const isOpen = useTooltip((st) => st.actions.isOpen);
  const setTooltip = useTooltip((st) => st.actions.setTooltip);

  setTooltip(name);

  const index = tooltips.indexOf(name);
  const open = status[index];

  return (
    <Provider>
      <Root
        open={open}
        onOpenChange={(status) => {
          isOpen({ name, open: status });
        }}
      >
        <Anchor />
        <Portal>
          <div style={{ position: "relative", zIndex }}>
            <Container
              side={side}
              sideOffset={5}
              collisionPadding={20}
              {...rest}
            >
              {children}
              <Arrow />
            </Container>
          </div>
        </Portal>
      </Root>
    </Provider>
  );
}
