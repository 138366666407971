import {
  CalendarDays,
  ClipboardEdit,
  Eraser,
  Redo,
  Save,
  Syringe,
} from "lucide-react";

import { VACCINATION_STATUS_ACTIONS } from "../../../../../../../store/service/veterinary/constants";
import { isAbleByStatus } from "../../../../../../../services/functions";

import { useUser } from "../../../../../../../store/global/useUser";
import { useVaccine } from "../../../../../../../store/service/veterinary/useVaccine";
import { useCare } from "../../../../../../../store/service/veterinary/useCare";

import { VeterinaryVaccineController } from "../../../../../../../services/controllers/VeterinaryCare";

import { Action } from "../../../../../../../components/ui/Action";

import { ActionsItem } from "../styles";

export function VaccineActions({ vaccineData }) {
  const CLVetVaccine = VeterinaryVaccineController();

  const userGroup = useUser(st => st.data.user.group);
  const careStatus = useCare(st => st.data.care.status)

  const protocolId = useVaccine((st) => st.data.showVaccinationProtocol);
  const assignedProtocols = useVaccine((st) => st.data.assignedProtocols);

  const selectedProtocol = _.find(assignedProtocols, { id: protocolId });

  if (selectedProtocol?.status === "interrupted") return null;

  const isVeterinary = userGroup?.type === 'veterinary' || false;
  const isAble = isAbleByStatus(careStatus,CLVetVaccine._name)

  if (!isVeterinary) return null

  return (
    <ActionsItem>
      {VACCINATION_STATUS_ACTIONS[vaccineData.status].includes("edit") && (
        <Action.Root
          outline
          status="400"
          type="button"
          title="Editar Vacina"
          disabled={!isVeterinary || !isAble}
          name={`edit-${vaccineData.id}`}
          onClick={() =>
            CLVetVaccine.changeVaccineStatus(
              vaccineData.id,
              "editing",
              vaccineData.status
            )
          }
        >
          <Action.Icon icon={ClipboardEdit} size={22} />
        </Action.Root>
      )}

      {VACCINATION_STATUS_ACTIONS[vaccineData.status].includes("apply") && (
        <Action.Root
          outline
          type="button"
          status="success"
          title="Aplicar Vacina"
          disabled={!isVeterinary || !isAble}
          name={`apply-${vaccineData.id}`}
          onClick={() =>
            CLVetVaccine.changeVaccineStatus(
              vaccineData.id,
              "applying",
              vaccineData.status
            )
          }
        >
          <Action.Icon icon={Syringe} size={22} />
        </Action.Root>
      )}

      {VACCINATION_STATUS_ACTIONS[vaccineData.status].includes("reprogram") && (
        <Action.Root
          outline
          status="600"
          type="button"
          title="Reprogramar Vacina"
          disabled={!isVeterinary || !isAble}
          name={`reprogram-${vaccineData.id}`}
          onClick={() =>
            CLVetVaccine.changeVaccineStatus(
              vaccineData.id,
              "reprogramming",
              vaccineData.status
            )
          }
        >
          <Action.Icon icon={CalendarDays} size={22} />
        </Action.Root>
      )}

      {VACCINATION_STATUS_ACTIONS[vaccineData.status].includes("cancel") && (
        <Action.Root
          not
          outline
          status="danger"
          title="Cancelar aplicação"
          type="button"
          name={`cancel-${vaccineData.id}`}
          disabled={!isVeterinary || !isAble}
          onClick={() => CLVetVaccine.cancelVaccine(vaccineData.id)}
        >
          <Action.Icon icon={Syringe} size={22} />
        </Action.Root>
      )}

      {VACCINATION_STATUS_ACTIONS[vaccineData.status].includes("save") && (
        <Action.Root
          outline
          status="success"
          type="button"
          title="Salvar"
          name={`save-${vaccineData.id}`}
          disabled={!isVeterinary || !isAble}
          onClick={() => CLVetVaccine.saveChangesVaccineValues(vaccineData.id)}
        >
          <Action.Icon icon={Save} size={22} />
        </Action.Root>
      )}
      {VACCINATION_STATUS_ACTIONS[vaccineData.status].includes("back") && (
        <Action.Root
          outline
          status="800"
          type="button"
          title="Cancelar"
          disabled={!isVeterinary || !isAble}
          name={`back-${vaccineData.id}`}
          onClick={() => {
            CLVetVaccine.setAssignedVaccineFieldsValue(vaccineData.id);
            CLVetVaccine.changeVaccineStatus(vaccineData.id, vaccineData.back);
          }}
        >
          <Action.Icon icon={Redo} size={22} />
        </Action.Root>
      )}

      {VACCINATION_STATUS_ACTIONS[vaccineData.status].includes("remove") && (
        <Action.Root
          outline
          status="danger"
          title="Remover Aplicação"
          type="button"
          disabled={!isVeterinary || !isAble}
          name={`remove-${vaccineData.id}`}
          onClick={() => CLVetVaccine.removeVaccine(vaccineData.id)}
        >
          <Action.Icon icon={Eraser} size={22} />
        </Action.Root>
      )}
    </ActionsItem>
  );
}
