import { rgba } from "polished";
import styled, { css, keyframes } from "styled-components";

const spinAnimate = keyframes`
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Container = styled.div`
  position: relative;
`;

export const IconBox = styled.span`
  display: flex;
  transition: all ${(props) => props.theme.animation.delay.lazy} ease-in-out;
  ${(props) =>
    props.isLoading &&
    css`
      animation: ${spinAnimate} ${(props) => props.theme.animation.delay.lazy}
        linear infinite;
    `}
`;

export const TipContent = styled.span`
  padding: 4px 12px;
  color: ${(props) => props.theme.colors.scale[500]};
`;

export const Button = styled.button`
  --default-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px
    5px;
  --hover-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 6px 6px 8px;
  --active-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 1px 1px 4px;
  --border-color: ${(props) => rgba(props.theme.colors.gray[800], 0.4)};

  --base-color: ${(props) => props.theme.colors.scale[500]};
  --remove-color: ${(props) => props.theme.colors.error};
  --add-color: ${(props) => props.theme.colors.success};

  ${(props) =>
    props.custom &&
    css`
      --base-color: ${props.custom};
    `}

  ${(props) =>
    props.status &&
    css`
      --base-color: ${(props) => props.theme.colors.scale[props.status]};
    `}

  ${(props) =>
    props.status === "danger" &&
    css`
      --base-color: ${(props) => props.theme.colors.error};
    `}

  ${(props) =>
    props.status === "warning" &&
    css`
      --base-color: ${(props) => props.theme.colors.warning};
    `}
    ${(props) =>
    props.status === "success" &&
    css`
      --base-color: ${(props) => props.theme.colors.success};
    `}

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  width: 100%;
  min-width: max-content;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  border: 1px solid var(--border-color);
  background: var(--base-color);
  box-shadow: var(--default-shadow);
  color: ${(props) => props.theme.colors.background};
  transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;

  &[disabled] {
    cursor: not-allowed;
    transform: scale(0.9);
    box-shadow: var(--active-shadow);
    background: ${(props) => props.theme.colors.gray[300]};
    transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  }
  &:hover:not([disabled]) {
    transform: scale(1.05);
    box-shadow: var(--hover-shadow);
    ${(props) =>
      props.animateHover === "spin" &&
      css`
        ${IconBox} {
          transform: rotate(720deg);
        }
      `}
    ${(props) =>
      props.animateHover === "reverseSpin" &&
      css`
        ${IconBox} {
          transform: rotate(-720deg);
        }
      `}
  }
  &:active:not([disabled]) {
    transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
    transform: scale(0.95);
    box-shadow: var(--active-shadow);
  }

  ${(props) => !!props.noHover && css`
      pointer-events: none;
  `}

  ${(props) => !props.full && css`
      max-width: 150px;
  `}
  ${(props) => props.active && css`
      cursor: default;
      &:hover:not([disabled]),
      &:active:not([disabled]) {
        transform: scale(1);
        box-shadow: var(--default-shadow);
      }
  `}

  ${(props) =>
    props.outline &&
    css`
      background: ${(props) => props.theme.colors.background};
      color: var(--base-color);
      border: 1px solid var(--base-color);
      ${(props) =>
        props.active &&
        css`
          background: var(--base-color);
          color: ${(props) => props.theme.colors.background};
        `}
      &[disabled] {
        color: ${(props) => props.theme.colors.gray[300]};
        background: ${(props) => props.theme.colors.background};
        border-color: ${(props) => props.theme.colors.gray[300]};
      }
    `}
  ${(props) =>
    props.remove &&
    css`
      &:hover:not([disabled]) {
        border-color: var(--border-color);
        background: var(--remove-color);
        color: ${(props) => props.theme.colors.background};
      }
    `}
  ${(props) =>
    props.add &&
    css`
      &:hover:not([disabled]) {
        border-color: var(--border-color);
        background: var(--add-color);
        color: ${(props) => props.theme.colors.background};
      }
    `}
  ${(props) =>
    props.not &&
    css`
      &::before {
        content: "";
        position: absolute;
        background: currentColor;
        height: 110%;
        width: 2px;
        transform-origin: center center;
        transform: rotate(-45deg);
      }
    `}
`;
