import { twMerge } from 'tailwind-merge'

export function CardMain({ className, ...rest }) {
  return (
    <div
      className={twMerge('flex w-full flex-col px-2', className)}
      {...rest}
    />
  )
}
