import _ from 'lodash'

import { PetController } from '../../../../../../../services/controllers/scheduling'
import { useCustomer } from '../../../../../../../store/customer/useCustomer'
import { usePet } from '../../../../../../../store/customer/usePet'

import { Input } from '../../../../../../ui/Form/Input'
import { Select } from '../../../../../../ui/Form/Select'
import { TextArea } from '../../../../../../ui/Form/TextArea'

import { Container } from './styles'

export function Pet() {
  const CLPet = PetController()

  const customer = useCustomer((st) => st.data.customer)
  const pet = usePet((st) => st.data.pet)

  const petMandatory = ['cpf']
  const isEmpty = !_.every(
    petMandatory,
    (key) => !_.isEmpty(_.get(customer, key)),
  )
  const isFullCpf = customer.cpf?.length === 11
  const disabled = isEmpty || !isFullCpf

  return (
    <Container>
      <Input.Root
        label="Nome do Pet"
        value={disabled ? '' : pet.name}
        disabled={disabled}
        style={{ minWidth: '100%' }}
        onChange={(name) => {
          CLPet.setPetData({ name })
        }}
      />
      <Select.Root
        label="Espécie"
        value={disabled ? null : pet.specie}
        disabled={disabled}
        style={{ minWidth: '130px' }}
        onChange={(specie) => {
          CLPet.setPetData({ specie })
        }}
      >
        {CLPet.attributeOptions('Espécie').map(({ _id, label }) => (
          <Select.Option key={_id} value={label}>
            {label}
          </Select.Option>
        ))}
      </Select.Root>
      <Select.Root
        label="&nbsp;Raça"
        value={disabled ? null : pet.breed}
        disabled={disabled}
        style={{ minWidth: '200px' }}
        onChange={(breed) => {
          CLPet.setPetData({ breed })
        }}
      >
        {CLPet.attributeOptions('Raça').map(({ _id, label }) => (
          <Select.Option key={_id} value={label}>
            {label}
          </Select.Option>
        ))}
      </Select.Root>
      <Select.Root
        label="&nbsp;Sexo"
        value={disabled ? null : pet.gender}
        disabled={disabled}
        style={{ minWidth: '100px' }}
        onChange={(gender) => {
          CLPet.setPetData({ gender })
        }}
      >
        {CLPet.attributeOptions('Sexo').map(({ _id, label }) => (
          <Select.Option key={_id} value={label}>
            {label}
          </Select.Option>
        ))}
      </Select.Root>
      <Select.Root
        label="Porte"
        value={disabled ? null : pet.size}
        disabled={disabled}
        style={{ minWidth: '100px' }}
        onChange={(size) => {
          CLPet.setPetData({ size })
        }}
      >
        {CLPet.attributeOptions('Porte').map(({ _id, label }) => (
          <Select.Option key={_id} value={label}>
            {label}
          </Select.Option>
        ))}
      </Select.Root>
      <Select.Root
        label="Pelagem"
        value={disabled ? null : pet.fur}
        disabled={disabled}
        style={{ minWidth: '130px' }}
        onChange={(fur) => {
          CLPet.setPetData({ fur })
        }}
      >
        {CLPet.attributeOptions('Pelagem').map(({ _id, label }) => (
          <Select.Option key={_id} value={label}>
            {label}
          </Select.Option>
        ))}
      </Select.Root>
      <Select.Root
        label="Castrado"
        value={disabled ? null : pet.castrated}
        disabled={disabled}
        style={{ minWidth: '130px' }}
        onChange={(castrated) => {
          CLPet.setPetData({ castrated })
        }}
      >
        {CLPet.attributeOptions('Castrado').map(({ _id, label }) => (
          <Select.Option key={_id} value={label}>
            {label}
          </Select.Option>
        ))}
      </Select.Root>
      <Input.Root
        label="Peso (Kg)"
        value={disabled ? '' : pet.weight}
        disabled={disabled}
        onChange={(weight) => {
          CLPet.setPetData({ weight })
        }}
      />
      <Input.Root
        label="Nascimento"
        mask="99/99/9999"
        value={disabled ? '' : pet.birthdate}
        disabled={disabled}
        style={{ minWidth: '200px' }}
        onChange={(birthdate) => {
          CLPet.setPetData({ birthdate })
        }}
      />

      <TextArea.Root
        label="Observação"
        rows={5}
        value={pet.observation}
        disabled={disabled}
        onChange={(observation) => CLPet.setPetData({ observation })}
      />
    </Container>
  )
}
