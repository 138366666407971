export const PartialDocumentoDate = {
  name: "Data do documento",
  gender: "female",
  fields: {
    documentoDateCity: {
      label: "Cidade do Documento",
      type: "text",
      format: "name",
      required: true,
      defaultPreview: "__________________",
    },
    documentoDateDate: {
      label: "Data do Documento",
      type: "text",
      required: true,
      defaultPreview: "__de____________de____",
      format: "longDate",
      props: {
        pickDate: true,
      },
    },
  },

  content: `
    <div class="right">
      <p>{{documentoDateCity}}, {{documentoDateDate}}</p>
    </div>
  `.trim(),
};
