import { CustomerController } from '../../../../../../../services/controllers/scheduling'
import { useCustomer } from '../../../../../../../store/customer/useCustomer'

import { Input } from '../../../../../../ui/Form/Input'

import { Container } from './styles'

export function Client() {
  const CLCustomer = CustomerController()
  const customer = useCustomer((st) => st.data.customer)

  return (
    <Container>
      <Input.Root
        label="CPF"
        mask="999.999.999-99"
        style={{ minWidth: '170px' }}
        value={customer.cpf}
        onChange={(cpf) => {
          CLCustomer.setCustomerData({ cpf })
        }}
      />
      <Input.Root
        label="Nome completo"
        style={{ minWidth: '360px' }}
        value={customer.name}
        onChange={(name) => {
          CLCustomer.setCustomerData({ name })
        }}
      />
      <Input.Root
        label="Celular"
        mask="(99) 9 9999-9999"
        style={{ minWidth: '190px' }}
        value={customer.cellphone}
        onChange={(cellphone) => {
          CLCustomer.setCustomerData({ cellphone })
        }}
      />
      <Input.Root
        label="Telefone de recado"
        mask="(99) 9 9999-9999"
        style={{ minWidth: '190px' }}
        value={customer.telephone}
        onChange={(telephone) => {
          CLCustomer.setCustomerData({ telephone })
        }}
      />
      <Input.Root
        label="E-mail"
        value={customer.email}
        onChange={(email) => {
          CLCustomer.setCustomerData({ email })
        }}
      />
    </Container>
  )
}
