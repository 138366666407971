import {  rgba } from 'polished'
import styled from 'styled-components'

export const SummaryTitle = styled.h3`
    margin: 0;
    color: ${(props) => props.theme.colors.scale[700]};
`

export const SummaryDetails = styled.ul`
    margin: 0 0 16px  0;
    padding: 4px 0 0 8px;
`

export const SummaryDetail = styled.li`
    list-style:none;
    strong{
      color: ${(props) => props.theme.colors.scale[500]};
    }
`

export const SummaryServicesTable = styled.div`
    margin-top: 4px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.colors.scale[300]};
`

export const SummaryServicesTableHeadLine = styled.div`
  gap: 4px;
  width: 100%;
  display: grid;
  grid-template-columns: 90px 1fr 150px;
  color: ${(props) => props.theme.colors.scale[500]};
  padding: 4px 0px;
  font-size: 14px;
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[300]};
  span{
    padding: 0 2px;
  }
`

export const SummaryServicesTableLine = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 150px;
  font-size: 14px;
  padding: 6px 0px;
  gap: 4px;
  color: ${(props) => props.theme.colors.gray[800]};
  &:nth-child(odd){
    background: ${(props) => rgba(props.theme.colors.scale[100], .3)};
  }
  span{
    padding: 0 8px;
  }
`

export const SummaryServicesTotalAmount = styled.p`
  width: 100%;
  margin-top: 12px;
  gap: 8px;
  padding-left: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 18px;
  color: ${(props) => props.theme.colors.scale[500]};
  font-weight: bold;
  strong{
    font-weight: normal;
    color: ${(props) => props.theme.colors.gray[900]};
    font-size:14px;
  }
`
