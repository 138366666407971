import React from 'react'
import { Grid, TextField } from '@material-ui/core'

import { useTaxiDog } from '../../../../../hooks/TaxiDogContext'

import { useStyles } from './styles'

function AddressForm({
  title,
  defaultAdress,
  useDefaultAdress,
  address,
  type,
}) {
  const { input } = useStyles()
  const { setTaxiDogInfo } = useTaxiDog()

  const changeAdress = (adressData) => {
    if (type === 'pickupAddress') {
      setTaxiDogInfo({
        pickupAddress: adressData,
      })
    }
    if (type === 'deliveryAddress') {
      setTaxiDogInfo({
        deliveryAddress: adressData,
      })
    }
  }

  if (useDefaultAdress) {
    if (address !== defaultAdress && type === 'pickupAddress')
      changeAdress(defaultAdress)
    if (address !== defaultAdress && type === 'deliveryAddress')
      changeAdress(defaultAdress)
  }

  return (
    <Grid spacing={2} item xs={12}>
      <Grid item xs={8}>
        <h3>{title}</h3>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Rua"
            variant="outlined"
            className={input}
            disabled={useDefaultAdress}
            InputLabelProps={{
              shrink: true,
            }}
            value={useDefaultAdress ? defaultAdress?.street : address.street}
            onChange={(e) => {
              changeAdress({ ...address, street: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Número"
            variant="outlined"
            className={input}
            disabled={useDefaultAdress}
            InputLabelProps={{
              shrink: true,
            }}
            value={useDefaultAdress ? defaultAdress?.number : address.number}
            onChange={(e) => {
              changeAdress({ ...address, number: e.target.value })
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Complemento"
          variant="outlined"
          className={input}
          disabled={useDefaultAdress}
          InputLabelProps={{
            shrink: true,
          }}
          value={
            useDefaultAdress ? defaultAdress?.complement : address.complement
          }
          onChange={(e) => {
            changeAdress({ ...address, complement: e.target.value })
          }}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="BAIRRO"
            variant="outlined"
            className={input}
            disabled={useDefaultAdress}
            InputLabelProps={{
              shrink: true,
            }}
            value={
              useDefaultAdress
                ? defaultAdress?.neighborhood
                : address.neighborhood
            }
            onChange={(e) => {
              changeAdress({ ...address, neighborhood: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Cidade"
            variant="outlined"
            className={input}
            disabled={useDefaultAdress}
            InputLabelProps={{
              shrink: true,
            }}
            value={useDefaultAdress ? defaultAdress?.city : address.city}
            onChange={(e) => {
              changeAdress({ ...address, city: e.target.value })
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddressForm
