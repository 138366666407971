import styled from "styled-components";

export const CustomMedicneContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 30px);
  display: flex;
  align-items: center;
  margin: 0 auto;
  &>*{
    width: 100%
  }
`;

export const CustomMedicneContent = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;
