import styled, { keyframes } from 'styled-components'

import {
  Content,
  Arrow as RadixArrow,
  Close as RadixClose,
  Anchor as RadixAnchor,
} from '@radix-ui/react-popover'

const slideUpAndFade = keyframes`
    0% { opacity: 0; transform: translateY(2px) };
    100% { opacity: 1; transform: translateY(0) };
`

const slideRightAndFade = keyframes`
    0% { opacity: 0; transform: translateX(-2px) };
    100% { opacity: 1; transform: translateX(0) };
`

const slideDownAndFade = keyframes`
    0% { opacity: 0; transform: translateY(-2px) };
    100% { opacity: 1; transform: translateY(0) };
`

const slideLeftAndFade = keyframes`
    0% { opacity: 0; transform: translateX(2px) };
    100% { opacity: 1; transform: translateX(0) };
`

export const Container = styled(Content)`
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 0;
  animation-duration: ${(props) => props.theme.animation.delay.slow};
  animation-timing-function: 'cubic-bezier(0.16, 1, 0.3, 1)';

  &[data-state='open'][data-side='top'] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state='open'][data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state='open'][data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`

export const Arrow = styled(RadixArrow)`
  fill: ${(props) => props.theme.colors.background};
`
export const Anchor = styled(RadixAnchor)`
  position: absolute;
  width: 0px;
  height: 0px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const CloseButton = styled(RadixClose)`
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${(props) => props.theme.colors.scale[500]};
  position: absolute;
  border: none;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all ${(props) => props.theme.animation.delay.fast} linear;
  &:hover {
    transform: scale(1.2);
    color: ${(props) => props.theme.colors.scale[800]};
  }
  &:active {
    transform: scale(0.85);
    color: ${(props) => props.theme.colors.scale[400]};
  }
`
