import { useRequest } from "../../../../../../../store/service/veterinary/useRequest";

import { RequestFieldsProvider } from "./Context";

import { Field } from "./Field";
import { PartialSwitch } from "./PartialSwitch";

import { Container } from "./styles";

export function RequestFields() {
  const requestData = useRequest((st) => st.data.request.requestData);
  const requestValues = useRequest((st) => st.data.request.requestValues);

  return (
    <RequestFieldsProvider fieldsValues={requestValues}>
      <Container>
        {requestData?.fields && <Field fields={requestData?.fields} />}
        {requestData?.partials && (
          <PartialSwitch partials={requestData.partials} />
        )}
      </Container>
    </RequestFieldsProvider>
  );
}
