import moment from "moment";

import { useContext } from "../../Context";
import { useListPreview } from "../../useListPreview";

import { ListPreviewIcon } from "../../ListPreviewIcon";
import { ItemContent } from "../ItemContent";

import {
  Container,
  BulletMenu,
  Item,
  Action,
  Icon,
  SubTitle,
  Title,
  Header,
  Information,
} from "./styles";

export function ListContentSmallGrid() {
  const { listPreviewName, customAction } = useContext();

  const toggleSelected = useListPreview((st) => st.actions.toggleSelected);

  const viewAction = !!customAction.open
    ? ({ item, name }) => customAction.open({ item, name }, toggleSelected)
    : toggleSelected;

  const selected = useListPreview(
    (st) => st.data.lists[listPreviewName].selected
  );
  const listData = useListPreview((st) => st.data.lists[listPreviewName].data);

  return (
    <Container>
      {!!listData &&
        listData.map((item) => (
          <Item
            key={item.id}
            data-selected={selected?.id === item.id}
            onClick={() => viewAction({ item, name: listPreviewName })}
          >
            <Header>
              <Icon>
                <ListPreviewIcon type={item.type} size={50} />
              </Icon>
              <Action>
                <BulletMenu />
              </Action>
            </Header>
            <Information>
              <Title>{item.name}</Title>
              {_.keys(item?.informationList).map((information) => (
                <SubTitle
                  key={`${listPreviewName}-grid-${item.id}-${information}`}
                >
                  <ItemContent
                    size="small"
                    content={item.informationList[information]}
                  />
                </SubTitle>
              ))}
            </Information>
          </Item>
        ))}
    </Container>
  );
}
