import styled from 'styled-components'

export const Title = styled.h2`
  color: #00567e;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-top: 60px;
`

export const CardContainer = styled.div``

export const Card = styled.div`
  background-color: #fff;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 10px 12px;
  width: 32%;
  min-height: 170px;
  position: relative;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
`

export const RowCard = styled.div`
  width: 100%;
`

export const IconCalendar = styled.div`
  color: #00567e;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 5px;
  }
`

export const Description = styled.div`
  font-size: 12px;
  padding: 1px 0;
  padding-top: ${(props) => props.index === 0 && '8px'};
  width: 200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
`

export const Button = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  background: ${(props) => props.color};
  margin: 3px;
  margin-bottom: 12px;
  padding: 7px 30px;
  border-radius: 3px;
`

export const DivButton = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
`
