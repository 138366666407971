
import { twMerge } from 'tailwind-merge'

export function CardContent({ className, ...rest }) {
  return (
    <div
      className={twMerge(
        'flex w-full flex-col gap-1 px-2',
        className,
      )}
      {...rest}
    />
  )
}
