import React, { useState } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

// Material ui - core
import {
  Tab,
  Tabs,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  Divider,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

// Material ui - icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { getServicesByName } from '../../../services/services'
import Resumo from './Resumo'
import ModalType from '../../atoms/Modal'
import BasePage from '../../../pages/basePage'
import API from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function NewPackage() {
  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      package: [
        {
          serviceId: '',
          price: 0,
          unitPrice: 0,
          qtd: 0,
          checkbox: false,
        },
      ],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'package',
  })

  const [openModal, setOpenModal] = React.useState(null)
  const [error, setError] = React.useState(null)

  const [stateButtom, setStateButtom] = React.useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const [value, setValuee] = useState('0')
  const [handleServicesStatus, setHandleServicesStatus] = useState(false)
  const [change, setChange] = useState(false)
  const [desconto, setDesconto] = useState('0')
  const [typeDesconto, setTypeDesconto] = useState('fixed_value')
  const [formValues, setFormValues] = useState([])

  const [services, setServices] = useState([])
  const history = useHistory()
  const handleChangeIndex = (event, value) => setValuee(value)

  const classes = useStyles()
  const isClinica = history.location.search.search('clinica=true')
  const department = isClinica >= 0 ? 'Clínica Veterinária' : 'Banho e Tosa'
  const redirect = () =>
    history.push(
      department === 'Clínica Veterinária' ? '/packages' : '/pacotes',
    )

  const postPackage = async (param, on, off) => {
    try {
      const result = await API.post('packages/rules?', {
        promotionName: param.nome,
        serviceConditions: off,
        gifts: on,
        department,
        discount: {
          discountType: typeDesconto,
          discountAmount: parseInt(param.discount, 10),
        },
        active: true,
      })

      if (result?.status === 201) {
        setError(false)
        setOpenModal(result?.data?.message)

        setTimeout(() => {
          redirect()
        }, 1500)
      }
    } catch (err) {
      console.log(err)
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
      setStateButtom(false)
    }
  }
  const onSubmit = async (data) => {
    setStateButtom(true)
    let on = []
    let off = []

    const activeBonus = data.package.map((acc) => {
      if (acc.checkbox) {
        return {
          serviceId: acc.serviceId,
          quantity: acc.qtd,
          discountInPercentage: 100,
        }
      }
      return ''
    }, {})

    const offBonus = data.package.map((acc) => {
      if (acc.checkbox === false) {
        return { serviceId: acc.serviceId, quantity: acc.qtd }
      }
      return ''
    }, {})

    on = activeBonus.filter((item) => item)
    off = offBonus.filter((item) => item)

    postPackage(data, on, off)
  }

  const handleServices = async ({ target }) => {
    clearTimeout(handleServicesStatus)
    setHandleServicesStatus(
      setTimeout(async () => {
        const result = await getServicesByName(target.value)
        setServices(result)
      }, 1000),
    )
  }

  return (
    <BasePage pageTitle="Novo Pacote">
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
        aria-label="disabled tabs example"
      >
        <Tab label="DADOS GERAIS" value="0" />
      </Tabs>

      {parseInt(value, 10) === 0 && (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Nome do pacote"
                    name="nome"
                    inputRef={register}
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Nome do pacote"
                  />
                </Grid>
              </Grid>

              {fields.map((item, index) => (
                <Grid container spacing={3} key={item.id}>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="package"
                      disableClearable
                      required
                      options={services}
                      loading
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <Controller
                          control={control}
                          defaultValue={item.serviceId}
                          render={({ value }) => (
                            <TextField
                              {...params}
                              label="Serviço"
                              value={value}
                              style={{ margin: '0px' }}
                              placeholder="Digite o nome do serviço"
                              margin="normal"
                              variant="outlined"
                              onChange={handleServices}
                              inputRef={register}
                            />
                          )}
                          name={`package[${index}].serviceId`}
                        />
                      )}
                      onChange={(e, data) => {
                        setValue(`package[${index}].serviceId`, data?.id)
                        setValue(`package[${index}].price`, data?.price)
                        const newFormValues = formValues || {}
                        newFormValues[index] = {
                          unitPrice: data.price,
                          price: 0,
                          bonus: false,
                        }
                        setFormValues([...newFormValues])
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="hidden"
                      id="unitPrice"
                      value={item.unitPrice}
                      defaultValue={item.unitPrice}
                      inputRef={register}
                      name={`package[${index}].unitPrice`}
                    />
                    <Controller
                      control={control}
                      defaultValue={item.price}
                      as={({ onChange, value }) => (
                        <TextField
                          id="price"
                          required
                          label="Valor"
                          value={value}
                          disabled
                          variant="outlined"
                          inputRef={register}
                          fullWidth
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            onChange(e.target.value)
                          }}
                        />
                      )}
                      name={`package[${index}].price`}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      control={control}
                      defaultValue={item.qtd}
                      as={({ onChange, value }) => (
                        <TextField
                          id="qtd"
                          required
                          label="Quantidade"
                          value={value}
                          variant="outlined"
                          inputRef={register}
                          fullWidth
                          type="number"
                          onChange={(e) => {
                            const newPrice =
                              parseInt(e.target.value, 10) *
                              formValues[index].unitPrice
                            setValue(`package[${index}].price`, newPrice)
                            onChange(e.target.value)
                          }}
                          onBlur={(e) => {
                            const newPrice =
                              parseInt(e.target.value, 10) *
                              formValues[index].unitPrice

                            const newFormValues = formValues
                            newFormValues[index] = {
                              ...formValues[index],
                              price: newPrice,
                            }
                            setFormValues([...newFormValues])
                          }}
                        />
                      )}
                      name={`package[${index}].qtd`}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel
                      control={
                        <Controller
                          name={`package[${index}].checkbox`}
                          defaultValue={false}
                          control={control}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => {
                                const newFormValues = formValues
                                newFormValues[index] = {
                                  ...formValues[index],
                                  bonus: e.target.checked,
                                }
                                setChange(true)
                                setFormValues(newFormValues)

                                props.onChange(e.target.checked)
                              }}
                            />
                          )}
                        />
                      }
                      label="bônus?"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      type="button"
                      onClick={() => {
                        const newFormValues = formValues.filter(
                          (value, formValueIndex) => formValueIndex !== index,
                        )
                        setFormValues(newFormValues)
                        remove(index)
                      }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                      append({
                        id: '',
                        qtd: '',
                        checkbox: false,
                      })
                    }}
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  >
                    ADICIONAR MAIS SERVIÇOS
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="discount"
                    label="Valor do Desconto"
                    name="discount"
                    inputRef={register}
                    variant="outlined"
                    type="number"
                    fullWidth
                    required
                    onBlur={(e) => {
                      setDesconto(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    select
                    required
                    id="outlined-basic"
                    label="Tipo do desconto"
                    placeholder=""
                    inputRef={register}
                    variant="outlined"
                    defaultValue="fixed_value"
                    name="discountType"
                  >
                    <MenuItem
                      key="by_percent"
                      value="fixed_value"
                      onClick={() => {
                        setTypeDesconto('fixed_value')
                      }}
                    >
                      R$ (valor fixo)
                    </MenuItem>
                    <MenuItem
                      key="by_percent"
                      value="by_percent"
                      onClick={() => {
                        setTypeDesconto('by_percent')
                      }}
                    >
                      Porcentagem (%)
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Resumo
                packages={formValues}
                desconto={desconto}
                type={typeDesconto}
                change={change}
                setChange={setChange}
              />
            </Grid>
            <Divider className={classes.bottom} />

            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={stateButtom}
                style={{
                  background: '#CCD219',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  fontWeight: '700',
                  width: '138px',
                  marginRight: '20px',
                }}
              >
                Salvar
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => redirect()}
                style={{
                  border: '1px solid #CCD219',
                  color: '#CCD219',
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  width: '120px',
                }}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </BasePage>
  )
}

export default NewPackage
