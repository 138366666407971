import { Calculator } from "lucide-react";

import { Input } from "../../../../components/ui/Form/Input";
import { SubscriptionCalculatorController } from "../../../../services/controllers/subscription";
import { useCalculator } from "../../../../store/subscription/useCalculator";
import { formatMoney } from "../../../../services/functions";
import { Skeleton } from "@mui/material";

export function DashboardCalculate(){
  const CLSubscriptionCalc = SubscriptionCalculatorController()
  const results = useCalculator(st=> st.data.results)
  const isLoading = useCalculator(st=> st.data.isLoading)
  const subscriberEstimate = useCalculator(st=> st.data.subscriberEstimate)

  return(
    <div className="w-full h-fit flex flex-col gap-4 flex-[3] min-w-fit pb-2">
      <div className="border rounded-md overflow-hidden flex flex-col flex-1 w-full min-w-fit shadow-md space-y-4 h-full">
        <div className="w-full py-2 px-4 flex">
          <h2 className="font-bold text-lg text-scale-600 packing-wide flex items-center mr-2"><Calculator className="h-6 w-6 mr-2"/>Calculadora</h2>
        </div>
        <form className="w-full py-2 px-4 flex">
          <Input.Root
            money
            noSuffix
            decimals={0}
            label="Número de assinantes"
            value={subscriberEstimate}
            onChange={(subscribers) => CLSubscriptionCalc.setSubscriberEstimate(subscribers)}/>
        </form>
        <div className="flex flex-col gap-2 px-4 pb-4 h-full">
          <p className="flex flex-col w-full text-base">
            <span>Resultado projetado é:</span>
            <span>
              {isLoading && <Skeleton className="w-1/2" />}
              {!isLoading && <strong className="font-bold text-green-700"> R$ {formatMoney(results ? results.result : 0)}</strong>}
            </span>
          </p>
          <div className="flex flex-col w-full mt-4">
            <p className="flex place-items-center text-base border-b border-gray-300 p-2 last:border-0">
              <span className="mr-2">Receita</span>

              {isLoading && <span className="ml-auto w-[100px]"><Skeleton /></span>}
              {!isLoading && <span className="ml-auto min-w-fit">R$ {formatMoney(results? results.revenue : 0)}</span>}
            </p>
            <p className="flex place-items-center text-base border-b border-gray-300 p-2 last:border-0">
              <span className="mr-2">Despesas</span>
              {isLoading && <span className="ml-auto w-[100px]"><Skeleton /></span>}
              {!isLoading &&  <span className="ml-auto min-w-fit">R$ {formatMoney(results ? results.expenses : 0)}</span>}
            </p>
          </div>
          <div className="flex flex-col w-full mt-auto">
            <p className="flex place-items-center text-base border-b border-gray-300 p-2 last:border-0 text-green-700">
              <span className="font-bold mr-2">Resultado</span>
              {isLoading && <span className="ml-auto w-[120px]"><Skeleton /></span>}
              {!isLoading &&  <span className="ml-auto min-w-fit">R$ {formatMoney(results ? results.result : 0)}</span>}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
