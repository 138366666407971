import { SelectGroup } from "./SelectGroup";
import { SelectTitle } from "./SelectTitle";
import { SelectOption } from "./SelectOption";
import { SelectRoot } from "./SelectRoot";
import { SelectSeparator } from "./SelectSeparator";

export const Select = {
  Root: SelectRoot,
  Group: SelectGroup,
  Title: SelectTitle,
  Separator: SelectSeparator,
  Option: SelectOption,
};
