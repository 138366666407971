import { EditorContent } from "@tiptap/react";
import { rgba } from "polished";

import styled from "styled-components";

export const Container = styled.fieldset`
  border-radius: 5px;
  display: flex;
  padding: 0;
  min-width: calc(100% - 2px);
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  position: relative;

  &[disabled] {
    pointer-events: none;
    cursor: default;
    color: ${(props) => props.theme.colors.gray[500]};
    border-color: ${(props) => props.theme.colors.gray[200]};
    background: ${(props) => rgba(props.theme.colors.gray[50], 0.2)};
    background: ${(props) =>
    `linear-gradient(180deg, ${rgba(
      props.theme.colors.gray[50],
      0
    )} 0%, ${rgba(props.theme.colors.gray[100], 0.8)} 100%)`};
  }
`;

export const EditorArea = styled(EditorContent)`
  display: flex;
  width: 100%;
  position: relative;
  padding: 16px 13px;
  .editor-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 200px;
    gap: 5px;
    margin: 0;
    outline: none;
    * {
      margin: 0;
    }
    ul,
    ol {
      margin: 0;
      padding-left: 30px;
    }
  }
`;

export const Space = styled.legend`
  visibility: hidden;
  padding: 0 5px;
  margin-left: 8px;
  width: 100%;
  max-width: fit-content;
  height: 0px;
  font-size: 14px;
  line-height: 1;
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Legend = styled.span`
  position: absolute;
  padding: 0px;
  font-size: 14px;
  top: -10px;
  left: 14px;
  transform: scale(1);
  color: ${(props) => props.theme.colors.gray[500]};
`;

export const Loading = styled.div`
  display: flex;
  width: 100%;
  min-height: 200px;
  margin: 0px;
  pointer-events: none;
`;

