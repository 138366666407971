import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 70px repeat(
      ${(props) => props.totalEmployees},
      ${(props) => props.collumnSize}px
    );
  background: #ffff;
  position: sticky;
  top: 1px;
  z-index: 6;
  transition: all 50ms linear;
  border-top: 1px solid ${(props) => props.theme.colors.primary};
`

export const HeadItem = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.background};
  border-right: 1px solid ${(props) => props.theme.colors.primary};
  &:first-child {
    border-left: 1px solid ${(props) => props.theme.colors.primary};
    width: 71px;
    position: sticky;
    left: 0;
    top: 0;
  }
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.scale[50]};
  }
`
