import API from './api'

export const addOrder = async (date, employeeId, serviceOrders = []) => {
  const order = {
    date,
    startTime: '07:00',
    employeeId,
    serviceOrders,
  }

  const data = await API.post('service-order/appointments/add-order', order)

  return data
}

export const newOrder = async (
  customer,
  date,
  serviceOrders = [],
  sellerId = '',
  recurring,
  description,
  valueCupom,
  setOpenModal,
  setError,
  setStateButtom,
  taxiDogData,
) => {
  const payload = {
    department: 'Banho e Tosa',
    channel: 'LOJA',
    customerId: customer.id,
    petId: customer.pet.id,
    appointmentDate: date,
    sellerId,
    serviceOrders,
    recurring,
    comment: description,
    couponCode: valueCupom,
    taxiDog: {
      driverId: taxiDogData.driverId,
      taxiDogType: taxiDogData.taxiDogType,
      driverPickupType: taxiDogData.driverPickupType,
      useCustomerAddress: taxiDogData.useCustomerAddress,
      total: taxiDogData.total,
      pickupAddress: taxiDogData.pickupAddress,
    },
  }

  try {
    const data = await API.post('service-order/appointments/new', payload)
    if (data) setError(false)

    return data
  } catch (err) {
    setError(true)
    setStateButtom(false)
    setOpenModal(err?.response?.data?.friendly_message)

    return err
  }
}

export const getOrder = async (orderId) => {
  const { data } = await API.get(`service-order/appointment/${orderId}`)

  return data
}

export const dragOrder = async (
  event = {},
  setAlert = null,
  setAlertStatusMessage = '',
  setEvents = null,
) => {
  const { orderId, startsAt, finishesAt, employeeId, roomId } = event

  try {
    const { data, status } = await API.post(`service-order/${orderId}`, {
      startsAt,
      finishesAt,
      employeeId,
      roomId,
    })

    if (status === 201) {
      setAlertStatusMessage('Agendamento alterado com sucesso!')
      setAlert('success')
      localStorage?.removeItem('eventsAgenda')
    }

    return data
  } catch (err) {
    setAlertStatusMessage('Erro ao fazer o agendamento!')
    setAlert('error')
    const backup = localStorage.getItem('eventsAgenda')
    setEvents(JSON.parse(backup))
    console.log('Erro:', err)

    setTimeout(() => {
      localStorage.removeItem('eventsAgenda')
    }, 1500)
  }
}

export const updateStatus = async (
  id,
  status = 'EM ANDAMENTO',
  handleGetOrderUpdate,
  closeModal = null,
) => {
  const payload = { status }
  const result = await API.post(`service-order/${id}/status`, payload)
  const { data } = result

  if (result?.status === 201) {
    handleGetOrderUpdate && handleGetOrderUpdate()
    closeModal && closeModal()
  }
  return data
}

export const addItemOrder = async (orderId, services) => {
  const payload = {
    department: 'Banho e Tosa',
    services,
  }

  const data = await API.post(
    `service-order/appointment/${orderId}/add-service`,
    payload,
  )

  return data
}
