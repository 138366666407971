import { rgba } from 'polished'
import styled from 'styled-components'

export const VaccineStatus = styled.em`
  background: ${(props) => props.theme.colors.gray[700]};
  color: ${(props) => props.theme.colors.background};
  font-style: normal;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 10px;
  border-radius: 21px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
  opacity: 0.6;
  &.expired {
    background: ${(props) => props.theme.colors.error};
  }
  &.scheduled {
    background: ${(props) => props.theme.colors.scheduleSteps.scheduledVaccine};
  }
  &.finished {
    background: ${(props) => props.theme.colors.scheduleSteps.checkout};
  }
  &.interrupted {
    background: ${(props) => props.theme.colors.gray[500]};
  }
`
export const VaccineDate = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 13px;
  text-align: right;
`

export const VaccineName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.scale[700]};
`
export const VaccineDescription = styled.p`
  margin: 0;
  font-size: 14px;
  span {
    font-size: 11px;
    letter-spacing: 0.025rem;
  }
`

export const Container = styled.li`
  list-style: none;
  overflow: hidden;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  padding: 0 40px 0 0;
  cursor: pointer;
  transition: all 250ms ease;
  border-radius: 8px 0 0 8px;
  border: 1px solid transparent;
  position: relative;
  transform: translateX(1px);

  &:last-child {
    margin-bottom: 0;
  }

  &:hover:not(.active) {
    width: calc(100% + 10px);
    padding: 0 50px 0 0;
    background: ${(props) => rgba(props.theme.colors.scale[300], 0.3)};
    ${VaccineStatus} {
      opacity: 1;
    }
  }

  & > div {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr;
    padding: 5px 10px;
  }
  &.active {
    z-index: 2;
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-right-color: transparent;
    background: ${(props) => props.theme.colors.background};
    ${VaccineStatus} {
      opacity: 1;
    }
  }
`
