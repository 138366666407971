import { ItemContentLabel } from "./ItemContentLabel";
import { ItemContentStyle } from "./ItemContentStyle";

export function ItemContent({ content, label = '', ...rest }) {
  if (!content) return null

  if (typeof content !== "string") return <ItemContentStyle {...rest} label={label} content={content} />

  return <ItemContentLabel  {...rest} content={content} label={label} />;

}
