import { createContext, useContext as useReactContext } from "react";

const ListPreviewContext = createContext({});

function ListPreviewProvider({ children, ...rest }) {
  return (
    <ListPreviewContext.Provider value={rest}>
      {children}
    </ListPreviewContext.Provider>
  );
}

function useContext() {
  const context = useReactContext(ListPreviewContext);
  if (!context) {
    throw new Error("use must be used within an Provider");
  }
  return context;
}

export { useContext, ListPreviewProvider };
