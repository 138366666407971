import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0%{
    opacity:1;
    transform: scale(1);
  }
  100%{
    opacity: 0;
    transform: scale(2);
  }
`

export const Container = styled.p`
  display: flex;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding-left: 12px;
  width: 100%;
  height: 36px;
`

export const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: currentColor;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: center center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: currentColor;
    animation: ${pulse} 1s infinite;
  }
`
