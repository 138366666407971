
import { PDFViewer, pdf } from '@react-pdf/renderer';

import { downloadBlob } from '../../services/functions';

async function downloadDocument(Document, fileName) {
  const blob = await pdf(<Document />).toBlob();
  downloadBlob(blob, fileName)
};

async function getDocumentBlob(Document) {
  const blob = await pdf(<Document />).toBlob();
  return blob
};

const getUploadFormData = async (Document, fileName, field = "file_name") => {
  const pdfBlob = await getDocumentBlob(Document)

  const formData = new FormData();

  formData.append("file", pdfBlob);
  formData.append(field, fileName);

  return formData
};

function viewDocument(Document, { style }) {
  return (
    <PDFViewer style={style}>
      <Document />
    </PDFViewer>
  );
}

export const PDFFileType = (document) => ({
  download: (...args) => downloadDocument(document, ...args),
  getBlob: (...args) => getDocumentBlob(document, ...args),
  getFormData: (...args) => getUploadFormData(document, ...args),
  view: (...args) => viewDocument(document, ...args)
})
