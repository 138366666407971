import { View, Text } from '@react-pdf/renderer';
import { useUser } from '../../../../store/global/useUser';


import { container, electronicSignature,  doctorName, doctorInfo, doctorId, doctorIdType} from './styles';

export function FooterAss() {
  const user = useUser.getState().data.user

  return (
    <View style={container}>
      <Text style={electronicSignature}>Assinado eletronicamente por</Text>
      <View style={doctorInfo}>
        <Text style={doctorName}>{user.name.full} -</Text>
        <View style={doctorInfo}>
          <Text style={doctorIdType}>CRMV</Text>
          <Text style={doctorId}>{ user.crmv || '-' }</Text>
        </View>
      </View>
    </View>
  );
}
