import { Container } from "./styles";
import { useTable } from "./useTable";
import { TableProvider } from "./TableContext";

export function TableRoot({
  children,
  config,
  action,
  customActions,
  name,
  ...rest
}) {
  const setTable = useTable((st) => st.actions.setTable);

  if (!children) return null;

  if (name) setTable({ name, config, action, customActions });

  return (
    <TableProvider tableName={name}>
      <Container config={!!config} {...rest}>
        {!name && "De um nome para a tabela!"}
        {name && children}
      </Container>
    </TableProvider>
  );
}
