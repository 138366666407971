import { isAbleByStatus } from "../../../../../services/functions";

import { useCare } from "../../../../../store/service/veterinary/useCare";
import { useRecord } from "../../../../../store/service/veterinary/useRecord";

import { Input } from "../../../../../components/ui/Form/Input";

import { Container } from "./styles";
import { VeterinaryRecordController } from "../../../../../services/controllers/VeterinaryCare";

export function RecordsContentsBodyExamination() {
  const careStatus = useCare(st => st.data.care.status)

  const chest = useRecord((st) => st.data.record?.chest);
  const abdomen = useRecord((st) => st.data.record?.abdomen);
  const thoracicLimbs = useRecord((st) => st.data.record?.thoracicLimbs);
  const pelvicLimbs = useRecord((st) => st.data.record?.pelvicLimbs);

  const CLVetRecord = VeterinaryRecordController();

  const isAble = isAbleByStatus(careStatus,CLVetRecord._name)

  return (
    <Container>
      <Input.Root
        label="Tórax"
        value={chest}
        disabled={!isAble}
        onChange={(chest) => CLVetRecord.changeRecordValue({ chest })}
        onBlur={CLVetRecord.saveBodyExamination}
      />
      <Input.Root
        label="Abdome"
        value={abdomen}
        disabled={!isAble}
        onChange={(abdomen) => CLVetRecord.changeRecordValue({ abdomen })}
        onBlur={CLVetRecord.saveBodyExamination}
      />
      <Input.Root
        label="Membros Torácicos"
        value={thoracicLimbs}
        disabled={!isAble}
        onChange={(thoracicLimbs) =>
          CLVetRecord.changeRecordValue({ thoracicLimbs })
        }
        onBlur={CLVetRecord.saveBodyExamination}
      />
      <Input.Root
        value={pelvicLimbs}
        label="Membros Pélvicos"
        disabled={!isAble}
        onChange={(pelvicLimbs) =>
          CLVetRecord.changeRecordValue({ pelvicLimbs })
        }
        onBlur={CLVetRecord.saveBodyExamination}
      />
    </Container>
  );
}
