import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 12px;
  padding-left: 24px;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    height: 90%;
    width: 1px;
    transform: translateY(-50%);
    background: linear-gradient(transparent, rgb(145, 179, 244), transparent);
    transition: all 250ms ease-in-out 0s;
  }
`

export const Title = styled.h2`
  color: ${props => props.theme.colors.scale[500]};
  margin: 0;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  p{
    margin: 0;
    color: ${props => props.theme.colors.gray[600]};
    strong{
      color: ${props => props.theme.colors.scale[600]};
    }
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  &>*{
    min-width: fit-content;
    flex: 1;
  }
`

export const FormActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  & > * {
    width: fit-content;
  }
  & > div{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    gap: 16px;
  }
`;
