import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const termBodyDonationConsent = {
  name: "Termo de Consentimento para Doação de Corpo",
  title:
    "Termo de Consentimento Livre e Esclarecido de Doação de Corpo de Animal para Fins de Ensino e Pesquisa",
  gender: "male",
  fields: {
    doctorNote: {
      label: "Observações do(a) Médico(a)-Veterinário(a)",
      type: "textarea",
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
    tutorNote: {
      label: "Observações do tutor(a)/proprietário(a)/responsável",
      type: "textarea",
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
  },

  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },

  content: `
    <p>Declaro o livre consentimento sobre a doação do corpo do animal identificado neste documento.</p>
    <p>Declaro, ainda, ter sido esclarecido(a) acerca da destinação do corpo para fins de estudo e pesquisa.</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}

    {{#doctorNote}}
      <br />
      <br />
      <p><strong>Observações do(a) Médico(a)-Veterinário(a):</strong></p>
      <p>{{{.}}}</p>
    {{/doctorNote}}
    {{#tutorNote}}
      <br />
      <br />
      <p><strong>Observações do tutor(a)/proprietário(a)/responsável:</strong></p>
      <p>{{{.}}}</p>
    {{/tutorNote}}
  `.trim(),
};
