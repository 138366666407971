import { splitName } from "../../../../../../../services/functions"
import { usePrinter } from "../../../../../../../store/schedule/usePrinter"

export function HeaderCustomer() {
  const customer = usePrinter(st => st.data.customer)
  if (!customer) return null

  const { firstname } = splitName(customer?.name || '')

  return (
    <div className='w-full mb-4 flex items-center gap-2 px-2'>
      <span className="text-2xl font-semibold flex-1 w-full">Olá {firstname}</span>
      <img src="/img/print/print-logo.png" className='w-24' />
    </div>
  )
}
