import Balancer from 'react-wrap-balancer'

import { useState } from 'react'
import {
  Container,
  Trigger,
  Portal,
  Overlay,
  Content,
  Title,
  Description,
  Cancel,
  Action,
  ButtonBox,
} from './styles'

export function Confirmation({
  children,
  title,
  style,
  description,
  cancelText,
  acceptText,
  cancelAction,
  acceptAction,
  buttonDirection,
}) {
  const [open, setOpen] = useState(false)

  return (
    <Container open={open} onOpenChange={setOpen}>
      <Trigger asChild>{children}</Trigger>
      <Portal>
        <Overlay />
        <Content style={style}>
          <Title>{title}</Title>
          <Description>
            <Balancer>{description}</Balancer>
          </Description>
          <ButtonBox style={{ flexDirection: buttonDirection || 'row' }}>
            <Cancel asChild>
              <button
                onClick={() => {
                  !!cancelAction && cancelAction()
                  setOpen(false)
                }}
              >
                {cancelText}
              </button>
            </Cancel>
            <Action asChild>
              <button
                onClick={() => {
                  !!acceptAction && acceptAction()
                  setOpen(false)
                }}
              >
                {acceptText}
              </button>
            </Action>
          </ButtonBox>
        </Content>
      </Portal>
    </Container>
  )
}
