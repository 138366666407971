import React from 'react'

import AgendamentoVeterinario from '../components/organisms/AgendamentoVeterinario'

function AgendamentoVeterinarioPage({ location }) {
  const { state } = location

  return <AgendamentoVeterinario {...state} />
}

export default AgendamentoVeterinarioPage
