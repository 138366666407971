import { View, Image } from '@react-pdf/renderer';

import { getDepartmentName } from '../../../services/functions';

import { container, mark } from './styles';

export function Watermark() {

  const department = getDepartmentName()
  const watermark = `/img/${department}/watermark-dark.png`



  return (
    <View style={container} fixed>
      <Image src={watermark} style={mark} />
    </View>
  );
}
