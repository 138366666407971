import styled from 'styled-components'

export const Container = styled.div`
  font-size: 14px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[300]};
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(20px * ${(props) => props.totalGaps});
  &:last-child {
    border: none;
  }
`
