import { Text, View } from '@react-pdf/renderer';
import moment from "moment";

import { formatName } from "../../../../services/functions";

import { useMerchant } from "../../../../store/global/useMerchant";

import { container, date } from './styles';

export function FooterDate() {
  const storeCity = useMerchant.getState().data.merchant.location.city

  const todayMoment = moment()

  const day = todayMoment.format("DD");
  const month = formatName(todayMoment.format("MMMM"));
  const year = todayMoment.format("YYYY");

  const today = `${day} de ${month} de ${year}`;

  return (
    <View style={container}>
      <Text style={date} >{storeCity}, {today}</Text>
    </View>
  );
}
