import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    overflowY: 'auto',
    width: '100%',
  },
  item: {
    background: '#F6F6F6',
    borderRadius: '7px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '46px 20px 16px 20px',
    margin: '16px 0',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
  buttonEdit: {
    position: 'absolute',
    right: '0',
    border: 'none',
    margin: '0px 78px 0 0',
    background: 'transparent',
    color: '#666666',
    cursor: 'pointer',
  },
  drawerBox: {
    maxWidth: '1020px',
    overflowX: 'hidden',
    padding: '0 40px',
    height: '100%',
  },
  buttonArrow: {
    right: '30px',
    width: '20px',
    border: 'none',
    height: '20px',
    margin: '0',
    position: 'absolute',
    transform: 'translate(0, -15%) rotate(45deg)',
    borderRight: '3px solid #666666',
    borderBottom: '3px solid #666666',
    background: 'transparent',
    transition: 'all 150ms linear',
    cursor: 'pointer',
  },
  buttonArrowOpen: {
    right: '30px',
    width: '20px',
    border: 'none',
    height: '20px',
    margin: '0',
    position: 'absolute',
    transform: 'translate(0, -15%) rotate(-45deg)',
    borderRight: '3px solid #666666',
    borderBottom: '3px solid #666666',
    background: 'transparent',
    transition: 'all 150ms linear',
    cursor: 'pointer',
  },
  itemOpen: {
    background: '#F6F6F6',
    borderRadius: '7px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    padding: '46px 20px 16px 20px',
    margin: '16px 0',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
  badge: {
    color: '#fff',
    background: '#00A1A4',
    position: 'absolute',
    width: '100%',
    height: ' 35px',
    left: '0',
    top: '0',
    padding: '4px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    transition: 'all 250ms  linear',
  },
  badgeContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '13px',
    gap: '10px',
  },
  pet: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 24px 0 0',
  },
  petName: {
    margin: '0',
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  avatar: {
    width: '60px',
    textDecoration: 'none',
    height: '60px',
    borderRadius: '50%',
    background: '#CCD219',
    margin: '0 12px',
    border: '3px solid #ffffff',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    overflow: 'hidden',
  },
  infoList: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoTitle: {
    margin: '0 0 6px 0',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '13px',
    color: '#666666',
  },
  info: {
    margin: '0 0 12px 0 ',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '13px',
    color: '#666666',
  },
  content: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: '50px',
    transition: 'all 250ms linear',
    marginTop: '30px',
  },
  contentOpen: {
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: '100vh',
    transition: 'all 250ms linear',
    marginTop: '20px',
  },
  contentTitle: {
    margin: 0,
  },
  more: {
    width: '100%',
    margin: '10px 0 0 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center-start',
    color: '#00567E',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  contentText: {
    margin: '0 0 10px 0',
  },
  contentLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '50px',
  },
  tabela: {
    width: '70%',
    margin: '38px 0',
    padding: '0 24px ',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0E0',
  },
  linhaHeader: {
    borderBottom: '1px solid #E0E0E0',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.56)',
    textAlign: 'start',
    padding: '12px',
  },
  linha: {
    borderBottom: '1px solid #E0E0E0',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '24px',
    color: '#000000',
    padding: '12px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
    gap: '10px',
  },
  cardButton: {
    background: '#00567E',
    borderRadius: '3px',
    border: 'none',
    color: '#fff',
    padding: '5px 10px',
    cursor: 'pointer',
  },
}))
