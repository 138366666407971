import { Children, cloneElement } from 'react'
import { Step as Container } from './styles'

export function StepsStep({ children, name, status }) {
  let stepStatus = 'inactive'

  const childrenProps = Children.map(children, (child) =>
    cloneElement(child, { name, status }),
  )

  switch (status) {
    case 0:
      stepStatus = 'inactive'
      break
    case 1:
      stepStatus = 'active'
      break
    case 2:
      stepStatus = 'complete'
      break
    default:
      stepStatus = 'inactive'
      break
  }

  return <Container className={stepStatus}>{childrenProps}</Container>
}
