import styled, { css } from 'styled-components'
import { darken, lighten, rgba } from 'polished'
import InputMask from 'react-input-mask'

export const Container = styled.div`
  width: 100%;
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  transition: all 250ms ease;
  border: 1px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.background};
  position: relative;
  z-index: 1;
  transition: all 500ms;
  &.first {
    border-radius: 0px 8px 8px 8px;
  }
  &.last {
    border-radius: 8px 8px 8px 0px;
  }
`

export const ProtocoloHistoricTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 10px 0;
  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    margin: 0;
    color: ${(props) => props.theme.colors.scale[700]};
    span {
      font-size: 13px;
      line-height: 16px;
      color: ${(props) => props.theme.colors.gray[600]};
      font-weight: normal;
      margin-left: 12px;
    }
  }
`

export const ProtocolButtonBox = styled.div`
  display: flex;
  gap: 10px;
`

export const ProtocolActionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  line-height: 0;
  padding: 4px 10px 4px 8px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.gray[600]};
  background: ${(props) => props.theme.colors.gray[300]};
  color: ${(props) => props.theme.colors.gray[900]};
  transition: all 150ms ease;
  cursor: pointer;
  svg {
    font-size: 15px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.gray[600]};
    &:hover {
      background: ${(props) => lighten(0.1, props.theme.colors.gray[300])};
    }
  }
  &:active {
    transform: scale(0.97);
  }

  &.base {
    background: ${(props) => props.theme.colors.scale[400]};
    border-color: ${(props) => props.theme.colors.scale[600]};
    color: ${(props) => props.theme.colors.background};
    &:hover {
      background: ${(props) => darken(0.1, props.theme.colors.scale[500])};
    }
  }
  &.error {
    background: ${(props) => props.theme.colors.error};
    border-color: ${(props) => darken(0.1, props.theme.colors.error)};
    color: ${(props) => props.theme.colors.background};
    &:hover {
      background: ${(props) => lighten(0.1, props.theme.colors.error)};
    }
  }
  &.success {
    background: ${(props) => props.theme.colors.success};
    border-color: ${(props) => darken(0.2, props.theme.colors.success)};
    color: ${(props) => props.theme.colors.background};
    &:hover {
      background: ${(props) => darken(0.2, props.theme.colors.success)};
    }
  }
`

export const ProtocoloHistoricListHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 150px 70px;
`

export const ProtocoloHistoricListHeaderItem = styled.span`
  font-weight: normal;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  font-size: 14px;
  padding: 10px 5px;
  transition: all 150ms ease;
  &:hover {
    background: ${(props) => props.theme.colors.scale[50]};
  }
`

export const ProtocoloHistoricListContent = styled(ProtocoloHistoricListHeader)`
  transition: all 1000ms ease;
  border-top: 1px solid ${(props) => props.theme.colors.primary};
  font-weight: bold;
  height: 51px;
  &.applied {
    background: ${(props) => rgba(props.theme.colors.success, 0.05)};
    color: ${(props) => props.theme.colors.success};
  }

  &.scheduled,
  &.remove {
    color: ${(props) => props.theme.colors.gray[700]};
    span.input {
      opacity: 0.4;
    }
    ${(props) =>
      props.expired &&
      css`
        background: ${(props) => rgba(props.theme.colors.error, 0.05)};
        color: ${(props) => props.theme.colors.error};
      `}
  }
`

export const ProtocoloHistoricList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${(props) =>
    props.interrupted &&
    css`
      ${ProtocoloHistoricListContent} {
        &.scheduled,
        &.expired {
          opacity: 0.4;
          background: ${(props) => rgba(props.theme.colors.gray[900], 0.05)};
          color: ${(props) => props.theme.colors.gray[700]};
          font-weight: bold;
        }
      }
    `}
`

export const ProtocoloHistoricListContentItem = styled.span`
  font-size: 13px;
  padding: 0px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  &.center {
    justify-content: center;
  }
`

const inputStyle = css`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 10px 14px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray[400]};
  outline: none;
  transition: all 250ms ease;
  &:focus {
    border-color: ${(props) => props.theme.colors.scale[500]};
    outline: 2px solid ${(props) => props.theme.colors.scale[400]};
  }
`
export const ProtocoloHistoricEditInput = styled.input`
  ${inputStyle}
`

export const ProtocoloHistoricEditInputMask = styled(InputMask)`
  ${inputStyle}
`

export const Button = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: ${(props) => props.theme.colors.primary};
  height: 30px;
  transition: all 250ms ease;
  background: none;
  border: 1px solid transparent;
  &:hover {
    background: ${(props) => props.theme.colors.scale[50]};
    border-color: ${(props) => props.theme.colors.primary};
  }

  &.confirm {
    color: ${(props) => darken(0.3, props.theme.colors.success)};
    &:hover {
      background: ${(props) => rgba(props.theme.colors.success, 0.3)};
      border-color: ${(props) => darken(0.1, props.theme.colors.success)};
    }
  }
  &.error {
    color: ${(props) => darken(0.3, props.theme.colors.error)};
    &:hover {
      background: ${(props) => rgba(props.theme.colors.error, 0.3)};
      border-color: ${(props) => darken(0.1, props.theme.colors.error)};
    }
  }

  svg {
    font-size: 20px;
  }
`
