import { useSubscribers } from "../../../../../../store/subscription/useSubscribers"

import { ChangePlanActionsChange } from "./Change"
import { ChangePlanActionsCurrent } from "./Current"

export function ChangePlanActions(props){
  const details = useSubscribers(st=>st.data.details)
  if(!details) return null

  if(props.plan.id !== details.plan.id) return <ChangePlanActionsChange {...props} />
  if(props.plan.id === details.plan.id) return <ChangePlanActionsCurrent {...props} />
}
