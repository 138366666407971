import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useMerchant } from "../../../../store/global/useMerchant";
import { useCallback, useEffect, useState } from "react";
import API from '../../../../services/api'
import { dateFormat, formatMoney } from "../../../../services/functions";
import _ from "lodash";
import { usePrinter } from "../../../../store/schedule/usePrinter";

export function AccordionCashback(props) {
  const cpf = props.cpf || props.customer.cpf
  const [cashback, setCashback] = useState(false)
  const merchant = useMerchant(st => st.data.merchant)

  const getCashback = useCallback(async () => {
    const { data } = await API.get(`/crm/cliente/cashback?storeid=${merchant.id}&cpf=${cpf}`)

    usePrinter.getState().actions.setCustomer({ cashback: data.cashback })
    setCashback(data.cashback)
  }, [])

  useEffect(() => { getCashback() }, [])

  if (!cashback || !cashback.status) return null

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Cashback: <strong>R$ {formatMoney(cashback?.total_bonus)}</strong></Typography>
        <span className="ml-auto bg-scale-500 mr-2 rounded-md leading-none px-2 py-1 h-6 text-white flex items-center justify-center">novo</span>
      </AccordionSummary>
      <AccordionDetails>
        <ul className="flex flex-col w-full">
          {cashback?.bonus_list.map((item) => (
            <li key={item.expiration_on} class="flex w-full items-end gap-3 border-b-2 border-zinc-100 px-2 py-4 first:pt-0 last:pb-0 last:border-none">
              <div className="flex flex-col w-full flex-1">
                <span><strong>Validade: </strong>{dateFormat(item?.expiration_on)}</span>
                <span><strong>Compra mínima: </strong>R$ {formatMoney(item?.balance * 2)}</span>
              </div>
              <span><strong>R$ {formatMoney(item?.balance)}</strong></span>
            </li>
          ))}

        </ul>
      </AccordionDetails>
    </Accordion>

  )
}
