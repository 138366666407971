import React from 'react'
import Grid from '@material-ui/core/Grid'
import { usePets } from '../../../../providers/pets'

import styles from './styles'

function PetLIst({ setSelectPet, selectPet }) {
  const { petList } = usePets()

  const { grid, pet, petImage } = styles()

  return (
    <Grid item xs={12} className={grid}>
      {petList &&
        petList.map(({ name, _id, petImg }) => {
          const found = selectPet.find((el) => el === _id)
          return (
            <button
              key={_id}
              onClick={() => {
                if (found) {
                  const newDepartment = selectPet.filter(
                    (value) => value !== _id,
                  )
                  setSelectPet(newDepartment)
                } else {
                  setSelectPet([...selectPet, _id])
                }
              }}
              className={pet}
              style={{ borderColor: found ? '#cf5d09' : '#ffffff' }}
            >
              {petImg && <img src={petImg} alt={name} className={petImage} />}
              {!petImg && name.charAt(0)}
            </button>
          )
        })}
    </Grid>
  )
}

export default PetLIst
