import { toast } from 'react-toastify'
import { useSchedule } from '../../store/schedule/useSchedule'
import API from '../api'

export class Merchant {
  setSellers = useSchedule((st) => st.actions.setSellers)

  async findSellers() {
    try {
      const { data } = await API.get('/employees/sellers/search')

      this.setSellers(data.results)
      return data.results
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: 'colored' })
      return null
    }
  }
}
