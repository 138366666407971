import { TableContent as Container } from "./styles";
import { TableLoading } from "./TableLoading";

export function TableContent({
  children,
  isLoading = false,
  loadingLine = 10,
  ...rest
}) {
  return (
    <Container {...rest}>
      {!isLoading && children}
      {isLoading && <TableLoading lines={loadingLine} />}
    </Container>
  );
}
