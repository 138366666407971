import React from 'react'

import { Controller, useForm } from 'react-hook-form'

// Material ui
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import SettingsIcon from '@mui/icons-material/Edit'
import Skeleton from '@mui/material/Skeleton'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import InputAdornment from '@mui/material/InputAdornment'
import Alert from '@mui/material/Alert'
import { Snackbar } from '@material-ui/core'
import Chip from '@mui/material/Chip'
import MenuItem from '@material-ui/core/MenuItem'

import { styled } from '@mui/material/styles'
import moment from 'moment'
import DrawerType from '../../../atoms/Drawer/index'

// Style
import { useStyles } from '../styles'

// moment
import API from '../../../../services/api'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(204, 210, 25)',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const possibleStatus = [
  'TODOS OS STATUS',
  'AGENDADO',
  'CANCELADO',
  'EM ANDAMENTO',
  'FINALIZADO',
  'ORÇAMENTO ENVIADO',
]

function Steps4({
  employeeId,
  handleStep,
  firstDay,
  lastDay,
  setFirstDay,
  setLastDay,
}) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [drowerOpen, setDrowerOpen] = React.useState(false)
  const [employees, setEmployees] = React.useState([])
  const [employeesDrawer, setEmployeesDrawer] = React.useState(false)
  const [serviceId, setServiceId] = React.useState(false)
  const [message, setMessage] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(false)
  const [disableButton, setDisableButton] = React.useState(false)
  const { control } = useForm()
  const [filterStatus, setFilterStatus] = React.useState('ORÇAMENTO ENVIADO')

  const handleStatus = (value) => {
    setFilterStatus(value)
  }

  const getService = async () => {
    try {
      let url = `employees/commissions/report-by-employee/${employeeId}?fromDate=${moment(
        firstDay,
      ).format('YYYY-MM-DD')}&toDate=${moment(lastDay).format('YYYY-MM-DD')}`
      if (filterStatus !== 'TODOS OS STATUS') {
        url = `employees/commissions/report-by-employee/${employeeId}?status=${filterStatus}&fromDate=${moment(
          firstDay,
        ).format('YYYY-MM-DD')}&toDate=${moment(lastDay).format('YYYY-MM-DD')}`
      }

      const { data } = await API.get(url)
      setEmployees(data)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleComisao = async (valor) => {
    const divisao = parseInt(valor, 10) / 100
    try {
      const result = await API.post(
        `employees/commissions/update-commission-by-service-order/${serviceId}`,
        {
          newCommissionPercentage: divisao,
        },
      )
      if (result?.status === 200) getService()
      if (result?.status === 201 || result?.status === 200) {
        setMessage(result?.data?.message)
        setDisableButton(false)
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message)
      setMessage('error')
      setDisableButton(false)
      console.log('Erro:', err)
    }
  }

  const handleBaseComisao = async (valor) => {
    try {
      const result = await API.post(
        `employees/commissions/update-commission-base-by-service-order/${serviceId}`,
        {
          newCommissionBase: valor,
        },
      )
      if (result?.status === 200) getService()
      if (result?.status === 201 || result?.status === 200) {
        setMessage(result?.data?.message)
        setDisableButton(false)
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message)
      setMessage('error')
      setDisableButton(false)
      console.log('Erro:', err)
    }
  }
  const getDrawer = async (id) => {
    setServiceId(id)
    try {
      const { data } = await API.get(
        `employees/commissions/details-by-service-order/${id}`,
      )
      setEmployeesDrawer(data)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleSubimit = async () => {
    if (employees?.report?.length > 0) {
      if (
        employees?.report?.find((item) => item.status !== 'ORÇAMENTO ENVIADO')
      ) {
        setErrMsg(
          'Só é possível fechar comissão com status ORÇAMENTO ENVIADO. Por favor, redefina o filtro de status.',
        )
        setMessage('error')
        return
      }
    }

    setDisableButton(true)
    const filterId = employees?.report?.map((item) => item.serviceOrderId)

    try {
      const result = await API.post(
        `employees/commissions/close-by-employee/${employeeId}`,
        { serviceOrderIds: filterId },
      )
      if (result?.status === 200) getService()
      if (result?.status === 201 || result?.status === 200) {
        setMessage(result?.data?.message)
        setDisableButton(false)
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message)
      setMessage('error')
      setDisableButton(false)
      console.log('Erro:', err)
    }
  }

  const filterData = async () => {
    if (firstDay && lastDay) {
      await getService()
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (employeesDrawer) setDrowerOpen(true)
  }, [employeesDrawer])

  React.useEffect(() => {
    const start = async () => {
      await getService()
      setLoading(false)
    }
    if (employeeId) {
      start()
    }
  }, [employeeId])

  return (
    <>
      <DrawerType
        open={drowerOpen}
        setDrowerOpen={setDrowerOpen}
        titleHeader="Editar comissão da venda"
        content={
          <div style={{ padding: '30px', width: '600px' }}>
            <Typography
              variant="h6"
              component="h3"
              className={classes.titleDrawer}
            >
              <span>
                Agendamento{' '}
                {employeesDrawer?.appointmentData?.appointmentOrderId}
              </span>
              {employeesDrawer?.isCommissionClosed ? (
                <span
                  style={{
                    float: 'right',
                    fontWeight: '300',
                    fontSize: '14px',
                    background: '#C64D21',
                    padding: '8px',
                    color: '#fff',
                    borderRadius: '25px',
                  }}
                >
                  Comissão Fechada
                </span>
              ) : (
                <span
                  style={{
                    float: 'right',
                    fontWeight: '300',
                    fontSize: '14px',
                    background: '#CCD219',
                    padding: '8px',
                    color: '#fff',
                    borderRadius: '25px',
                  }}
                >
                  Comissão Aberta
                </span>
              )}
            </Typography>
            <Typography
              variant="h6"
              component="h4"
              className={classes.subTitleDrawer}
            >
              {employeesDrawer?.appointmentData?.serviceName}
            </Typography>
            {employeesDrawer?.appointmentData?.isPackage && (
              <Typography
                variant="h6"
                component="h4"
                className={classes.subTitleDrawer}
              >
                Serviço de Pacote
              </Typography>
            )}
            <Typography
              variant="h6"
              component="h4"
              className={classes.subTitleDrawer}
            >
              {employeesDrawer?.appointmentData?.appointmentDate}
            </Typography>
            <Typography
              variant="h6"
              component="h4"
              className={classes.subTitleDrawer}
            >
              Cliente: {employeesDrawer?.appointmentData?.customerName}
            </Typography>
            <Typography
              variant="h6"
              component="h4"
              className={classes.subTitleDrawer}
            >
              Pet: {employeesDrawer?.appointmentData?.petName}
            </Typography>

            <Typography
              variant="h6"
              component="h3"
              className={classes.titleDrawer}
              style={{ marginTop: '30px' }}
            >
              Cálculo de comissão
            </Typography>
            <Typography
              variant="h6"
              component="h4"
              className={classes.subTitleDrawer}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                marginLeft: '10px',
              }}
            >
              Base de cálculo:{' '}
              {employeesDrawer?.isCommissionClosed ? (
                <>{employeesDrawer?.commissionData?.commissionBasisNumber}</>
              ) : (
                <TextField
                  type="number"
                  className={classes.accordionNumber}
                  defaultValue={
                    employeesDrawer?.commissionData?.commissionBasisNumber
                  }
                  onBlur={({ target }) => handleBaseComisao(target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              )}
            </Typography>

            <Typography
              variant="h6"
              component="h4"
              className={classes.subTitleDrawer}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                marginLeft: '10px',
              }}
            >
              Comissão:{' '}
              {employeesDrawer?.isCommissionClosed ? (
                <>{employeesDrawer?.commissionData?.commissionPercentage}</>
              ) : (
                <TextField
                  type="number"
                  className={classes.accordionNumber}
                  defaultValue={
                    employeesDrawer?.commissionData?.commissionPercentage ===
                    '0%'
                      ? 0
                      : employeesDrawer?.commissionData?.commissionPercentage *
                        100
                  }
                  onBlur={({ target }) => handleComisao(target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              )}
            </Typography>
            <Typography
              variant="h6"
              component="h4"
              className={classes.subTitleDrawer}
            >
              Valor da comissão:{' '}
              {employeesDrawer?.commissionData?.commssionValue}
            </Typography>
          </div>
        }
      />

      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Tooltip title="Voltar para o passo anterior">
            <Button
              color="primary"
              style={{
                fontSize: '13px',
                color: '#ccd219',
                border: '1px solid',
                backgroundColor: '#fff',
                padding: '6px',
                marginRight: '22px',
              }}
              onClick={() => handleStep(2)}
            >
              Voltar
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" className={classes.header}>
            {employees?.employeeName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.filterStep}>
        {firstDay && (
          <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              style={{ marginRight: '20px' }}
              id="inicio"
              label="Inicio"
              type="date"
              defaultValue={moment(firstDay).format('YYYY-MM-DD')}
              onChange={({ target }) => setFirstDay(target.value)}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span style={{ marginRight: '20px' }}> até </span>
            <TextField
              id="fim"
              label="Fim"
              type="date"
              defaultValue={moment(lastDay).format('YYYY-MM-DD')}
              onChange={({ target }) => setLastDay(target.value)}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <Controller
            control={control}
            defaultValue="ORÇAMENTO ENVIADO"
            name="status"
            as={({ onChange, value }) => (
              <TextField
                fullWidth
                className={classes.status}
                select
                value={value}
                label="Status"
                variant="outlined"
                defaultChecked="ORÇAMENTO ENVIADO"
                onChange={(e) => {
                  onChange(e.target.value)
                  handleStatus(e.target.value)
                }}
              >
                {possibleStatus.map((possibleStatus) => (
                  <MenuItem key={possibleStatus} value={possibleStatus}>
                    {possibleStatus}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: '100%' }}
          >
            <Box justifyContent="flex-end">
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => {
                  setLoading(true)
                  filterData()
                }}
                color="primary"
                style={{
                  background: '#CCD219',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  fontWeight: '700',
                  width: '138px',
                  marginRight: '20px',
                }}
              >
                Filtrar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Data da venda</StyledTableCell>
              <StyledTableCell align="center">Agendamento</StyledTableCell>
              <StyledTableCell align="center">Serviço</StyledTableCell>
              <StyledTableCell align="center" />
              <StyledTableCell align="center">Baixa</StyledTableCell>
              <StyledTableCell align="center">Base</StyledTableCell>
              <StyledTableCell align="center">Percentual</StyledTableCell>
              <StyledTableCell align="center">Comissão</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              employees?.report?.map((item) => (
                <StyledTableRow
                  key={item.serviceOrderId}
                  onClick={() => getDrawer(item.serviceOrderId)}
                  style={{ cursor: 'pointer' }}
                >
                  <StyledTableCell component="th" scope="row">
                    {item.appointmentDate}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.appointmentId}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.serviceName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.changedValue && (
                      <Chip
                        label={item.changedValue}
                        variant="error"
                        color="error"
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.closedAt ? item.closedAt : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.commissionBase}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.commissionPercentage}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.commissionValue}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {!item.closedAt ? (
                      <span
                        style={{
                          background: '#ccd219',
                          padding: '5px 14px',
                          borderRadius: '8px',
                          color: '#fff',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          textTransform: 'lowercase',
                        }}
                      >
                        ABERTA
                      </span>
                    ) : (
                      <span
                        style={{
                          background: '#c64d21',
                          padding: '5px 14px',
                          borderRadius: '8px',
                          color: '#fff',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          textTransform: 'lowercase',
                        }}
                      >
                        FECHADA
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: '#ccd219' }}>
                    <SettingsIcon />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {loading && (
              <>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {' '}
                    <Skeleton />
                  </StyledTableCell>
                </StyledTableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="h3"
            style={{
              fontSize: '28px',
              color: '#ccd219',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '20px',
            }}
          >
            Total em aberto: {employees?.openedTotalCommissionValue}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="h3"
            style={{
              fontSize: '28px',
              color: '#666666',
              textAlign: 'center',
              fontWeight: '700',
              marginBotton: '20px',
            }}
          >
            Total fechado: {employees?.totalCommissionValue}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            component="h4"
            style={{ fontSize: '14px', color: '#666666', fontWeight: '300' }}
          >
            {employees?.interval}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleSubimit()}
              disabled={disableButton}
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '190px',
              }}
            >
              Fechar comissão
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Snackbar
        open={message}
        autoHideDuration={5000}
        onClose={() => {
          setMessage(false)
        }}
      >
        <Alert severity={message === 'error' ? 'error' : 'success'}>
          {message === 'error' ? errMsg : message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Steps4
