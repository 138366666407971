import { CrmProvider } from '../../hooks/CrmContext'
import BasePage from '../basePage'
import { Drawer } from './Drawer'
import { StageList } from './StageList'

import { TabsList } from './TabsList'

import { Container } from './styles'

export function Crm() {
  return (
    <BasePage pageTitle="CRM">
      <CrmProvider>
        <Drawer />
        <Container container spacing={3}>
          <TabsList />
          <StageList />
        </Container>
      </CrmProvider>
    </BasePage>
  )
}
