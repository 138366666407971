import React from 'react'
import { Controller } from 'react-hook-form'
import { TextField, MenuItem } from '@material-ui/core'

function Select({ control, item }) {
  return (
    <Controller
      control={control}
      defaultValue={item.value}
      name={item.key}
      as={
        <TextField fullWidth select label={item.label} variant="outlined">
          {item.items.map((selectItem) => (
            <MenuItem
              key={selectItem.codigo}
              value={selectItem.codigo ? selectItem.codigo : selectItem}
            >
              {selectItem.nome ? selectItem.nome : selectItem}
            </MenuItem>
          ))}
        </TextField>
      }
    />
  )
}

export default Select
