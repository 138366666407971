import {
  TimeLineHeadboard as Container,
  TimeLineHeadboardContent,
  TimeLineHeadboardTitle,
  TimeLineHeadboardIcon,
} from "./styles";

export function TimeLineHeadboard({ children, icon: Icon }) {
  return (
    <Container>
      <TimeLineHeadboardContent>
        <TimeLineHeadboardTitle>{children}</TimeLineHeadboardTitle>
        {Icon && (
          <TimeLineHeadboardIcon>
            <Icon size={30} />
          </TimeLineHeadboardIcon>
        )}
      </TimeLineHeadboardContent>
    </Container>
  );
}
