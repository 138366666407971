import { DashboardSummary } from "./DashboardSummary";
import { DashboardDRE } from "./DashboardDRE";

import { ConfigureInformationDrawer } from "./components/ConfigureInformationDrawer";
import { UnsavedConfigurations } from "./components/Alerts/UnsavedConfigurations";

import { DashboardCalculate } from "./DashboardCalculate";
import { Diagnosis } from "./Diagnosis";
import { Actions } from "./Actions";

import { Container } from "./styles";

export function Dashboard() {
  return (
    <>
      <UnsavedConfigurations />
      <ConfigureInformationDrawer />
      <Container>
        <DashboardSummary />
        <div className="flex flex-wrap gap-4">
          <Actions />
          <DashboardDRE />
          <DashboardCalculate />
        </div>
        <Diagnosis />
      </Container>
    </>
  )
}
