import { create } from "zustand";
import { produce } from "immer";
import uuid from "react-uuid";

export const useAlert = create((set) => ({
  data: {
    alerts: {},
  },
  actions: {
    isOpen: (name, open) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.alerts[name]) return;

          draft.data.alerts[name].open = open;
        })
      ),
    setAlert: (name, option) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.alerts[name]) {
            draft.data.alerts[name] = {
              id: `alert-${uuid()}`,
              open: option?.open || false,
            };
          }
        })
      ),
  },
}));
