import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import API from '../services/api'

const CommentsContext = createContext({})

function CommentsProvider({ children }) {
  const [comments, setComments] = useState(false)

  const [filter, setFilter] = useState({
    page: 0,
    perPage: 50,
    type: 'review-comments',
    period: 'all-period',
  })
  //  "last-90-days" //"last-30-days", "last-7-days", "all-period"

  const findCommnets = useCallback(async () => {
    try {
      const { data } = await API.post('dashboard', filter)

      setComments(data.results)
    } catch (error) {
      console.log(error)
    }
  }, [filter])

  const getFilter = useCallback(() => filter, [filter])
  const updateFilter = useCallback((filter) => {
    setFilter((prev) => ({ ...prev, ...filter }))
  }, [])

  const getComments = useCallback(() => comments, [comments])

  useEffect(() => {
    findCommnets()
  }, [findCommnets])

  return (
    <CommentsContext.Provider
      value={{
        getFilter,
        updateFilter,
        findCommnets,
        getComments,
      }}
    >
      {children}
    </CommentsContext.Provider>
  )
}

function useComments() {
  const context = useContext(CommentsContext)
  if (!context) {
    throw new Error('useComments must be used within an CommentsProvider')
  }
  return context
}

export { useComments, CommentsProvider }
