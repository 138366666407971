import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    overflowY: 'auto',
  },
  item: {
    background: '#F6F6F6',
    borderRadius: '7px',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    marginBotton: '16px',
    '&::before': {
      content: '""',
      top: '50%',
      right: '40px',
      height: '40px',
      position: 'absolute',
      borderRight: '3px solid #666666',
      width: '40px',
      borderBottom: '3px solid #666666',
      transform: 'translate(0, -15%) rotate(-45deg)',
    },
  },
  link: {
    textDecoration: 'none',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '46px 20px 16px 20px',
    margin: '16px 20px',
    display: 'flex',
    cursor: 'pointer',
  },
  badge: {
    color: '#fff',
    background: '#00A1A4',
    position: 'absolute',
    width: '100%',
    height: ' 35px',
    left: '0',
    top: '0',
    padding: '4px 24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  badgeContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '13px',
  },
  petImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  pet: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 24px 0 0',
  },
  petName: {
    margin: '0',
    fontSize: '14px',
    color: '#666666',
  },
  avatar: {
    width: '60px',
    textDecoration: 'none',
    height: '60px',
    borderRadius: '50%',
    background: '#CCD219',
    margin: '0 12px',
    border: '3px solid #ffffff',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    overflow: 'hidden',
  },
  infoList: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoTitle: {
    margin: '0 0 6px 0',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '13px',
    color: '#666666',
  },
  info: {
    margin: '0 0 12px 0 ',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '13px',
    color: '#666666',
  },
}))
