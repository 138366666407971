import { StyleSheet } from "@react-pdf/renderer";

import { getCurrentThemeStyle } from "../../../../../../services/functions";

const theme = getCurrentThemeStyle()

export const self = StyleSheet.create({
  width: "100%",
  margin: "0",
  fontSize: '10px',
  color: theme.colors.gray[600]
})
