

import { formatMoney, formatWeight } from "../../../../../services/functions";

import { usePet } from "../../../../../store/global/usePet";

import { VeterinaryRecordController } from "../../../../../services/controllers/VeterinaryCare";

import { Table } from "../../../../../components/ui/Table";

export function WeightHistoryTable() {
  const weightHistory = usePet((st) => st.data.pet.weightHistory);

  const CLVetRecord = VeterinaryRecordController();

  if (!weightHistory) return null;
  const weightData = weightHistory.tableWeights

  return (
    <Table.Root
      name={CLVetRecord._weightHistoryTableName}
      style={{ minWidth: "100%", height: "auto", maxHeight: "100%" }}
    >
      <Table.Header columns={["Data", "Peso"]} />
      <Table.Content style={{ overflowY: 'auto', height: '275px' }}>
        {weightData.map((weight) => (
          <Table.Line key={weight.id}>
            <Table.Item> {weight.date} </Table.Item>
            <Table.Item>{formatMoney(weight.weight, 3)} Kg</Table.Item>
          </Table.Line>
        ))}
      </Table.Content>
    </Table.Root>
  );
}
