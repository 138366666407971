import styled from "styled-components";

export const Container = styled.div`
  grid-area: content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: fit-content;
  width: 100%;
  max-width: 750px;
  padding: 0 20px;
  flex-direction: column;
  margin: 0 auto;
  gap: 4px;
  p{
    font-size: 16px;
    color: ${props => props.theme.colors.gray[600]};
    margin: 0;
  }
`;
