import { useContext } from "../../Context";

import { Container } from "./styles";

export function ListContentPlaceholder() {
  const { placeholder } = useContext();

  if (!placeholder) return null

  return (
    <Container dangerouslySetInnerHTML={{ __html: placeholder }} />
  );
}
