import { PaginationBack } from './PaginationBack'
import { PaginationForward } from './PaginationForward'
import { PaginationPages } from './PaginationPages'
import { PaginationRoot } from './PaginationRoot'
import { usePagination } from './usePagination'

export const Pagination = {
  Root: PaginationRoot,
  Back: PaginationBack,
  Forward: PaginationForward,
  Pages: PaginationPages,
  store: usePagination,
}
