import { toast } from "react-toastify";

import {
  ProcedureController,
  ScheduleController,
} from "../../../../../services/controllers/scheduling";
import { useSchedule } from "../../../../../store/schedule/useSchedule";

import { formatName, getEmployeeTypeName, secondsToHms } from "../../../../../services/functions";

import { Select } from "../../../../ui/Form/Select";
import { Steps } from "../../../../ui/Steps";
import { SelectedPetCard } from "../../../components/SelectedPetCard";

import { Actions } from "../../Actions";

import { Container, FormContent } from "./styles";

export function SelectProcedure() {
  const CLScheduling = ScheduleController();
  const CLProcedure = ProcedureController();

  const procedure = useSchedule((st) => st.data.procedure);
  const employees = useSchedule((st) => st.data.customer.employees);
  const procedures = useSchedule((st) => st.data.customer.procedures);

  const durations = procedure.data?.durationOptions || procedure.baseDurations;
  const setActive = Steps.store((st) => st.actions.setActive);

  const toNext = (e) => {
    e.preventDefault();

    if (!procedure.data) {
      toast.error("Informe o procedimento", { theme: "colored" });
      return;
    }
    if (!procedure.duration) {
      toast.error("Informe a duração do procedimento", { theme: "colored" });
      return;
    }
    if (!procedure.duration) {
      toast.error("Informe a duração do procedimento", { theme: "colored" });
      return;
    }
    setActive(CLScheduling.stepsName, 2);
  };

  return (
    <Steps.Content
      name={CLScheduling.stepsName}
      step="Selecione o procedimento"
    >
      <Container onSubmit={toNext}>
        <SelectedPetCard />
        <FormContent>
          <Select.Root
            label="Procedimento"
            value={procedure.data?.id}
            onChange={CLProcedure.setProcedure}
            style={{ minWidth: "170px" }}
          >
            {procedures.map((procedure) => (
              <Select.Option key={procedure.id} value={procedure.id}>
                {procedure.name}
              </Select.Option>
            ))}
          </Select.Root>
          <Select.Root
            disabled={!procedure.data}
            label="Duração"
            value={procedure.duration}
            onChange={CLProcedure.setDuration}
            style={{ minWidth: "110px" }}
          >
            {durations.map((duration) => (
              <Select.Option key={`${duration}`} value={`${duration}`}>
                {secondsToHms(duration * 60).formated}
              </Select.Option>
            ))}
          </Select.Root>
          <Select.Root
            label={getEmployeeTypeName()}
            value={procedure.employee?.id}
            onChange={CLProcedure.setEmployee}
          >
            {employees.map((employee) => (
              <Select.Option value={employee.id} key={employee.id}>
                {formatName(employee.name)}
              </Select.Option>
            ))}
          </Select.Root>
        </FormContent>
        <Actions
          backAction={() => {
            setActive(CLScheduling.stepsName, 0);
          }}
        />
      </Container>
    </Steps.Content>
  );
}
