import { useParams } from "react-router-dom";
import { PiChatsFill } from "react-icons/pi";
import { LuHeartPulse, LuClipboardSignature, LuDog } from "react-icons/lu";

import { FaDog } from "react-icons/fa";

import { VeterinaryRecordController } from "../../../../services/controllers/VeterinaryCare";

import { Accordion } from "../../../../components/ui/Accordion";

import { RecordsContents } from "./RecordsContents/Index";
import { WeightHistoryAlert } from "./WeightHistoryAlert";

import { Container } from "./styles";

export function VeterinaryRecord() {
  const { orderId } = useParams();

  const CLVetRecord = VeterinaryRecordController(orderId);
  CLVetRecord.init();

  return (
    <>
      <Container>
        <Accordion.Root
          options={{
            type: "multiple",
            defaultOpen: [
              "Informações Gerais",
              "Sinais Vitais",
              "Exame Corporal",
              "Exame Físico Geral",
              "Diagnóstico",
            ],
          }}
          name="veterinary-record"
        >
          <Accordion.Item
            title="Informações Gerais"
            icon={PiChatsFill}
            animate="dance"
          >
            <Accordion.Content>
              <RecordsContents.GeneralInformation />
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item
            title="Sinais Vitais"
            icon={LuHeartPulse}
            animate="heart"
          >
            <Accordion.Content>
              <RecordsContents.VitalSigns />
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item
            title="Exame Físico Geral"
            icon={LuDog}
            animate="dance"
          >
            <Accordion.Content>
              <RecordsContents.GeneralPhysicalExamination />
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item title="Exame Corporal" icon={FaDog} animate="dance">
            <Accordion.Content>
              <RecordsContents.BodyExamination />
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item
            title="Diagnóstico"
            icon={LuClipboardSignature}
            animate="dance"
          >
            <Accordion.Content>
              <RecordsContents.Diagnosis />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      </Container>
      <WeightHistoryAlert />
    </>
  );
}
