import { create } from "zustand";
import { produce } from "immer";

import { getPDFGenerateStatusBadge } from "../../../services/functions";

const defaultRequest = {
  name: "",
  updated: true,
  requestPath: null,
  requestData: false,
  requestValues: false,
  requestDefaultValues: false,
}

const defaultData = {
  isLoading: false,
  requests: {},
  selectRequest: false,
  loadedThumbnails: false,
  request: defaultRequest
};

export const useRequest = create((set) => ({
  data: defaultData,
  actions: {
    isUpdated: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.request.updated = status;
        })
      ),
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),
    setLoadedThumbnailsStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.loadedThumbnails = status;
        })
      ),

    setRequests: (requests = false, clear = true) =>
      set((state) =>
        produce(state, (draft) => {
          if (!requests) return;

          if (!!clear) draft.data.requests = {};

          _.concat([], requests).forEach((request) => {
            draft.data.requests[request.id] = {
              ...request,
              returned: false,
              removed: false,
            };
          });
        })
      ),

    clearRequests: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),

    updateRequests: (requests = false) =>
      set((state) =>
        produce(state, (draft) => {
          requests.forEach((request) => {
            if (!requests) return;

            draft.data.requests[request.id] = {
              ...request,
              returned: false,
              removed: false,
            };
          });
        })
      ),

    changeRequestStatus: (requestId = false, status) =>
      set((state) =>
        produce(state, (draft) => {
          if (!requestId || !state.data.requests[requestId]) return

          draft.data.requests[requestId].status = status
          draft.data.requests[requestId].informationList.Status = getPDFGenerateStatusBadge(status)
        })
      ),
    changeRequestPath: (requestId = false, path) =>
      set((state) =>
        produce(state, (draft) => {
          if (!requestId || !state.data.requests[requestId]) return

          draft.data.requests[requestId].path = path
        })
      ),
    setSelectRequest: (request = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.selectRequest = request;
        })
      ),

    removeRequest: (requestId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!requestId) return;

          draft.data.requests[requestId].returned = false;
          draft.data.requests[requestId].removed = true;
        })
      ),
    returnRequest: (requestId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!requestId) return;

          draft.data.requests[requestId].returned = true;
          draft.data.requests[requestId].removed = false;
        })
      ),
    deleteRequest: (requestId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!requestId) return;
          delete draft.data.requests[requestId];
        })
      ),


    setRequestName: (name) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.request.name = name;
        })
      ),

    setRequestPath: (path = null) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.request.requestPath = path;
        })
      ),
    setRequestData: (data = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.request.requestData = data;
        })
      ),
    setRequestValues: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          const currentValues = state.data.request.requestValues;

          if (!values) {
            draft.data.request.requestValues = false;
            return;
          }

          draft.data.request.requestValues = { ...currentValues, ...values };
        })
      ),
    setRequestDefaultValues: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          const currentDefaultValues = state.data.request.requestDefaultValues;

          if (!values) {
            draft.data.request.requestDefaultValues = false;
            return;
          }

          draft.data.request.requestDefaultValues = {
            ...currentDefaultValues,
            ...values,
          };
        })
      ),
  },
}));
