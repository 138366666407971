import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Material ui - style
import { makeStyles, withStyles } from '@material-ui/core/styles'

// Material ui - lab

import Pagination from '@material-ui/lab/Pagination'

import DescriptionIcon from '@material-ui/icons/Description'
import CloseIcon from '@material-ui/icons/Close'
import { Feedback, MoreVert } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

// Material.ui
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  Paper,
  TableCell,
  TableBody,
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
  Menu,
  IconButton,
  Checkbox,
} from '@material-ui/core'

// moment
import moment from 'moment'
import API from '../../../services/api'
import 'moment/locale/pt-br'

moment.locale('pt-br')

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 700,
    border: '1px solid #ccd219',
  },
  tableContainer: {
    marginTop: '20px',
  },
  pagination: {
    marginTop: '20px',
  },

  status2: {
    color: '#008000',
  },
  status1: {
    color: '#968917',
  },
  'status-1': {
    color: '#CE2828',
  },

  filter: {
    margin: '30px 0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
    border: '1px solid #ccd219',
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #ccd219',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
    '& h6': {
      marginBottom: '-8px',
      color: '#000',
    },
    '& p': {
      margin: '0px',
      fontSize: '12px',
    },
  },
  loadingContainer: {
    textAlign: 'center',
    paddingBottom: '100px',
  },
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ccd219',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
    lineHeight: '42px',
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function RelatorioNotas({ primeiroDia, date }) {
  const PER_PAGE = 50
  const ITEM_HEIGHT = 48

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(null)

  const [massList, setMassList] = useState([])
  const [statusList, setStatusList] = useState(false)
  const [invoices, setInvoices] = useState(false)
  const [filterFromDate, setFilterFromDate] = useState(primeiroDia)
  const [filterToDate, setFilterToDate] = useState(date)
  const [filterPage, setFilterPage] = useState(0)
  const [filterTotalPages, setTotalPage] = useState(0)
  const [filterStatus, setFilterStatus] = useState('-')
  const [loading, setLoading] = useState(false)
  const [exportLinkReady, setExportLinkReady] = useState(true)

  const classes = useStyles()
  const { control } = useForm()

  async function getInvoices() {
    setLoading(true)
    let url = `invoices?fromDate=${filterFromDate}&toDate=${filterToDate}&page=${filterPage}&perPage=${PER_PAGE}`
    if (filterStatus !== '-')
      url = `invoices?fromDate=${filterFromDate}&toDate=${filterToDate}&page=${filterPage}&perPage=${PER_PAGE}&status=${filterStatus}`

    const invoices = await API.get(url)
    setLoading(false)
    setInvoices(invoices.data.report)
    setTotalPage(invoices?.data?.query?.totalPagesCount || 1)
  }

  useEffect(() => {
    getInvoices()
  }, [])

  useEffect(() => {
    async function getStatus() {
      const { data } = await API.get('invoices/status-options')
      let statusOptions = [
        {
          code: '-',
          status: 'TODOS',
        },
      ]
      statusOptions = statusOptions.concat(data?.options)
      setStatusList(statusOptions)
    }
    if (!statusList) {
      getStatus()
    }
  }, [])

  const onDecrement = (key) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(key)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const getExport = async () => {
    try {
      setExportLinkReady(false)
      const formatedFilterToDate = moment(filterToDate).format('YYYY-MM-DD')
      let url = `invoices/download?fromDate=${filterFromDate}&toDate=${formatedFilterToDate}&page=${filterPage}&perPage=${PER_PAGE}`
      if (filterStatus !== '-')
        url = `invoices/download?fromDate=${filterFromDate}&toDate=${formatedFilterToDate}&page=${filterPage}&perPage=${PER_PAGE}&status=${filterStatus}`
      const { data } = await API.get(url)
      setExportLinkReady(true)
      window.open(data?.link, '_blank')
    } catch (err) {
      setExportLinkReady(true)
      console.log('Erro:', err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setFilterPage(newPage - 1)
  }

  const toggleSelect = (id) => {
    if (!massList.includes(id)) {
      setMassList([...massList, id])
    } else {
      const newMassList = massList.filter((item) => item !== id)
      setMassList(newMassList)
    }
  }

  const selectAll = (select) => {
    if (select) {
      const idList = invoices.map(({ id }) => id)
      setMassList(idList)
    } else {
      setMassList([])
    }
  }

  const sendInvoices = async (id = false) => {
    setLoading(true)
    const invoicesIds = id ? [id] : [...massList]
    await API.post('invoices/send', { invoicesIds })
    getInvoices()
  }

  const cancelInvoices = async (id = false) => {
    setLoading(true)
    const invoicesIds = id ? [id] : [...massList]
    await API.post('invoices/cancel', { invoicesIds })

    getInvoices()
  }

  useEffect(() => {
    getInvoices()
  }, [filterPage])

  useEffect(() => {
    getInvoices()
  }, [filterStatus])

  return (
    <>
      <Grid container spacing={3} className={classes.filter}>
        <Grid item xs={2}>
          <Controller
            control={control}
            defaultValue={filterFromDate}
            name="dateInicio"
            as={({ onChange, value }) => (
              <TextField
                id="date"
                label="Inicio"
                type="date"
                value={value}
                className={classes.textField}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  onChange(e.target.value)
                  setFilterFromDate(e.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            defaultValue={moment(filterToDate).format('YYYY-MM-DD')}
            name="dateFim"
            as={({ onChange, value }) => (
              <TextField
                id="date"
                label="Fim"
                type="date"
                value={value}
                className={classes.textField}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  onChange(e.target.value)
                  setFilterToDate(e.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            defaultValue="ATIVO"
            name="status"
            as={() => (
              <TextField
                fullWidth
                select
                size="small"
                value={filterStatus}
                label=""
                variant="outlined"
                onChange={(e) => {
                  setFilterStatus(e.target.value)
                }}
              >
                {!!statusList &&
                  statusList?.map(({ code, status }) => (
                    <MenuItem key={code} value={code}>
                      {status}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: '100%' }}
          >
            <Box justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  getInvoices()
                }}
                color="primary"
                style={{
                  background: '#CCD219',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  fontWeight: '700',
                  width: '138px',
                  marginRight: '20px',
                }}
              >
                Filtrar
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  getExport()
                }}
                color="primary"
                disabled={!exportLinkReady}
                style={{
                  background: '#CCD219',
                  fontSize: '14px',
                  textTransform: 'capitalize',
                  fontWeight: '700',
                  width: '138px',
                  marginRight: '20px',
                }}
              >
                {exportLinkReady ? 'Baixar' : 'Baixando'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
          <Box justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => sendInvoices()}
              color="primary"
              disabled={!massList.length}
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '200px',
                marginRight: '20px',
              }}
            >
              <DescriptionIcon /> Emitir notas
            </Button>
            <Button
              variant="contained"
              onClick={() => cancelInvoices()}
              color="primary"
              disabled={!massList.length}
              style={{
                background: '#D25119',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '200px',
                marginRight: '20px',
              }}
            >
              <CloseIcon /> Cancelar notas
            </Button>
          </Box>
        </Box>
      </Grid>
      {loading ? (
        <Grid className={classes.loadingContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <TableContainer component={Paper} elevation={0}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
            tabIndex={-1}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell style={{ width: 50 }}>
                  <Checkbox
                    value="1"
                    onChange={(e) => selectAll(e.target.checked)}
                  />
                </StyledTableCell>
                <StyledTableCell style={{ width: 80 }}>Data</StyledTableCell>
                <StyledTableCell align="center" style={{ width: 100 }}>
                  Pedido
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 250 }}>
                  Núm. NF
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 80 }}>
                  RPS
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 80 }}>
                  Valor
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 200 }}>
                  Cliente
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 160 }}>
                  NFS-e
                </StyledTableCell>
                <StyledTableCell style={{ width: 5 }} />
                <StyledTableCell align="center" style={{ width: 5 }} />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {!!invoices &&
                invoices.map(
                  ({
                    customerNameShortened,
                    id,
                    status,
                    statusCode,
                    linkDownloadPDF,
                    linkDownloadXML,
                    orderDate,
                    orderNumber,
                    rps,
                    totalAmount,
                    warningMessage,
                  }) => (
                    <StyledTableRow key={id}>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox
                          checked={massList.includes(id)}
                          onChange={() => toggleSelect(id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {moment(orderDate).format('DD/MM/YYYY')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {orderNumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">{id}</StyledTableCell>
                      <StyledTableCell align="center">{rps}</StyledTableCell>
                      <StyledTableCell align="center">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(totalAmount)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {customerNameShortened}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes[`status${statusCode}`]}
                      >
                        {status}
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {warningMessage && (
                          <Tooltip title={warningMessage}>
                            <Feedback
                              style={{
                                marginTop: '15px',
                                marginLeft: '15px',
                                padding: '2px',
                                color: '#D25119',
                              }}
                            />
                          </Tooltip>
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <>
                          <IconButton
                            aria-label="more"
                            aria-controls={id}
                            aria-haspopup="true"
                            onClick={onDecrement(id)}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id={id}
                            keepMounted
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            open={id === open}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                sendInvoices(id)
                              }}
                            >
                              Enviar
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                cancelInvoices(id)
                              }}
                            >
                              Cancelar
                            </MenuItem>
                            <MenuItem
                              disabled={!linkDownloadPDF}
                              onClick={() => {
                                window.open(linkDownloadPDF)
                              }}
                            >
                              Baixar pdf
                            </MenuItem>
                            <MenuItem
                              disabled={!linkDownloadXML}
                              onClick={() => {
                                window.open(linkDownloadXML)
                              }}
                            >
                              Baixar xml
                            </MenuItem>
                          </Menu>
                        </>
                      </StyledTableCell>
                    </StyledTableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ justifyContent: 'center' }}
        className={classes.pagination}
      >
        {!!filterTotalPages && (
          <Pagination
            count={filterTotalPages}
            variant="outlined"
            onChange={handleChangePage}
          />
        )}
      </Grid>
    </>
  )
}

export default RelatorioNotas
