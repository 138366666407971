import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 14px;
`
export const Separator = styled.span`
  background: ${(props) => props.theme.colors.scale[200]};
  height: 1px;
  width: 100%;
`
