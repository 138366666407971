import React from 'react'

import Dados from './Dados'
import EmissorNotas from './EmissorNotas'
import HorarioFuncionamento from './HorarioFuncionamento'
import { Printer } from './Printer'

function TabSelect({ tab }) {
  switch (tab) {
    case 'dados':
      return <Dados />
    case 'horarios':
      return <HorarioFuncionamento />
    case 'notas':
      return <EmissorNotas />
    case 'printer':
      return <Printer />
    default:
      return <Dados />
  }
}

export default TabSelect
