import Fuse from 'fuse.js'

export default class FuseBuilder {
  _list = []
  _searchOptions = {
    includeScore: true,
  }

  constructor(name, list, options = {}) {
    this._name = name
    this._list = list

    this._searchOptions = {
      ...this._searchOptions,
      ...options
    }

    this._fuseIndexName = `@PETLAND/${this._name}`
  }

  init = _.once(() => {
    this.setFuseIndex()
    this.startFuse()
  });

  setList = (list) => {
    if (!list) return
    this._list = list
  }

  fuseIndex = () => {
    const storageMedicinesIndex = localStorage.getItem(this._fuseIndexName)
    if (!storageMedicinesIndex) return

    return Fuse.parseIndex(JSON.parse(storageMedicinesIndex))
  }

  startFuse = () => {
    this.fuse = new Fuse(this._list, this._searchOptions)
  }

  setFuseIndex = () => {
    if (!this._searchOptions.keys) return

    const fuseIndex = Fuse.createIndex(this._searchOptions.keys, this._list)
    localStorage.setItem(this._fuseIndexName, JSON.stringify(fuseIndex))
  }

  search = (term, limit = -1) => {
    if (!term) return []

    const response = this.fuse.search(term, { limit })
    return response
  }

  updateList = (list) => {
    if (!list) return

    this.fuse.setCollection(list)
  }

  add = (item) => {
    if (!item) return

    this.fuse.add(item)
  }

  remove = (item) => {
    if (!item) return

    const results = this.fuse.remove((doc) => {
      return doc === item
    })

    return results
  }
}
