import { Card } from '../components/Card'
import { Dog } from 'lucide-react'
import {
  ArrowUp,
  ChatCircleText,
  GenderMale,
  GenderFemale,
  Knife,
  Waves,
} from '@phosphor-icons/react'
import { usePet } from '../../../../../../store/global/usePet'
import { getCastrated, getPetGender } from '../../../../../../services/functions'
import { Plugs, PlugsConnected } from 'phosphor-react'

export function Pet() {
  const pet = usePet(st => st.data.pet)
  const attributes = pet.petAttributes

  return (
    <>
      <Card.Content>
        <Card.ContentTitle>{pet.name}:</Card.ContentTitle>
        <Card.ContentList className="grid grid-cols-2">
          {attributes?.size && (
            <Card.ContentListItem>
              <ArrowUp className="h-4 w-4" weight="bold" />
              <span className="leading-none">{attributes.size}</span>
            </Card.ContentListItem>
          )}
          {attributes?.fur && (
            <Card.ContentListItem>
              <Waves className="h-4 w-4" weight="bold" />
              <span className="leading-none">{attributes.fur}</span>
            </Card.ContentListItem>
          )}
          {attributes?.gender && (
            <Card.ContentListItem>
              {attributes.gender === "male" && <GenderMale className="h-4 w-4" weight="bold" />}
              {attributes.gender === "female" && <GenderFemale className="h-4 w-4" weight="bold" />}
              <span className="leading-none">{getPetGender(attributes.gender)[0]}</span>
            </Card.ContentListItem>
          )}
          <Card.ContentListItem>
            {attributes?.castrated && (
              <Plugs className="h-4 w-4" weight="bold" />
            )}
            {!attributes?.castrated && (
              <PlugsConnected className="h-4 w-4" weight="bold" />
            )}
            <span className="leading-none">
              {!attributes?.castrated && "Não "}
              {getCastrated(attributes?.gender)}
            </span>
          </Card.ContentListItem>
          {attributes?.breed && (
            <Card.ContentListItem className='col-span-2 w-full'>
              <Dog className="h-4 w-4" weight="bold" />
              <span className="leading-none flex-1 w-full">{attributes.breed}</span>
            </Card.ContentListItem>
          )}
          {pet?.note && (
            <Card.ContentListItem className="col-span-2 mt-2 flex-wrap">
              <span className="leading-normal">
                <ChatCircleText className="float-left mr-1 h-4 w-4" weight="bold" />
                {pet.note}
              </span>
            </Card.ContentListItem>
          )}
        </Card.ContentList>
      </Card.Content>
      <Card.Separator className="bg-black" />
    </>
  )
}
