import { Action } from "../Action";

import { TimeLineActions as Container } from "./styles";

export function TimeLineActions({ actions }) {
  return (
    <Container>
      {actions.map(({ text, icon, name, ...rest }) => (
        <Action.Root key={name} {...rest}>
          {icon && <Action.Icon icon={icon} />}
          {text}
        </Action.Root>
      ))}
    </Container>
  );
}
