import moment from 'moment'
import { differenceInDays } from 'date-fns'
import { PROTOCOL, VACCINE } from '../../styles/constant'
import API from '../../services/api'

export const protocolConciliation = (protocols) => {
  const isArray = Array.isArray(protocols)
  if (!isArray) {
    return {
      id: protocols.protocolId,
      name: protocols.vaccineName,
      description: protocols.protocolName,
      interrupted: !!protocols.interrupted,
    }
  }

  if (isArray) {
    return protocols.map((protocol) => ({
      id: protocol.protocolId,
      name: protocol.vaccineName,
      description: protocol.protocolName,
      interrupted: !!protocol.interrupted,
    }))
  }
}

export const vaccineConciliation = (vaccines) => {
  const isArray = Array.isArray(vaccines)

  if (!isArray) {
    return {
      id: vaccines.id,
      protocolId: vaccines.protocolId,
      vaccineId: vaccines.vaccineId,
      laboratories: vaccines.laboratories || false,
      status:
        vaccines.status ||
        VACCINE.status[vaccines.vaccinationStatus.toLowerCase()],
      expired:
        differenceInDays(new Date(vaccines.vaccinationDate), new Date()) < 0,
      vaccinationDate: moment(
        `${vaccines.vaccinationDate.toString().split('T')[0]} 00:00:00`,
      ).format(),
      vaccinationDateFormated: vaccines.vaccinationDate
        ? moment(vaccines.vaccinationDate.toString().split('T')[0]).format(
          'DD/MM/YYYY',
        )
        : '',
      applicationDate: moment(
        `${vaccines.applicationDate.toString().split('T')[0]} 00:00:00`,
      ).format(),
      applicationDateFormated: vaccines.applicationDate
        ? moment(vaccines.applicationDate.toString().split('T')[0]).format(
          'DD/MM/YYYY',
        )
        : '',
      laboratoryName: vaccines.laboratoryName,
      batch: vaccines.batch,
      actionState: 'default',
    }
  }

  if (isArray) {
    return vaccines.map((vaccine) => ({
      id: vaccine.id,
      protocolId: vaccine.protocolId,
      vaccineId: vaccine.vaccineId,
      laboratories: vaccines.laboratories || false,
      status:
        VACCINE.status[vaccine.vaccinationStatus.toLowerCase()] ||
        vaccine.status,
      expired:
        differenceInDays(new Date(vaccine.vaccinationDate), new Date()) < 0,
      vaccinationStatus: vaccine.vaccinationStatus.toLowerCase(),
      vaccinationDate: moment(
        `${vaccine.vaccinationDate.toString().split('T')[0]} 00:00:00`,
      ).format(),
      vaccinationDateFormated: vaccine.vaccinationDate
        ? moment(
          new Date(
            `${vaccine.vaccinationDate.toString().split('T')[0]} 00:00:00`,
          ),
        ).format('DD/MM/YYYY')
        : '',
      applicationDate: vaccine.applicationDate
        ? moment(
          `${vaccine.vaccinationDate.toString().split('T')[0]} 00:00:00`,
        ).format()
        : null,
      applicationDateFormated: vaccine.applicationDate
        ? moment(
          new Date(
            `${vaccine.applicationDate.toString().split('T')[0]} 00:00:00`,
          ),
        ).format('DD/MM/YYYY')
        : '',
      laboratoryName: vaccine.laboratoryName,
      batch: vaccine.batch,
      actionState: 'default',
      appointmentOrderId: vaccine.appointmentOrderId
    }))
  }
}

export const lastVaccineStatus = (vaccines, protocolId, interrupted) => {
  const list = vaccines.filter(
    (vaccine) =>
      vaccine.protocolId === protocolId &&
      vaccine.status !== 'applied' &&
      vaccine.status !== 'cancel',
  )
  if (!list.length) {
    const first = vaccines[0]
    return {
      ...PROTOCOL.status.finalizado,
      date: first.applicationDate,
      dateFormated: first.applicationDateFormated,
    }
  }

  const last = list.slice(-1)[0]
  const vaccineStatus =
    last.status === 'scheduled' && last.expired ? 'expired' : 'scheduled'
  const status = interrupted ? 'interrompido' : vaccineStatus

  return {
    ...PROTOCOL.status[status],
    date: last.vaccinationDate,
    dateFormated: last.vaccinationDateFormated,
  }
}

export const sortVaccines = (vaccinesList, order = 'asc') =>
  vaccinesList.sort((a, b) => {
    if (order === 'desc') {
      return new Date(a.vaccinationDate) - new Date(b.vaccinationDate)
    }
    return new Date(b.vaccinationDate) - new Date(a.vaccinationDate)
  })

export const getProtocols = async (petId) => {
  const { data } = await API.get(`vaccinations/protocols/${petId}`)
  return data
}

export const getLaboratories = async (vaccineId) => {
  const { data } = await API.get(`vaccine/${vaccineId}/labs`)
  return data.labs
}

export const interruptProtocol = async (protocolId, petId, isInterrupted) => {
  const url = isInterrupted
    ? 'vaccines/interrupt-pet-protocol'
    : 'vaccines/uninterrupt-pet-protocol'
  await API.post(url, {
    petId,
    protocolId,
  })
}

export const excludeProtocol = async (protocolId, petId) => {
  await API.post('vaccines/delete-pet-protocol', {
    petId,
    protocolId,
  })
}

export const vaccineBodyToEdit = (body) => ({
  action: body.actionState,
  data: {
    scheduleDate: body.vaccinationDate,
    applicationDate: body.applicationDate,
    laboratoryName: body.laboratoryName,
    batch: body.batch,
    appointmentOrderId: body.appointmentOrderId
  },
})

export const updateValues = async (id, updateData) => {
  if(updateData.action === 'apply'){
    await API.put(`/vaccination/${id}/action`, {
      action: updateData.action,
      data: updateData.data,
    })
  }
  else{
    if (['cancel', 'remove', 'reschedule'].includes(updateData.action)) {
      await API.put(`/vaccination/${id}/action`, {
        action: updateData.action,
      })
    }
  }

  if (['apply', 'reschedule', 'edit'].includes(updateData.action)) {
    await API.put(`/vaccination/${id}/action`, {
      action: 'edit',
      data: updateData.data,
    })
  }
}
