import moment from 'moment'

import { Person, Pets, CalendarMonth, Alarm } from '@mui/icons-material'
import { useSchedule } from '../../../../../../../../hooks/ScheduleContext'
import { secondsToHms } from '../../../../../../../../services/functions'

import {
  Container,
  Content,
  Arrow,
  Header,
  Client,
  PetName,
  CustomerName,
  ScheduleData,
  ScheduleDate,
  ScheduleHour,
  ScheduleServices,
} from './styles'

export function Tooltip({ id }) {
  const { getBubbleById, getConfig } = useSchedule()

  const bubble = getBubbleById(id)
  const config = getConfig()

  return (
    <Container>
      <Content
        sideOffset={5}
        side="top"
        collisionPadding={{ top: 20, left: 80 }}
      >
        <Header>
          <Client>
            <PetName>
              <Pets /> <span>{bubble.petName.toLowerCase()}</span>
            </PetName>
            <CustomerName>
              <Person /> <span>{bubble.customerName}</span>
            </CustomerName>
          </Client>
          <ScheduleData>
            <ScheduleDate>
              <CalendarMonth /> {moment(config.date).format('DD/MM/YYYY')}
            </ScheduleDate>
            <ScheduleHour>
              <Alarm />
              {secondsToHms(bubble.startsAt).formated} -{' '}
              {secondsToHms(bubble.finishesAt).formated}
            </ScheduleHour>
          </ScheduleData>
        </Header>
        <ScheduleServices>
          {bubble.services.map((service) => (
            <p key={service}>{service.toLowerCase()}</p>
          ))}
        </ScheduleServices>
        <Arrow />
      </Content>
    </Container>
  )
}
