import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import CircularProgress from '@mui/material/CircularProgress'
import BasePage from './basePage'
import { getCustomers } from '../services/customers'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 300,
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
  teste: {
    '& input': {
      padding: '15px',
      fontSize: '12px',
    },
    '& label': {
      top: '-4px',
    },
  },
  clasIcon: {
    backgroundColor: '#ccd219',
    color: '#fff',
    borderRadius: '5px',
    padding: '9px',
    '&:hover': {
      color: '#ccd219',
    },
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#ccd219',
    '&:hover': {
      backgroundColor: '#a0a510',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

function Clientes() {
  const history = useHistory()
  const classes = useStyles()
  const [customers, setCustomers] = useState([])
  const [validationButton, setValidationButton] = useState(false)
  const [messageError, setMessageError] = useState(false)

  const { register, handleSubmit } = useForm()

  const handleGetCustomer = async (data) => {
    setValidationButton(true)
    const result = await getCustomers(
      data?.cpf,
      data?.name,
      data?.pet,
      data?.cellphone,
    )
    if (result === 'Cliente não encontrado') {
      setValidationButton(false)
      setMessageError(result)
    } else {
      setCustomers(result)
      setValidationButton(false)
    }
  }

  return (
    <BasePage pageTitle="Clientes">
      <Grid container spacing={3} style={{ marginBottom: '10px' }}>
        <Grid item xs={9}>
          {/* <Paper component="form" className={classes.root} elevation={0}>
            <InputBase
              className={classes.input}
              placeholder="Nome, CPF, Telefone"
              onChange={handleGetCustomer}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper> */}

          <form onSubmit={handleSubmit(handleGetCustomer)}>
            <Grid container spacing={1} className={classes.autoContainer}>
              <Grid item xs={3}>
                <TextField
                  className={classes.teste}
                  fullWidth
                  name="cpf"
                  label="CPF"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  className={classes.teste}
                  fullWidth
                  name="cellphone"
                  label="Telefone"
                  type="number"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  className={classes.teste}
                  fullWidth
                  name="name"
                  label="Nome"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  className={classes.teste}
                  fullWidth
                  name="pet"
                  label="Pet"
                  variant="outlined"
                  inputRef={register}
                />
              </Grid>

              <Grid item xs={2}>
                {validationButton ? (
                  <CircularProgress
                    style={{ color: '#ccd219', width: '30px', height: '30px' }}
                  />
                ) : (
                  <IconButton
                    className={classes.clasIcon}
                    aria-label="search"
                    type="submit"
                  >
                    <SearchIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={3}>
          <CustomButton
            onClick={() => history.push('/clientes/novo')}
            fullWidth
            type="submit"
            color="secondary"
            size="large"
            justify="center"
            alignItems="center"
          >
            Novo Cliente
          </CustomButton>
        </Grid>
      </Grid>

      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="right">CPF</TableCell>
              <TableCell align="right">Telefone</TableCell>
              <TableCell align="right">E-mail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!customers.length && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <strong>
                    {messageError === 'Cliente não encontrado'
                      ? messageError
                      : 'Por favor, digite acima o cliente que deseja encontrar!'}
                  </strong>
                </TableCell>
              </TableRow>
            )}
            {customers.map((customer) => (
              <TableRow
                key={customer.id}
                onClick={() => history.push(`/clientes/resumo/${customer.id}`)}
              >
                <TableCell>{customer.name}</TableCell>
                <TableCell align="right">{customer.cpf}</TableCell>
                <TableCell align="right">{customer.telephone}</TableCell>
                <TableCell align="right">{customer.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </BasePage>
  )
}

export default Clientes
