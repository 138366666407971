import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { MerchantController, UserController } from "../services/controllers/global";

import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import EditCupom from "../components/organisms/EditCupom";
import EditEmployee from "../components/organisms/EditEmployee";
import Line from "../components/organisms/Line";
import NewAccessProfile from "../components/organisms/NewAccessProfile";
import NewCupom from "../components/organisms/NewCupom";
import NewEmployee from "../components/organisms/NewEmployee";
import NewService from "../components/organisms/NewService";
import ServiceEdit from "../components/organisms/ServiceEdit";
import AgendamentoPage from "./Agendamento";
import AgendamentoVeterinarioPage from "./AgendamentoVeterinario";
import Assinaturas from "./Assinaturas";
import { Avaliacoes } from "./Avaliacoes";
import Checkin from "./Checkin";
import CheckinClinica from "./CheckinClinica";
import Clientes from "./Clientes";
import { AtendimentoClinica } from "./Clinica/Atendimento";
import { ReportsVaccinations } from "./Clinica/ReportsVaccinations";
import Configuracoes from "./Configuracoes";
import { Crm } from "./Crm";
import CupomPage from "./Cupom";
import Dashboard from "./Dashboard";
import EditPacote from "./EditPacote";
import FilaAtendimento from "./FilaAtendimento";
import HistoryVaccination from "./HistoryVaccination";
import Login from "./Login";
import Logout from "./Logout";
import NovoCliente from "./NovoCliente";
import NovoPacotes from "./NovoPacote";
import Pacotes from "./Pacotes";
import PreAgendamento from "./PreAgendamento";
import Profissionais from "./Profissionais";
import Relatorio from "./Relatorio";
import ResumoCliente from "./ResumoCliente";
import { Schedule } from "./Schedule";
import Servicos from "./Servicos";
import Vacinas from "./Vacinas";
import Vendas from "./Vendas";

function PrivateRoute({ component: Component, ...rest }) {
  const authenticationStatus = isAuthenticated();
  const ClMerchant = MerchantController();

  if (authenticationStatus) ClMerchant.init();
  const CMerchant = MerchantController();
  const CUser = UserController();

  if (authenticationStatus) {
    CMerchant.init();
    CUser.init();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticationStatus ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}


function Routes() {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <Switch>
          <PrivateRoute
            exact
            path="/pre-agendamento"
            component={(props) => <PreAgendamento {...props} />}
          />
          <PrivateRoute
            exact
            path="/fila-de-atendimento"
            component={(props) => <FilaAtendimento {...props} />}
          />
          <PrivateRoute
            exact
            path="/assinaturas"
            component={(props) => <Assinaturas {...props} />}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            component={(props) => <Dashboard {...props} />}
          />
          <PrivateRoute
            exact
            path="/horario-trabalho"
            component={(props) => <Line {...props} />}
          />
          <PrivateRoute
            exact
            path="/banho-tosa/agendamento"
            component={(props) => <AgendamentoPage {...props} />}
          />
          <PrivateRoute
            exact
            path="/banho-tosa/agendamento/:data"
            component={(props) => <AgendamentoPage {...props} />}
          />
          <PrivateRoute
            exact
            path="/vacinas"
            component={(props) => <HistoryVaccination {...props} />}
          />
          <PrivateRoute
            exact
            path="/clinica/relatorios/vacinacoes"
            component={(props) => <ReportsVaccinations {...props} />}
          />
          <PrivateRoute
            exact
            path="/clinica/agendamento"
            component={(props) => <AgendamentoVeterinarioPage {...props} />}
          />
          <PrivateRoute
            exact
            path="/clinica/agendamento/:data"
            component={(props) => <AgendamentoVeterinarioPage {...props} />}
          />
          <PrivateRoute
            exact
            path="/clinica/check-in/order/:orderId"
            component={(props) => <CheckinClinica {...props} />}
          />
          <PrivateRoute
            exact
            path="/banho-tosa/check-in/order/:orderId"
            component={(props) => <Checkin {...props} />}
          />
          <PrivateRoute exact path="/clientes" component={() => <Clientes />} />
          <PrivateRoute
            exact
            path="/clientes/resumo/:id"
            component={(props) => <ResumoCliente {...props} />}
          />
          <PrivateRoute
            exact
            path="/clientes/novo"
            component={() => <NovoCliente />}
          />
          <PrivateRoute
            exact
            path="/avaliacoes"
            component={() => <Avaliacoes />}
          />
          <PrivateRoute exact path="/crm" component={() => <Crm />} />
          <PrivateRoute
            exact
            path="/packages"
            component={(props) => <Pacotes {...props} />}
          />
          <PrivateRoute
            exact
            path="/pacotes"
            component={(props) => <Pacotes {...props} />}
          />
          <PrivateRoute
            exact
            path="/relatorio"
            component={(props) => <Relatorio {...props} />}
          />
          <PrivateRoute
            exact
            path="/cupom"
            component={(props) => <CupomPage {...props} />}
          />

          <PrivateRoute
            exact
            path="/cupom/editar/:id"
            component={(props) => <EditCupom {...props} />}
          />

          <PrivateRoute
            exact
            path="/cupom/new"
            component={(props) => <NewCupom {...props} />}
          />
          <PrivateRoute
            exact
            path="/pacotes/new"
            component={(props) => <NovoPacotes {...props} />}
          />
          <PrivateRoute
            exact
            path="/pacotes/vendas"
            component={(props) => <Vendas {...props} />}
          />
          <PrivateRoute
            exact
            path="/pacotes/edit/:id"
            component={(props) => <EditPacote {...props} />}
          />
          <PrivateRoute
            exact
            path="/profissionais"
            component={() => <Profissionais />}
          />
          <PrivateRoute exact path="/protocolos" component={() => <Vacinas />} />
          <PrivateRoute
            exact
            path="/profissionais/new"
            component={() => <NewEmployee />}
          />
          <PrivateRoute
            exact
            path="/profissionais/editar/:id"
            component={(props) => <EditEmployee {...props} />}
          />
          <PrivateRoute
            exact
            path="/profissionais/profile/new"
            component={() => <NewAccessProfile />}
          />
          <PrivateRoute
            exact
            path="/servicos"
            component={(props) => <Servicos {...props} />}
          />
          <PrivateRoute
            exact
            path="/servicos/new"
            component={(props) => <NewService {...props} />}
          />
          <PrivateRoute
            exact
            path="/servicos/editar/:edit"
            component={(props) => <ServiceEdit {...props} />}
          />

          <PrivateRoute
            exact
            path="/configuracoes"
            component={(props) => <Configuracoes {...props} />}
          />
          {/* ************************************ */}
          {/* New Paths */}
          {/* ************************************ */}

          <PrivateRoute
            exact
            path="/clinica/atendimento/:orderId"
            component={() => <AtendimentoClinica />}
          />

          <PrivateRoute
            exact
            path="/agenda"
            component={(props) => <Schedule {...props} />}
          />

          <Route exact path="/" component={() => <Login />} />
          <Route exact path="/logout" component={() => <Logout />} />
          <Route path="*" component={() => <Login />} />
        </Switch>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default Routes;
