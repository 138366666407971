import React, { useMemo } from "react";
import { useDrop } from "react-dnd";

import { ScheduleController } from "../../../../../services/controllers/scheduling";

import { dateFormat, secondsToHms } from "../../../../../services/functions";

import { encodeUrlParans } from "../../../../../services/services";
import { CALENDAR } from "../../../../../styles/constant";
import { useSchedule } from "../../../../../hooks/ScheduleContext";
import { useSchedule as scheduleStore } from "../../../../../store/schedule/useSchedule";

import { useCalendar } from "../../../../../store/schedule/useCalendar";

import { Container, Highlight, HighlightButton } from "./styles";
import { useThemes } from "../../../../../providers/theme";

export function GapTime({ gap, employee, i }) {
  const { theme } = useThemes();
  const setProcedure = scheduleStore((st) => st.actions.setProcedure);
  const setOld = scheduleStore((st) => st.actions.setOld);

  const CLSchedule = ScheduleController();

  const setDragging = useCalendar(st => st.actions.setDragging);

  const { gapIsAble, patchSchedule, getConfig } = useSchedule();

  const config = getConfig();

  const able = gapIsAble(gap, employee);
  const [{ isHover, canDrop }, dropRef] = useDrop({
    accept: CALENDAR.ItemTypes.BUBBLE,
    drop(item) {
      setDragging(false);
      if (item.startsAt === gap && item.employeeId === employee.id) return;
      const startsAt = gap;
      const finishesAt = gap + item.duration;
      const employeeId = employee.id;

      patchSchedule(item.id, { startsAt, finishesAt, employeeId }, true);
    },

    hover() {
      setDragging(true);
    },

    collect: (monitor) => ({
      isHover: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const sendData = encodeUrlParans({
    event: {
      start: new Date(
        `${config.date} ${secondsToHms(gap, "HH:ii:ss").formated}`
      ),
    },
    professional: {
      resourceId: employee.id,
      resourceTitle: employee.name,
    },
  });

  const newScheduleLink = useMemo(()=>{
    if(!config.links?.newSchedule) return ''
    return config.links.newSchedule + '/' + sendData
  },[config.links, sendData])

  const openDrawer = async () => {
    await CLSchedule.destroy();

    const date = dateFormat(config.date);
    const time = secondsToHms(gap).formated;

    CLSchedule.setStartStatus("newSchedule");

    setProcedure({ employee, date, time });
    setOld(newScheduleLink)

    CLSchedule.init();
  };

  return (
    <Container
      disabled={!able}
      ref={dropRef}
      isHover={isHover}
      canDrop={canDrop}
      index={i}
      id={`gap-${secondsToHms(gap).formated}`}
    >
      {theme === "draMei" && (
        <HighlightButton type="button" onClick={openDrawer}>
          {secondsToHms(gap).formated}
        </HighlightButton>
      )}

      {theme === "petland" && (
        <Highlight href={newScheduleLink}>
          {secondsToHms(gap).formated}
        </Highlight >
      )}
    </Container >
  );
}
