import { create } from "zustand";
import { produce } from "immer";
import _ from "lodash";

const defaultData = {
  isLoading: false,
  percentageCorrect: false,
  recommendation: false,
  plans: false,
  table: false,
};

export const usePricing = create((set) => ({
  data: defaultData,
  actions: {
    setLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isLoading = status;
        })
      ),
    setPrcentageCorrect: (value) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.percentageCorrect = value;
        })
      ),
    setRecommendation: (value) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.recommendation = value
        })
      ),
    setTable: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.table = data
        })
      ),
    setPlans: (data) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.plans = data
        })
      ),
  },
}));
