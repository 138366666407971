import { toast } from "react-toastify";

import api from "../../api";

import { formatResponse } from "../../formatResponses/global";

export default class MerchantModel {
  findMerchant = async () => {
    try {
      const { data } = await api.get("store");
      const formattedMerchant = formatResponse.merchant(data);
      return formattedMerchant;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
