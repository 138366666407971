import { rgba } from "polished";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0%{ opacity: 1}
  50%{ opacity: .3}
  99%{ opacity: 1}
`;
export const Line = styled.span`
  --gradient-start: ${(props) => rgba(props.theme.colors.scale[500], 0.5)};
  --gradient-end: ${(props) => rgba(props.theme.colors.scale[300], 0.5)};
  height: 20px;
  display: flex;
  flex: 1;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.scale[100]};
  background-image: linear-gradient(
    -20deg,
    var(--gradient-start) 0%,
    var(--gradient-end) 100%
  );
  animation: ${pulse} 4s ease-in-out infinite;
`;
