import { create } from "zustand";
import { produce } from "immer";
import _ from "lodash";
import moment from "moment";

import { SCHEDULES_STATUS } from "./constants";

import { getSchedulingStatusTitle } from "../../services/functions";

const defaultData = {
  open: false,
  updated: true,
  oldUrl: false,
  disabledEdit: false,
  appointmentId: null,
  loading: false,
  pet: null,
  status: "newSchedule",
  title: getSchedulingStatusTitle(SCHEDULES_STATUS[0]),
  procedure: {
    data: null,
    duration: null,
    employee: null,
    date: moment().format("DD/MM/YYYY"),
    time: null,
    baseDurations: _.range(15, 375, 15),
    services: [],
  },
  details: {
    seller: null,
    observation: "",
  },
  attendingHistory: [],
  customer: {
    pets: [],
    procedures: [],
    employees: [],
    services: [],
    sellers: [],
  },
  servicesActions: {
    add: [],
    remove: [],
  },
};

export const useSchedule = create((set) => ({
  data: defaultData,
  actions: {
    isUpdated: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (state.data.updated === status) return
          draft.data.updated = status;
        })
      ),
    setLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.loading = status;
        })
      ),
    setPets: (pets) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.customer.pets = pets;
        })
      ),
    setTitle: (title) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.title = title;
        })
      ),

    setStatus: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.status = status;
        })
      ),
    setDisabledEdit: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.disabledEdit = status;
        })
      ),
    setAttendingHistory: (history) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.attendingHistory = history;
        })
      ),
    setProcedures: (procedures) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.customer.procedures = procedures;
        })
      ),
    setEmployees: (employees) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.customer.employees = employees || [];
        })
      ),
    setServices: (services) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.customer.services = services || [];
        })
      ),
    setSellers: (sellers) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.customer.sellers = sellers || [];
        })
      ),
    setPet: (pet) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pet = pet;
        })
      ),
    setPetWeightHistory: (weightHistory) =>
      set((state) =>
        produce(state, (draft) => {
          if (state.data.pet?.weightHistory) {
            draft.data.pet.weightHistory = {};
          }
          draft.data.pet.weightHistory = weightHistory;
        })
      ),
    setAppointment: (appointmentId) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.appointmentId = appointmentId;
        })
      ),
    setProcedure: (procedure) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.procedure = { ...state.data.procedure, ...procedure };
          draft.data.disabledEdit = false;
        })
      ),
    setDateTime: (dateTime) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.procedure = { ...state.data.procedure, ...dateTime };
          draft.data.disabledEdit = false;
        })
      ),
    setDetails: (details) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.details = { ...state.data.details, ...details };
          draft.data.disabledEdit = false;
        })
      ),
    setServiceToRemove: ({ add, remove }) =>
      set((state) =>
        produce(state, (draft) => {
          if (add) {
            const newItem = _.concat([], add);
            draft.data.servicesActions.remove = [
              ...state.data.servicesActions.remove,
              ...newItem,
            ];
          }
          if (remove) {
            const removeList = _.concat([], remove);
            draft.data.servicesActions.remove = _.difference(
              state.data.servicesActions.remove,
              removeList
            );
          }
        })
      ),
    setServiceToAdd: ({ add, remove }) =>
      set((state) =>
        produce(state, (draft) => {
          if (add) {
            const newItem = _.concat([], add);
            draft.data.servicesActions.add = [
              ...state.data.servicesActions.add,
              ...newItem,
            ];
          }
          if (remove) {
            const removeList = _.concat([], add);
            draft.data.servicesActions.add = _.difference(
              state.data.servicesActions.add,
              removeList
            );
          }
        })
      ),
    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),
    setOld: (url) =>
      set((state) =>
        produce(state, (draft) => {
          if (state.data.oldUrl === url) return
          draft.data.oldUrl = url;
        })
      ),
  },
}));
