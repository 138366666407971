import moment from "moment";
import {
  CalendarCheck,
  CalendarClock,
  CalendarHeart,
  CalendarX,
  Dog,
} from "lucide-react";
import { getScheduleStatus } from "../../../../../../../services/functions";

export function VaccineStatusIcon({ vaccine }) {
  const vaccineSchedule = getScheduleStatus(vaccine.scheduledDate);
  let StatusIcon = Dog;

  if (vaccineSchedule.status === "scheduled") {
    StatusIcon = CalendarClock;
  }

  if (vaccineSchedule.status === "today") {
    StatusIcon = CalendarHeart;
  }

  if (vaccineSchedule.status === "delayed") {
    StatusIcon = CalendarX;
  }

  if (vaccine.status === "applied") {
    StatusIcon = CalendarCheck;
  }

  return <StatusIcon />;
}
