import _ from "lodash";
import { Alert } from "../../../../../../components/ui/Alert";
import { VeterinaryCareController } from "../../../../../../services/controllers/VeterinaryCare";
import { formatMoney, formatName } from "../../../../../../services/functions";

import { useService } from "../../../../../../store/service/veterinary/useService";

import {
  SummaryServicesTable,
  SummaryServicesTablBox,
  SummaryServicesTableHeadLine,
  SummaryServicesTableLine,
  SummaryTitle,
} from "./styles";

export function ServicesNotSaved() {
  const CLVetCare = VeterinaryCareController()

  const services = useService((st) => st.data.services);
  const notRegistered = _.pickBy(services, { status: 'new' })

   return (
    <Alert.Root name={CLVetCare._servicesNotSavedAlertName}>
      <Alert.Content style={{maxWidth: '600px'}}>
        <Alert.Title>Atenção!</Alert.Title>
        <Alert.Description>
          <p>
            Observamos que alguns serviços não foram registrados durante o atendimento.<br />
            Por favor, certifique-se de que todos os serviços realizados no atendimento sejam devidamente registrados antes de finaliza-lo.
          </p>
        </Alert.Description>

        <SummaryServicesTablBox>
          <SummaryTitle>Serviços não registrados:</SummaryTitle>
          <SummaryServicesTable>
            <SummaryServicesTableHeadLine>
              <span>Nome do Serviço</span>
              <span>Valor Original</span>
              <span>Valor Cobrado</span>
            </SummaryServicesTableHeadLine>
            {!!notRegistered &&
              Object.entries(notRegistered).map(([key, service]) => (
              <SummaryServicesTableLine key={key}>
                <span>{formatName(service.name)}</span>
                <span>R$ {formatMoney(service.originalPrice)}</span>
                <span>R$ {formatMoney(service.price)}</span>
              </SummaryServicesTableLine>
            ))}
          </SummaryServicesTable>
        </SummaryServicesTablBox>

        <Alert.Actions>
          <Alert.Cancel>Cancelar</Alert.Cancel>
          <Alert.Confirm onClick={()=>CLVetCare.finishAtttending(true)}>Tudo certo, finalizar atendimento</Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
}
