import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin-top: 12px;
  padding-top: 24px;
  border-top: 1px solid ${(props) => props.theme.colors.scale[500]};
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  & > * {
    min-width: fit-content;
    flex: 1;
  }
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-top: 12px;
  & > * {
    width: fit-content;
  }
`;
