import { Redo, Undo } from "lucide-react";
import { useContext } from "../Context";

import { MenuButton, MenuGroup } from "./styles";

export function MenuUndoRedo() {
  const { editor } = useContext();

  return (
    <MenuGroup>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        <Undo size={18} />
      </MenuButton>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        <Redo size={18} />
      </MenuButton>
    </MenuGroup>
  );
}
