
import { createGlobalStyle } from "styled-components";

import './tw.css'

export default createGlobalStyle`
  body,html, #root{
    max-width: 100vw;
    overflow-x: hidden;
  }
  #root{
    position: relative;
  }

  input, textarea, select, button {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

`;
