import { memo } from "react";

import { useCare } from "../../../../store/service/veterinary/useCare";
import { ProcedureIcon } from "../../../../components/ui/ProcedureIcon";

import { Container } from "./styles";

export const Title = memo(() => {
  const care = useCare(st => st.data.care)
  if (!care) return null

  const Icon = ProcedureIcon(care.procedure.name)

  return (
    <Container>
      <Icon size={30} />
      <span>
        {care.pageTitle}
        <em>#{care.orderId}</em>
      </span>
    </Container>
  );
})
