import { StepsBullet } from './StepsBullet'
import { StepsContent } from './StepsContent'
import { StepsText } from './StepsText'
import { StepsRoot } from './StepsRoot'
import { useSteps } from './useSteps'

export const Steps = {
  Root: StepsRoot,
  Bullet: StepsBullet,
  Text: StepsText,
  Content: StepsContent,
  store: useSteps,
}
