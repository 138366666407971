import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  height: fit-content;
`
export const Attribute = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: ${(props) => props.theme.colors.gray[600]};
  margin: 0px;
  transform-origin: left;
  transition: all ease ${(props) => props.theme.animation.delay.normal};
  position: relative;
  span{
    display: flex;
    align-items: center;
    gap: 8px
  }
`
