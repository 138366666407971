import { Root, Track, Range, Thumb } from '@radix-ui/react-slider'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 200px;
`

export const SliderRoot = styled(Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;
`

export const SliderTrack = styled(Track)`
  background-color: ${(props) => props.theme.colors.scale[400]};
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
`

export const SliderRange = styled(Range)`
  position: absolute;
  background-color: ${(props) => props.theme.colors.scale[400]};
  border-radius: 9999px;
  height: 100%;
`

export const Tip = styled.span`
  top: -30px;
  opacity: 0;
  display: flex;
  min-width: 70px;
  padding: 0px 5px;
  border-radius: 7px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 250ms linear;
  color: ${(props) => props.theme.colors.gray[500]};
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0 2px 10px ${(props) => props.theme.colors.scale[300]};
  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 6px solid ${(props) => props.theme.colors.background};
    border-bottom: 0;
    bottom: -6px;
  }
`

export const SliderThumb = styled(Thumb)`
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0 2px 10px ${(props) => props.theme.colors.scale[800]};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.scale[500]};
    ${Tip} {
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
  }
`

export const StepMarkList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  margin: 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[300]};
  span {
    height: 10px;
    width: 1px;
    background-color: ${(props) => props.theme.colors.gray[300]};
    display: flex;
  }
`
