import styled from "styled-components";

export const Container = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  & > button {
    max-width: fit-content;
  }
`;
