import _ from "lodash";
import { Save, Undo2 } from "lucide-react";

import { DRE_GOALS } from "../../../../../../store/subscription/constants";
import { clearNumber, formatMoney } from "../../../../../../services/functions";

import { useDre } from "../../../../../../store/subscription/useDre";

import { SubscriptionDREController } from "../../../../../../services/controllers/subscription";

import { Input } from "../../../../../../components/ui/Form/Input";
import { Action } from "../../../../../../components/ui/Action";

export function ConfigureInformationGoal() {
  const CLDre = SubscriptionDREController()

  const period = useDre(st => st.data.config.period)
  const goals = useDre(st => st.data.config.goals)
  const isLoading = useDre(st => st.data.config.loading)
  const isSaving = useDre(st => st.data.config.saving)

  const validDate = _.size(clearNumber(period)) === 6

  const parseValue = (value = '') => {
    if (isLoading) return 'Carregando...'
    if (!validDate) return ''
    if (!value) return ''
    return value
  }

  if (!goals[period] && validDate) CLDre.findGoals()

  return (
    <form className="flex flex-wrap px-4 gap-y-4 gap-x-2 w-full" onSubmit={CLDre.savePeriodGoalsConfig}>
      <div className="w-full mb-4">
        <div className="flex-1 w-full max-w-[300px]">
          <Input.Root
            label="Período"
            mask='99/9999'
            value={period}
            onChange={CLDre.setConfigPeriod}
          />
        </div>
      </div>
      {Object.entries(DRE_GOALS).map(([key, goal]) => (
        <div key={key} className="flex-1 w-full min-w-[300px]">
          <Input.Root
            disabled={isLoading || isSaving || !goals[period] || !validDate}
            value={parseValue(!!goals[period] && goals[period][key]?.value)}
            onChange={(value) => CLDre.setConfigGoal(key, value)}
            {...goal}
          />
          <span className="text-sm text-zinc-400 pl-1">Valor padrão: {goal.prefix || ''}{!!goals[period] && formatMoney(goals[period][key]?.default, goal.decimals)}{goal.suffix || ''}</span>
        </div>
      ))}
      <div className="flex w-full mt-4 items-center justify-between">
        <Action.Root
          outline
          status="danger"
          type="button"
          disabled={isLoading || isSaving}
          onClick={CLDre.setDefaultGoals}
        >
          <Action.Icon icon={Undo2} />
          Usar valores padrão
        </Action.Root>
        <Action.Root disabled={isLoading || isSaving}>
          <Action.Icon icon={Save} isLoading={isSaving} />
          {isSaving ? "Aplicando" : "Aplicar"}
        </Action.Root>
      </div>
    </form>
  );
}
