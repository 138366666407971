import { rgba } from "polished";
import styled, { keyframes } from "styled-components";

const fileIconHoverAnimate = keyframes`
  0%{ transform: rotate(0deg); }
  25%{ transform: rotate(30deg); }
  50%{ transform: rotate(-30deg); }
  75%{ transform: rotate(30deg) scale(1); }
  100%{ transform: rotate(0deg) scale(1.2); }
`;

export const Container = styled.main`
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 12px 12px 12px 22px;
  height: fit-content;
  width: 100%;

  &:has(button:hover),
  &:has(button[data-selected="true"]) {
    & > button {
      opacity: 0.5;
      filter: grayscale(1);
      transform: scale(0.95);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
`;

export const Icon = styled.span`
  flex: 1;
  color: ${(props) => props.theme.colors.scale[500]};
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform-origin: center center;
  margin-bottom: auto;
`;

export const Title = styled.span`
  display: flex;
  max-width: 100%;
  height: fit-content;
  font-size: 16px;
  padding: 0 12px;
  margin: 0;
  color: ${(props) => props.theme.colors.gray[700]};
`;

export const SubTitle = styled.span`
  display: flex;
  min-width: 100%;
  height: fit-content;
  padding: 0 12px;
  margin: 0;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray[500]};
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
`;

export const BulletMenu = styled.span`
  position: relative;
  border-radius: 100px;
  width: 6px;
  height: 6px;
  color: ${(props) => props.theme.colors.scale[200]};
  background: currentColor;
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  transform-origin: right;
  &::before {
    content: "";
    right: 0;
    position: absolute;
    top: calc(100% + 5px);
    border-radius: 100px;
    width: 6px;
    height: 6px;
    background: currentColor;
    transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  }
  &::after {
    content: "";
    right: 0;
    position: absolute;
    bottom: calc(100% + 5px);
    border-radius: 100px;
    width: 6px;
    height: 6px;
    background: currentColor;
    transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  }
`;

export const Item = styled.button`
  all: unset;
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: space-between;
  gap: 4px;
  width: 100%;
  min-height: 150px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.scale[300]};
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.1)} 5px 5px 10px;
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  opacity: 0.5;
  &:hover {
    opacity: 0.9 !important;
    transform: scale(1.05) !important;
    filter: grayscale(0.3) !important;
    ${BulletMenu} {
      width: 12px;
      &::before {
        width: 18px;
      }
    }
    ${Icon} {
      & > * {
        animation: ${fileIconHoverAnimate}
          ${(props) => props.theme.animation.delay.slow} ease-in-out forwards;
      }
    }
  }
  &[data-selected="true"] {
    opacity: 1 !important;
    transform: scale(1.13) !important;
    filter: grayscale(0) !important;
    ${BulletMenu} {
      width: 12px;
      &::before {
        width: 18px;
      }
    }
  }
`;
