import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px 10px;
  & > div {
    flex: 0 1 0%;
  }
`
export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
`

export const FieldsBox = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  & > * {
    min-width: fit-content;
    flex: 1;
  }
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  & > button {
    max-width: min-content;
  }
`
export const BoxActions = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  flex: 1;
  gap: 8px;
  justify-content: flex-end;
  align-items: stretch;
  & > button {
    min-width: fit-content;
    width: 100%;
  }
`
