import React from "react";
import moment from "moment";
import { FileDown, Filter as FilterIcon } from "lucide-react";

import { Input } from "../../../../components/ui/Form/Input";
import { Filter } from "../../../../components/ui/Filter";

export function RoyaltyStatementFilter() {
  return (
    <Filter.Root onSubmit={()=>{}}>
      <Filter.Container>
        <Input.Root
          pickDate
          disabled
          label="Início"
          style={{ maxWidth: "300px" }}
          value={moment().startOf('month').format("DD/MM/YYYY")}
         />
        <Input.Root
          pickDate
          disabled
          label="Fim"
          style={{ maxWidth: "300px" }}
          value={moment().format("DD/MM/YYYY")}
         />
      </Filter.Container>
      <Filter.Actions>
        <Filter.Action disabled>
          <Filter.ActionIcon icon={FilterIcon} />
          Filtrar
        </Filter.Action>
        <Filter.Action outline type="button" disabled>
          <Filter.ActionIcon icon={FileDown} />
          Exportar
        </Filter.Action>
      </Filter.Actions>
    </Filter.Root>
  );
}
