import { rgba } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.form`
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  gap: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 10px;
  position: relative;
  ${(props) =>
    props.waiting &&
    css`
      overflow: hidden;
      cursor: not-allowed;
      border-color: ${(props) => props.theme.colors.gray[500]};
      background: ${(props) => rgba(props.theme.colors.gray[500], 0.2)};
      &::before {
        content: "⚠️ Em Construção";
        box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 1px 1px
          4px;
        position: absolute;
        background: ${(props) => props.theme.colors.scale[500]};
        left: 0;
        top: 0;
        font-weight: bold;
        padding: 5px 10px;
        color: ${(props) => props.theme.colors.background};
        font-size: 12px;
        border-radius: 0 0 5px 0;
      }
    `}
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
`;

export const FilterActions = styled.div`
  display: flex;
  gap: 15px;
`;
