import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useForm, Controller } from 'react-hook-form'

// Componentes

// Material ui - core
import {
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
} from '@material-ui/core'

// Material ui - icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { getServices } from '../services/services'
import SidebarCustomer from '../components/organisms/SidebarCustomer'
import ModalType from '../components/atoms/Modal'
import API from '../services/api'
import BasePage from './basePage'

const useStyles = makeStyles(() => ({
  total: {
    textAlign: 'center',
    color: '#666666',
    fontWeight: '400',
    display: 'block',
    fontSize: '18px',
    marginTop: '50px',
    marginBottom: '-20px',
  },
  price: {
    border: '1px solid #CCCCCC',
    textAlign: 'center',
    width: '100%',
    fontWeight: '700',
    display: 'block',
    padding: '10px',
    fontSize: '36px',
    marginBottom: '-20px',
    color: '#666666',
  },
}))

export const getEmployees = async (id = '') => {
  let data
  try {
    data = await API.get(`employees/sellers/search?text=${id}`)
  } catch (err) {
    console.log('Erro:', err)
  }

  return data
}

function Vendas({ location }) {
  const {
    state: { id },
  } = location

  const { register, control, getValues } = useForm({
    // defaultValues: {
    //   package: [{ id: "", qtd: "", checkbox: "" }],
    // },
  })

  const classes = useStyles()
  const history = useHistory()
  const [service, setService] = useState()
  const [packagesServices, setPackagesServices] = useState([])
  const [employees, setEmployees] = useState([])
  const [employee, setEmployee] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [blockButton, setBlockButton] = useState(false)
  const [services, setServices] = useState()
  const [loadServices, setLoadServices] = useState(false)
  const [discountType, setDiscountType] = useState()
  const [disabledButton, setDisabledButton] = useState(true)
  const [customServices, setCustomServices] = useState([])
  const [gifts, setGifts] = useState(true)
  const [error, setError] = React.useState(null)
  const [verification, setVerification] = React.useState(true)
  const [finalPrice, setFinalPrice] = useState(0)
  const [priceCustomServices, setPriceCustomServices] = useState(0)

  const discount = getValues()

  const isClinica = history.location.search.search('clinica=true')

  const department = isClinica >= 0 ? 'Clínica Veterinária' : 'Banho e Tosa'

  const [stateButtom, setStateButtom] = React.useState(false)

  const [open, setOpen] = useState(false)

  const getPackages = async () => {
    try {
      const { data } = await API.get(`packages/rule/${id}`)
      setService(data)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const postSell = async () => {
    try {
      const result = await API.post(`packages/rule/${id}/sell`, {
        customerId: customer?.id,
        petId: customer?.pet?.id,
        sellerId: employee,
        customServices,
        gifts,
        discount: {
          discountType,
          discountAmount: discount?.discount,
        },
      })

      setOrderId(result?.data?.packageOrderId)
      // eslint-disable-next-line no-constant-condition
      if (result?.status === 201 || 200) {
        setVerification(true)
        setOpen(true)
        setError(false)
      }
    } catch (err) {
      console.log('Erro:', err)
      setVerification(false)
      setStateButtom(false)
      setOpen(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const postExport = async () => {
    setBlockButton(true)
    try {
      await API.post(`packages/orders/${orderId}/send-to-vo`)
      history.push(
        department === 'Clínica Veterinária' ? '/packages' : '/pacotes',
      )
    } catch (err) {
      console.log('Erro:', err)
      history.push(
        department === 'Clínica Veterinária' ? '/packages' : '/pacotes',
      )
    }
  }

  const handleCustomers = async ({ target }) => {
    const { data } = await getEmployees(target.value)
    if (data.results) {
      setEmployees(data.results)
    }
  }

  const handleCustomer = (event, value) => {
    setEmployee(value?.id)
  }

  const handleSell = () => {
    if (id && customer?.id && customer?.pet?.id) {
      postSell()
      setStateButtom(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
    if (!error) {
      setTimeout(() => {
        history.push(
          department === 'Clínica Veterinária' ? '/packages' : '/pacotes',
        )
      }, 500)
    }
  }

  async function handleServices() {
    setLoadServices(true)
    const data = await getServices(customer?.pet.id, department)
    setLoadServices(false)
    setServices(data)
  }

  useEffect(() => {
    handleServices()
    if (customer?.pet?.id) setDisabledButton(false)
  }, [customer])

  useEffect(() => {
    if (id) getPackages()
  }, [id])

  const handleInformation = useCallback(() => {
    if (!services) return

    const replacedNames = packagesServices.map((item) => ({
      checkbox: item.checkbox,
      serviceId: item.id,
      quantity: item.qtd,
    }))

    const activeBonus = replacedNames.filter(
      (packageItem) => packageItem.checkbox && packageItem.quantity > 0,
    )
    const offBonus = replacedNames.filter(
      (packageItem) => !packageItem.checkbox && packageItem.quantity > 0,
    )

    const on = activeBonus.map((item) => {
      const service = services.filter(
        (service) => service.id === item.serviceId,
      )
      if (service.length) {
        return { ...item, service: service[0] }
      }
      return item
    })

    const off = offBonus.map((item) => {
      const service = services.filter(
        (service) => service.id === item.serviceId,
      )

      if (service.length) {
        return { ...item, service: service[0] }
      }
      return item
    })

    setCustomServices(off)
    setGifts(on)
  }, [packagesServices, services])

  const removePackage = useCallback(
    (index) => {
      const newpackagesServices = packagesServices
        .map((item, i) => {
          if (i !== index) return item
          return null
        })
        .filter((el) => el != null)

      setPackagesServices(newpackagesServices)
    },
    [packagesServices],
  )

  useEffect(() => {
    handleInformation()
  }, [packagesServices])

  useEffect(() => {
    if (customServices.length) {
      const newPriceCustomServices = customServices.reduce((acc, cur) => {
        const price = cur.service ? cur.service.price : 0
        const qtd = cur.service ? +cur.quantity : 0
        return acc + price * qtd
      }, 0)
      setPriceCustomServices(newPriceCustomServices)
    } else {
      setPriceCustomServices(0)
    }
  }, [customServices])

  return (
    <BasePage pageTitle="Venda Pacote">
      <ModalType
        oneButton="Exportar para PDV"
        open={open}
        err={error}
        close={handleClose}
        eventClick={postExport}
        disabled={blockButton}
        verification={verification}
        titleDescription={error ? open : 'Pacote vendido com sucesso.'}
        modalDelete={false}
      />
      <Grid
        container
        spacing={3}
        style={{
          background: '#ffffff',
          borderRadius: '7px',
          minHeight: '700px',
        }}
      >
        <Grid item xs={4} style={{ borderRight: '2px solid #f6f6f6' }}>
          <SidebarCustomer
            customer={customer}
            setCustomer={setCustomer}
            finalPrice={finalPrice}
            setFinalPrice={setFinalPrice}
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <span className={classes.total}>TOTAL A PAGAR</span>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.price}>
                {(
                  service?.totalPrice +
                  (priceCustomServices -
                    (priceCustomServices * discount.discount) / 100)
                ).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disableClearable
                options={employees}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vendedor"
                    placeholder="Digite o nome do vendedor"
                    margin="normal"
                    variant="outlined"
                    onChange={handleCustomers}
                    required
                  />
                )}
                onChange={handleCustomer}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} style={{ paddingLeft: '15px' }}>
            <h3>Serviços</h3>
          </Grid>
          {service?.services.map((item) => (
            <Grid key={item.name} container spacing={3}>
              <Grid item xs={8}>
                <TextField
                  id={item.id}
                  label="Serviço"
                  name={item.name}
                  variant="outlined"
                  fullWidth
                  value={item.name}
                  disabled
                  placeholder={item.name}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  id={item.id}
                  label="Quantidade"
                  name="codigo"
                  variant="outlined"
                  fullWidth
                  value={item.quantity}
                  disabled
                  placeholder={item.quantity}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  id={item.id}
                  label="Total"
                  name={item.price}
                  variant="outlined"
                  fullWidth
                  value={item.price.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  disabled
                  placeholder={item.quantity}
                />
              </Grid>
            </Grid>
          ))}

          <Grid
            container
            spacing={2}
            style={{ marginTop: '40px', paddingLeft: '15px' }}
          >
            <h4>Adicione mais serviços no pacote</h4>
          </Grid>
          {packagesServices.map((item, index) => (
            <Grid key={item.id} container spacing={3}>
              <Grid item xs={8}>
                <TextField
                  id="outlined-basic"
                  select
                  fullWidth
                  disabled={disabledButton}
                  value={packagesServices[index].id}
                  label="Serviço"
                  variant="outlined"
                  onChange={(e) => {
                    if (packagesServices[index]) {
                      const newPackagesServices = packagesServices.map(
                        (item, i) => {
                          if (index === i) {
                            return { ...item, id: e.target.value }
                          }
                          return item
                        },
                      )
                      setPackagesServices(newPackagesServices)
                    } else {
                      setPackagesServices((prev) => [
                        ...prev,
                        { id: e.target.value, qtd: 0, checkbox: false },
                      ])
                    }
                  }}
                >
                  {loadServices && <MenuItem value="">Carregando...</MenuItem>}
                  {services?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.internalCode} -{service.name} - R${' '}
                      {service.price}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2}>
                <TextField
                  id="qtd"
                  label="Quantidade"
                  name={`package[${index}].qtd`}
                  variant="outlined"
                  type="number"
                  value={packagesServices[index].qtd}
                  fullWidth
                  disabled={disabledButton}
                  onChange={(e) => {
                    if (packagesServices[index]) {
                      const newPackagesServices = packagesServices.map(
                        (item, i) => {
                          if (index === i) {
                            return { ...item, qtd: e.target.value }
                          }
                          return item
                        },
                      )
                      setPackagesServices(newPackagesServices)
                    } else {
                      setPackagesServices((prev) => [
                        ...prev,
                        { qtd: e.target.value, id: '', checkbox: false },
                      ])
                    }
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={`package[${index}].checkbox`}
                        checked={packagesServices[index].checkbox}
                        inputRef={register}
                        disabled={disabledButton}
                        onChange={(e) => {
                          if (packagesServices[index]) {
                            const newPackagesServices = packagesServices.map(
                              (item, i) => {
                                if (index === i) {
                                  return {
                                    ...item,
                                    checkbox: e.target.checked,
                                  }
                                }
                                return item
                              },
                            )
                            setPackagesServices(newPackagesServices)
                          } else {
                            setPackagesServices((prev) => [
                              ...prev,
                              { qtd: 0, id: '', checkbox: e.target.checked },
                            ])
                          }
                        }}
                      />
                    }
                    key="Checkbox"
                    label="bônus?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={1}>
                <Button
                  type="button"
                  onClick={() => {
                    removePackage(index)
                  }}
                >
                  <DeleteForeverIcon />
                </Button>
              </Grid>
            </Grid>
          ))}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                color="secondary"
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setPackagesServices((prev) => [
                    ...prev,
                    { checkbox: false, id: '', qtd: 0 },
                  ])
                }}
                style={{ fontSize: '12px', fontWeight: 'bold' }}
              >
                ADICIONAR MAIS SERVIÇOS
              </Button>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="discount"
                label="Valor do Desconto"
                name="discount"
                inputRef={register}
                variant="outlined"
                type="number"
                fullWidth
                disabled={disabledButton}
                onChange={() => handleInformation()}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                as={({ onChange, value }) => (
                  <TextField
                    id="outlined-basic"
                    select
                    fullWidth
                    value={value}
                    disabled={disabledButton}
                    label="Tipo do desconto"
                    variant="outlined"
                    onChange={(e) => {
                      onChange(e.target.value)
                      setDiscountType(e.target.value)
                    }}
                  >
                    <MenuItem key="by_percent" value="by_percent">
                      Porcentagem (%)
                    </MenuItem>
                    {/* <MenuItem key="by_percent" value="fixed_value">
                                                R$ (valor fixo)
                                            </MenuItem> */}
                  </TextField>
                )}
                name="discountType"
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ marginTop: '80px' }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => handleSell()}
              disabled={stateButtom}
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
            >
              Vender
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                history.push(
                  department === 'Clínica Veterinária'
                    ? '/packages'
                    : '/pacotes',
                )
              }
              style={{
                border: '1px solid #CCD219',
                color: '#CCD219',
                textTransform: 'capitalize',
                fontSize: '14px',
                width: '120px',
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BasePage>
  )
}

export default Vendas
