import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { EditorProvider } from "./Context";
import { BubbleMenu } from "./BubbleMenu";

import { EditorArea } from "./styles";

const extensions = [StarterKit];

export function EditorContent({
  content = '',
  placeholder = false,
  editable = true,
  onBlur: onBlurTrigger = () => null,
  onChange: onChangeTrigger = () => null
}) {
  const editorContent = !content && placeholder ? placeholder : content

  const editor = useEditor({
    extensions,
    content: editorContent,
    onBlur: ({ editor }) => onBlurTrigger(editor.getHTML()),
    onUpdate: ({ editor }) => onChangeTrigger(editor.getHTML()),
    editorProps: {
      attributes: {
        class: "editor-container",
      },
    },
  });

  if (editor) editor.options.editable = editable;

  return (
    <EditorProvider editor={editor}>
      <EditorArea editor={editor} />
      <BubbleMenu />
    </EditorProvider>
  );
}
