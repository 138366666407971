import { Action } from '../Action'
import { useAlert } from './useAlert'

export function AlertTrigger({ children, name, ...rest }) {

  const isOpen = useAlert((st) => st.actions.isOpen)

  const alerts = useAlert((st) => st.data.alerts)
  const alert = alerts[name]

  const toggleAlert = () => {
    isOpen(name, !alert.open)
  }

  return (
    <Action.Root type="button" onClick={toggleAlert} {...rest}>
      {children}
    </Action.Root>
  )
}


