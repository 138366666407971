import { Text, View } from '@react-pdf/renderer';

import { formatCellPhoneNumber, formatCpf, formatMoney, formatName, getPetCastratedStatus, getPetGender, getPetSpecieName } from '../../../../services/functions';

import { usePet } from '../../../../store/global/usePet';

import { PdfIcon } from '../../PdfIcon';

import { attribute, attributeIcon, attributes, bubble, container, highlight, title, titleBg, attributeAddress, addressLine } from './styles';

const iconsAttribute = {
  name: "spell-check",
  breed: "paw-print",
  birthDate: "calendar",
  age: "cake",
  weight: 'dumbbell',
  castrated: { true: "ph-plugs", false: "ph-plugs" },
  specie: {
    dog: "dog",
    cat: "cat",
  },
  gender: {
    male: 'ph-male',
    female: 'ph-female'
  },

  ownerName: 'user',
  ownerEmail: 'mail',
  ownerCpf: 'ph-identification-card',
  ownerPhone: 'phone',
  ownerAddress: 'home',
}

export function HeaderPet() {
  const pet = usePet.getState().data.pet
  const specie = getPetSpecieName(pet.petAttributes?.specie)
  const gender = getPetGender(pet.petAttributes?.gender)
  const weight = formatMoney(pet.petAttributes?.weight, 3)
  const castrated = getPetCastratedStatus(pet.petAttributes?.castrated)

  if (!pet || !pet.owner) return null

  return (
    <View style={container}>
      <View style={bubble}>
        <View style={title} >
          <View style={titleBg} />
          <Text style={highlight}>Paciente Paciente:</Text>
        </View>
        <View style={attributes}>
          <View style={{ ...attribute, width: '100%' }}>
            <PdfIcon iconName={iconsAttribute.name} style={attributeIcon} />
            <Text>{pet.name}</Text>
          </View>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.breed} style={attributeIcon} />
            <Text>{pet.petAttributes?.breed}</Text>
          </View>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.specie[pet.petAttributes?.specie]} style={attributeIcon} />
            <Text>{formatName(specie[0])}</Text>
          </View>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.gender[pet.petAttributes?.gender]} style={attributeIcon} />
            <Text>{formatName(gender[0])}</Text>
          </View>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.castrated[String(pet.petAttributes?.castrated)]} style={attributeIcon} />
            <Text>{castrated[pet.petAttributes?.gender]}</Text>
          </View>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.weight} style={attributeIcon} />
            <Text>{weight}kg</Text>
          </View>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.birthDate} style={attributeIcon} />
            <Text>{pet.petAttributes?.birthDate}</Text>
          </View>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.age} style={attributeIcon} />
            <Text>{pet.petAttributes?.age}</Text>
          </View>
        </View>
      </View>
      <View style={bubble}>
        <View style={title} >
          <View style={titleBg} />
          <Text style={highlight}>Tutor:</Text>
        </View>
        <View style={attributes}>
          <View style={{ ...attribute }}>
            <PdfIcon iconName={iconsAttribute.ownerName} style={attributeIcon} />
            <Text>{pet.owner.name.full}</Text>
          </View>
          {pet.owner?.cpf &&
            <View style={{ ...attribute }}>
              <PdfIcon iconName={iconsAttribute.ownerCpf} style={attributeIcon} />
              <Text>{formatCpf(pet.owner?.noMask?.cpf)}</Text>
            </View>
          }
        </View>
        <View style={attributes}>
          {pet.owner?.email &&
            <View style={{ ...attribute }}>
              <PdfIcon iconName={iconsAttribute.ownerEmail} style={attributeIcon} />
              <Text>{pet.owner?.noMask?.email}</Text>
            </View>
          }
          {pet.owner?.phone &&
            <View style={{ ...attribute }}>
              <PdfIcon iconName={iconsAttribute.ownerPhone} style={attributeIcon} />
              <Text>{formatCellPhoneNumber(pet.owner?.noMask?.phone)}</Text>
            </View>
          }
        </View>
        {!!pet.owner?.address.splitted.street &&
          <View style={{ ...attributes }}>
            <View style={{ ...attribute, width: '100%' }}>
              <PdfIcon iconName={iconsAttribute.ownerAddress} style={attributeIcon} />
              <View style={{ ...attributeAddress }}>
                <View style={{ ...addressLine }}>
                  <Text style={{ ...attribute }}>{pet.owner?.address.splitted.street}, {pet.owner?.address.splitted.number}</Text>
                  {pet.owner?.address.splitted.complement && <Text style={{ ...attribute }}>/{pet.owner?.address.splitted.complement}</Text>}
                  <Text style={{ ...attribute }}> - </Text>
                  <Text style={{ ...attribute }}>{pet.owner?.address.splitted.neighborhood}, </Text>
                  <Text style={{ ...attribute }}>{pet.owner?.address.blocks.city}, </Text>
                  <Text style={{ ...attribute }}>{pet.owner?.address.splitted.postalCode} </Text>
                </View>
              </View>
            </View>
          </View>
        }
      </View>
    </View>
  )
}
