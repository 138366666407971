import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { useEmployee } from '../../../store/schedule/useEmployee'
import { useSchedule } from '../../../hooks/ScheduleContext'

import { Employee } from './Employee'
import { Hours } from './Hours'

import { Container } from './styles'

export function ScheduleContent() {
  const { getEmployees } = useSchedule()
  const {
    employee: { size },
  } = useEmployee()

  const employees = getEmployees()

  return (
    <Container totalEmployees={employees.length} collumnSize={size}>
      <DndProvider backend={HTML5Backend}>
        <Hours />
        {!!employees &&
          employees.map((employee) => (
            <Employee key={employee.id} employeeId={employee.id} />
          ))}
      </DndProvider>
    </Container>
  )
}
