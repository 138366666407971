import React from 'react'

import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToApp from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import SchoolIcon from '@material-ui/icons/School'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { logout } from '../../../services/auth'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  '& #HW_badge': {
    height: '14px',
    width: '14px',
    lineHeight: '14px',
    fontSize: '11px',
    top: '14px',
    left: '12px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))(MenuItem)

function Submenu() {
  const config = {
    selector: '.notificationId',
    account: 'JmjAMy',
    translations: {
      title: 'Notificações',
      readMore: 'Read more',
      labels: {
        new: 'News',
        improvement: 'Updates',
        fix: 'Fixes',
      },
      footer: 'Ler Mais 👉',
    },
  }

  React.useEffect(() => {
    window?.Headway.init(config)
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const store = localStorage.getItem('STORE')
  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ display: 'flex' }}>
      <span style={{ paddingRight: '15px', paddingTop: '3px' }}>{store}</span>

      <span
        style={{ paddingRight: '1px', paddingTop: '0' }}
        className="notificationId"
      >
        <div style={{ position: 'absolute' }}>
          <NotificationsActiveIcon />
        </div>
      </span>

      <AccountCircle onClick={handleClick} />
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => {
            history.push('/configuracoes')
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => logout() && history.push('/')}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </StyledMenuItem>
      </StyledMenu>

      <a
        href="https://petland.sults.com.br/universidade/aluno/curso/163/0"
        target="_blank"
        rel="noreferrer"
      >
        <SchoolIcon style={{ marginLeft: '10px' }} />
      </a>
    </div>
  )
}

export default Submenu
