import { useMemo } from 'react'
import { useWindowSize , useMeasure} from "react-use";

import { Content, ScrollAreaRoot, ScrollAreaViewport, ScrollAreaScrollbar, ScrollAreaThumb } from './styles'

export function AlertContent({ children, ...rest }) {
  const [ref, alert] = useMeasure();
 const window = useWindowSize();

 const alertHeight = useMemo(()=>{
   const maxHeight = window.height * 0.9
   if(maxHeight.toFixed(0)=== alert.height.toFixed(0) )  return `${maxHeight}px`
   return 'fit-content'
 },[window.height,alert.height])

  return (
    <Content ref={ref} {...rest}>
      <ScrollAreaRoot type="hover" height={alertHeight}>
        <ScrollAreaViewport>
          {children}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ScrollAreaRoot>
    </Content>
  )
}
