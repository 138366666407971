import { useSchedule } from '../../../../../hooks/ScheduleContext'
import { GapTime } from '../GapTime'
import { Bubble } from './Bubble'

import { Container } from './styles'

export function HoursGaps({ display, gaps, employee }) {
  const {
    getBlocks,
    getSchedules,
    getBubblesByEmployeeId,
    getBubblesBetweenBlock,
    getBubblesByRoomId,
  } = useSchedule()

  const schedules = getSchedules()
  const blocks = getBlocks()

  const bubbles = !!schedules && !!blocks ? [...schedules, ...blocks] : []
  const employeeBubbles = getBubblesByEmployeeId({
    employeeId: employee.id,
    bubbles,
  })
  const roomBubbles = getBubblesByRoomId({ roomId: employee.id, bubbles })
  const bubbleList = [...roomBubbles, ...employeeBubbles]

  const bubbleInBlockGap = getBubblesBetweenBlock({
    blockGap: display,
    bubbles: bubbleList,
  })

  return (
    <Container totalGaps={gaps.length}>
      {!!bubbleInBlockGap.length &&
        bubbleInBlockGap.map((bubble) => (
          <Bubble key={bubble.id} display={display} {...bubble} />
        ))}
      {gaps.map((gap, i) => (
        <GapTime key={gap} gap={gap} employee={{ ...employee }} i={i} />
      ))}
    </Container>
  )
}
