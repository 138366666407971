
import PetClass from "./Pet";
import UserClass from "./User";
import MerchantClass from "./Merchant";
import MedicineClass from "./Medicine";

let MPet = null;
let MUser = null;
let MMedicine = null;
let MMerchant = null;

export function MerchantModel() {
  if (MMerchant) { return MMerchant; }

  MMerchant = new MerchantClass();

  return MMerchant;
}
export function UserModel() {
  if (MUser) { return MUser; }

  MUser = new UserClass();

  return MUser;
}

export function PetModel() {
  if (MPet) { return MPet; }

  MPet = new PetClass();

  return MPet;
}

export function MedicineModel() {
  if (MMedicine) { return MMedicine; }

  MMedicine = new MedicineClass();

  return MMedicine;
}
