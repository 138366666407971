import { toast } from "react-toastify";

import api from "../../api";

import { formatResponse } from "../../formatResponses/global";

export default class UserModel {
  find = async (userId) => {
    try {
      const { data } = await api.get(`employee/${userId}`);

      const formattedUser = formatResponse.user(data);
      return formattedUser;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  findGroup = async () => {
    try {
      const { data } = await api.get('employees/groups');

      const formattedGroups = formatResponse.groups(data.results);
      return formattedGroups;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
