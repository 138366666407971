import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Label = styled.label`
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  margin-bottom: 10px;
`
export const ObsBox = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d2d735;
  height: 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  transition: all 250ms ease-in-out;
  &.active {
    padding-top: 5px;
    height: 150px;
  }
`

export const TextArea = styled.textarea`
  padding: 5px 20px 5px 10px;
  width: calc(100% + 10px);
  height: 100%;
  flex: 1;
  border: none;
  &:focus {
    outline: none;
  }
`

export const ActionBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 150ms ease-in-out;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  &.active {
    opacity: 1;
    height: 30px;
  }
`

export const ActionButton = styled.button`
  border-radius: 5px;
  border: none;
  border-radius: 0;
  color: #ffffff;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 15px;
  color: #fdfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 5px 0px 0px 0px;
  background: #d2d735;
  &:hover {
    background: #bfc34b;
  }

  &.whatsapp {
    background: #1bd741;
    &:hover {
      background: #30c04d;
    }
  }
  &.call {
    background: #0090b8;
    &:hover {
      background: #1385a4;
    }
  }
`
