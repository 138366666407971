import { PotentialSubscribers } from './PotentialSubscribers';
import { Frequency } from './Frequency';
import { Pricing } from './Pricing';

export function Diagnosis(){
  return (
    <div className="flex w-full flex-col gap-4">
      <h3 className="text-2xl font-bold text-scale-500 border-b border-scale-500 mb-2">Diagnóstico</h3>
      <div className="flex flex-1 w-full flex-wrap gap-2">
       <PotentialSubscribers />
       <Frequency />
       <Pricing />
      </div>
    </div>
  )
}
