import { StyleSheet } from "@react-pdf/renderer";

import { getCurrentThemeStyle } from "../../../../../services/functions";

const theme = getCurrentThemeStyle()

export const container = StyleSheet.create({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  margin: "0 0 8px 0 ",
  padding: "0"
})

export const title = StyleSheet.create({
  width: "100%",
  fontSize: "10px",
  fontFamily: "Helvetica-Bold",
  color: theme.colors.scale[700]
})

export const medicineContent = StyleSheet.create({
  margin: "0 0 8px 0",
  width: "100%",
  padding: "0 8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "4px",
})


export const subTitleBox = StyleSheet.create({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  margin: "0",
  alignItems: "baseline",
  justifyContent: "flex-start",
  gap: "4px"
})

export const subTitle = StyleSheet.create({
  fontSize: "8px",
  gap: "4px",
  color: theme.colors.gray[500]
})

export const subTitleAmount = StyleSheet.create({
  fontFamily: "Helvetica-Bold",
  fontSize: "10px",
  color: theme.colors.scale[500]
})

export const subTitleSeparator = StyleSheet.create({
  display: "flex",
  flex: "1",
  width: "100%",
  borderBottom: `1px dashed ${theme.colors.gray[400]}`,
  height: "100%"
})

export const medicineName = StyleSheet.create({
  margin: "4px 0 0 0",
  fontSize: "12px",
  fontFamily: "Helvetica-Bold",
  color: theme.colors.scale[600]
})

export const dosage = StyleSheet.create({
  margin: "0",
  width: "100%",
  fontSize: "8px",
  color: theme.colors.gray[700]

})

export const note = StyleSheet.create({
  marginTop: "4px",
  fontSize: "8px",
  fontFamily: "Helvetica-Oblique",
  color: theme.colors.gray[500],
})

export const noteHighlight = StyleSheet.create({
  fontFamily: "Helvetica-Bold",
  fontSize: "8px",
  color: theme.colors.gray[700]
})
