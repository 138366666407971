import { Line } from './Line'
import { twMerge } from 'tailwind-merge'

import { useContext } from '../../Context'
import { useBlockTable } from '../../useBlockTable'

export function Lines({ id }){
  const { tableName } = useContext()

  const hiddenDetails = useBlockTable(st => st.data.tables[tableName].hiddenDetails)
  const blockDetails = useBlockTable(st => st.data.tables[tableName].blockDetails[id])

  if(!blockDetails) return null
  const hiddenContent = hiddenDetails.includes(id)? 'max-h-0' : 'max-h-fit'

  return (
    <div className={twMerge("flex flex-col w-full flex-1 overflow-hidden relative max-h-fit", hiddenContent)}>
      {blockDetails.map((detail) => <Line key={detail.id} blockId={id} {...detail} /> )}
    </div>
   )
}
