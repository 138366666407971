import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  overflow-x: auto;
`

export const ActivitiesTitle = styled.h3`
  font-weight: 400;
  font-size: 15px;
  margin: 0 0 20px 0;
  color: #000000;
`
