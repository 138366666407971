import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { getVets } from '../../../services/vets'

// Material ui - icones
import API from '../../../services/api'

// Componentes
import ModalType from '../../atoms/Modal'

// Base Page
import BasePage from '../../../pages/basePage'

// Serviço
import { addOrder } from '../../../services/order'
import { decodeUrlParans } from '../../../services/services'
import { getSellers } from '../../../services/sellers'

// Componente Organisms
import Scheduling from '../Scheduling'
import SidebarCustomer from '../SidebarCustomer'

moment.locale('pt-BR')

const useStyles = makeStyles({
  button: {
    color: '#fff',
    fontSize: '15px',
    borderRadius: '7px',
    border: '0',
    backgroundColor: '#25d366',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#1dba58',
      border: 0,
      color: '#fff',
    },
  },
  successTitle: {
    color: '#47A59D',
    fontSize: '36px',
    margin: 0,
    textAlign: 'center',
  },
  successNumber: {
    color: '#47A59D',
    fontSize: '36px',
    fontWeight: 'bold',
    margin: 0,
    textAlign: 'center',
  },
  back: {
    color: '#666',
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'space-around',
    textDecoration: 'none',
    margin: '50px 5px',
  },
  imageContainer: {
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
    marginTop: '15px',
  },
  textField: {
    marginTop: '10px',
  },
})

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#ccd219',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#a0a510',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const getDate = (date) => {
  const newDate = moment(date).format('YYYY-MM-DD')
  return newDate
}

const getTime = (date) => {
  const newDate = new Date(date)
  const hour =
    newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
  const minutes =
    newDate.getMinutes() < 10
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes()

  return `${hour}:${minutes}`
}

function AgendamentoVeterinario({ event, professional }) {
  const classes = useStyles()
  const { data } = useParams()

  if (data) {
    const byUrl = decodeUrlParans(data)
    event = { start: new Date(byUrl.event.start) }
    professional = byUrl.professional
  }

  const date = getDate(event.start)
  const time = getTime(event.start)

  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: {
      scheduling: [
        {
          professional: '',
          duration: '',
          service: '',
          time: '',
        },
      ],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'scheduling',
  })
  const schedulingFields = watch('scheduling')

  const [availableTimes, setAvailableTimes] = useState([])
  const [services, setServices] = useState([])
  const [order, setOrder] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [errorCustomer, setErrorCustomer] = useState(false)
  const [sellers, setSellers] = useState([])
  const [errorSeller, setErrorSeller] = useState(false)
  const [seller, setSeller] = useState('')
  const [vets, setVets] = useState([])
  const [errorVet, setErrorVet] = useState(false)
  const [vet, setVet] = useState('')

  // State Cupom
  const loading = false

  // FINAL PRICE
  const [finalPrice, setFinalPrice] = useState(0)

  const [stateButtom, setStateButtom] = React.useState(false)

  // state array horas
  const [schedulings, setSchedulings] = useState([
    {
      id: Math.random(),
      service: null,
      time,
      duration: null,
      start: event.start,
    },
  ])

  // Modal
  const [openModal, setOpenModal] = React.useState(null)
  const error = null

  const closeModal = () => {
    setOpenModal(false)
    // history.push(`/clinica`)
  }

  const handleSellers = async () => {
    const result = await getSellers()
    setSellers(result)
  }

  const handleSeller = (event, value) => {
    setSeller(value?.id)
  }

  const onSubmit = async (data) => {
    setStateButtom(true)
    if (!customer) {
      setErrorCustomer(true)
      setStateButtom(false)
      return
    }

    if (!seller) {
      setErrorSeller(true)
      setStateButtom(false)
      return
    }

    if (!vet) {
      setErrorVet(true)
      setStateButtom(false)
    }

    const { description } = data

    const response = await API.post('service-order/appointments/new', {
      department: 'Clínica Veterinária',
      appointmentDate: date,
      comment: description,
      customerId: customer.id,
      petId: customer.pet.id,
      procedureId: schedulings[0].service,
      sellerId: seller,
      vetId: vet,
      startsAt: schedulings[0].time,
      roomId: professional.resourceId,
      durationInMinutes: parseInt(schedulings[0].duration, 10) || 60,
    })
    setOrder(response?.data)
    setOpenModal(response.data.message)
    setStateButtom(false)
  }

  const handleAddOrder = async (serviceOrders = []) => {
    const {
      data: { availableTimes: times = [] },
    } = await addOrder(date, professional.resourceId, serviceOrders)
    setAvailableTimes(times)
  }

  async function handleServices() {
    const { data } = await API.get('services/vet-procedures')
    setServices(data.procedures)
  }

  React.useEffect(() => {
    getVets()
  }, [])

  useEffect(() => {
    if (customer) handleServices()
  }, [customer])

  useEffect(() => {
    handleAddOrder()
    window.scrollTo(0, 0)
  }, [])

  return (
    <BasePage pageTitle="Novo Agendamento">
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />
      <Grid
        container
        spacing={3}
        style={{ background: '#ffffff', borderRadius: '7px' }}
      >
        <Grid item xs={4} style={{ borderRight: '2px solid #f6f6f6' }}>
          <SidebarCustomer
            order={order}
            loading={loading}
            customer={customer}
            setCustomer={setCustomer}
            priceCupom=""
            schedulings={schedulingFields}
            services={services}
            finalPrice={finalPrice}
            setFinalPrice={setFinalPrice}
          />
          <Grid item xs={12}>
            <Divider variant="fullWidth" style={{ marginTop: '20px' }} />
          </Grid>

          <Grid item xs={12} className={classes.subtitle}>
            Vendedor
          </Grid>

          <Autocomplete
            disableClearable
            required
            options={sellers}
            loading
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendedor"
                placeholder="Digite o nome do vendedor"
                margin="normal"
                variant="outlined"
                onChange={handleSellers}
              />
            )}
            onChange={handleSeller}
          />
        </Grid>
        <Grid item xs={8}>
          {order ? (
            <>
              <Grid item xs={12}>
                <p className={classes.successTitle}>Serviço Agendado</p>
                <p className={classes.successNumber}>
                  {order?.appointmentOrderId}
                </p>
              </Grid>
              <Grid item xs={12} className={classes.imageContainer}>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{
                    pathname: `https://wa.me/55${
                      customer.phone
                    }?text=Olá ${customer.owner
                      .split(' ')
                      .slice(0, 1)
                      .join(
                        '',
                      )} segue o resumo do seu agendamento da clinica Dra mei, Pet: ${
                      customer?.label
                    } Data: ${moment(date).format('DD/MM/YYYY')} Horário: ${
                      schedulings[0].time
                    }`,
                  }}
                  target="_blank"
                >
                  <Button
                    endIcon={<WhatsAppIcon />}
                    type="submit"
                    color="secondary"
                    size="large"
                    className={classes.button}
                  >
                    Enviar por WhatsApp
                  </Button>
                </Link>
              </Grid>

              <Grid item xs={12}>
                <Link to="/agenda" className={classes.back}>
                  VOLTAR PARA AGENDA
                </Link>
              </Grid>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <h3>Adicione o Serviço</h3>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center">
                {schedulings.map(
                  (item, index) =>
                    item && (
                      <Scheduling
                        key={item.id}
                        index={index}
                        serviceId={item.id}
                        serviceDuration={item.duration}
                        time={item.time}
                        services={services}
                        professional={professional}
                        availableTimes={availableTimes}
                        setSchedulings={setSchedulings}
                        schedulings={schedulings}
                        duration={item.duration}
                        control={control}
                        register={register}
                        fields={fields}
                        remove={remove}
                        append={append}
                        item={item}
                        customer={customer}
                        setVets={setVets}
                        setVet={setVet}
                        vets={vets}
                        segment="clinicaveterinaria"
                      />
                    ),
                )}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      label="Observação"
                      inputRef={register}
                      id="description"
                      name="description"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CustomButton
                    fullWidth
                    color="secondary"
                    size="large"
                    type="submit"
                    justify="center"
                    alignItems="center"
                    className={classes.button}
                    disabled={stateButtom}
                  >
                    Salvar
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={errorCustomer || errorSeller}
        autoHideDuration={3000}
        onClose={() => {
          setErrorCustomer(false)
          setErrorSeller(false)
        }}
      >
        <Alert severity="warning">
          {errorCustomer
            ? 'Por favor, selecione o cliente antes de continuar!'
            : 'Por favor, selecione o vendedor antes de continuar!'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorVet}
        autoHideDuration={3000}
        onClose={() => {
          setErrorVet(false)
        }}
      >
        <Alert severity="warning">
          Por favor, selecione o profissional antes de continuar!
        </Alert>
      </Snackbar>
    </BasePage>
  )
}

export default AgendamentoVeterinario
