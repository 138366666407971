import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  merchant: false,
};

export const useMerchant = create((set) => ({
  data: defaultData,
  actions: {
    setMerchant: (merchant = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!merchant) return;

          draft.data.merchant = merchant;
        })
      ),

    updateMerchant: (merchantValues = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!merchantValues) return;

          Object.keys(merchantValues).forEach((merchantKey) => {
            draft.data.merchant[merchantKey] = merchant[merchantKey];
          });
        })
      ),
  },
}));
