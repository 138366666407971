import { Coins } from "lucide-react";
import { SubscriptionPricingController } from "../../../../services/controllers/subscription";
import { usePricing } from "../../../../store/subscription/usePricing";
import { formatMoney } from "../../../../services/functions";

export function Pricing(){
  const CLSubscriptionPricing = SubscriptionPricingController()
  CLSubscriptionPricing.init()

  const pricingTable = usePricing(st => st.data.table)
  const pricingRecommendation = usePricing(st => st.data.recommendation)
  const pricingPercentageCorrect = usePricing(st => st.data.percentageCorrect)

  const planList  = _.values(pricingTable)[0]

  return (
    <div className="border rounded-md overflow-hidden flex flex-col flex-[3] w-full shadow-md space-y-4 h-full py-2 px-4  min-w-[400px]">
      <div className="flex flex-col gap-2 w-full">
        <h4 className="text-xl text-scale-500 w-full font-bold flex items-center"><Coins className="mr-2 w-6 h-6" />Precificação</h4>
        <p className="flex flex-col w-full">
          <span className="text-base text-zinc-400">O preço ideal da assinatura é estar abaixo de <strong>{formatMoney(pricingRecommendation * 100, 1)}%</strong> do valor do serviço. Sua loja está com <strong>{formatMoney(pricingPercentageCorrect * 100, 1)}% dos preços corretos</strong>.</span>
        </p>
        <p className="flex gap-4 w-full">
          <span className="text-sm text-rose-700 flex gap-1 items-center">
          <u className="bg-rose-500 w-5 h-3 flex border border-rose-700"/>
            A cima do preço
          </span>
          <span className="text-sm text-green-700 flex gap-1 items-center">
            <u className="bg-green-500 w-5 h-3 flex border border-green-700"/>
            Preço ideal
          </span>
          <span className="text-sm text-amber-700 flex gap-1 items-center">
            <u className="bg-amber-400 w-5 h-3 flex border border-amber-700"/>
            A baixo do preço
          </span>
        </p>
      </div>
      <div className="flex w-full">
        <div className="flex w-full flex-1 flex-col border border-zinc-700 rounded-md overflow-hidden">
        <p className="w-full grid grid-cols-4 border-b border-zinc-700 last:border-none">
          <span className="text-base font-semibold p-3 border-r border-zinc-700" />
          {!!planList && planList.map(plan =>(
            <span className="text-base p-3 border-r border-zinc-700 last:border-none text-center">
              {plan.plano}
            </span>
          ))}
        </p>

          {!!pricingTable && Object.entries(pricingTable).map(([key,plans])=>(
            <p className="w-full grid grid-cols-4 border-b border-zinc-700 last:border-none">
              <span className="text-base font-semibold p-3 border-r border-zinc-700">
                {key}
              </span>
              {plans.map(plan =>(
                <span
                  data-status={plan.status}
                  className="text-base p-3 border-r border-zinc-700 last:border-none text-center text-zinc-50 data-[status=green]:bg-green-500 data-[status=red]:bg-rose-500 data-[status=yellow]:bg-amber-400"
                >
                  R$ {formatMoney(plan.precoAssinatura)}
                </span>
              ))}
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}
