import { ClipboardList } from "lucide-react";

import { Drawer } from "../../../../../../components/ui/Drawer";

import { VeterinaryPrescriptionController } from "../../../../../../services/controllers/VeterinaryCare";

import { DocumentsHeader } from "../../../components/DocumentsHeader";

import { PrescriptionContent } from "../PrescriptionContent";

import { Container, PreviewContent, Title } from "./styles";

export function PrescriptionPreview() {
  const CLVetPrescription = VeterinaryPrescriptionController();
  const isOpen = Drawer.store(st => st.data.drawers[CLVetPrescription._drawerName].open);

  const incompleteRegistrationWarningContent = CLVetPrescription.getPrescriptionWarningsContent('incompleteRegistration')

  return (
    <Container>
      <Title>
        <ClipboardList size={24} /> Prévia da Receita
      </Title>
      {isOpen && <DocumentsHeader name="prescriptions" warning={incompleteRegistrationWarningContent} />}
      <PreviewContent>
        <PrescriptionContent />
      </PreviewContent>
    </Container>
  );
}
