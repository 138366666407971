import { produce } from "immer";
import _ from "lodash";
import { create } from "zustand";

const defaultData = {
  tables: {}
};

const defaultTable = {
  hiddenDetails: [],
  blocks: false,
  columns: [],
  blockColumns: {},
  blockDetails: {},
  blockDetailsColumns: {},
  linesColumns: false,
};


export const useBlockTable = create((set) => ({
  data: defaultData,
  actions: {
    register: (name, defaultValues) =>
      set((state) =>
        produce(state, (draft) => {
          if (!!state.data.tables[name]) return

          draft.data.tables[name] = defaultTable;

          if (!!defaultValues?.blocks) {
            draft.data.tables[name].blocks = defaultValues.blocks;
          }

          if (!!defaultValues?.columns) {
            draft.data.tables[name].columns = defaultValues.columns;
          }

          if (!!defaultValues?.lines) {
            draft.data.tables[name].lines = defaultValues.lines;
          }
          if (!!defaultValues?.linesColumns) {
            draft.data.tables[name].linesColumns = defaultValues.linesColumns;
          }
          if (!!defaultValues?.hiddenDetails) {
            draft.data.tables[name].hiddenDetails = defaultValues.hiddenDetails;
          }

        })
      ),

    addBlocks: (name, blocks) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name]) return

          const currentBlocks = state.data.tables[name].blocks
          const newBlocks = _.concat([], blocks)

          draft.data.tables[name].blocks = _.unionBy(currentBlocks, newBlocks, 'id');
        })
      ),

    clearBlocks: (name) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name]) return

          draft.data.tables[name].blocks = defaultTable.blocks
          draft.data.tables[name].columns = defaultTable.columns
          draft.data.tables[name].blockColumns = defaultTable.blockColumns
          draft.data.tables[name].blockDetails = defaultTable.blockDetails
          draft.data.tables[name].blockDetailsColumns = defaultTable.blockDetailsColumns
          draft.data.tables[name].linesColumns = defaultTable.linesColumns
        })
      ),

    addColumns: (name, columns) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name] || !columns ) return

          const currentColumns = state.data.tables[name].columns
          const newColumns = _.concat([], columns)

          draft.data.tables[name].columns = _.union(currentColumns, newColumns);
        })
      ),

    addBlockColumns: (name, blockId, columns) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name] || !blockId || !columns) return

          if(!state.data.tables[name].blockColumns[blockId]){
            draft.data.tables[name].blockColumns[blockId] = {}
          }

          _.merge(draft.data.tables[name].blockColumns[blockId], columns)
        })
      ),

    addBlockDetails: (name, blockId, details) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name] || !blockId || !details) return

          if(!state.data.tables[name].blockDetails[blockId]){
            draft.data.tables[name].blockDetails[blockId] = []
          }

          draft.data.tables[name].blockDetails[blockId] = _.union(state.data.tables[name].blockDetails[blockId], _.concat([],details))
        })
      ),

    addBlockDetailsColumns: (name, blockId, columns) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name] || !blockId || !columns) return

          if(!state.data.tables[name].blockDetailsColumns[blockId]){
            draft.data.tables[name].blockDetailsColumns[blockId] = {}
          }

          _.merge(draft.data.tables[name].blockDetailsColumns[blockId], columns)
        })
      ),

    removeHeaderColumns: (name, blockId, id) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name] || !id || !blockId) return

          const currentColumns = state.data.tables[name].columns[blockId]

          draft.data.tables[name].columns[blockId] = _.reject(currentColumns, { id });
        })
      ),

    addBlockLines: (name, blockId, lines) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name] || !blockId || !lines) return

          if (!state.data.tables[name].lines) {
            draft.data.tables[name].lines = {}
          }

          if (!state.data.tables[name].lines[blockId]) {
            draft.data.tables[name].lines[blockId] = []
          }

          const currentLines = state.data.tables[name].lines[blockId]
          const newLines = _.concat([], lines)

          draft.data.tables[name].lines[blockId] = _.unionBy(currentLines, newLines, 'id');
        })
      ),

    addBlockLineColumns: (name, lineId, columns) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name] || !columns || !lineId) return

          if (!state.data.tables[name].linesColumns) {
            draft.data.tables[name].linesColumns = {}
          }

          if (!state.data.tables[name].linesColumns[lineId]) {
            draft.data.tables[name].linesColumns[lineId] = []
          }

          const currentColumns = state.data.tables[name].linesColumns[lineId]
          draft.data.tables[name].linesColumns[lineId] = _.unionBy(currentColumns, _.concat([], columns), 'id');
        })
      ),

    toogleHiddenLines: (name, blockId) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.tables[name]) return

          const hiddenBlocks = state.data.tables[name].hiddenDetails

          if (hiddenBlocks.includes(blockId)) {
            draft.data.tables[name].hiddenDetails = _.without(hiddenBlocks, blockId);
            return
          }

          draft.data.tables[name].hiddenDetails.push(blockId);
        })
      ),
  },
}));
