import { create } from 'zustand'
import { produce } from 'immer'
import {
  getLaboratories,
  sortVaccines,
  updateValues,
  vaccineBodyToEdit,
  vaccineConciliation,
} from './protocolFuncions'

export const useVaccines = create((set, get) => ({
  vaccinesList: false,
  actions: {
    updateState: async (id, status) => {
      const vaccines = get().vaccinesList
      const updateVaccines = vaccines.find((vaccine) => vaccine.id === id)
      let { laboratories } = updateVaccines

      if (!laboratories) {
        laboratories = await getLaboratories(updateVaccines.vaccineId)
      }

      set((state) =>
        produce(state, (draft) => {
          const updateIndex = state.vaccinesList.findIndex(
            (vaccine) => vaccine.id === id,
          )
          draft.vaccinesList[updateIndex].actionState = status
          draft.vaccinesList[updateIndex].laboratories = laboratories
        }),
      )
    },

    setVaccines: (vaccines) => {
      set((state) =>
        produce(state, (draft) => {
          draft.vaccinesList = sortVaccines(vaccineConciliation(vaccines))
        }),
      )
    },
    remove: (id) =>
      set((state) =>
        produce(state, (draft) => {
          draft.vaccinesList = sortVaccines(
            draft.vaccinesList.filter((vaccine) => vaccine.id !== id),
          )
        }),
      ),

    update: async (id, newData) => {
      const vaccines = get().vaccinesList
      const updateVaccines = vaccines.find((vaccine) => vaccine.id === id)

      set((state) =>
        produce(state, (draft) => {
          draft.vaccinesList = sortVaccines(
            state.vaccinesList.map((vaccine) => {
              if (vaccine.id === id) {
                return vaccineConciliation({ ...vaccine, ...newData })
              }
              return vaccine
            }),
          )
        }),
      )

      updateValues(id, vaccineBodyToEdit({ ...updateVaccines, ...newData }))
    },
  },
}))
