import styled from 'styled-components'

import { Grid, MenuItem, TextField } from '@material-ui/core'

export const Container = styled(Grid)`
  background: #fff;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &.MuiGrid-grid-xs-6 {
    flex-basis: calc(50% - 24px);
  }
`

export const BoxColumn = styled(Container)`
  flex-direction: column;
`

export const Item = styled(Grid)`
  padding: 12px;
`

export const Select = styled(TextField)``

export const Option = styled(MenuItem)`
  margin-bottom: 20px;
`
