import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  isWaiting: false,
  invoice: [],
  summary: {
    totalInputs: 0,
    totalFees: 0,
    totalChargeBacks: 0,
    netValue: 0,
  },
  filter: {
    dateFrom: null,
    dateTo: null,
    type: null,
    summaryType: null,
    cpf: null,
  },
  pagination: {
    page: 0,
    totalPages: 0,
    perPage: 10,
    totalDocuments: 0,
  },
};

export const useInvoice = create((set) => ({
  data: defaultData,
  actions: {
    setSummary: (summaryData) =>
      set((state) =>
        produce(state, (draft) => {
          const summary = state.data.summary;
          draft.data.summary = { ...summary, ...summaryData };
        })
      ),

    setInvoice: (invoices, pagination) =>
      set((state) =>
        produce(state, (draft) => {
          const page =
            typeof pagination === "number" ? pagination : pagination.page;

          draft.data.invoice[page] = invoices;
          draft.data.pagination = pagination;

          draft.data.isLoading = false;
          draft.data.isWaiting = false;
        })
      ),
    setFilter: (filterProps) =>
      set((state) =>
        produce(state, (draft) => {
          const filter = state.data.filter;
          draft.data.filter = { ...filter, ...filterProps };
        })
      ),

    setPaginationPage: (page) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pagination.page = page;
        })
      ),

    setPaginationPerPage: (perPage) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pagination.page = 0;
          draft.data.pagination.perPage = perPage;
          draft.data.invoice = [];
        })
      ),

    setLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isLoading = status;
          draft.data.isWaiting = status;
        })
      ),

    setWaiting: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isWaiting = status;
        })
      ),

    clearInvoice: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.invoice = [];
        })
      ),
    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),
  },
}));
