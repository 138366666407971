import styled from "styled-components";


export const PreviewSeparator = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  margin: 0;
  border-top: 1px solid ${(props) => props.theme.colors.scale[300]};
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[500]};
`;
