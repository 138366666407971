import { NewScheduling } from "./NewScheduling";
import { Scheduled } from "./Scheduled";
import { InService } from "./InService";
import { Finished } from "./Finished";
import { BudgetSent } from "./BudgetSent";
import { useSchedule } from "../../store/schedule/useSchedule";

export function SchedulingContent() {
  const status = useSchedule((st) => st.data.status);

  switch (status) {
    case "newSchedule":
      return <NewScheduling />;
    case "scheduled":
      return <Scheduled />;
    case "waitingStart":
      return <InService />;
    case "checkIn":
      return <InService />;
    case "finished":
      return <Finished />;
    case "checkout":
      return <BudgetSent />;
    default:
      return <NewScheduling />;
  }
}
