import { SubscriptionPricingModel } from "../../models/subscription";
import { usePricing } from "../../../store/subscription/usePricing";
import _ from "lodash";


export default class SubscriptionPricingController {


  setTable = usePricing.getState().actions.setTable
  setLoading = usePricing.getState().actions.setLoading
  setPrcentageCorrect = usePricing.getState().actions.setPrcentageCorrect
  setRecommendation = usePricing.getState().actions.setRecommendation

  constructor() {
    this.name = "subscription-pricing";

    this.MSubscriptionPricing = SubscriptionPricingModel();
  }

  init = _.once(async () => {
    await this.findTable()
  });


  findTable = async () => {
    this.setLoading(true)

    const result =  await this.MSubscriptionPricing.findTable();

    this.setTable(result.table)
    this.setPrcentageCorrect(result.percentageCorrect)
    this.setRecommendation(result.recommendation)

    this.setLoading(false)
  };
}
