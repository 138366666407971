import moment from "moment"
import { formatMoney, formatName } from "../../services/functions"

export const DRE_DEFAULT_BLOCK = {
  header: { id: 'header', type: 'neutral' }
}

export const DRE_MAX_SHOW_MONTHS = 5

export const DRE_GOALS = {
  margin: {
    id: 'Margem',
    label: 'Margem',
    number: true,
    suffix: '%',
    decimals: 1,
    disabled: true,
  },
  subscribers: {
    id: 'Assinaturas',
    label: 'Total de Assinantes',
    number: true,
    decimals: 0,
  },
  invoicing: {
    id: 'Faturamento',
    label: 'Faturamento',
    number: true,
    prefix: 'R$ ',
    decimals: 2,
  },
  cancellations: {
    id: 'Cancelamentos',
    label: 'Limite de Cancelamentos',
    number: true,
    decimals: 0,
  },
}

export const DRE_EXPENSES = {
  fee: {
    id: 'Taxa Transacional',
    group: 'Taxas',
    label: 'Taxa Transacional',
    suffix: '%',
    number: true,
    decimals: 1,
    disabled: true,
  },
  iss: {
    id: 'ISS',
    group: 'Taxas',
    label: 'ISS',
    suffix: '%',
    number: true,
    decimals: 1,
  },
  employees: {
    id: 'Pessoas',
    group: 'Pessoas',
    label: 'Salário base de um banhista',
    prefix: 'R$ ',
    number: true,
    decimals: 2,
  },
  commission: {
    id: 'Comissão',
    group: 'Pessoas',
    label: 'Comissão por banho',
    suffix: '%',
    number: true,
    decimals: 0,
  },
  neutralizing: {
    id: 'Shampoo Neutralizador',
    group: 'Banho/Produtos',
    label: 'Shampoo Neutralizador (5L)',
    prefix: 'R$ ',
    number: true,
    decimals: 2,
  },
  neutral: {
    id: 'Shampoo Neutro',
    group: 'Banho/Produtos',
    label: 'Shampoo Neutro (5L)',
    prefix: 'R$ ',
    number: true,
    decimals: 2,
  },
  conditioner: {
    id: 'Condicionador',
    group: 'Banho/Produtos',
    label: 'Condicionador (5L)',
    prefix: 'R$ ',
    number: true,
    decimals: 2,
  },
  pretties: {
    id: 'Adereços',
    group: 'Banho',
    label: 'Adereço',
    prefix: 'R$ ',
    number: true,
    decimals: 2,
  },
  towels: {
    id: 'Toalhas',
    group: 'Banho',
    label: 'Toalha',
    prefix: 'R$ ',
    number: true,
    decimals: 2,
  },
}

export const DRE_RESPONSE_BLOCKS = {
  header: {
    type: 'neutral',
    format: (value) => formatName(moment(value).locale('pt-br').format('MMMM/YYYY'))
  },
  receivable: {
    name: 'Receita',
    column: 'receitas',
    type: 'inflow',
    format: (value) => `R$ ${formatMoney(value)}`,
  },
  costs: {
    name: 'Custos',
    column: 'custos',
    type: 'outflow',
    format: (value) => `R$ ${formatMoney(value)}`,
  },
  grossResult: {
    name: 'Resultado Bruto',
    column: 'resultadoBruto',
    type: 'settings',
    format: (value) => `R$ ${formatMoney(value)}`,
  },
  expenses: {
    name: 'Despesas',
    column: 'despesas',
    type: 'outflow',
    format: (value) => `R$ ${formatMoney(value)}`,
  },
  operationResult: {
    name: 'Resultado Operacional',
    column: 'resultadoOperacional',
    type: 'settings',
    format: (value) => `R$ ${formatMoney(value)}`,
  },
}

export const BATH_USED_PRODUCT = {
  neutralizing: {
    name: 'Shampoo Neutralizador',
    usage: {
      short: {
        name: 'Curto',
        quantities: ['100ml', '100ml', '300ml', '400ml', '600ml']
      },
      medium: {
        name: 'Médio',
        quantities: ['100ml', '200ml', '500ml', '600ml', '900ml']
      },
      long: {
        name: 'Longo',
        quantities: ['200ml', '300ml', '600ml', '800ml', '1.200ml']
      },
    }
  },
  neutral: {
    name: 'Shampoo Neutro',
    usage: {
      short: {
        name: 'Curto',
        quantities: ['100ml', '100ml', '300ml', '400ml', '600ml']
      },
      medium: {
        name: 'Médio',
        quantities: ['100ml', '200ml', '500ml', '600ml', '900ml']
      },
      long: {
        name: 'Longo',
        quantities: ['200ml', '300ml', '600ml', '800ml', '1.200ml']
      },
    },
  },
  conditioner: {
    name: 'Condicionador',
    usage: {
      short: {
        name: 'Curto',
        quantities: ['50ml', '50ml', '150ml', '200ml', '300ml']
      },
      medium: {
        name: 'Médio',
        quantities: ['50ml', '100ml', '250ml', '300ml', '450ml']
      },
      long: {
        name: 'Longo',
        quantities: ['100ml', '150ml', '300ml', '400ml', '600ml']
      },
    },
  },
}
