import styled from 'styled-components'

export const Shadow = styled.div`
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100%;
  z-index: 110;
  opacity: 0;
  display: none;
  transition: opacity 250ms linear;
  &.open {
    display: initial;
    opacity: 1;
  }
`

export const Container = styled.div`
  z-index: 111;
  left: 0;
  top: 0;
  position: fixed;
  padding-bottom: 100px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #ffffff;
  width: 640px;
  height: 100%;
  transition: all 150ms linear;
  transform: translate(-100%, 0px);
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  &.open {
    display: flex;
    transform: translate(0%, 0px);
  }
`
export const Head = styled.h1`
  background: #d2d735;
  margin: 0 0 10px 0;
  color: #fff;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
  background: none;
  color: #ffff;
  cursor: pointer;
  transition: all 150ms linear;
  &:hover {
    transform: scale(1.5);
  }
`

export const PetList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px 20px;
  width: 100%;
`
export const PetInformationBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`
