import React, { useState, useEffect } from 'react'
import InputMask from "react-input-mask";
import { useForm, Controller } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import { newPet, getPetAttributes } from '../../../services/pets'

function NewPet({ customerData }) {
  const { customerId } = customerData
  const { register, handleSubmit, unregister, control } = useForm()
  const [attributes, setAttributes] = useState({})

  const [stateButtom, setStateButtom] = React.useState(false)

  const onSubmit = async (data) => {
    setStateButtom(true)
    const {
      name,
      Espécie,
      Raça,
      Sexo,
      Peso,
      Porte,
      Pelagem,
      Castrado,
      birthdate,
      comments,
      Status,
    } = data


    const payload = {
      name,
      birthdate,
      comments,
      attributes: {
        Espécie,
        Raça,
        Sexo,
        Peso,
        Porte,
        Pelagem,
        Castrado,
        Status: Status ? 'Vivo' : 'Morto',
      },
    }

    const valResult = await newPet(customerId, payload)

    if (valResult?.status === 201) {
      unregister()
      customerData.callBack()
    } else {
      // setOpenModal(valResult?.data?.friendly_message);
      customerData.callBack()
      setStateButtom(false)
    }
  }

  const handleAttributes = async () => {
    const values = await getPetAttributes()
    setAttributes(values)
  }

  useEffect(() => {
    handleAttributes()
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} xs={12}>
        <Grid item xs={12}>
          <h3>Dados do Pet</h3>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Nome do Pet"
            variant="outlined"
            inputRef={register}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue=""
            name="Espécie"
            as={
              <TextField
                required
                fullWidth
                select
                label="Espécie"
                variant="outlined"
              >
                {attributes['Espécie'] &&
                  attributes['Espécie'].map((value) => (
                    <MenuItem key={value._id} value={value.label}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue=""
            name="Raça"
            as={
              <TextField fullWidth select label="Raça" variant="outlined">
                {attributes['Raça'] &&
                  attributes['Raça'].map((value) => (
                    <MenuItem key={value._id} value={value.label}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue=""
            name="Sexo"
            as={
              <TextField
                required
                fullWidth
                select
                label="Sexo"
                variant="outlined"
              >
                {attributes.Sexo &&
                  attributes.Sexo.map((value) => (
                    <MenuItem key={value._id} value={value.label}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            name="Peso"
            label="Peso"
            variant="outlined"
            type="number"
            inputRef={register}
            inputProps={{
              maxLength: 13,
              step: '0.01',
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue=""
            name="Porte"
            as={
              <TextField
                required
                fullWidth
                select
                label="Porte"
                variant="outlined"
              >
                {attributes.Porte &&
                  attributes.Porte.map((value) => (
                    <MenuItem key={value._id} value={value.label}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue=""
            name="Pelagem"
            as={
              <TextField
                required
                fullWidth
                select
                label="Pelagem"
                variant="outlined"
              >
                {attributes.Pelagem &&
                  attributes.Pelagem.map((value) => (
                    <MenuItem key={value._id} value={value.label}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue=""
            name="Castrado"
            as={
              <TextField fullWidth select label="Castrado" variant="outlined">
                {attributes.Castrado &&
                  attributes.Castrado.map((value) => (
                    <MenuItem key={value._id} value={value.label}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="birthdate"
            as={
              <InputMask mask="99/99/9999">
                {(inputProps) => (
                  <TextField
                    fullWidth
                    name="birthdate"
                    label="Nascimento"
                    variant="outlined"
                    {...inputProps}
                  />
                )}
              </InputMask>
            }
          />
        </Grid>
        < Grid item xs={12}>
          <TextField
            fullWidth
            name="comments"
            label="Observações"
            variant="outlined"
            multiline
            rows={2}
            inputRef={register}
          />
        </Grid>

        <Grid item xs={12}>
          <Switch
            defaultChecked
            color="primary"
            name="Status"
            inputRef={register}
          />
          Ativo
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={stateButtom}
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              customerData.callBack()
            }}
            style={{
              border: '1px solid #CCD219',
              color: '#CCD219',
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '120px',
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewPet
