import _ from "lodash";

import { DRE_MAX_SHOW_MONTHS } from "../../../../store/subscription/constants";

import { SubscriptionDREController } from "../../../../services/controllers/subscription";

import { Select } from "../../../../components/ui/Form/Select";
import { useDre } from "../../../../store/subscription/useDre";

import { Action } from "../../../../components/ui/Action";
import { Settings } from "lucide-react";

export function Actions() {
  const showingMonths = useDre(st => st.data.months)
  const CLDre = SubscriptionDREController()

  return (
    <div className="flex w-full items-center gap-4">
      <div className="flex w-full max-w-[250px] items-center gap-4">
        <Select.Root
          full
          label="Número de Meses"
          value={showingMonths.length}
          onChange={CLDre.setTotalMonths}
        >
          {_.range(1, DRE_MAX_SHOW_MONTHS + 1).map((i)=>(
            <Select.Option value={i}>{i} Mês</Select.Option>
          ))}
        </Select.Root>
      </div>
      <div className="flex items-center ml-auto">
        <Action.Root
          outline
          className="group"
          onClick={CLDre.openConfigurationDrawer}
        >
          <Action.Icon icon={Settings} className="group-hover:animate-spin"/>
          Configurar Informações
        </Action.Root>
      </div>
    </div>
  )
}
