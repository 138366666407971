import { Container, PetTitle } from "./styles";

export function PetCardRoot({
  children,
  noAction,
  size = "big",
  title = false,
  ...rest
}) {
  let tagName = "button";

  if (rest.as) {
    tagName = rest.as;
  }

  if (noAction) {
    tagName = "div";
  }

  return (
    <Container {...rest} as={tagName} className={size}>
      {title && <PetTitle>{title}:</PetTitle>}
      {children}
    </Container>
  );
}
