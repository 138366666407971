import styled from 'styled-components'
import {
  Portal as PortalTooltip,
  Content as ContentTooltip,
  Arrow as ArrowTooltip,
} from '@radix-ui/react-tooltip'

export const Container = styled(PortalTooltip)``

export const Content = styled(ContentTooltip)`
  background: ${(props) => props.theme.colors.background};
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  z-index: 21;
`

export const Arrow = styled(ArrowTooltip)`
  fill: ${(props) => props.theme.colors.background};
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
`

export const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  margin-bottom: 10px;
`

export const PetName = styled.p`
  font-size: 16px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.colors.gray[800]};
  span {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  svg {
    font-size: 18px;
    color: ${(props) => props.theme.colors.scale[400]};
    filter: drop-shadow(0px 0px 1px ${(props) => props.theme.colors.gray[300]});
    transform: translateY(-2px);
  }
`
export const CustomerName = styled.p`
  font-size: 14px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.colors.gray[600]};
  span {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  svg {
    font-size: 16px;
    color: ${(props) => props.theme.colors.scale[500]};
    filter: drop-shadow(0px 0px 1px ${(props) => props.theme.colors.gray[300]});
    transform: translateY(-1px);
  }
`
export const ScheduleData = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  gap: 50px;
`

export const ScheduleDate = styled.p`
  font-size: 14px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: ${(props) => props.theme.colors.gray[800]};
  svg {
    font-size: 16px;
    color: ${(props) => props.theme.colors.gray[800]};
    transform: translateY(-2px);
  }
`
export const ScheduleHour = styled.p`
  font-size: 14px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: ${(props) => props.theme.colors.gray[800]};
  svg {
    font-size: 16px;
    color: ${(props) => props.theme.colors.gray[800]};
    transform: translateY(-2px);
  }
`
export const ScheduleServices = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.gray[200]};
  padding: 10px 0;
  p {
    margin: 0;
    font-size: 16px;
    padding: 0 0 0 10px;
    position: relative;
    color: ${(props) => props.theme.colors.gray[800]};
    &:first-letter {
      text-transform: uppercase;
    }
    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background: currentcolor;
    }
  }
`
