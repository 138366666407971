import { toast } from "react-toastify";
import qs from "qs";
import API from "../api";

import { dateFormat, dateFormatedToDB } from "../functions";
import { format } from "../formatResponses/schedule";
import { formatResponse } from "../formatResponses/global";

import { useSchedule } from "../../store/schedule/useSchedule";
import { PetModel } from ".";

export class Veterinary {
  constructor() {
    this.MPet = PetModel();
  }

  async findProcedures() {
    try {
      const { data } = await API.get("/services/vet-procedures");
      return data.procedures;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async findEmployees() {
    const { procedure } = useSchedule.getState().data;

    const findQuery = qs.stringify({
      department: "Clínica Veterinária",
      employeesWorkingOnly: true,
      date: dateFormatedToDB(procedure.date),
    });

    try {
      const { data } = await API.get(`/calendar/employees?${findQuery}`);
      return data.employees;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async findServices() {
    const { pet } = useSchedule.getState().data;

    if (!pet?.id) return null;

    const findQuery = qs.stringify({
      department: "Clínica Veterinária",
      employeesWorkingOnly: true,
      pet: pet.id,
    });

    try {
      const { data } = await API.get(`/services?${findQuery}`);

      return data.results;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }



  async saveNew() {
    const { pet } = useSchedule.getState().data;
    const { details } = useSchedule.getState().data;
    const { procedure } = useSchedule.getState().data;

    if (!procedure.time) {
      toast.error("Informe a hora de início para criar um novo agendamento.", {
        theme: "colored",
      });
      return;
    }
    if (!procedure.employee?.id) {
      toast.error("Selecione um veterinário para criar um novo agendamento.", {
        theme: "colored",
      });
      return;
    }
    if (!details.seller) {
      toast.error("Selecione um vendedor para prosseguir.", {
        theme: "colored",
      });
      return;
    }

    const body = {
      department: "Clínica Veterinária",
      channel: "Agenda",

      petId: pet.id,
      customerId: pet.tutor.id,

      serviceOrders: [],

      procedureId: procedure.data.id,
      durationInMinutes: procedure.duration,
      vetId: procedure.employee.id,
      appointmentDate: dateFormatedToDB(procedure.date),
      startsAt: procedure.time,

      sellerId: details.seller,
      comment: details.observation,
    };

    try {
      const { data } = await API.post("/dra-mei/appointments/new", body);

      toast.success(data.message, { theme: "colored" });
      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async find() {
    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.get(`dra-mei/appointment/${appointmentId}`);

      const schedulePet = await this.MPet.findPet(data.pet.id);
      const pet = formatResponse.pet(schedulePet);

      const procedure = {
        data: { id: data.procedureId },
        duration: data.durationInMinutes,
        employee: { id: data.vetId, name: data.vetName },
        services: format.services(data.services),
        date: dateFormat(data.date),
        time: data.startAt,
      };

      return {
        pet,
        seller: data.sellerId,
        procedure,
      };
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      this.setPets([]);
    }
  }

  async update() {
    const { appointmentId } = useSchedule.getState().data;
    const { details } = useSchedule.getState().data;
    const { procedure } = useSchedule.getState().data;

    const body = {
      procedureId: procedure.data.id,
      durationInMinutes: procedure.duration,
      vetId: procedure.employee.id,
      appointmentDate: dateFormatedToDB(procedure.date),
      startsAt: procedure.time,
      services: procedure.services,
      sellerId: details.seller,
    };

    try {
      const { data } = await API.post(
        `/dra-mei/appointment/${appointmentId}`,
        body
      );
      toast.success(data.friendlyMessage, { theme: "colored" });
      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async delete() {
    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.post(
        `/dra-mei/appointment/${appointmentId}/cancel`
      );
      toast.success(data.message, { theme: "colored" });
      return true;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async reset() {
    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.post(
        `/appointments/reset-status/${appointmentId}`
      );
      toast.success(data.message, { theme: "colored" });
      return true;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async checkIn() {
    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.post(
        `/dra-mei/appointment/${appointmentId}/check-in`
      );

      toast.success(data.message, { theme: "colored" });

      return true;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async startAttending() {
    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.post(
        `/dra-mei/appointment/${appointmentId}/start-attending`
      );
      toast.success(data.message, { theme: "colored" });

      return true;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async finishAttending() {
    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.post(
        `/dra-mei/appointment/${appointmentId}/finish-attending`
      );
      toast.success(data.message, { theme: "colored" });

      return true;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async checkOut() {
    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.post(
        `/dra-mei/appointment/${appointmentId}/send-to-vo`
      );
      toast.success(data.message, { theme: "colored" });

      return true;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async serviceRemove(serviceId) {
    if (!serviceId) {
      toast.error("Nenhum serviço foi selecionado.", { theme: "colored" });
      return false;
    }

    const { appointmentId } = useSchedule.getState().data;

    try {
      const { data } = await API.delete(
        `/dra-mei/appointment/${appointmentId}/remove-service`,
        {
          serviceId,
        }
      );
      toast.success(data.message, { theme: "colored" });

      return true;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }
}
