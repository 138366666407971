import { create } from 'zustand'
import { produce } from 'immer'

const defaultData = {
  pet: {
    name: '',
    observation: '',
    birthdate: '',
    castrated: null,
    specie: null,
    fur: null,
    weight: null,
    size: null,
    breed: null,
    gender: null,
    status: null,
  },
  attributes: [],
}
export const usePet = create((set) => ({
  data: defaultData,
  actions: {
    setPet: (Pet) =>
      set((state) =>
        produce(state, (draft) => {
          if (!draft.data.Pet) {
            draft.data.Pet = Pet
          }
        }),
      ),
    setPetData: (petData) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pet = { ...state.data.pet, ...petData }
        }),
      ),
    setAttributesValues: (attributes) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.attributes = attributes
        }),
      ),
    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        }),
      ),
  },
}))
