import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core'
import API from '../../../services/api'

import Modal from '../../../components/atoms/Modal'

import Title from './Title'
import FieldText from './Fields/Text'
import FieldTextMask from './Fields/Mask'
import FieldSelect from './Fields/Select'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  switchControl: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function EmissorNotas() {
  const classes = useStyles()
  const { register, control, handleSubmit } = useForm()
  const [settings, setSettings] = useState(false)
  const [openModal, setOpenModal] = useState(null)
  const [autoSenderEnabled, setAutoSenderEnabled] = useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const onSubmit = async (data) => {
    const location = settings.location.map((no) => ({
      key: no.key,
      value: data[no.key],
    }))
    const identification = settings.identification.map((no) => ({
      key: no.key,
      value: data[no.key],
    }))
    const defaultService = settings.defaultService.map((no) => ({
      key: no.key,
      value: data[no.key],
    }))
    const defaultFeature = settings.defaultFeature.map((no) => ({
      key: no.key,
      value: data[no.key],
    }))

    const result = await API.post('invoices/settings', {
      location,
      identification,
      defaultService,
      defaultFeature,
    })

    setOpenModal(result.data.message)
  }

  useEffect(() => {
    const getSettings = async () => {
      const result = await API.get('invoices/settings')
      setSettings(result.data)
      setAutoSenderEnabled(result?.data?.autoSenderEnabled)
    }
    if (!settings) {
      getSettings()
    }
  }, [])

  if (!settings) {
    return null
  }
  if (settings.active === false) {
    return (
      <h4>
        Emissor de notas não habilitado. Por favor, contate o administrador do
        sistema para ativar.
      </h4>
    )
  }

  const renderTitle = (item) => {
    if (item.key !== 'title') {
      return null
    }
    return (
      <Grid item xs={12}>
        <Title label={item.label} />
      </Grid>
    )
  }

  const renderField = (item) => {
    if (item.key === 'title') {
      return null
    }
    return (
      <Grid item xs={5}>
        {item.type === 'text' && !!item.mask && (
          <FieldTextMask control={control} item={item} register={register} />
        )}
        {item.type === 'text' && !item.mask && (
          <FieldText control={control} item={item} register={register} />
        )}
        {item.type === 'select' && (
          <FieldSelect control={control} item={item} />
        )}
      </Grid>
    )
  }

  const handleAutoSenderSwitch = async (state) => {
    const response = await API.post('invoices/set-auto-active', {
      active: state,
    })
    if (response.status === 201) setAutoSenderEnabled(state)
  }

  return (
    <>
      <Modal
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        verification={false}
      />
      <Grid
        className={classes.switchControl}
        container
        spacing={3}
        xs={7}
        direction="row"
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={autoSenderEnabled}
                onChange={() => handleAutoSenderSwitch(!autoSenderEnabled)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Emissão automática"
          />
        </FormGroup>
      </Grid>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} xs={7}>
          {settings.location.map((item) => (
            <>
              {renderTitle(item)}
              {renderField(item)}
            </>
          ))}
          {settings.identification.map((item) => (
            <>
              {renderTitle(item)}
              {renderField(item)}
            </>
          ))}
          {settings.defaultService.map((item) => (
            <>
              {renderTitle(item)}
              {renderField(item)}
            </>
          ))}
          {settings.defaultFeature.map((item) => (
            <>
              {renderTitle(item)}
              {renderField(item)}
            </>
          ))}
        </Grid>

        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>
        </Grid>
      </form>
    </>
  )
}

export default EmissorNotas
