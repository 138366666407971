import { memo } from "react";

import { formatName, getArticles } from "../../../../../services/functions";

import { usePet } from "../../../../../store/global/usePet";

import { VeterinaryRecordController } from "../../../../../services/controllers/VeterinaryCare";

import { Alert } from "../../../../../components/ui/Alert";
import { Tabs } from "../../../../../components/ui/Tabs";

import { WeightHistoryChart } from "./WeightHistoryChart";
import { WeightHistoryTable } from "./WeightHistoryTable";

export const WeightHistoryAlert = memo(() => {
  const CLVetRecord = VeterinaryRecordController();
  const pet = usePet((st) => st.data.pet);

  if (!pet?.id) return null

  const article = getArticles(pet?.petAttributes.gender);

  return (
    <Alert.Root name={CLVetRecord._weightHistoryAlertName}>
      <Alert.Content style={{ maxWidth: "700px" }}>
        <Alert.Title>
          Histórico de peso {article[1]}
          <strong> {formatName(pet?.name)}</strong>
        </Alert.Title>
        <Alert.Description>
          <Tabs.Root
            name="pet-weight-history-tabs"
            tabs={["Gráfico", "Tabela",]}
          >
            <Tabs.Content
              tabName="Gráfico"
              style={{ position: "relative", paddingBottom: 0 }}
            >
              <WeightHistoryChart />
            </Tabs.Content>
            <Tabs.Content
              tabName="Tabela"
              style={{ position: "relative", paddingBottom: 0 }}
            >
              <WeightHistoryTable />
            </Tabs.Content>
          </Tabs.Root>

        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
