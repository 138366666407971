import { useState, useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Container, Label } from './styles'
import { useCrm } from '../../../../hooks/CrmContext'

export function Frequency({ petData, updatePetFrequency }) {
  const [frequency, setFrequency] = useState(false)
  const { sendEvent } = useCrm()

  const handleChange = (event) => {
    setFrequency(event.target.value)
    sendEvent({
      eventName: 'set-pet-frequency',
      data: {
        frequency: event.target.value,
        petId: petData.petId,
      },
    })
    updatePetFrequency(petData.petId, event.target.value)
  }

  useEffect(() => {
    setFrequency(petData?.frequency)
  }, [petData])

  return (
    <Container>
      <Label>Frequência do Pet:</Label>
      <FormControl sx={{ minWidth: 120 }} size="small">
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={frequency}
          onChange={handleChange}
        >
          <MenuItem value="semanal">Semanal</MenuItem>
          <MenuItem value="quinzenal">Quinzenal</MenuItem>
        </Select>
      </FormControl>
    </Container>
  )
}
