import styled, { css } from "styled-components";

import {
  Root as RadixToggleGroupRoot,
  Item as RadixToggleGroupItem,
} from "@radix-ui/react-toggle-group";
import { rgba } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled(RadixToggleGroupRoot)`
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.scale[200]};
  border: 1px solid ${(props) => props.theme.colors.scale[400]};
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px 5px;
  transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;
  ${(props) =>
    props.disabled &&
    css`
      box-shadow: none;
      background: ${(props) => props.theme.colors.gray[200]};
      border-color: ${(props) => props.theme.colors.gray[300]};
    `}
`;

export const Label = styled.span`
  min-width: 100%;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray[600]};
`;

export const Item = styled(RadixToggleGroupItem)`
  background-color: white;
  color: ${(props) => props.theme.colors.scale[500]};
  height: 40px;
  display: flex;
  padding: 0 12px;
  gap: 8px;
  font-size: 16px;
  flex: 1;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  border: none;
  cursor: pointer;
  transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;
  &:first-child {
    border: none;
    margin-left: 0;
  }
  &:last-child {
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &[data-state="on"] {
    background: ${(props) => props.theme.colors.scale[400]};
    color: ${(props) => props.theme.colors.background};
    box-shadow: inset ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px
      5px;
  }
  &[data-disabled] {
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.gray[300]};
    box-shadow: none;
  }
`;
