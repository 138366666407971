import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Grid, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Pagination } from "@mui/material";
import API from "../../../services/api";

import FilterPet from "./FilterPet";
import Box from "./Box";
import styles from "./styles";

function VetRecords() {
  const { grid, title, header, content, pagination, filter } = styles();
  const { id } = useParams();
  const [date, setDate] = useState({
    inicio: moment(new Date()).format("YYYY-MM-DD"),
    fim: moment(new Date()).add(30, "days").format("YYYY-MM-DD"),
  });
  const filterStatus = "TODOS";
  const [selectPet, setSelectPet] = useState([]);
  const [vetRecordsList, setVetRecordsList] = useState(false);
  const [findPage, setFindPage] = useState(0);
  const [paginationData, setPaginationData] = useState(false);

  const getVetRecords = useCallback(async () => {
    try {
      const status = filterStatus === "TODOS" ? "" : filterStatus;
      const { data } = await API.get(
        `/customer/${id}/vet-records?petsId=${selectPet.join(
          ","
        )}&status=${status}&page=${findPage}&perPage=3&dateFrom=${
          date.inicio
        }&dateTo=${date.fim}`
      );
      setPaginationData(data.pagination);
      setVetRecordsList(data.results);
    } catch (err) {
      console.log("Erro:", err);
    }
  }, [filterStatus, id, selectPet, findPage, date]);

  useEffect(() => {
    getVetRecords();
  }, [filterStatus, selectPet, getVetRecords]);


  return (
    <Grid item xs={12} className={grid}>
      <div className={header}>
        <h2 className={title}>Prontuários</h2>
        <FilterPet setSelectPet={setSelectPet} selectPet={selectPet} />
        <form>
          <Grid container spacing={3} className={filter}>
            <Grid item xs={5}>
              <TextField
                id="dateStart"
                label="INICIO"
                fullWidth
                type="date"
                value={date.inicio}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setDate((date) => ({ ...date, inicio: e.target.value }));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="date"
                label="FIM"
                type="date"
                value={date.fim}
                fullWidth
                className=""
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setDate((date) => ({ ...date, fim: e.target.value }));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
              <div
                color="primary"
                disabled={false}
                style={{
                  background: "#CCD219",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  fontWeight: "700",
                  color: "#ffffff",
                  width: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  lineHeight: "13px",
                  borderRadius: "6px",
                  justifyContent: "center",
                  textDecoration: "none",
                  padding: "5px",
                }}
              >
                <Search onClick={getVetRecords} />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={content}>
        {vetRecordsList &&
          vetRecordsList.map((VetRecordsData) => (
            <Box
              key={VetRecordsData.createdAt}
              VetRecordsData={VetRecordsData}
              getVetRecords={getVetRecords}
            />
          ))}
        {paginationData && paginationData.totalPages > 1 && (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ justifyContent: "center" }}
            className={pagination}
          >
            <Pagination
              count={paginationData.totalPages}
              variant="outlined"
              onChange={(e, nextPage) => {
                setFindPage(nextPage - 1);
              }}
            />
          </Grid>
        )}
      </div>
    </Grid>
  );
}

export default VetRecords;
