import { ListCheck, ListChecks, Bone } from 'lucide-react'
import { Card } from './Card'
import { usePrinter } from '../../../../../../store/schedule/usePrinter'

export function PetStatus() {
  const checklist = usePrinter(st => st.data.checklist)
  const schedule = usePrinter(st => st.data.schedule)
  const newComment = usePrinter(st => st.data.newComment)

  const comment = newComment ?? schedule.comment

  if (!comment && !checklist.length) return null

  return (
    <>
      <Card.Content>
        <Card.ContentTitle>
          <ListChecks className="h-4 w-4" weight="bold" />
          <span className="leading-none w-full">Checklist</span>
        </Card.ContentTitle>
        <Card.ContentList className="gap-3 pt-4 flex-row flex-wrap">
          {!!checklist.length && checklist.map((item) => (
            <Card.ContentListItem key={item._id} className="items-center gap-1 w-fit flex">
              <Bone className="h-3 w-3" />
              <span className="leading-none flex-1 w-full flex text-nowrap">{item.label}</span>
            </Card.ContentListItem>
          ))}
          {!!comment && (
            <Card.ContentListItem className="col-span-2 flex-wrap">
              <span className="leading-normal">
                <ListCheck className="float-left mr-1 h-4 w-4" weight="bold" />
                {comment}
              </span>
            </Card.ContentListItem>
          )}
        </Card.ContentList>
      </Card.Content>
      <Card.Separator className="bg-black" />
    </>
  )
}
