
import { EditorLabel } from "./EditorLabel";
import { EditorContent } from "./EditorContent";
import { EditorLoading } from "./EditorLoading";

import { Container } from "./styles";

export function Editor({
  label,
  disabled = false,
  style = {},
  ...editorProps
}) {

  const isLoading = _.isUndefined(editorProps.content) || _.isNull(editorProps.content);

  return (
    <Container disabled={disabled} style={style}>
      {label && <EditorLabel>{label}</EditorLabel>}
      {isLoading && <EditorLoading />}
      {!isLoading && <EditorContent editable={!disabled} {...editorProps} />}
    </Container>
  );
}
