import { FaWhatsapp } from "react-icons/fa";
import { Download, Trash2, ClipboardPenLine, FolderSync } from "lucide-react";
import { usePrescription } from "../../../../../store/service/veterinary/usePrescription";

import { Action } from "../../../../../components/ui/Action";
import {  VeterinaryPrescriptionController } from "../../../../../services/controllers/VeterinaryCare";

export function PreviewActions(){
  const selectPrescription = usePrescription((st) => st.data.selectPrescription);

  const CLVetPrescription = VeterinaryPrescriptionController();

  const disableResend = selectPrescription?.status === "generating"

  return(
    <>
      <Action.Root
        outline
        type="button"
        title="Reenviar Receita"
        name="resend-file-prescription"
        disabled={disableResend}
        onClick={() => CLVetPrescription.resendPrescription(selectPrescription.id)}
      >
        <Action.Icon icon={FolderSync} size={22} />
      </Action.Root>
      <Action.Root
        type="button"
        title="Baixar Receita"
        name="download-prescription"
        download={{
          path: selectPrescription?.path,
          name: selectPrescription?.slug,
        }}
      >
        <Action.Icon icon={Download} size={22} />
      </Action.Root>
      <Action.Root
        type="button"
        status="success"
        title="Enviar por WhatsApp"
        name="send-prescription"
        onClick={() => CLVetPrescription.sendMessage()}
      >
        <Action.Icon icon={FaWhatsapp} size={22} />
      </Action.Root>
      <Action.Root
        type="button"
        status="400"
        title="Editar Receita"
        name="edit-prescription"
        onClick={() => CLVetPrescription.editPrescription()}
      >
        <Action.Icon icon={ClipboardPenLine} size={22} />
      </Action.Root>
      <Action.Root
        type="button"
        status="danger"
        title="Excluir Receita"
        name="delete-prescription"
        onClick={() => CLVetPrescription.deletePrescription()}
      >
        <Action.Icon icon={Trash2} size={22} />
      </Action.Root>
    </>
  )
}
