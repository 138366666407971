import styled from 'styled-components'

export const Container = styled.span`
  color: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  span {
    font-size: 14px;
    line-height: 120%;
  }

  svg {
    font-size: inherit;
  }
`
