import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import {
  Tab,
  Tabs,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Grid,
  Button,
  Divider,
} from '@material-ui/core'
import BasePage from '../../../pages/basePage'
import API from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function NewService({ location }) {
  const {
    state: { categories },
  } = location

  const history = useHistory()
  const defaultIds = []

  const { register, control, handleSubmit, getValues } = useForm({
    defaultValues: { item_ids: defaultIds },
  })

  const [value, setValue] = useState('0')
  const handleChangeIndex = (event, value) => setValue(value)

  const classes = useStyles()
  const [sw, setSwitch] = useState(false)
  const handleCheck = (checkedId) => {
    const { item_ids: ids } = getValues()
    const newIds = ids?.includes(checkedId)
      ? ids?.filter((id) => id !== checkedId)
      : [...(ids ?? []), checkedId]
    return newIds
  }

  const [attrPelagem, setAttrPelagem] = useState()
  const [attrSexo, setAttrSexo] = useState()
  const [attrPorte, setAttrPorte] = useState()
  const [employees, setEmployees] = useState()

  const [valorSw, setValorSw] = useState()
  const [tipoSw, setTipoSw] = useState()

  const handleChangeSw = () => {
    setSwitch(!sw)
  }

  const onSubmitStep1 = (data) => {
    localStorage.setItem('codigo', data.codigo)
    localStorage.setItem('nome', data.nome)
    localStorage.setItem('departamento', data.departamento)
    localStorage.setItem('categoria', data.categoria)
    localStorage.setItem('limit_atend', data.limit_atend)
    localStorage.setItem('tempo', data.tempo)
    localStorage.setItem('peso_min', data.peso_min)
    localStorage.setItem('peso_max', data.peso_max)
    localStorage.setItem('sexo', data.sexo)
    localStorage.setItem('porte', data.porte)
    localStorage.setItem('pelagem', data.pelagem)
    setValue('1')
  }

  const onSubmitStep2 = (data) => {
    localStorage.setItem('employees', JSON.stringify(data.item_ids))
    setValue('2')
  }

  const onSubmitStep3 = (data) => {
    localStorage.setItem('precoSgt', data.precoSgt)
    localStorage.setItem('custo', data.custo)
    setValue('3')
  }

  const postNewServices = async () => {
    try {
      await API.post('services/new', {
        name: localStorage.getItem('nome'),
        code: localStorage.getItem('codigo'),
        department: localStorage.getItem('departamento'),
        categoryId: localStorage.getItem('categoria'),
        attendanceLimitPerHour: localStorage.getItem('limit_atend'),
        minimumWeight: localStorage.getItem('peso_min'),
        maximumWeight: localStorage.getItem('peso_max'),
        gender: null,
        size: localStorage.getItem('porte'),
        employeeGroupIdsLicensed: localStorage.getItem('employees'),
        defaultCost: localStorage.getItem('custo'),
        defaultPrice: localStorage.getItem('precoSgt'),
        comissionDefinedByCategory: sw,
        defaultComission: valorSw,
        comissionType: tipoSw,
        active: true,
      })
      history.push('/servicos')
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const onSubmit = (data) => {
    setValorSw(data.valorSw)
    setTipoSw(data.tipoSw)
    postNewServices()
  }

  const getPorte = async () => {
    try {
      const resp = await API.get('pet-attributes/porte/values')
      resp && setAttrPorte(resp.data.label.values)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getPelagem = async () => {
    try {
      const resp = await API.get('pet-attributes/pelagem/values')
      resp && setAttrPelagem(resp.data.label.values)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getSexo = async () => {
    try {
      const resp = await API.get('pet-attributes/sexo/values')
      resp && setAttrSexo(resp.data.label.values)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getEmployees = async () => {
    try {
      const resp = await API.get('employees/groups')
      resp && setEmployees(resp.data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getPorte()
    getPelagem()
    getSexo()
    getEmployees()
  }, [])

  return (
    <BasePage pageTitle="Novo Serviço">
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
        aria-label="disabled tabs example"
      >
        <Tab label="DADOS GERAIS" value="0" />
        <Tab label="GRUPO USUÁRIOS" value="1" />
        <Tab label="PREÇOS" value="2" />
        <Tab label="COMISSÃO" value="3" />
      </Tabs>

      {parseInt(value, 10) === 0 && (
        <form className={classes.root} onSubmit={handleSubmit(onSubmitStep1)}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                id="codigo"
                label="Código"
                name="codigo"
                variant="outlined"
                fullWidth
                required
                inputRef={register}
                placeholder="Código do Produto"
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                id="name"
                label="Nome"
                name="nome"
                inputRef={register}
                variant="outlined"
                fullWidth
                required
                placeholder="Nome do Serviço ou Produto"
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <TextField
                    id="departamento"
                    select
                    fullWidth
                    required
                    label="Departamento"
                    variant="outlined"
                  >
                    <MenuItem key="Banho e Tosa" value="Banho e Tosa">
                      Banho e Tosa
                    </MenuItem>
                  </TextField>
                }
                name="departamento"
              />
            </Grid>

            <Grid item xs={8}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <TextField
                    id="categoria"
                    select
                    fullWidth
                    required
                    label="Categoria"
                    variant="outlined"
                  >
                    {categories?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="categoria"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                id="limit_atend"
                label="Limite Atendimento"
                variant="outlined"
                fullWidth
                required
                name="limit_atend"
                inputRef={register}
                placeholder="Atendimentos por hora"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                id="tempo"
                label="Tempo"
                fullWidth
                name="tempo"
                required
                inputRef={register}
                variant="outlined"
                placeholder="Tempo Previsto (min)"
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2>Restrições de uso</h2>
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="peso_min"
                name="peso_min"
                inputRef={register}
                label="Peso Mínimo"
                variant="outlined"
                placeholder="Peso mínimo do animal"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="peso_max"
                name="peso_max"
                inputRef={register}
                label="Peso Máximo"
                variant="outlined"
                placeholder="Peso máximo do animal"
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <TextField
                    id="sexo"
                    select
                    fullWidth
                    label="Sexo"
                    variant="outlined"
                  >
                    {attrSexo?.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="sexo"
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <TextField
                    id="porte"
                    select
                    fullWidth
                    label="Porte"
                    variant="outlined"
                  >
                    {attrPorte?.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="porte"
              />
            </Grid>

            <Grid item xs={8}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <TextField
                    id="pelagem"
                    select
                    fullWidth
                    label="Pelagem"
                    variant="outlined"
                  >
                    {attrPelagem?.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                name="pelagem"
              />
            </Grid>
          </Grid>

          <Divider className={classes.bottom} />

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
            >
              Salvar
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.push('/servicos')}
              style={{
                border: '1px solid #CCD219',
                color: '#CCD219',
                textTransform: 'capitalize',
                fontSize: '14px',
                width: '120px',
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </form>
      )}
      {parseInt(value, 10) === 1 && (
        <form className={classes.root} onSubmit={handleSubmit(onSubmitStep2)}>
          <Controller
            name="item_ids"
            render={(props) => (
              <FormGroup>
                {employees?.map((item) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => props.onChange(handleCheck(item.id))}
                        defaultChecked={defaultIds.includes(item.id)}
                      />
                    }
                    key={item.id}
                    label={item.name}
                  />
                ))}
              </FormGroup>
            )}
            control={control}
          />
          <Divider className={classes.bottom} />

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
            >
              Salvar
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.push('/servicos')}
              style={{
                border: '1px solid #CCD219',
                color: '#CCD219',
                textTransform: 'capitalize',
                fontSize: '14px',
                width: '120px',
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </form>
      )}
      {parseInt(value, 10) === 2 && (
        <form className={classes.root} onSubmit={handleSubmit(onSubmitStep3)}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="precoSgt"
                name="precoSgt"
                inputRef={register}
                label="Preço Sugestão"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="custo"
                label="Custo"
                name="custo"
                inputRef={register}
                variant="outlined"
                placeholder="Custo do Produto / Serviço"
              />
            </Grid>
          </Grid>

          <Divider className={classes.bottom} />

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
            >
              Salvar
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.push('/servicos')}
              style={{
                border: '1px solid #CCD219',
                color: '#CCD219',
                textTransform: 'capitalize',
                fontSize: '14px',
                width: '120px',
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </form>
      )}
      {parseInt(value, 10) === 3 && (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={sw}
                    onChange={handleChangeSw}
                    color="primary"
                    name="ativo"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Usar comissão da categoria"
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <TextField
                    id="outlined-basic"
                    select
                    fullWidth
                    label="Tipo"
                    variant="outlined"
                    disabled={sw}
                    required={!sw}
                  >
                    <MenuItem key="by_percent" value="by_percent">
                      Porcentagem (%)
                    </MenuItem>
                  </TextField>
                }
                name="tipoSw"
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Valor"
                name="valorSw"
                inputRef={register}
                variant="outlined"
                disabled={sw}
                required={!sw}
              />
            </Grid>
          </Grid>

          <Divider className={classes.bottom} />

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
            >
              Salvar
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => history.push('/servicos')}
              style={{
                border: '1px solid #CCD219',
                color: '#CCD219',
                textTransform: 'capitalize',
                fontSize: '14px',
                width: '120px',
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </form>
      )}
    </BasePage>
  )
}

export default NewService
