import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import API from '../services/api'

const AssessmentsContext = createContext({})

function AssessmentsProvider({ children }) {
  const [nps, setNps] = useState(false)
  const [npsScores, setNpsScores] = useState(false)
  const [stars, setStars] = useState(false)
  const [starScores, setStarScores] = useState(false)
  const [tags, setTags] = useState(false)

  const [filter, setFilter] = useState({
    page: 0,
    perPage: 50,
    type: 'review-scores',
    period: 'last-30-days',
  })
  //  "last-90-days" //"last-30-days", "last-7-days", "all-period"

  const findReviews = useCallback(async () => {
    try {
      const { data } = await API.post('dashboard', filter)
      const { nps, npsScores, stars, starScores, tags } = data.results

      setNps(nps)
      setNpsScores(npsScores)
      setStars(stars)
      setStarScores(starScores)
      setTags(tags)
    } catch (error) {
      console.log(error)
    }
  }, [filter])

  const getFilter = useCallback(() => filter, [filter])

  const updateFilter = useCallback((filter) => {
    setFilter((prev) => ({ ...prev, ...filter }))
  }, [])

  const getNps = useCallback(() => nps, [nps])
  const getNpsScores = useCallback(() => npsScores, [npsScores])
  const getStars = useCallback(() => stars, [stars])
  const getStarScores = useCallback(() => starScores, [starScores])
  const getTags = useCallback(() => tags, [tags])

  useEffect(() => {
    findReviews()
  }, [findReviews])

  return (
    <AssessmentsContext.Provider
      value={{
        getFilter,
        updateFilter,
        findReviews,
        getNps,
        getNpsScores,
        getStars,
        getStarScores,
        getTags,
      }}
    >
      {children}
    </AssessmentsContext.Provider>
  )
}

function useAssessments() {
  const context = useContext(AssessmentsContext)
  if (!context) {
    throw new Error('useAssessments must be used within an AssessmentsProvider')
  }
  return context
}

export { useAssessments, AssessmentsProvider }
