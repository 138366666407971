import React from "react";
import {
  formatCpf,
  formatMoney,
  formatName,
  moneyType,
  stringDateFormat,
} from "../../../../services/functions";

import { InvoiceController } from "../../../../services/controllers/subscription";
import { useInvoice } from "../../../../store/subscription/useInvoice";

import { Table } from "../../../../components/ui/Table";
import { Pagination } from "../../../../components/ui/Pagination";
import { SplitIcon } from "./SplitIcon";

export function ExtratoTable() {
  const CInvoice = InvoiceController();

  const page = useInvoice((st) => st.data.pagination.page);
  const isWaiting = useInvoice((st) => st.data.isWaiting);
  const paginationData = useInvoice((st) => st.data.pagination);

  const invoice = useInvoice((st) => st.data.invoice);

  return (
    <Table.Root name={CInvoice.tableName} config>
      <Table.Header
        columns={[
          "Split:60px",
          "Data:100px",
          "Nome",
          "Pet",
          "CPF:150px",
          "V.Bruto:100px",
          "Taxa Transacional:150px",
          "V.Líquido:100px",
          "V.Split:100px:true",
          "Tipo::true",
        ]}
      />
      <Table.Content isLoading={isWaiting}>
        {!!invoice[page] &&
          invoice[page].map((invoice) => (
            <Table.Line key={invoice.id} type={moneyType(invoice.description)}>
              <Table.Item><SplitIcon active={invoice.split.active} /></Table.Item>
              <Table.Item>{stringDateFormat(invoice.billingAt)}</Table.Item>
              <Table.Item>{formatName(invoice.customer.name)}</Table.Item>
              <Table.Item>{formatName(invoice.customer.pet)}</Table.Item>
              <Table.Item>{formatCpf(invoice.customer.cpf)}</Table.Item>
              <Table.Item>
                R$ {formatMoney(invoice.paidAmount / 100)}
              </Table.Item>
              <Table.Item>R$ {formatMoney(invoice.fee / 100)}</Table.Item>

              <Table.Item type={moneyType(invoice.description)}>
                R$ {formatMoney(Math.abs(invoice.paidNet / 100))}
              </Table.Item>
              <Table.Item type={moneyType(invoice.description)}>
                {!!invoice.split.amount ?`R$ ${formatMoney(invoice.split.amount)}`:'-'}
              </Table.Item>

              <Table.Item>{invoice.description}</Table.Item>
            </Table.Line>
          ))}
      </Table.Content>
      <Pagination.Root
        name={CInvoice.paginationName}
        totalPages={paginationData.totalPages}
        currentPage={paginationData.page}
        onChange={(page) => CInvoice.setPage(page)}
      >
        <Pagination.Back />
        <Pagination.Pages />
        <Pagination.Forward />
      </Pagination.Root>
    </Table.Root>
  );
}
