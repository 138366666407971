import { TimeLineBadge } from "./TimeLineBadge";
import { TimeLineBadges as Container } from "./styles";

export function TimeLineBadges({ badges }) {
  return (
    <Container>
      {badges.map((badge) => (
        <TimeLineBadge key={badge.id} {...badge} />
      ))}
    </Container>
  );
}
