import { Customer } from "./customer";
import { Merchant } from "./merchant";
import { Pet } from "./pet";
import { Plans } from "./plans";
import { Schedule } from "./schedule";
import { Veterinary } from "./veterinary";

let MCustomer = null;
let MVeterinary = null;
let MPet = null;
let MPlans = null;
let MSchedule = null;
let MMerchant = null;

export function VeterinaryModel() {
  if (MVeterinary) {
    return MVeterinary;
  }
  MVeterinary = new Veterinary();

  return MVeterinary;
}

export function PetModel() {
  if (MPet) {
    return MPet;
  }
  MPet = new Pet();

  return MPet;
}

export function ScheduleModel() {
  if (MSchedule) {
    return MSchedule;
  }
  MSchedule = new Schedule();

  return MSchedule;
}

export function MerchantModel() {
  if (MMerchant) {
    return MMerchant;
  }
  MMerchant = new Merchant();

  return MMerchant;
}

export function CustomerModel() {
  if (MCustomer) {
    return MCustomer;
  }
  MCustomer = new Customer(PetModel());

  return MCustomer;
}

export function PlanModel() {
  if (MPlans) {
    return MPlans;
  }

  MPlans = new Plans();
  return MPlans;
}
