import React from "react";

import { ExtratoFilter } from "./ExtratoFilter";
import { ExtratoSummary } from "./ExtratoSummary";
import { ExtratoTable } from "./ExtratoTable";

import { Container } from "./styles";

export function Extrato() {
  return (
    <Container>
      <ExtratoFilter />
      <ExtratoSummary />
      <ExtratoTable />
    </Container>
  );
}
