import { create } from 'zustand'
import { produce } from 'immer'
import { splitName } from '../../services/functions'

const defaultData = {
  customer: {
    id: null,
    cpf: null,
    name: null,
    firstname: null,
    lastname: null,
    cellphone: null,
    telephone: null,
    email: null,
  },
}

export const useCustomer = create((set) => ({
  data: defaultData,
  actions: {
    setCustomerData: (customerData) =>
      set((state) =>
        produce(state, (draft) => {
          if (customerData.cpf) {
            customerData.id = customerData.id || null
          }
          if (customerData.name) {
            const { firstname, lastname } = splitName(customerData.name)

            customerData.firstname = firstname
            customerData.lastname = lastname
          }

          draft.data.customer = { ...state.data.customer, ...customerData }
        }),
      ),
    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        }),
      ),
  },
}))
