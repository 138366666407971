import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import { FilterPetsProvider } from '../../providers/FilterPetsContext'
import { PetsProvider } from '../../providers/pets'

import BasePage from '../basePage'

import User from './User'
import Packages from './Packages'
import Filter from './Filter'
import PetList from './PetLIst'
import FilterPet from './FilterPet'
import Feed from './Feed'
import VetRecords from './VetRecords'

function Pages() {
  const [filterList, setFilterList] = useState({
    department: [],
    status: [],
    pet: '',
  })
  const [petList, setPetList] = useState(false)

  return (
    <BasePage pageTitle="Perfil do Cliente">
      <PetsProvider>
        <FilterPetsProvider
          value={{
            filterList,
            setFilterList,
            petList,
            setPetList,
          }}
        >
          <Grid container spacing={3} style={{ minHeight: '100vh' }}>
            <Grid item xs={8} style={{ background: '', borderRadius: '7px' }}>
              <User />
              <PetList />
              <Packages />
              <VetRecords />
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                padding: '0',
                margin: '10px 0 0 0',
                background: '#FFFFFF',
                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '7px',
              }}
            >
              <Filter />
              <FilterPet />
              <Feed />
            </Grid>
          </Grid>
        </FilterPetsProvider>
      </PetsProvider>
    </BasePage>
  )
}

export default Pages
