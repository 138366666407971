import { createContext, useContext } from "react";

const PaginationContext = createContext({});

function PaginationProvider({ children, ...rest }) {
  return (
    <PaginationContext.Provider value={rest}>
      {children}
    </PaginationContext.Provider>
  );
}

function usePaginationContext() {
  const context = useContext(PaginationContext);
  if (!context) return false
  return context;
}

export { usePaginationContext, PaginationProvider };
