import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'

// material.ui
import { TextField, Grid, MenuItem, Divider, Button } from '@material-ui/core'
import BasePage from '../../../pages/basePage'
import API from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function NewCategory({ stateValue }) {
  const { register, control, handleSubmit } = useForm()

  const onSubmit = async (data) => {
    try {
      await API.post('services/categories/new', {
        name: data.nome,
        defaultComission: data.comissao,
        department: data.departamento,
        active: true,
      })
      stateValue(0)
    } catch (err) {
      console.log('Erro:', err)
    }
  }
  const classes = useStyles()

  return (
    <BasePage pageTitle="Nova Categoria">
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Controller
              control={control}
              defaultValue=""
              as={
                <TextField
                  id="departamento"
                  select
                  fullWidth
                  label="Departamento"
                  variant="outlined"
                >
                  <MenuItem key="Banho e Tosa" value="Banho e Tosa">
                    Banho e Tosa
                  </MenuItem>
                </TextField>
              }
              name="departamento"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="name"
              label="Nome"
              name="nome"
              inputRef={register}
              variant="outlined"
              fullWidth
              placeholder="Nome Completo"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="comissao"
              label="Comissão"
              name="comissao"
              inputRef={register}
              variant="outlined"
              fullWidth
              placeholder="Comissão"
            />
          </Grid>
        </Grid>

        <Divider className={classes.bottom} />

        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => stateValue(0)}
            style={{
              border: '1px solid #CCD219',
              color: '#CCD219',
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '120px',
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </form>
    </BasePage>
  )
}

export default NewCategory
