import styled from 'styled-components';

export const Container = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  span{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 8px;
  }
  em{
    font-style: normal;
    font-size: 16px;
    color:${props => props.theme.colors.gray[400]}
  }
`;
