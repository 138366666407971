import { Badge } from "../../../Badge";

export function ItemContentType({ content, ...rest }) {
  switch (content.type) {
    case "badge":
      return (
        <Badge.Root  {...rest} {...content.props}>
          {content.icon && <Badge.Icon icon={content.icon} />}
          <Badge.Text>{content.value}</Badge.Text>
        </Badge.Root>
      );

    default:
      return content.value;
  }
}
