import _ from "lodash";
import uuid from "react-uuid";
import { create } from "zustand";
import { produce } from "immer";


const defaultListPreviewData = {
  data: [],
  preview: false,
  selected: null,
  listStyle: "grid",
  showStyles: ["smallGrid", "bigGrid", "list"],
  placeholder: false,
};

export const useListPreview = create((set) => ({
  data: {
    lists: {},
  },

  actions: {
    setList: (name, props, data = []) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.lists[name]) {
            draft.data.lists[name] = {
              ...defaultListPreviewData,
              id: `${name}-${uuid()}`,
              data,
              ...props
            };
          }
        })
      ),

    setPreview: (name) =>
      set((state) =>
        produce(state, (draft) => {
          const allLists = state.data.lists;
          if (!allLists[name] || state.data.lists[name].preview) {
            return;
          }

          draft.data.lists[name].preview = true;
        })
      ),

    setListData: (name, data) =>
      set((state) =>
        produce(state, (draft) => {
          const allLists = state.data.lists;

          if (allLists[name].data.length > data.length) {
            draft.data.lists[name].selected = null;
          }

          if (!allLists[name] || _.isEqual(state.data.lists[name].data, data)) {
            return;
          }

          draft.data.lists[name].data = data;
        })
      ),

    setListStyle: (name, style) =>
      set((state) =>
        produce(state, (draft) => {
          const allLists = state.data.lists;
          if (!allLists[name]) return;

          draft.data.lists[name].listStyle = style;
        })
      ),

    toggleSelected: ({ item, name }) =>
      set((state) =>
        produce(state, (draft) => {
          const allLists = state.data.lists;
          if (!allLists[name]) return;

          if (_.isEqual(state.data.lists[name].selected, item)) item = null;

          draft.data.lists[name].selected = item;
        })
      ),
  },
}));
