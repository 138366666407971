import React from 'react'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import { Link } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { useTaxiDog } from '../../../../../hooks/TaxiDogContext'
import { useStyles } from './styles'

export function Lines() {
  const { getTaxiDogs } = useTaxiDog()

  const taxiDogs = getTaxiDogs()

  const { TaxiDogItem, TaxiDogHeader, TaxiDogLine, TaxiDogText, Icon } =
    useStyles()

  return (
    <>
      {!taxiDogs ? (
        <div className={TaxiDogItem}>
          <div className={TaxiDogHeader}>
            <span className={TaxiDogText}>
              <strong>TAXI DOG:</strong> <Skeleton style={{ width: '150px' }} />
              <em className={Icon}>
                <RemoveRedEye />
              </em>
            </span>
            <span className={TaxiDogText}>
              <strong>Data:</strong>
              <Skeleton style={{ width: '150px' }} />
            </span>
          </div>
          <div className={TaxiDogLine}>
            <span className={TaxiDogText}>
              <strong>Recebimento:</strong>
              <Skeleton style={{ width: '150px' }} />
            </span>
            <span className={TaxiDogText}>
              <strong>Valor:</strong> <Skeleton style={{ width: '150px' }} />
            </span>
            <span className={TaxiDogText}>
              <strong>Motorista:</strong>
              <Skeleton style={{ width: '150px' }} />
            </span>
          </div>
          <div className={TaxiDogLine}>
            <span className={TaxiDogText}>
              <strong>Endereço Retirada:</strong>
              <Skeleton style={{ width: '150px' }} />
            </span>
          </div>
          <div className={TaxiDogLine}>
            <span className={TaxiDogText}>
              <strong>Endereço Entrega:</strong>{' '}
              <Skeleton style={{ width: '150px' }} />
            </span>
          </div>
          <div className={TaxiDogLine}>
            <span className={TaxiDogText}>
              <strong>Cliente:</strong>
              <Skeleton style={{ width: '150px' }} />
            </span>
            <span className={TaxiDogText}>
              <strong>Pet:</strong> <Skeleton style={{ width: '150px' }} />
            </span>
            <span className={TaxiDogText}>
              <strong>Porte:</strong>
              <Skeleton style={{ width: '150px' }} />
            </span>
          </div>
        </div>
      ) : (
        taxiDogs.map(
          ({
            appointmentId,
            formattedDate,
            hour,
            taxiDogType,
            total,
            deliveryAddress,
            driverPickupType,
            driver,
            pickupAddress,
            customerName,
            petName,
            petSize,
          }) => (
            <div className={TaxiDogItem} key={appointmentId}>
              <div className={TaxiDogHeader}>
                <span className={TaxiDogText}>
                  <strong>TAXI DOG:</strong> {taxiDogType}
                  <Link
                    to={`/banho-tosa/check-in/order/${appointmentId}`}
                    target="_blank"
                  >
                    <em className={Icon}>
                      <RemoveRedEye />
                    </em>
                  </Link>
                </span>
                <span className={TaxiDogText}>
                  <strong>Data:</strong>
                  {formattedDate} às
                  {hour}
                </span>
              </div>
              <div className={TaxiDogLine}>
                <span className={TaxiDogText}>
                  <strong>Recebimento:</strong>
                  {driverPickupType}{' '}
                </span>
                <span className={TaxiDogText}>
                  <strong>Valor:</strong>{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(total)}
                </span>
                <span className={TaxiDogText}>
                  <strong>Motorista:</strong> {driver.name}
                </span>
              </div>
              {!!pickupAddress && (
                <div className={TaxiDogLine}>
                  <span className={TaxiDogText}>
                    <strong>Endereço Retirada:</strong> {pickupAddress.street},{' '}
                    {pickupAddress.number} -{pickupAddress.complement} -
                    {pickupAddress.neighborhood} -{pickupAddress.city}
                  </span>
                </div>
              )}

              {!!deliveryAddress && (
                <div className={TaxiDogLine}>
                  <span className={TaxiDogText}>
                    <strong>Endereço Entrega:</strong> {deliveryAddress.street},{' '}
                    {deliveryAddress.number} -{deliveryAddress.complement} -
                    {deliveryAddress.neighborhood} -{deliveryAddress.city}
                  </span>
                </div>
              )}
              <div className={TaxiDogLine}>
                <span className={TaxiDogText}>
                  <strong>Cliente:</strong> {customerName}
                </span>
                <span className={TaxiDogText}>
                  <strong>Pet:</strong> {petName}
                </span>
                <span className={TaxiDogText}>
                  <strong>Porte:</strong> {petSize}
                </span>
              </div>
            </div>
          ),
        )
      )}
    </>
  )
}
