import _ from "lodash";

import { SubscriberController } from "../../../../../services/controllers/subscription";

import { useSubscribers } from "../../../../../store/subscription/useSubscribers";

import { Alert } from "../../../../../components/ui/Alert";

import { SummaryTitle, SummaryDetails, SummaryDetail, SummaryServicesTable, SummaryServicesTableHeadLine, SummaryServicesTableLine, SummaryServicesTotalAmount } from "./styles";
import { formatMoney, formatName } from "../../../../../services/functions";

export function AlertCycleAppointments(){
  const CSubscriber = SubscriberController();

  const details = useSubscribers(st=>st.data.details)
  const cycleAppointments = useSubscribers(st=>st.data.cycleAppointments)

  if(!cycleAppointments) return null

  const totalAmount = _.sumBy(cycleAppointments, 'service.price.totalPrice');

  return (
      <Alert.Root name={CSubscriber.cycleAppointmentsAlertName}>
        <Alert.Content style={{ maxWidth: '600px' }}>
          <Alert.Title>Visualização de Banho por Ciclo</Alert.Title>
          <div>
            <SummaryTitle>Assinatura:</SummaryTitle>
            <SummaryDetails>
              <SummaryDetail>
                <strong>Pet: </strong>
                {formatName(details.pet.name || '')}
              </SummaryDetail>
              <SummaryDetail>
                <strong>Tutor: </strong>
                {formatName(details.customer.name.fullName || '')}
              </SummaryDetail>
              <SummaryDetail>
                <strong>Início: </strong>
                {details.createdAt}
              </SummaryDetail>
              <SummaryDetail>
                <strong>Status: </strong>
                {details.status}
              </SummaryDetail>
            </SummaryDetails>

            <SummaryTitle>Ciclo:</SummaryTitle>
            <SummaryServicesTable>
              <SummaryServicesTableHeadLine>
                <span>Data</span>
                <span>Serviço</span>
                <span>Repasse</span>
              </SummaryServicesTableHeadLine>

              {cycleAppointments.map(cycle =>(
                <SummaryServicesTableLine>
                  <span>{cycle.appointment.date}</span>
                  <span>{cycle.service.name}</span>
                  <span>R$ {formatMoney(cycle.service.price.totalPrice)}</span>
                </SummaryServicesTableLine>
              ))}

            </SummaryServicesTable>
            <SummaryServicesTotalAmount><strong>Repasse Total: </strong> <span>R$ {formatMoney(totalAmount)}</span></SummaryServicesTotalAmount>
          </div>
          <Alert.Actions>

            <Alert.Confirm
              onClick={CSubscriber.closeCycleAppointments}
            >
              Fechar
            </Alert.Confirm>
          </Alert.Actions>
        </Alert.Content>
      </Alert.Root>
  )
}
