import { useCallback, useState } from 'react'
import ScrollArea from 'react-scrollbar'

import { useRanking } from '../../../../hooks/RankingContext'

import { Employee } from '../Employee'

import { Title } from '../styles'
import {
  Container,
  Concorrentes,
  Header,
  HeaderItem,
  Concorrente,
  ConcorrenteSale,
  ConcorrenteAccomplished,
} from './styles'

export function Store() {
  const [scrollHeight, setScrollHeight] = useState(-1)

  const { getStoreRanking, getRankingInfo, getPagination, updatePagination } =
    useRanking()

  const rankingInfo = getRankingInfo()
  const ranking = getStoreRanking()

  const pagination = getPagination()

  const paginationOnScroll = useCallback(
    ({ realHeight, topPosition, containerHeight }) => {
      if (
        typeof topPosition === 'number' &&
        topPosition + containerHeight >= realHeight - 200 &&
        pagination
      ) {
        if (realHeight > scrollHeight) {
          updatePagination(pagination.store + 1, 'store')
          setScrollHeight(realHeight)
        }
      }
    },
    [pagination, scrollHeight, updatePagination],
  )

  return (
    <Container>
      <Title>
        Meta por vendedor:
        {!!rankingInfo && rankingInfo.storeGoal}
      </Title>
      <ScrollArea
        smoothScrolling
        onScroll={paginationOnScroll}
        horizontal={false}
      >
        <Concorrentes>
          <Header>
            <HeaderItem>Vendedor</HeaderItem>
            <HeaderItem>Total Vendido</HeaderItem>
            <HeaderItem>(%) Realizado</HeaderItem>
          </Header>
          {!!ranking &&
            ranking.map((player) => (
              <Concorrente key={player.id}>
                <Employee.Root size="mini">
                  <Employee.Position
                    value={player.position}
                    tip={
                      player.position >= 100 &&
                      `ranking-employee-${player.id}-position`
                    }
                  />
                  <Employee.Informations>
                    <Employee.InformationTitle>
                      {player.name}
                    </Employee.InformationTitle>
                  </Employee.Informations>
                </Employee.Root>
                <ConcorrenteSale>{player.quantity}</ConcorrenteSale>
                <ConcorrenteAccomplished>
                  {Math.ceil(player.percentage)}%
                </ConcorrenteAccomplished>
              </Concorrente>
            ))}
        </Concorrentes>
      </ScrollArea>
    </Container>
  )
}
