import React from "react";
import CountUp from "react-countup";
import { Coins, PiggyBank, RefreshCcw, Wallet } from "lucide-react";

import { useInvoice } from "../../../../store/subscription/useInvoice";
import { Summary } from "../../../../components/ui/Summary";

export function ExtratoSummary() {
  const summary = useInvoice((st) => st.data.summary);

  return (
    <Summary.Root>
      <Summary.Box>
        <Summary.Icon icon={PiggyBank} />
        <Summary.TextContent>
          <Summary.Title>
            R$
            <CountUp
              end={summary.totalInputs}
              separator="."
              prefix=" "
              decimals={2}
              decimal=","
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Entradas</Summary.Text>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={Coins} />
        <Summary.TextContent>
          <Summary.Title>
            R${" "}
            <CountUp
              end={summary.totalFees}
              separator="."
              decimals={2}
              decimal=","
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Taxas Transacionais</Summary.Text>
          <Summary.SubTitle>taxa: 3,8%</Summary.SubTitle>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={RefreshCcw} />
        <Summary.TextContent>
          <Summary.Title>
            R${" "}
            <CountUp
              end={summary.totalChargeBacks}
              separator="."
              decimals={2}
              decimal=","
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Estornos e Cancelamentos</Summary.Text>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={Wallet} />
        <Summary.TextContent>
          <Summary.Title>
            R${" "}
            <CountUp
              end={summary.netValue}
              separator="."
              decimals={2}
              decimal=","
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Líquido</Summary.Text>
        </Summary.TextContent>
      </Summary.Box>
    </Summary.Root>
  );
}
