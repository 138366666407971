import { Settings } from "lucide-react";

import { Drawer } from "../../../../../components/ui/Drawer";
import { Tabs } from "../../../../../components/ui/Tabs";

import { SubscriptionDREController } from "../../../../../services/controllers/subscription";

import { ConfigureInformationGoal } from "./TabContent/Goal";
import { ConfigureInformationExpenses } from "./TabContent/Expenses";
import { ConfigureInformationFaq } from "./TabContent/Faq";

export function ConfigureInformationDrawer() {
  const CLDre = SubscriptionDREController()

  return (
    <>
      <Drawer.Root
        name={CLDre._configurationDrawerName}
        style={{ width: "90vw" }}
        direction="left"
      >
        <Drawer.Controls controls={{ close: CLDre.closeConfigurationDrawer }} />
        <Drawer.Header><Settings className='mr-2 w-5 h-5'/> Configurações</Drawer.Header>
        <Drawer.Content>
          <Tabs.Root
            name={`${CLDre._configurationTabsName}`}
            tabs={["Meta", "Despesas", "FAQ"]}
          >
            <Tabs.Content tabName="Meta">
              <ConfigureInformationGoal />
            </Tabs.Content>
            <Tabs.Content tabName="Despesas">
              <ConfigureInformationExpenses />
            </Tabs.Content>
            <Tabs.Content tabName="FAQ">
              <ConfigureInformationFaq />
            </Tabs.Content>
          </Tabs.Root>
        </Drawer.Content>
        <Drawer.Footer />
      </Drawer.Root>
    </>
  );
}
