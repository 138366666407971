import { memo } from "react";
import { ChevronsRight } from "lucide-react";

import { usePet } from "../../../../../store/global/usePet";

import { VeterinaryServiceController } from "../../../../../services/controllers/VeterinaryCare";

import { Action } from "../../../../../components/ui/Action";
import { Alert } from "../../../../../components/ui/Alert";
import { Table } from "../../../../../components/ui/Table";

import { Quantity } from './styles'

export const AvailableServicePackages = memo(() => {
  const CLVetService = VeterinaryServiceController();

  const veterinaryPackageServices = usePet(st => st.data.pet.packages?.veterinary) || []

  return (
    <Alert.Root name={CLVetService._availableServicePackagesAlertName}>
      <Alert.Content>
        <Alert.Title>Serviços disponíveis!</Alert.Title>
        <Alert.Description>
          <Table.Root
            name={CLVetService._availableServicePackagesAlertName}
            style={{ minWidth: "100%", maxHeight: "100%" }}
          >
            <Table.Header columns={["Serviço", "Quantidade:103px", "#:70px"]} />
            <Table.Content style={{ overflowY: 'auto', maxHeight: '275px' }}>
              {veterinaryPackageServices.map((service) => (
                <Table.Line key={service.id}>
                  <Table.Item> {service.name}</Table.Item>
                  <Quantity>{service.quantityAvailable}</Quantity>
                  <Table.Item>
                    <Action.Root
                      outline
                      title='Adicionar Serviço'
                      name={`${CLVetService._availableServicePackagesAlertName}-add-service-${service.id}`}
                      onClick={() => CLVetService.addServiceFromServicePackage(service)}
                    >
                      <Action.Icon icon={ChevronsRight} />
                    </Action.Root>
                  </Table.Item>
                </Table.Line>
              ))}
            </Table.Content>
          </Table.Root>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
