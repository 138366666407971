import { toast } from "react-toastify";

import api from "../../api";
import { formatResponses } from "../../formatResponses/veterinaryCare";
import { formatBody } from "../../formatBody/veterinaryCare";

export default class VeterinaryPrescriptionModel {
  constructor(orderId) {
    this._orderId = orderId;
  }

  findPrescriptions = async () => {
    try {
      const { data } = await api.get(
        `/prescriptions/${this._orderId}`
      );

      const formattedPrescriptions = formatResponses.prescriptions(
        data.prescriptions
      );

      return formattedPrescriptions;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  deletePrescription = async (prescriptionId) => {
    try {
      const { data } = await api.delete(`prescriptions/${this._orderId}/${prescriptionId}`);
      return data

    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  savePrescription = async (prescription) => {
    if (!prescription) return;

    const formattedValues = formatBody.prescription(prescription);

    try {
      const { data } = await api.put(`prescription/${this._orderId}/new2`, formattedValues);

      const formattedPrescription = formatResponses.prescription(
        data.createdPrescription
      );

      return formattedPrescription;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  updatePrescription = async (prescription) => {
    if (!prescription) return;

    const formattedValues = formatBody.prescription(prescription);

    try {
      const { data } =  await api.post(`prescription/${this._orderId}/${prescription.id}`, formattedValues);
      const formattedPrescription = formatResponses.prescription(
        data.updatedPrescription
      );

      return formattedPrescription;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  sendPrescriptionFile = async (prescriptionId, formData) => {
    try {
      const { data } = await api.put(
        `prescription/${prescriptionId}/pdf`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return data.pdfFile

    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

}
