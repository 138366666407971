import styled from 'styled-components'
import { BoxColumn } from '../styles'

export const Container = styled(BoxColumn)``

export const CommentList = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  list-style: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

export const CommentBox = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 12px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #cccccc;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
`

export const CommentTitle = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin: 0;
`

export const Comment = styled.p`
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
`

export const CommentTags = styled.ul`
  margin: 24px 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  li {
    background: #eee;
    border-radius: 7px;
    padding: 2px 10px;
    transition: all 250ms linear;
    &:hover {
      transform: scale(1.1);
    }
  }
`
