import { Container } from "./styles";
import { usePagination } from "./usePagination";
import { PaginationProvider } from "./PaginationContext";
import { useEffect } from "react";

export function PaginationRoot({
  children,
  name,
  totalPages,
  currentPage,
  onChange = () => null,
}) {

  const pagination = usePagination((st) => st.data.pagination[name]);
  const setPagination = usePagination((st) => st.actions.setPagination);
  const setCurrentPage = usePagination((st) => st.actions.setCurrentPage);

  const updatePaginationProps = usePagination(
    (st) => st.actions.updatePaginationProps
  );

  setPagination(name, { totalPages, currentPage });

  if (!!pagination && pagination?.currentPage !== currentPage) {
    updatePaginationProps(name, { currentPage });
  }

  if (!!pagination && pagination?.totalPages !== totalPages) {
    updatePaginationProps(name, { totalPages });
  }

  useEffect(() => {
    if (!!pagination && pagination.pageEmitter !== false) {
      setCurrentPage(name, false);
      onChange(pagination.pageEmitter);
    }
  }, [pagination, onChange]);

  return (
    <PaginationProvider paginationName={name}>
      <Container>{children}</Container>
    </PaginationProvider>
  );
}
