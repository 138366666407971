
const prescription = {}
const request = {}

prescription.incompleteRegistration = `
  ⚠️
  <span>O cadastro {{petArticles.0}} <strong>{{petName}}</strong> está incompleto.</span>
  <span>Isso pode afetar a receita, especialmente para medicamentos que exigem informações detalhadas sobre o pet e/ou seu tuto</span>
  <span>Por favor, atualize as informações necessárias para garantir um cuidado adequado para {{petArticles.1}} <strong>{{petName}}.</strong> <a href='/clientes/resumo/{{customerID}}' target='_blank'>Atualizar Cadastro</a></span>
`

request.incompleteRegistration = `
  ⚠️
  <span>O cadastro {{petArticles.0}} <strong>{{petName}}</strong> está incompleto.</span>
  <span>Isso pode impactar a requisição, uma vez que informações detalhadas sobre o pet e/ou seu tutor são indispensáveis para determinados tipos de requisições.</span>
  <span>Por favor, atualize as informações necessárias para garantir um cuidado adequado para {{petArticles.1}} <strong>{{petName}}.</strong> <a href='/clientes/resumo/{{customerID}}' target='_blank'>Atualizar Cadastro</a></span>
`

export const veterinaryCareWarnings = Object.freeze({
  prescription,
  request
})



