import { Container, PetAvatar, PetName } from './styles'

export function Pet({ index, petName, petId, activePetState }) {
  const [active, setActive] = activePetState
  return (
    <Container
      delay={index * 180 * 0.3}
      onClick={() => {
        setActive(petId)
      }}
    >
      <PetAvatar className={active === petId ? 'active' : ''}>
        {petName.charAt(0)}
      </PetAvatar>
      <PetName className={active === petId ? 'active' : ''}>{petName}</PetName>
    </Container>
  )
}
