import { usePlans } from "../../../store/subscription/usePlans";
import { PlanModel } from "../../models";

import { Alert } from "../../../components/ui/Alert";

export default class PlanController {
  alertIsOpen = Alert.store.getState().actions.isOpen;

  constructor() {
    this.name = "subscription-plan";
    this.saveRulesAlertName = `${this.name}-save-rules`;
    this.MPlans = PlanModel();
  }

  findPlans = async (force = false) => {
    const { plans } = usePlans.getState().data;
    if (plans && !force) return;

    await this.MPlans.findPlans(force);
  };

  setFactor = (planId, factor) => {
    this.MPlans.setFactor(planId, factor);
  };

  saveRulesAlert = async (e) => {
    if (e) e.preventDefault();
    this.alertIsOpen(this.saveRulesAlertName, true);
  };

  saveRules = async (e) => {
    if (e) e.preventDefault();
    const savedRules = await this.MPlans.saveRules();

    if (!savedRules) this.findPlans(true);
    this.alertIsOpen(this.saveRulesAlertName, false);
  };

  undoRules = async (e) => {
    if (e) e.preventDefault();

    this.MPlans.undoRules();
    this.alertIsOpen(this.saveRulesAlertName, false);
  };
}
