import React from 'react'

import { Grid, Divider } from '@material-ui/core'

function Title({ label }) {
  return (
    <Grid spacing={3} container xs={12}>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
        <h3>{label}</h3>
      </Grid>
    </Grid>
  )
}

export default Title
