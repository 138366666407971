import { dateFormat } from '../../../../services/functions'
import {
  Container,
  PetInformationList,
  PetInformationLine,
  PetTags,
  PetTag,
  PetInformationFooter,
} from './styles'

export function PetInformation({
  subscription,
  lastAppointment,
  nextAppointment,
  petName,
  hasPackage,
  petSize,
  petBreed,
  petFur,
  petId,
  activePet,
}) {
  return (
    <Container className={activePet === petId ? 'active' : ''}>
      <PetInformationList>
        <PetInformationLine>
          Nome: <strong>{petName}</strong>
        </PetInformationLine>
        <PetInformationLine>
          Raça: <strong>{petBreed}</strong> / Porte: <strong>{petSize}</strong>{' '}
          / Pelagem: <strong>{petFur}</strong>
        </PetInformationLine>
      </PetInformationList>
      <PetTags>
        {!!subscription && <PetTag>ASSINANTE</PetTag>}
        {hasPackage && <PetTag>PACOTE</PetTag>}
      </PetTags>
      <PetInformationFooter>
        <PetInformationLine>
          Último agendamento:{' '}
          <strong>
            {lastAppointment
              ? dateFormat(lastAppointment.appointmentDate)
              : '--'}
          </strong>
        </PetInformationLine>
        <PetInformationLine>
          Próximo agendamento:{' '}
          <strong>
            {nextAppointment
              ? dateFormat(nextAppointment.appointmentDate)
              : '--'}
          </strong>
        </PetInformationLine>
      </PetInformationFooter>
    </Container>
  )
}
