import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;

  & input {
    padding: 15px 14px;
    color: #666666;
  }
`
