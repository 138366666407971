import { ArrowLeft } from "lucide-react";
import { Action } from "../../../ui/Action";

import { Container, BoxActions } from "./styles";
import { ScheduleController } from "../../../../services/controllers/scheduling";

export function Actions({
  reset = false,
  cancel = false,
  actions = [],
  submit = false,
}) {
  const VetScheduling = ScheduleController();

  return (
    <Container>
      {!!reset && (
        <Action.Root
          outline
          type="button"
          status="danger"
          {...reset}
          onClick={VetScheduling.reset}
        >
          <ArrowLeft size={16} />{" "}
          {reset.text || (
            <>
              Voltar para <strong>Agendado</strong>
            </>
          )}
        </Action.Root>
      )}
      {!!cancel && (
        <Action.Root
          status="danger"
          {...cancel}
          type="button"
          onClick={VetScheduling.delete}
        >
          {cancel.text || "Cancelar Agendamento"}
        </Action.Root>
      )}
      <BoxActions>
        {!!actions.length &&
          actions.map((actionButton) => (
            <Action.Root key={actionButton.id} {...actionButton}>
              {actionButton.text || "ação"}
            </Action.Root>
          ))}

        {!!submit && (
          <Action.Root {...submit}>{submit.text || "Salvar"}</Action.Root>
        )}
      </BoxActions>
    </Container>
  );
}
