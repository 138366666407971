import { ListChecks } from 'lucide-react'

import { SubscriberController } from '../../../../../services/controllers/subscription';

import { Action } from "../../../../../components/ui/Action";

export function CycleActions({cycle}){
  const CSubscriber = SubscriberController();

  return (
    <Action.Root
      outline
      status="400"
      type="button"
      title="Ver Detalhes"
      name={`see-cycle-detail-${cycle.id}`}
      onClick={()=> CSubscriber.findCycleDetails(cycle.id)}
    >
      <Action.Icon icon={ListChecks} size={22} />
    </Action.Root>
  )
}
