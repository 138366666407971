import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    background: '#FFFFFF',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px 0',
    margin: '16px 20px',
    borderBottom: '3px solid #EBE8E8',
  },
  petImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  pet: {
    width: '60px',
    textDecoration: 'none',
    height: '60px',
    borderRadius: '50%',
    background: '#CCD219',
    margin: '0 -20px 0 0',
    border: '3px solid #ffffff',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    overflow: 'hidden',
    transition: 'all 150ms linear',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  },
}))
