import styled from 'styled-components'

export const Container = styled.div`
  gap: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Actions = styled.div`
  display: flex;
  gap: 14px;
  height: fit-content;
  flex-wrap: wrap;
  &>*{
    flex: 1;
  }
`
