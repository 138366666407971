import styled, { css, keyframes } from "styled-components";
import { darken, rgba } from "polished";
import { Root as CheckboxRoot, Indicator as CheckboxIndicator, } from "@radix-ui/react-checkbox";

const removeAnimate = keyframes`
  0%{}
  20%{ opacity: 1; height: 51px;  overflow: hidden;  transform: translateX(0);  }
  70%{ opacity: 0; }
  90%{ transform: translateX(-105%); }
  100%{ height: 0px; border: none}
`;

const returnAnimate = keyframes`
  0%{ opacity: 0; height: 0px; transform: translateX(-105%);  }
  20%{ opacity: 0;}
  70%{ transform: translateX(0); height: 51px;  border-bottom: 1px solid ${(props) => props.theme.colors.gray[300]}; }
  90%{ opacity: 1; pointer-events: initial }
  100%{}
`;

export const TableConfigItem = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 0;
  list-style: none;
`;

export const TableConfigCheckIndicator = styled(CheckboxIndicator)`
  transition: all ${(props) => props.theme.animation.delay.fast} ease;
  background: ${(props) => props.theme.colors.scale[500]};
  border-radius: 3px;
  width: 70%;
  height: 70%;
`;

export const TableConfigCheck = styled(CheckboxRoot)`
  border: 1px solid ${(props) => props.theme.colors.gray[100]};
  background: ${(props) => props.theme.colors.background};
  outline: none;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px ${(props) => rgba(props.theme.colors.black, 0.2)};
  transition: all ${(props) => props.theme.animation.delay.fast} ease;
  cursor: pointer;
  &:hover {
    border-color: ${(props) => props.theme.colors.scale[500]};
  }
  &:disabled{
    cursor: default;
    pointer-events: none;
    ${TableConfigCheckIndicator}{
      background: ${(props) => props.theme.colors.gray[300]};
    }
  }
`;


export const TableConfigLabel = styled.label`
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.gray[800]};
`;

export const TableLine = styled.div`
  --tableLine-bg-hover-color: ${(props) => props.theme.colors.gray[100]};

  ${(props) => props.type === "outflow" && css`
      --tableLine-bg-hover-color: ${(props) =>
      rgba(props.theme.colors.error, 0.15)};
  `}

  border-bottom: 1px solid ${(props) => props.theme.colors.gray[300]};
  display: grid;
  gap: 3px;
  grid-template-columns: ${(props) => props.columns};
  transition: all ${(props) => props.theme.animation.delay.slow} ease;
  font-size: 14px;
  &:hover {
    background: var(--tableLine-bg-hover-color);
  }
  &:last-child {
    border: none;
  }

  ${(props) => props.removed && css`
      overflow: hidden;
      pointer-events: none;
      minheight: 51px;
      background: ${(props) => rgba(props.theme.colors.error, 0.3)};
      animation: ${removeAnimate} ${(props) => props.theme.animation.delay.slow}
        ease-in-out forwards;
  `};
  ${(props) => props.returned && css`
      overflow: hidden;
      background: transparent;
      animation: ${returnAnimate} ${(props) => props.theme.animation.delay.slow}
        ease-in-out forwards;
   `};
  ${(props) => !props.returned && !props.removed && css`
      opacity: 1;
      min-height: 51px;
      transform: translateX(0%);
  `};
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.gray[300]};
  border-top: none;
  color: ${(props) => props.theme.colors.gray[800]};
  border-radius: 0 0 3px 3px;
`;

export const TableItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  padding: 5px 0px;
  & > span {
    padding: 0px 10px 0px 15px;
    width: 100%;

    ${(props) =>
    props.type === "inflow" &&
    css`
        color: ${(props) => darken(0.2, props.theme.colors.success)};
      `}
    ${(props) =>
    props.type === "outflow" &&
    css`
        color: ${(props) => props.theme.colors.error};
        padding-left: 7px;
        &::before {
          content: "- ";
        }
      `}
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  background: ${(props) => props.theme.colors.scale[500]};
  transition: all ${(props) => props.theme.animation.delay.slow} ease;
  gap: 3px;
  border-radius: 5px 5px 0 0;
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  color: ${(props) => props.theme.colors.background};
  ${TableItem} {
    span {
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

export const TableHeaderButton = styled.button`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.background};
  transition: all ${(props) => props.theme.animation.delay.fast} ease;
  cursor: pointer;
  font-weight: bold;

  &:hover:not(:disabled) {
    transform: scale(1.1);
  }
  &:active:not(:disabled) {
    transform: scale(0.95);
  }

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
    opacity: .5;
  }


  &[data-hidden="true"]{
      opacity:0;
}
  ${(props) =>
    props.inverse &&
    css`
      color: ${(props) => props.theme.colors.scale[500]};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  min-height: 100%;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 10px;

  ${(props) =>
    props.config &&
    css`
      ${TableItem} {
        overflow: hidden;
      }
    `}
`;
