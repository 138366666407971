import { toast } from "react-toastify";

import api from "../../api";

import { formatBody } from "../../formatBody/veterinaryCare";
import { formatResponses } from "../../formatResponses/veterinaryCare";

export default class VeterinaryProtocolModel {
  constructor() { }

  findVaccinesProtocols = async (vaccineId) => {
    if (!vaccineId) {
      throw new Error(
        "Model:VeterinaryProtocolModel - getVaccinesProtocols: vaccineId não informado!"
      );
    }

    try {
      const { data } = await api.get(`vaccine/${vaccineId}/protocols`);
      const formattedProtocols = formatResponses.protocols(data.protocols);

      return formattedProtocols;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  findAssignedVaccinationProtocol = async (petId) => {
    if (!petId) {
      throw new Error(
        "Model:VeterinaryProtocolModel - findVaccinesProtocols: petId não informado!"
      );
    }

    try {
      const { data } = await api.get(`vaccinations/protocols/${petId}`);

      const assignedVaccinationProtocol =
        formatResponses.assignedVaccinationProtocols({
          protocols: data.protocolsIndex,
          vaccinations: data.vaccinationsList,
        });

      return assignedVaccinationProtocol;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveVaccinationProtocol = async (vaccineId, protocolData) => {
    const protocolBody = formatBody.protocol(protocolData);

    if (!vaccineId) {
      toast.error("Nenhuma vacina foi informada", { theme: "colored" });
      return;
    }

    if (protocolBody.doses > 10) {
      toast.error(
        `Atualmente, o número máximo de doses permitido é 10. Estamos empenhados em desenvolver uma solução que atenda a uma quantidade de doses ainda maior, até mesmo doses ilimitadas. Por favor, considere ajustar a duração ou a frequência para garantir que o número de doses não ultrapasse o limite estabelecido de 10.`,
        { theme: "colored" }
      );
      return;
    }

    try {
      const { data } = await api.post(
        `vaccine/${vaccineId}/protocol`,
        protocolBody
      );

      return formatResponses.protocol(data.protocolCreated);
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  assignVaccinationProtocol = async (orderId, vaccinationProtocolBody) => {
    try {
      const { data } = await api.post(
        `service-order/appointment/${orderId}/add-vaccination`,
        vaccinationProtocolBody
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  resumeVaccinationProtocol = async (body) => {
    try {
      const { data } = await api.post(
        "vaccines/uninterrupt-pet-protocol",
        body
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  interruptVaccinationProtocol = async (body) => {
    try {
      const { data } = await api.post("vaccines/interrupt-pet-protocol", body);

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  removeVaccinationProtocol = async (body) => {
    try {
      const { data } = await api.post("vaccines/delete-pet-protocol", body);

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  removeVaccinationProtocol = async (body) => {
    try {
      const { data } = await api.post("vaccines/delete-pet-protocol", body);

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
