import { Action } from "../../../../../../../components/ui/Action";
import { VeterinaryPrescriptionController } from "../../../../../../../services/controllers/VeterinaryCare";
import { MedicineController } from "../../../../../../../services/controllers/global";

import { CustomMedicneContainer, CustomMedicneContent } from "./styles";

export function CustomMedicine({name}){
  const CLVetPrescription = VeterinaryPrescriptionController();
  const CLMedicine = MedicineController(CLVetPrescription._medicinesId);

    return (
      <CustomMedicneContainer>
        <Action.Root
          full
          outline
          type="button"
          onClick={() => {
            CLMedicine.selectCustomMedicine(name)
            CLVetPrescription.setNewCustomMedicineProps(name)
          }}
        >
        <CustomMedicneContent>
          Adicionar: <strong>{name}</strong>
        </CustomMedicneContent>
        </Action.Root>
      </CustomMedicneContainer>
    )
}
