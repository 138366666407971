import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

// Material ui - style
import { makeStyles, withStyles } from '@material-ui/core/styles'

// Material ui - core
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
} from '@material-ui/core'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'
import API from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 300,
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
  title: {
    fontSize: '20px',
    textAlign: 'center',
  },
  divider: {
    margin: '35px -5px',
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#00567e',
    '&:hover': {
      backgroundColor: '#0097a9',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

function Service({ service }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [queueList, setQueueList] = useState(false)
  const history = useHistory()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, service?.length - page * rowsPerPage)

  const classes = useStyles()

  useEffect(() => {
    async function getQueue() {
      try {
        const { data } = await API.post('service-order/appointments/queue', {
          date: moment().format('YYYY-MM-DD'),
        })
        setQueueList([...data.queue])
      } catch (err) {
        console.log('Erro:', err)
      }
    }
    getQueue()
  }, [])

  return (
    <Grid container spacing={3} style={{ width: '100%' }}>
      <Grid item xs={12} spacing={3} style={{ padding: '40px' }}>
        <Typography
          gutterBottom
          variant="h5"
          component="h3"
          className={classes.title}
        >
          Fila de Atendimento
        </Typography>

        <Divider className={classes.divider} />
        <TableContainer component={Paper} elevation={0}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 160 }}>Tutor</TableCell>
                <TableCell align="center">Pet</TableCell>
                <TableCell align="center">Hora</TableCell>
                <TableCell align="center">Chegada</TableCell>
                <TableCell align="center">Veterinário</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            {queueList ? (
              <TableBody>
                {queueList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.customerName}>
                      <TableCell component="th" scope="row">
                        {row.customerName}
                      </TableCell>
                      <TableCell align="center">{row.petName}</TableCell>
                      <TableCell align="center">{row.startsAt}</TableCell>
                      <TableCell align="center">
                        {row.arrivalHour
                          ? moment(row.arrivalHour).format('LT')
                          : 'CD ya'}
                      </TableCell>
                      <TableCell align="center">{row.employee}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">
                        <CustomButton
                          fullWidth
                          color="secondary"
                          size="smail"
                          type="submit"
                          justify="center"
                          alignItems="center"
                          className={classes.button}
                          onClick={() => {
                            history.push(
                              `/clinica/atendimento/${row.appointmentId}`,
                              {
                                employeeId: row.employeeId,
                              },
                            )
                          }}
                        >
                          Atender
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={service?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Service
