import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    background: '#FFFFFF',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px 0',
    margin: '16px 20px',
    flex: 'initial',
  },
  petImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  pet: {
    width: '40px',
    height: '40px',
    textDecoration: 'none',
    borderRadius: '50%',
    background: '#CCD219',
    margin: '0 -10px 0 0',
    border: '3px solid #ffffff',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'all 150ms linear',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  },
}))
