import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;

  & > * {
    min-width: fit-content;
    flex: 1;
  }
`;

export const PartialContainer = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PartialSwitchBox = styled.div`
  min-width: 100%;
`;

export const PartialFields = styled.div`
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;

  & > * {
    min-width: fit-content;
    flex: 1;
  }
`;
