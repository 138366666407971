import { DayPicker } from 'react-day-picker'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  margin: 0;
`
export const Calendar = styled(DayPicker)`
  margin: 0;
  .rdp-caption_label {
    text-transform: capitalize;
    color: ${(props) => props.theme.colors.scale[500]};
  }

  .rdp-day_today:not(.rdp-day_outside):not(.rdp-day_selected) {
    color: ${(props) => props.theme.colors.scale[500]};
  }
  .rdp-day_selected {
    background-color: ${(props) => props.theme.colors.scale[500]};
    &:hover {
      background-color: ${(props) => props.theme.colors.scale[600]};
    }
  }
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${(props) => props.theme.colors.scale[300]};
    color: ${(props) => props.theme.colors.background};
  }
  .rdp-button:focus-visible:not([disabled]) {
    background-color: transparent;
    border: transparent;
  }
`
