import { Dog, RefreshCw } from "lucide-react";
import { IconBox } from "./styles";

export function ActionIcon({ icon: Icon = Dog, isLoading = false, ...rest }) {
  if (isLoading) Icon = RefreshCw;

  return (
    <IconBox isLoading={isLoading}>
      <Icon size={18} {...rest} />
    </IconBox>
  );
}
