import { PetCardAttribute } from "./PetCardAttribute";
import { PetCardAttributes } from "./PetCardAttributes";
import { PetCardAvatar } from "./PetCardAvatar";
import { PetCardBadges } from "./PetCardBadges";
import { PetCardNote } from "./PetCardNote";
import { PetCardRoot } from "./PetCardRoot";

export const PetCard = {
  Root: PetCardRoot,
  Avatar: PetCardAvatar,
  Attributes: PetCardAttributes,
  Attribute: PetCardAttribute,
  Note: PetCardNote,
  Badges: PetCardBadges,
};
