import ReportVaccinationsClass from "./ReportVaccinations";

let CMReportVaccinations = null;

export function ReportVaccinationsController() {
  if (CMReportVaccinations) {
    return CMReportVaccinations;
  }
  CMReportVaccinations = new ReportVaccinationsClass();

  return CMReportVaccinations;
}
