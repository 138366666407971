
import { twMerge } from 'tailwind-merge'

export function CardHighlight({ className, ...rest }) {
  return (
    <div
      className={twMerge(
        'flex rounded-md border border-black px-4 py-2 text-sm font-semibold text-black items-center gap-1',
        className,
      )}
      {...rest}
    />
  )
}
