import { RoyaltyStatementModel } from "../../models/reports";

export default class RoyaltyStatementController {
  constructor() {
    this.name = "reports-royalty-statement";
    this.tableName = `${this.name}-table`;
    this.paginationName = `${this.name}-pagination`;

    this.MRoyaltyStatement = RoyaltyStatementModel();

    this.init();
  }

  init = async () => {
    this.findRoyaltyStatements()
  };

  findRoyaltyStatements = async () => {
    await this.MRoyaltyStatement.findRoyaltyStatements();
  };

  setPage = (page) => {
    this.MRoyaltyStatement.setPage(page);
  };

  reportExport = () => {
    this.MRoyaltyStatement.export();
  };
}
