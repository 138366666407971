import styled from "styled-components";
import { Table } from "../../../../../components/ui/Table";

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  h2 {
    color: ${(props) => props.theme.colors.scale[500]};
    margin: 0;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;
