import uuid from "react-uuid";
import slugify from "slugify";
import { create } from "zustand";
import { produce } from "immer";

export const useAccordion = create((set) => ({
  data: {
    accordions: {},
  },
  actions: {
    setAccordion: (name, option) =>
      set((state) =>
        produce(state, (draft) => {
          const defaultOpen = option?.defaultOpen
            ? option.defaultOpen.map(
              (defaultValue) =>
                `${name}/${slugify(defaultValue.toLowerCase())}`
            )
            : [];

          if (!state.data.accordions[name]) {
            draft.data.accordions[name] = {
              id: `accordion-${uuid()}`,
              type: option?.type || "single",
              open: _.compact(_.concat([], option?.open, defaultOpen)),
            };
          }
        })
      ),
    setOpen: (name, items) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.accordions[name]) return;
          draft.data.accordions[name].open = _.concat(
            state.data.accordions[name].open,
            items
          );
        })
      ),
    setClose: (name, items) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.accordions[name]) return;

          _.pullAll(draft.data.accordions[name].open, items);
        })
      ),

    toggle: (name, item) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.accordions[name]) return;

          if (state.data.accordions[name].type === "single") {
            draft.data.accordions[name].open = [];
          }

          if (state.data.accordions[name].open.includes(item)) {
            _.pull(draft.data.accordions[name].open, item);
            return;
          }

          draft.data.accordions[name].open.push(item);
        })
      ),
  },
}));
