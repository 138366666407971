import { isAbleByStatus } from "../../../../../services/functions";

import { useCare } from "../../../../../store/service/veterinary/useCare";
import { useRecord } from "../../../../../store/service/veterinary/useRecord";

import { VeterinaryRecordController } from "../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../components/ui/Form/Input";

import { Container } from "./styles";

export function RecordsContentsGeneralPhysicalExamination() {
  const careStatus = useCare(st => st.data.care.status)

  const ears = useRecord((st) => st.data.record?.ears);
  const skin = useRecord((st) => st.data.record?.skin);
  const hydration = useRecord((st) => st.data.record?.hydration);
  const lymphNodes = useRecord((st) => st.data.record?.lymphNodes);
  const mucous = useRecord((st) => st.data.record?.mucous);

  const CLVetRecord = VeterinaryRecordController();

  const isAble = isAbleByStatus(careStatus,CLVetRecord._name)


  return (
    <Container>
      <Input.Root
        label="Ouvidos"
        value={ears}
        disabled={!isAble}
        style={{ minWidth: "300px" }}
        onChange={(ears) => CLVetRecord.changeRecordValue({ ears })}
        onBlur={CLVetRecord.saveGeneralPhysicalExamination}
      />
      <Input.Root
        label="Mucosas"
        value={mucous}
        disabled={!isAble}
        style={{ minWidth: "300px" }}
        onChange={(mucous) => CLVetRecord.changeRecordValue({ mucous })}
        onBlur={CLVetRecord.saveGeneralPhysicalExamination}
      />
      <Input.Root
        label="Hidratação"
        value={hydration}
        disabled={!isAble}
        style={{ minWidth: "300px" }}
        onChange={(hydration) => CLVetRecord.changeRecordValue({ hydration })}
        onBlur={CLVetRecord.saveGeneralPhysicalExamination}
      />
      <Input.Root
        label="Linfonodos"
        value={lymphNodes}
        disabled={!isAble}
        style={{ minWidth: "300px" }}
        onChange={(lymphNodes) => CLVetRecord.changeRecordValue({ lymphNodes })}
        onBlur={CLVetRecord.saveGeneralPhysicalExamination}
      />
      <Input.Root
        label="Pele/Pelo"
        value={skin}
        disabled={!isAble}
        style={{ minWidth: "300px" }}
        onChange={(skin) => CLVetRecord.changeRecordValue({ skin })}
        onBlur={CLVetRecord.saveGeneralPhysicalExamination}
      />
    </Container>
  );
}
