import { VACCINATION_STATUS } from "../../store/reports/veterinary/constants";
import {
  abbreviate,
  clearNumber,
  formatCellPhoneNumber,
  formatName,
  splitName,
  stringDateFormat,
} from "../functions";

function formatVaccine(vaccine) {
  return {
    id: vaccine.id,
    name: formatName(vaccine.name),
  };
}

function formatProtocol(protocol) {
  return {
    id: protocol._id,
    name: formatName(protocol.name),
  };
}

function formatVaccination(vaccination) {
  const customerFullName = formatName(
    `${vaccination.customerFirstName.trim()} ${vaccination.customerLastName.trim()}`
  );
  const customerSplittedName = splitName(customerFullName);
  const customerAbbreviateName = abbreviate(customerFullName);

  return {
    recordId: vaccination.recordId,
    id: vaccination.vaccinationId,
    status: VACCINATION_STATUS[vaccination.status] || "programmed",
    date: stringDateFormat(vaccination.date),
    customer: {
      id: vaccination.customerId,
      phone: clearNumber(vaccination.customerCellphone),
      name: {
        first: customerSplittedName.firstname,
        last: customerSplittedName.firstname,
        abbreviate: customerAbbreviateName,
        full: customerFullName,
        filterString: customerFullName.toLowerCase().replace(/[^a-z0-9]/g, ""),
      },
    },
    pet: {
      id: vaccination.petId,
      name: formatName(vaccination.petName),
      filterString: vaccination.petName.toLowerCase().replace(/[^a-z0-9]/g, ""),
    },
    protocol: {
      id: vaccination.protocolId,
      name: formatName(vaccination.protocolName),
    },
    vaccine: {
      id: vaccination.vaccineId,
      name: formatName(vaccination.vaccineName),
      lab: formatName(vaccination?.labName) || "-",
      batch: vaccination?.batch || "-",
    },
  };
}

export const formatResponse = {
  vaccine: (vaccine) => formatVaccine(vaccine),
  vaccines: (vaccines) => {
    if (!vaccines.length) return [];
    return vaccines.map(formatVaccine);
  },
  protocol: (protocol) => formatProtocol(protocol),
  protocols: (protocols) => {
    if (!protocols.length) return [];
    return protocols.map(formatProtocol);
  },
  vaccination: (vaccination) => formatVaccination(vaccination),
  vaccinations: (vaccinations) => {
    if (!vaccinations.length) return [];
    return vaccinations.map(formatVaccination);
  },
};
