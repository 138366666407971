import moment from "moment";
import {
  CARE_STATUS,
  VACCINATION_PROTOCOL_STATUS,
  VACCINE_STATUS,
} from "../../store/service/veterinary/constants";

import { dateFormat, formatName, getVeterinaryCareStatusName, stringDateFormat } from "../functions";

function formatCare(care) {
  const procedureName = formatName(care.procedureName)

  const titleDate = moment(care.date).format('DD/MM/YYYY')
  const titleHour = care.startAt
  const titleProcedure = procedureName

  const pageTitle = `${titleProcedure} - ${titleDate} às ${titleHour}`;

  const services = _.map(care.services, (service) => ({ id: service.serviceId, serviceOrderId: service.id, price: service.totalPrice }));

  return {
    id: care.id,
    orderId: care.appointmentOrderId,
    date: care.date,
    hour: care.startAt,
    note: care.comment,
    pageTitle,
    status: CARE_STATUS[care.status] || "error",
    seller: care.sellerId,
    veterinarian: care.vetId,
    pet: care.pet.id,
    customer: care.customer.id,
    status: getVeterinaryCareStatusName(care.status),
    procedure: {
      id: care.procedureId,
      name: procedureName
    },
    veterinarian: {
      id: care.vetId,
      name: care.vetName
    },
    services: services
  };
}

function formatProtocol(protocol) {
  return {
    id: protocol._id,
    vaccineId: protocol.vaccineId,
    name: protocol.name,
    specie: protocol.specie,
    alertRules: protocol.alertRules,
    doses: protocol.doses,
    intervalBetweenDoses: protocol.intervalBetweenDoses,
    createdAt: protocol.createdAt,
  };
}

function formatAssignedVaccinationProtocol(assignedVaccinationProtocol) {
  const protocols = assignedVaccinationProtocol.protocols;
  const vaccinations = assignedVaccinationProtocol.vaccinations;

  const assignedProtocol = _.orderBy(
    protocols.map((protocol) => ({
      id: protocol.protocolId,
      vaccineName: protocol.vaccineName,
      protocolName: protocol.protocolName,
      status:
        VACCINATION_PROTOCOL_STATUS[protocol.statusLabel.toLowerCase()] ||
        "error",
      scheduledDate: stringDateFormat(protocol.vaccinationDate),
      interrupted: protocol.interrupted,
      delayed: protocol.late,
    })),
    "vaccineName"
  );



  const assignedVaccinations = _.groupBy(
    vaccinations.map((vaccine) => ({
      id: vaccine.id,
      vaccineId: vaccine.vaccineId,
      protocolId: vaccine.protocolId,
      batch: vaccine.batch,
      lab: formatName(vaccine.laboratoryName),
      status: VACCINE_STATUS[vaccine.vaccinationStatus.toLowerCase()],
      scheduledDate: stringDateFormat(vaccine.vaccinationDate),
      applicationDate: VACCINE_STATUS[vaccine.vaccinationStatus.toLowerCase()] ==='applied'?stringDateFormat(vaccine.vaccinationDate) : null,
      updated: true,
    })),
    "protocolId"
  );

  return { assignedProtocol, assignedVaccinations };
}

function formatService(service) {
  const suggestionCode = !!service.internalCode ? `${service.internalCode} - ` : '';

  return {
    id: service.id,
    name: formatName(service.name),
    suggestionName: `${suggestionCode}${formatName(service.name)}`,
    category: service.category,
    defaultDuration: service.defaultDurationInMinutes,
    durations: service.durationOptions,
    code: String(service.internalCode || ""),
    price: service.price,
    originalPrice: service.price,
    active: service.status === "ATIVO",
  };
}

function formatAttachment(attachment) {
  return {
    id: attachment._id || attachment.id,
    name: attachment.fileName,
    type: attachment.fileExtension,
    path: attachment.fileUrl,
    size: attachment.fileSize,
    date: attachment.attachmentDate,
  };
}

function formatPrescription(prescription) {
  return {
    type: 'pdf',
    id: prescription._id,
    createdDate: prescription.createdAt,
    updatedDate: prescription.updatedAt,
    name: prescription.prescriptionName,
    path: prescription.pdfFile,
    medicines: prescription.medicines
  }
}

function formatRecord(attachment) {
  return {
    weight: attachment.petWeight,
    anamnesis: attachment.mainComplaint,

    fc: attachment.exam.heartRate,
    fr: attachment.exam.respiratoryRate,
    tpc: attachment.exam.TPC,
    temperature: +attachment.exam.temperature,

    ears: attachment.exam.earsCondition,
    mucous: attachment.exam.mucosa,
    hydration: attachment.exam.hydration,
    lymphNodes: attachment.exam.lymphNode,
    skin: attachment.exam.skinFur,

    chest: attachment.exam.chest,
    abdomen: attachment.exam.abdomen,
    thoracicLimbs: attachment.exam.thoracicLimbs,
    pelvicLimbs: attachment.exam.pelvicLimbs,

    diagnosis: attachment.diagnosisAndTreatment,
  };
}

function formatRequest(request) {
  return {
    id: request._id,
    date: request.createdAt,
    name: request.requestName,
    path: request.pdfFile,
    contentType: request.requestType,
    type: 'pdf',
    path: request.pdfFile,
    values: request.variables,
    content: request.content,
  }
}

function formatHistory(history) {
  return {
    date: dateFormat(history.date),
    procedures: history.procedures.map((procedure) => ({
      id: procedure.appointmentId,
      name: procedure.procedureName,
      vetName: procedure.vetName,
      hasAnamnesis: !!procedure.anamnesis,
      hasAttachments: !!procedure.attachments,
      hasVaccinations: !!procedure.vaccinations,
    }))
  }
}

export const formatResponses = {
  care: (care) => formatCare(care),
  cares: (cares) => {
    if (!cares.length) return [];
    return cares.map(formatCare);
  },
  protocol: (protocol) => formatProtocol(protocol),
  protocols: (protocols) => {
    if (!protocols.length) return [];
    return protocols.map(formatProtocol);
  },
  assignedVaccinationProtocols: (protocols) =>
    formatAssignedVaccinationProtocol(protocols),

  service: (service) => formatService(service),
  services: (services) => {
    if (!services.length) return [];
    return services.map(formatService);
  },
  attachment: (attachment) => formatAttachment(attachment),
  attachments: (attachments) => {
    if (!attachments.length) return [];
    return attachments.map(formatAttachment);
  },
  prescription: (prescription) => formatPrescription(prescription),
  prescriptions: (prescriptions) => {
    if (!prescriptions.length) return [];
    return prescriptions.map(formatPrescription);
  },
  record: (record) => formatRecord(record),
  records: (record) => {
    if (!record.length) return [];
    return record.map(formatRecord);
  },
  request: (request) => formatRequest(request),
  requests: (request) => {
    if (!request.length) return [];
    return request.map(formatRequest);
  },
  history: (history) => formatHistory(history),
  histories: (histories) => {
    if (!histories.length) return [];
    return histories.map(formatHistory);
  },
};
