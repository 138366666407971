import { useParams } from "react-router-dom";
import { FilePlus2 } from "lucide-react";

import {
  getArticles,
  getBuddy,
  getOur,
  getPreviewListPlaceholder,
  isAbleByStatus
} from "../../../../services/functions";

import { usePet } from "../../../../store/global/usePet";
import { useCare } from "../../../../store/service/veterinary/useCare";
import { useVaccine } from "../../../../store/service/veterinary/useVaccine";

import { VeterinaryVaccineController } from "../../../../services/controllers/VeterinaryCare";

import { Action } from "../../../../components/ui/Action";
import { ListPreview } from "../../../../components/ui/ListPreview";

import { NewVaccinationProtocolDrawer } from "./NewVaccinationProtocolDrawer";
import { IncompleteVaccinationProtocol } from "./Alerts/IncompleteVaccinationProtocol";
import { VaccineProtocolDrawer } from "./VaccineProtocolDrawer";

export function VeterinaryVaccines() {
  const { orderId } = useParams();

  const careStatus = useCare(st => st.data.care.status)

  const pet = usePet(st => st.data.pet);
  const assignedProtocols = useVaccine((st) => st.data.assignedProtocols);

  const CLVetVaccine = VeterinaryVaccineController(orderId);

  const isAble = isAbleByStatus(careStatus,CLVetVaccine._name)

  if (!!pet?.id) CLVetVaccine.init();

  const article = getArticles(pet.petAttributes?.gender);
  const our = getOur(pet.petAttributes?.gender);
  const buddy = getBuddy(pet.petAttributes?.gender);

  const placeholderValues = {
    article: article[0],
    our: our[0],
    buddy: buddy[1],
    petName: pet.name
  }

  return (
    <>
      <ListPreview.Root
        name={CLVetVaccine._previewListName}
        list={assignedProtocols}
        placeholder={getPreviewListPlaceholder(CLVetVaccine._previewListName, placeholderValues)}
        customAction={{
          open: CLVetVaccine.openVaccinationProtocolDrawer,
          close: CLVetVaccine.closeVaccinationProtocolDrawer,
        }}
        options={{
          selected: null,
          listStyle: "list",
          showStyles: ["smallGrid", "list"],
        }}
      >
        <ListPreview.Header>
          <Action.Root disabled={!isAble} onClick={CLVetVaccine.openNewVaccinationProtocolDrawer}>
            <Action.Icon icon={FilePlus2} />
            Novo Protocolo
          </Action.Root>
        </ListPreview.Header>
      </ListPreview.Root>

      <NewVaccinationProtocolDrawer />
      <IncompleteVaccinationProtocol />
      <VaccineProtocolDrawer />
    </>
  );
}
