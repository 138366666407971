import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import React from 'react'

// import { Container } from './styles';

function ProfileDashboard({ item }) {
  return (
    <ListItem style={{ padding: '0' }}>
      <ListItemAvatar>
        <Avatar
          style={{
            backgroundColor: '#D24519',
            textTransform: 'capitalize',
            fontWeight: '700',
          }}
        >
          {item?.name.substr(0, 1)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            variant="subtitle1"
            style={{
              fontSize: '13px',
              textTransform: 'capitalize',
              fontWeight: '700',
              color: '#666666',
            }}
          >
            {item?.name}
          </Typography>
        }
        secondary={
          <span
            style={{
              color: `${
                item?.currentQuantity / item?.lastQuantity - 1 > 0
                  ? '#CCD219'
                  : '#E10050'
              }`,
            }}
          >
            {Number.isFinite(item?.currentQuantity / item?.lastQuantity - 1) &&
              (item?.currentQuantity / item?.lastQuantity - 1).toLocaleString(
                'pt-BR',
                { style: 'percent' },
              )}
          </span>
        }
      />
    </ListItem>
  )
}

export default ProfileDashboard
