import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { usePet } from "../../../../store/global/usePet";
import { PetController } from "../../../../services/controllers/global";

export function AccordionServiceHistory(props){
  PetController(props.pet.id).init()
  const pet = usePet(st => st.data.pet)

  if(!pet || !pet.serviceHistory) return null

  const { services } = pet.serviceHistory

  return(
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Histórico de serviços</Typography>
        <span className="ml-auto bg-scale-500 mr-2 rounded-md leading-none px-2 py-1 h-6 text-white flex items-center justify-center">novo</span>
      </AccordionSummary>
      <AccordionDetails>
        <ul className="flex flex-col w-full space-y-4">
          <li class="flex w-full flex-col rounded-md">
            <div className="flex flex-col gap-0 pl-2">
              {services.map(service => (
                <p key={service.id} class="text-sm text-zinc-600">
                  <strong class="font-bold text-zinc-600">{service.title}: </strong>
                  {service.dateMsg}
                </p>
              ))}
            </div>
          </li>
        </ul>
      </AccordionDetails>
    </Accordion>
  )
}
