import styled from 'styled-components'
import { rgba } from 'polished'
import { Action } from '../Action'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin: 10px auto 10px auto;
`

export const PaginationPageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0 15px;
`

export const PaginationPage = styled(Action.Root)`
  width: auto;
  flex: initial;
  padding: 0;
  height: 30px;
  min-width: 30px;
`

export const PaginationBack = styled(PaginationPage)`
  gap: 0;
  svg {
    margin-right: calc(-15px * 0);
    margin-left: calc(-15px * 1);
    transition: all ${(props) => props.theme.animation.delay.fast} ease;
    filter: ${(props) =>
      `drop-shadow(5px 5px 5px ${rgba(props.theme.colors.black, 0.2)})`};
    &:first-child {
      margin-right: 0;
      margin-left: 0;
    }
  }
`
