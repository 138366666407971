import _ from "lodash";
import Mustache from "mustache";

import { templatesFunctions } from "../../services/templates/functions";

import { BODY_PARSER } from "../../styles/constant";

import {
  autoFormatMedicineDosageInformation,
  dateFormatedToDB,
  getPeriodDiff,
  getTotalDosesWithFrequencyInDuration,
} from "../functions";
import { partials } from "../templates/partials";

function formatProtocol(protocol) {
  const durationData = {
    duration: protocol.duration,
    durationPeriod: protocol.durationPeriod,
  };
  const frequencyData = {
    frequency: protocol.frequency,
    frequencyPeriod: protocol.frequencyPeriod,
  };

  const intervalBetweenDoses = getPeriodDiff(protocol.frequency, {
    from: protocol.frequencyPeriod,
    to: "day",
  });

  const doses = getTotalDosesWithFrequencyInDuration(
    durationData,
    frequencyData
  );

  return {
    doses,
    intervalBetweenDoses,
    name: protocol.name,
    specie: BODY_PARSER.specie[protocol.species],
    sendSms: true,
    daysBeforeApplicationToSendSMS: 5,
  };
}

function formatVaccinationProtocol(vaccinationProtocol) {
  return _.omitBy(
    {
      date: dateFormatedToDB(vaccinationProtocol.date),
      batch: vaccinationProtocol.batch || null,
      lab: vaccinationProtocol.lab || null,
      protocolId: vaccinationProtocol.protocolId,
      vaccineId: vaccinationProtocol.vaccineId,
    },
    _.isEmpty
  );
}

function formatVaccine(vaccine) {
  return {
    scheduleDate: dateFormatedToDB(vaccine.scheduledDate),
    applicationDate: dateFormatedToDB(vaccine.applicationDate),
    laboratoryName: vaccine.lab,
    batch: vaccine.batch,
  };
}

function formatService(service) {
  return {
    serviceId: service.id,
    price: service.price,
  };
}

function formatPrescription(prescription) {
  const medicines = prescription.medicines.map(medicine => ({
    fillStyle: medicine.fillStyle,
    medicineName: medicine.name,
    medicineVariation: medicine.presentation,
    posology: autoFormatMedicineDosageInformation(medicine),
    pharmacyType: medicine.pharmacyType,
    dose: medicine.dose,
    measure: medicine.measure,
    frequency: medicine.frequency,
    frequencyPeriod: medicine.frequencyPeriod,
    duration: medicine.duration,
    durationPeriod: medicine.durationPeriod,
    usage: medicine.usage,
    note: medicine.note,
    quantity: medicine.quantity,
  }))

  return {
    prescriptionName: prescription.name.trim(),
    medicines
  };
}

function formatReport(report) {
  return {
    mainComplaint: report.anamnesis,
    diagnosisAndTreatment: report.diagnosis,
    exam: {
      heartRate: report.fc,
      respiratoryRate: report.fr,
      TPC: report.tpc,
      temperature: report.temperature,
      earsCondition: report.ears,
      mucosa: report.mucous,
      hydration: report.hydration,
      lymphNode: report.lymphNodes,
      skinFur: report.skin,
      chest: report.chest,
      abdomen: report.abdomen,
      thoracicLimbs: report.thoracicLimbs,
      pelvicLimbs: report.pelvicLimbs,
    },
  };
}

function formatRequest(request) {
  const partialsContents = _.mapValues(partials, "content");

  const fields = {
    ...templatesFunctions.get.partialsFieldsFormat(request.requestData),
    ...templatesFunctions.get.fieldsFormat(request.requestData.fields),
  };

  const addBreakLines = templatesFunctions.addBreakLines(request.requestValues);
  const formattedValues = templatesFunctions.format(fields, addBreakLines)

  const contentData = Mustache.render(
    request.requestData.content,
    formattedValues,
    partialsContents
  )

  return {
    requestName: request.name.trim(),
    requestType: request.requestPath,
    content: contentData,
    variables: request.requestValues
  };
}

export const formatBody = {
  protocol: (protocol) => formatProtocol(protocol),
  addVaccinationProtocol: (vaccinationProtocol) =>
    formatVaccinationProtocol(vaccinationProtocol),
  changeVaccine: (vaccine) => formatVaccine(vaccine),
  service: (service) => formatService(service),
  services: (services) => {
    if (!services.length) return [];
    return services.map(formatService);
  },
  prescription: (prescription) => formatPrescription(prescription),
  prescriptions: (prescriptions) => {
    if (!prescriptions.length) return [];
    return prescriptions.map(formatPrescription);
  },
  report: (report) => formatReport(report),
  reports: (reports) => {
    if (!reports.length) return [];
    return reports.map(formatReport);
  },
  request: (request) => formatRequest(request),
  requests: (requests) => {
    if (!requests.length) return [];
    return requests.map(formatRequest);
  },
};
