import { CALENDAR } from '../../../../styles/constant'
import { useEmployee } from '../../../../store/schedule/useEmployee'

import {
  Container,
  SliderRoot,
  SliderTrack,
  SliderRange,
  SliderThumb,
  StepMarkList,
  Tip,
} from './styles'

export function Slider() {
  const {
    employee: { size },
    actions: { updatEmployeeSize },
  } = useEmployee()

  return (
    <Container>
      <SliderRoot
        className="SliderRoot"
        defaultValue={[size]}
        max={1000}
        min={150}
        onValueChange={(value) => {
          updatEmployeeSize(value)
        }}
      >
        <SliderTrack className="SliderTrack">
          <SliderRange className="SliderRange" />
        </SliderTrack>
        <SliderThumb className="SliderThumb">
          <Tip>{((size * 100) / CALENDAR.public.collumnSize).toFixed(0)}%</Tip>
        </SliderThumb>
      </SliderRoot>
      <StepMarkList>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </StepMarkList>
    </Container>
  )
}
