import _ from "lodash";
import { BATH_USED_PRODUCT } from "../../../../../../store/subscription/constants";

export function ConfigureInformationFaq() {
  return (
    <div className="flex flex-col px-4 w-full text-base">
      <strong className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">Pessoas:</strong>
      <div className="w-full flex flex-col mt-2 mb-4 px-4 text-zinc-700 gap-0">
        <p><strong className="text-zinc-600 font-semibold">Salário base: </strong>é o valor médio do salário base de um banhista</p>
        <p><strong className="text-zinc-600 font-semibold">Comissão: </strong>é a porcentagem do valor do serviço de assinatura paga ao profissional por cada banho realizado através do serviço de assinatura.</p>
      </div>

      <strong className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">Banho</strong>
      <div className="w-full flex flex-col mt-2 mb-4 px-4 text-zinc-700 gap-0">
        <p><strong className="text-zinc-600 font-semibold">Shampoo Neutralizador: </strong>é o valor pago em 5 litros de Shampoo Neutralizador.</p>
        <p><strong className="text-zinc-600 font-semibold">Shampoo Neutro: </strong>é o valor pago em 5 litros de Shampoo Neutro.</p>
        <p><strong className="text-zinc-600 font-semibold">Condicionador: </strong>é o valor pago em 5 litros de Condicionador.</p>
        <p><strong className="text-zinc-600 font-semibold">Hidratante: </strong>é o valor pago em 5 litros de Hidratante.</p>
        <p><strong className="text-zinc-600 font-semibold">Adereço: </strong>é o valor unitário do adereço adicionado em um banho realizado.</p>
        <p><strong className="text-zinc-600 font-semibold">Toalha: </strong>é o valor unitário da toalha gasto por cada banho realizado.</p>
      </div>

      <strong className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">Como é calculada a despesa com Pessoas?:</strong>
      <div className="w-full flex flex-col mt-1 mb-4 px-4 text-zinc-700 gap-2">
        <p>Analisamos todos os profissionais que realizam serviços de banho por assinatura. Para determinar o valor da hora de cada banhista, utilizamos o salário base do profissional e dividimos pelo número de horas trabalhadas.</p>
        <p>Isso nos permite calcular o custo por hora do serviço oferecido.</p>
      </div>

      <strong className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">Como é calculada a despesa com Comissão?:</strong>
      <div className="w-full flex flex-col mt-1 mb-4 px-4 text-zinc-700 gap-2">
        <p>Analisamos todos os profissionais que realizam serviços de banho por assinatura. Para determinar a comissão de cada banhista, utilizamos a comissão do profissional e dividimos pelo número de banhos.</p>
        <p>Isso nos permite calcular o custo com comissão do serviço oferecido.</p>
      </div>

      <strong className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">Como é calculada a despesa com Adereços?:</strong>
      <div className="w-full flex flex-col mt-1 mb-4 px-4 text-zinc-700 gap-2">
        <p>Analisamos todos os serviços de banho por assinatura, multiplicamos o numero de banhos pelo valor do Adereço.</p>
        <p>Isso nos permite calcular o custo com Adereços do serviço oferecido.</p>
      </div>

      <strong className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">Como é calculada a despesa com Toalhas?:</strong>
      <div className="w-full flex flex-col mt-1 mb-4 px-4 text-zinc-700 gap-2">
        <p>Temos uma base de quantas toalhas são gastas. Calculamos o gasto de toalhas para cada porte de animal.</p>
        <p>Para determinar a despesa com toalhas, multiplicamos o valor unitário de uma toalha pela quantidade de toalhas gastas em cada porte e pelo número de banhos de cada porte.</p>
        <p>Isso nos permite calcular o custo com Toalhas do serviço oferecido.</p>
      </div>

      <strong className="text-lg text-scale-500 border-b border-scale-500 w-full font-semibold">Como é calculada a despesa com Produtos?:</strong>
      <div className="w-full flex flex-col mt-1 mb-4 px-4 text-zinc-700 gap-2">
        <p>Temos uma base de quanto cada produto rende. Calculamos o gasto do produto já diluído para cada porte de animal.</p>
        <p>Para determinar o valor do produto gasto em cada banho, utilizamos 5 litros do produto diluído em água na proporção de uma parte do produtos para 10 partes de água resultando em 55 litros, dividimos a quantidade utlizada no banho de cada porte pelo total da solução e esse valor multiplicamos ele pelo valor do produtos.</p>
        <p>Por exemplo, pra porte Mini pelo Curto é utilizado 100ml de Shampoo Neutralizador, ou seja, 0,18% dos 55L(Shampoo + Água). neste caso para 5L de Shampoo Neutralizador a R$ 288,00, um banho de um pet porte mini tem o custo de R$ 0,52, aproximadamente, de Shampoo Neutralizador.</p>
        <p>Por exemplo, pra porte Mini pelo Curto é utilizado 100ml de Shampoo Neutralizador, ou seja, 0,18% dos 55L(Shampoo + Água). neste caso para 5L de Shampoo Neutralizador a R$ 288,00, um banho de um pet porte mini tem o custo de R$ 0,52, aproximadamente, de Shampoo Neutralizador.</p>
        <p><strong>Formulá do cálculo:</strong></p>
        <p>VB = (PU/(((L * D) + L) * 1000)) * VG</p>
        <ul className="text-zinc-400 text-sm">
          <li><strong className="text-zinc-500">VB: </strong>valor do produto por banho.</li>
          <li><strong className="text-zinc-500">PU: </strong>mililitros(ml) de produto já diluído utilizado no banho.</li>
          <li><strong className="text-zinc-500">L: </strong>litragem de um galão do produto, utilizamos como base 5L.</li>
          <li><strong className="text-zinc-500">D: </strong>valor da porporção para diluição do produto.</li>
          <li><strong className="text-zinc-500">VG: </strong>valor pago em um galão do produto.</li>
        </ul>

        <div className="w-full flex flex-col gap-0">
          <p><strong>Consideramos as seguinte diluições:</strong></p>
          <p className="pl-2"><strong className="font-semibold text-zinc-500">Shampoo: </strong>proporção de 1 para 10, <strong>D = 10</strong></p>
          <p className="pl-2"><strong className="font-semibold text-zinc-500">Condicionador: </strong>proporção de 1 para 10, <strong>D = 10</strong></p>
          <p className="pl-2"><strong className="font-semibold text-zinc-500">Hidratante: </strong>sem diluição, <strong>D = 1</strong></p>
        </div>
        <div className="w-full grid grid-auto-fit-[30rem] gap-x-2 gap-y-6">
          {_.map(BATH_USED_PRODUCT, (product, key) => (
            <div key={key} className="w-full">
              <p><strong>{product.name}:</strong></p>
              <table className="w-[calc(100%_-_16px)] mx-[8px] border border-zinc-300">
                <tr className="border-b border-zinc-300 last:border-0">
                  <td className="px-3 py-1 last:border-0 font-semibold text-zinc-400">Pelo</td>
                  <td className="px-3 py-1 last:border-0 font-semibold text-scale-500">Mini</td>
                  <td className="px-3 py-1 last:border-0 font-semibold text-scale-500">Pequeno</td>
                  <td className="px-3 py-1 last:border-0 font-semibold text-scale-500">Médio</td>
                  <td className="px-3 py-1 last:border-0 font-semibold text-scale-500">Grande</td>
                  <td className="px-3 py-1 last:border-0 font-semibold text-scale-500">Gigante</td>
                </tr>
                {_.map(product.usage, (usage, usageKey) => (
                  <tr key={usageKey} className="border-b border-zinc-300 last:border-0">
                    <td className="px-3 py-1 font-semibold text-scale-500 last:border-0">{usage.name}</td>
                    {_.map(usage.quantities, (quantity) => (
                      <td key={quantity} className="px-3 py-1 last:border-0">{quantity}</td>
                    ))}
                  </tr>
                ))}
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
