import { memo } from "react";
import { FaWhatsapp } from "react-icons/fa";

import { SEND_SCHEDULE_STATUS } from "../../../styles/constant";

import { useSchedule } from "../../../store/schedule/useSchedule";
import { Action } from "../../ui/Action";
import { Container } from "./styles";
import { ScheduleController } from "../../../services/controllers/scheduling";

export const Title = memo(() => {
  const CLScheduling = ScheduleController();

  const status = useSchedule(st => st.data.status);
  const title = useSchedule(st => st.data.title);


  const showSendShedule = SEND_SCHEDULE_STATUS.includes(status)

  return (
    <Container>
      {title}
      {showSendShedule &&
        <Action.Root status="success" onClick={CLScheduling.sendCareSummary}>
          Enviar
          <Action.Icon icon={FaWhatsapp} size={18} />
        </Action.Root>
      }
    </Container>
  )
})
