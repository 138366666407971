export const messageSendPrescription = {
  name: "Mensagem de envio de Prescrição",
  gender: "female",
  content: `
  %0AOlá *{{tutorName}}* 🐶,
  %0A%0A*{{veterinaryName}}* da *{{storeName}}* enviou a *{{prescriptionName}}* {{petArticle}} *{{petName}}*, gerada em *{{{date}}}*. Você pode conferir ela acessando o link abaixo:
  %0A%0A{{{prescriptionPath}}}
  %0A%0ASe precisar de alguma assistência ou tiver dúvidas, estamos à disposição.
  %0A%0AAtenciosamente,
  %0A*{{veterinaryName}}*
  %0A_{{storeName}}_ 🩺 🐾
  `.trim(),

};
