import { Dog, User } from "lucide-react";

import { usePet } from "../../../../../store/global/usePet";

import { PetCard } from "../../../../../components/ui/PetCard";
import { Accordion } from "../../../../../components/ui/Accordion";

import { PreviewSeparator } from "./styles";
import { IncompleteRegistration } from "./IncompleteRegistration";

export function DocumentsHeader({ name = "", warning = false }) {
  const pet = usePet((st) => st.data.pet);
  const showWarning = !!warning && !pet?.isCompleteRegistration

  return (
    <>
      <Accordion.Root
        options={{
          type: "multiple",
          defaultOpen: ["Dados do Pet", "Dados do tutor"],
        }}
        name={`veterinary-document-header-${name}`}
      >
        <Accordion.Item title="Dados do Pet" icon={Dog}>
          <Accordion.Content>
            <PetCard.Root size="small" data-full noAction noBubble>
              <PetCard.Avatar url={pet.photo} attributes={pet.attributes} />
              <PetCard.Attributes
                attributes={pet.attributes}
                show={[
                  "Nome",
                  "Espécie",
                  "Raça",
                  "Sexo",
                  "Peso",
                  "Nascimento",
                  "Idade",
                ]}
              />
              <PetCard.Badges show={pet.badges} direction="column" />
            </PetCard.Root>
          </Accordion.Content>
        </Accordion.Item>
        <Accordion.Item title="Dados do tutor" icon={User}>
          <Accordion.Content>
            <PetCard.Root size="small" data-full noAction noBubble>
              <PetCard.Attributes
                attributes={pet.attributes}
                direction="column"
                show={["Tutor", "TutorCpf", "TutorPhone", "TutorAddress"]}
              />
              <PetCard.Badges show={pet.badges} direction="column" />
            </PetCard.Root>
            <PreviewSeparator />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
      {showWarning && <IncompleteRegistration content={warning} />}
    </>
  );
}


