import { ptBR } from 'date-fns/locale'
import 'react-day-picker/dist/style.css'
import { useState } from 'react'
import { useSchedule } from '../../../../../../../../../hooks/ScheduleContext'

import { Container, Calendar } from './styles'

export function PickerDay({ onChange }) {
  const { getConfig } = useSchedule()
  const config = getConfig()
  const [selected, setSelected] = useState(new Date(`${config.date} 00:00:00`))

  return (
    <Container>
      <Calendar
        mode="single"
        selected={selected}
        onSelect={(d) => {
          setSelected(d)
          onChange(d)
        }}
        locale={ptBR}
      />
    </Container>
  )
}
