import React, { useEffect, useRef, useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { decode } from "html-entities";
import { Link } from "react-router-dom";

import Autocomplete from "@material-ui/lab/Autocomplete";

import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { Check, Warning, DeleteForever } from "@material-ui/icons";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  TableRow,
  Checkbox,
  Divider,
  MenuItem,
  Drawer,
  Tooltip,
  Typography,
  Paper,
  Snackbar,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ColorizeIcon from "@material-ui/icons/Colorize";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import moment from "moment";
import DrawerContent from "./DrawerContent";

// Material ui - lab

import ModalType from "../../atoms/Modal";
import { getServices } from "../../../services/services";

import API from "../../../services/api";
import "moment/locale/pt-br";
import Prescription from "../Prescription";
import SummaryPrescription from "../../molecules/SummaryPrescription";
import { PrescriptionContext } from "../../../providers/prescription";
import { Protocols } from "./Protocols";
import { useProtocols } from "../../../store/protocols/useProtocols";

moment.locale("pt-br");

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  table: {
    minWidth: 400,
  },
  avatar: {
    backgroundColor: "#CCD219",
    fontSize: "50px",
    height: "90px",
    width: "90px",
  },
  filter: {
    margin: " 0",
    background: "#fff",
    width: "100%",
    borderRadius: "7px",
    padding: "15px",
  },
  petName: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    color: "#ccc",
  },
  assinante: {
    fontSize: "8px",
    background: "#f50057",
    color: "#fff",
    padding: "3px 6px",
    borderRadius: "37px",
    marginLeft: "20px",
  },
  button: {
    backgroundColor: "#CCD219",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#CCD219",
    },
  },
  buttonEnd: {
    border: "1px solid #f50057",
    backgroundColor: "#fff",
    marginRight: "10px",
    color: "#f50057",
    "&:hover": {
      backgroundColor: "#f50057",
      color: "#fff",
    },
  },
  buttonWhatsApp: {
    color: "#fff",
    fontSize: "12px",
    borderRadius: "7px",
    border: "0",
    backgroundColor: "#25d366",
    "&:hover": {
      backgroundColor: "#1dba58",
      border: 0,
      color: "#fff",
    },
  },
  pet: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  listServices: {
    width: "min(600px, 100%)",
  },
  total: {
    textAlign: "center",
    fontWeight: "400",
    display: "block",
    fontSize: "18px",
    marginBottom: "-15px",
  },
  price: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    textAlign: "center",
    width: "100%",
    fontWeight: "700",
    display: "block",
    padding: "10px",
    fontSize: "36px",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#666666",
  },
  espaco: {
    margin: "15px 0",
  },
  customerDetails: {
    fontSize: "14px",
    color: "#878c93",
  },
  successTitle: {
    color: "#47A59D",
    fontSize: "36px",
    margin: 0,
    textAlign: "center",
    marginTop: "30px",
  },
  back: {
    color: "#666",
    display: "flex",
    fontSize: "14px",
    justifyContent: "space-around",
    textDecoration: "none",
    margin: "50px 5px",
  },
  discount: {
    marginBottom: "-10px",
    textDecoration: "line-through",
    color: "#878c93",
    fontSize: "11px",
  },
  history: {
    width: "100%",
    padding: "48px",
  },
  historyItem: {
    "&:last-child": {
      "& .globalHistoryItemService::before": {
        background: "transparent",
      },
    },
  },
  historyItemTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "&::before": {
      content: '" "',
      height: "18px",
      width: "18px",
      background: "#DCDCDC",
      borderRadius: "50%",
      marginRight: "12px",
    },
  },
  historyItemService: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "1px solid #CCCCCC",
    boxSizing: "border-box",
    borderRadius: "5px",
    position: "relative",
    margin: "24px 48px;",
    "&::before": {
      position: "absolute",
      content: '" "',
      height: "140px",
      left: "-40px",
      width: "2px",
      background: "#DCDCDC",
    },
  },
  historyItemServiceType: {
    background: "#EEEEEE",
    minHeight: "100%",
    padding: "12px 48px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "35px",
    },
    "& p": {
      margin: "0 0 6px 0 ",
    },
  },
  historyItemServicInformation: {
    display: "flex",
    flex: "1",
  },
  historyItemServicInformationOwner: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    padding: "0 24px",
    "& span": {
      color: "#CCCCCC",
    },
    "& p": {
      margin: "0",
      color: "#666666",
    },
  },
  historyItemServicInformationList: {
    margin: "0 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& span": {
      width: "36px",
      height: "36px",
      color: "#fff",
      background: "#f00",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      margin: "0 12px",
    },
  },
  historyItemServiceInformationButton: {
    marginRight: "15px",
  },
  tableCelll: {
    "& input": {
      padding: "10px 5px",
    },
  },
}));

const CustomButton = withStyles(() => ({
  root: {
    color: "#fff",
    border: "0",
    backgroundColor: "#00567e",
    "&:hover": {
      backgroundColor: "#0097a9",
      border: 0,
      color: "#fff",
    },
  },
}))(Button);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#00567e",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
    lineHeight: "42px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export function HistoryTable({ weightHis }) {
  const classes = useStyles();

  return (
    <>
      <p style={{ color: "#666666", fontSize: "16px", fontWeight: "bold" }}>
        Histórico de Peso
      </p>
      <Divider style={{ marginBottom: "15px" }} />
      <TableContainer component={Paper} elevation={0}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
          tabIndex={-1}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ width: 110 }}>Data</StyledTableCell>
              <StyledTableCell align="center" style={{ width: 160 }}>
                Peso
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {weightHis?.map((row) => (
              <StyledTableRow key={row?.date}>
                <StyledTableCell component="th" scope="row">
                  {row?.date}
                </StyledTableCell>
                <StyledTableCell align="center">{row?.weight}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export function TablePrice(props) {
  const classes = useStyles();
  const { control } = useForm();

  const handlePrice = async (val, id) => {
    props?.setTitleModal(null);
    try {
      const result = await API.post(`service-order/${id}/price`, {
        price: val,
      });

      if (result?.status === 201) {
        props?.setOpenModal(result?.data?.message);
        props?.setError(false);
        props?.setVerificationStatus(true);
      }
    } catch (err) {
      console.log("Erro:", err);
    }
  };

  return (
    <TableCell align="center" key={props?.row?.id}>
      <Controller
        control={control}
        disabled={props?.order?.status === "AGENDADO" || props?.ord}
        name="editPrice"
        defaultValue={props?.row?.totalPrice}
        as={({ onChange, value }) => (
          <OutlinedInput
            id="outlined-adornment-amount"
            value={value}
            disabled={props?.o}
            className={classes.tableCelll}
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={() => {
              handlePrice(value, props?.row?.id);
            }}
            startAdornment={
              <InputAdornment position="start">R$</InputAdornment>
            }
          />
        )}
      />
    </TableCell>
  );
}

function Main({ order, handleGetOrderUpdate, currentTab, setCurrentTab }) {
  const classes = useStyles();
  const { newPrescription, setNewPrescription, getPrescriptions, summary } =
    useContext(PrescriptionContext);
  const [anamnesis, setAnamnesis] = useState(false);
  const [services, setServices] = useState([]);
  const [historyList, setHistoryList] = useState(false);
  const [openModal, setOpenModal] = React.useState(null);
  const [agendaModal, setAgendaModal] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [weightHis, setWeightHis] = React.useState([]);
  const [errMsg, setErrMsg] = React.useState(false);
  const [alert, setAlert] = React.useState("");

  const [selectedServices, setSelectedServices] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [labs, setLabs] = useState([]);
  const [vaccineType, setVaccineType] = useState(false);
  const [vaccinations, setVaccinations] = useState([]);

  const [showAddServices, setShowAddServices] = useState(false);
  const [titleModal, setTitleModal] = React.useState(null);
  const [idDelete, setIdDelete] = React.useState(null);
  const [loadingService, setLoadingService] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerOrderId, setDrawerOrderId] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [attachments, setAttachments] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [newVaccine, setNewVaccine] = useState(false);

  const [pesoStatus, setPesoStatus] = useState("");
  const [totalServices, setTotalServices] = useState();
  const [fcStatus, setFcStatus] = useState("");
  const [frStatus, setFrStatus] = useState("");
  const [temperaturaStatus, setTemperaturaStatus] = useState("");
  const [tcpStatus, setTcpStatus] = useState("");
  const [ouvidosStatus, setOuvidosStatus] = useState("");
  const [mucosasStatus, setMucosasStatus] = useState("");
  const [hidratacaoStatus, setHidratacaoStatus] = useState("");
  const [linfonodosStatus, setLinfonodosStatus] = useState("");
  const [pelePeloStatus, setPelePeloStatus] = useState("");
  const [membrosToracicosStatus, setMembrosToracicosStatus] = useState("");
  const [toraxStatus, setToraxStatus] = useState("");
  const [membrosPelvicosStatus, setMembrosPelvicosStatus] = useState("");
  const [abdomeStatus, setAbdomeStatus] = useState("");
  const [diagnosticoStatus, setDiagnosticoStatus] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState("");
  const [protocolArray, setProtocolArray] = useState([]);

  const [viewModal, setViewModal] = useState(false);

  const {
    actions: { setPet, findProtocols },
  } = useProtocols();

  const closeModal = () => {
    setOpenModal(false);
    setAgendaModal(false);
    setTimeout(() => {
      setViewModal(false);
    }, 200);
  };

  async function getHistory() {
    const { data } = await API.get(
      `service-order/appointment/${order?.appointmentOrderId}/history`
    );
    setHistoryList(data.history);
  }

  useEffect(() => {
    if (order) getHistory();
  }, [order]);

  const [tabs] = useState([
    {
      value: 0,
      label: "Prontuário",
      show: true,
    },
    {
      value: 1,
      label: "VACINAS",
      show: true,
    },
    {
      value: 2,
      label: "ANEXOS",
      show: true,
    },
    {
      value: 3,
      label: "HISTÓRICO",
      show: true,
    },
    {
      value: 4,
      label: "SERVIÇOS",
      show: true,
    },
    {
      value: 5,
      label: "RECEITUÁRIO",
      show: true,
    },
  ]);

  const weightHistory = () => {
    setAgendaModal(true);
    setViewModal(true);
  };

  useEffect(() => {
    if (currentTab === 5) {
      getPrescriptions(order.appointmentOrderId);
    }
  }, [currentTab]);

  async function getAnamnese() {
    const result = await API.get(
      `service-order/appointment/${order.appointmentOrderId}/anamnesis`
    );
    setAnamnesis(result?.data.anamnesis);
  }

  useEffect(() => {
    const statusAllowed = [
      "AGENDADO",
      "EM ANDAMENTO",
      "FINALIZADO",
      "ORÇAMENTO ENVIADO",
      "VACINA PROGRAMADA",
    ];
    if (statusAllowed.includes(order?.status)) {
      getAnamnese();
    }
  }, [order]);

  useEffect(() => {
    const weightHistory = async () => {
      const { data } = await API.get(`pet/${order?.pet?.id}/weight-history`);
      setWeightHis(data?.result);
    }
    weightHistory()
  }, [order]);

  const { control, register, getValues, handleSubmit } = useForm({
    defaultValues: {
      scheduling: [
        {
          professional: "",
          duration: "",
          service: "",
          time: "",
        },
      ],
    },
  });

  const fileInput = useRef();

  const handleNewServices = async () => {
    setLoadingService(true);

    try {
      await API.post(
        `service-order/appointment/${order.appointmentOrderId}/service`,
        {
          serviceId: selectedServices?.id,
        }
      );
      handleGetOrderUpdate();
    } catch (error) {
      setShowAddServices(false);
      setSelectedServices([]);
    }

    setTimeout(() => {
      setLoadingService(false);
    }, 500);
  };

  async function handleServices() {
    const data = await getServices(order?.pet?.id, "Clínica Veterinária");
    setServices(data);
  }

  // Deletar Serviço
  const handleDelete = (id) => {
    setTitleModal("Cancelar Serviço");
    setAgendaModal("Você tem certeza que deseja cancelar esse serviço?");
    setError(true);
    setIdDelete(id);
  };

  const handleDeleteVaccine = (id) => {
    setIdDelete(id);
    setTitleModal("Remover Vacina");
    setAgendaModal("Você tem certeza que deseja remover essa vacina?");
    setError(true);
  };

  const handleHealthPlan = async (serviceOrderId, healthPlan) => {
    try {
      await API.post(
        `service-order/appointment/${order.appointmentOrderId}/service/health-plan`,
        {
          serviceOrderId,
          healthPlan: healthPlan === "true" ? "false" : "true",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (order) handleServices();
  }, [order]);

  useEffect(() => {
    if (selectedServices?.id) handleNewServices();
  }, [selectedServices]);

  const onChangeImage = (e) => {
    const input = e.target;

    const reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      setPreviewImage(dataURL);
    };
    reader.readAsDataURL(input.files[0]);
  };

  async function getVaccinations() {
    if (order?.appointmentOrderId) {
      const { data } = await API.get(
        `service-order/appointment/${order?.appointmentOrderId}/vaccinations`
      );
      setVaccinations(data.vaccinations);
    }
  }

  useEffect(() => {
    getVaccinations();
    setPet(order?.pet.id);
  }, [order]);

  useEffect(() => {
    async function getVaccine() {
      const { data } = await API.get("vaccines");
      setVaccines(data.vaccines);
    }
    getVaccine();
  }, []);

  useEffect(() => {
    async function getLabs() {
      const { data } = await API.get(`vaccine/${vaccineType}/labs`);
      setLabs(data.labs);
    }
    if (vaccineType) {
      getLabs();
    }
  }, [vaccineType]);

  useEffect(() => {
    async function getProtocols() {
      const { data } = await API.get(`vaccine/${vaccineType}/protocols`);
      setProtocolArray(data?.protocols);
    }

    if (vaccineType) {
      getProtocols();
    }
  }, [vaccineType]);

  const onSubmitDescription = async () => {
    const { description } = getValues();

    setDescriptionStatus("loading");

    const payload = {
      mainComplaint: description,
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setDescriptionStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setDescriptionStatus("erro");
    }
  };

  const onSubmitBpm = async () => {
    const { bpm } = getValues();

    setFcStatus("loading");

    const payload = {
      exam: {
        heartRate: bpm,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setFcStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setFcStatus("erro");
    }
  };
  const onSubmitDiagnostico = async () => {
    const { diagnostico } = getValues();
    setDiagnosticoStatus("loading");

    const payload = {
      diagnosisAndTreatment: diagnostico,
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setDiagnosticoStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setDiagnosticoStatus("erro");
    }
  };

  const onSubmitHidratacao = async () => {
    const { hidratacao } = getValues();

    setHidratacaoStatus("loading");

    const payload = {
      exam: {
        hydration: hidratacao,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setHidratacaoStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setHidratacaoStatus("erro");
    }
  };
  const onSubmitMpm = async () => {
    const { mpm } = getValues();

    setFrStatus("loading");

    const payload = {
      exam: {
        respiratoryRate: mpm,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setFrStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setFrStatus("erro");
    }
  };

  const onSubmitMucosas = async () => {
    const { mucosas } = getValues();

    setMucosasStatus("loading");

    const payload = {
      exam: {
        mucosa: mucosas,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setMucosasStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setMucosasStatus("erro");
    }
  };

  const onSubmitOuvidos = async () => {
    const { ouvidos } = getValues();

    setOuvidosStatus("loading");

    const payload = {
      exam: {
        earsCondition: ouvidos,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setOuvidosStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setOuvidosStatus("erro");
    }
  };

  const onSubmitSeg = async () => {
    const { seg } = getValues();

    setTcpStatus("loading");

    const payload = {
      exam: {
        TPC: seg,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setTcpStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setTcpStatus("erro");
    }
  };

  const onSubmitLymphNode = async () => {
    const { lymphNode } = getValues();

    setLinfonodosStatus("loading");

    const payload = {
      exam: {
        lymphNode,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setLinfonodosStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setLinfonodosStatus("erro");
    }
  };

  const onSubmitSkinFur = async () => {
    const { skinFur } = getValues();

    setPelePeloStatus("loading");

    const payload = {
      exam: {
        skinFur,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setPelePeloStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setPelePeloStatus("erro");
    }
  };

  const onSubmitThoracicLimbs = async () => {
    const { thoracicLimbs } = getValues();

    setMembrosToracicosStatus("loading");

    const payload = {
      exam: {
        thoracicLimbs,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setMembrosToracicosStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setMembrosToracicosStatus("erro");
    }
  };

  const onSubmitChest = async () => {
    const { chest } = getValues();

    setToraxStatus("loading");

    const payload = {
      exam: {
        chest,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setToraxStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setToraxStatus("erro");
    }
  };

  const onSubmitPelvicLimbs = async () => {
    const { pelvicLimbs } = getValues();

    setMembrosPelvicosStatus("loading");

    const payload = {
      exam: {
        pelvicLimbs,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setMembrosPelvicosStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setMembrosPelvicosStatus("erro");
    }
  };

  const onSubmitAbdomen = async () => {
    const { abdomen } = getValues();

    setAbdomeStatus("loading");

    const payload = {
      exam: {
        abdomen,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");
        setAbdomeStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setAbdomeStatus("erro");
    }
  };

  const onSubmitTemperatura = async () => {
    const { temperatura } = getValues();

    setTemperaturaStatus("loading");

    const payload = {
      exam: {
        temperature: temperatura,
      },
    };

    try {
      const result = await API.post(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
        payload
      );
      if (result?.status === 200 || result?.status === 201) {
        setAlert("sucesso");

        setTemperaturaStatus("success");
        getAnamnese();
      }
    } catch (err) {
      setErrMsg(err?.response?.data?.friendly_message);
      setAlert("erro");
      setTemperaturaStatus("erro");
    }
  };

  const getFileList = async () => {
    const fileList = await API.get(
      `service-order/appointment/${order.appointmentOrderId}/attachments`
    );
    setAttachments(fileList.data.attachments);
  };

  useEffect(() => {
    if (order) {
      getFileList();
    }
  }, [order]);

  const handleUploadFile = async () => {
    setLoadingImage(true);
    setBtnDisabled(true);
    const formData = new FormData();
    const fileData = document.querySelector('input[type="file"]');

    if (fileData.files && fileData.files.length > 0) {
      formData.append("file", fileData.files[0]);
      formData.append("file_name", fileData.files[0].name);

      await API.post(
        `service-order/appointment/${order.appointmentOrderId}/attachment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then((res) => {
        console.log("response from server: ", res);
      });
      setPreviewImage(false);
      setTimeout(() => {
        getFileList();
        setBtnDisabled(false);
        setLoadingImage(false);
      }, 1000);
    }
  };

  const removeFile = async (id) => {
    await API.delete(
      `service-order/appointment/${order?.appointmentOrderId}/attachment/${id}`
    );
    setTimeout(() => {
      getFileList();
    }, 1000);
  };

  const onSubmitVacina = async (data) => {
    if (data.vaccine) {
      setNewVaccine(true);
      setBtnDisabled(true);
      const payload = {
        date: data.date,
        lab: data.lab,
        batch: data.batch,
        vaccineId: data.vaccine,
        protocolId: data.protocolo,
      };

      try {
        await API.post(
          `service-order/appointment/${order?.appointmentOrderId}/add-vaccination`,
          payload
        );
      } catch (error) {
        setErrMsg(error?.response?.data?.friendlyMessage);
        setAlert("erro");
        setDescriptionStatus("erro");
      }

      findProtocols();

      setTimeout(() => {
        setBtnDisabled(false);
        setNewVaccine(false);
      }, 1000);

      getVaccinations();
    }
  };

  const handleChangeIndex = (event, value) => {
    getAnamnese();
    setCurrentTab(value);
    setNewPrescription(1);
  };

  const serviceDelete = async () => {
    try {
      const result = await API.delete(`service-order/${idDelete}`);

      if (result?.status === 201) {
        handleGetOrderUpdate();
        closeModal();
      }
    } catch (err) {
      console.log("Erro:", err);
    }
  };
  const vaccineDelete = async () => {
    try {
      const result = await API.post(
        `service-order/appointment/${order?.appointmentOrderId}/delete-vaccination`,
        {
          vaccinationId: idDelete,
        }
      );
      findProtocols();

      if (result?.status === 201) {
        getVaccinations();
        closeModal();
      }
    } catch (err) {
      console.log("Erro:", err);
    }
  };

  const handleWeight = async () => {
    const { peso } = getValues();
    setPesoStatus("loading");
    try {
      await API.put(
        `service-order/appointment/${order?.appointmentOrderId}/pet-weight`,
        {
          weight: peso,
        }
      );
      setPesoStatus("success");
    } catch (err) {
      console.log("Erro:", err);
      setPesoStatus("erro");
    }
  };

  useEffect(() => {
    setTotalServices(
      order?.services.reduce((a, b) => a + (b.totalPrice || 0), 0)
    );
  }, [order]);
  return (
    <div>
      <ModalType
        open={agendaModal || openModal}
        close={closeModal}
        title={titleModal}
        view={viewModal}
        content={<HistoryTable weightHis={weightHis} />}
        titleDescription={agendaModal || openModal}
        handleClick={() => {
          switch (agendaModal) {
            case "Você tem certeza que deseja remover essa vacina?":
              return vaccineDelete();
            case "Você tem certeza que deseja cancelar esse serviço?":
              return serviceDelete();
            default:
              break;
          }
        }}
        err={error}
        oneButton={false}
        verification={agendaModal}
      />

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <DrawerContent
          orderId={drawerOrderId}
          close={() => {
            setOpenDrawer(false);
          }}
        />
      </Drawer>

      <Snackbar
        open={alert === "erro"}
        autoHideDuration={3000}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity="error">{errMsg}</Alert>
      </Snackbar>

      <Snackbar
        open={alert === "sucesso"}
        autoHideDuration={3000}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity="success">Informações atualizadas com sucesso!</Alert>
      </Snackbar>

      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
      >
        {tabs.map(
          (tab) =>
            tab.show && (
              <Tab
                key={tab.label}
                style={{ minWidth: "125px" }}
                label={tab.label}
                value={tab.value}
              />
            )
        )}
      </Tabs>

      {(order?.status === "AGENDADO" || order?.status === "EM ANDAMENTO") && (
        <Grid
          item
          xs={12}
          style={{ top: "110px", right: "40px", position: "absolute" }}
        >
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: `https://wa.me/55${order?.customer.phoneNumber
                }?text=Olá ${order?.customer.name
                  .split(" ")
                  .slice(0, 1)
                  .join(
                    ""
                  )} segue o resumo do seu agendamento da clinica Dra mei, Pet: ${order?.pet?.name
                } Local: ${order?.storeName} Endereço: ${order?.storeAddress
                } Data: ${order?.date} Horário: ${order?.startAt}`,
            }}
            target="_blank"
          >
            <Button
              endIcon={<WhatsAppIcon />}
              type="submit"
              color="secondary"
              size="large"
              className={classes.buttonWhatsApp}
            >
              Enviar por WhatsApp
            </Button>
          </Link>
        </Grid>
      )}

      {currentTab === 0 && (
        <Grid item xs={12} container spacing={3} className={classes.filter}>
          <Grid item xs={12}>
            <h2>Anamnese</h2>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <TextField
                defaultValue={
                  anamnesis
                    ? decode(
                      anamnesis?.mainComplaint.replace(/(<([^>]+)>)/gi, "")
                    )
                    : ""
                }
                placeholder={anamnesis ? "" : "Carregando..."}
                disabled={
                  descriptionStatus === "loading" ||
                  order?.status === "AGENDADO"
                }
                name="description"
                error={descriptionStatus === "erro"}
                onBlur={() => {
                  onSubmitDescription();
                }}
                id="description"
                label=""
                multiline
                fullWidth
                inputRef={register}
                rows={10}
                className={classes.textField}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {descriptionStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {descriptionStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {descriptionStatus === "loading" && <>Salvando...</>}
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <h2>Exame Físico</h2>
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={3}>
            <Grid item xs={3}>
              <TextField
                defaultValue={anamnesis ? anamnesis?.petWeight : ""}
                placeholder={anamnesis ? "" : "Carregando..."}
                name="peso"
                disabled={pesoStatus === "loading"}
                onBlur={() => {
                  handleWeight();
                }}
                id="peso"
                label="PESO"
                error={pesoStatus === "erro"}
                color="primary"
                fullWidth
                inputRef={register}
                className={classes.textField}
                variant="outlined"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {pesoStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {pesoStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {pesoStatus === "loading" && <>Salvando...</>}
            </Grid>

            <Grid
              item
              xs={3}
              onClick={() => weightHistory()}
              style={{
                textAlign: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <span style={{ cursor: "pointer" }}>Ver Histórico de Peso</span>
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={3}>
            <Grid item xs={3}>
              <TextField
                defaultValue={anamnesis ? anamnesis?.exam?.heartRate : ""}
                placeholder={anamnesis ? "" : "Carregando..."}
                name="bpm"
                disabled={
                  fcStatus === "loading" || order?.status === "AGENDADO"
                }
                onBlur={() => {
                  onSubmitBpm();
                }}
                id="bpm"
                label="FC (bpm)"
                fullWidth
                error={fcStatus === "erro"}
                inputRef={register}
                className={classes.textField}
                variant="outlined"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {fcStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {fcStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {fcStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={
                  frStatus === "loading" || order?.status === "AGENDADO"
                }
                defaultValue={anamnesis?.exam?.respiratoryRate}
                placeholder={anamnesis ? " " : "Carregando..."}
                name="mpm"
                id="mpm"
                error={frStatus === "erro"}
                inputRef={register}
                onBlur={() => {
                  onSubmitMpm();
                }}
                label="FR (mpm)"
                variant="outlined"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {frStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {frStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {frStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled={
                  temperaturaStatus === "loading" ||
                  order?.status === "AGENDADO"
                }
                id="TEMPERATURA"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                error={temperaturaStatus === "erro"}
                name="temperatura"
                inputRef={register}
                label="TEMPERATURA"
                variant="outlined"
                rowsMax={1}
                onBlur={() => {
                  onSubmitTemperatura();
                }}
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.temperature : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {temperaturaStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {temperaturaStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {temperaturaStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                id="seg"
                name="seg"
                disabled={
                  tcpStatus === "loading" || order?.status === "AGENDADO"
                }
                inputRef={register}
                label="TPC (seg)"
                error={tcpStatus === "erro"}
                onBlur={() => {
                  onSubmitSeg();
                }}
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.TPC : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {tcpStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {tcpStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {tcpStatus === "loading" && <>Salvando...</>}
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="OUVIDOS"
                disabled={
                  ouvidosStatus === "loading" || order?.status === "AGENDADO"
                }
                name="ouvidos"
                error={ouvidosStatus === "erro"}
                onBlur={() => {
                  onSubmitOuvidos();
                }}
                inputRef={register}
                label="OUVIDOS"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.earsCondition : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {ouvidosStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {ouvidosStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {ouvidosStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  mucosasStatus === "loading" || order?.status === "AGENDADO"
                }
                id="MUCOSAS"
                name="mucosas"
                error={mucosasStatus === "erro"}
                onBlur={() => {
                  onSubmitMucosas();
                }}
                inputRef={register}
                label="MUCOSAS"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.mucosa : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {mucosasStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {mucosasStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {mucosasStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  hidratacaoStatus === "loading" || order?.status === "AGENDADO"
                }
                id="HIDRATAÇÃO"
                name="hidratacao"
                onBlur={() => {
                  onSubmitHidratacao();
                }}
                error={hidratacaoStatus === "erro"}
                inputRef={register}
                label="HIDRATAÇÃO"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.hydration : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {hidratacaoStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {hidratacaoStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {hidratacaoStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  linfonodosStatus === "loading" || order?.status === "AGENDADO"
                }
                id="lymphNode"
                name="lymphNode"
                onBlur={() => {
                  onSubmitLymphNode();
                }}
                erro={linfonodosStatus === "erro"}
                inputRef={register}
                label="LINFONODOS  "
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.lymphNode : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {linfonodosStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {linfonodosStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {linfonodosStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  pelePeloStatus === "loading" || order?.status === "AGENDADO"
                }
                id="skinFur"
                name="skinFur"
                error={pelePeloStatus === "erro"}
                onBlur={() => {
                  onSubmitSkinFur();
                }}
                inputRef={register}
                label="PELE/PELO"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.skinFur : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {pelePeloStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {pelePeloStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {pelePeloStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  membrosToracicosStatus === "loading" ||
                  order?.status === "AGENDADO"
                }
                id="thoracicLimbs"
                name="thoracicLimbs"
                error={membrosToracicosStatus === "erro"}
                onBlur={() => {
                  onSubmitThoracicLimbs();
                }}
                inputRef={register}
                label="MEMBROS TORÁCICOS"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.thoracicLimbs : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {membrosToracicosStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {membrosToracicosStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {membrosToracicosStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  toraxStatus === "loading" || order?.status === "AGENDADO"
                }
                id="chest"
                error={toraxStatus === "erro"}
                name="chest"
                onBlur={() => {
                  onSubmitChest();
                }}
                inputRef={register}
                label="TÓRAX"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.chest : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {toraxStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {toraxStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {toraxStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  membrosPelvicosStatus === "loading" ||
                  order?.status === "AGENDADO"
                }
                id="pelvicLimbs"
                error={membrosPelvicosStatus === "erro"}
                name="pelvicLimbs"
                onBlur={() => {
                  onSubmitPelvicLimbs();
                }}
                inputRef={register}
                label="MEMBROS PÉLVICOS"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.pelvicLimbs : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {membrosPelvicosStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {membrosPelvicosStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {membrosPelvicosStatus === "loading" && <>Salvando...</>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={
                  abdomeStatus === "loading" || order?.status === "AGENDADO"
                }
                id="abdomen"
                name="abdomen"
                error={abdomeStatus === "erro"}
                onBlur={() => {
                  onSubmitAbdomen();
                }}
                inputRef={register}
                label="ABDOME"
                variant="outlined"
                placeholder={anamnesis ? " " : "Carregando..."}
                defaultValue={anamnesis ? anamnesis.exam.abdomen : ""}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {abdomeStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {abdomeStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {abdomeStatus === "loading" && <>Salvando...</>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h2>Diagnóstico e Tratamento</h2>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <TextField
                disabled={
                  diagnosticoStatus === "loading" ||
                  order?.status === "AGENDADO"
                }
                id="Diagnóstico"
                error={diagnosticoStatus === "erro"}
                name="diagnostico"
                label=""
                multiline
                fullWidth
                rows={10}
                inputRef={register}
                onBlur={() => {
                  onSubmitDiagnostico();
                }}
                className={classes.textField}
                placeholder={anamnesis ? " " : "Carregando..."}
                variant="outlined"
                size="small"
                defaultValue={
                  anamnesis
                    ? decode(
                      anamnesis?.diagnosisAndTreatment.replace(
                        /(<([^>]+)>)/gi,
                        ""
                      )
                    )
                    : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {diagnosticoStatus === "erro" && (
                        <Warning style={{ color: "#f44336", fontSize: 20 }} />
                      )}
                      {diagnosticoStatus === "success" && (
                        <Check style={{ color: "#66bb6a", fontSize: 20 }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {diagnosticoStatus === "loading" && <>Salvando...</>}
            </Grid>
          </Grid>
        </Grid>
      )}

      {currentTab === 1 && (
        <Grid item xs={12}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={3}>
              <h3>Data</h3>
            </Grid>
            <Grid item xs={2}>
              <h3>Tipo</h3>
            </Grid>
            <Grid item xs={1}>
              <h3>Lote</h3>
            </Grid>
            <Grid item xs={2} container alignItems="center" justify="center">
              <h3>Laboratório</h3>
            </Grid>
            <Grid item xs={2} container alignItems="center" justify="center">
              <h3>Protocolo</h3>
            </Grid>
          </Grid>
          {vaccinations?.map(
            ({
              vaccinationDate,
              id,
              vaccineName,
              laboratoryName,
              batch,
              protocolo,
            }) => (
              <Grid container spacing={3} key={id} xs={12}>
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid item xs={3}>
                  <p>
                    {!!vaccinationDate &&
                      moment
                        .utc(vaccinationDate.toLocaleString())
                        .format("DD/MM/YYYY")}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <p>{vaccineName}</p>
                </Grid>
                <Grid item xs={1}>
                  <p>{batch}</p>
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                >
                  {laboratoryName}
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                >
                  {protocolo}
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <IconButton
                    style={{ color: "red" }}
                    aria-label="delete"
                    onClick={() => {
                      handleDeleteVaccine(id);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </Grid>
              </Grid>
            )
          )}
          <form onSubmit={handleSubmit(onSubmitVacina)}>
            <Grid container spacing={3} xs={12}>
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={moment().format("YYYY-MM-DD")}
                  as={
                    <TextField
                      fullWidth
                      id="date"
                      name="date"
                      type="date"
                      inputRef={register}
                      variant="outlined"
                    />
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  control={control}
                  name="vaccine"
                  as={
                    <TextField fullWidth select label="TIPO" variant="outlined">
                      {vaccines.map(({ id, name }) => (
                        <MenuItem
                          key={id}
                          value={id}
                          onClick={() => {
                            setVaccineType(id);
                          }}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  fullWidth
                  id="batch"
                  name="batch"
                  inputRef={register}
                  variant="outlined"
                  placeholder=""
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  control={control}
                  name="lab"
                  as={
                    <TextField
                      fullWidth
                      select
                      label="LABORATÓRIO"
                      variant="outlined"
                      disabled={!labs.length}
                    >
                      {labs?.map((lab) => (
                        <MenuItem key={lab} value={lab}>
                          {lab}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  control={control}
                  name="protocolo"
                  as={
                    <TextField
                      fullWidth
                      select
                      label="Protocolo"
                      variant="outlined"
                      disabled={!protocolArray.length}
                    >
                      {protocolArray?.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                />
              </Grid>
              <Grid item xs={2} container alignItems="center" justify="center">
                {!newVaccine ? (
                  <CustomButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    disableElevation
                    size="large"
                    disabled={btnDisabled}
                  >
                    Adicionar
                  </CustomButton>
                ) : (
                  <CircularProgress disableShrink />
                )}
              </Grid>
            </Grid>
          </form>
          <Protocols />
        </Grid>
      )}

      {currentTab === 2 && (
        <Grid item xs={12} container spacing={3} className={classes.filter}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(handleUploadFile)}>
              <Grid item xs={12}>
                <label
                  style={{
                    border: "2px dashed #ccc",
                    padding: "16px",
                    width: "100%",
                    borderRadius: "5px",
                    flex: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#f5f5f5",
                  }}
                >
                  {previewImage ? (
                    <div style={{ textAlign: "center" }}>
                      <InsertDriveFileIcon style={{ fontSize: "60px" }} />{" "}
                      <Typography variant="subtitle2" component="h2">
                        Arquivo inserido com sucesso, clique no botão ENVIAR
                        para salvar
                      </Typography>
                    </div>
                  ) : (
                    <span style={{ fontSize: "28px", color: "#ccc" }}>
                      Clique para enviar arquivos
                    </span>
                  )}
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="file"
                    name="file"
                    multiple
                    onChange={onChangeImage}
                    ref={fileInput}
                  />
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justify="center"
                className={classes.filter}
              >
                {!loadingImage ? (
                  <CustomButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={btnDisabled}
                    disableElevation
                    size="large"
                  >
                    Enviar
                  </CustomButton>
                ) : (
                  <CircularProgress disableShrink />
                )}
              </Grid>
            </form>
          </Grid>
          {attachments && attachments.length >= 1 && (
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="h5"
                component="h4"
                className={classes.title}
              >
                Arquivos
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
            {attachments &&
              attachments.map(({ fileName, fileUrl, id }) => (
                <>
                  {fileUrl.split(".").pop() === "pdf" && (
                    <button
                      style={{
                        position: "relative",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        marginRight: "30px",
                        width: "145px",
                        fontsize: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          fontSize: "16px",
                          color: "#7e7d7d",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          removeFile(id);
                        }}
                      >
                        x
                      </span>

                      <PictureAsPdfIcon
                        style={{ fontSize: "100px" }}
                        onClick={() => {
                          window.open(fileUrl);
                        }}
                      />
                      <p style={{ wordBreak: "break-all" }}>{fileName}</p>
                    </button>
                  )}
                </>
              ))}
          </Grid>

          {attachments && attachments.length >= 1 && (
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="h5"
                component="h4"
                className={classes.title}
              >
                Fotos
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
            {attachments &&
              attachments.map(({ fileName, fileUrl, id }) => (
                <>
                  {fileUrl.split(".").pop() !== "pdf" && (
                    <button
                      style={{
                        position: "relative",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        marginRight: "30px",
                        width: "145px",
                        fontsize: "10px",
                        lineHeight: "12px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          fontSize: "16px",
                          color: "#7e7d7d",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          removeFile(id);
                        }}
                      >
                        x
                      </span>

                      <img
                        src={fileUrl}
                        alt=""
                        style={{
                          maxWidth: "110px",
                          height: "100px",
                          margin: "16px",
                          marginBottom: "0",
                        }}
                        onClick={() => {
                          window.open(fileUrl);
                        }}
                      />
                      <p style={{ wordBreak: "break-all" }}>{fileName}</p>
                    </button>
                  )}
                </>
              ))}
          </Grid>
        </Grid>
      )}

      {currentTab === 3 && (
        <Grid container spacing={3} xs={12}>
          <div className={classes.history}>
            {historyList === false && (
              <>
                <Skeleton style={{ width: "150px" }} />
                <Skeleton
                  style={{
                    width: "720px",
                    height: "140px",
                    marginLeft: "50px",
                  }}
                />
              </>
            )}

            {!!historyList.length &&
              historyList.map(({ date, procedures }) => (
                <div className={classes.historyItem} key={date}>
                  <h2 className={classes.historyItemTitle}>
                    {moment(date).format("DD/MM/YYYY")}
                  </h2>
                  {procedures.map(
                    ({
                      procedureName,
                      procedureIcon,
                      vetName,
                      anamnesis,
                      vaccinations,
                      attachments,
                      appointmentId,
                    }) => (
                      <div
                        className={`globalHistoryItemService ${classes.historyItemService}`}
                        key={procedureName}
                      >
                        <div className={classes.historyItemServiceType}>
                          <p>{procedureName}</p>
                          <img src={procedureIcon} alt={procedureName} />
                        </div>
                        <div className={classes.historyItemServicInformation}>
                          <div
                            className={
                              classes.historyItemServicInformationOwner
                            }
                          >
                            <span>Atendido por</span>
                            <p>{vetName}</p>
                          </div>
                          <div
                            className={classes.historyItemServicInformationList}
                          >
                            {anamnesis && (
                              <span style={{ background: "#2F57BE" }}>
                                <Tooltip title="Anamnese" arrow>
                                  <AssignmentIcon />
                                </Tooltip>
                              </span>
                            )}
                            {vaccinations && (
                              <span style={{ background: "#6E2FBE" }}>
                                <Tooltip title="Vacina" arrow>
                                  <ColorizeIcon />
                                </Tooltip>
                              </span>
                            )}
                            {attachments && (
                              <span style={{ background: "#12A79E" }}>
                                <Tooltip title="Anexo" arrow>
                                  <AttachFileIcon />
                                </Tooltip>
                              </span>
                            )}
                          </div>
                          <div
                            className={
                              classes.historyItemServiceInformationButton
                            }
                          >
                            <CustomButton
                              variant="contained"
                              type="submit"
                              color="primary"
                              disableElevation
                              onClick={() => {
                                setOpenDrawer(true);
                                setDrawerOrderId(appointmentId);
                              }}
                            >
                              Ver detalhes
                            </CustomButton>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ))}
          </div>
        </Grid>
      )}

      {currentTab === 4 && (
        <>
          <div>
            <Grid item xs={12}>
              <TableContainer>
                <Table size="medium" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Serviço</TableCell>
                      <TableCell align="center" style={{ width: 150 }}>
                        Valor
                      </TableCell>
                      <TableCell align="center">Profissional</TableCell>
                      <TableCell align="center">Plano de Saúde</TableCell>
                      <TableCell align="center" />
                    </TableRow>
                  </TableHead>
                  {order?.services && !loadingService ? (
                    <TableBody>
                      {order?.services.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            <div
                              style={{
                                justifyContent: "left",
                                alignItems: "center",
                              }}
                            >
                              {row.serviceName}
                              {row?.hasPackage && (
                                <span className={classes.assinante}>
                                  PACOTE
                                </span>
                              )}
                            </div>
                          </TableCell>

                          <TablePrice
                            row={row}
                            setOpenModal={setOpenModal}
                            setTitleModal={setTitleModal}
                            setError={setError}
                            order={order}
                          />

                          <TableCell component="th" scope="row" align="center">
                            {row.employeeName}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <Checkbox
                              defaultChecked={row?.healthPlan === "true"}
                              onClick={() => {
                                handleHealthPlan(
                                  row?.serviceOrderId,
                                  row?.healthPlan
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <IconButton
                              style={{ color: "red" }}
                              aria-label="delete"
                              onClick={() => {
                                handleDelete(row?.id);
                              }}
                            >
                              <DeleteForever />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell align="center" />
                        <TableCell align="center" />
                        <TableCell align="center" />
                        <TableCell align="center">Total</TableCell>
                        <TableCell align="center">
                          {`R$ ${String(totalServices.toFixed(2)).replace(
                            ".",
                            ","
                          )}`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </div>
          <Grid item xs={12} container spacing={3} className={classes.filter}>
            <div className={classes.espaco}>
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  startIcon={<AddCircleIcon />}
                  onClick={() => setShowAddServices(true)}
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Adicionar mais serviços
                </Button>
              </Grid>
            </div>

            {showAddServices && (
              <Grid item xs={12}>
                <Autocomplete
                  options={services}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => (
                    <>
                      {option.internalCode} -{option.name} R$
                      {option.price}
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Serviços adicionais"
                      placeholder="Procurar serviços adicionais"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, value) => {
                    setSelectedServices(value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}

      {currentTab === 5 && (
        <>
          {newPrescription === 1 ? (
            <>
              {summary.length > 0 && (
                <SummaryPrescription
                  summary={summary}
                  appointmentId={order.appointmentOrderId}
                />
              )}
              <Prescription appointmentId={order.appointmentOrderId} />
            </>
          ) : (
            <Prescription appointmentId={order.appointmentOrderId} />
          )}
        </>
      )}
    </div>
  );
}

export default Main;
