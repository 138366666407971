import {
  CustomerController,
  ScheduleController,
} from "../../../../../../../services/controllers/scheduling";
import { useSchedule } from "../../../../../../../store/schedule/useSchedule";

import { Steps } from "../../../../../../ui/Steps";
import { PetCard } from "../../../../../../ui/PetCard";

import { Container } from "./styles";

export function Pets() {
  const CLScheduling = ScheduleController();
  const CLCustomer = CustomerController();

  const setActive = Steps.store((st) => st.actions.setActive);
  const pets = useSchedule((st) => st.data.customer.pets);

  const selectPet = async (petId) => {
    await CLCustomer.selectPet(petId);
    setActive(CLScheduling.stepsName, 1);
  };

  return (
    <Container>
      {pets.map((pet) => (
        <PetCard.Root
          key={pet.id}
          size="small"
          onClick={() => {
            selectPet(pet.id);
          }}
        >
          <PetCard.Badges show={pet.badges} />
          <PetCard.Avatar url={pet.petPhoto} attributes={pet.attributes} />
          <PetCard.Attributes
            attributes={pet.attributes}
            show={["Nome", "Tutor", "Raça"]}
          />
        </PetCard.Root>
      ))}
    </Container>
  );
}
