import styled from 'styled-components'

export const Container = styled.span`
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  color: #fdfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  weight: 100%;
  padding: 5px;
  cursor: pointer;
  &.whatsapp {
    background: #1bd741;
    &:hover {
      background: #30c04d;
    }
  }
  &.other-channels {
    background: #0090b8;
    &:hover {
      background: #1385a4;
    }
  }

  &.block-contact {
    background: #f90303;
    &:hover {
      background: #de1b1b;
    }
  }
`
