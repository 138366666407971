import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import endOfWeek from 'date-fns/endOfWeek'
import isSameDay from 'date-fns/isSameDay'
import isWithinInterval from 'date-fns/isWithinInterval'
import startOfWeek from 'date-fns/startOfWeek'
import moment from 'moment'
import ptBR from 'date-fns/locale/pt-BR'

// MATERIAL MUI
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import Alert from '@mui/material/Alert'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import MenuItem from '@mui/material/MenuItem'
import { MockAvailablesTimes } from '../../atoms/AvailableTimes/mock'
import API from '../../../services/api'

// Componentes
import DrawerType from '../../atoms/Drawer/index'

moment.locale('pt-BR')

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}))

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
}

const Root = styled('div')(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }

    td:hover span {
        display: flex;
        cursor: pointer
    }

    td,
    th {
      border: 1px solid ${
        theme.palette.mode === 'dark' ? grey[800] : grey[200]
      };
      text-align: left;
      padding: 6px;
    }

    th {
      background-color: ${
        theme.palette.mode === 'dark' ? grey[900] : grey[100]
      };
    }
    `,
)

const Span = styled('span')(
  () => `
    justify-content: center;
    align-items: center;
    display: none;
    `,
)

export function TableInformation(props) {
  const [hourNext, setHourNext] = React.useState()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      shifts: [{ startsAt: hourNext?.toString(), stopsAt: '' }],
    },
  })

  const isHourGreaterThan = (hour1, hour2) => {
    const h1 = hour1.split(':')[0]
    const m1 = hour1.split(':')[1]
    hour1 = parseInt(h1, 10) * 60 + parseInt(m1, 10)
    const h2 = hour2.split(':')[0]
    const m2 = hour2.split(':')[1]
    hour2 = parseInt(h2, 10) * 60 + parseInt(m2, 10)
    return hour1 < hour2
  }

  const { selectRoom, idSelectRoom, getRooms, setDrowerOpen } = props
  const [addShift, setAddShift] = React.useState(false)
  const [newArray, setNewArray] = React.useState(selectRoom?.shifts)
  const [verific, setVerific] = React.useState('')
  const [mgs, setMsg] = React.useState(false)

  const availableTimes = MockAvailablesTimes?.availableTimes

  const ultimoHorario =
    selectRoom?.shifts && selectRoom?.shifts[0]?.stopsAt === null
      ? '18:00'
      : selectRoom?.shifts && selectRoom?.shifts[0]?.stopsAt?.toString()
  const newDate = new Date(`2022-01-01T${ultimoHorario}`)
  const mutation = new Date(newDate.getTime())
  mutation.setHours(mutation.getHours() + 1)

  function addZero(i) {
    if (i < 10) {
      i = `0${i}`
    }
    return i
  }

  const handleDelete = () => {
    const fill = selectRoom?.shifts?.filter((item, index) => index !== 1)
    setNewArray(fill)
    setAddShift(false)
  }

  function functionTransform() {
    const d = mutation
    const h = addZero(d.getHours())
    const m = addZero(d.getMinutes())
    // let s = addZero(d.getSeconds()); SEGUNDOS SE PRECISAR
    return setHourNext(`${h}:${m}`)
  }

  React.useEffect(() => {
    functionTransform()
  }, [])

  const handleOnSubmit = async (event) => {
    try {
      const result = await API.put(`room/${idSelectRoom}/update-hour`, {
        date: selectRoom?.day,
        shifts: event,
      })

      if (result?.status === 201 || result?.status === 201) {
        getRooms()
        setDrowerOpen(false)
      }
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const onSubmit = async (event) => {
    if (event?.shifts?.length > 1) {
      const huours = isHourGreaterThan(
        event?.shifts[0]?.startsAt,
        event?.shifts[0]?.stopsAt,
      )
      const huours2 = isHourGreaterThan(
        event?.shifts[1]?.startsAt,
        event?.shifts[1]?.stopsAt,
      )

      if (huours && huours2) {
        const hr = isHourGreaterThan(
          event?.shifts[0]?.stopsAt,
          event?.shifts[1]?.startsAt,
        )

        if (hr) {
          setVerific(true)
          handleOnSubmit(event?.shifts)
        } else {
          setMsg('Os turnos estão sobrepostos')
          setVerific(false)
        }
      } else {
        setMsg('O horário de início deve ser anterior ao horário de término')
        setVerific(false)
      }
    } else {
      const hours = isHourGreaterThan(
        event?.shifts[0]?.startsAt,
        event?.shifts[0]?.stopsAt,
      )

      if (hours) {
        setVerific(hours)
        handleOnSubmit(event?.shifts)
      } else {
        setMsg('O horário de início deve ser anterior ao horário de término')
        setVerific(false)
      }
    }
  }

  const handleEx = async () => {
    try {
      const result = await API.delete(
        `room/${idSelectRoom}/delete-date-hours`,
        {
          data: { date: selectRoom?.day?.toString() },
        },
      )

      if (result?.status === 201 || result?.status === 201) {
        getRooms()
        setDrowerOpen(false)
      }
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  return (
    <Box sx={{ width: 500 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} style={{ padding: '20px' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Editar Horário
            </Typography>
            <Divider variant="fullWidth" />
          </Grid>
          {verific === false && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Alert severity="error">{mgs}</Alert>
            </Grid>
          )}

          {newArray?.map((item, index) => (
            <>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  defaultValue={
                    item?.startsAt === null
                      ? '08:00'
                      : item?.startsAt?.toString()
                  }
                  name={`shifts.${index}.startsAt`}
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      select
                      value={value}
                      // disabled={!customer}
                      label="HORA DE INÍCIO"
                      variant="outlined"
                      defaultChecked={
                        item?.startsAt === null
                          ? '08:00'
                          : item?.startsAt?.toString()
                      }
                      defaultValue={
                        item?.startsAt === null
                          ? '08:00'
                          : item?.startsAt?.toString()
                      }
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                    >
                      {availableTimes?.map((item) => (
                        <MenuItem key={item.time} value={item.time}>
                          {item.time}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={index === 1 ? 5 : 6}>
                <Controller
                  control={control}
                  defaultValue={
                    item?.stopsAt === null ? '18:00' : item?.stopsAt?.toString()
                  }
                  name={`shifts.${index}.stopsAt`}
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      select
                      value={value}
                      label="HORA DE FIM"
                      variant="outlined"
                      defaultValue={
                        item?.stopsAt === null
                          ? '18:00'
                          : item?.stopsAt?.toString()
                      }
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                    >
                      {availableTimes?.map((item) => (
                        <MenuItem key={item.time} value={item.time}>
                          {item.time}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              {index === 1 && (
                <Grid
                  item
                  xs={1}
                  style={{
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Button
                    color="secondary"
                    onClick={() => handleDelete()}
                    style={{ fontSize: '12px', fontWeight: 'bold' }}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Grid>
              )}
            </>
          ))}

          {newArray?.length < 2 && hourNext && addShift && (
            <>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  defaultValue={hourNext}
                  name={`shifts.${1}.startsAt`}
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      select
                      value={value}
                      // disabled={!customer}
                      label="HORA DE INÍCIO"
                      variant="outlined"
                      defaultChecked={hourNext}
                      defaultValue={hourNext}
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                    >
                      {availableTimes?.map((item) => (
                        <MenuItem key={item.time} value={item.time}>
                          {item.time}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={`shifts.${1}.stopsAt`}
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      select
                      value={value}
                      label="HORA DE FIM"
                      variant="outlined"
                      required
                      onChange={(e) => {
                        onChange(e.target.value)
                      }}
                    >
                      {availableTimes?.map((item) => (
                        <MenuItem key={item.time} value={item.time}>
                          {item.time}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid
                item
                xs={1}
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Button
                  color="secondary"
                  onClick={() => handleDelete()}
                  style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        {!addShift && newArray?.length < 2 && (
          <Grid container spacing={2} style={{ padding: '20px' }}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                color="secondary"
                onClick={() => setAddShift(true)}
                style={{ fontSize: '12px', fontWeight: 'bold' }}
              >
                Adicionar outro turno
              </Button>
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{
                fontSize: '14px',
                color: '#f50057',
                border: '1px solid #f50057',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
              onClick={() => handleEx()}
            >
              Deletar
            </Button>
          </Grid>

          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

function TableEmployee() {
  // SELECT DATA
  const [value, setValue] = React.useState(new Date())

  const [diaInicio, setDiaInicio] = React.useState(null)
  const [diaFim, setDiaFim] = React.useState(null)
  const [rooms, setRooms] = React.useState([])
  const [drowerOpen, setDrowerOpen] = React.useState(false)
  const [selectRoom, setSelectRoom] = React.useState(false)
  const [idSelectRoom, setIdSelectRoom] = React.useState(false)

  const getRooms = async () => {
    try {
      const { data } = await API.get(
        `rooms?from=${moment(diaInicio).format('YYYY-MM-DD')}&to=${moment(
          diaFim,
        ).format('YYYY-MM-DD')}`,
      )
      setRooms(data)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />
    }

    const start = startOfWeek(value)
    const end = endOfWeek(value)

    setDiaInicio(start?.toString())
    setDiaFim(end?.toString())

    const dayIsBetween = isWithinInterval(date, { start, end })
    const isFirstDay = isSameDay(date, start)
    const isLastDay = isSameDay(date, end)

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  React.useEffect(() => {
    renderWeekPickerDay()
  }, [])

  React.useEffect(() => {
    if (diaFim) getRooms()
  }, [diaFim])

  function clickDrawer() {
    setDrowerOpen(!drowerOpen)
  }

  const [dateOpen, setDateOpen] = React.useState(false)

  return (
    <Grid container spacing={2} style={{ padding: '10px', width: '1100px' }}>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <DatePicker
            open={dateOpen}
            onClose={() => setDateOpen(false)}
            displayStaticWrapperAs="desktop"
            value={value}
            disableToolbar
            onChange={(newValue) => {
              setValue(newValue)
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            placeholder="ex: DD/MM/AAAA"
            renderDay={renderWeekPickerDay}
            renderInput={(params) => (
              <TextField {...params} onClick={() => setDateOpen(true)} />
            )}
            inputFormat={`${new Date(diaInicio)?.getDate()} - ${new Date(
              diaFim,
            )?.getDate()} MMM,  yyyy`}
          />
        </LocalizationProvider>
      </Grid>
      <DrawerType
        open={drowerOpen}
        setDrowerOpen={setDrowerOpen}
        setSelectRoom={setSelectRoom}
        setIdSelectRoom={setIdSelectRoom}
        content={
          <TableInformation
            selectRoom={selectRoom}
            idSelectRoom={idSelectRoom}
            getRooms={getRooms}
            setDrowerOpen={setDrowerOpen}
          />
        }
      />
      <Grid item xs={12}>
        <Root sx={{ maxWidth: '100%' }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th style={{ width: 260 }}>Sala</th>
                {rooms?.defaultHours?.map((row) => (
                  <th key={row.day} style={{ textAlign: 'center' }}>
                    {row?.day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rooms?.result?.map(
                (row) =>
                  row?.active && (
                    <tr key={row.name}>
                      <td>{row?.name}</td>
                      {row?.hoursByDay?.map((rows) => (
                        <td
                          key={rows._id}
                          style={{ width: 135 }}
                          align="center"
                        >
                          {rows?.shifts?.map((rowss) =>
                            rowss.startsAt ? (
                              <div
                                key={rowss.id}
                                style={{
                                  cursor: 'pointer',
                                  backgroundColor: '#ccd219',
                                  margin: '5px',
                                  paddingLeft: '10px',
                                  color: '#fff',
                                }}
                                onClick={() => {
                                  setSelectRoom(rows)
                                  setIdSelectRoom(row?._id)
                                  clickDrawer()
                                }}
                              >
                                {rowss.startsAt} -{rowss.stopsAt}
                              </div>
                            ) : (
                              <Span
                                key={row._id}
                                onClick={() => {
                                  setSelectRoom(rows)
                                  setIdSelectRoom(row?._id)
                                  clickDrawer()
                                }}
                              >
                                <AddIcon />
                              </Span>
                            ),
                          )}
                        </td>
                      ))}
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        </Root>
      </Grid>
    </Grid>
  )
}

export default TableEmployee
