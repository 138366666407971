import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const termEndoscopyAuthorization = {
  name: "Termo de Autorização para Endoscopia",
  title: "Termo de Consentimento Livre e Esclarecido para Endoscopia",
  gender: "male",
  fields: {
    doctorName: {
      label: "Médico(a)/Veterinário(a)",
      type: "text",
      required: true,
      defaultPreview: _.range(20).fill("_").join(""),
    },
    doctorCRV: {
      label: "CRMV",
      type: "text",
      required: true,
      defaultPreview: _.range(5).fill("_").join(""),
    },
    procedure: {
      label: "Procedimento",
      type: "text",
      required: true,
      defaultPreview: _.range(20).fill("_").join(""),
    },
    procedureCost: {
      label: "Valor",
      type: "text",
      required: true,
      defaultPreview: "0,00",
      format: "money",
      props: {
        money: true,
      },
    },
    note: {
      label: "Observações do(a) Médico(a)-Veterinário(a)",
      type: "textarea",
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
  },
  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },
  content: `
    <p>Eu, acima identificado, na condição de responsável legal pelo paciente {{petName}}, estando no pleno gozo de
    minhas faculdades mentais, <strong>autorizo</strong> o Dr. <span>{{doctorName}}</span>, CRMV-{{doctorCRV}}, a realizar o procedimento de <span>{{procedure}}</span>.</p>
    <p>Estou ciente de que em procedimentos médicos mínimo invasivos, como o citado, podem ocorrer complicações, mas estas
    são raras. Pode ocorrer sangramento após uma biópsia ou remoção de um pólipo. Este é mínimo e raramente há
    necessidade de internação, transfusões sanguíneas ou cirurgia. Complicações maiores como perfurações são raras, mas
    podem acontecer, mesmo que o procedimento tenha sido realizado sob os mais rigorosos padrões técnicos;</p>

    <p>Estou ciente de que para realizar o procedimento acima especificado será necessário preparo prévio, assim como o emprego
    de anestesia geral, que será realizada através da recomendação do profissional pela clínica solicitante.</p>
    <p>O procedimento de endoscopia tem caráter de ser diagnostica e/ou terapêutica, portanto em casos de corpo estranho há
    possibilidades de não retirada, assim o paciente encaminhado para procedimento cirúrgico.</p>

    <p>Declaro ainda que estou ciente do custo do procedimento <span>{{procedureCost}}</span> e me comprometo a arcar com os encargos
    financeiros deste;</p>

    <p>Recebi todas as explicações necessárias quanto aos riscos, benefícios, alternativas de tratamento, bem como fui
    informado(a) sobre os riscos e/ou benefícios de não ser tomada nenhuma atitude terapêutica diante da natureza da(s)
    enfermidade(s) diagnosticada(s);</p>

    <p>Tive a oportunidade de esclarecer todas as minhas dúvidas relativas ao(s) procedimento(s), li e compreendi todas as
    informações deste documento, antes de sua assinatura; Certifico que este termo me foi explicado e que o li, que entendi o
    seu conteúdo, AUTORIZANDO a realização do procedimento.</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}

    {{#note}}
      <br />
      <br />
      <p><strong>Observações:</strong></p>
      <p>{{{.}}}</p>
    {{/note}}
  `.trim(),
};
