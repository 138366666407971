import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  isWaiting: false,
  reFetch: false,
  vaccinations: false,
  filteredVaccinations: false,
  pagedVaccinations: false,
  filter: false,
  pagination: {
    page: 0,
    totalPages: 0,
  },
  filterProps: {
    vaccines: false,
    protocols: false,
  },
};

export const useVaccinations = create((set) => ({
  data: defaultData,
  actions: {
    setVaccinationsFilter: (filter = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!filter) return;

          const currentFilter = state.data.filter || {};
          draft.data.filter = { ...currentFilter, ...filter };
        })
      ),
    setVaccinationsFilterProps: (props = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!props) return;

          Object.keys(props).forEach((propKey) => {
            draft.data.filterProps[propKey] = props[propKey];
          });
        })
      ),

    setVaccinations: (vaccinations = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.vaccinations = vaccinations;
        })
      ),

    setFilteredVaccinations: (vaccinations = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.filteredVaccinations = vaccinations;
        })
      ),
    setPagedVaccinations: (vaccinations = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.pagedVaccinations = vaccinations;
        })
      ),

    setLoadingStatus: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isLoading = status;
          draft.data.isWaiting = status;
        })
      ),

    setWaitingStatus: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isWaiting) return;
          draft.data.isWaiting = status;
        })
      ),

    setPagination: (pagination = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!pagination) return;

          Object.keys(pagination).forEach((paginationKey) => {
            draft.data.pagination[paginationKey] = pagination[paginationKey];
          });
        })
      ),

    setReFetchStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.reFetch = status;
        })
      ),
  },
}));
