import { VeterinaryVaccineController } from "../../../../../services/controllers/VeterinaryCare";

import { Drawer } from "../../../../../components/ui/Drawer";

import { useVaccine } from "../../../../../store/service/veterinary/useVaccine";

import { NewVaccineForm } from "./NewVaccineForm";
import { NewProtocolForm } from "./NewProtocolForm";

import { IncompleteProtocol } from "../Alerts/IncompleteProtocol";

import { Container } from "./styles";

export function NewVaccinationProtocolDrawer() {
  const CLVetVaccine = VeterinaryVaccineController();
  const showNewProtocol = useVaccine((st) => st.data.newProtocol);

  return (
    <>
      <Drawer.Root
        name={CLVetVaccine._drawerNewVaccinationProtocolName}
        style={{ width: "90vw" }}
        direction="left"
      >
        <Drawer.Controls
          controls={{ close: CLVetVaccine.closeNewVaccinationProtocolDrawer }}
        />
        <Drawer.Header>Novo Protocolo de Vacinação</Drawer.Header>
        <Drawer.Content>
          <Container showNewProtocol={showNewProtocol}>
            <NewVaccineForm />
            <NewProtocolForm />
          </Container>
        </Drawer.Content>
        <Drawer.Footer />
        <IncompleteProtocol />
      </Drawer.Root>
    </>
  );
}
