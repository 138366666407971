import { InputMask } from '../styles'

export function InputTypeMask({
  mask = '',
  onChange: onChangeTrigger = () => null,
  ...rest
}) {
  return (
    <InputMask
      {...rest}
      mask={mask}
      onChange={(e) => onChangeTrigger(e.target.value)}
      placeholder=" "
    />
  )
}
