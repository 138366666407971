import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Divider,
} from '@mui/material'
import React from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BathtubIcon from '@mui/icons-material/Bathtub'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ContentCutIcon from '@mui/icons-material/ContentCut'

import { Container } from './style'

function ModuleMetrics({ itens }) {
  const { name, primary, secondary, variation } = itens[0]
  return (
    <Container
      xs={3}
      item
      color={
        parseInt(variation?.value?.replace(/\s/g, ''), 10) > 0
          ? '#038C5A'
          : '#E10050'
      }
    >
      <Card sx={{ maxWidth: 345 }}>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          align="center"
          style={{
            textTransform: 'capitalize',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19px',
            color: '#666666',
            padding: '10px',
          }}
        >
          {name}
        </Typography>
        <Divider variant="middle" />
        <CardHeader
          avatar={
            (name === 'faturamento' && (
              <Avatar
                aria-label="recipe"
                style={{ background: '#168BF2', fontSize: '40px' }}
              >
                <AttachMoneyIcon />
              </Avatar>
            )) ||
            (name === 'agendamentos-hoje' && (
              <Avatar
                aria-label="recipe"
                style={{ background: '#038C5A', fontSize: '28px' }}
              >
                <BathtubIcon />
              </Avatar>
            )) ||
            (name === 'agendamentos-futuros' && (
              <Avatar
                aria-label="recipe"
                style={{ background: '#F2A516', fontSize: '28px' }}
              >
                <CalendarTodayIcon />
              </Avatar>
            )) ||
            (name === 'total-servicos' && (
              <Avatar
                aria-label="recipe"
                style={{ background: '#F24F13', fontSize: '28px' }}
              >
                <ContentCutIcon />
              </Avatar>
            ))
          }
          title={<span>{variation?.value}</span>}
          subheader={name === 'agendamentos-futuros' ? 'hoje' : 'dia anterior'}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            <span
              style={{
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#666666',
                paddingRight: '10px',
              }}
            >
              {name === 'faturamento' ? '' : primary?.value}
            </span>

            {name === 'faturamento' ? (
              <span
                style={{
                  paddingRight: '10px',
                  fontWeight: '700',
                  fontSize: '20px',
                  lineHeight: '23px',
                  color: '#666666',
                }}
              >
                {primary?.value?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </span>
            ) : (
              <span
                style={{
                  paddingRight: '10px',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '14px',
                  color: '#C6C2C2',
                }}
              >
                {(name === 'agendamentos-hoje' && 'Ag. Finalizados') ||
                  (name === 'agendamentos-futuros' && 'Amanhã') ||
                  (name === 'total-servicos' && 'Serviços Finalizados')}
              </span>
            )}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            <span
              style={{
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#666666',
                paddingRight: '10px',
              }}
            >
              {secondary?.value}
            </span>
            <span
              style={{
                paddingRight: '10px',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '14px',
                color: '#C6C2C2',
              }}
            >
              {(name === 'faturamento' && 'Receita Hoje') ||
                (name === 'agendamentos-hoje' && 'Ag. Cancelados') ||
                (name === 'agendamentos-futuros' && 'Próximos 7 dias') ||
                (name === 'total-servicos' && 'Ag. Cancelados')}
            </span>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  )
}

export default ModuleMetrics
