export const messageSendFinishedSchedulingSummary = {
  name: "Mensagem de envio de resumo do Agendamento",
  gender: "female",
  content: `
  %0AOlá *{{tutorName}}* 🐶,
  %0A%0A{{veterinaryName}}* da *{{storeName}}* enviou o resumo do agendamento veterinário {{petArticle}} *{{petName}}*.
  %0A
  %0A*Local:* {{storeName}}
  %0A*Endereço:* {{storeAddress}}
  %0A*Data:* {{careDate}}
  %0A*Hora:* {{careHour}}
  %0ASe precisar de alguma assistência ou tiver dúvidas, estamos à disposição.
  %0A%0AAtenciosamente,
  %0A*{{veterinaryName}}*
  %0A_{{storeName}}_ 🩺 🐾
  `.trim(),
};
