import { Heading1, Heading2, Heading3 } from "lucide-react";
import { useContext } from "../Context";

import { MenuButton, MenuGroup } from "./styles";

export function MenuHeading() {
  const { editor } = useContext();

  return (
    <MenuGroup>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
      >
        <Heading1 size={18} />
      </MenuButton>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
      >
        <Heading2 size={18} />
      </MenuButton>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
      >
        <Heading3 size={18} />
      </MenuButton>
    </MenuGroup>
  );
}
