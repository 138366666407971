
import { Page, View, Document } from '@react-pdf/renderer';

import { Header } from "../../Header";
import { Footer } from "../../Footer";
import { Watermark } from "../../Watermark";

import { Request } from './Request';

import { container, content } from './styles';

export function RequestDocument() {
  return (
    <Document>
      <Page size="A4" style={container} wrap>
        <Header.Root>
          <Header.Store />
          <Header.Pet />
        </Header.Root>
        <View style={content}>
          <Request />
        </View>
        <Footer.Root>
          <Footer.Ass />
          <Footer.Date />
          <Footer.Page />
        </Footer.Root>
        <Watermark />
      </Page>
    </Document>
  )
}
