import { memo } from "react";

import { getArticles } from "../../../../../services/functions";

import { usePet } from "../../../../../store/global/usePet";

import { Alert } from "../../../../../components/ui/Alert";

import { VeterinaryPrescriptionController } from "../../../../../services/controllers/VeterinaryCare";

export const IncompletePrescription = memo(() => {
  const CLVetPrescription = VeterinaryPrescriptionController();

  const pet = usePet((st) => st.data.pet);
  const articles = getArticles(pet.formattedAttributes?.gender);

  return (
    <Alert.Root name={CLVetPrescription._incompleteAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Dados não Salvos</Alert.Title>
        <Alert.Description>
          <p>
            Atenção! A receita {articles[1]}
            <strong> {pet.formattedAttributes?.name} </strong>
            ainda possui alterações não salvas. Se você fechar agora, todas as
            modificações realizadas serão perdidas. Tem certeza de que deseja
            sair sem salvar?
          </p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm
            onClick={() => {
              CLVetPrescription.closeDrawer(true);
            }}
          >
            Sim, sair
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
