import {
  ProcedureController,
  ScheduleController,
} from "../../../services/controllers/scheduling";
import { useSchedule } from "../../../store/schedule/useSchedule";

import { formatName, getEmployeeTypeName, getUserType, secondsToHms } from "../../../services/functions";

import { Input } from "../../ui/Form/Input";
import { Select } from "../../ui/Form/Select";

import { SelectedPetCard } from "../components/SelectedPetCard";

import { Container, Content, FieldsBox } from "./styles";
import { Actions } from "../components/Actions";

export function Scheduled() {
  const details = useSchedule((st) => st.data.details);
  const sellers = useSchedule((st) => st.data.customer.sellers);
  const procedure = useSchedule((st) => st.data.procedure);
  const employees = useSchedule((st) => st.data.customer.employees);
  const procedures = useSchedule((st) => st.data.customer.procedures);
  const disabledEdit = useSchedule((st) => st.data.disabledEdit);

  const CLScheduling = ScheduleController();
  const CLProcedure = ProcedureController();

  const durations = procedure.data?.durationOptions || procedure.baseDurations;

  return (
    <Container>
      <SelectedPetCard />
      <Content onSubmit={CLScheduling.update}>
        <FieldsBox>
          <Input.Root
            label="Data"
            pickDate
            value={procedure.date}
            style={{ maxWidth: "300px" }}
            onChange={(date) => {
              CLProcedure.setDate(date);
              CLScheduling.reFindEmployees();
            }}
          />
          <Input.Root
            label="Hora de início"
            mask="99:99"
            value={procedure.time}
            style={{ minWidth: "130px" }}
            onChange={(time) => {
              CLProcedure.setTime(time);
            }}
          />
          <Select.Root
            label={getEmployeeTypeName()}
            value={procedure.employee?.id}
            style={{ minWidth: "325px" }}
            onChange={(employee) => {
              CLProcedure.setEmployee(employee);
            }}
          >
            {employees.map((employee) => (
              <Select.Option value={employee.id} key={employee.id}>
                {formatName(employee.name)}
              </Select.Option>
            ))}
          </Select.Root>
          <Select.Root
            label="Procedimento"
            value={procedure.data?.id}
            style={{ minWidth: "240px" }}
            onChange={(procedure) => {
              CLProcedure.setProcedure(procedure);
            }}
          >
            {procedures.map((procedure) => (
              <Select.Option key={procedure.id} value={procedure.id}>
                {procedure.name}
              </Select.Option>
            ))}
          </Select.Root>
          <Select.Root
            label="Duração"
            value={procedure.duration}
            style={{ minWidth: "110px" }}
            onChange={(duration) => {
              CLProcedure.setDuration(duration);
            }}
          >
            {durations.map((duration) => (
              <Select.Option key={`${duration}`} value={`${duration}`}>
                {secondsToHms(duration * 60).formated}
              </Select.Option>
            ))}
          </Select.Root>
          <Select.Root
            label="Vendedor(a)"
            value={details?.seller || ''}
            styles={{ minWidth: "140px" }}
            onChange={(seller) => {
              CLScheduling.setDetails({ seller });
            }}
          >
            {sellers.map((seller) => (
              <Select.Option key={seller.id} value={seller.id}>
                {formatName(seller.name)}
              </Select.Option>
            ))}
          </Select.Root>
        </FieldsBox>

        <Actions
          cancel
          actions={[
            {
              type:'button',
              text: "Informar Chegada",
              onClick:  CLScheduling.checkIn,
            },
          ]}
          submit={{
            disabled: disabledEdit,
            status: "success",
            text: "Salvar Edição",
          }}
        />
      </Content>
    </Container>
  );
}
