import styled from 'styled-components'

export const Container = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 3;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;
  li {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`

export const StartsTagName = styled.p`
  font-weight: 300;
  font-size: 15px;
  line-height: 16px;
  flex: 1;
  width: 100%;
  margin: 0;
`

export const StartsTagLikeBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`
export const StartsTagLike = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  span {
    font-size: 15px;
  }
`
