import { useEffect, useState } from 'react'
import { Close } from '@material-ui/icons'
import { useCrm } from '../../../hooks/CrmContext'
import { PetInformation } from './PetInformation'
import { Activities } from './Activities'
import { Actions } from './Actions'
import { Pet } from './Pet'
import { Obs } from './Obs'
import { Frequency } from './Frequency'

import {
  Shadow,
  Container,
  Head,
  PetList,
  PetInformationBox,
  CloseButton,
} from './styles'

export function Drawer() {
  const activePetState = useState(false)
  const {
    getDetailsmodalOpen,
    closeDetailsmodalOpen,
    getDetailsCard,
    getStageActions,
  } = useCrm()

  const stageActions = getStageActions()

  const detailsCard = getDetailsCard()
  const modalIsOpen = getDetailsmodalOpen()

  const updatePetFrequency = (petId, frequency) => {
    if (detailsCard) {
      const petIndex = detailsCard.pets.findIndex((pet) => pet.petId === petId)
      if (petIndex !== -1) {
        detailsCard.pets[petIndex].frequency = frequency
      }
    }
  }

  const close = () => {
    closeDetailsmodalOpen()
  }

  useEffect(() => {
    if (detailsCard) {
      const petExistiInCard = !!detailsCard.pets.find(
        (pet) => pet.petId === activePetState[0],
      )
      if (!activePetState[0] || !petExistiInCard) {
        activePetState[1](detailsCard.pets[0].petId)
      }
    }
  }, [activePetState, detailsCard])

  return (
    <>
      <Shadow className={modalIsOpen ? 'open' : ''} onClick={close} />
      <Container className={modalIsOpen ? 'open' : ''}>
        <Head>
          Mais Detalhes
          <CloseButton
            onClick={() => {
              close()
            }}
          >
            <Close />
          </CloseButton>
        </Head>
        <PetList>
          {!!detailsCard &&
            detailsCard.pets.map((petData, i) => (
              <Pet
                key={petData.petId}
                index={i}
                {...petData}
                activePetState={activePetState}
              />
            ))}
          <PetInformationBox>
            {!!detailsCard &&
              detailsCard.pets.map((petData) => (
                <PetInformation
                  key={petData.petId}
                  {...petData}
                  activePet={activePetState[0]}
                />
              ))}
          </PetInformationBox>
        </PetList>
        <Frequency
          petData={
            detailsCard?.pets?.length > 0 &&
            detailsCard.pets.find(
              (petData) => petData.petId === activePetState[0],
            )
          }
          updatePetFrequency={updatePetFrequency}
        />
        <Obs petId={activePetState[0]} />
        <Activities petId={activePetState[0]} />
        <Actions actions={stageActions} />
      </Container>
    </>
  )
}
