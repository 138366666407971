import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    padding: '0 16px',
    background: '#FFFFFF',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '7px',
    marginBottom: '20px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#666666',
    flex: '1',
  },
  statusBox: {
    width: '350px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
  },
  pagination: {
    margin: '20px 0',
  },
}))
