import { toast } from 'react-toastify'

import { clearNumber } from '../../functions'

import { useCustomer } from '../../../store/customer/useCustomer'
import { useSchedule } from '../../../store/schedule/useSchedule'

import {
  CustomerModel,
  PetModel,
  ScheduleModel,
} from '../../models'

export default class CustomerController {

  isUpdatedScheduler = useSchedule.getState().actions.isUpdated;
  setLoading = useSchedule.getState().actions.setLoading;

  constructor() {
    this.MCustomer = CustomerModel()
    this.MPet = PetModel()
    this.MSchedule = ScheduleModel()
  }

  setCustomerData = (newCustomerData) => {
    if (newCustomerData.cpf) {
      newCustomerData.cpf = clearNumber(newCustomerData.cpf)
    }

    this.isUpdatedScheduler(false)
    this.MCustomer.setCustomer(newCustomerData)
  }

  findCustomer = async (e) => {
    if (e) e.preventDefault()
    const customer = await this.MCustomer.findCustomer()

    return customer
  }

  findPets = async (e) => {
    if (e) {
      e.preventDefault()
    }
    await this.MSchedule.findPets()
  }

  selectPet = (id) => {
    const { pets } = useSchedule.getState().data.customer
    const pet = pets.find((pet) => pet.id === id)

    this.MSchedule.setPet(pet)
  }

  sendNewPet = async (e) => {
    if (!!e) e.preventDefault()
    this.setLoading(true)
    const { customer } = useCustomer.getState().data

    if (!customer.cpf && !customer.id) {
      toast.error('Por favor informe o CPF do cliente!', { theme: 'colored' })
      return
    }

    if (!customer.id && customer.cpf) {
      await this.findCustomer()
    }

    const newPet = await this.MPet.saveNewPet()

    if(!newPet){
      this.setLoading(false)
      return false
    }

    this.MSchedule.pushPets(newPet)
    this.selectPet(newPet[0].id)

    this.setLoading(false)
  }

  sendNewClient = async (e) => {
    if (e) e.preventDefault()
    this.setLoading(true)

    const customer = await this.MCustomer.saveNewCustomer()
    if(!customer){
      this.setLoading(false)
      return false
    }

    await this.sendNewPet()

    this.setLoading(false)
    return true
  }
}
