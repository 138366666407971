import React, { useEffect, useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'

import Grid from '@material-ui/core/Grid'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import EditIcon from '@mui/icons-material/Edit'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import WhatsApp from '@mui/icons-material/WhatsApp'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// Componente

import moment from 'moment'
import 'moment/locale/pt-br'
import { Button } from '@mui/material'
import { getSellers, postSeller } from '../services/sellers'
import { getChecklist } from '../services/checklist'
import { getOrder } from '../services/order'
import BasePage from './basePage'
import DrawerType from '../components/atoms/Drawer'
import EditPet from '../components/organisms/EditPet'
import Main from '../components/organisms/MainCheckin'
import { AccordionCashback } from '../components/organisms/SidebarCustomer/AccordionItens/AccordionCashback'
import { AccordionServiceHistory } from '../components/organisms/SidebarCustomer/AccordionItens/AccordionServiceHistory'
import { AccordionPurchaseHistory } from '../components/organisms/SidebarCustomer/AccordionItens/AccordionPurchaseHistory'

moment.locale('pt-br')

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: '#CCD219',
    fontSize: '50px',
    height: '90px',
    width: '90px',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: '#ccc',
  },
  pet: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  total: {
    textAlign: 'center',
    fontWeight: '400',
    display: 'block',
    fontSize: '18px',
    marginBottom: '-15px',
  },
  price: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    textAlign: 'center',
    width: '100%',
    fontWeight: '700',
    display: 'block',
    padding: '10px',
    fontSize: '36px',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
  },
  customerDetails: {
    fontSize: '14px',
    color: '#878c93',
  },
  drawerBox: {
    maxWidth: '1020px',
    overflowX: 'hidden',
    padding: '0 40px',
    height: '100%',
  },
}))

function Resume({ order, handleGetOrder, loading }) {
  const [sellers, setSellers] = useState([])

  const [openDrawer, setOpenDrawer] = useState(false)

  const closeDrawerPet = () => {
    handleGetOrder()
    setOpenDrawer(false)
  }

  const handleSellers = async () => {
    const result = await getSellers()
    setSellers(result)
  }

  const handleSeller = (event, value) => {
    postSeller(order.appointmentOrderId, value?.id)
  }

  useEffect(() => {
    handleSellers()
  }, [])

  const classes = useStyles()
  const getAttribute = (name) =>
    order?.pet?.attributes?.filter((attr) => attr.label === name)[0].value
  return (
    <Grid item container xs={12} spacing={2}>
      <DrawerType
        open={openDrawer}
        setDrowerOpen={setOpenDrawer}
        titleHeader="Editar Pet"
        content={
          <div className={classes.drawerBox}>
            <EditPet
              petData={{
                customerId: order?.customer?.id,
                callBack: closeDrawerPet,
                value: {
                  ...order?.pet,
                  petId: order?.pet?.id,
                },
              }}
            />
          </div>
        }
      />
      <Grid item xs={4} className={classes.avatarContainer}>
        {order?.pet?.subscriptionStatus === 'Ativo' ? (
          <Avatar
            className={classes.avatar}
            src={order.pet.subscriptionImgUrl}
          />
        ) : (
          <>
            {order?.pet?.petPhoto ? (
              <Avatar className={classes.avatar} src={order.pet.petPhoto} />
            ) : (
              <Avatar className={classes.avatar}>
                {order?.label?.substr(0, 1)}
              </Avatar>
            )}
          </>
        )}
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={12}>
          <span className={classes.pet}>
            {order?.pet ? order?.pet?.name : <Skeleton />}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.customerDetails}>
            {order?.customer ? (
              <>
                Tutor:
                {order?.customer?.name}
              </>
            ) : (
              <Skeleton />
            )}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.customerDetails}>
            {order?.customer ? (
              <>
                CPF:
                {order?.customer?.cpf}
              </>
            ) : (
              <Skeleton />
            )}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.customerDetails}>
            {order?.customer ? (
              <>
                Telefone:
                {order?.customer?.phoneNumber}
              </>
            ) : (
              <Skeleton />
            )}
          </span>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Link
            to="route"
            target="_blank"
            onClick={(event) => {
              event.preventDefault()
              window.open(`/clientes/resumo/${order?.customer?.id}`, '_blank')
            }}
            style={{
              padding: '6px 10px',
              margin: '10px 0',
              textTransform: 'capitalize',
              color: 'rgb(255, 255, 255)',
              backgroundColor: '#CCD219',
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '90px',
              borderRadius: '8px',
            }}
          >
            <AccountBoxIcon style={{ marginRight: '8px' }} /> Perfil
          </Link>
          {order?.status === 'AGENDADO' && (
            <Link
              style={{
                padding: '6px 10px',
                margin: '10px',
                textTransform: 'capitalize',
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(37, 211, 102)',
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '189px',
                borderRadius: '8px',
              }}
              to={{
                pathname: `https://wa.me/55${order?.customer.phoneNumber
                  .replace(/\D/g, '')
                  .trim()}?text=Ol%C3%A1%20${order?.customer.name
                  .split(' ')
                  .slice(0, 1)
                  .join(
                    ' ',
                  )}%20segue%20o%20resumo%20do%20seu%20agendamento%0A%0A*Pet:*%20${
                  order?.pet.name
                }%0A*Valor%20do%20agendamento:*%20${new Intl.NumberFormat(
                  'pt-BR',
                  { style: 'currency', currency: 'BRL' },
                ).format(order?.totalPrice)}%0A*Local:*%20${
                  order?.storeName
                }%0A*Endere%C3%A7o:*%20${order?.storeAddress}%0A*Data:*%20${
                  order?.date
                }%0A*Hor%C3%A1rio:*%20${order?.startAt}`,
              }}
              target="_blank"
            >
              <WhatsApp style={{ marginRight: '8px' }} /> Enviar agendamento
            </Link>
          )}
          {order?.status === 'ORÇAMENTO ENVIADO' && (
            <Link
              style={{
                padding: '6px 10px',
                margin: '10px',
                textTransform: 'capitalize',
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(37, 211, 102)',
                textDecoration: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '189px',
                borderRadius: '8px',
              }}
              to={{
                pathname: `https://wa.me/55${order?.customer.phoneNumber
                  .replace(/\D/g, '')
                  .trim()}?text=Olá ${order?.customer.name
                  .split(' ')
                  .slice(0, 1)
                  .join(' ')}. *${order?.pet.name}* já está pronto!`,
              }}
              target="_blank"
            >
              <WhatsApp style={{ marginRight: '8px' }} /> Avisar o Cliente
            </Link>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Dados do Pet</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12}>
              <Grid item xs={3} className={classes.title}>
                Espécie
              </Grid>
              <Grid item xs={9}>
                {getAttribute('Espécie') ? (
                  getAttribute('Espécie')
                ) : (
                  <Skeleton />
                )}
              </Grid>
              <Grid item xs={3} className={classes.title}>
                Porte
              </Grid>
              <Grid item xs={9}>
                {getAttribute('Porte') ? getAttribute('Porte') : <Skeleton />}
              </Grid>
              <Grid item xs={3} className={classes.title}>
                Pelagem
              </Grid>
              <Grid item xs={9}>
                {getAttribute('Pelagem') ? (
                  getAttribute('Pelagem')
                ) : (
                  <Skeleton />
                )}
              </Grid>
              <Grid item xs={3} className={classes.title}>
                Raça
              </Grid>
              <Grid item xs={9}>
                {getAttribute('Raça') || getAttribute('Raça') === '' ? (
                  getAttribute('Raça')
                ) : (
                  <Skeleton />
                )}
              </Grid>
              <Grid item xs={3} className={classes.title}>
                Nascimento
              </Grid>
              <Grid item xs={9}>
                {order?.pet ? order?.pet?.birthdate : <Skeleton />}
              </Grid>

              <Grid item xs={3} className={classes.title}>
                Observações
              </Grid>
              <Grid item xs={9}>
                {order?.pet?.comments}
              </Grid>

              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => setOpenDrawer(true)}
                  style={{ color: 'rgb(204, 210, 25)' }}
                >
                  Editar dados do pet <EditIcon />
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Serviços Disponíveis no Pacote</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {order?.service_in_package.length >= 1 && (
              <>
                {order?.service_in_package.map((item) => (
                  <Grid item xs={12} key={item.name}>
                    {item.quantityAvailable} -{item.name}
                  </Grid>
                ))}
              </>
            )}
            {order?.service_in_package.length < 1 && (
              <Grid item xs={12}>
                <span className={classes.noPackage}>Cliente Sem Pacote</span>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>

        {!!order?.petSubscription.status && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Detalhes da Assinatura</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item xs={12}>
                <Grid item xs={4} className={classes.title}>
                  Status da assinatura:
                </Grid>
                <Grid item xs={8}>
                  {order.petSubscription.status}
                </Grid>
                <Grid item xs={4} className={classes.title}>
                  Banhos disponíveis:
                </Grid>
                <Grid item xs={8}>
                  {order.petSubscription.planType === 'Ilimitado'
                    ? order.petSubscription.planType
                    : order.petSubscription.quantityAvailable}
                </Grid>
                <Grid item xs={4} className={classes.title}>
                  Plano:
                </Grid>
                <Grid item xs={8}>
                  {order.petSubscription.planType}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {!!order?.futureProtocolVaccinations.length && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Protocolos Vacinas Ativos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item xs={12}>
                {order?.futureProtocolVaccinations.map((protocol) => (
                  <Grid
                    key={protocol.id}
                    style={{ marginBottom: '5px' }}
                    item
                    xs={12}
                  >
                    {`${protocol.date} - ${protocol.status} ${protocol.vaccinationNumber}/${protocol.vaccinationTotal}`}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {!!order && <AccordionCashback {...order} /> }
        {!!order && <AccordionServiceHistory {...order} /> }
        {!!order && <AccordionPurchaseHistory {...order} />}
      </Grid>

      {order && (
        <Autocomplete
          disableClearable
          required
          options={sellers}
          loading
          fullWidth
          defaultValue={{ name: order.sellerName }}
          getOptionLabel={(option) => option.name}
          disabled={order?.status === 'ORÇAMENTO ENVIADO'}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Vendedor"
              defaultValue={order.sellerName}
              placeholder="Digite o nome do vendedor"
              margin="normal"
              variant="outlined"
              onChange={handleSellers}
            />
          )}
          onChange={handleSeller}
        />
      )}

      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid item xs={12}>
        <span className={classes.total}>TOTAL A PAGAR</span>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <Skeleton />
        ) : (
          <span className={classes.price}>
            {order?.totalPrice || order?.totalPrice === 0 ? (
              Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(order?.totalPrice)
            ) : (
              <Skeleton />
            )}
          </span>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <span className={classes.subtitle}>Histórico de Movimentações</span>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        Agendamento criado em {order?.createdAt}
      </Grid>
    </Grid>
  )
}

function CheckinPage({ location }) {
  const { orderId } = useParams()
  const { search } = useLocation()
  const { state } = location

  const employeeQuery = new URLSearchParams(search).get('employeeId')
  const employeeId = employeeQuery || state?.employeeId

  const [order, setOrder] = useState(null)
  const [checklist, setChecklist] = useState([])
  const [loading, setLoading] = React.useState(false)

  const handleGetOrder = async () => {
    const data = await getOrder(orderId)
    setOrder(data)
  }

  const handleChecklist = async () => {
    const { items } = await getChecklist()
    setChecklist(items)
  }

  useEffect(() => {
    handleGetOrder()
    handleChecklist()
    window.scrollTo(0, 0)
  }, [])

  return (
    <BasePage
      pageTitle={`Agendamento ${order?.appointmentOrderId} - ${moment(
        order?.date,
      ).format('DD/MM/YYYY')} às ${order?.startAt}`}
    >
      <Grid
        container
        spacing={3}
        style={{
          background: '#ffffff',
          borderRadius: '7px',
          minHeight: '100vh',
        }}
      >
        <Grid item xs={4} style={{ borderRight: '1px solid #f6f6f6' }}>
          <Resume
            order={order}
            handleGetOrder={handleGetOrder}
            loading={loading}
          />
        </Grid>
        <Grid item xs={8}>
          <Main
            order={order}
            checklist={checklist}
            setOrder={setOrder}
            employeeId={employeeId}
            handleGetOrderUpdate={handleGetOrder}
            setLoading={setLoading}
            loading={loading}
          />
        </Grid>
      </Grid>
    </BasePage>
  )
}

export default CheckinPage
