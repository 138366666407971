import { ListPreviewContent } from "../ListPreviewContent";

import { InfoContent } from "./styles";

export function ListPreviewInfoContent({ children, fit = 'contain', ...rest }) {
  return (
    <InfoContent {...rest}>
      <ListPreviewContent fit={fit} />
    </InfoContent>
  );
}
