import { AlertActions } from './AlertActions'
import { AlertCancel } from './AlertCancel'
import { AlertConfirm } from './AlertConfirm'
import { AlertContent } from './AlertContent'
import { AlertDescription } from './AlertDescription'
import { AlertDescriptionContent } from './AlertDescriptionContent'
import { AlertRoot } from './AlertRoot'
import { AlertTitle } from './AlertTitle'
import { AlertTrigger } from './AlertTrigger'
import { useAlert } from './useAlert'

export const Alert = {
  Root: AlertRoot,
  Trigger: AlertTrigger,
  Title: AlertTitle,
  Description: AlertDescription,
  DescriptionContent: AlertDescriptionContent,
  Content: AlertContent,
  Actions: AlertActions,
  Confirm: AlertConfirm,
  Cancel: AlertCancel,
  store: useAlert,
}
