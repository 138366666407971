import PlanControllerClass from "./PlanController";
import InvoiceControllerClass from "./InvoiceController";
import SubscriberControllerClass from "./SubscriberController";
import SubscriptionDREControllerClass from "./SubscriptionDREController";
import SubscriptionCalculatorControllerClass from "./SubscriptionCalculatorController";
import SubscriptionPricingControllerClass from "./SubscriptionPricingController";
import SubscriptionIndicatorControllerClass from "./SubscriptionIndicatorController";

let CPlan = null;
let CInvoice = null;
let CSubscriber = null;
let CSubscriptionDRE = null;
let CSubscriptionCalculator = null;
let CSubscriptionPricing = null;
let CSubscriptionIndicator = null;

export function PlanController() {
  if (CPlan) {
    return CPlan;
  }
  CPlan = new PlanControllerClass();

  return CPlan;
}

export function InvoiceController() {
  if (CInvoice) {
    return CInvoice;
  }

  CInvoice = new InvoiceControllerClass();
  return CInvoice;
}

export function SubscriberController() {
  if (CSubscriber) {
    return CSubscriber;
  }

  CSubscriber = new SubscriberControllerClass();
  return CSubscriber;
}

export function SubscriptionDREController() {
  if (CSubscriptionDRE) {
    return CSubscriptionDRE;
  }

  CSubscriptionDRE = new SubscriptionDREControllerClass();
  return CSubscriptionDRE;
}

export function SubscriptionCalculatorController() {
  if (CSubscriptionCalculator) {
    return CSubscriptionCalculator;
  }

  CSubscriptionCalculator = new SubscriptionCalculatorControllerClass();
  return CSubscriptionCalculator;
}

export function SubscriptionPricingController() {
  if (CSubscriptionPricing) {
    return CSubscriptionPricing;
  }

  CSubscriptionPricing = new SubscriptionPricingControllerClass();
  return CSubscriptionPricing;
}
export function SubscriptionIndicatorController() {
  if (CSubscriptionIndicator) {
    return CSubscriptionIndicator;
  }

  CSubscriptionIndicator = new SubscriptionIndicatorControllerClass();
  return CSubscriptionIndicator;
}
