import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Grid, TextField, MenuItem } from '@material-ui/core'
import { Pagination } from '@mui/material'
import API from '../../../services/api'

import FilterPet from './FilterPet'
import Box from './Box'
import styles from './styles'

function Packages() {
  const { grid, title, header, content, statusBox, pagination } = styles()
  const { id } = useParams()
  const { control } = useForm()
  const [filterStatus, setFilterStatus] = useState('TODOS')
  const [selectPet, setSelectPet] = useState([])
  const [packageList, setPackageList] = useState(false)
  const [findPage, setFindPage] = useState(0)
  const [paginationData, setPaginationData] = useState(false)

  const getPackages = useCallback(async () => {
    try {
      const status = filterStatus === 'TODOS' ? '' : filterStatus
      const { data } = await API.get(
        `/customer/${id}/packages?petsId=${selectPet.join(
          ',',
        )}&status=${status}&page=${findPage}&perPage=3`,
      )
      setPaginationData(data.pagination)
      setPackageList(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }, [filterStatus, id, findPage, selectPet])

  useEffect(() => {
    getPackages()
  }, [filterStatus, selectPet, getPackages])

  return (
    <Grid item xs={12} className={grid}>
      <div className={header}>
        <h2 className={title}>Pacotes</h2>
        <FilterPet setSelectPet={setSelectPet} selectPet={selectPet} />
        <div className={statusBox}>
          <Controller
            control={control}
            defaultValue="TODOS"
            name="status"
            as={() => (
              <TextField
                fullWidth
                select
                size="small"
                value={filterStatus}
                label=""
                variant="outlined"
                onChange={(e) => {
                  setFilterStatus(e.target.value)
                }}
              >
                <MenuItem value="TODOS">TODOS</MenuItem>
                <MenuItem value="ATIVO">ATIVO</MenuItem>
                <MenuItem value="FINALIZADO">FINALIZADO</MenuItem>
                <MenuItem value="CANCELADO">CANCELADO</MenuItem>
              </TextField>
            )}
          />
        </div>
      </div>
      <div className={content}>
        {packageList &&
          packageList.map((packageData) => (
            <Box
              key={packageData.createdAt}
              packageData={packageData}
              getPackages={getPackages}
            />
          ))}
        {paginationData && paginationData.totalPages > 1 && (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ justifyContent: 'center' }}
            className={pagination}
          >
            <Pagination
              count={paginationData.totalPages}
              variant="outlined"
              onChange={(e, nextPage) => {
                setFindPage(nextPage - 1)
              }}
            />
          </Grid>
        )}
      </div>
    </Grid>
  )
}

export default Packages
