import { memo } from "react";
import _ from "lodash";
import { ClipboardList } from "lucide-react";

import { useHistory } from "../../../../../../store/service/veterinary/useHistory";

import { Accordion } from "../../../../../../components/ui/Accordion";

import { GeneralInformation } from "./GeneralInformation";
import { VitalSigns } from "./VitalSigns";
import { GeneralPhysicalExamination } from "./GeneralPhysicalExamination";
import { BodyExamination } from "./BodyExamination";
import { Diagnosis } from "./Diagnosis";

import { HistoryInformationContent as Container, FluidGrid } from "../styles";

export const HistoryViewRecord = memo(() => {
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const procedureData = useHistory((st) => st.data.historyProcedureData);

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedureRecord = selectedProcedure?.record

  const isEnabled = _.some(procedureRecord, _.identity)

  return (
    <Accordion.Item
      animate="dance"
      title="Prontuário"
      icon={ClipboardList}
      disabled={!isEnabled}
    >
      <Accordion.Content>
        <Container>
          <FluidGrid>
            <VitalSigns />
            <GeneralPhysicalExamination />
            <BodyExamination />
          </FluidGrid>
          <GeneralInformation />
          <Diagnosis />
        </Container>
      </Accordion.Content>
    </Accordion.Item>
  );
}

)





