import { Root, Portal } from '@radix-ui/react-popover'

import { usePopover } from './usePopover'

import { Container, Arrow, Anchor } from './styles'

export function PopoverRoot({ children, name, side = 'left', ...rest }) {
  const { popovers, status } = usePopover((st) => st.data)
  const { isOpen } = usePopover((st) => st.actions)

  const index = popovers.indexOf(name)
  const open = status[index]

  return (
    <Root open={open}>
      <Anchor />
      <Portal>
        <Container
          onPointerDownOutside={() => {
            isOpen({ name, open: false })
          }}
          side={side}
          sideOffset={5}
          collisionPadding={{ top: 20, left: 80 }}
          {...rest}
        >
          {children}
          <Arrow />
        </Container>
      </Portal>
    </Root>
  )
}
