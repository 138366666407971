import { toast } from "react-toastify";

import api from "../../api";

import { formatResponse } from "../../formatResponses/global";

export default class PetModel {
  find = async (petId) => {
    try {
      const { data } = await api.get(`pet/${petId}`);

      const formattedPet = formatResponse.pet(data);
      return formattedPet;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  serviceHistory = async (petId) => {
    // try {
      const { data } = await api.get(`crm/cliente/historico-servicos?petId=${petId}`);
      const formattedHistory = formatResponse.petServiceHistory(data);
      return formattedHistory;
    // } catch (error) {
    //   toast.error(error.response.data.friendly_message, { theme: "colored" });
    //   return false;
    // }
  };

  findWeightHistory = async (petId) => {
    try {
      const { data } = await api.get(`pet/${petId}/weight-history`);

      const formattedWeightHistory = formatResponse.petWeightHistory(data.result);
      return formattedWeightHistory;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
