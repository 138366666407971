import { memo } from "react";
import moment from "moment";

import { FilterIcon } from "lucide-react";

import { VACCINATION_STATUS_NAME } from "../../../../store/reports/veterinary/constants";

import { useVaccinations } from "../../../../store/reports/veterinary/useVaccinations";
import { ReportVaccinationsController } from "../../../../services/controllers/veterinary/reports";

import { Input } from "../../../../components/ui/Form/Input";
import { Select } from "../../../../components/ui/Form/Select";
import { Filter } from "../../../../components/ui/Filter";

import { FilterBox } from "./styles";

export const VaccinationsFilter = memo(() => {
  const filter = useVaccinations((st) => st.data.filter);
  const isLoading = useVaccinations((st) => st.data.isLoading);

  const vaccines = useVaccinations((st) => st.data.filterProps.vaccines);
  const protocols = useVaccinations((st) => st.data.filterProps.protocols);

  const CLReportVaccinations = ReportVaccinationsController();

  if (!filter) return null;
  return (
    <Filter.Root onSubmit={CLReportVaccinations.filterVaccinations}>
      <FilterBox>
        <Filter.Container>
          <Input.Root
            label="Início"
            pickDate
            style={{ maxWidth: "300px", zIndex: 4 }}
            value={moment(filter?.dateStart, "DD/MM/YYYY").format("DD/MM/YYYY")}
            onChange={(dateStart) => {
              CLReportVaccinations.setFilterReFetch({ dateStart });
            }}
          />
          <Input.Root
            label="Fim"
            pickDate
            style={{ maxWidth: "300px", zIndex: 3 }}
            value={moment(filter?.dateEnd, "DD/MM/YYYY").format("DD/MM/YYYY")}
            onChange={(dateEnd) => {
              CLReportVaccinations.setFilterReFetch({ dateEnd });
            }}
          />
          <Select.Root
            label="Status"
            style={{ minWidth: "200px" }}
            value={filter?.status || "*"}
            onChange={(status) => {
              CLReportVaccinations.setFilter({ status });
            }}
          >
            <Select.Option value="*">Todas</Select.Option>
            {Object.keys(VACCINATION_STATUS_NAME).map((statusKey) => (
              <Select.Option value={statusKey} key={statusKey}>
                {VACCINATION_STATUS_NAME[statusKey][0]}
              </Select.Option>
            ))}
          </Select.Root>
          <Select.Root
            label="Vacina"
            style={{ minWidth: "300px" }}
            value={filter?.vaccine || "*"}
            onChange={(vaccine) => {
              CLReportVaccinations.setFilter({ vaccine });
              CLReportVaccinations.findProtocols(vaccine);
            }}
          >
            <Select.Option value="*">Todas</Select.Option>
            {!!vaccines &&
              vaccines.map((vaccine) => (
                <Select.Option value={vaccine.id} key={vaccine.id}>
                  {vaccine.name}
                </Select.Option>
              ))}
          </Select.Root>
          <Select.Root
            label="Protocolo"
            style={{ minWidth: "250px" }}
            disabled={!protocols}
            value={filter?.protocol || "*"}
            onChange={(protocol) => {
              CLReportVaccinations.setFilter({ protocol });
            }}
          >
            <Select.Option value="*">Todos</Select.Option>
            {!!protocols &&
              protocols.map((protocol) => (
                <Select.Option value={protocol.id} key={protocol.id}>
                  {protocol.name}
                </Select.Option>
              ))}
          </Select.Root>
        </Filter.Container>
        <Filter.Container>
          <Input.Root
            label="Cliente"
            style={{ width: "initial", minWidth: "200px", flex: 1 }}
            value={filter?.customerName}
            onChange={(customerName) => {
              CLReportVaccinations.setFilter({ customerName });
            }}
          />
          <Input.Root
            label="pet"
            style={{ width: "initial", minWidth: "200px", flex: 1 }}
            value={filter?.petName}
            onChange={(petName) => {
              CLReportVaccinations.setFilter({ petName });
            }}
          />
        </Filter.Container>
      </FilterBox>
      <Filter.Actions>
        <Filter.Action isLoading={isLoading}>
          <Filter.ActionIcon icon={FilterIcon} isLoading={isLoading} />
          Filtrar
        </Filter.Action>
      </Filter.Actions>
    </Filter.Root>
  );
});
