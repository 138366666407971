import React from 'react'
import Dashboard from '../../components/organisms/Dashboard'
import { DashboardProvider } from '../../providers/dashboard'

// import { Container } from './styles';

function DashboardPage() {
  return (
    <DashboardProvider>
      <Dashboard />
    </DashboardProvider>
  )
}

export default DashboardPage
