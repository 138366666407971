import React, { useEffect } from 'react'
import {
  addDays,
  addMonths,
  differenceInMonths,
  format,
  isSameDay,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { Button, Grid } from '@mui/material'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

// moment
import moment from 'moment'
import styles from './DatePicker.module.css'
import 'moment/locale/pt-br'

moment.locale('pt-br')

function DateView({
  startDate,
  lastDate,
  selectDate,
  getSelectedDay,
  marked,
  setDateActive,
  dateActive,
  color,
}) {
  const firstSection = { marginLeft: '40px' }
  const themColor = color || 'rgb(204, 210, 25)'
  const selectedStyle = {
    fontWeight: 'bold',
    width: '55px',
    height: '65px',
    borderRadius: '14%',
    background: themColor,
    color: '#fff',
    justifyContent: 'center',
  }

  const getStyles = (day) => (isSameDay(day, dateActive) ? selectedStyle : null)

  const getId = (day) => (isSameDay(day, dateActive) ? 'selecteds' : '')

  const onDateClick = (day) => {
    setDateActive(day)
    if (getSelectedDay) {
      getSelectedDay(day)
    }
  }

  const renderDays = () => {
    const dateFormat = 'd'

    const months = []
    let days = []

    for (let i = 0; i <= differenceInMonths(lastDate, startDate); i += 1) {
      const month = startOfMonth(addMonths(startDate, i))

      const start = i === 0 ? Number(format(startDate, dateFormat)) - 1 : 0
      const end =
        i === differenceInMonths(lastDate, startDate)
          ? Number(format(lastDate, 'd'))
          : Number(format(lastDayOfMonth(month), 'd'))

      for (let j = start; j < end; j += 1) {
        const currentDay = addDays(month, j)

        days.push(
          <div
            id={`${getId(currentDay)}`}
            className={marked ? styles.dateDayItemMarked : styles.dateDayItem}
            style={getStyles(currentDay)}
            key={currentDay}
            onClick={() => onDateClick(currentDay)}
          >
            <div className={styles.dayLabel}>
              {format(currentDay, 'cccccc', { locale: ptBR })}
            </div>
            <div className={styles.dateLabel}>
              {format(currentDay, dateFormat)}
            </div>
            {/* {getMarked(currentDay)} */}
          </div>,
        )
      }
      months.push(
        <div className={styles.monthContainer} key={month}>
          <div
            className={styles.daysContainer}
            style={i === 0 ? firstSection : null}
          >
            {days}
          </div>
        </div>,
      )
      days = []
    }

    return (
      <div id="container" className={styles.dateListScrollable}>
        {months}
      </div>
    )
  }

  const handleNextDate = () => {
    const Next = new Date(moment(selectDate).add(12, 'days'))

    setDateActive(Next)
    if (getSelectedDay) {
      getSelectedDay(Next)
    }
  }

  const handleBackDate = () => {
    const Back = new Date(moment(selectDate).subtract(12, 'days'))

    setDateActive(Back)
    if (getSelectedDay) {
      getSelectedDay(Back)
    }
  }

  useEffect(() => {
    if (dateActive) {
      setTimeout(() => {
        const view = document.getElementById('selecteds')
        view?.scrollIntoView({
          behavior: 'auto',
          inline: 'center',
          block: 'nearest',
        })
      }, 100)
    }
  }, [dateActive])

  return (
    <Grid container>
      <Grid
        item
        xs={1}
        onClick={() => handleBackDate()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button style={{ color: themColor }}>
          <NavigateBeforeIcon />
        </Button>
      </Grid>

      <Grid item xs={10}>
        {renderDays()}
      </Grid>

      <Grid
        item
        xs={1}
        onClick={() => handleNextDate()}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button style={{ color: themColor }}>
          <NavigateNextIcon />
        </Button>
      </Grid>
    </Grid>
  )
}

export { DateView }
