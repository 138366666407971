import { Container, Dot } from './styles'

export function Waiting({ children, color, ...rest }) {
  return (
    <Container style={{ color, ...rest.style }}>
      <Dot />
      {children}
    </Container>
  )
}
