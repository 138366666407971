import { Global } from './Global'

import { Store } from './Store'
import { History } from './History'

import { Container, Main, Side } from './styles'

import { RankingProvider } from '../../../hooks/RankingContext'

export function Ranking() {
  return (
    <Container>
      <RankingProvider>
        <Main>
          <Global />
        </Main>
        <Side>
          <Store />
          <History />
        </Side>
      </RankingProvider>
    </Container>
  )
}
