import { toast } from "react-toastify";

import API from "../../api";

export default class SubscriptionIndicatorModel {
  getIndicator = async (indicator) => {
    try {
      const { data } = await API.get(`/dashboard/dre-assinatura/indicadores?indicador=${indicator}`);
      return data.resultado
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  };
}
