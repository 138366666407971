import { create } from 'zustand'
import { produce } from 'immer'

import {
  getProtocols,
  interruptProtocol,
  protocolConciliation,
  excludeProtocol,
} from './protocolFuncions'
import { useVaccines } from './useVaccines'

export const useProtocols = create((set, get) => ({
  active: '0',
  petId: false,
  protocolList: false,
  actions: {
    findProtocols: async () => {
      const { petId } = get()
      const data = await getProtocols(petId)

      set((state) =>
        produce(state, (draft) => {
          draft.protocolList = protocolConciliation(data.protocolsIndex)
          useVaccines.getState().actions.setVaccines(data.vaccinationsList)
        }),
      )
    },
    setPet: (id) =>
      set((state) =>
        produce(state, (draft) => {
          draft.petId = id
        }),
      ),

    setActiveProtocol: (id) =>
      set((state) =>
        produce(state, (draft) => {
          draft.active = id
        }),
      ),
    interruptToggle: (id) => {
      set((state) =>
        produce(state, (draft) => {
          draft.protocolList = state.protocolList.map((protocol) => {
            if (protocol.id === id) {
              return { ...protocol, interrupted: !protocol.interrupted }
            }
            return protocol
          })
        }),
      )

      const { petId } = get()
      const isInterrupted = get().protocolList.find(
        (protocol) => protocol.id === id,
      ).interrupted

      interruptProtocol(id, petId, isInterrupted)
    },

    exclude: (id) => {
      set((state) =>
        produce(state, (draft) => {
          draft.protocolList = state.protocolList.filter(
            (protocol) => protocol.id !== id,
          )
        }),
      )

      const { petId } = get()
      excludeProtocol(id, petId)
    },

    clearProtocols: (vaccines) =>
      set((state) =>
        produce(state, (draft) => {
          draft.protocolList = !state.protocolList
            ? state.protocolList
            : state.protocolList.filter((protocol) =>
                vaccines.find(
                  (vaccine) =>
                    vaccine.protocolId === protocol.id &&
                    vaccine.status !== 'cancel',
                ),
              )
        }),
      ),
  },
}))
