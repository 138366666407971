import { Action as RadixConfirm } from '@radix-ui/react-alert-dialog'

import { Action } from '../Action'

export function AlertConfirm({ children, ...rest }) {
  return (
    <RadixConfirm asChild>
      <Action.Root {...rest}>{children}</Action.Root>
    </RadixConfirm>
  )
}
