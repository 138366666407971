import styled, { keyframes } from "styled-components";

import {
  Root as RadixAccordionRoot,
  Item as RadixAccordionItem,
  Content as RadixAccordionContent,
} from "@radix-ui/react-accordion";

const slideDown = keyframes`
  from { height: 0; }
  to { height: var(--radix-accordion-content-height); }
`;
const slideUp = keyframes`
  from { height: var(--radix-accordion-content-height); }
  to { height: 0; }
`;

export const Container = styled(RadixAccordionRoot)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Item = styled(RadixAccordionItem)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled(RadixAccordionContent)`
  overflow: hidden;

  &[data-disabled] {
    height: 0;
  }

  &[data-state="open"] {
    animation: ${slideDown}  ${(props) => props.theme.animation.delay.normal} cubic-bezier(0.87, 0, 0.13, 1) forwards;
  }

  &[data-state="closed"] {
    animation: ${slideUp}  ${(props) => props.theme.animation.delay.normal} cubic-bezier(0.87, 0, 0.13, 1) forwards;
  }
`;
