import React, { useState } from 'react'
import API from '../services/api'

export const PrescriptionContext = React.createContext({})

export function PrescriptionProvider({ children }) {
  const [searchText, setSearchText] = useState('')
  const [newPrescription, setNewPrescription] = useState(1)
  const [arrayMedicines, setArrayMedicines] = useState([])
  const [summary, setSummary] = useState([])
  const [open, setOpen] = useState(false)
  const [editId, setPrescriptionId] = React.useState('')
  async function searchPrescription(text = '') {
    try {
      const { data } = await API.get(
        `medicines/search?text=${text}&page=0&perPage=50`,
      )
      if (text === '') {
        setArrayMedicines([])
      } else {
        setArrayMedicines([...data])
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getPrescriptions(appointmentId) {
    try {
      const { data } = await API.get(`prescriptions/${appointmentId}`)
      setSummary(data?.prescriptions)
    } catch (error) {
      console.log(error)
    }
  }

  async function deletePrescriptions(appointmentId, prescriptionId) {
    try {
      const data = await API.delete(
        `prescriptions/${appointmentId}/${prescriptionId}`,
      )
      console.log(data, 'delete')
    } catch (error) {
      console.log(error)
    }
  }
  let filteredItems = []
  if (arrayMedicines.length > 0) {
    filteredItems = arrayMedicines?.filter((item) =>
      item?.nome?.toLowerCase().includes(searchText?.toLowerCase()),
    )
  }

  return (
    <PrescriptionContext.Provider
      value={{
        newPrescription,
        setNewPrescription,
        searchPrescription,
        arrayMedicines,
        setSearchText,
        searchText,
        filteredItems,
        setArrayMedicines,
        getPrescriptions,
        summary,
        open,
        setOpen,
        deletePrescriptions,
        editId,
        setPrescriptionId,
      }}
    >
      {children}
    </PrescriptionContext.Provider>
  )
}

export const usePrescription = () => React.useContext(PrescriptionContext)
