import React, { useContext } from 'react'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MedicationIcon from '@mui/icons-material/Medication'

import Backdrop from '@mui/material/Backdrop'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import { PrescriptionContext } from '../../../providers/prescription'
import API from '../../../services/api'

import {
  Title,
  Input,
  Row,
  ListMedicines,
  TitleMedicines,
  ItemMedicine,
  CheckBoxItem,
  DivTitleMedicines,
  ListNames,
  ListNamesItens,
  RowButton,
  InputType,
  InputTypeName,
  StyleText,
} from './styles'

function EditPrescription({ appointmentId }) {
  const [textareas, setTextareas] = React.useState([])
  const [nextId, setNextId] = React.useState(2)
  const [isFocused, setIsFocused] = React.useState(false)
  const [inputApresentacao, setInputApresentacao] = React.useState(true)
  const cores = ['#ccc', '#fff']
  const {
    setNewPrescription,
    setSearchText,
    searchText,
    filteredItems,
    searchPrescription,
    setArrayMedicines,
    open,
    setOpen,
    getPrescriptions,
    editId,
  } = useContext(PrescriptionContext)

  const addTextarea = (item) => {
    setTextareas([
      ...textareas,
      {
        id: nextId,
        variation:
          item?.apresentacoesConcentracoes[0]?.apresentacoesEConcentracoes[0] ||
          '',
        name: '',
        pharmacyType: '',
        posology: '',
        info: item || null,
      },
    ])
    setNextId(nextId + 1)
    setSearchText('')
    setArrayMedicines([])
  }

  const removeTextarea = (id) => {
    setTextareas(
      textareas.filter((textarea) =>
        textarea?.id ? textarea?.id !== id : textarea?._id !== id,
      ),
    )
  }

  const handleTextareaChange = (id, posology) => {
    const updatedTextareas = textareas.map((textarea) => {
      if (textarea._id === id) {
        return { ...textarea, posology }
      }
      return textarea
    })
    setTextareas(updatedTextareas)
  }

  const handleTypeChange = (id, pharmacyType) => {
    const updatedTextareas = textareas.map((textarea) => {
      if (textarea._id === id) {
        return { ...textarea, pharmacyType }
      }
      return textarea
    })
    setTextareas(updatedTextareas)
  }

  const handleOptionChange = (id, variation) => {
    const updatedTextareas = textareas.map((textarea) => {
      if (textarea._id === id) {
        return { ...textarea, variation }
      }
      return textarea
    })
    setTextareas(updatedTextareas)
  }

  const handleNameChange = (id, name) => {
    const updatedTextareas = textareas.map((textarea) => {
      if (textarea.id === id) {
        return { ...textarea, name }
      }
      return textarea
    })
    setTextareas(updatedTextareas)
  }

  function handleFocus() {
    setIsFocused(true)
  }

  function handleBlur() {
    setIsFocused(false)
  }

  async function getPresc() {
    try {
      const { data } = await API.get(`prescription/${appointmentId}/${editId}`)
      setTextareas(data.medicines)
    } catch (error) {
      console.log(error)
    }
  }

  async function getPrescription() {
    setOpen(true)
    const arrayAtualizado = textareas.map((item) => ({
      name: item?.info?.nome ? item?.info?.nome : item.name,
      posology: item?.posology,
      variation: item?.variation,
      pharmacyType: item?.pharmacyType,
    }))

    try {
      const data = await API.post(`prescription/${appointmentId}/${editId}`, {
        medicines: arrayAtualizado,
      })

      if (data.status === 201) {
        getPrescriptions(appointmentId)
        setNewPrescription(1)
        setOpen(false)
      }
      setOpen(false)
    } catch (error) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    if (editId) getPresc()
  }, [editId])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Title>Editar Prescrição</Title>
      <ListMedicines>
        {textareas?.map((item) => (
          <ItemMedicine key={item._id}>
            <Row>
              <DivTitleMedicines>
                {item?.info?.nome ? (
                  <TitleMedicines>{item?.info?.nome}</TitleMedicines>
                ) : (
                  <div style={{ width: '95%' }}>
                    <InputTypeName
                      type="text"
                      placeholder="Digite o nome"
                      value={item.name}
                      onChange={(e) =>
                        handleNameChange(item._id, e.target.value)
                      }
                    />
                  </div>
                )}

                <span onClick={() => removeTextarea(item._id)}>
                  <DeleteForeverIcon />
                </span>
              </DivTitleMedicines>

              {item?.info?.apresentacoesConcentracoes
                ?.apresentacoesEConcentracoes?.length > 0 ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {inputApresentacao ? (
                    <>
                      <select
                        style={{
                          borderRadius: '3px',
                          padding: '8px 6px',
                          width: '68.5%',
                        }}
                        value={item.variation}
                        onChange={(e) =>
                          handleOptionChange(item._id, e.target.value)
                        }
                      >
                        {item?.info?.apresentacoesConcentracoes?.apresentacoesEConcentracoes?.map(
                          (option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ),
                        )}
                      </select>

                      <EditIcon
                        onClick={() => setInputApresentacao(false)}
                        style={{ marginLeft: '15px', cursor: 'pointer' }}
                      />
                    </>
                  ) : (
                    <>
                      <InputType
                        type="text"
                        placeholder="Digite a apresentação"
                        value={item.variation}
                        onChange={(e) =>
                          handleOptionChange(item._id, e.target.value)
                        }
                      />

                      <CloseIcon
                        onClick={() => setInputApresentacao(true)}
                        style={{ marginLeft: '15px', cursor: 'pointer' }}
                      />
                    </>
                  )}
                </div>
              ) : (
                <InputType
                  type="text"
                  placeholder="Digite a apresentação "
                  value={item?.variation}
                  onChange={(e) => handleOptionChange(item._id, e.target.value)}
                />
              )}
            </Row>

            <Row>
              <textarea
                style={{ resize: 'none', padding: '8px 10px' }}
                name="postContent"
                placeholder="Digite a Posologia"
                value={item?.posology}
                onChange={(e) =>
                  handleTextareaChange(item._id, e.target.value, item)
                }
                rows={4}
                cols={80}
              />
              <span>
                Sugestão: &quot;Em gatos, administrar ao menos 10 mL de água
                após uso do produto.&quot;
              </span>
            </Row>

            <Row>
              <CheckBoxItem>
                <div>TIPO DE FARMÁCIA (OPCIONAL)</div>
                <input
                  type="checkbox"
                  name="Veterinária"
                  checked={item.pharmacyType === 'Veterinária'}
                  value={item.pharmacyType}
                  onChange={() => handleTypeChange(item._id, 'Veterinária')}
                />
                <label>Veterinária</label>
                <input
                  type="checkbox"
                  checked={item.pharmacyType === 'Humana'}
                  value={item.pharmacyType}
                  onChange={() => handleTypeChange(item._id, 'Humana')}
                />
                <label>Humana</label>
              </CheckBoxItem>
            </Row>
          </ItemMedicine>
        ))}
      </ListMedicines>

      <Input
        type="text"
        value={searchText}
        placeholder={isFocused ? '' : '+ Adicionar Medicamento'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        label="Adicionar Medicamento"
        onChange={(e) => {
          setSearchText(e.target.value)
          searchPrescription(e.target.value)
        }}
      />

      {searchText && filteredItems.length === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <StyleText>Remédio não encontrado</StyleText>
          </div>
          <div>
            <button
              onClick={() => addTextarea()}
              style={{
                backgroundColor: '#ccd219',
                color: '#fff',
                border: 'none',
                padding: '10px 22px',
                cursor: 'pointer',
                borderRadius: '3px',
              }}
            >
              Inserir Manualmente
            </button>
          </div>
        </div>
      )}

      {filteredItems.length === 0 && (
        <RowButton>
          <button
            onClick={() => {
              setNewPrescription(1)
              setTextareas([])
            }}
          >
            Cancelar
          </button>
          {textareas.length > 0 && (
            <button disabled={open} onClick={() => getPrescription()}>
              Salvar
            </button>
          )}
        </RowButton>
      )}

      {filteredItems.length > 0 && (
        <ListNames>
          {filteredItems?.map((item, index) => (
            <ListNamesItens
              key={item.id}
              onClick={() => addTextarea(item)}
              style={{ backgroundColor: cores[index % cores.length] }}
            >
              <MedicationIcon /> {item.nome}
            </ListNamesItens>
          ))}
        </ListNames>
      )}
    </>
  )
}

export default EditPrescription
