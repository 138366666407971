import { CardContent } from './CardContent'
import { CardContentList } from './CardContentList'
import { CardContentListItem } from './CardContentListItem'
import { CardContentTitle } from './CardContentTitle'
import { CardHeader } from './CardHeader'
import { CardHeaderDescription } from './CardHeaderDescription'
import { CardHeaderTitle } from './CardHeaderTitle'
import { CardHighlight } from './CardHighlight'
import { CardMain } from './CardMain'
import { CardRoot } from './CardRoot'
import { CardSeparator } from './CardSeparator'

export const Card = {
  Root: CardRoot,
  Main: CardMain,
  Header: CardHeader,
  Content: CardContent,
  Highlight: CardHighlight,
  Separator: CardSeparator,
  ContentList: CardContentList,
  HeaderTitle: CardHeaderTitle,
  ContentTitle: CardContentTitle,
  ContentListItem: CardContentListItem,
  HeaderDescription: CardHeaderDescription,
}
