export const ANAMNESE_DEFAULT_CONTENT = `
<ul>
<li><strong>Queixa principal:</strong> - </li>
<li><strong>Tempo de evolução:</strong> - </li>
</ul>
<p></p>
<ul>
<li><strong>Alimentação:</strong> - </li>
<li><strong>Vermifugação:</strong> - </li>
<li><strong>Vacinação:</strong> - </li>
<li><strong>Doenças anteriores:</strong> - </li>
<li><strong>Acesso à rua?:</strong> - </li>
</ul>
`;

export const DIAGNOSIS_DEFAULT_CONTENT = `
<h3>Diagnóstico:</h3>
<p></p>
<p></p>
<p></p>
<h3>Tratamento:</h3>
<p></p>
`;
