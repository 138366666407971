import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 45px;
`

export const Title = styled.h2`
  color: #252525;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
`
