import { create } from "zustand";
import { produce } from "immer";
import _ from "lodash";

export const useGradient = create((set) => ({
  data: {
    gradients: {},
  },
  actions: {
    setGradient: (name, gradient) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.gradients[name]) {
            draft.data.gradients[name] = {
              colors: gradient.colors || ["transparent", "transparent"],
            };
          }
        })
      ),
    setControl: (name, control) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.gradients[name]) return;
          draft.data.gradients[name].control = control
        })
      ),
    updateColors: (name, newColors) =>
      set((state) =>
        produce(state, (draft) => {
          if (!state.data.gradients[name]) return;
          draft.data.gradients[name].colors = newColors;
        })
      ),
    animateStart: (name) =>
      set((state) =>
        produce(state, () => {
          if (!state.data.gradients[name]) return;
          state.data.gradients[name].control.start("end");
        })
      ),
    animateReset: (name) =>
      set((state) =>
        produce(state, () => {
          if (!state.data.gradients[name]) return;
          state.data.gradients[name].control.set("init");
        })
      ),
  },
}));
