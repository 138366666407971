import React from 'react'
import { useDrag } from 'react-dnd'

import { useThemes } from '../../../../../../providers/theme'
import { useSchedule } from '../../../../../../hooks/ScheduleContext'
import { CALENDAR } from '../../../../../../styles/constant'

import { Container } from './styles'
import { Schedule } from './Bubbles/Schedule'
import { Block } from './Bubbles/Block'
import { calendarStatusIfTheme } from '../../../../../../services/functions'
import { useCalendar } from '../../../../../../store/schedule/useCalendar'
import { VetSchedule } from './Bubbles/VetSchedule'

export function Bubble(bubbleProps) {
  const { id, type, status, totalServices } = bubbleProps
  const { theme } = useThemes()
  const { getBubbleInformation, getBubblesBetweenBubbleGap } = useSchedule()
  const { bubbleSize } = getBubbleInformation(bubbleProps)
  const statusByTheme = calendarStatusIfTheme(theme, status)

  const { bubbleBlockGapFromStart } = getBubbleInformation(bubbleProps)

  const {
    calendar: { dragging },
  } = useCalendar()

  const [{ isDragging }, dragRef] = useDrag({
    type: totalServices <= 1 ? CALENDAR.ItemTypes.BUBBLE : '',
    item: bubbleProps,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const bubblesInGap = getBubblesBetweenBubbleGap(bubbleProps)

  const indexInGap = bubblesInGap.findIndex(
    (bubble) => bubble.id === bubbleProps.id,
  )
  const bubbleWidth = 90 / bubblesInGap.length
  const bubbleLeft = bubbleWidth * indexInGap

  const ScheduleBubble = theme === "draMei"? VetSchedule :Schedule

  return (
    <Container
      size={dragging && type !== 'block' ? 20 : bubbleSize}
      isDragging={isDragging}
      status={statusByTheme?.code || 'checkIn'}
      top={bubbleBlockGapFromStart}
      w={bubbleWidth}
      l={bubbleLeft}
      ref={dragRef}
      className="bubble"
    >
      {type === 'schedule' && <ScheduleBubble id={id} width={bubbleWidth} />}
      {type === 'block' && <Block id={id} />}
    </Container>
  )
}
