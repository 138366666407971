import React, { useContext } from 'react'
import { BlockEmployeeContext } from '../../../../../providers/blockEmployee'

import {
  CheckboxContainer,
  DayCheckbox,
  DivContainer,
  ItemContainer,
} from './styles'

function CheckBox() {
  const { daysSelected, setDaysSelected } = useContext(BlockEmployeeContext)

  const handleCheckboxChangee = (event) => {
    setDaysSelected({
      ...daysSelected,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <CheckboxContainer>
      <DivContainer>
        <ItemContainer>
          <DayCheckbox
            name="SEGUNDA"
            checked={daysSelected.SEGUNDA}
            onChange={handleCheckboxChangee}
          />
          <span
            onClick={() =>
              setDaysSelected({
                ...daysSelected,
                SEGUNDA: !daysSelected.SEGUNDA,
              })
            }
          >
            SEGUNDA
          </span>
        </ItemContainer>
        <ItemContainer>
          <DayCheckbox
            name="TERÇA"
            checked={daysSelected.TERÇA}
            onChange={handleCheckboxChangee}
          />
          <span
            onClick={() =>
              setDaysSelected({
                ...daysSelected,
                TERÇA: !daysSelected.TERÇA,
              })
            }
          >
            TERÇA
          </span>
        </ItemContainer>
        <ItemContainer>
          <DayCheckbox
            name="QUARTA"
            checked={daysSelected.QUARTA}
            onChange={handleCheckboxChangee}
          />
          <span
            onClick={() =>
              setDaysSelected({
                ...daysSelected,
                QUARTA: !daysSelected.QUARTA,
              })
            }
          >
            QUARTA
          </span>
        </ItemContainer>
      </DivContainer>
      <DivContainer>
        <ItemContainer>
          <DayCheckbox
            name="QUINTA"
            checked={daysSelected.QUINTA}
            onChange={handleCheckboxChangee}
          />
          <span
            onClick={() =>
              setDaysSelected({
                ...daysSelected,
                QUINTA: !daysSelected.QUINTA,
              })
            }
          >
            QUINTA
          </span>
        </ItemContainer>
        <ItemContainer>
          <DayCheckbox
            name="SEXTA"
            checked={daysSelected.SEXTA}
            onChange={handleCheckboxChangee}
          />
          <span
            onClick={() =>
              setDaysSelected({
                ...daysSelected,
                SEXTA: !daysSelected.SEXTA,
              })
            }
          >
            SEXTA
          </span>
        </ItemContainer>
        <ItemContainer>
          <DayCheckbox
            name="SÁBADO"
            checked={daysSelected.SÁBADO}
            onChange={handleCheckboxChangee}
          />
          <span
            onClick={() =>
              setDaysSelected({
                ...daysSelected,
                SÁBADO: !daysSelected.SÁBADO,
              })
            }
          >
            SÁBADO
          </span>
        </ItemContainer>
      </DivContainer>
      <DivContainer>
        <ItemContainer>
          <DayCheckbox
            name="DOMINGO"
            checked={daysSelected.DOMINGO}
            onChange={handleCheckboxChangee}
          />
          <span
            onClick={() =>
              setDaysSelected({
                ...daysSelected,
                DOMINGO: !daysSelected.DOMINGO,
              })
            }
          >
            DOMINGO
          </span>
        </ItemContainer>
      </DivContainer>
    </CheckboxContainer>
  )
}

export default CheckBox
