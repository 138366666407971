import { Chart as ChartJS, LineElement, Legend, Tooltip } from "chart.js";
import { Chart } from "react-chartjs-2";
import { useTheme } from "styled-components";

import { formatWeight } from "../../../../../services/functions";

import { usePet } from "../../../../../store/global/usePet";

ChartJS.register(LineElement, Legend, Tooltip);

export function WeightHistoryChart() {
  const theme = useTheme();
  const weightHistory = usePet((st) => st.data.pet.weightHistory);

  if (!weightHistory) return null;

  const weightData = weightHistory.chartWeights
  const data = {
    labels: weightData.dates,
    datasets: [
      {
        type: "line",
        label: "Peso",
        borderColor: theme.colors.scale[500],
        backgroundColor: theme.colors.scale[500],
        borderWidth: 2,
        fill: null,
        data: weightData.weights,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += `${formatWeight(context.parsed.y)}kg`;
            }
            return label;
          },
        },
      },
    },
  };

  return <Chart data={data} options={options} />;
}
