import React, { useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

// moment
import moment from 'moment'
import { ReportContext } from '../../../providers/report'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ccd219',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function Downloads({ setValue }) {
  const { getDownloads, downloads } = useContext(ReportContext)

  const handleDownloadClick = (value) => {
    window.location.href = value
  }

  useEffect(() => {
    getDownloads()
  }, [])

  return (
    <TableContainer component={Paper} style={{ marginTop: '15px' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
            <StyledTableCell align="left">Solicitação</StyledTableCell>
            <StyledTableCell align="left">Finalização</StyledTableCell>
            <StyledTableCell align="center">Relatório</StyledTableCell>
            <StyledTableCell align="center">Arquivo</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {downloads?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.status}</StyledTableCell>
              <StyledTableCell align="left">
                {moment(row.createdAt).format('DD-MM-YYYY')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.status === 'FINALIZADO' &&
                  moment(row.updatedAt).format('DD-MM-YYYY')}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Tooltip title={row.name} placement="left">
                  <Button
                    onClick={() =>
                      setValue(
                        (row.name === 'Relatório de Pacote' && '2') ||
                          (row.name === 'Relatório de Pet' && '5') ||
                          (row.name === 'Relatório de Cliente' && '1') ||
                          (row.name === 'Relatório de Agendamento' && '0'),
                      )
                    }
                    style={{ color: '#ccd219' }}
                  >
                    Ver Relatório
                  </Button>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Tooltip title="Baixar" placement="left">
                  <Button onClick={() => handleDownloadClick(row.file)}>
                    <FileDownloadIcon style={{ color: '#ccd219' }} />
                  </Button>
                </Tooltip>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Downloads
