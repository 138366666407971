import { useCrm } from '../../../../../hooks/CrmContext'
import { ActionIconStyled } from '../../../ActionIcon'

export function Action({ button, goToStage, cardId, eventData }) {
  const { updateCard, sendEvent } = useCrm()

  const updateCardStage = (e) => {
    e.stopPropagation()
    if (goToStage > 0) {
      updateCard(cardId, { stageCode: goToStage })
    }
    if (button === 'block-contact') {
      updateCard(cardId, { optout: true })
    }

    sendEvent(eventData, cardId)
  }

  return (
    <button onClick={updateCardStage}>
      <ActionIconStyled button={button} styled />
    </button>
  )
}
