import { useMemo } from 'react'
import { Package, Save } from 'lucide-react';

import { formatMoney, isAbleByStatus } from "../../../../services/functions";

import { usePet } from "../../../../store/global/usePet";
import { useCare } from '../../../../store/service/veterinary/useCare';
import { useService } from '../../../../store/service/veterinary/useService';

import { VeterinaryServiceController } from "../../../../services/controllers/VeterinaryCare";

import { Action } from '../../../../components/ui/Action';

import { AvailableServicePackages } from './AvailableServicePackages';
import { ServicesList } from "./ServicesList";

import { Container, Header, Title, Footer, TotalServices } from "./styles";

export function VeterinaryServices() {
  const CLVetServices = VeterinaryServiceController();

  const careStatus = useCare(st => st.data.care.status)
  const isAble = isAbleByStatus(careStatus,CLVetServices._name)

  const pet = usePet(st => st.data.pet);
  const services = useService(st => st.data.services);
  const serviceCare = useCare(st => st.data.care.services)
  const veterinaryPackageServices = usePet(st => st.data.pet.packages?.veterinary) || []

  if (!!pet?.id) {
    CLVetServices.init(serviceCare)
  }

  const totalPrice = useMemo(
    () => _.reduce(services, (acc, item) => acc + item.price, 0),
    [services]
  );

  return (
    <>
      <Container>
        <Header>
          <Title>Serviços do Atendimento</Title>
          <Action.Root
            outline
            name="view-service-packages"
            title='Ver Pacotes disponíveis'
            disabled={!veterinaryPackageServices.length || !isAble}
            onClick={CLVetServices.showAvailablePackages}
          >
            <Action.Icon icon={Package} />
          </Action.Root>
        </Header>
        <ServicesList />
        <Footer>
          <TotalServices>Total: <span>R$ {formatMoney(totalPrice, 2)}</span></TotalServices>
          <Action.Root
            status="success"
            name="save-care-services"
            title='Adicionar Serviços no atendimento'
            disabled={!isAble}
            onClick={CLVetServices.saveService}
          >
            <Action.Icon icon={Save} /> Salvar
          </Action.Root>
        </Footer>
      </Container>
      <AvailableServicePackages />
    </>
  );
}
