import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  width: 71px;
  height: 100%;
  background: ${(props) => props.theme.colors.background};
  position: sticky;
  left: 0;
  border-right: 1px solid ${(props) => props.theme.colors.primary};
  border-left: 1px solid ${(props) => props.theme.colors.primary};
  z-index: 5;
`

export const ScheduleHoursLine = styled.div`
  width: 100%;
  min-height: ${(props) => `calc(20px * ${props.totalGaps}.03)`};
  color: ${(props) => props.theme.colors.primary};
  padding: 5px 10px;
  &:first-child {
    border: none;
  }
`
