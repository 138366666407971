import { toast } from "react-toastify";

import { clearNumber } from "../../functions";

import { SubscriberModel } from "../../models/subscription";

import { Alert } from "../../../components/ui/Alert";
import { Drawer } from "../../../components/ui/Drawer";
import { PetController } from "../global";
import { useSubscribers } from "../../../store/subscription/useSubscribers";

export default class SubscriberController {
  currentSubscriber = {}

  alertIsOpen = Alert.store.getState().actions.isOpen;
  drawerIsOpen = Drawer.store.getState().actions.isOpen;

  constructor() {
    this.name = "subscriptions-subscribers";
    this.tableName = `${this.name}-table`;
    this.subscriptionDetailsDrawerName = `${this.name}-subscription-details-drawer`;

    this.paginationName = `${this.name}-pagination`;
    this.changeCreditCardAlertName = `${this.name}-changeCreditCard-alert`;
    this.changeSubscriptionPlanAlertName = `${this.name}-changePlan-alert`;
    this.cancelSubscriptionAlertName = `${this.name}-cancelSubscription-alert`;
    this.pauseSubscriptionAlertName = `${this.name}-pauseSubscription-alert`;
    this.cycleAppointmentsAlertName = `${this.name}-cycleAppointments-alert`;


    this.MSubscriber = SubscriberModel();

    this.init();
  }

  init = async () => {
    Promise.all([this.findSummary(), this.findSubscribers()]);
  };

  findSummary = async () => {
    await this.MSubscriber.findSummary();
  };

  findSubscribers = async () => {

    await this.MSubscriber.findSubscribers();
  };

  findCycleDetails = async (cyrcleId) => {
    await this.MSubscriber.findCycleDetails(cyrcleId);

    this.alertIsOpen(this.cycleAppointmentsAlertName, true);
  };

  findCycleDetails = async (cyrcleId) => {
    await this.MSubscriber.findCycleDetails(cyrcleId);

    this.openCycleAppointments()
  };

  openCycleAppointments = () => {
    this.alertIsOpen(this.cycleAppointmentsAlertName, true);
  };

  closeCycleAppointments = () => {
    this.alertIsOpen(this.cycleAppointmentsAlertName, false);
  };

  openChangePlan = () => {
    this.alertIsOpen(this.changePlanAlertName, true);
  };

  closeChangePlan = () => {
    this.alertIsOpen(this.changePlanAlertName, false);
  };

  setPage = (page) => {
    this.MSubscriber.setPage(page);
  };

  setFilter = (filterData) => {
    if (filterData.status === '*') filterData.status = ''
    if (filterData.size === '*') filterData.size = ''

    if (filterData.cpf) {
      filterData.cpf = clearNumber(filterData.cpf);
    }

    this.MSubscriber.setFilter(filterData);
  };

  filterSubscribers = async (e) => {
    if (e) e.preventDefault();
    await this.MSubscriber.filterSubscribers();
  };

  showDetails = (subscription) => {
    this.findDetails(subscription)

    this.drawerIsOpen(this.subscriptionDetailsDrawerName, true)
  };

  findDetails = async (subscription) => {
    const details = useSubscribers.getState().data.details

    if (!!details && details.id === subscription.id) return

    PetController(subscription.pet.id).startPet()

    await Promise.all([
      this.MSubscriber.findSubscription(subscription.id),
      this.MSubscriber.findAvailablePlans(subscription.pet.id),
    ])
  };

  closeDetailsDrawer = () => {
    this.drawerIsOpen(this.subscriptionDetailsDrawerName, false)
  };

  changeCreditCardSubscribers = async (subscriber, force = false) => {
    if (subscriber) this.currentSubscriber = subscriber;

    await this.findDetails(this.currentSubscriber)

    if (!force) {
      this.alertIsOpen(this.changeCreditCardAlertName, true);
      return;
    }

    const isSent = await this.MSubscriber.changeCreditCard(this.currentSubscriber.id, this.currentSubscriber.pet.id);

    if (!!isSent) toast.success("SMS Enviado para o cliente", { theme: "colored" });
  };

  cancelSubscribers = async (subscriber, force = false) => {
    if (subscriber) this.currentSubscriber = subscriber;

    await this.findDetails(this.currentSubscriber)

    if (!force) {
      this.alertIsOpen(this.cancelSubscriptionAlertName, true);
      return;
    }

    const isSent = await this.MSubscriber.cancelCreditCard(this.currentSubscriber.id);

    if (!!isSent) toast.success("SMS Enviado para o cliente", { theme: "colored" });
  };

  pauseSubscribers = async (subscriber, force = false) => {
    if (subscriber) this.currentSubscriber = subscriber;

    await this.findDetails(this.currentSubscriber)

    if (!force) {
      this.alertIsOpen(this.pauseSubscriptionAlertName, true);
      return;
    }

    const isSent = await this.MSubscriber.pausePlan(this.currentSubscriber.id);

    if (!!isSent) toast.success("SMS Enviado para o cliente", { theme: "colored" });
  };

  changeSubscriberPlan = async (subscriber, force = false) => {
    const details = useSubscribers.getState().data.details

    if (subscriber) this.currentSubscriber = subscriber;

    if (!force) {
      this.alertIsOpen(this.changeSubscriptionPlanAlertName, true);
      return;
    }

    const isSent = await this.MSubscriber.changePlan(details.id, this.currentSubscriber);
    if (!!isSent) toast.success("SMS Enviado para o cliente", { theme: "colored" });
  };

  reportExport = () => {
    this.MSubscriber.export();
  };
}
