import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import API from "../../../services/api";
import { getPetAttributes } from "../../../services/pets";
import _ from "lodash";
import { formatName } from "../../../services/functions";

function EditPet({ petData }) {
  const { customerId, value } = petData;
  const [getPet, setGetPet] = useState();
  const [ativo, setAtivo] = useState(false);

  // Filter initial value
  const especie = getPet?.attributes
    .filter((item) => item?.label === "Espécie")
    .map((val) => val.value)
    .toString();
  const raca = getPet?.attributes
    .filter((item) => item?.label === "Raça")
    .map((val) => val.value);
  const sexo = getPet?.attributes
    .filter((item) => item?.label === "Sexo")
    .map((val) => val.value)
    .toString();
  const peso = getPet?.attributes
    .filter((item) => item?.label === "Peso")
    .map((val) => val.value);
  const porte = getPet?.attributes
    .filter((item) => item?.label === "Porte")
    .map((val) => val.value)
    .toString();
  const pelagem = getPet?.attributes
    .filter((item) => item?.label === "Pelagem")
    .map((val) => val.value)
    .toString();
  const castrado = getPet?.attributes
    .filter((item) => item?.label === "Castrado")
    .map((val) => val.value);
  const status = getPet?.attributes
    .filter((item) => item?.label === "Status")
    .map((val) => val.value)[0];

  const handleGetPet = async () => {
    try {
      const { data } = await API.get(`pet/${value?.petId}`);

      setGetPet(data);
    } catch (err) {
      console.log("Erro:", err);
    }
  };

  const { register, handleSubmit, unregister, control } = useForm();
  const [attributes, setAttributes] = useState({});

  useEffect(() => {
    setAtivo(status === "Vivo");
  }, [status]);

  const onSubmit = async (data) => {
    const {
      name,
      Espécie,
      Raça,
      Sexo,
      Peso,
      Porte,
      Pelagem,
      Castrado,
      birthdate,
      comments,
      Status,
    } = data;

    const result = await API.put(`pet/${value.petId}`, {
      customerId,
      pet: {
        name,
        birthdate,
        comments,
        attributes: {
          Espécie,
          Raça,
          Sexo,
          Peso,
          Porte,
          Pelagem,
          Castrado: Castrado,
          Status: Status ? "Vivo" : "Morto",
        },
      },
    });

    if (result?.status === 201) {
      unregister();
      petData.callBack();
    }
  };

  const handleAttributes = async () => {
    const values = await getPetAttributes();
    setAttributes(values);
  };

  useEffect(() => {
    handleGetPet();
    handleAttributes();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Dados do Pet</h3>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="name"
            label="Nome do Pet"
            variant="outlined"
            defaultValue={value?.petName || value?.name}
            inputRef={register}
            required
          />
        </Grid>

        <Grid item xs={6}>
          {attributes["Espécie"] ? (
            <Controller
              control={control}
              defaultValue={especie}
              name="Espécie"
              as={
                <TextField
                  required
                  fullWidth
                  select
                  label="Espécie"
                  variant="outlined"
                >
                  {attributes["Espécie"] &&
                    attributes["Espécie"].map((value) => (
                      <MenuItem key={value._id} value={value.label}>
                        {value.label}
                      </MenuItem>
                    ))}
                </TextField>
              }
            />
          ) : (
            <Skeleton variant="rect" height={56} />
          )}
        </Grid>

        <Grid item xs={6}>
          {attributes["Raça"] ? (
            <Controller
              control={control}
              defaultValue={raca?.toString()}
              name="Raça"
              as={
                <TextField fullWidth select label="Raça" variant="outlined">
                  {attributes["Raça"] &&
                    attributes["Raça"].map((value) => (
                      <MenuItem key={value._id} value={value.label}>
                        {value.label}
                      </MenuItem>
                    ))}
                </TextField>
              }
            />
          ) : (
            <Skeleton variant="rect" height={56} />
          )}
        </Grid>

        <Grid item xs={6}>
          {attributes.Sexo ? (
            <Controller
              control={control}
              defaultValue={sexo}
              name="Sexo"
              as={
                <TextField
                  required
                  fullWidth
                  select
                  label="Sexo"
                  variant="outlined"
                >
                  {attributes.Sexo &&
                    attributes.Sexo.map((value) => (
                      <MenuItem key={value._id} value={formatName(value.label)}>
                        {formatName(value.label)}
                      </MenuItem>
                    ))}
                </TextField>
              }
            />
          ) : (
            <Skeleton variant="rect" height={56} />
          )}
        </Grid>

        {getPet ? (
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="Peso"
              label="Peso"
              variant="outlined"
              type="number"
              defaultValue={peso}
              inputRef={register}
              inputProps={{
                maxLength: 13,
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Skeleton variant="rect" height={56} />
          </Grid>
        )}

        <Grid item xs={6}>
          {attributes.Porte ? (
            <Controller
              control={control}
              defaultValue={porte}
              name="Porte"
              as={
                <TextField
                  required
                  fullWidth
                  select
                  label="Porte"
                  variant="outlined"
                >
                  {attributes.Porte.map((value) => (
                    <MenuItem key={value._id} value={formatName(value.label)}>
                      {formatName(value.label)}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          ) : (
            <Skeleton variant="rect" height={56} />
          )}
        </Grid>

        <Grid item xs={6}>
          {attributes.Pelagem ? (
            <Controller
              control={control}
              defaultValue={pelagem}
              name="Pelagem"
              as={
                <TextField
                  required
                  fullWidth
                  select
                  label="Pelagem"
                  variant="outlined"
                >
                  {attributes.Pelagem.map((value) => (
                    <MenuItem key={value._id} value={formatName(value.label)}>
                      {formatName(value.label)}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          ) : (
            <Skeleton variant="rect" height={56} />
          )}
        </Grid>

        <Grid item xs={6}>
          {attributes.Castrado ? (
            <Controller
              control={control}
              defaultValue={castrado}
              name="Castrado"
              as={
                <TextField
                  required
                  fullWidth
                  select
                  label="Castrado"
                  variant="outlined"
                >
                  {attributes.Castrado.map((value) => (
                    <MenuItem key={value._id} value={formatName(value.label)}>
                      {formatName(value.label)}
                    </MenuItem>
                  ))}
                </TextField>
              }
            />
          ) : (
            <Skeleton variant="rect" height={56} />
          )}
        </Grid>
        {getPet ? (
          <Grid item xs={6}>
            <Controller
              control={control}
              defaultValue={getPet?.birthdate}
              name="birthdate"
              as={
                <InputMask mask="99/99/9999">
                  {(inputProps) => (
                    <TextField
                      fullWidth
                      name="birthdate"
                      label="Nascimento"
                      variant="outlined"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              }
            />
          </Grid>
        ) : (
          <Skeleton variant="rect" height={56} />
        )}

        {getPet ? (
          <Grid item xs={12}>
            <TextField
              fullWidth
              defaultValue={getPet?.comments}
              name="comments"
              label="Observações"
              variant="outlined"
              inputRef={register}
              multiline
              rows={2}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Skeleton variant="rect" height={56} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Switch
            checked={ativo}
            onClick={() => {
              setAtivo(!ativo);
            }}
            color="primary"
            name="Status"
            inputRef={register}
          />
          Ativo
        </Grid>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          style={{
            background: "#CCD219",
            fontSize: "14px",
            textTransform: "capitalize",
            fontWeight: "700",
            width: "138px",
            marginRight: "20px",
          }}
        >
          Salvar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            petData.callBack();
          }}
          style={{
            border: "1px solid #CCD219",
            color: "#CCD219",
            textTransform: "capitalize",
            fontSize: "14px",
            width: "120px",
          }}
        >
          Cancelar
        </Button>
      </Grid>
    </form>
  );
}

export default EditPet;
