import { StyleSheet } from "@react-pdf/renderer";

export const container = StyleSheet.create({
  backgroundColor: 'transparent',
  padding: "16px 10px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  gap: "8px",
  fontFamily: "Helvetica"
})

export const content = StyleSheet.create({
  flex: '1',
  display: 'flex',
  padding: '0  10px'
})
