import { List, ListOrdered } from "lucide-react";
import { useContext } from "../Context";

import { MenuButton, MenuGroup } from "./styles";

export function MenuList() {
  const { editor } = useContext();

  return (
    <MenuGroup>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        <List size={18} />
      </MenuButton>

      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
      >
        <ListOrdered size={18} />
      </MenuButton>
    </MenuGroup>
  );
}
