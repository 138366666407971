import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 600px;
  padding: 40px 30px;
`

export const Ball = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-right: 5px;
  display: inline-block;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
`

export const DateInputt = styled.input`
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 2px solid #ccc;
  font-size: 16px;

  &:focus {
    border-bottom: 2px solid #000;
  }
`

export const Input = styled.input`
  padding: 8px;
  margin-bottom: 16px;
  width: 100%;
  max-width: 300px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-bottom: 1px solid #333;
  }
`

export const CancelButton = styled.button`
  background-color: #fff;
  border: 1px solid #ccd219;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #666666;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 150ms linear;
  &:hover {
    background-color: #ccd219;
    border: 1px solid #fff;
    color: #fff;
  }
`

export const SaveButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#ccc' : '#ccd219')};
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : '#e2e739')};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #ccc;
      opacity: 0.5;
      padding: 9px 16px;
      cursor: not-allowed;
    `}
`

export const TextError = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #ff0000;
`
