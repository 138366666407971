import React from 'react'

import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import Divider from '@material-ui/core/Divider'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/pickers
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ptBR from 'date-fns/locale/pt-BR'

// Material ui - core
import { Modal, Fade, Backdrop, TextField, MenuItem } from '@material-ui/core'

// Material ui - icones
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

// moment
import moment from 'moment'
import { MockAvailablesTimes } from '../../atoms/AvailableTimes/mock'
import API from '../../../services/api'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
  span: {
    fontSize: '90px',
    color: '#ccd219',
  },
  spanErr: {
    fontSize: '90px',
    color: 'red',
  },
  titleModal: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#5D6373',
  },
  descriptionModa: {
    fontStyle: 'normal',
    fontweight: '200',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
  },
  btnModal: {
    margin: '10px',
    background: '#CCD219',
    padding: '8px 25px',
    '&:hover': {
      background: '#CCD219',
      opacity: '0.6',
    },
  },
  textLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))

function AgendaModal({
  close,
  open,
  handleClose,
  serviceModal,
  id,
  date,
  url,
}) {
  const classes = useStyles()
  const history = useHistory()
  const [selectedDate, setSelectedDate] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [messageSuccess, setMessageSuccess] = React.useState(false)
  const [professional, setProfessional] = React.useState(null)
  const [employeeId, setEmployeeId] = React.useState(null)
  const [startsAt, setStartsAt] = React.useState(null)
  const availableTimes = MockAvailablesTimes?.availableTimes

  const { control } = useForm()

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const getProf = async () => {
    try {
      const { data } = await API.get(
        'employees/search?status=ATIVO&executesService=true',
      )
      setProfessional(data?.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  React.useEffect(() => {
    getProf()
  }, [])

  const handlePostDate = async () => {
    try {
      const result = await API.put(`service-order/appointment/${id}`, {
        date: selectedDate
          ? moment(selectedDate).format('YYYY-MM-DD')
          : moment(date).format('YYYY-MM-DD'),
        startsAt,
        employeeId,
      })

      if (result?.status === 201) {
        setSuccess(true)
        setMessageSuccess(result?.data?.message)
        setTimeout(() => {
          history.push(url)
        }, 1500)
      }
    } catch (err) {
      setSuccess(false)
      console.log('Erro:', err)
    }
  }

  return (
    <>
      {!serviceModal ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={close}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            style: { backdropFilter: 'blur(3px)' },
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <span id="transition-modal-title">
                <CancelIcon className={classes.spanErr} />
              </span>
              <p
                id="transition-modal-description"
                className={classes.titleModal}
              >
                Cancelar Agendamento
              </p>
              <span
                id="transition-modal-title"
                className={classes.descriptionModa}
              >
                Você tem certeza que deseja cancelar esse agendamento?
              </span>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ justifyContent: 'center' }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.btnModal}
                  onClick={() => handleClose()}
                >
                  Sim
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.btnModal}
                  onClick={() => close()}
                >
                  Não
                </Button>
              </Grid>
              <p
                id="transition-modal-description"
                className={classes.textLink}
                onClick={() => history.push(url)}
              >
                VOLTAR PARA AGENDA
              </p>
            </div>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={close}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              {success ? (
                <>
                  <span id="transition-modal-title">
                    <CheckCircleOutlineIcon className={classes.span} />
                  </span>
                  <p
                    id="transition-modal-description"
                    className={classes.titleModal}
                  >
                    {messageSuccess}
                  </p>
                </>
              ) : (
                <>
                  <p
                    style={{
                      color: '#666666',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Mudar data do serviço
                  </p>
                  <Divider style={{ marginBottom: '15px' }} />
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{ justifyContent: 'center', width: '350px' }}
                  >
                    <MuiPickersUtilsProvider
                      locale={ptBR}
                      utils={DateFnsUtils}
                      style={{ margin: '5px 0px', padding: '0' }}
                    >
                      <Controller
                        name="date"
                        rules={{ required: true }}
                        control={control}
                        defaultValue={new Date(moment(date))}
                        as={({ onChange, value }) => (
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Data"
                            format="yyyy-MM-dd"
                            value={value}
                            fullWidth
                            onChange={(e) => {
                              onChange(e)
                              handleDateChange(e)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        )}
                      />
                    </MuiPickersUtilsProvider>

                    <Grid
                      item
                      xs={12}
                      style={{ margin: '5px 0px', padding: '0' }}
                    >
                      <TextField
                        id="name"
                        select
                        fullWidth
                        label="Profissionais"
                        variant="outlined"
                        required
                        onChange={({ target }) => setEmployeeId(target.value)}
                      >
                        {professional
                          // .filter((time) => time.available)
                          ?.map((option) => (
                            <MenuItem
                              key={option?.employeeId}
                              value={option?.employeeId}
                              selected={option?.employeeId}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        margin: '5px 0px',
                        padding: '0',
                        paddingRight: '5px',
                      }}
                    >
                      <TextField
                        id="hora"
                        label="Horário"
                        name="hora"
                        variant="outlined"
                        required
                        fullWidth
                        select
                        onChange={({ target }) => setStartsAt(target.value)}
                      >
                        {availableTimes
                          // .filter((time) => time.available)
                          ?.map((option) => (
                            <MenuItem
                              key={option?.time}
                              value={option?.time}
                              selected={option?.time}
                            >
                              {option.time}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>

                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.btnModal}
                      onClick={() => handlePostDate()}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </>
              )}
            </div>
          </Fade>
        </Modal>
      )}
    </>
  )
}

export default AgendaModal
