import { StyleSheet } from "@react-pdf/renderer";
import { getCurrentThemeStyle } from "../../../../services/functions";

const theme = getCurrentThemeStyle()

export const container = StyleSheet.create({
  width: "100%",
  display: "flex",
  gap: "4px",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "flex-end",
})


export const highlight = StyleSheet.create({
  fontSize: "10px",
  color: theme.colors.scale[700]
})


export const separator = StyleSheet.create({
  fontSize: "10px",
  color: theme.colors.gray[500],
})

export const page = StyleSheet.create({
  fontSize: "10px",
  color: theme.colors.gray[500]
})
