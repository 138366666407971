import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: fit-content;
  padding: 0 12px;
  padding-bottom: 20px;
  flex-direction: column;
  gap: 40px;
  margin-top: 6px;
  &>div{
    width: 100%;
    display: flex;
    height: fit-content;
    flex-direction: column;
    gap: 10px;
  }
`

export const Title = styled.h2`
  font-size: 20px;
  display: flex;
  width: 100%;
  margin: 0;
  color: ${(props) => props.theme.colors.scale[600]};
`
