import { useEffect } from "react";
import { ListPreviewProvider } from "../Context";
import { ListContent } from "../ListContent";

import { useListPreview } from "../useListPreview";

import { Container } from "./styles";

export function ListPreviewRoot({
  name,
  list,
  children,
  placeholder = false,
  options = {},
  customAction = {},
  onSelected = () => null,
}) {
  const setListNew = useListPreview((st) => st.actions.setList);
  const setListData = useListPreview((st) => st.actions.setListData);

  const selected = useListPreview((st) => st.data.lists[name]?.selected);
  const preview = useListPreview((st) => st.data.lists[name]?.preview);

  const configCustomAction = {
    open: false,
    close: false,
    ...customAction,
  };



  setListNew(name, options, list);
  setListData(name, list);

  useEffect(() => {
    onSelected(selected);
  }, [selected]);

  return (
    <ListPreviewProvider
      listPreviewName={name}
      placeholder={placeholder}
      customAction={configCustomAction}
    >
      <Container showFileInfo={!!selected && !!preview}>
        {children}
        <ListContent />
      </Container>
    </ListPreviewProvider>
  );
}
