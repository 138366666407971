import _ from "lodash";

import { isAbleByStatus } from "../../../../../services/functions";

import { useCare } from "../../../../../store/service/veterinary/useCare";
import { useService } from "../../../../../store/service/veterinary/useService";

import { VeterinaryServiceController } from "../../../../../services/controllers/VeterinaryCare";

import { Table } from "../../../../../components/ui/Table";

import { FindService } from "./FindService";
import { PriceService } from "./PriceService";

import { Container, Line } from "./styles";

export function ServicesList() {
  const CLVetServices = VeterinaryServiceController();

  const careStatus = useCare((st) => st.data.care.status);
  const services = useService((st) => st.data.services);

  const isAble = isAbleByStatus(careStatus,CLVetServices._name)
  const addNewService = isAble ? CLVetServices.addService : false
  const removeNewService = isAble ? (serviceKey) => CLVetServices.deleteService(serviceKey) : null

  return (
    <Container>
      <Table.Root
        name="veterinary-care-services-table"
        disabled={isAble}
        style={{ minWidth: "100%", height: "auto", maxHeight: "100%" }}
        action={{
          add: addNewService,
          remove: removeNewService,
        }}
      >
        <Table.Header columns={["Serviços", "Valor:200px"]} />
        <Table.Content>
          {!!services &&
            Object.entries(services).map(([key, service],i) => (
              <Line
                id={key}
                key={key}
                data-spackage={key}
                data-package={service.isPackage}
                data-status={service.status}
                removed={service.removed || false}
                returned={service.returned || false}
                style={{ zIndex: _.size(services) - i }}
              >
                <Table.Item>
                  <FindService serviceKey={key} />
                </Table.Item>
                <Table.Item>
                  <PriceService serviceKey={key} />
                </Table.Item>
              </Line>
            ))}
        </Table.Content>
      </Table.Root>
    </Container>
  );
}
