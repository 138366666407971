import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  opacity: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  padding: 0 2px;
  min-height: 20px;
  z-index: 1;
  cursor: grab;
  position: absolute;
  height: ${(props) => props.size}px;
  width: ${(props) => props.w}%;
  left: ${(props) => props.l}%;
  top: ${(props) => props.top}px;
  /* border: 1px solid; */
  &:hover {
    z-index: 4;
  }
  ${(props) =>
    props.isDragging &&
    css`
      & > div {
        opacity: 0.7;
        background: transparent;
        box-shadow: none;
        cursor: grabbing;
        border: 3px dashed
          ${(props) => props.theme.colors.scheduleSteps[props.status]};
        .time,
        .pet,
        .customer,
        .description {
          visibility: hidden;
        }
      }
    `}
`
