import Mustache from "mustache";
import { ClipboardList } from "lucide-react";

import { VeterinaryRequestController } from "../../../../../../services/controllers/VeterinaryCare";
import { useRequest } from "../../../../../../store/service/veterinary/useRequest";

import { Drawer } from "../../../../../../components/ui/Drawer";

import { DocumentsHeader } from "../../../components/DocumentsHeader";

import { Container, PreviewContent, PreviewRequest, Title } from "./styles";
import { templatesFunctions } from "../../../../../../services/templates/functions";

export function RequestPreview() {
  const CLVetRequest = VeterinaryRequestController();

  const requestData = useRequest((st) => st.data.request.requestData);
  const values = useRequest((st) => st.data.request.requestValues);

  const isOpen = Drawer.store(st => st.data.drawers[CLVetRequest._drawerName].open);

  const incompleteRegistrationWarningContent = CLVetRequest.getRequestWarningsContent('incompleteRegistration')

  const fields = {
    ...templatesFunctions.get.partialsFieldsFormat(requestData),
    ...templatesFunctions.get.fieldsFormat(requestData.fields),
  };

  const addBreakLines = templatesFunctions.addBreakLines(values);

  const formattedValues = {
    ...CLVetRequest._defaultRequest,
    ...templatesFunctions.format(fields, addBreakLines),
  };

  const contentData = !!requestData.content
    ? Mustache.render(
      requestData.content,
      formattedValues,
      CLVetRequest._partialsContent
    )
    : "";

  return (
    <Container>
      <Title>
        <ClipboardList size={24} /> Prévia do Documento
      </Title>

      {isOpen && <DocumentsHeader name="request" warning={incompleteRegistrationWarningContent} />}

      <PreviewContent>
        <PreviewRequest dangerouslySetInnerHTML={{ __html: contentData }} />
      </PreviewContent>
    </Container>
  );
}
