import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const StatusBadge = styled.span`
  background: rgb(255, 0, 0);
  padding: 4px 16px;
  border-radius: 99999px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 10px;
  border: 1px solid rgba(220, 220, 220, 0.4);
  background: ${props=>props.background}
`
