import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'

import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
  },
  avatar: {
    backgroundColor: '#CCD219',
    fontSize: '50px',
    height: '90px',
    width: '90px',
  },
  petName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#CCD219',
    color: '#fff',
    fontWeight: 'bold',
  },
  title: {
    color: '#ccc',
  },
  total: {
    textAlign: 'center',
    color: '#666666',
    fontWeight: '400',
    display: 'block',
    fontSize: '18px',
    marginBottom: '-15px',
  },
  price: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    textAlign: 'center',
    width: '100%',
    fontWeight: '700',
    display: 'block',
    padding: '10px',
    fontSize: '36px',
    color: '#666666',
  },
  tabs: {
    marginBottom: '20px',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
  },
  noPackage: {
    color: '#f33838',
    fontWeight: 'bold',
  },
  colorCpf: {
    color: 'red',
  },
  autoContainer: {
    alignItems: 'center',
    paddingBottom: '10px',
    paddingTop: '8px',
  },
  searchContainer: {
    padding: '10px',
    maxHeight: '400px',
    overflowY: 'scroll',
    width: '100%',
    alignItems: 'center',
  },
  searchItem: {
    display: 'flex',
    borderBottom: '1px solid #efefef',
    padding: '15px 10px !important',
  },
  autoAvatar: {
    textTransform: 'capitalize',
    width: '48px',
    height: '48px',
    backgroundColor: '#ccd219',
  },
  assinante: {
    fontSize: '8px',
    background: '#f50057',
    color: '#fff',
    padding: '3px 6px',
    borderRadius: '37px',
    marginLeft: '20px',
  },
  autoFirstP: {
    margin: '0',
    textTransform: 'capitalize',
    fontSize: '18px',
    fontWeight: '500',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    color: '#ccd219',
    display: 'flex',
  },
  autoIcon: {
    fontSize: '16px',
    marginRight: '5px',
  },
  autoLastP: {
    margin: '0',
    color: '#666',
    fontSize: '14px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  teste: {
    marginBottom: '5px',

    '& input': {
      padding: '15px',
      fontSize: '12px',
    },
    '& label': {
      top: '-4px',
    },
  },
  clasIcon: {
    backgroundColor: '#ccd219',
    color: '#fff',
    borderRadius: '5px',
    padding: '9px',
    '&:hover': {
      color: '#ccd219',
    },
  },
}))

function Customer({ customer, order, setCustomer, handleReset }) {
  const classes = useStyles()

  const handleClose = () => {
    setCustomer(null)
    handleReset()
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={3} className={classes.avatarContainer}>
        {customer?.pet?.subscriptionStatus === 'Ativo' ? (
          <Avatar
            className={classes.avatar}
            src={customer.pet.subscriptionImgUrl}
          />
        ) : (
          <>
            {customer?.pet?.petPhoto ? (
              <Avatar className={classes.avatar} src={customer.pet.petPhoto} />
            ) : (
              <Avatar className={classes.avatar}>
                {customer.pet?.name?.substr(0, 1)}
              </Avatar>
            )}
          </>
        )}
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={12}>
          <span className={classes.petName}>{customer.pet?.name}</span>
        </Grid>
        <Grid item xs={12}>
          {customer.owner}
        </Grid>
        <Grid item xs={12}>
          {customer.email}
        </Grid>
        <Grid item xs={12}>
          {customer.phone}
        </Grid>
      </Grid>

      <Grid item xs={1}>
        {!order && (
          <IconButton onClick={() => handleClose()}>
            <HighlightOffIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default Customer
