
import { twMerge } from 'tailwind-merge'

export function CardHeaderDescription({
  className,
  ...rest
}) {
  return (
    <p
      className={twMerge(
        'w-full px-2 text-right text-sm text-black',
        className,
      )}
      {...rest}
    />
  )
}
