import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import PetsIcon from '@material-ui/icons/Pets'
import StarIcon from '@material-ui/icons/Star'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons//Add'
import CircularProgress from '@mui/material/CircularProgress'
import { getPets } from '../../../services/pets'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
  },
  avatar: {
    backgroundColor: '#CCD219',
    fontSize: '50px',
    height: '90px',
    width: '90px',
  },
  petName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#CCD219',
    color: '#fff',
    fontWeight: 'bold',
  },
  title: {
    color: '#ccc',
  },
  total: {
    textAlign: 'center',
    color: '#666666',
    fontWeight: '400',
    display: 'block',
    fontSize: '18px',
    marginBottom: '-15px',
  },
  price: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    textAlign: 'center',
    width: '100%',
    fontWeight: '700',
    display: 'block',
    padding: '10px',
    fontSize: '36px',
    color: '#666666',
  },
  tabs: {
    marginBottom: '20px',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
  },
  noPackage: {
    color: '#f33838',
    fontWeight: 'bold',
  },
  colorCpf: {
    color: 'red',
  },
  autoContainer: {
    alignItems: 'center',
    paddingBottom: '10px',
    paddingTop: '8px',
  },
  searchContainer: {
    padding: '10px',
    maxHeight: '400px',
    overflowY: 'scroll',
    width: '100%',
    alignItems: 'center',
  },
  searchItem: {
    display: 'flex',
    borderBottom: '1px solid #efefef',
    padding: '15px 10px !important',
  },
  autoAvatar: {
    textTransform: 'capitalize',
    width: '48px',
    height: '48px',
    backgroundColor: '#ccd219',
  },
  assinante: {
    fontSize: '8px',
    background: '#f50057',
    color: '#fff',
    padding: '3px 6px',
    borderRadius: '37px',
    marginLeft: '20px',
  },
  autoFirstP: {
    margin: '0',
    textTransform: 'capitalize',
    fontSize: '18px',
    fontWeight: '500',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    color: '#ccd219',
    display: 'flex',
  },
  autoIcon: {
    fontSize: '16px',
    marginRight: '5px',
  },
  autoLastP: {
    margin: '0',
    color: '#666',
    fontSize: '14px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  teste: {
    marginBottom: '5px',

    '& input': {
      padding: '15px',
      fontSize: '12px',
    },
    '& label': {
      top: '-4px',
    },
  },
  clasIcon: {
    backgroundColor: '#ccd219',
    color: '#fff',
    borderRadius: '5px',
    padding: '9px',
    '&:hover': {
      color: '#ccd219',
    },
  },
}))

function SidebarPet(props) {
  const { register, handleSubmit } = useForm()
  const classes = useStyles()
  const [customers, setCustomers] = useState([])
  const [customersLoading, setCustomersLoading] = useState(true)
  const [validateArray, setValidateArray] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [validationButton, setValidationButton] = useState(false)

  const handleSearch = async (data) => {
    setValidationButton(true)
    setCustomers([])
    try {
      const result = await getPets(
        data?.cpf,
        data?.name,
        data?.pet,
        data?.cellphone,
      )
      setCustomers(result)
      setValidateArray(result)
      setCustomersLoading(!result.length)
      setErrMsg(false)
      setValidationButton(false)
    } catch (err) {
      console.log('Erro:', err)
      setValidateArray(false)
      setErrMsg('Cliente não encontrado.')
      setValidationButton(false)
    }
  }

  const handleCustomer = (value) => {
    props.setCustomer(value)
    props.handleNext()
  }

  return (
    <Grid container spacing={1} className={classes.autoContainer}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Grid container spacing={1} className={classes.autoContainer}>
          <Grid item xs={5}>
            <TextField
              className={classes.teste}
              fullWidth
              name="cpf"
              label="CPF"
              variant="outlined"
              inputRef={register}
            />

            <TextField
              className={classes.teste}
              fullWidth
              name="name"
              label="Tutor"
              variant="outlined"
              inputRef={register}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              className={classes.teste}
              fullWidth
              name="cellphone"
              label="Telefone"
              variant="outlined"
              inputRef={register}
            />

            <TextField
              className={classes.teste}
              fullWidth
              name="pet"
              label="Pet"
              variant="outlined"
              inputRef={register}
            />
          </Grid>

          <Grid item xs={2}>
            {validationButton ? (
              <CircularProgress
                style={{ color: '#ccd219', width: '30px', height: '30px' }}
              />
            ) : (
              <IconButton
                className={classes.clasIcon}
                aria-label="search"
                type="submit"
              >
                <SearchIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>

      {!customersLoading && (
        <Grid container spacing={1} className={classes.searchContainer}>
          {customers?.map((option) => (
            <Grid
              item
              xs={12}
              key={option.label}
              className={classes.searchItem}
            >
              <Grid item xs={2}>
                {option?.pet?.subscriptionStatus === 'Ativo' ? (
                  <Avatar
                    className={classes.autoAvatar}
                    src={option.pet.subscriptionImgUrl}
                  />
                ) : (
                  <>
                    {option?.pet?.petPhoto ? (
                      <Avatar
                        className={classes.autoAvatar}
                        src={option.pet.petPhoto}
                      />
                    ) : (
                      <Avatar className={classes.autoAvatar}>
                        {option?.label?.substr(0, 1)}
                      </Avatar>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={8}>
                <p className={classes.autoFirstP}>
                  {' '}
                  <PetsIcon className={classes.autoIcon} /> {option.label}{' '}
                  {option?.pet?.subscriptionStatus === 'Ativo' && (
                    <span className={classes.assinante}>ASSINANTE</span>
                  )}
                </p>
                <p className={classes.autoLastP}>
                  {option.fullName}{' '}
                  {option.alreadyClient ? (
                    <StarIcon fontSize="small" color="secondary" />
                  ) : (
                    ''
                  )}{' '}
                </p>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  size="large"
                  onClick={() => handleCustomer(option)}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {validateArray?.length === 0 && (
        <div
          style={{
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            color: 'red',
          }}
        >
          <p style={{ textAlign: 'center' }}>Cliente sem pet cadastrado</p>
          <p
            style={{ textAlign: 'center', color: '#ccd219', cursor: 'pointer' }}
            className={classes.textLink}
          >
            CADASTRAR NOVO PET
          </p>
        </div>
      )}

      {errMsg && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            color: 'red',
          }}
        >
          <p>{errMsg}</p>
        </div>
      )}
    </Grid>
  )
}

export default SidebarPet
