import { StyleSheet } from "@react-pdf/renderer";

import { getCurrentThemeStyle } from "../../../../services/functions";

const theme = getCurrentThemeStyle()
export const container = StyleSheet.create({
  display: 'flex',
  flexDirection: 'column',
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "20px",
  padding: "0",
  marginBottom: '10px',
  padding: "0 10px 10px 10px",
  borderBottom: `1px solid ${theme.colors.scale[100]}`,
})
