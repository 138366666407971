import { Gift, Hamburger } from '@phosphor-icons/react'
import { Card } from '../components/Card'

import { Service } from '../components/Service'
import { SchedulingHeader } from '../components/SchedulingHeader'
import { Header } from '../components/Header'
import { Signature } from '../components/Signature'
import { PetStatus } from '../components/PetStatus'

import { Pet } from './Pet'
import { Customer } from './Customer'

export function Store() {
  return (
    <>
      <Header.Simple />
      <Card.Root>
        <SchedulingHeader />
        <Card.Main>
          <Customer />
          <Pet />
          <Service />
          <PetStatus />
          <Card.Content>
            <Signature.Customer />
            <Signature.Store />
          </Card.Content>
        </Card.Main>
      </Card.Root>
    </>
  )
}
