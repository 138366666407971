export const VACCINATION_STATUS_NAME = {
  programmed: ["Programada", "Programadas", "Programado", "Programados"],
  scheduled: ["Agendada", "Agendadas", "Agendado", "Agendados"],
  applied: ["Aplicada", "Aplicadas", "Aplicado", "Aplicados"],
  canceled: ["Cancelada", "Canceladas", "Cancelado", "Cancelados"],
};

export const VACCINATION_STATUS = {
  "VACINA PROGRAMADA": "programmed",
  AGENDADO: "scheduled",
  APLICADA: "applied",
  CANCELADO: "canceled",
};

export const VACCINATION_STATUS_ACTIONS = {
  programmed: ["edit", "cancel", "send"], //"send"
  scheduled: ["see", "cancel"], //"send"
  applied: ["see"], //"send"
  canceled: [], //"send"
};
