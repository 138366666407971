import { memo } from "react";

import { Alert } from "../../../../../components/ui/Alert";

import { getArticles } from "../../../../../services/functions";

import { VeterinaryRequestController } from "../../../../../services/controllers/VeterinaryCare";
import { useRequest } from "../../../../../store/service/veterinary/useRequest";
import { usePet } from "../../../../../store/global/usePet";

export const IncompleteRequest = memo(() => {
  const pet = usePet((st) => st.data.pet);
  const request = useRequest((st) => st.data.request.requestData);

  const articles = getArticles(pet.formattedAttributes?.gender);
  const requestArticles = getArticles(request.gender);

  const CLVetRequest = VeterinaryRequestController();

  if (!pet.id) return null;

  return (
    <Alert.Root name={CLVetRequest._incompleteAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Dados não Salvos</Alert.Title>
        <Alert.Description>
          <p>
            Atenção! {requestArticles[0]} <strong>{request?.name} </strong>
            {articles[1]} <strong> {pet.formattedAttributes?.name} </strong>
            ainda não foi salvo. Se você sair agora, todas as modificações
            realizadas serão perdidas. Tem certeza de que deseja sair sem
            salvar?
          </p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm
            onClick={() => {
              CLVetRequest.closeDrawer(true);
            }}
          >
            Sim, sair
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
