import { useLocation } from 'react-router-dom'

import { Store } from './checklist/Store'
import { Client } from './checklist/Client'
import { TermsService } from './checklist/TermsService'
import { PrintImage } from '../../../../services/builders/PrintImage'


export function Printer() {
  const padding = PrintImage.position();

  const { search } = useLocation()
  const showPrinter = new URLSearchParams(search).get('showPrinter')
  const hiden = !showPrinter ? 'left-full top-full' : 'left-0 top-0 z-[100]'

  return (
    <div className='flex gap-8 flex-col'>
      <div className={`flex gap-8 w-screen fixed items-start ${hiden}`}>
        <div role="print-page" className='w-[300px] bg-white flex flex-col' style={{ padding }}>
          <TermsService />
        </div>
        <div role="print-page" className='w-[300px] bg-white flex flex-col' style={{ padding }}>
          <Store />
        </div>
        <div role="print-page" className='w-[300px] bg-white flex flex-col' style={{ padding }}>
          <Client />
        </div>
      </div>
    </div >
  )
}
