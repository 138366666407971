import CountUp from 'react-countup'

import { useAssessments } from '../../../../hooks/AssessmentsContext'
import {
  Container,
  StartsAverageTitle,
  StartsAverageStats,
  StartsTotalResponse,
} from './styles'

export function StartsAverage() {
  const { getStarScores } = useAssessments()

  const starScores = getStarScores()
  const average = starScores ? 20 * starScores.store : 0

  return (
    <Container>
      <StartsAverageTitle>
        <CountUp
          decimals={2}
          decimal=","
          end={starScores && starScores.store}
        />
      </StartsAverageTitle>
      <StartsAverageStats average={average} />
      <StartsTotalResponse>
        Petland Brasil: {starScores && starScores.franchisor}
      </StartsTotalResponse>
    </Container>
  )
}
