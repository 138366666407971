import { useState } from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'

import { Close, Person, Pets } from '@mui/icons-material'
import { Portal } from '@radix-ui/react-popover'
import { MenuItem, TextField } from '@material-ui/core'

import { useThemes } from '../../../../../../../../providers/theme'
import { useSchedule } from '../../../../../../../../hooks/ScheduleContext'
import {
  HmsToseconds,
  calendarStatusIfTheme,
  secondsToHms,
} from '../../../../../../../../services/functions'

import { PickerDay } from './PickerDay'

import {
  Container,
  Arrow,
  CloseButton,
  Header,
  HeaderAvatar,
  ProfileBox,
  HeaderTitle,
  Information,
  InformationHeader,
  InformationTime,
  InformationService,
  InformationData,
  InformationDataBadge,
  InformationEdit,
  InformationEditLine,
  InformationEditInput,
  ActionButton,
  CancelButton,
  InformationEditLineCalendar,
  InformationEditActionLine,
  ThemeButton,
} from './styles'

export function SchedulePopover({ id }) {
  const { theme } = useThemes()
  const history = useHistory()
  const {
    getBubbleById,
    getEmployees,
    patchSchedule,
    getConfig,
    updateScheduleStatus,
  } = useSchedule()

  const bubble = getBubbleById(id)
  const config = getConfig()
  const employees = getEmployees()

  const statusByTheme = calendarStatusIfTheme(theme, bubble.status)

  const [scheduledData, setScheduledData] = useState({
    date: bubble.date,
    startsAt: secondsToHms(bubble.startsAt).formated,
    finishesAt: secondsToHms(bubble.finishesAt).formated,
    employeeId: bubble.employeeId,
  })

  const save = () => {
    if (
      scheduledData.startsAt !== bubble.startsAt ||
      scheduledData.finishesAt !== bubble.finishesAt ||
      scheduledData.employeeId !== bubble.employeeId ||
      scheduledData.room !== bubble.roomId ||
      scheduledData.date !== bubble.date
    ) {
      const update =
        theme === 'petland'
          ? {
            startsAt: HmsToseconds(scheduledData.startsAt),
            finishesAt: HmsToseconds(scheduledData.finishesAt),
            employeeId: scheduledData.employeeId,
            date: scheduledData.date,
          }
          : {
            startsAt: HmsToseconds(scheduledData.startsAt),
            finishesAt: HmsToseconds(scheduledData.finishesAt),
            roomId: scheduledData.employeeId,
            date: scheduledData.date,
          }
      patchSchedule(id, update)
    }
  }
  const scheduleStart = () => {
    switch (theme) {
      case 'draMei':
        return (
          <InformationEditActionLine>
            <ThemeButton
              scale="700"
              onClick={() => {
                updateScheduleStatus('AGUARDANDO ATENDIMENTO', bubble)
              }}
            >
              Informar Chegada
            </ThemeButton>
          </InformationEditActionLine>
        )
      case 'petland':
        return (
          <InformationEditActionLine>
            <ThemeButton
              scale="500"
              onClick={() => {
                history.push(
                  `${config.links.checkIn}/${bubble.appointmentOrderId}`,
                )
              }}
            >
              Check In
            </ThemeButton>
          </InformationEditActionLine>
        )
      default:
    }
  }

  return (
    <Portal>
      <Container
        side="left"
        sideOffset={5}
        className={statusByTheme?.code || 'checkIn'}
        hideWhenDetached
        collisionPadding={{ top: 20, left: 80 }}
      >
        <Header>
          <HeaderAvatar>{bubble.petName.charAt(0)}</HeaderAvatar>
          <ProfileBox>
            <HeaderTitle>
              <Pets /> {bubble.petName}
            </HeaderTitle>
            <HeaderTitle className="subTitle">
              <Person /> {bubble.customerName}
            </HeaderTitle>
          </ProfileBox>
        </Header>
        <Information>
          <InformationHeader>
            <InformationTime>
              <span>{moment(config.date).format('DD/MM/YYYY')}</span>
              <span>
                {secondsToHms(bubble.startsAt).formated} -{' '}
                {secondsToHms(bubble.finishesAt).formated}
              </span>
            </InformationTime>
          </InformationHeader>
          <InformationService>
            {bubble.services.map((service) => (
              <p key={service}>{service.toLowerCase()}</p>
            ))}
          </InformationService>
          <InformationData>
            {bubble.isPackage && (
              <InformationDataBadge>Pacote</InformationDataBadge>
            )}
            {bubble.isSubscription && (
              <InformationDataBadge>Assinante</InformationDataBadge>
            )}
          </InformationData>
          {statusByTheme?.code === 'scheduled' && scheduleStart()}
          <InformationEdit className={statusByTheme?.code || 'checkIn'}>
            <InformationEditLine>
              <InformationEditInput
                mask="99:99"
                value={scheduledData.startsAt}
                onChange={(e) => {
                  setScheduledData((prev) => ({
                    ...prev,
                    startsAt: e.target.value,
                  }))
                }}
              >
                {(inputProps) => (
                  <TextField
                    fullWidth
                    name="startsAt"
                    label="Início"
                    variant="outlined"
                    {...inputProps}
                  />
                )}
              </InformationEditInput>
              <InformationEditInput
                mask="99:99"
                value={scheduledData.finishesAt}
                onChange={(e) => {
                  setScheduledData((prev) => ({
                    ...prev,
                    finishesAt: e.target.value,
                  }))
                }}
              >
                {(inputProps) => (
                  <TextField
                    fullWidth
                    name="finishesAt"
                    label="Fim"
                    variant="outlined"
                    {...inputProps}
                  />
                )}
              </InformationEditInput>
            </InformationEditLine>
            {statusByTheme?.code === 'scheduled' && (
              <InformationEditLineCalendar>
                <PickerDay
                  scheduledId={id}
                  onChange={(e) => {
                    setScheduledData((prev) => ({
                      ...prev,
                      date: moment(e).format('YYYY-MM-DD'),
                    }))
                  }}
                />
              </InformationEditLineCalendar>
            )}
            <InformationEditLine style={{ gridArea: 'e' }}>
              <TextField
                fullWidth
                select
                name="employeeId"
                label={theme === 'petland' ? 'Profissional' : 'Sala'}
                variant="outlined"
                defaultValue={scheduledData.employeeId}
                onChange={(e) => {
                  setScheduledData((prev) => ({
                    ...prev,
                    employeeId: e.target.value,
                  }))
                }}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                ))}
              </TextField>
            </InformationEditLine>
            <InformationEditActionLine>
              <ActionButton onClick={save}>Salvar</ActionButton>
              <CancelButton>Cancelar</CancelButton>
            </InformationEditActionLine>
          </InformationEdit>
        </Information>
        <CloseButton aria-label="Close">
          <Close />
        </CloseButton>
        <Arrow />
      </Container>
    </Portal>
  )
}
