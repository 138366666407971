import { useCallback } from "react";
import { InputText } from "../styles";
import { formatMoney } from "../../../../../services/functions";

export function InputTypeNumber({
  value,
  suffix = '',
  prefix = '',
  decimals = 0,
  onChange: onChangeTrigger = () => null,
  ...rest
}) {
  const divider = 10 ** decimals;

  const formatValue = useCallback(
    (number) => {
      const cleanedNUmber = number
        .toFixed(decimals)
        .toString()
        .replace(/\D/g, "");

      const formatedNUmber = formatMoney(cleanedNUmber / divider, decimals);
      return `${prefix}${formatedNUmber}${suffix}`;
    },
    [prefix, suffix, decimals, divider]
  );

  const changeValue = useCallback(
    (number) => {
      const value = +number.replace(/[^0-9]+/g, "") / divider;

      onChangeTrigger(value);
    },
    [onChangeTrigger]
  );

  const backspace = (e) => {
    if(e.keyCode === 8){
      e.preventDefault()
      const numbers = e.target.value.replace(/[^0-9]+/g, "")
      const value = +numbers.slice(0, -1) / divider;

      onChangeTrigger(value);
    }
  }

  value = typeof value === "number" ? formatValue(value) : value;

  return (
    <InputText
      {...rest}
      value={value}
      onChange={(e) => changeValue(e.target.value)}
      onKeyDown={backspace}
      placeholder=" "
    />
  );
}
