import _ from "lodash";
import uuid from "react-uuid";

import { useService } from "../../../store/service/veterinary/useService";
import { usePet } from "../../../store/global/usePet";

import { VeterinaryServiceModel } from "../../models/VeterinaryCare";

import { FuseBuilder } from "../../builders";

import { Alert } from "../../../components/ui/Alert";
import { PetController } from "../global";

export default class VeterinaryServiceController {
  _name = 'veterinary-services';
  _label = 'Serviços';
  _department = "veterinary";

  _availableServicePackagesAlertName = `${this._name}-available-packages-alert`

  _searchServiceName = `${this._name}-search`
  _searchLimit = 10
  _searchServiceOptions = {
    minMatchCharLength: 1,
    threshold: 0.6,
    keys: [
      { name: 'name', weight: 2 },
      { name: 'code', weight: 1 }
    ]
  }
  _searchSuggestionParser = {
    id: 'id', value: 'suggestionName'
  }

  _defaultService = {
    name: null,
    code: "",
    price: 0,
    defaultDuration: null,
    durations: "",
    filterString: "",
    suggestionName: "",
    isPackage: false,
    status: 'empty',
    foundServices:[]
  };

  alertIsOpen = Alert.store.getState().actions.isOpen;

  setLoadingStatus = useService.getState().actions.setLoadingStatus;

  setServiceList = useService.getState().actions.setServices;
  setDepartmentServices = useService.getState().actions.setDepartmentServices;

  setFoundServices = useService.getState().actions.setFoundServices;
  updateServiceValues = useService.getState().actions.updateService;

  removeService = useService.getState().actions.removeService;
  returnService = useService.getState().actions.returnService;
  excludeService = useService.getState().actions.deleteService;

  constructor(orderId, saveInStore = true, label = false) {
    this._orderId = orderId;

    this._saveInStore = saveInStore
    this._label = label || this._label

    this.BServiceFuse = FuseBuilder(this._searchServiceName, [], this._searchServiceOptions);

    this.MVetService = VeterinaryServiceModel(this._orderId);
  }

  init = _.once(async (services = []) => {
    await this.findServices();
    this.startGlobals();
    this.setStartServices(services);
  });

  startGlobals = () => {
    const pet = usePet.getState().data.pet;
    if (!pet.id) return

    this.CPet = PetController(pet.id)
  }

  showAvailablePackages = () => {
    this.alertIsOpen(this._availableServicePackagesAlertName, true);
  }

  findServices = async () => {
    this.setLoadingStatus(true)
    const pet = usePet.getState().data.pet;
    const services = await this.MVetService.getServices(pet.id);

    this.BServiceFuse.setList(services)
    this.BServiceFuse.init()
    this.setDepartmentServices(services);
    this.setLoadingStatus(false)
  };

  searchService = (serviceKey, term) => {
    if (!serviceKey) return

    const result = this.BServiceFuse.search(term, this._searchLimit)

    this.setFoundServices(serviceKey, _.map(result, 'item'))
  }

  selectService = (serviceKey, serviceId) => {
    const departmentServices = useService.getState().data.departmentServices;
    const serviceData = departmentServices.find(service => service.id === serviceId)

    this.setServices({ ...serviceData, status: 'new' }, serviceKey);
  };

  setServiceFieldsValue = (serviceKey, values) => {
    this.updateServiceValues(serviceKey, { ...values, status: 'new' });
  };

  setStartServices = (services) => {
    if (!_.isArray(services)) return

    const departmentServices = useService.getState().data.departmentServices;
    const startServices = services.map(service => {
      const serviceData = _.find(departmentServices, { id: service.id });

      return {
        ...serviceData,
        status: 'inCare',
        price: service.price,
        serviceOrderId: service.serviceOrderId
      }
    })

    this.setServiceList(startServices)
  }

  setServices = (serviceData, key = null) => {
    if (_.isEmpty(serviceData)) return

    const veterinaryPackageServices = usePet.getState().data.pet.packages?.veterinary
    const serviceKey = key || uuid();

    const newServices = _.concat([], serviceData).map(service => {
      const isServicePackage = !!_.find(veterinaryPackageServices, { id: service.id });

      if (!isServicePackage) return service

      const servicePackageData = this.removePackageService(service)

      return {
        ...service,
        ...servicePackageData,
      }
    })

    this.setServiceList(newServices, serviceKey);
  }

  addService = () => {
    this.setServices(this._defaultService);
  };

  addServiceFromServicePackage = (packageService) => {
    if (!packageService.quantityAvailable) return

    const departmentServices = useService.getState().data.departmentServices;
    const serviceData = departmentServices.find(service => service.id === packageService.id)

    this.setServices({ ...serviceData, price: 0, status: 'new', isPackage: true });
  };

  removePackageService = (service) => {
    const servicesPackage = usePet.getState().data.pet.packages?.veterinary
    if(!servicesPackage) return

    const packages = servicesPackage.map(packageService => {
      if (service.serviceOrderId) return packageService
      if (packageService.id !== service.id) return packageService


      return {
        ...packageService,
        quantityAvailable: packageService.quantityAvailable - 1
      }
    })

    this.CPet.setServicesPackage('veterinary', packages)

    return {
      ...service,
      hasRemoved: true,
      isServicePackage: true
    }
  }

  addPackageService = (service) => {
    const servicesPackage = usePet.getState().data.pet.packages?.veterinary

    if(!servicesPackage) return service

    const packages = servicesPackage.map(packageService => {
      if (!service.hasRemoved) return packageService
      if (packageService.id !== service.id) return packageService

      return {
        ...packageService,
        quantityAvailable: packageService.quantityAvailable + 1
      }
    })

    this.CPet.setServicesPackage('veterinary', packages)

    return {
      ...service,
      hasRemoved: false,
    }
  }

  deleteService = async (serviceKey) => {
    const service = useService.getState().data.services[serviceKey];

    this.removeService(serviceKey);

    if (!service.id || !service.serviceOrderId) {
      this.addPackageService(service)
      setTimeout(() => this.excludeService(serviceKey), 550);
      return
    }

    const response = await this.MVetService.deleteServices(service.id);

    if (!response) {
      this.returnService(serviceKey);
      return;
    }

    this.addPackageService(service)
    this.excludeService(serviceKey);
  };

  applyServices = () => {
    const services = useService.getState().data.services;

    Object.keys(services).forEach(serviceKey => {
      if (services[serviceKey].status === 'inCare') return

      if (services[serviceKey].status === 'empty') {
        this.excludeService(serviceKey);
        return
      }

      this.updateServiceValues(serviceKey, { status: 'inCare' })
    })
  };

  saveService = async () => {
    const services = useService.getState().data.services;
    const arrayServices = _.map(services).filter(service => !service.removed && service.status !== 'empty')

    this.applyServices()
    await this.MVetService.saveServices(arrayServices);
  };
}
