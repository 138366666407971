import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  border-radius: 10px;
  overflow: hidden;
  height: 100%;

  height: 100%;
  display: flex;
`
export const Months = styled.div`
  height: 100%;
  padding: 0;
  overflow-y: auto;
`

export const Month = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
export const MonthHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 15px 0px 15px;
`

export const MonthHeaderItens = styled.div`
  display: grid;
  gap: 10px;
  padding: 5px 0;
  grid-template-columns: ${(props) => props.cols};
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[500]};
`

export const MonthHeaderItem = styled.p`
  color: ${(props) => props.theme.colors.gray[500]};
  font-size: 14px;
  margin: 0;
  text-align: center;
`

export const MonthWinner = styled.div`
  width: 100%;
  gap: 10px;
  display: grid;
  grid-template-columns: ${(props) => props.cols};
  transition: all 250ms ease;
  padding: 10px 15px;
  &:hover {
    background: ${(props) => props.theme.colors.gray[50]};
  }
`

export const MonthWinnerTotalGoal = styled.p`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  margin: 0;
  span {
    font-size: 13px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.gray[600]};
    font-weight: normal;
  }
`

export const MonthWinnerAward = styled.p`
  font-weight: bold;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  line-height: 110%;
`
