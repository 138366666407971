import { faker } from '@faker-js/faker/locale/pt_BR'

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

function generateRandomUniqueList(length, min, max) {
  const uniqueList = Array.from({ length: max - min + 1 }, (_, i) => i + min)
  const shuffledList = shuffleArray(uniqueList).slice(0, length)
  shuffledList.sort((a, b) => a - b)
  return shuffledList
}

function globalRankingPlayer(i, total) {
  const fullNameSplited = faker.name.fullName().split('. ')
  const name =
    fullNameSplited.length > 1 ? fullNameSplited[1] : fullNameSplited[0]
  const percentage = i / total

  return {
    id: faker.datatype.uuid(),
    name,
    percentage: percentage * 100,
    quantity: Math.floor(50 * percentage),
    store: `Petland ${faker.address.cityName()} - ${faker.address.stateAbbr()}`,
  }
}

function globalRankingList(total) {
  const userList = []
  for (let i = total; i > 0; i -= 1) {
    userList.push(globalRankingPlayer(i, total))
  }
  return userList
}

function storeRankingPlayer(position, total, globalTotal) {
  const fullNameSplited = faker.name.fullName().split('. ')
  const name =
    fullNameSplited.length > 1 ? fullNameSplited[1] : fullNameSplited[0]
  const percentage = position / globalTotal

  return {
    id: faker.datatype.uuid(),
    name,
    percentage: percentage * 100,
    position,
    quantity: Math.floor(50 * percentage),
    store: `Petland ${faker.address.cityName()} - ${faker.address.stateAbbr()}`,
  }
}

function storeRankingList(globalList, total) {
  const globalTotal = globalList.length
  const randomIndexList = generateRandomUniqueList(total + 1, 0, globalTotal)

  const storePlayerList = globalList
    .map((player, index) => {
      if (randomIndexList.includes(index)) {
        return { ...player, position: index + 1 }
      }
      return false
    })
    .filter((player) => player !== false)

  return storePlayerList
}

function customerPet() {
  const breed = faker.animal.dog()
  const gender = faker.helpers.arrayElement(['Macho', 'Fêmea'])
  const specie = faker.helpers.arrayElement(['Cão', 'Gato'])
  const sex = gender === 'Macho' ? 'male' : 'female'

  return {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(sex),
    petPhoto: `https://source.unsplash.com/random/400x700/?dog,${breed}`,

    attributes: [
      {
        label: 'Espécie',
        value: specie,
      },
      {
        label: 'Sexo',
        value: gender,
      },
      {
        label: 'Raça',
        value: breed,
      },
      {
        label: 'Porte',
        value: faker.helpers.arrayElement([
          'Mini',
          'Pequeno',
          'Médio',
          'Grande',
          'Gigante',
        ]),
      },
      {
        label: 'Pelagem',
        value: faker.helpers.arrayElement(['Curto', 'Médio', 'Longo', 'Duplo']),
      },
      {
        label: 'Castrado',
        value: faker.helpers.arrayElement(['Sim', 'Não']),
      },
      {
        label: 'Status',
        value: faker.helpers.arrayElement(['Vivo', 'Morto']),
      },
    ],
  }
}
function customerPets(total) {
  const petList = []
  for (let i = total; i > 0; i -= 1) {
    petList.push(customerPet())
  }
  return petList
}

export const Faker = {
  globalRankingList,
  storeRankingList,
  globalRankingPlayer,
  storeRankingPlayer,
  customer: {
    pets: customerPets,
  },
}
