import { memo } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Download, Paperclip, Trash2 } from "lucide-react";

import { useHistory } from "../../../../../../store/service/veterinary/useHistory";

import { VeterinaryHistoryController } from "../../../../../../services/controllers/VeterinaryCare";

import { Accordion } from "../../../../../../components/ui/Accordion";
import { ListPreview } from "../../../../../../components/ui/ListPreview";
import { Action } from "../../../../../../components/ui/Action";

export const HistoryViewAttachments = memo(() => {
  const procedureData = useHistory((st) => st.data.historyProcedureData);
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const selectedProcedureAttachment = useHistory((st) => st.data.selectedProcedureAttachment[selectHistoryProcedure]);

  const CLVetHistory = VeterinaryHistoryController();

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedureAttachments = selectedProcedure?.attachments || []

  const isEnabled = procedureAttachments.length

  const listPreviewName = CLVetHistory._attachmentListPreviewName + '-' + selectHistoryProcedure

  return (
    <Accordion.Item
      title="Anexos"
      animate="dance"
      icon={Paperclip}
      disabled={!isEnabled}
    >
      <Accordion.Content>
        <ListPreview.Root
          name={listPreviewName}
          list={procedureAttachments}
          onSelected={(attachment) => CLVetHistory.selectProcedureAttachment(attachment)}
          options={{
            selected: null,
            listStyle: "list",
          }}
        >
          <ListPreview.Header />
          <ListPreview.Info style={{ height: '700px' }}>
            <ListPreview.InfoActions style={{ justifyContent: "flex-end" }}>
              <Action.Root
                type="button"
                title="Baixar Anexo"
                name={`download-attachment-${selectHistoryProcedure}`}
                download={{
                  path: selectedProcedureAttachment?.path,
                  name: selectedProcedureAttachment?.slug,
                }}
              >
                <Action.Icon icon={Download} size={22} />
              </Action.Root>

              <Action.Root
                type="button"
                status="success"
                title="Enviar por WhatsApp"
                name={`send-attachment-${selectHistoryProcedure}`}
                onClick={() => CLVetHistory.runProcedureAction('CAttachment', 'sendMessage', selectedProcedureAttachment)}
              >
                <Action.Icon icon={FaWhatsapp} size={22} />
              </Action.Root>
              <Action.Root
                disabled
                type="button"
                status="danger"
                title="Excluir Anexo"
                name={`delete-attachment-${selectHistoryProcedure}`}
              >
                <Action.Icon icon={Trash2} size={22} />
              </Action.Root>
            </ListPreview.InfoActions>
            <ListPreview.InfoContent fit='cover' />
            <ListPreview.InfoData />
          </ListPreview.Info>
        </ListPreview.Root>
      </Accordion.Content>
    </Accordion.Item>
  );
})
