import { memo } from "react";

import { VACCINE_START_WARNING_DAYS } from "../../../../../../store/service/veterinary/constants";

import { getScheduleStatus } from "../../../../../../services/functions";

import { Table } from "../../../../../../components/ui/Table";

import { VaccineStatusIcon } from "./VaccineStatusIcon";
import { VaccineActions } from "./VaccineActions";

import { VaccineLab } from "./VaccineLab";
import { VaccineBatch } from "./VaccineBatch";
import { VaccineScheduledDate } from "./VaccineScheduledDate";
import { VaccineApplicationDate } from "./VaccineApplicationDate";

import { Container } from "./styles";

export const Vaccine = memo(function Vaccine({ vaccineData }) {
  const vaccineSchedule = getScheduleStatus(vaccineData.scheduledDate);

  const isWarning = VACCINE_START_WARNING_DAYS - vaccineSchedule.daysGap || 0;

  if(vaccineData.status ==='canceled') return null

  return (
    <Container
      id={vaccineData.id}
      key={vaccineData.id}
      vaccineStatus={vaccineData.status}
      scheduleStatus={vaccineSchedule.status}
      warning={isWarning}
    >
      <Table.Item>
        <VaccineStatusIcon vaccine={vaccineData} />
      </Table.Item>
      <Table.Item>
        <VaccineScheduledDate vaccine={vaccineData} />
      </Table.Item>
      <Table.Item>
        <VaccineApplicationDate vaccine={vaccineData} />
      </Table.Item>
      <Table.Item>
        <VaccineLab vaccine={vaccineData} />
      </Table.Item>
      <Table.Item>
        <VaccineBatch vaccine={vaccineData} />
      </Table.Item>
      <VaccineActions vaccineData={vaccineData} />
    </Container>
  );
});
