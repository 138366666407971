import { useCrm } from '../../../../hooks/CrmContext'
import { Activity } from './Activity'
import { ActivitiesTitle, Container } from './styles'

export function Activities({ petId }) {
  const { getActivities } = useCrm()
  const activities = getActivities()
  return (
    <Container>
      <ActivitiesTitle>Atividades:</ActivitiesTitle>
      {!!activities &&
        activities.map((activity) =>
          petId ? (
            activity.petId ? (
              activity.petId &&
              activity.petId === petId && <Activity {...activity} />
            ) : (
              <Activity {...activity} />
            )
          ) : (
            <Activity key={activity.petId} {...activity} />
          ),
        )}
    </Container>
  )
}
