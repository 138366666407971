import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  history: false,
  isLoading: false,
  historyProcedureData: {},
  selectHistoryProcedure: false,
  selectedProcedureAttachment: {},
  selectedProcedureRequest: {},
  selectedProcedurePrescription: {},
};

export const useHistory = create((set) => ({
  data: defaultData,
  actions: {
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),
    setHistory: (history = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!history) return;

          draft.data.history = history
        })
      ),

    clearHistory: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        })
      ),

    setSelectProcedureAttachment: (attachment = false, procedure = false) =>
      set((state) =>
        produce(state, (draft) => {
          procedure = procedure || state.data.selectHistoryProcedure

          draft.data.selectedProcedureAttachment[procedure] = attachment;
        })
      ),

    setSelectProcedureRequest: (request = false, procedure = false) =>
      set((state) =>
        produce(state, (draft) => {
          procedure = procedure || state.data.selectHistoryProcedure

          draft.data.selectedProcedureRequest[procedure] = request;
        })
      ),

    setSelectProcedurePrescription: (prescription = false, procedure = false) =>
      set((state) =>
        produce(state, (draft) => {
          procedure = procedure || state.data.selectHistoryProcedure

          draft.data.selectedProcedurePrescription[procedure] = prescription;
        })
      ),

    setSelectHistoryProcedure: (procedureId = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.selectHistoryProcedure = procedureId;
        })
      ),

    setHistoryProcedureData: (procedure = false, procedureData = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!procedure && procedureData) return

          if (!procedure && !procedureData) {
            draft.data.historyProcedureData = {}
            return
          }

          if (!procedureData) {
            draft.data.historyProcedureData[procedure] = false
            return
          }

          Object.keys(procedureData).forEach((procedureKey) => {
            if (!state.data.historyProcedureData[procedure]) {
              draft.data.historyProcedureData[procedure] = {}
            }

            draft.data.historyProcedureData[procedure][procedureKey] = procedureData[procedureKey]
          })
        })
      ),
  },
}));
