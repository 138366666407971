import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
`
export const ActivityLabel = styled.label`
  font-weight: 300;
  font-size: 11px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  width: 100%;
  strong {
    font-weight: 600;
    font-size: 15px;
    color: #2b2b2b;
  }
`
export const ActivityDescription = styled.input`
  background: #f6f6f6;
  border: 1px solid #cccccc;
  color: #666666;
  padding: 10px;
`
