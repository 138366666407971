import { useSchedule } from '../../../../hooks/ScheduleContext'
import { HoursGaps } from './HoursGaps'

import { Container } from './styles'

export function Employee({ employeeId }) {
  const { getHours, getEmployeeById } = useSchedule()

  const hours = getHours()
  const employee = getEmployeeById(employeeId)

  return (
    <Container>
      {!!hours &&
        hours.displayGaps.map((gap) => (
          <HoursGaps key={gap.display} {...gap} employee={employee} />
        ))}
    </Container>
  )
}
