import { memo } from "react";

import { useService } from "../../../../../../store/service/veterinary/useService";

import { VeterinaryServiceController } from "../../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../../components/ui/Form/Input";
import { isAbleByStatus } from "../../../../../../services/functions";
import { useCare } from "../../../../../../store/service/veterinary/useCare";

export const PriceService = memo(({ serviceKey }) => {
  const CLVetServices = VeterinaryServiceController();

  const careStatus = useCare((st) => st.data.care.status);
  const service = useService((st) => st.data.services[serviceKey]);

  const isAble = isAbleByStatus(careStatus,CLVetServices._name)

  return (
    <Input.Root
      money
      disabled={!isAble}
      value={service.cost || service.price}
      onChange={(price) =>
        CLVetServices.setServiceFieldsValue(serviceKey, { price })
      }
    />
  );
});
