import { useContext } from 'react'

import FilterPetsContext from '../providers/FilterPetsContext'

export const useFilterPet = () => {
  const { filterList, setFilterList, petList, setPetList } =
    useContext(FilterPetsContext)

  return {
    filterList,
    setFilterList,
    petList,
    setPetList,
  }
}
