import qs from "qs";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import API from "../../services/api";

import { REPORTS_CONFIG, REPORTS_FORCE_VALUES } from "../../styles/constant";

export class Report {
  constructor(slug, config = {}) {
    if (!slug) {
      toast.error("Erro ao gerar relatório", { theme: "colored" });
      throw new Error("insira o nome do relatório");
    }

    if (!REPORTS_CONFIG[slug]) {
      toast.error("Este relatório não esta disponível no momento", {
        theme: "colored",
      });
      throw new Error("relatório não encontrados");
    }

    this.reportUrl = null;
    this.filter = {
      dateFrom:
        config.dateFrom || moment().startOf("month").format("YYYY-MM-DD"),
      dateTo: config.dateTo || moment().format("YYYY-MM-DD"),
    };
    this.queryFilter = "";

    this.uri = REPORTS_CONFIG[slug].uri;
    this.name = REPORTS_CONFIG[slug].name;
    this.format = REPORTS_CONFIG[slug].format;
    this.method = REPORTS_CONFIG[slug].method;

    this.typeProps = {
      name: this.name,
      fileFormat: this.format,
    };
  }

  postMethod = async () => {
    try {
      const { data } = await API.post(this.uri, {
        filters: this.filter,
        type: this.typeProps,
      });

      this.reportUrl = data.report;
      return this.reportUrl;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
    }
  };

  setFilter = async (filterProps) => {
    const newFilter = _.pickBy(filterProps, _.identity);

    REPORTS_FORCE_VALUES.forEach((value) => {
      if (!!filterProps[value]) {
        newFilter[value] = filterProps[value];
      }
    });

    this.filter = {
      ...this.filter,
      ...newFilter,
    };
    this.queryFilter = qs.stringify(this.filter);

    if (!!this.filter.cpf && this.filter.cpf.length < 11) return;

    await this.findReportUrl();
  };

  findReportUrl = async () => {
    switch (this.method) {
      case "POST":
        return await this.postMethod();
      default:
        throw new Error("HTTP METHOD não compatível");
    }
  };

  getFileUrl() {
    return this.reportUrl;
  }

  download = () => {
    window.open(this.reportUrl, "_blank").focus();
  };
}
