import axios from 'axios'
import { getToken, logout } from './auth'


const urls ={
  'url':process.env.REACT_APP_HUB_API_URL.replace('/amei.cloud',''),
  'whith-amei-cloud':process.env.REACT_APP_HUB_API_URL,
}

const api = axios.create({
  baseURL: urls['whith-amei-cloud'],
})

export const apiSimple = axios.create({
  baseURL: urls['url'],
})


apiSimple.interceptors.request.use(async (config) => {
  const token = getToken()

  config.headers['unic-request'] = `${new Date()} - ${Math.random()}`

  if (token) config.headers.Authorization = `${token}`

  return config
})

api.interceptors.request.use(async (config) => {
  const token = getToken()

  config.headers['unic-request'] = `${new Date()} - ${Math.random()}`

  if (token) config.headers.Authorization = `${token}`

  return config
})




api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.friendly_message === 'Token expirado.'
    ) {
      logout()
      window.location = '/'
    } else {
      return Promise.reject(error)
    }
  },
)


export default api
