import { memo } from "react";

import { Alert } from "../../../../../components/ui/Alert";

import { formatName, getArticles } from "../../../../../services/functions";

import { VeterinaryVaccineController } from "../../../../../services/controllers/VeterinaryCare";
import { usePet } from "../../../../../store/global/usePet";

export const IncompleteVaccinationProtocol = memo(() => {
  const CLVetVaccine = VeterinaryVaccineController();

  const pet = usePet((st) => st.data.pet);
  if (!pet.id) return null

  const articles = getArticles(pet?.petAttributes.gender);

  return (
    <Alert.Root name={CLVetVaccine._incompleteNewVaccinationProtocolAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Dados não Salvos</Alert.Title>
        <Alert.Description>
          <p>
            Atenção! O protocolo de vacinação {articles[1]}
            <strong> {formatName(pet.name)} </strong> ainda não foi
            salvo. Se você sair agora, todas as modificações realizadas serão
            perdidas. Tem certeza de que deseja sair sem salvar?
          </p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm
            onClick={() => CLVetVaccine.closeNewVaccinationProtocolDrawer(true)}
          >
            Sim, sair
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
