import { ShowerHead, Dog } from 'lucide-react'
import { Card } from '../components/Card'
import { Bathtub } from '@phosphor-icons/react'
import { usePrinter } from '../../../../../../store/schedule/usePrinter'
import { dateFormat, formatName } from '../../../../../../services/functions'
import { usePet } from '../../../../../../store/global/usePet'

export function Pet() {
  const pet = usePet(st => st.data.pet)
  const schedule = usePrinter(st => st.data.schedule)
  const servicesList = usePrinter(st => st.data.services)

  return (
    <Card.Content>
      <Card.Header className="mb-4">
        <Card.HeaderTitle className="flex items-center">
          <Dog className="h-5 w-5 mr-2 " />{pet.name}
          <span className="ml-auto text-sm">{schedule.orderId}</span>
        </Card.HeaderTitle>
        <Card.HeaderDescription>{dateFormat(schedule.date || '')} às {schedule.startAt}</Card.HeaderDescription>
      </Card.Header>

      <div className="flex flex-col gap-4 w-full">
        {Object.entries(servicesList).map(([key, services]) => (
          <Card.Content key={key}>
            <Card.ContentTitle>
              <Bathtub className="h-4 w-4" weight="bold" />
              <span className="leading-none w-full">{formatName(services[0].employeeName)}</span>
            </Card.ContentTitle>
            <Card.ContentList className="gap-2 pt-2">
              {services.map((service) => (
                <Card.ContentListItem key={service.id} className="items-center gap-2 w-full flex pl-2">
                  <ShowerHead className="h-4 w-4" />
                  <span className="leading-none flex-1 w-full">{formatName(service.serviceName)}</span>
                </Card.ContentListItem>
              ))}
            </Card.ContentList>
          </Card.Content>
        ))}
      </div>
    </Card.Content>
  )
}
