import { rgba } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const PrescriptionTitle = styled.li`
  width: 100%;
  list-style: none;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.scale[600]};
`;

export const PrescriptionListItem = styled.li`
  list-style: none;
  margin: 0 0 24px 0;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  border-radius: 5px;
  transition: all ${(props) => props.theme.animation.delay.slow} ease-in-out;
  ${(props) =>
    props.new &&
    css`
      background: ${(props) => rgba(props.theme.colors.scale[200], 0.3)};
    `}
`;

export const PrescriptionSubTitle = styled.p`
  display: flex;
  width: 100%;
  margin: 0;
  align-items: baseline;
  justify-content: flex-start;
  font-size: 12px;
  gap: 4px;
  color: ${(props) => props.theme.colors.gray[600]};

  strong {
    display: flex;
    font-weight: bold;
    font-size: 14px;
    color: ${(props) => props.theme.colors.scale[600]};
  }
`;

export const PrescriptionSubTitleSeparator = styled.em`
  display: flex;
  flex: 1;
  width: 100%;
  border-bottom: 1px dashed ${(props) => props.theme.colors.gray[400]};
  height: 100%;
`;

export const PrescriptionMedicine = styled.h3`
  margin: 4px 0 0 0;
  font-size: 18px;
  color: ${(props) => props.theme.colors.scale[700]};
`;

export const PrescriptionDosage = styled.div`
  margin: 0;
  width: 100%;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray[700]};
`;

export const PrescriptionNote = styled.em`
  margin-top: 4px;
  color: ${(props) => props.theme.colors.gray[500]};
  strong {
    font-style: normal;
    color: ${(props) => props.theme.colors.gray[700]};
  }
`;
