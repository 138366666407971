import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 60px;
`

export const Title = styled.h4`
  font-size: 18px;
  color: ${(props) => props.theme.colors.scale[700]};
  margin: 0 0 2 0px 0;
`

export const Empty = styled.p`
  font-size: 13px;
  color: ${(props) => props.theme.colors.gray[600]};
  margin: 0;
`
export const Vaccines = styled.div`
  display: grid;
  width: 100%;
  gap: 0px;
  grid-template-columns: 350px 1fr;
`
export const VaccineList = styled.ul`
  padding-left: 20px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
