import React from "react";
import CountUp from "react-countup";
import { Bell, TrendingUp , TrendingDown, AlertTriangle } from "lucide-react";

import { useSubscribers } from "../../../../store/subscription/useSubscribers";
import { Summary } from "../../../../components/ui/Summary";

export function SubscribersSummary() {
  const summary = useSubscribers((st) => st.data.summary);

  return (
    <Summary.Root>
      <Summary.Box>
        <Summary.Icon icon={TrendingUp} />
        <Summary.TextContent>
          <Summary.Title>
            <CountUp
              end={summary.totalSubscribers}
              separator="."
              decimals={0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Total de Assinantes</Summary.Text>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={Bell} />
        <Summary.TextContent>
          <Summary.Title>
            <CountUp
              end={summary.totalActives}
              separator="."
              decimals={0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Assinantes Ativos</Summary.Text>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={AlertTriangle} />
        <Summary.TextContent>
          <Summary.Title>
            <CountUp
              end={summary.totalOverdue}
              separator="."
              decimals={0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Total em Atraso</Summary.Text>
        </Summary.TextContent>
      </Summary.Box>
      <Summary.Box>
        <Summary.Icon icon={TrendingDown } />
        <Summary.TextContent>
          <Summary.Title>

            <CountUp
              end={summary.totalCanceled}
              separator="."
              decimals={0}
              preserveValue
              useEasing
            />
          </Summary.Title>
          <Summary.Text>Total Cancelado</Summary.Text>
        </Summary.TextContent>
      </Summary.Box>
    </Summary.Root>
  );
}
