import _ from "lodash";
import moment from "moment";

import { dateFormatedToDB, formatName } from "../../functions";

function money(value) {
  if (!value) return value;

  const formatted = Number(value)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.\d{2})/g, "$1m")
    .replace(".", ",")
    .replaceAll("m", ".");
  return `R$ ${formatted}`;
}

function longDate(value) {
  if (!value) return value;

  const momentDate = moment(dateFormatedToDB(value));

  const day = momentDate.format("DD");
  const month = formatName(momentDate.format("MMMM"));
  const year = momentDate.format("YYYY");

  return `${day} de ${month} de ${year}`;
}

function name(value) {
  if (!value) return value;

  return formatName(value);
}


export const functionFormat = (formatFields, data) => {
  const formattedValues = {};

  Object.keys(formatFields).forEach((key) => {
    switch (formatFields[key]) {
      case "money":
        formattedValues[key] = money(data[key]);
        break;
      case "longDate":
        formattedValues[key] = longDate(data[key]);
        break;
      case "name":
        formattedValues[key] = name(data[key]);
        break;

      default:
        formattedValues[key] = data[key];
        break;
    }
  });

  return { ...data, ..._.omitBy(formattedValues, _.isEmpty) };
};

export function breakLines(data) {
  const formattedValues = {};

  Object.keys(data).forEach((key) => {
    switch (typeof data[key]) {
      case "string":
        formattedValues[key] = data[key].replace(/\n/g, "<br />");
        break;
      default:
        formattedValues[key] = data[key];
        break;
    }
  });

  return { ...data, ..._.omitBy(formattedValues, _.isEmpty) };
}
