import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 70px repeat(
      ${(props) => props.totalEmployees},
      ${(props) => props.collumnSize}px
    );
  border-top: none;
  background-image: linear-gradient(
    135deg,
    transparent 46%,
    ${(props) => props.theme.colors.scale[100]} 49%,
    ${(props) => props.theme.colors.scale[100]} 51%,
    transparent 55%
  );
  background-size: 10px 10px;
  transition: all 50ms linear;
`
