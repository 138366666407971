import { PetController } from '../../../../../../services/controllers/global';
import { PageVisibilityAction } from '../../../../../../components/PageVisibilityAction';

import { Container } from './styles';

export function IncompleteRegistration({ content }) {
  const CLPet = PetController();

  return (
    <>
      <PageVisibilityAction onVisibility={CLPet.getPet} />
      <Container dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
}
