import {
  SpellCheck,
  ChevronUp,
  Dog,
  Skull,
  User,
  Dumbbell,
  CircleSlash2,
  Cake,
  Gift,
  PhoneCall,
  Home,
} from "lucide-react";
import {
  GenderFemale,
  GenderMale,
  Heart,
  IdentificationCard,
  PawPrint,
  Plugs,
  PlugsConnected,
  WaveSine,
} from "phosphor-react";
import { PetAttributeIconBox } from "./styles";
import _ from "lodash";

export function PetCardAttributeIcon({ label, value }) {

  if (label === "Porte") {
    return (
      <PetAttributeIconBox>
        <ChevronUp size={18} />
        <ChevronUp size={18} />
      </PetAttributeIconBox>
    );
  }
  if (label === "Pelagem") {
    return (
      <PetAttributeIconBox>
        <WaveSine size={18} />
        <WaveSine size={18} />
        <WaveSine size={18} />
      </PetAttributeIconBox>
    );
  }
  if (label === "Raça") {
    return (
      <PetAttributeIconBox>
        <Dog size={18} />
      </PetAttributeIconBox>
    );
  }
  if (label === "Nascimento") {
    return (
      <PetAttributeIconBox>
        <Gift size={18} />
      </PetAttributeIconBox>
    );
  }
  if (label === "Idade") {
    return (
      <PetAttributeIconBox>
        <Cake size={18} />
      </PetAttributeIconBox>
    );
  }

  if (label === "Espécie") {
    return (
      <PetAttributeIconBox>
        <PawPrint size={18} />
      </PetAttributeIconBox>
    );
  }

  if (label === "Nome") {
    return (
      <PetAttributeIconBox>
        <SpellCheck size={18} />
      </PetAttributeIconBox>
    );
  }
  if (label === "Peso" || label === "PetPeso") {
    return (
      <PetAttributeIconBox>
        <Dumbbell size={18} />
      </PetAttributeIconBox>
    );
  }

  if (label === "Sexo" || label === "gender") {
    return (
      <PetAttributeIconBox>
        {!value && <CircleSlash2 size={18} />}
        {value.toLowerCase() === "fêmea" && <GenderFemale size={18} />}
        {value.toLowerCase() === "macho" && <GenderMale size={18} />}
      </PetAttributeIconBox>
    );
  }
  if (label === "Sexo") {
    return (
      <PetAttributeIconBox>
        {!value && <CircleSlash2 size={18} />}
        {value === "Fêmea" && <GenderFemale size={18} />}
        {value === "Macho" && <GenderMale size={18} />}
      </PetAttributeIconBox>
    );
  }
  if (label === "Status") {
    return (
      <PetAttributeIconBox>
        {!value && <CircleSlash2 size={18} />}
        {value === "Vivo" && <Heart size={18} />}
        {value === "Morto" && <Skull size={18} />}
      </PetAttributeIconBox>
    );
  }
  if (label === "Castrado") {
    const castredValue = _.isArray(value)?  _.flattenDeep(value)[0]:value
    return (
      <PetAttributeIconBox>
        {!castredValue && <CircleSlash2 size={18} />}
        {castredValue.toLowerCase() === "sim" && <Plugs size={18} />}
        {castredValue.toLowerCase() === "não" && <PlugsConnected size={18} />}
      </PetAttributeIconBox>
    );
  }
  if (label === "Tutor" || label === "TutorFull") {
    return (
      <PetAttributeIconBox>
        <User size={18} />
      </PetAttributeIconBox>
    );
  }
  if (label === "TutorCpf") {
    return (
      <PetAttributeIconBox>
        <IdentificationCard size={22} />
      </PetAttributeIconBox>
    );
  }
  if (label === "TutorPhone") {
    return (
      <PetAttributeIconBox>
        <PhoneCall size={18} />
      </PetAttributeIconBox>
    );
  }
  if (label === "TutorAddress") {
    return (
      <PetAttributeIconBox>
        <Home size={18} />
      </PetAttributeIconBox>
    );
  }

  return null;
}
