import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

import {
  TextField,
  MenuItem,
  Grid,
  Button,
  Divider,
  makeStyles,
  Snackbar,
} from '@material-ui/core'
import { usePackge } from '../../../../hooks/EditPackageContext'

import Resumo from '../Resumo'
import Services from './Services'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function Content() {
  const [sucessMsg, setSucessMsg] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const classes = useStyles()
  const {
    getPackage,
    getPackageName,
    setPackageName,
    getPackageDiscount,
    setPackageDiscount,
    getPackageDiscountType,
    setPackageDiscountType,
    updatePackage,
  } = usePackge()

  const packageName = getPackageName()
  const discount = getPackageDiscount()
  const discountType = getPackageDiscountType()

  const redirectToList = () => {
    setSucessMsg(true)
    setTimeout(() => {
      history.push('/pacotes')
    }, 1000)
  }

  const submitPackage = (e) => {
    e.preventDefault()
    updatePackage(redirectToList)
  }

  useEffect(() => {
    if (id) {
      getPackage(id)
    }
  }, [id])

  return (
    <form className={classes.root} onSubmit={submitPackage}>
      <Snackbar
        open={sucessMsg}
        autoHideDuration={3000}
        onClose={() => {
          setSucessMsg(false)
        }}
      >
        <Alert severity="success">Pacote Atualizado</Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Nome do pacote"
                name="nome"
                variant="outlined"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
                fullWidth
                required
                placeholder="Nome do pacote"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Services />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                id="discount"
                label="Valor do Desconto"
                name="discount"
                variant="outlined"
                type="number"
                fullWidth
                required
                value={discount}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  e.target.value < 0 ? 0 : setPackageDiscount(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                select
                required
                id="outlined-basic"
                label="Tipo do desconto"
                placeholder=""
                variant="outlined"
                value={discountType}
                name="discountType"
              >
                <MenuItem
                  key="by_percent"
                  value="fixed_value"
                  onClick={() => setPackageDiscountType('fixed_value')}
                >
                  R$ (valor fixo)
                </MenuItem>
                <MenuItem
                  key="by_percent"
                  value="by_percent"
                  onClick={() => setPackageDiscountType('by_percent')}
                >
                  Porcentagem (%)
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Resumo />
        </Grid>
        <Divider className={classes.bottom} />

        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={false}
            style={{
              background: '#CCD219',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: '700',
              width: '138px',
              marginRight: '20px',
            }}
          >
            Salvar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => null}
            style={{
              border: '1px solid #CCD219',
              color: '#CCD219',
              textTransform: 'capitalize',
              fontSize: '14px',
              width: '120px',
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Content
