import uuid from "react-uuid";

import { create } from "zustand";
import { produce } from "immer";
import { getPDFGenerateStatusBadge } from "../../../services/functions";

const defaultPrescription = {
  name: "",
  updated: true,
  id: false,
  medicines: [],
  newMedicine: {
    id: null,
    name: "",
    presentation: "",
    fillStyle: null,
    freeText: "",
    dose: "",
    measure: null,
    frequency: "",
    frequencyPeriod: null,
    duration: "",
    durationPeriod: null,
    note: "",
    pharmacyType: null,
    usage: null,
    quantity: "",
    new: true,
  },
};
const defaultData = {
  prescriptions: {},
  isLoading: false,
  selectPrescription: false,
  loadedThumbnails: false,
  prescription: defaultPrescription
};

export const usePrescription = create((set) => ({
  data: defaultData,
  actions: {
    isUpdated: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.prescription.updated = status;
        })
      ),
    setLoadedThumbnailsStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.loadedThumbnails = status;
        })
      ),
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),

    setPrescriptions: (prescriptions = false, clear = true) =>
      set((state) =>
        produce(state, (draft) => {
          if (!prescriptions) return;

          if (!!clear) draft.data.prescriptions = {};

          _.concat([], prescriptions).forEach((prescription) => {
            draft.data.prescriptions[prescription.id] = {
              ...prescription,
              returned: false,
              removed: false,
            };
          });
        })
      ),
    clearPrescriptions: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        })
      ),

    updatePrescriptions: (prescriptions = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!prescriptions) return;

          _.concat([], prescriptions).forEach((prescription) => {
            draft.data.prescriptions[prescription.id] = {
              ...prescription,
              returned: false,
              removed: false,
            };
          });
        })
      ),
    setSelectPrescription: (prescription = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.selectPrescription = prescription;
        })
      ),

    changePrescriptionStatus: (prescriptionId = false, status) =>
      set((state) =>
        produce(state, (draft) => {
          if (!prescriptionId || !state.data.prescriptions[prescriptionId]) return

          if(!!state.data.selectPrescription){
            draft.data.selectPrescription.status = status
          }

          draft.data.prescriptions[prescriptionId].status = status
          draft.data.prescriptions[prescriptionId].informationList.Status = getPDFGenerateStatusBadge(status)
        })
      ),
    changePrescriptionPath: (prescriptionId = false, path) =>
      set((state) =>
        produce(state, (draft) => {
          if (!prescriptionId || !state.data.prescriptions[prescriptionId]) return

          draft.data.prescriptions[prescriptionId].path = path
        })
      ),

    removePrescription: (prescriptionId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!prescriptionId) return;

          draft.data.prescriptions[prescriptionId].returned = false;
          draft.data.prescriptions[prescriptionId].removed = true;
        })
      ),
    returnPrescription: (prescriptionId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!prescriptionId) return;

          draft.data.prescriptions[prescriptionId].returned = true;
          draft.data.prescriptions[prescriptionId].removed = false;
        })
      ),
    deletePrescription: (prescriptionId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!prescriptionId) return;
          delete draft.data.prescriptions[prescriptionId];
        })
      ),

    setPrescriptionName: (name) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.prescription.name = name;
        })
      ),
    setPrescriptionID: (id = null) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.prescription.id = id;
        })
      ),

    setFillStyle: (style) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.prescription.fillStyle = style;
        })
      ),

    setNewMedicine: (props) =>
      set((state) =>
        produce(state, (draft) => {
          const actualProps = state.data.prescription.newMedicine;


          if (!state.data.prescription.newMedicine.id) {
            draft.data.prescription.newMedicine.id = uuid();
          }

          draft.data.prescription.newMedicine = { ...actualProps, ...props };
        })
      ),

    addMedicines: (medicines = []) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.prescription.medicines = medicines;
        })
      ),

    addMedicine: (defaultProps = {}) =>
      set((state) =>
        produce(state, (draft) => {
          const defaultMedicine = {
            ...defaultPrescription.newMedicine,
            ...defaultProps,
          }
          draft.data.prescription.medicines.push({ ...state.data.prescription.newMedicine, new: false });

          draft.data.prescription.newMedicine = {
            ...defaultMedicine,
            id: uuid(),
          };
        })
      ),

    updatePrescriptions: (prescriptions = false) =>
      set((state) =>
        produce(state, (draft) => {
          prescriptions.forEach((prescription) => {
            if (!prescriptions) return;

            draft.data.prescriptions[prescription.id] = {
              ...prescription,
              returned: false,
              removed: false,
            };
          });
        })
      ),

    removeMedicine: (medicineId) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.prescription.medicines = state.data.prescription.medicines.filter(
            (medicine) => medicine.id !== medicineId
          );
        })
      ),
  },
}));
