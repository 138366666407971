import React from 'react'

// Compenentes
import Cupom from '../components/organisms/Cupom'

function CupomPage() {
  return <Cupom />
}

export default CupomPage
