import { Text, View } from '@react-pdf/renderer';

import { getCurrentThemeStyle } from '../../../../services/functions';

import { container, highlight, separator, page } from './styles';

export function FooterPage() {
  return (
    <View style={container}>
      <Text style={highlight} render={({ pageNumber, totalPages }) => totalPages > 1 && pageNumber} />
      <Text style={separator} render={({ totalPages }) => totalPages > 1 && '/'} />
      <Text style={page} render={({ totalPages }) => totalPages > 1 && totalPages} />
    </View>
  );
}


