import { FooterAss } from "./FooterAss";
import { FooterDate } from "./FooterDate";
import { FooterPage } from "./FooterPage";
import { FooterRoot } from "./FooterRoot";

export const Footer = {
  Root: FooterRoot,
  Date: FooterDate,
  Ass: FooterAss,
  Page: FooterPage,
}
