import React from 'react'

// Material.ui
import Drawer from '@mui/material/Drawer'

function DrawerType(props) {
  const {
    setDrowerOpen = null,
    open = null,
    content = null,
    setSelectRoom = null,
    setIdSelectRoom = null,
    titleHeader = null,
    anchor = 'left',
    color = null,
  } = props

  function clickDrawer() {
    if (setDrowerOpen) setDrowerOpen(!open)
    if (setIdSelectRoom) setIdSelectRoom(false)
    if (setSelectRoom) setSelectRoom(false)
  }

  return (
    <Drawer anchor={anchor} open={open} onClose={clickDrawer}>
      {titleHeader && (
        <div
          style={{
            background: `${color || 'rgb(204, 210, 25)'}`,
            color: '#fff',
            padding: '10px',
            fontSize: '18px',
          }}
        >
          {titleHeader}
        </div>
      )}
      {content}
    </Drawer>
  )
}

export default DrawerType
