import styled from 'styled-components'

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px;
  width: 100%;
  flex: 1;
  min-width: 370px;
  min-height: calc(100vh - 230px);
  transition: all 250ms linear;
`

export const SetageHeader = styled.header`
  width: 100%;
  background: ${(props) => props.color};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-weight: bold;
    font-size: 18px;
    color: #fafafa;
    margin: 0;
  }
`
export const SetageHeaderBadge = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color};
  background: #fafafa;
  border-radius: 5px;
  font-weight: bold;
  padding: 3px 6px;
`

export const CardList = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
