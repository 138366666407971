import { cloneElement } from "react";
import _ from "lodash";

import { Controls as Container } from "../styles";
import { DrawerControlClose } from "./DrawerControlClose";
import { DrawerControlMinimize } from "./DrawerControlMinimize";

export function DrawerControls({
  name,
  controls = {
    close: false,
    minimize: false,
  },
}) {
  const controlList = [
    {
      controlName: "close",
      callback: controls.close || false,
      Element: <DrawerControlClose />,
    },
    {
      controlName: "minimize",
      callback: controls.minimize || false,
      Element: <DrawerControlMinimize />,
    },
  ];

  const filteredControls = controlList.filter(({ controlName }) =>
    _.keys(controls).includes(controlName)
  );
  const childrenProps = filteredControls.map(
    ({ Element, callback, controlName }, i) =>
      cloneElement(Element, {
        name,
        key: controlName,
        callback,
        delay: (i + 1) * 300,
      })
  );

  return <Container>{childrenProps}</Container>;
}
