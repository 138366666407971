import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
  & > button {
    max-width: fit-content;
  }
`

export const FormContent = styled.div`
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  & > * {
    min-width: fit-content;
    flex: 1;
  }
`
