import _ from "lodash";
import { toast } from "react-toastify";

import { useRecord } from "../../../store/service/veterinary/useRecord";

import { VeterinaryRecordModel } from "../../models/VeterinaryCare";

import { Alert } from "../../../components/ui/Alert";
import { TIMES } from "../../../styles/constant";
import {
  RECORD_FIELDS,
  RECORD_FIELDS_GROUP,
} from "../../../store/service/veterinary/constants";

export default class VeterinaryRecordController {
  _name = "veterinary-record";
  _weightHistoryName = `${this._name}-weight-history`;
  _weightHistoryAlertName = `${this._weightHistoryName}-alert`;
  _weightHistoryTableName = `${this._weightHistoryName}-table`;

  _saveInStore = true;
  _delayToSave = 1 * TIMES.second;

  _requiredRecord = {
    generalInformation: ["weight", "anamnesis"],
    vitalSigns: ["fr", "tpc", "temperature"],
    generalPhysicalExamination: [
      "ears",
      "mucous",
      "hydration",
      "lymphNodes",
      "skin",
    ],
    bodyExamination: ["chest", "abdomen", "thoracicLimbs", "pelvicLimbs"],
    diagnosis: ["diagnosis"],
  };

  _defaultRecord = {
    weight: null,
    anamnesis: undefined,

    fc: null,
    fr: null,
    tpc: null,
    temperature: null,

    ears: null,
    mucous: null,
    hydration: null,
    lymphNodes: null,
    skin: null,

    chest: null,
    abdomen: null,
    thoracicLimbs: null,
    pelvicLimbs: null,

    diagnosis: undefined,
  };

  alertIsOpen = Alert.store.getState().actions.isOpen;

  setLoadingStatus = useRecord.getState().actions.setLoadingStatus;

  setRecordData = useRecord.getState().actions.setRecord;
  setRecordValues = useRecord.getState().actions.setRecordValues;

  constructor(orderId, saveInStore = null) {
    this._orderId = orderId;

    if (saveInStore !== null) this._saveInStore = !!saveInStore

    this._saveGroupsActions = {
      generalInformation: this.saveGeneralInformation,
      vitalSigns: this.saveVitalSigns,
      generalPhysicalExamination: this.saveGeneralPhysicalExamination,
      bodyExamination: this.saveBodyExamination,
      diagnosis: this.saveDiagnosis,
    };

    this.MVetRecord = VeterinaryRecordModel(this._orderId);
  }

  init = _.once(() => {
    this.setRecord();
    this.getRecord();
  });

  debouncedSave = _.debounce(
    (saveFunction) => saveFunction(),
    this._delayToSave
  );

  runSaveGroupByField = (fields) => {
    fields.forEach((field) => {
      const group = _.findKey(RECORD_FIELDS_GROUP, (groupFields) =>
        groupFields.includes(field)
      );

      this.debouncedSave(this._saveGroupsActions[group]);
    });
  };

  validateRecord = () => {
    const recordValues = useRecord.getState().data.record;
    let invalidFields = [];

    _.mapKeys(this._requiredRecord, (groupFields) => {
      invalidFields = _.concat(
        invalidFields,
        _.filter(groupFields, (item) => !recordValues[item])
      );
    });

    invalidFields.forEach((error, i) => {
      setTimeout(() => {
        toast.error(
          `O campo ${RECORD_FIELDS[error][1]} é obrigatório para finalizar o atendimento`,
          { theme: "colored" }
        );
      }, 200 * i);
    });

    return !invalidFields.length;
  };

  setRecord = (record) => {
    record = record || this._defaultRecord;

    if (this._saveInStore) this.setRecordData(record);
  };

  changeRecordValue = (value) => {
    const changedFields = Object.keys(value);

    if (this._saveInStore) this.setRecordValues(value);

    if (this._saveInStore) this.runSaveGroupByField(changedFields);
  };

  getRecord = async () => {
    if (this._saveInStore) this.setLoadingStatus(true);

    const record = await this.MVetRecord.findRecord();

    if (this._saveInStore) this.setLoadingStatus(false);
    if (!record) return;

    if (this._saveInStore) this.setRecord(record);

    return record
  };

  saveGeneralInformation = () => {
    this.debouncedSave.cancel();
    const recordValues = useRecord.getState().data.record;

    this.MVetRecord.saveWeight(recordValues.weight);
    this.MVetRecord.saveAnamnese(recordValues);
  };

  saveVitalSigns = () => {
    this.debouncedSave.cancel();
    const recordValues = useRecord.getState().data.record;

    this.MVetRecord.saveVitalSigns(recordValues);
  };

  saveGeneralPhysicalExamination = () => {
    this.debouncedSave.cancel();
    const recordValues = useRecord.getState().data.record;

    this.MVetRecord.saveGeneralPhysicalExamination(recordValues);
  };

  saveBodyExamination = () => {
    this.debouncedSave.cancel();
    const recordValues = useRecord.getState().data.record;

    this.MVetRecord.saveBodyExamination(recordValues);
  };

  saveDiagnosis = () => {
    this.debouncedSave.cancel();
    const recordValues = useRecord.getState().data.record;

    this.MVetRecord.saveDiagnosis(recordValues);
  };

  openWeightHistory = () => {
    this.alertIsOpen(this._weightHistoryAlertName, true);
  };
}
