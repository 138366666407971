import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import { Edit } from '@material-ui/icons'
import { usePets } from '../../../providers/pets'

import DrawerType from '../../../components/atoms/Drawer'
import EditPet from '../../../components/organisms/EditPet'
import NewPet from '../../../components/organisms/NewPet'

import styles from './styles'

function PetLIst() {
  const { petList, getPets } = usePets()
  const { id } = useParams()

  const [selectPet, setSelectPet] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openNewPetDrawer, setOpenNewPetDrawer] = useState(false)

  const { grid, petNew, pet, petEdit, petBox, petImage, drawerBox } = styles()

  const closeDrawerPet = () => {
    getPets()
    setOpenDrawer(false)
    setOpenNewPetDrawer(false)
  }

  return (
    <Grid item xs={12} className={grid}>
      <DrawerType
        open={openDrawer}
        setDrowerOpen={setOpenDrawer}
        titleHeader="Editar Pet"
        content={
          <div className={drawerBox}>
            <EditPet
              petData={{
                customerId: id,
                callBack: closeDrawerPet,
                // eslint-disable-next-line no-underscore-dangle
                value: { ...selectPet, petId: selectPet?._id },
              }}
            />
          </div>
        }
      />
      <DrawerType
        open={openNewPetDrawer}
        setDrowerOpen={setOpenNewPetDrawer}
        titleHeader="Novo Pet"
        content={
          <div className={drawerBox}>
            <NewPet
              customerData={{ customerId: id, callBack: closeDrawerPet }}
            />
          </div>
        }
      />

      <div className={petBox}>
        <span
          onClick={() => {
            setOpenNewPetDrawer(true)
          }}
          className={petNew}
        >
          +
        </span>
        Novo Pet
      </div>
      {petList &&
        petList.map((petData) => (
          <div key={petData.name} className={petBox}>
            <span
              key={petData._id}
              onClick={() => {
                setSelectPet(petData)
                setOpenDrawer(true)
              }}
              className={pet}
            >
              {petData.petImg && (
                <img
                  src={petData.petImg}
                  alt={petData.name}
                  className={petImage}
                />
              )}
              {!petData.petImg && petData.name.charAt(0)}
              <dd className={petEdit}>
                <Edit />
              </dd>
            </span>
            {petData.name}
          </div>
        ))}
    </Grid>
  )
}

export default PetLIst
