import React, { useEffect, useState } from 'react'
import { Button, Grid, TextField } from '@material-ui/core'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import ptBR from 'date-fns/locale/pt-BR'
import { useTaxiDog } from '../../../../../hooks/TaxiDogContext'
import { useStyles } from './styles'

export function Filter({ date }) {
  const { filterBox, filterButton } = useStyles()

  const [value, setValue] = useState(new Date(date))
  const [dateOpen, setDateOpen] = useState(false)

  const {
    getTaxiDogs,
    findTaxiDogs,
    findDrivers,
    setTaxiFilter,
    exportTaxiDogs,
  } = useTaxiDog()

  useEffect(() => {
    findDrivers()
    findTaxiDogs(value)
  }, [])

  return (
    <div className={filterBox}>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <DatePicker
            open={dateOpen}
            onClose={() => setDateOpen(false)}
            displayStaticWrapperAs="desktop"
            value={value}
            disableToolbar
            mask="____/__/__"
            onChange={(newValue) => {
              setTaxiFilter({ date: newValue })
              setValue(newValue)
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            placeholder="ex: DD/MM/AAAA"
            renderInput={(params) => (
              <TextField {...params} onClick={() => setDateOpen(true)} />
            )}
          />
        </LocalizationProvider>
      </Grid>

      <Grid xs={6} style={{ display: 'flex', gap: '15px' }}>
        <Button
          fullWidth
          color="secondary"
          size="large"
          type="submit"
          justify="center"
          alignItems="center"
          className={filterButton}
          onClick={() => {
            findTaxiDogs()
          }}
          disabled={!getTaxiDogs()}
        >
          Filtrar
        </Button>
        <Button
          fullWidth
          color="secondary"
          size="large"
          type="submit"
          justify="center"
          alignItems="center"
          className={filterButton}
          onClick={exportTaxiDogs}
        >
          Exportar
        </Button>
      </Grid>
    </div>
  )
}
