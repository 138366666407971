import { FontBoldIcon, FontItalicIcon } from "@radix-ui/react-icons";
import { useContext } from "../Context";

import { MenuButton, MenuGroup } from "./styles";

export function MenuTextStyle() {
  const { editor } = useContext();

  return (
    <MenuGroup>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        <FontBoldIcon width="18" height="18" />
      </MenuButton>
      <MenuButton
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
      >
        <FontItalicIcon width="18" height="18" />
      </MenuButton>
    </MenuGroup>
  );
}
