export const PartialResponsibleSignature = {
  name: "Assinatura do Responsável",
  gender: "female",
  content: `
    <div class="center column">
      <span class="line"></span>
      <p>Assinatura do(a) Responsável</p>
    </div>
  `.trim(),
};
