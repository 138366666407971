import React from 'react'
import { useStyles } from './styles'

export function LineTaxidog({
  taxiDogType,
  total,
  deliveryAddress,
  driverPickupType,
  driverName,
  pickupAddress,
}) {
  const { TaxiDogItem, TaxiDogHeader, TaxiDogLine, TaxiDogText } = useStyles()

  return (
    <div className={TaxiDogItem}>
      <div className={TaxiDogHeader}>
        <span className={TaxiDogText}>
          <strong>TAXI DOG:</strong> {taxiDogType}
        </span>
      </div>
      <div className={TaxiDogLine}>
        <span className={TaxiDogText}>
          <strong>Recebimento:</strong>
          {driverPickupType}{' '}
        </span>
        <span className={TaxiDogText}>
          <strong>Valor:</strong>{' '}
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(total)}
        </span>
        <span className={TaxiDogText}>
          <strong>Motorista:</strong> {driverName}
        </span>
      </div>
      {!!pickupAddress && (
        <div className={TaxiDogLine}>
          <span className={TaxiDogText}>
            <strong>Endereço Retirada:</strong> {pickupAddress.street},{' '}
            {pickupAddress.number} -{pickupAddress.complement} -
            {pickupAddress.neighborhood} -{pickupAddress.city}
          </span>
        </div>
      )}
      {!!deliveryAddress && (
        <div className={TaxiDogLine}>
          <span className={TaxiDogText}>
            <strong>Endereço Entrega:</strong> {deliveryAddress.street},{' '}
            {deliveryAddress.number} -{deliveryAddress.complement} -
            {deliveryAddress.neighborhood} -{deliveryAddress.city}
          </span>
        </div>
      )}
    </div>
  )
}
