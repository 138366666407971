import { create } from 'zustand'
import { produce } from 'immer'
import { CALENDAR } from '../../styles/constant'

const storageCollumnSize = localStorage.getItem('@PETLAND/collumnSize')

export const useEmployee = create((set) => ({
  employee: {
    size: +(storageCollumnSize || CALENDAR.public.collumnSize),
  },
  actions: {
    updatEmployeeSize: (newSize) =>
      set((state) =>
        produce(state, (draft) => {
          draft.employee.size = newSize
          localStorage.setItem('@PETLAND/collumnSize', newSize)
        }),
      ),
  },
}))
