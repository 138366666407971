import { useState } from 'react'

import DrawerType from '../../../../components/atoms/Drawer'
import ServiceQueue from '../../../../components/organisms/ServiceQueue'

import { ActionButton } from '../styles'

export function ServiceQueueButton() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DrawerType
        open={isOpen}
        setDrowerOpen={setIsOpen}
        titleHeader="Fila de Atendimento"
        content={<ServiceQueue />}
      />
      <ActionButton onClick={() => setIsOpen(true)}>Fila</ActionButton>
    </>
  )
}
