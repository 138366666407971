import { Tab, Tabs } from '@material-ui/core'
import { Container } from './styles'
import { useCrm } from '../../../hooks/CrmContext'

export function TabsList() {
  const { updateTab, getTab } = useCrm()
  const actualTab = getTab()

  return (
    <Container xs={12}>
      <Tabs
        value={actualTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, value) => {
          updateTab(value)
        }}
        style={{ flex: 1 }}
      >
        <Tab label="LISTA DE CLIENTES" value="customersList" />
      </Tabs>
    </Container>
  )
}
