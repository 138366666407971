import { useCallback } from "react";
import { InputText } from "../styles";
import { formatMoney } from "../../../../../services/functions";

export function InputTypeMoney({
  value,
  noSuffix = false,
  decimals = 2,
  onChange: onChangeTrigger = () => null,
  ...rest
}) {
  const divider = 10 ** decimals;

  const formatValue = useCallback(
    (number) => {
      const cleanedNUmber = number
        .toFixed(decimals)
        .toString()
        .replace(/\D/g, "");

      const formatedNUmber = formatMoney(cleanedNUmber / divider, decimals);
      if (noSuffix) {
        return formatedNUmber;
      }
      return `R$ ${formatedNUmber}`;
    },
    [noSuffix, decimals, divider]
  );

  const changeValue = useCallback(
    (number) => {
      const value = +number.replace(/[^0-9]+/g, "") / divider;

      onChangeTrigger(value);
    },
    [onChangeTrigger]
  );

  value = typeof value === "number" ? formatValue(value) : value;

  return (
    <InputText
      {...rest}
      value={value}
      onChange={(e) => changeValue(e.target.value)}
      placeholder=" "
    />
  );
}
