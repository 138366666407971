import { VeterinaryRequestController } from "../../../../../services/controllers/VeterinaryCare";

import { Drawer } from "../../../../../components/ui/Drawer";

import { RequestForm } from "./RequestForm";
import { RequestPreview } from "./RequestPreview";

import { Container } from "./styles";

export function NewRequestDrawer() {
  const CLVetRequest = VeterinaryRequestController();

  return (
    <>
      <Drawer.Root
        name={CLVetRequest._drawerName}
        style={{ width: "90vw" }}
        direction="left"
      >
        <Drawer.Controls controls={{ close: CLVetRequest.closeDrawer }} />
        <Drawer.Header>Nova Requisição</Drawer.Header>
        <Drawer.Content>
          <Container>
            <RequestForm />
            <RequestPreview />
          </Container>
        </Drawer.Content>
        <Drawer.Footer />
      </Drawer.Root>
    </>
  );
}
