import { rgba } from "polished";
import styled, { keyframes } from "styled-components";

const IconDanceAnimation = keyframes`
  0%, 100%, 80% { transform:rotate(0deg)}
  85%{ transform:rotate(-10deg)}
  95%{ transform:rotate(10deg)}
`;


export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
  margin: 0 0 20px 0;
  position: relative;
  padding: 0 8px 8px 8px;
  color: ${(props) => props.theme.colors.scale[700]};
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid ${(props) => props.theme.colors.scale[400]};
    border-bottom: 1px solid
      ${(props) => rgba(props.theme.colors.scale[500], 0.5)};
  }
`;
export const Title = styled.h2`
  margin: 0;
`

export const TitleIcon = styled.span`
  display: flex;
  animation: ${IconDanceAnimation} 18s ease-in-out infinite;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
  margin: 20px 0 0 0;
  position: relative;
  padding: 8px;
  color: ${(props) => props.theme.colors.scale[700]};
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid ${(props) => props.theme.colors.scale[400]};
    border-top: 1px solid
      ${(props) => rgba(props.theme.colors.scale[500], 0.5)};
  }
`
export const TotalServices = styled.p`
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
    align-items: baseline;
    span{
      padding: 0 4px;
    }
  `;
