import { darken, rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

const bobbleAnimate = keyframes`
  0%, 50%, 100% {
    transform: translateY(0px);
    box-shadow: var(--shadow-color) 5px 5px 5px;
  }
  25%{
    transform: translateY(-2px);
    box-shadow: var(--shadow-color) 5px 8px 8px;
  }

  75%{
    transform: translateY(2px);
    box-shadow: var(--shadow-color) 5px 2px 2px;
  }
`

export const Container = styled.div`
  --shadow-color :${(props) => rgba(darken(.2, props.theme.colors.warning), .3)};
  padding: 8px 12px 12px 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 2px;
  width: 100%;
  position: relative;
  box-shadow: var(--shadow-color) 5px 5px 5px;
  background: ${props => rgba(props.theme.colors.warning, .1)};
  border: 1px solid ${(props) => rgba(darken(.2, props.theme.colors.warning), .2)};
  animation: ${bobbleAnimate} ${props => props.theme.animation.delay.lazy} ease-in 4;
  span{
    color: ${(props) => darken(.3, props.theme.colors.warning)};
    padding: 0 8px;
  }
  a{
    color: ${props => props.theme.colors.scale[700]};
    text-decoration: none;
    position: relative;
    font-weight: bold;
    margin: 0 2px;
    padding: 4px;
    &::after{
      content: '';
      position: absolute;
      width: 0%;
      height: 1px;
      left: 50%;
      bottom: 4px;
      transform: translateX(-50%);
      transform-origin: center;
      background: currentColor;
      transition: all ${props => props.theme.animation.delay.fast} ease-in-out;
    }
    &:hover{
      &::after{
        width: 105%;
      }
    }
  }
`;

