import styled from 'styled-components'
import { Table } from "../../../../components/ui/Table";

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StatusDot = styled.u`
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px;
  border: 1px solid #000;
`
