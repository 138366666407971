import uuid from 'react-uuid'

function formatPlans(plans, rules = false) {
  const formatedPlans = []

  plans[0].values.forEach((plan) => {
    const planRule = rules.find((rule) => rule.type === plan.name)
    const factor = planRule ? planRule.factor : 3

    formatedPlans.push({
      id: uuid(),
      factor,
      oldFactor: factor,
      name: plan.name,
      sizes: [],
    })
  })

  plans.forEach((plan) => {
    const sizeName = plan.name

    plan.values.forEach((value) => {
      const index = formatedPlans.findIndex(
        (formatedPlan) => formatedPlan.name === value.name,
      )
      if (~index) {
        formatedPlans[index].sizes.push({
          id: uuid(),
          name: sizeName,
          value: value.price,
        })
      }
    })
  })

  return formatedPlans
}

export const format = {
  plans: (plans, rules) => formatPlans(plans, rules),
}
