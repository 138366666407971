import { create } from 'zustand'
import { produce } from 'immer'

export const useTooltip = create((set) => ({
  data: {
    tooltips: [],
    status: [],
  },
  actions: {
    isOpen: (tooltip) =>
      set((state) =>
        produce(state, (draft) => {
          if (!tooltip) {
            draft.data.status = state.data.status.map(() => false)
            return
          }

          const { name, open } = tooltip
          const index = draft.data.tooltips.indexOf(name)

          if (!~index) {
            draft.data.tooltips.push(name)
            draft.data.status.push(open)
            return
          }

          draft.data.status[index] = tooltip.open
        }),
      ),
    setTooltip: (name) =>
      set((state) =>
        produce(state, (draft) => {
          const index = draft.data.tooltips.indexOf(name)
          if (!~index) {
            draft.data.tooltips.push(name)
            draft.data.status.push(false)
          }
        }),
      ),
  },
}))
