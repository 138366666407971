import { useSubscribers } from "../../../../store/subscription/useSubscribers";

import { SubscriberController } from "../../../../services/controllers/subscription";

import { Alert } from "../../../../components/ui/Alert";

export function AlertCancelSubscription(){
  const details = useSubscribers(st => st.data.details)

  const CSubscriber = SubscriberController();

  if(!details) return null

  return (
      <Alert.Root name={CSubscriber.cancelSubscriptionAlertName}>
        <Alert.Content>
          <Alert.Title>Cancelar Assinatura</Alert.Title>
          <Alert.Description>
            <span>Ao clicar em <strong className="highlight">Enviar SMS/Email</strong>, será enviada uma mensagem para o cliente {details.customer.name.fullName}. O que irá acontecer:</span>
          </Alert.Description>
          <ul>
            <li>Cliente irá receber por SMS e por Email um link para <strong className="highlight">CANCELAMENTO DA ASSINATURA</strong>;</li>
            <li>Quando entrar nesse link, o cliente deverá confirmar a solicitação de cancelamento;</li>
            <li>O cancelamento é irreversível.</li>
            <li>A assinatura permanecerá <strong className="highlight">ATIVA</strong> até a data da próxima cobrança;</li>
            <li>O link expira em até 24 horas;</li>
         </ul>
          <Alert.Actions>
           <Alert.Cancel>Cancelar</Alert.Cancel>
            <Alert.Confirm
              onClick={() =>CSubscriber.cancelSubscribers(false,true)}
            >
              Enviar SMS/Email
            </Alert.Confirm>
          </Alert.Actions>
        </Alert.Content>
      </Alert.Root>
  )
}
