import { useRef, useEffect } from 'react'

import { usePageVisibility } from 'react-page-visibility';

export function PageVisibilityAction({
  onVisibility = () => null,
  onHidden = () => null,
}) {
  const firstRender = useRef(true)
  const isVisible = usePageVisibility()

  useEffect(() => {
    if (!firstRender.current) {
      if (isVisible) onVisibility()
      if (!isVisible) onHidden()
    }
    firstRender.current = false
  }, [isVisible])

  return null;
}
