import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  selectAttachment: false,
  loadedThumbnails: false,
  attachments: {},
};

export const useAttachment = create((set) => ({
  data: defaultData,
  actions: {
    setAttachments: (attachments = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!attachments) return;

          draft.data.attachments = {};

          attachments.forEach((attachment) => {
            draft.data.attachments[attachment.id] = {
              ...attachment,
              returned: false,
              removed: false,
            };
          });
        })
      ),
    clearAttachments: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        })
      ),
    setSelectAttachment: (attachment = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.selectAttachment = attachment;
        })
      ),

    removeAttachment: (attachmentId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!attachmentId) return;

          draft.data.attachments[attachmentId].returned = false;
          draft.data.attachments[attachmentId].removed = true;
        })
      ),
    returnAttachment: (attachmentId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!attachmentId) return;

          draft.data.attachments[attachmentId].returned = true;
          draft.data.attachments[attachmentId].removed = false;
        })
      ),
    deleteAttachment: (attachmentId = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!attachmentId) return;
          delete draft.data.attachments[attachmentId];
        })
      ),
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),
    setLoadedThumbnailsStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.loadedThumbnails = status;
        })
      ),
  },
}));
