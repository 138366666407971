import { ArrowRightToLine, ClipboardList, Save } from "lucide-react";

import { VeterinaryVaccineController } from "../../../../../../services/controllers/VeterinaryCare";
import { useVaccine } from "../../../../../../store/service/veterinary/useVaccine";

import { PET_SPECIES } from "../../../../../../styles/constant";

import {
  VACCINE_DURATION,
  VACCINE_FREQUENCY,
} from "../../../../../../store/service/veterinary/constants";

import { Action } from "../../../../../../components/ui/Action";
import { Input } from "../../../../../../components/ui/Form/Input";
import { Select } from "../../../../../../components/ui/Form/Select";

import { Description } from "./Description";

import { Container, FormActions, FormGroup, Title } from "./styles";
import { useMemo } from "react";
import { formatName } from "../../../../../../services/functions";

export function NewProtocolForm() {
  const CLVetVaccine = VeterinaryVaccineController();
  const protocolValues = useVaccine((st) => st.data.newProtocolValues);

  const vaccineId = useVaccine((st) => st.data.vaccineValues?.vaccineId);
  const vaccinesList = useVaccine((st) => st.data.vaccinesList);

  const vaccineName = useMemo(() => {
    if (!vaccinesList || !vaccineId) return "";

    const vaccine = _.find(vaccinesList, { id: vaccineId });

    return formatName(vaccine.name);
  }, [vaccinesList, vaccineId]);

  return (
    <Container onSubmit={CLVetVaccine.saveVaccineProtocol}>
      <Title>
        <ClipboardList /> Novo Protocolo de: {vaccineName}
      </Title>
      <FormGroup>
        <Input.Root
          label="Nome do Protocolo"
          value={protocolValues.name}
          onChange={(name) => CLVetVaccine.setProtocolFieldsValue({ name })}
        />
      </FormGroup>
      <FormGroup>
        <Select.Root
          label="Espécie"
          value={protocolValues.species}
          onChange={(species) =>
            CLVetVaccine.setProtocolFieldsValue({ species })
          }
        >
          {Object.keys(PET_SPECIES).map((specie) => (
            <Select.Option key={specie} value={specie}>
              {formatName(PET_SPECIES[specie][0])}
            </Select.Option>
          ))}
        </Select.Root>
      </FormGroup>

      <FormGroup>
        <Input.Root
          money
          noSuffix
          decimals={0}
          label="Frequência"
          value={protocolValues.frequency}
          onChange={(frequency) =>
            CLVetVaccine.setProtocolFieldsValue({ frequency })
          }
        />

        <Select.Root
          label="Período"
          value={protocolValues.frequencyPeriod}
          onChange={(frequencyPeriod) =>
            CLVetVaccine.setProtocolFieldsValue({ frequencyPeriod })
          }
        >
          {Object.keys(VACCINE_FREQUENCY).map((frequencyPeriod) => (
            <Select.Option key={frequencyPeriod} value={frequencyPeriod}>
              {formatName(VACCINE_FREQUENCY[frequencyPeriod][0])}
            </Select.Option>
          ))}
        </Select.Root>
      </FormGroup>

      <FormGroup>
        <Input.Root
          money
          noSuffix
          decimals={0}
          label="Duração"
          value={protocolValues.duration}
          onChange={(duration) =>
            CLVetVaccine.setProtocolFieldsValue({ duration })
          }
        />

        <Select.Root
          label="Período"
          value={protocolValues.durationPeriod}
          onChange={(durationPeriod) =>
            CLVetVaccine.setProtocolFieldsValue({ durationPeriod })
          }
        >
          {Object.keys(VACCINE_DURATION).map((durationPeriod) => (
            <Select.Option key={durationPeriod} value={durationPeriod}>
              {formatName(VACCINE_DURATION[durationPeriod][0])}
            </Select.Option>
          ))}
        </Select.Root>
      </FormGroup>

      <Description />
      <FormActions>
        <Action.Root
          outline
          status="danger"
          type="button"
          onClick={() => CLVetVaccine.closeNewProtocol()}
        >
          <Action.Icon icon={ArrowRightToLine} />
          Cancelar
        </Action.Root>
        <div>
          <Action.Root
            status="success"
            disabled={protocolValues.updated || protocolValues.loading}
          >
            <Action.Icon icon={Save} isLoading={protocolValues.loading} />
            Salvar
          </Action.Root>
        </div>
      </FormActions>
    </Container>
  );
}
