import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Concorrentes = styled.div`
  padding: 10px 15px;
  overflow-y: auto;
  height: 100%;
`

export const ConcorrenteLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 120px;
  padding: 5px 10px;
  transition: all 250ms ease;
  &:nth-child(2) {
    border-radius: 10px 10px 0 0;
  }
  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  &:hover:not(:first-child) {
    background: ${(props) => props.theme.colors.gray[50]};
  }
`

export const Header = styled(ConcorrenteLine)``
export const HeaderItem = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.colors.scale[600]};
`
export const Concorrente = styled(ConcorrenteLine)`
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[500]};
  &:last-child {
    border: none;
  }
`
export const ConcorrenteSale = styled.div`
  text-align: left;
  padding-left: 20px;
`
export const ConcorrenteAccomplished = styled.div`
  text-align: center;
`
