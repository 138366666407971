import moment from "moment";
import { VACCINATION_STATUS_FIELDS } from "../../../../../../../store/service/veterinary/constants";

import { VeterinaryVaccineController } from "../../../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../../../components/ui/Form/Input";

export function VaccineApplicationDate({ vaccine }) {
  const CLVetVaccine = VeterinaryVaccineController();

  if (!VACCINATION_STATUS_FIELDS[vaccine.status||'scheduled'].includes("applyDate")) {
    return vaccine?.applicationDate || "";
  }

  if (!vaccine.applicationDate) {
    CLVetVaccine.setAssignedVaccineFieldsValue(vaccine.id, {
      applicationDate: moment().format("DD/MM/YYYY"),
    });
  }

  return (
    <Input.Root
      label="Aplicação"
      mask="99/99/9999"
      value={vaccine?.applicationDate}
      onChange={(applicationDate) => {
        CLVetVaccine.setAssignedVaccineFieldsValue(vaccine.id, {
          applicationDate,
        });
      }}
    />
  );
}
