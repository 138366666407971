
import axios from 'axios';
import _ from 'lodash';

import * as pdfjsLib from 'pdfjs-dist';
pdfjsLib.GlobalWorkerOptions.workerSrc = '//unpkg.com/pdfjs-dist@4.0.379/build/pdf.worker.min.mjs';

import { getDepartmentName } from './functions';

const departmentName = getDepartmentName()

function getAspectRatio(originalSize = false, size = 300) {
  if (!originalSize) {
    throw new Error("thumbnail - getAspectRation: originalSize não informado!");
  }

  size = !!size ? size : originalSize.width

  const scale = size / originalSize.width;
  const width = size;
  const height = originalSize.height * scale;

  return {
    scale,
    width,
    height,
  }
}


function resizeCanvas(originalCanvas = false, size) {
  if (!originalCanvas) return ''
  const aspectRatio = getAspectRatio(originalCanvas, size)

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = aspectRatio.width;
  canvas.height = aspectRatio.height;

  context.drawImage(
    originalCanvas,
    0,
    0,
    originalCanvas.width,
    originalCanvas.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return canvas.toDataURL('image/jpeg')
}



async function imageThumbnail(filePath, sizes = []) {
  if (_.isEmpty(sizes)) return `/img/${departmentName}/logo-dark.png`

  try {
    const originalSizeImage = new Image();
    originalSizeImage.src = filePath;
    originalSizeImage.crossOrigin = 'Anonymous';

    await new Promise((resolve, reject) => {
      originalSizeImage.onload = resolve;
      originalSizeImage.onerror = reject;
    });

    return _.reduce(sizes, (thumbnails, size) => {
      thumbnails[size] = resizeCanvas(originalSizeImage, size)
      return thumbnails
    }, {})
  } catch (err) {
    console.error('Error generating image thumbnail:', err);

    return _.reduce(sizes, (erroThumbnails, size) => {
      erroThumbnails[size] = '/img/${departmentName}/logo-dark.png'
      return erroThumbnails
    }, {})
  }
}

async function getPDFThumbnail(path, sizes = []) {
  if (_.isEmpty(sizes)) return `/img/${departmentName}/logo-dark.png`

  try {
    const pdfDoc = await pdfjsLib.getDocument(path).promise;
    const page = await pdfDoc.getPage(1);

    const scale = 1;

    const originalSizeViewport = page.getViewport({ scale });

    const originalSize = {
      width: page.getViewport({ scale }).width,
      height: page.getViewport({ scale }).height,
    }

    const originalSizeCanvas = document.createElement('canvas');
    const originalSizeContext = originalSizeCanvas.getContext('2d');

    originalSizeCanvas.width = originalSize.width;
    originalSizeCanvas.height = originalSize.height;

    const renderContext = {
      canvasContext: originalSizeContext,
      viewport: originalSizeViewport
    };

    await page.render(renderContext).promise;

    return _.reduce(sizes, (thumbnails, size) => {
      thumbnails[size] = resizeCanvas(originalSizeCanvas, size)
      return thumbnails
    }, {})
  } catch (_err) {
    console.error('Error generating image thumbnail:', err);

    return _.reduce(sizes, (erroThumbnails, size) => {
      erroThumbnails[size] = '/img/${departmentName}/logo-dark.png'
      return erroThumbnails
    }, {})
  }
};

async function get(type, ...args) {
  switch (type) {
    case 'img':
      return imageThumbnail(...args);
    case 'pdf':
      return getPDFThumbnail(...args);
    default:
      return imageThumbnail(...args);
  }
}



export const thumbnail = {
  from: get,
  fromPDF: getPDFThumbnail,
  fromImage: imageThumbnail
}
