import { Table } from "../../../../../components/ui/Table"
import { useSubscribers } from "../../../../../store/subscription/useSubscribers"

import { Status } from '../../Status'
import { CycleActions } from "./CycleActions"
export function SubscriptionDetailsCycles() {
  const cycles = useSubscribers(st => st.data.details.cycles)

  return (
    <Table.Root name='subscriptionDetailsCycles-table'>
      <Table.Header
        columns={[
          "Data:100px",
          "Preço:80px",
          "Status:120px",
          "Mensagem:minmax(0px, 1fr)",
          ":80px"
        ]}
      />
      <Table.Content>
        {!!cycles && cycles.map((cycle) => (
          <Table.Line key={cycle.id}>
            <Table.Item>{cycle.billingAt}</Table.Item>
            <Table.Item>{cycle.paidAmount}</Table.Item>
            <Table.Item><Status>{cycle.status.text}</Status></Table.Item>
            <Table.Item>{cycle.message || "-"}</Table.Item>
            <Table.Item><CycleActions cycle={cycle} /></Table.Item>
          </Table.Line>
        ))}
      </Table.Content>
    </Table.Root>
  )
}
