import qs from "qs";
import _ from "lodash";
import { toast } from "react-toastify";

import API from "../../api";

import { formatResponse } from "../../formatResponses/reports";
import { useRoyaltyStatements } from "../../../store/reports/useRoyaltyStatements";

export default class RoyaltyStatementModel {
  setRoyaltyStatement = useRoyaltyStatements.getState().actions.setRoyaltyStatement;

  clearRoyaltyStatement = useRoyaltyStatements.getState().actions.clearRoyaltyStatement;

  setLoading = useRoyaltyStatements.getState().actions.setLoading;
  setWaiting = useRoyaltyStatements.getState().actions.setWaiting;
  setPaginationPage = useRoyaltyStatements.getState().actions.setPaginationPage;

  constructor() {
    this.name = "reports-royalty-statement";
    this.reportName = `${this.name}-report`;

  }

  export() {
    console.log('Exportar Relátórtio de Royalty');
  }

  async setPage(page) {
    const pageExists = useRoyaltyStatements.getState().data.royaltyStatement[page];
    this.setPaginationPage(page);

    if (!pageExists) await this.findRoyaltyStatements();
  }

  async findRoyaltyStatements() {
    await this.getRoyaltyStatement();
  }

  async filterRoyaltyStatements() {
    this.setLoading(true);
    this.setPaginationPage(0);
    this.clearRoyaltyStatement();
    await this.getRoyaltyStatement();
    this.setLoading(false);
  }

  async getRoyaltyStatement() {
    try {
      this.setWaiting(true);
      const query = this.queryParams();

      const { data } = await API.get(`/extrato-royalties/relatorio?${query}`);

      const formattedRoyaltyStatement = formatResponse.royalties(data.relatorio);
      const pagination = formatResponse.pagination(data.paginacao);

      this.setRoyaltyStatement(formattedRoyaltyStatement, pagination);
      this.setWaiting(false);
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      this.setWaiting(false);
      return null;
    }
  }

  queryParams() {
    const pagination = useRoyaltyStatements.getState().data.pagination;

    const objectParams = _.pickBy({
        pagina: pagination.page || 0,
        porPagina: pagination.perPage || 10,
      }, _.identity);

    return qs.stringify(objectParams);
  }
}
