import ReportVaccinationsClass from "./ReportVaccinations";

let MReportVaccinations = null;

export function ReportVaccinationsModel() {
  if (MReportVaccinations) {
    return MReportVaccinations;
  }

  MReportVaccinations = new ReportVaccinationsClass();

  return MReportVaccinations;
}
