import { Legend, Space } from './styles'

export function TextAreaLabel({ children }) {
  return (
    <>
      <Space>{children}</Space>
      <Legend>{children}</Legend>
    </>
  )
}
