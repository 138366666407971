import React from 'react'
import { MenuItem, TextField } from '@mui/material'

import { useSchedule } from '../../../../hooks/ScheduleContext'

import { Container } from './styles'

function SelectGap() {
  const { updateHours, getHours } = useSchedule()
  const hours = getHours()

  return (
    <Container>
      <TextField
        fullWidth
        select
        name="gap"
        size="small"
        label="Intervalo"
        variant="outlined"
        value={(hours.gap / 60).toString()}
        onChange={(e) => {
          updateHours({ gap: +e.target.value * 60 })
        }}
      >
        <MenuItem value="5">5 min</MenuItem>
        <MenuItem value="10">10 min</MenuItem>
        <MenuItem value="15">15 min</MenuItem>
        <MenuItem value="20">20 min</MenuItem>
        <MenuItem value="25">25 min</MenuItem>
        <MenuItem value="30">30min</MenuItem>
      </TextField>
    </Container>
  )
}

export default SelectGap
