import { ListPreviewHeader } from "./ListPreviewHeader";
import { ListPreviewInfo } from "./ListPreviewInfo";
import { ListPreviewInfoActions } from "./ListPreviewInfoActions";
import { ListPreviewInfoContent } from "./ListPreviewInfoContent";
import { ListPreviewInfoData } from "./ListPreviewInfoData";
import { ListPreviewRoot } from "./ListPreviewRoot";
import { useListPreview } from "./useListPreview";

export const ListPreview = {
  Root: ListPreviewRoot,
  Header: ListPreviewHeader,
  Info: ListPreviewInfo,
  InfoActions: ListPreviewInfoActions,
  InfoContent: ListPreviewInfoContent,
  InfoData: ListPreviewInfoData,
  store: useListPreview,
};
