import React, { useState } from 'react'
import {
  CalendarToday,
  AssignmentOutlined,
  AttachFile,
  Colorize,
} from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'

// moment
import moment from 'moment'
import 'moment/locale/pt-br'

import { Drawer } from '@material-ui/core'
import styles from './styles'
import DrawerContent from '../../../../components/organisms/MainCheckinClinica/DrawerContent'

moment.locale('pt-br')

function Box({ VetRecordsData }) {
  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)

  const {
    avatar,
    grid,
    item,
    itemOpen,
    badge,
    content,
    contentTitle,
    contentText,
    contentLine,
    contentOpen,
    badgeContent,
    pet,
    petName,
    buttonBox,
    more,
    cardButton,
  } = styles()

  const {
    anamnesis,
    appointmentDate,
    appointmentOrderId,
    attachment,
    petName: name,
    prescriptions,
    startsAt,
    vaccination,
    veterinaryProcedureName,
  } = VetRecordsData

  return (
    <Grid item xs={12} className={grid}>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
      >
        <DrawerContent
          orderId={appointmentOrderId}
          close={() => {
            setOpenDrawer(false)
          }}
        />
      </Drawer>

      <Grid item xs={12} className={open ? itemOpen : item}>
        <span className={badge} style={{ background: '#00567E' }}>
          <dd className={badgeContent}>
            {veterinaryProcedureName}

            {prescriptions && <AssignmentOutlined />}
            {attachment && <AttachFile />}
            {vaccination && <Colorize />}
          </dd>
          <dd className={badgeContent}>
            <CalendarToday /> {moment(appointmentDate).format('DD/MM/YYYY')} às{' '}
            {startsAt}
          </dd>
        </span>
        <div className={pet}>
          <div className={avatar}>{!!name && name.charAt(0)}</div>
          <p className={petName}> {!!name && name}</p>
        </div>

        <div className={open ? contentOpen : content}>
          <h3 className={contentTitle}>Anamnese</h3>
          <p className={contentText}>
            {anamnesis.mainComplaint ? anamnesis.mainComplaint : '-'}
          </p>
          <div className={contentLine}>
            <div>
              <h3 className={contentTitle}>FC (bpm)</h3>
              <p className={contentText}>
                {anamnesis.exam.heartRate ? anamnesis.exam.heartRate : '-'}
              </p>
            </div>
            <div>
              <h3 className={contentTitle}>FR (mpm)</h3>
              <p className={contentText}>
                {anamnesis.exam.respiratoryRate
                  ? anamnesis.exam.respiratoryRate
                  : '-'}
              </p>
            </div>
            <div>
              <h3 className={contentTitle}>TEMPERATURA</h3>
              <p className={contentText}>
                {anamnesis.exam.temperature ? anamnesis.exam.temperature : '-'}
              </p>
            </div>
            <div>
              <h3 className={contentTitle}>TPC (seg)</h3>
              <p className={contentText}>
                {anamnesis.exam.TPC ? anamnesis.exam.TPC : '-'}
              </p>
            </div>
          </div>

          <h3 className={contentTitle}>OUVIDOS</h3>
          <p className={contentText}>
            {anamnesis.exam.earsCondition ? anamnesis.exam.earsCondition : '-'}
          </p>

          <h3 className={contentTitle}>MUCOSAS</h3>
          <p className={contentText}>
            {anamnesis.exam.mucosa ? anamnesis.exam.mucosa : '-'}
          </p>

          <h3 className={contentTitle}>HIDRATAÇÃO</h3>
          <p className={contentText}>
            {anamnesis.exam.hydration ? anamnesis.exam.hydration : '-'}
          </p>

          <h3 className={contentTitle}>LINFONODOS</h3>
          <p className={contentText}>
            {anamnesis.exam.lymphNode ? anamnesis.exam.lymphNode : '-'}
          </p>

          <h3 className={contentTitle}>PELE/PELO</h3>
          <p className={contentText}>
            {anamnesis.exam.skinFur ? anamnesis.exam.skinFur : '-'}
          </p>

          <h3 className={contentTitle}>MEMBROS TORÁCICOS</h3>
          <p className={contentText}>
            {anamnesis.exam.thoracicLimbs ? anamnesis.exam.thoracicLimbs : '-'}
          </p>

          <h3 className={contentTitle}>TÓRAX</h3>
          <p className={contentText}>
            {anamnesis.exam.chest ? anamnesis.exam.chest : '-'}
          </p>

          <h3 className={contentTitle}>MEMBROS PÉLVICOS</h3>
          <p className={contentText}>
            {anamnesis.exam.pelvicLimbs ? anamnesis.exam.pelvicLimbs : '-'}
          </p>

          <h3 className={contentTitle}>ABDOME</h3>
          <p className={contentText}>
            {anamnesis.exam.abdomen ? anamnesis.exam.abdomen : '-'}
          </p>

          <h3 className={contentTitle}>Diagnóstico e Tratamento</h3>
          <p className={contentText}>
            {anamnesis.diagnosisAndTreatment
              ? anamnesis.diagnosisAndTreatment
              : '-'}
          </p>
        </div>
        <span
          className={more}
          onClick={() => {
            setOpen((open) => !open)
          }}
        >
          {!open ? 'Ver Anamnese Completa...' : 'Fechar Anamnese Completa'}
        </span>
        <div className={buttonBox}>
          <button className={cardButton} onClick={() => setOpenDrawer(true)}>
            Ver detalhes
          </button>
        </div>
      </Grid>
    </Grid>
  )
}

export default Box
