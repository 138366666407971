import { Close } from '@mui/icons-material'

import { CloseButton as Container } from './styles'

export function PopoverClose() {
  return (
    <Container aria-label="Close">
      <Close />
    </Container>
  )
}
