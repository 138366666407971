import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 0px;
`

export const DayCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 5px;
`

export const DivContainer = styled.div`
  display: block;
  padding-right: 60px;
`

export const ItemContainer = styled.div`
  display: flex;
  padding: 6px 0px;

  & span {
    color: #666666;
  }
`
