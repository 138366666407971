import { LineChart } from "lucide-react";

import { isAbleByStatus } from "../../../../../services/functions";

import { usePet } from "../../../../../store/global/usePet";
import { useCare } from "../../../../../store/service/veterinary/useCare";
import { useRecord } from "../../../../../store/service/veterinary/useRecord";

import { VeterinaryRecordController } from "../../../../../services/controllers/VeterinaryCare";

import { Action } from "../../../../../components/ui/Action";

import { Editor } from "../../../../../components/ui/Form/Editor";
import { Input } from "../../../../../components/ui/Form/Input";

import { ANAMNESE_DEFAULT_CONTENT } from "./editorValues";

import { Container } from "./styles";

export function RecordsContentsGeneralInformation() {
  const pet = usePet((st) => st.data.pet);

  const careStatus = useCare(st => st.data.care.status)

  const weight = useRecord((st) => st.data.record?.weight);
  const anamnesis = useRecord((st) => st.data.record?.anamnesis);
  const isLoading = useRecord((st) => st.data.isLoading);

  const CLVetRecord = VeterinaryRecordController();

  const isAble = isAbleByStatus(careStatus,CLVetRecord._name)

  return (
    <Container>
      <Input.Root
        money
        noSuffix
        decimals={3}
        value={weight}
        label="Peso (Kg)"
        disabled={!isAble}
        style={{ maxWidth: "fit-content" }}
        onChange={(weight) => {
          CLVetRecord.changeRecordValue({ weight });
        }}
        onBlur={CLVetRecord.saveGeneralInformation}
      />
      <Action.Root
        type="button"
        style={{ maxWidth: "fit-content" }}
        disabled={!pet.id}
        onClick={CLVetRecord.openWeightHistory}
      >
        <Action.Icon icon={LineChart} />
      </Action.Root>
      <Editor
        label="Anamnese"
        content={anamnesis}
        disabled={isLoading || !isAble}
        placeholder={ANAMNESE_DEFAULT_CONTENT}
        onChange={(anamnesis) => {
          CLVetRecord.changeRecordValue({ anamnesis });
          CLVetRecord.saveGeneralInformation()
        }}
      />
    </Container>
  );
}
