import { memo } from "react";

import { useService } from "../../../../../../store/service/veterinary/useService";

import { VeterinaryServiceController } from "../../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../../components/ui/Form/Input";
import { isAbleByStatus } from "../../../../../../services/functions";
import { useCare } from "../../../../../../store/service/veterinary/useCare";

export const FindService = memo(({ serviceKey }) => {
  const CLVetServices = VeterinaryServiceController();

  const careStatus = useCare((st) => st.data.care.status);

  const isLoading = useService((st) => st.data.isLoading);
  const serviceName = useService((st) => st.data.services[serviceKey].suggestionName);
  const foundServices = useService((st) => st.data.services[serviceKey].foundServices);

  const isAble = isAbleByStatus(careStatus,CLVetServices._name)

  return (
    <Input.Root
      disabled={!isAble || isLoading}
      label={isLoading ? "Carregando..." : ""}
      defaultValue={serviceName}
      parser={CLVetServices._searchSuggestionParser}
      suggestions={foundServices}
      onSelected={(selected) => {
        CLVetServices.selectService(serviceKey, selected);
      }}
      onChange={(term) => {
        CLVetServices.searchService(serviceKey, term);
      }}
    />
  );
});
