import React, { useContext, useState } from 'react'

export const ThemesContext = React.createContext({})

function ThemesProvider({ children }) {
  const storageTheme = localStorage.getItem('theme')
  const [theme, setTheme] = useState(storageTheme)

  const toggleTheme = (themeValue) => {
    localStorage.setItem('theme', themeValue.toString())
    window.dispatchEvent(new Event('storage'))
    setTheme(themeValue)
  }

  return (
    <ThemesContext.Provider value={{ toggleTheme, theme, setTheme }}>
      {children}
    </ThemesContext.Provider>
  )
}

function useThemes() {
  const context = useContext(ThemesContext)
  if (!context) {
    throw new Error('useThemes must be used within an ThemesProvider')
  }
  return context
}

export { useThemes, ThemesProvider }
