import { QueryBuilder } from '@mui/icons-material'

import { Container, HeadItem } from './styles'
import { useSchedule } from '../../../hooks/ScheduleContext'
import { useEmployee } from '../../../store/schedule/useEmployee'

export function Head() {
  const { getEmployees } = useSchedule()
  const {
    employee: { size },
  } = useEmployee()
  const employees = getEmployees()

  return (
    <Container totalEmployees={employees.length} collumnSize={size}>
      <HeadItem>
        <QueryBuilder />
      </HeadItem>
      {!!employees &&
        employees.map((employee) => (
          <HeadItem key={employee.id}>{employee.name}</HeadItem>
        ))}
    </Container>
  )
}
