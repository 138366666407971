

import { SCHEDULES_STATUS_MESSAGE } from "../../../store/schedule/constants";

import {
  ProcedureController,
  ScheduleController,
} from "../../../services/controllers/scheduling";
import { useSchedule } from "../../../store/schedule/useSchedule";

import { formatName, getEmployeeTypeName } from "../../../services/functions";

import { Input } from "../../ui/Form/Input";
import { Select } from "../../ui/Form/Select";
import { Table } from "../../ui/Table";

import { Waiting } from "../components/Waiting";
import { Actions } from "../components/Actions";
import { SelectedPetCard } from "../components/SelectedPetCard";

import { Container, Content, FieldsBox } from "./styles";
import { SendBudget } from "../components/Alerts/SendBudget";

export function Finished() {
  const CLScheduling = ScheduleController();
  const CLProcedure = ProcedureController();

  const details = useSchedule((st) => st.data.details);

  const procedure = useSchedule((st) => st.data.procedure);
  const procedureServices = useSchedule((st) => st.data.procedure.services);

  const sellers = useSchedule((st) => st.data.customer.sellers);
  const services = useSchedule((st) => st.data.customer.services);

  return (
    <>
      <Container>
        <SelectedPetCard />
        <Content onSubmit={CLScheduling.sendBudget}>
          <FieldsBox>
            <Waiting
              color={SCHEDULES_STATUS_MESSAGE[CLScheduling.status].color}
              style={{ minWidth: "100%" }}
            >
              {SCHEDULES_STATUS_MESSAGE[CLScheduling.status].value || ""}
            </Waiting>
            <Input.Root
              label="Data"
              value={procedure.date}
              style={{ maxWidth: "300px" }}
              disabled
            />
            <Input.Root
              label="Hora de início"
              mask="99:99"
              value={procedure.time}
              style={{ minWidth: "130px" }}
              disabled
            />
            <Input.Root
              label={getEmployeeTypeName()}
              value={formatName(procedure.employee?.name)}
              style={{ minWidth: "325px" }}
              disabled
            />
            <Input.Root
              label="Procedimento"
              value={formatName(procedure.data?.name)}
              style={{ minWidth: "240px" }}
              disabled
            />

            <Select.Root
              label="Vendedor(a)"
              value={details?.seller || ''}
              styles={{ minWidth: "140px" }}
              onChange={(seller) => {
                CLScheduling.setDetails({ seller });
              }}
            >
              {sellers.map((seller) => (
                <Select.Option key={seller.id} value={seller.id}>
                  {formatName(seller.name)}
                </Select.Option>
              ))}
            </Select.Root>
            <Select.Root
              label="Adicionar Serviços"
              disabled={!services.length}
              onChange={(serviceId) => {
                CLProcedure.addNewService(serviceId);
              }}
            >
              {services.map((service) => (
                <Select.Option
                  key={service.serviceId}
                  value={service.serviceId}
                >
                  {formatName(service.serviceName)}
                </Select.Option>
              ))}
            </Select.Root>
            <Table.Root
              name="schedule-services-table"
              style={{ minWidth: "100%", height: "300px", maxHeight: "100%" }}
              action={{
                add: 'hidden',
                remove: CLProcedure.removeService,
              }}
            >
              <Table.Header columns={["Serviços", "Valor"]} />
              <Table.Content
                style={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 702px)",
                  minHeight: "50px",
                }}
              >
                {procedureServices.map((service) => (
                  <Table.Line key={service.serviceId} id={service.serviceId}>
                    <Table.Item>{formatName(service.serviceName)}</Table.Item>
                    <Table.Item>
                      <Input.Root
                        money
                        value={service.price}
                        onChange={(price) => {
                          CLProcedure.updateService(service.serviceId, {
                            price,
                          });
                        }}
                      />
                    </Table.Item>
                  </Table.Line>
                ))}
              </Table.Content>
            </Table.Root>
          </FieldsBox>
          <Actions
            reset
            submit={{
              text: "Enviar Orçamento",
            }}
          />
        </Content>
      </Container>
      <SendBudget />
    </>
  );
}
