import React, { useCallback, useEffect, useState } from 'react'

import { Edit } from '@material-ui/icons'
import API from '../../../../services/api'

import DrawerType from '../../../../components/atoms/Drawer'

import EditDrawer from './Edit'
import Log from './Log'
import styles from './styles'

function EditPackage({ id }) {
  const [packageData, setPackageData] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)

  const getPackagesDetails = useCallback(async () => {
    try {
      const { data } = await API.get(`/package-details/${id}`)
      setPackageData(data)
    } catch (err) {
      console.log('Erro:', err)
    }
  }, [])

  const closeUpdate = async () => {
    await getPackagesDetails()
    setOpenDrawer(false)
  }

  useEffect(() => {
    getPackagesDetails()
  }, [getPackagesDetails])

  const {
    title,
    text,
    container,
    table,
    header,
    item,
    itemNumber,
    itemDate,
    line,
    itemButton,
    button,
    drawerBox,
    itemTag,
  } = styles()

  return (
    <div className={container}>
      <DrawerType
        open={!!openDrawer}
        setDrowerOpen={setOpenDrawer}
        titleHeader="Editar serviço de pacote"
        content={
          <div className={drawerBox}>
            <EditDrawer data={openDrawer} />
          </div>
        }
      />

      <h2 className={title}>
        {!!packageData && packageData.packageInfo.promotionName}
      </h2>
      <p className={text}>
        Preço original:{' '}
        {!!packageData &&
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(packageData.packageInfo.originalPrice)}
      </p>
      <p className={text}>
        Desconto:{' '}
        {!!packageData &&
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(packageData.packageInfo.discount)}
      </p>
      <p className={text}>
        Preço total:{' '}
        {!!packageData &&
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(packageData.packageInfo.totalPrice)}
      </p>
      <p className={text}>
        Data da venda: {!!packageData && packageData.packageInfo.soldAt}
      </p>

      <div className={table}>
        <div className={header}>
          <span className={item}>Serviço</span>
          <span className={itemNumber}>Vendido</span>
          <span className={itemNumber}>Disponível</span>
          <span className={itemButton} />
        </div>
        {!!packageData &&
          packageData.services.map((service) => (
            <div key={service.serviceId} className={line}>
              <span className={item}>{service.serviceName}</span>
              <span className={itemNumber}>{service.quantitySold}</span>
              <span className={itemNumber}>{service.quantityAvailable}</span>
              <span className={itemButton}>
                <button
                  className={button}
                  onClick={() => {
                    setOpenDrawer({
                      packageId: id,
                      serviceName: service.serviceName,
                      serviceId: service.serviceId,
                      quantityAvailable: service.quantityAvailable,
                      quantitySold: service.quantitySold,
                      closeUpdate,
                    })
                  }}
                >
                  <Edit />
                </button>
              </span>
            </div>
          ))}
      </div>

      <div className={table}>
        <div className={header}>
          <span className={itemTag} />
          <span className={itemDate}>Data</span>
          <span className={item}>Serviço</span>
        </div>
        {!!packageData &&
          packageData.logsData.map((log) => (
            <Log key={log.serviceName} logdata={log} />
          ))}
      </div>
    </div>
  )
}

export default EditPackage
