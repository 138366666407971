import { TableContent } from './TableContent'
import { TableHeader } from './TableHeader'
import { TableItem } from './TableItem'
import { TableLine } from './TableLine'
import { TableRoot } from './TableRoot'
import { useTable } from './useTable'

export const Table = {
  Root: TableRoot,
  Header: TableHeader,
  Content: TableContent,
  Line: TableLine,
  Item: TableItem,
  store: useTable,
}
