import styled from 'styled-components';

export const StatusPlaceholder = styled.span`
  margin: 0;
    font-size: 16px;
    color:${props => props.theme.colors.scale[600]}
`;

  export const ActionsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    &>div{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;
