import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 18px 12px;
  margin: 18px 0;
  & > * {
    flex: 1;
    width: 100%;
    min-width: fit-content;
  }
`;
