import moment from 'moment'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import API from '../services/api'

const TaxiDogContext = createContext({})

function TaxiDogProvider({ children }) {
  const [drivers, setDrivers] = useState([])
  const [filter, setFilter] = useState({
    page: 0,
    perPage: 50,
    date: '',
  })

  const [taxiDogs, setTaxiDogs] = useState([])
  const [taxiDog, setTaxiDog] = useState({
    driverId: '',
    driverName: '',
    taxiDogType: 'Somente Retirada',
    driverPickupType: 'Não',
    pickupAddress: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      postalCode: '',
    },
    deliveryAddress: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      postalCode: '',
    },
    useCustomerAddress: false,
    sameAddress: false,
    total: '',
  })

  const findTaxiDogs = useCallback(
    async (dt = false) => {
      const date = dt
        ? moment(dt).format('YYYY-MM-DD')
        : !filter.date
        ? filter.date
        : moment(filter.date).format('YYYY-MM-DD')

      try {
        setTaxiDogs(false)
        const { data } = await API.get(
          `taxi-dog/rides?page=${filter.page}&perPage=${filter.perPage}&date=${date}`,
        )
        setTaxiDogs(data)
      } catch (error) {
        console.log(error)
      }
    },
    [filter],
  )

  const getTaxiDogs = useCallback(() => taxiDogs, [taxiDogs])

  const setTaxiFilter = useCallback((data) => {
    setFilter((prev) => ({ ...prev, ...data }))
  }, [])

  const findDrivers = async () => {
    try {
      const { data } = await API.get('taxi-dog/drivers?page=0&perPage=50')
      setDrivers(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getDrivers = useCallback(() => drivers, [drivers])

  const clearTaxiDog = () => {
    setTaxiDog({
      driverId: '',
      taxiDogType: 'Somente Retirada',
      driverPickupType: 'Não',
      pickupAddress: {
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        postalCode: '',
      },
      useCustomerAddress: false,
      sameAddress: false,
      total: '',
    })
  }

  const setTaxiDogInfo = useCallback(
    (data) => {
      setTaxiDog((prev) => ({ ...prev, ...data }))
    },
    [taxiDog],
  )

  const getTaxiDogInfo = useCallback(() => taxiDog, [taxiDog])

  const exportTaxiDogs = useCallback(async () => {
    const date = !filter.date
      ? filter.date
      : moment(filter.date).format('YYYY-MM-DD')
    const { data } = await API.post(`taxi-dog/rides/export?date=${date}`)
    window.open(data.fileLocation, '_blank').focus()
  }, [filter])

  useEffect(() => {
    if (
      taxiDog.taxiDogType === 'Somente Entrega' &&
      taxiDog.sameAddress !== taxiDog.useCustomerAddress
    ) {
      setTaxiDog((prev) => ({
        ...prev,
        sameAddress: taxiDog.useCustomerAddress,
      }))
    }
  }, [taxiDog])

  return (
    <TaxiDogContext.Provider
      value={{
        findDrivers,
        getDrivers,
        setTaxiDogInfo,
        getTaxiDogInfo,
        clearTaxiDog,
        findTaxiDogs,
        getTaxiDogs,
        setTaxiFilter,
        exportTaxiDogs,
      }}
    >
      {children}
    </TaxiDogContext.Provider>
  )
}

function useTaxiDog() {
  const context = useContext(TaxiDogContext)
  if (!context) {
    throw new Error('useTaxiDog must be used within an TaxiDogProvider')
  }
  return context
}

export { useTaxiDog, TaxiDogProvider }
