import VeterinaryRecordClass from "./VeterinaryRecord";
import VeterinaryRequestClass from "./VeterinaryRequest";
import VeterinaryVaccineClass from "./VeterinaryVaccine";
import VeterinaryPrescriptionClass from "./VeterinaryPrescription";
import VeterinaryServiceClass from "./VeterinaryService";
import VeterinaryAttachmentClass from "./VeterinaryAttachment";
import VeterinaryHistoryClass from "./VeterinaryHistory";
import VeterinaryCareClass from "./VeterinaryCare";

let CLVeterinaryCare = null;
let CLVeterinaryRecord = null;
let CLVeterinaryRequest = null;
let CLVeterinaryVaccine = null;
let CLVeterinaryPrescription = null;
let CLVeterinaryService = null;
let CLVeterinaryAttachment = null;
let CLVeterinaryHistory = null;

export function VeterinaryCareController(id) {
  if (!CLVeterinaryCare && !id) {
    throw new Error(
      "Controllers - VeterinaryVaccineController: ID não informado!"
    );
  }
  if (CLVeterinaryCare && !id) {
    return CLVeterinaryCare;
  }
  if (CLVeterinaryCare && CLVeterinaryCare._ID === id) {
    return CLVeterinaryCare;
  }

  CLVeterinaryCare = new VeterinaryCareClass(id);

  return CLVeterinaryCare;
}

export function clearVeterinaryCare(){
  VeterinaryCareClass = null
}

export function VeterinaryRecordController(orderId) {
  if (!CLVeterinaryRecord && !orderId) {
    throw new Error(
      "Controllers - VeterinaryVaccineController: orderId não informado!"
    );
  }
  if (CLVeterinaryRecord && !orderId) {
    return CLVeterinaryRecord;
  }
  if (CLVeterinaryRecord && CLVeterinaryRecord._orderId === orderId) {
    return CLVeterinaryRecord;
  }

  CLVeterinaryRecord = new VeterinaryRecordClass(orderId);

  return CLVeterinaryRecord;
}

export function VeterinaryPrescriptionController(orderId, ...args) {
  if (!CLVeterinaryPrescription && !orderId) {
    throw new Error(
      "Controllers - VeterinaryPrescriptionController: orderId não informado!"
    );
  }
  if (CLVeterinaryPrescription && !orderId) {
    return CLVeterinaryPrescription;
  }
  if (CLVeterinaryPrescription && CLVeterinaryPrescription._orderId === orderId) {
    return CLVeterinaryPrescription;
  }

  CLVeterinaryPrescription = new VeterinaryPrescriptionClass(orderId, true, ...args);
  return CLVeterinaryPrescription;
}

export function VeterinaryRequestController(orderId, ...args) {
  if (!CLVeterinaryRequest && !orderId) {
    throw new Error(
      "Controllers - VeterinaryRequestController: orderId não informado!"
    );
  }
  if (CLVeterinaryRequest && !orderId) {
    return CLVeterinaryRequest;
  }
  if (CLVeterinaryRequest && CLVeterinaryRequest._orderId === orderId) {
    return CLVeterinaryRequest;
  }

  CLVeterinaryRequest = new VeterinaryRequestClass(orderId, true, ...args);

  return CLVeterinaryRequest;
}

export function VeterinaryServiceController(orderId, ...args) {
  if (!CLVeterinaryService && !orderId) {
    throw new Error(
      "Controllers - VeterinaryServiceController: orderId não informado!"
    );
  }
  if (CLVeterinaryService && !orderId) {
    return CLVeterinaryService;
  }
  if (CLVeterinaryService && CLVeterinaryService._orderId === orderId) {
    return CLVeterinaryService;
  }

  CLVeterinaryService = new VeterinaryServiceClass(orderId, true, ...args);
  return CLVeterinaryService;
}

export function VeterinaryVaccineController(orderId) {
  if (!CLVeterinaryVaccine && !orderId) {
    throw new Error(
      "Controllers - VeterinaryVaccineController: orderId não informado!"
    );
  }
  if (CLVeterinaryVaccine && !orderId) {
    return CLVeterinaryVaccine;
  }
  if (CLVeterinaryVaccine && CLVeterinaryVaccine._orderId === orderId) {
    return CLVeterinaryVaccine;
  }

  CLVeterinaryVaccine = new VeterinaryVaccineClass(orderId);
  return CLVeterinaryVaccine;
}
export function VeterinaryAttachmentController(orderId, ...args) {
  if (!CLVeterinaryAttachment && !orderId) {
    throw new Error(
      "Controllers - VeterinaryAttachmentController: orderId não informado!"
    );
  }
  if (CLVeterinaryAttachment && !orderId) {
    return CLVeterinaryAttachment;
  }
  if (CLVeterinaryAttachment && CLVeterinaryAttachment._orderId === orderId) {
    return CLVeterinaryAttachment;
  }

  CLVeterinaryAttachment = new VeterinaryAttachmentClass(orderId, true, ...args);
  return CLVeterinaryAttachment;
}

export function VeterinaryHistoryController(orderId) {
  if (!CLVeterinaryHistory && !orderId) {
    throw new Error(
      "Controllers - VeterinaryHistoryController: orderId não informado!"
    );
  }
  if (CLVeterinaryHistory && !orderId) {
    return CLVeterinaryHistory;
  }
  if (CLVeterinaryHistory && CLVeterinaryHistory._orderId === orderId) {
    return CLVeterinaryHistory;
  }

  CLVeterinaryHistory = new VeterinaryHistoryClass(orderId);
  return CLVeterinaryHistory;
}
