import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  status: 'idle',
  message: null,
  error: null
};

export const useSocket = create((set) => ({
  data: defaultData,
  actions: {
    changeStatus: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.status = status;
        })
      ),
    setMessage: (message) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.message = message;
        })
      ),
    setError: (error) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.error = error;
        })
      ),
  },
}));
