import React, { useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import BasePage from '../basePage'
import ShowTab from './TabSelect'

function Configuracoes() {
  const [tab, setTab] = useState('dados')

  return (
    <BasePage pageTitle="Configurações">
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, value) => setTab(value)}
      >
        <Tab label="DADOS" value="dados" />
        <Tab label="HORÁRIOS DE FUNCIONAMENTO" value="horarios" />
        <Tab label="EMISSOR DE NOTAS" value="notas" />
        <Tab label="IMPRESSORA" value="printer" />
      </Tabs>
      <ShowTab tab={tab} />
    </BasePage>
  )
}

export default Configuracoes
