import { ClipboardList, Eye, Paperclip, Syringe } from "lucide-react";

import { useHistory } from "../../../../../store/service/veterinary/useHistory";

import { VeterinaryHistoryController } from "../../../../../services/controllers/VeterinaryCare";

import { TimeLine } from "../../../../../components/ui/TimeLine";
import { ProcedureIcon } from "../../../../../components/ui/ProcedureIcon";

export function HistoryTimeLine() {
  const historyList = useHistory((st) => st.data.history);

  const CLVetHistory = VeterinaryHistoryController();

  return (
    <TimeLine.Root>
      {!!historyList && historyList.map((history) => (
        <TimeLine.Item key={history.date} title={history.date}>
          {history.procedures.map((procedure) => (
            <TimeLine.Content key={procedure.id}>
              <TimeLine.Headboard
                icon={ProcedureIcon(procedure.name)}
              >
                {procedure.name}
              </TimeLine.Headboard>
              <TimeLine.Description title="Atendido por">
                {procedure.vetName}
              </TimeLine.Description>
              <TimeLine.Badges
                badges={[
                  {
                    id: `${procedure.id}-Vacina`,
                    name: "Vacina",
                    icon: Syringe,
                    show: procedure.hasVaccinations,
                  },
                  {
                    id: `${procedure.id}-Anexo`,
                    name: "Anexo",
                    icon: Paperclip,
                    show: procedure.hasAttachments,
                  },
                  {
                    id: `${procedure.id}-Anamnese`,
                    name: "Anamnese",
                    icon: ClipboardList,
                    show: procedure.hasAnamnesis,
                  },
                ]}
              />
              <TimeLine.Actions
                actions={[
                  {
                    name: "view history",
                    outline: true,
                    icon: Eye,
                    onClick: () => CLVetHistory.openDrawer(procedure.id),
                  },
                ]}
              />
            </TimeLine.Content>
          ))}
        </TimeLine.Item>
      ))}
    </TimeLine.Root>
  );
}
