import styled, { css } from "styled-components";
import { Table } from "../../../../components/ui/Table";
import { darken, rgba } from "polished";

export const Container = styled.div``;

export const TableLine = styled(Table.Line)`
  ${(props) =>
    props.status === "canceled" &&
    css`
      background: ${(props) => props.theme.colors.gray[50]};
      color: ${(props) => props.theme.colors.gray[900]};
      opacity: 0.5;
      filter: grayscale(1);
    `}
  ${(props) =>
    props.status === "programmed" &&
    css`
      background: ${(props) => rgba(props.theme.colors.warning, 0.2)};
      font-weight: bold;
      color: ${(props) => darken(0.2, props.theme.colors.warning)};
    `}
  ${(props) =>
    props.status === "applied" &&
    css`
      background: ${(props) => rgba(props.theme.colors.success, 0.2)};
      font-weight: bold;
      color: ${(props) => darken(0.2, props.theme.colors.success)};
    `}
  ${(props) =>
    props.status === "scheduled" &&
    css`
      font-weight: bold;
      color: ${(props) => darken(0.2, props.theme.colors.gray[700])};
    `}
`;
