import { VACCINATION_STATUS_FIELDS } from "../../../../../../../store/service/veterinary/constants";

import { formatName } from "../../../../../../../services/functions";

import { useVaccine } from "../../../../../../../store/service/veterinary/useVaccine";

import { VeterinaryVaccineController } from "../../../../../../../services/controllers/VeterinaryCare";

import { Select } from "../../../../../../../components/ui/Form/Select";

export function VaccineLab({ vaccine }) {
  const CLVetVaccine = VeterinaryVaccineController();

  const vaccineLabList = useVaccine((st) => st.data.vaccineLabList);

  if (!vaccineLabList) {
    CLVetVaccine.getVaccinesLabs(vaccine.vaccineId);
  }

  if (!VACCINATION_STATUS_FIELDS[vaccine.status].includes("lab")) {
    return vaccine.lab || "";
  }

  return (
    <Select.Root
      label="Laboratório"
      value={vaccine.lab}
      onChange={(lab) => {
        CLVetVaccine.setAssignedVaccineFieldsValue(vaccine.id, { lab });
      }}
    >
      {!!vaccineLabList &&
        vaccineLabList.map((lab) => (
          <Select.Option key={lab} value={lab}>
            {formatName(lab)}
          </Select.Option>
        ))}
    </Select.Root>
  );
}
