import { Text, View } from '@react-pdf/renderer';

import { formatMedicineQuantity, getMedicinePharmacyType, getMedicineUsage } from '../../../../../services/functions';

import { usePrescription } from '../../../../../store/service/veterinary/usePrescription';

import { DosageInformation } from './DosageInformation';

import {
  container,
  medicineContent,
  title,
  subTitleBox,
  subTitle,
  subTitleSeparator,
  subTitleAmount,
  medicineName,
  dosage,
  note,
  noteHighlight,
} from './styles';


export function Medicines() {
  const medicinesList = usePrescription.getState().data.prescription.medicines;

  const medicinesByUsage = _.groupBy(medicinesList, "usage");

  return Object.keys(medicinesByUsage).map((usageKey) => (
    <View style={container} key={usageKey} wrap={false}>
      <View style={title}>
        <Text>Uso {getMedicineUsage(usageKey)[0]}</Text>
      </View>
      {medicinesByUsage[usageKey].map((medicine) => (
        <View style={medicineContent} key={`${usageKey}-${medicine.id}`}>
          <View style={subTitleBox}>
            <Text style={subTitle}>{getMedicinePharmacyType(medicine.pharmacyType)}</Text>
            <View style={subTitleSeparator} />
            <Text style={subTitleAmount}>{formatMedicineQuantity(medicine)}</Text>
          </View>
          <Text style={medicineName}>{medicine.presentation}</Text>
          <View style={dosage}>
            <DosageInformation medicine={medicine} />
          </View>
          {medicine.note && medicine.fillStyle === "self" && (
            <Text style={note}>
              <Text style={noteHighlight}>OBS: </Text>
              {medicine.note}
            </Text>
          )}
        </View>
      ))}
    </View>
  ))
}
