import { memo } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Download, FolderHeart, Trash2 } from "lucide-react";

import { useHistory } from "../../../../../../store/service/veterinary/useHistory";

import { VeterinaryHistoryController } from "../../../../../../services/controllers/VeterinaryCare";

import { Accordion } from "../../../../../../components/ui/Accordion";
import { ListPreview } from "../../../../../../components/ui/ListPreview";
import { Action } from "../../../../../../components/ui/Action";

export const HistoryViewRequests = memo(() => {
  const procedureData = useHistory((st) => st.data.historyProcedureData);
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const selectedProcedureRequest = useHistory((st) => st.data.selectedProcedureRequest[selectHistoryProcedure]);

  const CLVetHistory = VeterinaryHistoryController();

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedureRequests = selectedProcedure?.requests || []

  const isEnabled = procedureRequests.length

  const listPreviewName = CLVetHistory._requestListPreviewName + '-' + selectHistoryProcedure

  return (
    <Accordion.Item
      animate="dance"
      title="Requisições"
      icon={FolderHeart}
      disabled={!isEnabled}
    >
      <Accordion.Content>
        <ListPreview.Root
          name={listPreviewName}
          list={procedureRequests}
          onSelected={(request) => CLVetHistory.selectProcedureRequest(request)}
          options={{
            selected: null,
            listStyle: "list",
          }}
        >
          <ListPreview.Header />
          <ListPreview.Info style={{ height: '700px' }}>
            <ListPreview.InfoActions style={{ justifyContent: "flex-end" }}>
              <Action.Root
                type="button"
                title="Baixar Requisição"
                name={`download-request-${selectHistoryProcedure}`}
                download={{
                  path: selectedProcedureRequest?.path,
                  name: selectedProcedureRequest?.slug,
                }}
              >
                <Action.Icon icon={Download} size={22} />
              </Action.Root>

              <Action.Root
                type="button"
                status="success"
                title="Enviar por WhatsApp"
                name={`send-request-${selectHistoryProcedure}`}
                onClick={() => CLVetHistory.runProcedureAction('CRequest', 'sendMessage', selectedProcedureRequest)}
              >
                <Action.Icon icon={FaWhatsapp} size={22} />
              </Action.Root>
              <Action.Root
                type="button"
                status="danger"
                title="Excluir Requisição"
                name={`delete-request-${selectHistoryProcedure}`}
                disabled
              >
                <Action.Icon icon={Trash2} size={22} />
              </Action.Root>
            </ListPreview.InfoActions>
            <ListPreview.InfoContent fit='cover' />
            <ListPreview.InfoData />
          </ListPreview.Info>
        </ListPreview.Root>
      </Accordion.Content>
    </Accordion.Item>
  );
})
