import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Skeleton from '@material-ui/lab/Skeleton'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import moment from 'moment'
import API from '../../../../services/api'
import 'moment/locale/pt-br'

moment.locale('pt-br')
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    backgroundColor: '#CCD219',
    fontSize: '50px',
    height: '90px',
    width: '90px',
  },
  filter: {
    margin: ' 0',
    background: '#fff',
    width: '80vw',
    borderRadius: '7px',
    padding: '15px',
  },
  petName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: '#ccc',
  },
  button: {
    backgroundColor: '#CCD219',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#CCD219',
    },
  },
  buttonEnd: {
    border: '1px solid #f50057',
    backgroundColor: '#fff',
    marginRight: '10px',
    color: '#f50057',
    '&:hover': {
      backgroundColor: '#f50057',
      color: '#fff',
    },
  },
  buttonWhatsApp: {
    color: '#fff',
    fontSize: '12px',
    borderRadius: '7px',
    border: '0',
    backgroundColor: '#25d366',
    '&:hover': {
      backgroundColor: '#1dba58',
      border: 0,
      color: '#fff',
    },
  },
  pet: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  listServices: {
    width: 'min(600px, 100%)',
  },
  total: {
    textAlign: 'center',
    fontWeight: '400',
    display: 'block',
    fontSize: '18px',
    marginBottom: '-15px',
  },
  price: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    textAlign: 'center',
    width: '100%',
    fontWeight: '700',
    display: 'block',
    padding: '10px',
    fontSize: '36px',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
  },
  espaco: {
    margin: '15px 0',
  },
  customerDetails: {
    fontSize: '14px',
    color: '#878c93',
  },
  successTitle: {
    color: '#47A59D',
    fontSize: '36px',
    margin: 0,
    textAlign: 'center',
    marginTop: '30px',
  },
  back: {
    color: '#666',
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'space-around',
    textDecoration: 'none',
    margin: '50px 5px',
  },
  discount: {
    marginBottom: '-10px',
    textDecoration: 'line-through',
    color: '#878c93',
    fontSize: '11px',
  },
  history: {
    width: '100%',
    padding: '48px',
  },
  historyItem: {
    '&:last-child': {
      '& .globalHistoryItemService::before': {
        background: 'transparent',
      },
    },
  },
  historyItemTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::before': {
      content: '" "',
      height: '18px',
      width: '18px',
      background: '#DCDCDC',
      borderRadius: '50%',
      marginRight: '12px',
    },
  },
  historyItemService: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    position: 'relative',
    margin: '24px 48px;',
    '&::before': {
      position: 'absolute',
      content: '" "',
      height: '140px',
      left: '-40px',
      width: '2px',
      background: '#DCDCDC',
    },
  },
  historyItemServiceType: {
    background: '#EEEEEE',
    minHeight: '100%',
    padding: '12px 48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '35px',
    },
    '& p': {
      margin: '0 0 6px 0 ',
    },
  },
  historyItemServicInformation: {
    display: 'flex',
    flex: '1',
  },
  historyItemServicInformationOwner: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: '0 24px',
    '& span': {
      color: '#CCCCCC',
    },
    '& p': {
      margin: '0',
      color: '#666666',
    },
  },
  historyItemServicInformationList: {
    margin: '0 12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      width: '36px',
      height: '36px',
      color: '#fff',
      background: '#f00',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      margin: '0 12px',
    },
  },
  historyItemServiceInformationButton: {},
}))

function DrawerContent({ orderId, close }) {
  const classes = useStyles()
  const { register } = useForm()

  const [order, setOrder] = useState(null)
  const [vaccinations, setVaccinations] = useState([])
  const [currentTab, setCurrentTab] = useState(0)
  const [anamnesis, setAnamnesis] = useState(false)
  const [attachments, setAttachments] = useState(false)

  useEffect(() => {
    const handleGetOrder = async () => {
      const { data } = await API.get(`service-order/appointment/${orderId}`)
      setOrder(data)
    }
    handleGetOrder()
  }, [orderId])

  const getFileList = async () => {
    const fileList = await API.get(
      `service-order/appointment/${order.appointmentOrderId}/attachments`,
    )
    setAttachments(fileList.data.attachments)
  }

  useEffect(() => {
    if (order) {
      getFileList()
    }
  }, [order])

  useEffect(() => {
    async function getAnamnese() {
      const { data } = await API.get(
        `service-order/appointment/${order.appointmentOrderId}/anamnesis`,
      )
      setAnamnesis(data.anamnesis)
    }
    if (order) {
      getAnamnese()
    }
  }, [order])

  async function getVaccinations() {
    if (order?.appointmentOrderId) {
      const { data } = await API.get(
        `service-order/appointment/${order?.appointmentOrderId}/vaccinations`,
      )
      setVaccinations(data.vaccinations)
    }
  }

  useEffect(() => {
    getVaccinations()
  }, [order])

  const [tabs] = useState([
    {
      value: 0,
      label: 'ANAMNESE',
      show: true,
    },
    {
      value: 1,
      label: 'VACINAS',
      show: true,
    },
    {
      value: 2,
      label: 'ANEXOS',
      show: true,
    },
    {
      value: 4,
      label: 'SERVIÇOS',
      show: true,
    },
  ])

  const handleChangeIndex = (event, value) => setCurrentTab(value)

  return (
    <div onKeyDown={(e) => (e.key === 'Escape' ? close() : null)}>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
      >
        {tabs.map(
          (tab) =>
            tab.show && (
              <Tab key={tab.label} label={tab.label} value={tab.value} />
            ),
        )}
      </Tabs>

      {currentTab === 0 && (
        <Grid item xs={12} spacing={3} className={classes.filter}>
          <Grid item xs={12}>
            <h2>Queixa Principal</h2>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <TextField
                defaultValue={anamnesis ? anamnesis?.mainComplaint : ''}
                disabled
                name="description"
                id="description"
                label=""
                multiline
                fullWidth
                inputRef={register}
                rows={10}
                className={classes.textField}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <h2>Exame Físico</h2>
            </Grid>
            <Grid item xs={3}>
              <TextField
                defaultValue={anamnesis ? anamnesis?.exam?.heartRate : ''}
                name="bpm"
                disabled
                id="bpm"
                label="FC (bpm)"
                fullWidth
                inputRef={register}
                className={classes.textField}
                variant="outlined"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled
                defaultValue={anamnesis?.exam?.respiratoryRate}
                name="mpm"
                id="mpm"
                inputRef={register}
                label="FR (mpm)"
                variant="outlined"
                placeholder=" "
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                disabled
                id="TEMPERATURA"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                name="temperatura"
                inputRef={register}
                label="TEMPERATURA"
                variant="outlined"
                rowsMax={1}
                placeholder=" "
                defaultValue={anamnesis ? anamnesis.exam.temperature : ''}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                id="seg"
                name="seg"
                disabled
                inputRef={register}
                label="TPC (seg)"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.TPC : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="OUVIDOS"
                disabled
                name="ouvidos"
                inputRef={register}
                label="OUVIDOS"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.earsCondition : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="MUCOSAS"
                name="mucosas"
                inputRef={register}
                label="MUCOSAS"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.mucosa : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="HIDRATAÇÃO"
                name="hidratacao"
                inputRef={register}
                label="HIDRATAÇÃO"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.hydration : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="lymphNode"
                name="lymphNode"
                inputRef={register}
                label="LINFONODOS"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.lymphNode : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="skinFur"
                name="skinFur"
                inputRef={register}
                label="PELE/PELO"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.skinFur : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="thoracicLimbs"
                name="thoracicLimbs"
                inputRef={register}
                label="MEMBROS TORÁCICOS"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.thoracicLimbs : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="chest"
                name="chest"
                inputRef={register}
                label="TÓRAX"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.chest : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="pelvicLimbs"
                name="pelvicLimbs"
                inputRef={register}
                label="MEMBROS PÉLVICOS"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.pelvicLimbs : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                id="abdomen"
                name="abdomen"
                inputRef={register}
                label="ABDOME"
                variant="outlined"
                placeholder=""
                defaultValue={anamnesis ? anamnesis.exam.abdomen : ''}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h2>Diagnóstico e Tratamento</h2>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <TextField
                disabled
                id="Diagnóstico"
                name="diagnostico"
                label=""
                multiline
                fullWidth
                rows={10}
                inputRef={register}
                className={classes.textField}
                variant="outlined"
                size="small"
                defaultValue={anamnesis ? anamnesis.diagnosisAndTreatment : ''}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {currentTab === 1 && (
        <Grid item xs={12} spacing={3} className={classes.filter}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={3}>
              <h3>Data</h3>
            </Grid>
            <Grid item xs={3}>
              <h3>Tipo</h3>
            </Grid>
            <Grid item xs={2}>
              <h3>Lote</h3>
            </Grid>
            <Grid item xs={2} container alignItems="center" justify="center">
              <h3>Laboratório</h3>
            </Grid>
            <Grid item xs={2} container alignItems="center" justify="center" />
          </Grid>
          {vaccinations?.map(
            ({ vaccinationDate, id, vaccineName, laboratoryName, batch }) => (
              <Grid container spacing={3} key={id} xs={12}>
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
                <Grid item xs={3}>
                  <p>{moment(vaccinationDate).format('DD/MM/YYYY')}</p>
                </Grid>
                <Grid item xs={3}>
                  <p>{vaccineName}</p>
                </Grid>
                <Grid item xs={2}>
                  <p>{batch}</p>
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                >
                  {laboratoryName}
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  alignItems="center"
                  justify="center"
                />
              </Grid>
            ),
          )}
        </Grid>
      )}
      {currentTab === 2 && (
        <Grid item xs={12} container spacing={3} className={classes.filter}>
          {attachments && attachments.length >= 1 && (
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="h5"
                component="h4"
                className={classes.title}
              >
                Documentos
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
            {attachments &&
              attachments.map(({ fileUrl, id }) => (
                <button
                  key={id}
                  style={{
                    position: 'relative',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    marginRight: '30px',
                  }}
                >
                  {fileUrl.split('.').pop() === 'pdf' ? (
                    <PictureAsPdfIcon
                      style={{ fontSize: '100px' }}
                      onClick={() => {
                        window.open(fileUrl)
                      }}
                    />
                  ) : (
                    <img
                      src={fileUrl}
                      alt=""
                      style={{
                        maxWidth: '110px',
                        height: '100px',
                        margin: '16px',
                      }}
                      onClick={() => {
                        window.open(fileUrl)
                      }}
                    />
                  )}
                </button>
              ))}
          </Grid>
        </Grid>
      )}
      {currentTab === 4 && (
        <Grid item xs={12} spacing={3} className={classes.filter}>
          <TableContainer>
            <Table size="medium" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Serviço</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Profissional</TableCell>
                  <TableCell align="center">Plano de Saúde</TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>
              {order?.services ? (
                <TableBody>
                  {order?.services.map((row) => (
                    <TableRow key={row.serviceName}>
                      <TableCell component="th" scope="row">
                        <div
                          style={{
                            justifyContent: 'left',
                            alignItems: 'center',
                          }}
                        >
                          {row.serviceName}
                        </div>
                      </TableCell>

                      <TableCell align="center">
                        {row?.discount === 0 ? (
                          Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(row?.totalPrice)
                        ) : (
                          <>
                            <p className={classes.discount}>
                              {Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(row?.originalPrice)}
                            </p>
                            <p>
                              {Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(row?.totalPrice)}
                            </p>
                          </>
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.employeeName}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Checkbox defaultChecked={!!row?.healthPlan} disabled />
                      </TableCell>
                      <TableCell component="th" scope="row" align="center" />
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      )}
    </div>
  )
}

export default DrawerContent
