import React from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'

// icone Material ui
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import 'moment/locale/pt-br'

import { TableRow, TableCell, TableBody, IconButton } from '@material-ui/core'

moment.locale('pt-br')

const useStyles = makeStyles({
  AGENDADO: {
    backgroundColor: '#00A1A4',
    '& td': {
      color: '#fff',
    },
    '& svg': {
      color: '#fff',
    },
  },
  FINALIZADO: {
    backgroundColor: '#C64D21',
    '& td': {
      color: '#fff',
    },
    '& svg': {
      color: '#fff',
    },
  },
  ORÇAMENTO: {
    backgroundColor: '#59602C',
    '& td': {
      color: '#fff',
    },
    '& svg': {
      color: '#fff',
    },
  },
})

function TabelaVisual({ events, onDoubleClickEvent }) {
  const classes = useStyles()

  return (
    <TableBody>
      {events?.map((item) => (
        <TableRow
          key={item.id}
          className={
            item.status === 'FINALIZADO' || item.status === 'AGENDADO'
              ? classes[item.status]
              : classes[item.status.substring(0, item.status.indexOf(' '))]
          }
        >
          <TableCell>{moment(item.start).format('HH:mm')}</TableCell>
          <TableCell>{moment(item.end).format('HH:mm')}</TableCell>
          <TableCell>{item.employeeName}</TableCell>
          <TableCell>{item.pet}</TableCell>
          <TableCell>{item.customer}</TableCell>
          <TableCell>{item.title}</TableCell>
          <TableCell align="center">{item.id}</TableCell>
          <TableCell>
            {
              {
                AGENDADO: 'AGENDADO',
                FINALIZADO: 'CHECK-IN',
                'ORÇAMENTO ENVIADO': 'FINALIZADO',
              }[item.status]
            }
          </TableCell>
          <TableCell>
            <IconButton
              type="submit"
              aria-label="search"
              disabled={item.status === 'BLOQUEADO'}
              onClick={() =>
                onDoubleClickEvent({
                  id: item.id,
                  orderId: item.id,
                  employeeId: item.employeeId,
                })
              }
            >
              <VisibilityOutlinedIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}

export default TabelaVisual
