import { ChevronRight } from "lucide-react";
import { PaginationBack as ButtonForward } from "./styles";
import { usePagination } from "./usePagination";
import { usePaginationContext } from "./PaginationContext";

export function PaginationForward() {
  const { paginationName } = usePaginationContext();

  const setCurrentPage = usePagination((st) => st.actions.setCurrentPage);
  const currentPage =
    usePagination((st) => st.data.pagination[paginationName]?.currentPage) || 0;
  const totalPages =
    usePagination((st) => st.data.pagination[paginationName]?.totalPages) || 0;

  const toEnd = () => {
    setCurrentPage(paginationName, totalPages);
  };

  const toNext = () => {
    if (currentPage + 1 > totalPages) return;
    setCurrentPage(paginationName, currentPage + 1);
  };

  return (
    <>
      <ButtonForward
        onClick={toNext}
        disabled={totalPages === currentPage}
        outline
        data-direction="right"
      >
        <ChevronRight size={20} />
      </ButtonForward>
      <ButtonForward
        onClick={toEnd}
        disabled={totalPages === currentPage}
        outline
        data-direction="right"
      >
        <ChevronRight size={20} />
        <ChevronRight size={20} />
      </ButtonForward>
    </>
  );
}
