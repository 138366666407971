import { StyleSheet } from "@react-pdf/renderer";

import { getCurrentThemeStyle } from "../../../../services/functions";

const theme = getCurrentThemeStyle()

export const container = StyleSheet.create({
  display: 'flex',
  justifyContent: "space-between",
  alignItems: "flex-start",
  alignContent: "space-between",
  flexDirection: 'row',
  gap: "14px",
  width: "100%",
  minHeight: '50px',
  padding: "0",
})

export const line = StyleSheet.create({
  margin: "0",
  fontSize: "8px",
  color: theme.colors.gray[700],
  fontFamily: "Helvetica"
})


export const highlight = StyleSheet.create({
  fontFamily: "Helvetica-Bold",
  color: theme.colors.scale[500]
})

export const storeDataContent = StyleSheet.create({})

export const storeData = StyleSheet.create({
  display: "flex",
  flexDirection: 'row',
  justifyContent: "flex-start",
  alignContent: "space-between",
  flexWrap: "wrap",
  height: "100%",
  gap: "4px 10px"
})


export const storeName = StyleSheet.create({
  width: "100%",
  fontSize: "10px",
  margin: "0",
  marginBottom: "auto",
  fontFamily: "Helvetica-Bold",
  color: theme.colors.scale[600]
})

export const storeLogo = StyleSheet.create({
  height: "50px",
  width: "50px",
})

export const img = StyleSheet.create({
  width: "100%",
  height: "100%",
  objectFit: "contain"
})
