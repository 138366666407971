import { Grid2X2, Grid3X3, LayoutList } from "lucide-react";
import { useQueryParam } from 'use-query-params';


import { ToggleGroup } from "../../Form/ToggleGroup";

import { useContext } from "../Context";
import { useListPreview } from "../useListPreview";

import { Container, HeaderListStyle, HeaderActions } from "./styles";

export function ListPreviewHeader({ children, ...rest }) {
  const { listPreviewName } = useContext();
  const [style, setQueryListStyle] = useQueryParam(`${listPreviewName}-style`);

  const setListStyle = useListPreview((st) => st.actions.setListStyle);

  const listStyle = useListPreview((st) => st.data.lists[listPreviewName].listStyle);
  const showStyles = useListPreview((st) => st.data.lists[listPreviewName].showStyles);

  return (
    <Container>
      <HeaderListStyle>
        <ToggleGroup.Root
          defaultValue={listStyle}
          value={style}
          onValueChange={(style) => {
            setListStyle(listPreviewName, style)
            setQueryListStyle(style)
          }}
        >
          {!showStyles || showStyles.includes("smallGrid") && (
            <ToggleGroup.Item value="smallGrid">
              <Grid3X3 />
            </ToggleGroup.Item>
          )}
          {!showStyles || showStyles.includes("bigGrid") && (
            <ToggleGroup.Item value="bigGrid">
              <Grid2X2 />
            </ToggleGroup.Item>
          )}
          {!showStyles || showStyles.includes("list") && (
            <ToggleGroup.Item value="list">
              <LayoutList />
            </ToggleGroup.Item>
          )}
        </ToggleGroup.Root>
      </HeaderListStyle>
      {children && <HeaderActions {...rest}>{children}</HeaderActions>}
    </Container>
  );
}
