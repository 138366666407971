import React, { useContext } from 'react'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import { ReportContext } from '../../../providers/report'

function SnackbarComponent() {
  const { openSnackbar, messageExport, handleClose } = useContext(ReportContext)

  return (
    <Snackbar
      open={openSnackbar}
      onClose={handleClose}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {messageExport}
        </Alert>
      </Stack>
    </Snackbar>
  )
}

export default SnackbarComponent
