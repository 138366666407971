import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import InputMask from "react-input-mask";
import PetsIcon from "@material-ui/icons/Pets";
import StarIcon from "@material-ui/icons/Star";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons//Add";
import CircularProgress from "@mui/material/CircularProgress";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EditIcon from "@mui/icons-material/Edit";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Material ui - lab
import Skeleton from "@material-ui/lab/Skeleton";
import { newCustomer } from "../../../services/customers";
import { getPets, getPetAttributes } from "../../../services/pets";
import API from "../../../services/api";

// Componentes
import ModalType from "../../atoms/Modal";
import DrawerType from "../../atoms/Drawer";
import EditPet from "../EditPet";

import styles from "./styles";
import { AccordionCashback } from "./AccordionItens/AccordionCashback";
import { AccordionPurchaseHistory } from "./AccordionItens/AccordionPurchaseHistory";
import { AccordionServiceHistory } from "./AccordionItens/AccordionServiceHistory";
import { AuthContext } from "../../../providers/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
  },
  avatar: {
    backgroundColor: "#CCD219",
    fontSize: "50px",
    height: "90px",
    width: "90px",
  },
  petName: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#CCD219",
    color: "#fff",
    fontWeight: "bold",
  },
  title: {
    color: "#ccc",
    paddingBottom: "10px",
  },
  total: {
    textAlign: "center",
    color: "#666666",
    fontWeight: "400",
    display: "block",
    fontSize: "18px",
    marginBottom: "-15px",
  },
  price: {
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    textAlign: "center",
    width: "100%",
    fontWeight: "700",
    display: "block",
    padding: "10px",
    fontSize: "36px",
    color: "#666666",
  },
  tabs: {
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#666666",
  },
  noPackage: {
    color: "#f33838",
    fontWeight: "bold",
  },
  colorCpf: {
    color: "red",
  },
  autoContainer: {
    alignItems: "center",
    paddingBottom: "10px",
    paddingTop: "8px",
  },
  searchContainer: {
    padding: "10px",
    maxHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    alignItems: "center",
  },
  searchItem: {
    display: "flex",
    borderBottom: "1px solid #efefef",
    padding: "15px 10px !important",
  },
  autoAvatar: {
    textTransform: "capitalize",
    width: "48px",
    height: "48px",
    backgroundColor: "#ccd219",
  },
  assinante: {
    fontSize: "8px",
    background: "#ccd219",
    color: "#fff",
    padding: "3px 6px",
    borderRadius: "37px",
    marginLeft: "20px",
  },
  atraso: {
    fontSize: "8px",
    background: "#f50057",
    color: "#fff",
    padding: "3px 6px",
    borderRadius: "37px",
    marginLeft: "20px",
  },
  autoFirstP: {
    margin: "0",
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: "500",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    color: "#ccd219",
    display: "flex",
  },
  autoIcon: {
    fontSize: "16px",
    marginRight: "5px",
  },
  autoLastP: {
    margin: "0",
    color: "#666",
    fontSize: "14px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  teste: {
    marginBottom: "5px",

    "& input": {
      padding: "15px",
      fontSize: "12px",
    },
    "& label": {
      top: "-4px",
    },
  },
  clasIcon: {
    backgroundColor: "#ccd219",
    color: "#fff",
    borderRadius: "5px",
    padding: "9px",
    "&:hover": {
      color: "#ccd219",
    },
  },
}));

function Customer(props) {
  const {
    setAtualizePet,
    customer,
    order,
    loading,
    setCustomer,
    setSearchCpf,
    setPetName,
    services,
    schedulings,
    priceCupom,
    servicesSeletected = [],
    finalPrice,
    setFinalPrice,
    hideServiceList = false,
  } = props
  const classes = useStyles();
  const history = useHistory();
  const { drawerBox } = styles();

  const [openDrawer, setOpenDrawer] = useState(false);

  const closeDrawerPet = () => {
    setAtualizePet(true);
    setOpenDrawer(false);
  };

  const isClinica = history.location.search
    ? history.location.search.search("clinica=true") >= 0
    : history.location.pathname.split("/").includes("clinica");
  const department = isClinica ? "Clínica Veterinária" : "Banho e Tosa";

  useEffect(() => {
    if (schedulings) {
      const servicesIds = schedulings.map(({ service }) => service);
      const values = services.filter(({ id }) => servicesIds.includes(id));

      const priceServices = values?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.price,
        0
      );

      setFinalPrice(
        !order
          ? priceServices || 0
          : parseFloat(order?.totalPrice ? order?.totalPrice : 0)
      );
    } else {
      const priceServices = servicesSeletected.reduce(
        (accumulator, currentValue) => accumulator + currentValue.price,
        0
      );

      setFinalPrice(
        !order
          ? priceServices || 0
          : parseFloat(order?.totalPrice ? order?.totalPrice : 0)
      );
    }
  }, [order, servicesSeletected, schedulings]);

  const handleClose = () => {
    setCustomer(null);
    setSearchCpf(null);
    setPetName(null);
  };

  return (
    <Grid item container xs={12} spacing={2}>
      <DrawerType
        open={openDrawer}
        setDrowerOpen={setOpenDrawer}
        titleHeader="Editar Pet"
        content={
          <div className={drawerBox}>
            <EditPet
              petData={{
                customerId: customer?.id,
                callBack: closeDrawerPet,
                value: { ...customer?.pet, petId: customer?.pet?.id },
              }}
            />
          </div>
        }
      />
      <Grid item xs={3} className={classes.avatarContainer}>
        {customer?.pet?.subscriptionStatus === "Ativo" ? (
          <Avatar
            className={classes.avatar}
            src={customer.pet.subscriptionImgUrl}
          />
        ) : (
          <>
            {customer?.pet?.petPhoto ? (
              <Avatar className={classes.avatar} src={customer.pet.petPhoto} />
            ) : (
              <Avatar className={classes.avatar}>
                {customer.pet.name?.substr(0, 1)}
              </Avatar>
            )}
          </>
        )}
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={12}>
          <span className={classes.petName}>{customer.pet.name}</span>
        </Grid>
        <Grid item xs={12}>
          {customer.owner}
        </Grid>
        <Grid item xs={12}>
          {customer.email}
        </Grid>
        <Grid item xs={12}>
          {customer.phone}
        </Grid>
        <Grid item xs={12}>
          <Link
            to="route"
            target="_blank"
            style={{ textDecoration: "none" }}
            onClick={(event) => {
              event.preventDefault();
              window.open(`/clientes/resumo/${customer.id}`, "_blank");
            }}
          >
            <Button
              style={{
                padding: "3px 12px",
                margin: "10px 0",
                textTransform: "capitalize",
                color: "#fff",
                backgroundColor: "rgb(204, 210, 25)",
              }}
            >
              <AccountBoxIcon style={{ marginRight: "8px" }} /> Perfil
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {!order && (
          <IconButton onClick={() => handleClose()}>
            <HighlightOffIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Dados do Pet</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12}>
              <Grid item xs={2} className={classes.title}>
                <strong style={{ color: "#999" }}>Assinatura</strong>
              </Grid>
              <Grid item xs={10}>
                {customer?.pet?.subscriptionStatus === "Ativo" && (
                  <span className={classes.assinante}>ASSINANTE</span>
                )}
                {customer?.pet?.subscriptionStatus === "Em atraso" && (
                  <span className={classes.atraso}>EM ATRASO</span>
                )}
              </Grid>
              <Grid item xs={2} className={classes.title}>
                Espécie
              </Grid>
              <Grid item xs={10}>
                {customer.pet.specie}
              </Grid>
              <Grid item xs={2} className={classes.title}>
                Porte
              </Grid>
              <Grid item xs={10}>
                {customer.pet.size}
              </Grid>
              <Grid item xs={2} className={classes.title}>
                Pelagem
              </Grid>
              <Grid item xs={10}>
                {customer.pet.coat}
              </Grid>
              <Grid item xs={2} className={classes.title}>
                Raça
              </Grid>
              <Grid item xs={10}>
                {customer.pet.breed}
              </Grid>
              <Grid item xs={2} className={classes.title}>
                Observação
              </Grid>
              <Grid item xs={10}>
                {customer.pet.comments}
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setOpenDrawer(true)}
                  style={{ color: "rgb(204, 210, 25)" }}
                >
                  Editar dados do pet <EditIcon />
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {!hideServiceList && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Serviços Disponíveis no Pacote</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {customer?.packages.length >= 1 && (
                <>
                  {customer?.packages.map((item) => {
                    if (item.department === department) {
                      return (
                        <Grid
                          key={item.name}
                          item
                          xs={12}
                          style={{ paddingBottom: "10px" }}
                        >
                          {item.quantityAvailable} -{item.name}
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </>
              )}
              {customer?.packages.length < 1 && (
                <Grid item xs={12}>
                  <span className={classes.noPackage}>Cliente Sem Pacote</span>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {!!customer.pet.subscription && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Detalhes da Assinatura</Typography>
            </AccordionSummary>
            {console.log(customer.pet.subscription)}
            <AccordionDetails>
              <Grid container item xs={12}>
                <Grid item xs={4} className={classes.title}>
                  Status da assinatura:
                </Grid>
                <Grid item xs={8}>
                  {customer.pet.subscription.status}
                </Grid>
                <Grid item xs={4} className={classes.title}>
                  Banhos disponíveis:
                </Grid>
                <Grid item xs={8}>
                  {customer.pet.subscription.planType === "Ilimitado"
                    ? customer.pet.subscription.planType
                    : customer.pet.subscription.quantityAvailable}
                </Grid>
                <Grid item xs={4} className={classes.title}>
                  Plano:
                </Grid>
                <Grid item xs={8}>
                  {customer.pet.subscription.planType}
                </Grid>
                {!!customer.pet.subscription.servicosAdicionais && !!customer.pet.subscription.servicosAdicionais.length && (
                  <>
                    <Grid item xs={4} className={classes.title}>
                      Serviços adicionais:
                    </Grid>
                    <Grid item xs={8}>
                      {customer.pet.subscription.servicosAdicionais.map((service)=>(service.nomeServico)).join(', ' )}
                    </Grid>
                  </>
                )}
                {!!customer.pet.subscription.descontoServicosAdicionais && (
                  <>
                    <Grid item xs={4} className={classes.title}>
                      Desconto nos demais serviços:
                    </Grid>
                    <Grid item xs={8}>
                      {customer.pet.subscription.descontoServicosAdicionais.valorDesconto||0}%
                    </Grid>
                  </>
                )}

              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {!!customer.pet?.futureProtocolVaccinations.length && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Protocolos Vacinas Ativos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container item xs={12}>
                {customer.pet?.futureProtocolVaccinations.map((protocol) => (
                  <Grid
                    key={protocol.id}
                    style={{ marginBottom: "5px" }}
                    item
                    xs={12}
                  >
                    {`${protocol.date} - ${protocol.status} ${protocol.vaccinationNumber}/${protocol.vaccinationTotal}`}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        <AccordionCashback {...customer} />
        <AccordionServiceHistory {...customer} />
        <AccordionPurchaseHistory {...customer} />
      </Grid>

      {!hideServiceList && (
        <>
          {order && (
            <>
              <Grid item xs={12}>
                Ordem de Serviços
              </Grid>
              <Grid item xs={12}>
                {customer.pet.breed}
              </Grid>
            </>
          )}
          {finalPrice !== 0 && (
            <>
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12}>
                <span className={classes.total}>TOTAL A PAGAR</span>
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Skeleton style={{ width: "100%" }} />
                ) : (
                  <span className={classes.price}>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(priceCupom || finalPrice)}
                  </span>
                )}
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}

function SearchCustomer({
  setCustomer,
  searchCpf,
  petName,
  setTab,
  setFormDados,
}) {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(true);
  const [validateArray, setValidateArray] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [validationButton, setValidationButton] = useState(false);

  const { register, handleSubmit } = useForm();

  const handleSearch = async (data) => {
    setValidationButton(true);
    setCustomers([]);
    setFormDados(data);
    try {
      const result = await getPets(
        data?.cpf,
        data?.name,
        data?.pet,
        data?.cellphone
      );
      setCustomers(result);
      setValidateArray(result);
      setCustomersLoading(!result.length);
      setErrMsg(false);
      setValidationButton(false);
    } catch (err) {
      console.log("Erro:", err);
      setValidateArray(false);
      setErrMsg("Cliente não encontrado.");
      setValidationButton(false);
    }
  };

  const handleCustomer = (value) => {
    setCustomer(value);
  };

  const handleGetPets = async () => {
    const result = await getPets(searchCpf);
    const petFilter = result.filter((item) => item.label === petName);
    setCustomer(petFilter[0]);
  };

  useEffect(() => {
    if (petName) handleGetPets();
  }, [petName]);

  return (
    <Grid container spacing={1} className={classes.autoContainer}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Grid container spacing={1} className={classes.autoContainer}>
          <Grid item xs={5}>
            <TextField
              className={classes.teste}
              fullWidth
              name="cpf"
              label="CPF"
              variant="outlined"
              inputRef={register}
            />

            <TextField
              className={classes.teste}
              fullWidth
              name="name"
              label="Tutor"
              variant="outlined"
              inputRef={register}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              className={classes.teste}
              fullWidth
              name="cellphone"
              label="Telefone"
              variant="outlined"
              inputRef={register}
            />

            <TextField
              className={classes.teste}
              fullWidth
              name="pet"
              label="Pet"
              variant="outlined"
              inputRef={register}
            />
          </Grid>

          <Grid item xs={2}>
            {validationButton ? (
              <CircularProgress
                style={{ color: "#ccd219", width: "30px", height: "30px" }}
              />
            ) : (
              <IconButton
                className={classes.clasIcon}
                aria-label="search"
                type="submit"
              >
                <SearchIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>

      {!customersLoading && (
        <Grid container spacing={1} className={classes.searchContainer}>
          {customers?.map((option) => (
            <Grid
              key={option.label}
              item
              xs={12}
              className={classes.searchItem}
            >
              <Grid item xs={2}>
                {option?.pet?.subscriptionStatus === "Ativo" ? (
                  <Avatar
                    className={classes.autoAvatar}
                    src={option.pet.subscriptionImgUrl}
                  />
                ) : (
                  <>
                    {option?.pet?.petPhoto ? (
                      <Avatar
                        className={classes.autoAvatar}
                        src={option.pet.petPhoto}
                      />
                    ) : (
                      <Avatar className={classes.autoAvatar}>
                        {option?.label?.substr(0, 1)}
                      </Avatar>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={8}>
                <p className={classes.autoFirstP}>
                  <PetsIcon className={classes.autoIcon} />
                  {option.label}
                  {option?.pet?.subscriptionStatus === "Ativo" && (
                    <span className={classes.assinante}>ASSINANTE</span>
                  )}
                  {option?.pet?.subscriptionStatus === "Em atraso" && (
                    <span className={classes.atraso}>EM ATRASO</span>
                  )}
                </p>
                <p className={classes.autoLastP}>
                  {option.fullName}{" "}
                  {option.alreadyClient ? (
                    <StarIcon fontSize="small" color="secondary" />
                  ) : (
                    ""
                  )}{" "}
                </p>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  size="large"
                  onClick={() => handleCustomer(option)}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {validateArray?.length === 0 && (
        <div
          style={{
            display: "block",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            color: "red",
          }}
        >
          <p style={{ textAlign: "center" }}>Cliente sem pet cadastrado</p>
          <p
            style={{
              textAlign: "center",
              color: "#ccd219",
              cursor: "pointer",
            }}
            className={classes.textLink}
            onClick={() => setTab("2")}
          >
            CADASTRAR NOVO PET
          </p>
        </div>
      )}

      {errMsg && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            color: "red",
          }}
        >
          <p>{errMsg}</p>
        </div>
      )}
    </Grid>
  );
}

function SidebarCustomer({
  order,
  customer,
  setCustomer,
  servicesSeletected,
  priceCupom,
  loading,
  schedulings,
  services,
  finalPrice,
  setFinalPrice,
  hideServiceList = false,
}) {
  const classes = useStyles();

  const { register, handleSubmit, unregister, control, watch } = useForm({
    defaultValues: {
      street: "...",
      uf: "...",
      city: "...",
      neighborhood: "...",
    },
  });

  const [breeds, setBreeds] = React.useState(null);

  const handleBreed = async (val) => {
    try {
      const { data } = await API.get(`pet-attributes/breeds/${val}`);
      setBreeds(data?.results);
    } catch (err) {
      console.log(err);
    }
  };

  const [validateCpf, setValidateCpf] = useState(true);

  const [openModal, setOpenModal] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [stateButtom, setStateButtom] = React.useState(false);
  const [atualizePet, setAtualizePet] = React.useState(false);
  const [formDados, setFormDados] = useState(false);
  const [validatePetCpf, setValidatePetCpf] = useState(true);
  const [resultCpf, setResultCpf] = useState(true);
  const [searchCpf, setSearchCpf] = useState(null);
  const [petName, setPetName] = useState(null);
  const [errorEmail, setErrorEmail] = React.useState(true);

  const handlePet = async () => {
    try {
      const result = await getPets(
        formDados?.cpf,
        formDados?.name,
        formDados?.pet,
        formDados?.cellphone
      );
      const resutPetAtualize = result?.filter(
        (item) => item?.pet?.id === customer?.pet?.id
      );
      setCustomer(resutPetAtualize[0]);
      setAtualizePet(false);
    } catch (err) {
      console.log("Erro:", err);
    }
  };

  useEffect(() => {
    if (atualizePet) handlePet();
  }, [atualizePet]);

  const closeModal = () => {
    setOpenModal(false);
  };

  const { cpf, cpfPet } = watch();

  const handleCpf = async (val) => {
    try {
      const { data } = await API.post("customers/isCpfAvailable", {
        cpf: val,
      });
      setValidateCpf(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (cpf?.length > 10) handleCpf(cpf);
  }, [cpf]);

  const [tab, setTab] = useState("0");
  const [attributes, setAttributes] = useState({});

  const handleChangeIndex = (event, value) => {
    setTab(value);
    setBreeds(null);
  };

  const onSubmit = async (data) => {
    setStateButtom(true);
    const {
      cpf,
      firstname,
      lastname,
      email,
      cellphone,
      telephone,
      name,
      comments,
      Espécie,
      Raça,
      Sexo,
      Peso,
      Porte,
      Pelagem,
      Castrado,
      birthdate,
    } = data;

    const payload = {
      cpf,
      firstname,
      lastname,
      email,
      cellphone,
      telephone,
      newPet: {
        name,
        comments,
        birthdate,
        attributes: {
          Espécie,
          Raça,
          Sexo,
          Peso,
          Porte,
          Pelagem,
          Castrado,
          Status: "Vivo",
        },
      },
    };

    const result = await newCustomer(payload);

    if (result?.status === 201) {
      setOpenModal("Cliente cadastrado com sucesso.");
      setError(false);

      setTimeout(() => {
        setSearchCpf(cpf.replace(/\.|-/gm, ""));
        setPetName(name);
        handleChangeIndex(null, "0");
        unregister();
      }, 1500);
    } else {
      setOpenModal(result?.data?.friendly_message);
      setError(true);
      setStateButtom(false);
    }
  };

  const handleAttributes = async () => {
    const values = await getPetAttributes();
    setAttributes(values);
  };

  useEffect(() => {
    handleAttributes();
  }, []);

  const regex = {
    maskEmail: (value) => {
      if (value) {
        const emailPattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailPattern.test(value);
      }
      return true;
    },
  };

  const handleCustomerCpf = async (val) => {
    try {
      const { data } = await API.get(`customers/search?cpf=${val}`);
      setResultCpf(data);
    } catch (err) {
      setValidatePetCpf(false);
    }
  };

  useEffect(() => {
    if (resultCpf?.results?.length === 1) setValidatePetCpf(true);
  }, [resultCpf]);

  useEffect(() => {
    if (cpfPet?.length > 10) handleCustomerCpf(cpfPet);
  }, [cpfPet]);

  const onSubmitPet = async (data) => {
    setStateButtom(true);
    try {
      await API.post("pets/new", {
        customerId: resultCpf?.results[0]?.id,
        pet: {
          name: data.name,
          birthdate: data.birthdate,
          comments: data.comments,
          attributes: {
            Espécie: data.Espécie,
            Raça: data.Raça,
            Sexo: data.Sexo,
            Peso: 25,
            Porte: data.Porte,
            Pelagem: data.Pelagem,
            Status: "Vivo",
            Castrado: data.Castrado,
          },
        },
      });
      setOpenModal("Pet cadastrado com sucesso.");
      setError(false);

      setTimeout(() => {
        setSearchCpf(cpfPet);
        setPetName(data.name);
        handleChangeIndex(null, "0");
      }, 1500);
    } catch (err) {
      console.log(err);
      setOpenModal(err?.response?.data?.friendly_message);
      setError(true);
      setStateButtom(false);
    }
  };

  return (
    <>
      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        err={error}
        verification={false}
      />
      <Tabs
        value={tab}
        className={classes.tabs}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeIndex}
      >
        <Tab label="CLIENTE" value="0" style={{ minWidth: "118px" }} />
        <Tab label="NOVO CLIENTE" value="1" style={{ minWidth: "118px" }} />
        <Tab label="NOVO PET" value="2" style={{ minWidth: "118px" }} />
      </Tabs>
      {parseInt(tab, 10) === 0 &&
        (customer ? (
          <Customer
            setAtualizePet={setAtualizePet}
            customer={customer}
            order={order}
            loading={loading}
            priceCupom={priceCupom}
            setCustomer={setCustomer}
            servicesSeletected={servicesSeletected}
            setSearchCpf={setSearchCpf}
            setPetName={setPetName}
            services={services}
            schedulings={schedulings}
            finalPrice={finalPrice}
            setFinalPrice={setFinalPrice}
            hideServiceList={hideServiceList}
          />
        ) : (
          <SearchCustomer
            setFormDados={setFormDados}
            formDados={formDados}
            setAtualizePet={setAtualizePet}
            atualizePet={atualizePet}
            customer={customer}
            setCustomer={setCustomer}
            petName={petName}
            searchCpf={searchCpf}
            setTab={setTab}
          />
        ))}
      {parseInt(tab, 10) === 1 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <InputMask mask="999.999.999-99">
                    {(inputProps) => (
                      <TextField
                        name="cpf"
                        label="CPF"
                        fullWidth
                        variant="outlined"
                        required
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                }
                name="cpf"
              />
              {!validateCpf && (
                <p className={classes.colorCpf}>Cliente já cadastrado.</p>
              )}
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="firstname"
                label="Nome"
                variant="outlined"
                inputRef={register}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="lastname"
                label="Sobrenome"
                variant="outlined"
                inputRef={register}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <InputMask mask="(99) 99999-9999">
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="cellphone"
                        label="Telefone principal"
                        variant="outlined"
                        required
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                }
                name="cellphone"
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                as={
                  <InputMask mask="(99) 99999-9999">
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        name="telephone"
                        label="Telefone de recado"
                        variant="outlined"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                }
                name="telephone"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="E-mail"
                variant="outlined"
                placeholder="seuemail@seuemail.com"
                type="email"
                inputRef={register}
                required={!errorEmail}
                error={!errorEmail}
                onBlur={({ target }) =>
                  setErrorEmail(regex.maskEmail(target.value))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <h3>Dados do Pet</h3>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Nome do Pet"
                variant="outlined"
                inputRef={register}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Espécie"
                as={
                  <TextField
                    fullWidth
                    select
                    label="Espécie"
                    variant="outlined"
                    required
                  >
                    {attributes["Espécie"] &&
                      attributes["Espécie"].map((value) => (
                        <MenuItem
                          key={value._id}
                          value={value.label}
                          onClick={() => handleBreed(value._id)}
                        >
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Raça"
                disabled={!breeds}
                as={
                  <TextField fullWidth select label="Raça" variant="outlined">
                    {breeds &&
                      breeds.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Sexo"
                as={
                  <TextField
                    required
                    fullWidth
                    select
                    label="Sexo"
                    variant="outlined"
                  >
                    {attributes.Sexo &&
                      attributes.Sexo.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="Peso"
                label="Peso"
                variant="outlined"
                type="number"
                inputRef={register}
                inputProps={{
                  maxLength: 13,
                  step: "0.01",
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Porte"
                as={
                  <TextField
                    required
                    fullWidth
                    select
                    label="Porte"
                    variant="outlined"
                  >
                    {attributes.Porte &&
                      attributes.Porte.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Pelagem"
                as={
                  <TextField
                    fullWidth
                    select
                    label="Pelagem"
                    variant="outlined"
                    required
                  >
                    {attributes.Pelagem &&
                      attributes.Pelagem.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Castrado"
                as={
                  <TextField
                    fullWidth
                    select
                    label="Castrado"
                    variant="outlined"
                  >
                    {attributes.Castrado &&
                      attributes.Castrado.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="birthdate"
                type="date"
                label="Nascimento"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="comments"
                label="Observações"
                variant="outlined"
                multiline
                rows={2}
                inputRef={register}
              />
            </Grid>

            <Grid
              item
              xs={12}
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <Button
                className={classes.button}
                variant="contained"
                type="submit"
                disabled={stateButtom}
              >
                Cadastrar Cliente
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      {parseInt(tab, 10) === 2 && (
        <form onSubmit={handleSubmit(onSubmitPet)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="cpfPet"
                label="CPF"
                variant="outlined"
                required
                error={!validatePetCpf}
                inputRef={register}
              />
              {!validatePetCpf && (
                <p className={classes.colorCpf}>
                  Cliente ainda não cadastrado.
                </p>
              )}
            </Grid>

            <Grid item xs={12}>
              <h3>Dados do Pet</h3>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label="Nome do Pet"
                variant="outlined"
                inputRef={register}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Espécie"
                as={
                  <TextField
                    fullWidth
                    select
                    label="Espécie"
                    variant="outlined"
                    required
                  >
                    {attributes["Espécie"] &&
                      attributes["Espécie"].map((value) => (
                        <MenuItem
                          key={value._id}
                          value={value.label}
                          onClick={() => handleBreed(value._id)}
                        >
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Raça"
                disabled={!breeds}
                as={
                  <TextField fullWidth select label="Raça" variant="outlined">
                    {breeds &&
                      breeds.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Sexo"
                as={
                  <TextField
                    required
                    fullWidth
                    select
                    label="Sexo"
                    variant="outlined"
                  >
                    {attributes.Sexo &&
                      attributes.Sexo.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                name="Peso"
                label="Peso"
                variant="outlined"
                type="number"
                inputRef={register}
                inputProps={{
                  maxLength: 13,
                  step: "0.01",
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Porte"
                as={
                  <TextField
                    required
                    fullWidth
                    select
                    label="Porte"
                    variant="outlined"
                  >
                    {attributes.Porte &&
                      attributes.Porte.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Pelagem"
                as={
                  <TextField
                    fullWidth
                    select
                    label="Pelagem"
                    variant="outlined"
                    required
                  >
                    {attributes.Pelagem &&
                      attributes.Pelagem.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                defaultValue=""
                name="Castrado"
                as={
                  <TextField
                    fullWidth
                    select
                    label="Castrado"
                    variant="outlined"
                  >
                    {attributes.Castrado &&
                      attributes.Castrado.map((value) => (
                        <MenuItem key={value._id} value={value.label}>
                          {value.label}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                type="date"
                name="birthdate"
                label="Nascimento"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="comments"
                label="Observações"
                variant="outlined"
                multiline
                rows={2}
                inputRef={register}
              />
            </Grid>

            <Grid
              item
              xs={12}
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <Button
                className={classes.button}
                variant="contained"
                type="submit"
                disabled={stateButtom}
              >
                Cadastrar Pet
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}

export default SidebarCustomer;
