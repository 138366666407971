import React, { useCallback, useEffect, useState } from 'react'

import { Divider } from '@material-ui/core'
import { useStyles } from './style'

function Resumo({ packages, desconto, type, change, setChange }) {
  const [servicos, setServicos] = useState(0)
  const refreshSoma = useCallback(() => {
    if (packages.length) {
      let soma = 0
      packages.forEach((item) => {
        const add = !item?.bonus ? parseFloat(item.price) : 0
        soma += add
      })
      setServicos(soma)
    }
  }, [packages])

  useEffect(() => {
    refreshSoma()
  }, [packages, refreshSoma])

  useEffect(() => {
    if (change) {
      setChange(false)
      refreshSoma()
    }
  }, [change, refreshSoma, setChange])

  const { Container, Title, Line, StrongLine } = useStyles()

  const totalDeconton =
    type === 'fixed_value' ? desconto : servicos * (desconto / 100)
  const finalValue = servicos - totalDeconton < 0 ? 0 : servicos - totalDeconton

  return (
    <div className={Container}>
      <h1 className={Title}>Resumo do Pacote</h1>
      <p className={Line}>
        <span>Serviços</span>{' '}
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(servicos)}
        </span>
      </p>
      <p className={Line}>
        <span>Descontos</span>{' '}
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(totalDeconton)}
        </span>
      </p>
      <Divider />
      <p className={StrongLine}>
        <span>Total</span>{' '}
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(finalValue)}
        </span>
      </p>
    </div>
  )
}

export default Resumo
