import { Root, Portal } from '@radix-ui/react-alert-dialog'
import { useAlert } from './useAlert'

import { Overlay } from './styles'

export function AlertRoot({ children, name}) {
  const { setAlert, isOpen } = useAlert((st) => st.actions)

  setAlert(name)

  const alerts = useAlert((st) => st.data.alerts)
  const alert = alerts[name]

  return (
    <Root
      open={alert.open}
      onOpenChange={(open) => {
        isOpen(name, open)
      }}
    >
      <Portal>
        <Overlay />
        {children}
      </Portal>
    </Root>
  )
}
