import { ChevronDown } from 'lucide-react'

import { useContext } from "../../Context"
import { useBlockTable } from "../../useBlockTable"

export function Columns({ id }){
  const { tableName } = useContext()
  const toogleHiddenLines = useBlockTable.getState().actions.toogleHiddenLines

  const hiddenDetails = useBlockTable(st => st.data.tables[tableName].hiddenDetails)
  const columns = useBlockTable(st => st.data.tables[tableName].columns)
  const blockColumns = useBlockTable(st => st.data.tables[tableName].blockColumns[id])
  const blockDetails = useBlockTable(st => st.data.tables[tableName].blockDetails[id])

  if(!columns || !blockColumns) return null

  return(
    <div className="flex-1 w-full flex items-center">

        {columns.map(column => (
          <h2
            key={column}
            className="text-base overflow-hidden font-bold flex items-center px-2 w-full flex-1 min-w-[150px] justify-start origin-left text-nowrap"
          >
            {blockColumns[column]?.value || '-'}
          </h2>
        ))}
      <span className='flex h-6 w-6'>
        {!!blockDetails && (
          <button
            type='button'
            data-active={hiddenDetails.includes(id)}
            className='flex w-full h-full items-center justify-center cursor-pointer transition-all duration-200 hover:scale-110 data-[active=true]:rotate-180'
            onClick={()=> toogleHiddenLines(tableName, id)}
          >
            <ChevronDown />
          </button>
        )}
      </span>
    </div>
  )
}
