import styled, { keyframes } from 'styled-components'

import {
  Content,
  Arrow as RadixArrow,
  Trigger as RadixAnchor,
} from '@radix-ui/react-tooltip'

const slideUpAndFade = keyframes`
    0% { opacity: 0; transform: translateY(2px) };
    100% { opacity: 1; transform: translateY(0) };
`

const slideRightAndFade = keyframes`
    0% { opacity: 0; transform: translateX(-2px) };
    100% { opacity: 1; transform: translateX(0) };
`

const slideDownAndFade = keyframes`
    0% { opacity: 0; transform: translateY(-2px) };
    100% { opacity: 1; transform: translateY(0) };
`

const slideLeftAndFade = keyframes`
    0% { opacity: 0; transform: translateX(2px) };
    100% { opacity: 1; transform: translateX(0) };
`

export const Container = styled(Content)`
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 0;
  animation-duration: ${(props) => props.theme.animation.delay.slow};
  animation-timing-function: 'cubic-bezier(0.16, 1, 0.3, 1)';

  &[data-state='open'][data-side='top'] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state='open'][data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state='open'][data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`

export const Arrow = styled(RadixArrow)`
  fill: ${(props) => props.theme.colors.background};
`
export const Anchor = styled(RadixAnchor)`
  position: absolute;
  width: 0px;
  height: 0px;
  padding: 0;
  background: transparent;
  border: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
