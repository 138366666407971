import { useCrm } from '../../../../hooks/CrmContext'
import { Card } from './Card'
import { EmptyCard } from './EmptyCard'

import { Container, SetageHeader, SetageHeaderBadge, CardList } from './styles'

export function Stage({ name, code, color, styles, stageActions }) {
  const { getCards } = useCrm()
  const cards = getCards(code)

  return (
    <Container>
      <SetageHeader color={color}>
        <h4>{name}</h4>
        <SetageHeaderBadge color={color}>
          {cards.length} clientes
        </SetageHeaderBadge>
      </SetageHeader>
      <CardList>
        {!!cards &&
          cards.map((cards, index) => (
            <Card
              key={cards.cardId}
              {...cards}
              index={index}
              stageActions={stageActions}
            />
          ))}

        {!!cards && cards.length === 0 && <EmptyCard {...styles} />}
      </CardList>
    </Container>
  )
}
