import ScrollArea from 'react-scrollbar'
import { useCallback, useState } from 'react'
import { useRanking } from '../../../../hooks/RankingContext'
import { abbreviate } from '../../../../services/functions'
import { Employee } from '../Employee'
import { Title } from '../styles'

import {
  Container,
  Concorrentes,
  Concorrente,
  ConcorrenteGoal,
  ConcorrenteGoalPercent,
  ConcorrenteGoalBar,
} from './styles'

export function Global() {
  const [scrollHeight, setScrollHeight] = useState(-1)
  const { getGlobalRanking, getPagination, updatePagination } = useRanking()

  const ranking = getGlobalRanking()
  const pagination = getPagination()

  const paginationOnScroll = useCallback(
    ({ realHeight, topPosition, containerHeight }) => {
      if (
        typeof topPosition === 'number' &&
        topPosition + containerHeight >= realHeight - 200 &&
        pagination
      ) {
        if (realHeight > scrollHeight) {
          updatePagination(pagination.global + 1, 'global')
          setScrollHeight(realHeight)
        }
      }
    },
    [pagination, scrollHeight, updatePagination],
  )

  return (
    <Container>
      <Title>Ranking Petland</Title>
      <ScrollArea
        smoothScrolling
        onScroll={paginationOnScroll}
        horizontal={false}
      >
        <Concorrentes>
          {!!ranking &&
            ranking.map((player, i) => (
              <Concorrente key={player.id}>
                <Employee.Root>
                  <Employee.Avatar name={player.name}>
                    <Employee.Position
                      value={i + 1}
                      tip={i + 1 >= 100 && 'ranking-employee-position'}
                    />
                  </Employee.Avatar>
                  <Employee.Informations>
                    <Employee.InformationTitle>
                      {abbreviate(player.name)}
                    </Employee.InformationTitle>
                    <Employee.InformationSubTitle>
                      {player.store}
                    </Employee.InformationSubTitle>
                  </Employee.Informations>
                </Employee.Root>
                <ConcorrenteGoal>
                  <ConcorrenteGoalPercent>
                    {Math.ceil(player.percentage)}%
                  </ConcorrenteGoalPercent>
                  <ConcorrenteGoalBar total={player.percentage} />
                </ConcorrenteGoal>
              </Concorrente>
            ))}
        </Concorrentes>
      </ScrollArea>
    </Container>
  )
}
