import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import API from '../services/api'

// Componentes
import Package from '../components/organisms/Package'

// moment
import 'moment/locale/pt-br'

moment.locale('pt-br')

const options = ['ativo', 'inativo']

function Pacotes({ location }) {
  const [stateValue, setStateValue] = useState(0)
  const [packages, setPackages] = useState()
  const [packageSold, setPackageSold] = useState()
  const history = useHistory()
  const isClinica = location.pathname.split('/').includes('packages')

  const departmentGet = isClinica ? 'Clínica Veterinária' : 'Banho e Tosa'

  // PAGINAÇÃO
  const date = new Date()
  const primeiroDia = moment(
    new Date(date.getFullYear(), date.getMonth(), 1),
  ).format('YYYY-MM-DD')
  const [pagination, setPagination] = useState(null)

  const getPackage = async (text = '') => {
    try {
      const { data } = await API.get(
        `packages/rules/search?text=${text}&department=${departmentGet}`,
      )
      setPackages(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getPackageActive = async (active = true) => {
    try {
      const { data } = await API.get(
        `packages/rules/search?active=${active}&department=${departmentGet}`,
      )
      setPackages(data.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getPackageSold = async (
    currentePage = 1,
    firstData = null,
    lastData = null,
  ) => {
    try {
      const { data } = await API.get(
        `packages/orders?dateFrom="${firstData || primeiroDia}"&dateTo="${
          lastData || moment(date).format('YYYY-MM-DD')
        }"&currentePage=${currentePage}&perPage=50&department=${departmentGet}`,
      )
      setPackageSold(data.results)
      setPagination(data?.pagination)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getPackage()
    getPackageSold()
  }, [])

  return (
    <>
      {stateValue === 0 && (
        <Package
          packages={packages}
          packageSold={packageSold}
          getPackageSold={getPackageSold}
          options={options}
          date={date}
          primeiroDia={primeiroDia}
          stateValue={setStateValue}
          getPackage={getPackage}
          pagination={pagination}
          setPackageSold={setPackageSold}
          getPackageActive={getPackageActive}
        />
      )}
      {stateValue === 1 && history.push(`/pacotes/new?clinica=${isClinica}`)}
    </>
  )
}

export default Pacotes
