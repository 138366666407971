import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: true,
  departmentServices: false,
  services: {},
};

export const useService = create((set) => ({
  data: defaultData,
  actions: {
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),
    setDepartmentServices: (departmentServices) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.departmentServices = departmentServices;
        })
      ),

    clearServices: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),

    setServices: (services = false, serviceKey = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!services) {
            draft.data.services = {};
            return;
          }

          _.concat([], services).forEach(service => {
            const serviceId = serviceKey || service.id;

            if (serviceId) {
              draft.data.services[serviceId] = {
                ...service,
                returned: false,
                removed: false,
              };
            }
          })
        })
      ),

    removeService: (serviceKey = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!serviceKey) return;

          draft.data.services[serviceKey].returned = false;
          draft.data.services[serviceKey].removed = true;
        })
      ),

    returnService: (serviceKey = false) =>
      set((state) =>
        produce(state, (draft) => {
          console.log(serviceKey)
          if (!serviceKey) return;

          draft.data.services[serviceKey].returned = true;
          draft.data.services[serviceKey].removed = false;
        })
      ),

    deleteService: (serviceKey) =>
      set((state) =>
        produce(state, (draft) => {
          if (!serviceKey) return;

          delete draft.data.services[serviceKey];
        })
      ),

    updateService: (serviceKey, values) =>
      set((state) =>
        produce(state, (draft) => {
          const currentValue = state.data.services[serviceKey];

          draft.data.services[serviceKey] = {
            ...currentValue,
            ...values,
          };
        })
      ),

    setFoundServices: (serviceKey, services) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.services[serviceKey].foundServices = services;
        })
      ),
  },
}));
