import { StyleSheet } from "@react-pdf/renderer";
import { getCurrentThemeStyle } from "../../../../../services/functions";

const theme = getCurrentThemeStyle()

export const container = StyleSheet.create({
  display: "flex",
  width: "1px",
  flex: "1",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: "8px"
})

export const title = StyleSheet.create({
  width: "100%",
  textAlign: 'center',
  fontFamily: 'Helvetica-Bold',
  color: theme.colors.scale[600],
  fontSize: '16px',
  margin: '4px 0 8px 0'
})

export const content = StyleSheet.create({
  display: "flex",
  flex: "1",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "row",
  gap: "8px"
})

export const HTMLContentStyle = `
<style>
  * {
    padding: 0;
    margin: 0;
  }
  p { margin: 0 0 1px 0; line-height: 1.5px; font-size: 10px; color: ${theme.colors.gray[600]}; }
  strong { font-family: "Helvetica-Bold"; color: ${theme.colors.scale[600]}; }
  hr { border: none; height: 4px; }

  span {
    font-weight: 700;
    color: ${theme.colors.scale[600]};
  }
  em {
    font-style: normal;
    color: ${theme.colors.gray[900]};
  }
  .right {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .column {
    flex-direction: column;
  }
  .line {
    display:block;
    width: 50%;
    height: 2px;
    margin-bottom: 2px;
    border-bottom: 1px solid ${theme.colors.gray[800]};
  }
</style>
`
