import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
`

export const StartsAverageTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`

export const StartsAverageStats = styled.span`
  width: 140px;
  height: 30px;
  margin: 0;
  position: relative;
  ::after {
    content: '';
    z-index: 1;
    width: ${(props) => props.average}%;
    height: 100%;
    transition: all 250ms linear;
    position: absolute;
    left: 0;
    top: 0;
    background: #fbd723;
  }
  ::before {
    content: '';
    z-index: 2;
    background: url('/img/starsMask.png');
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
`
export const StartsTotalResponse = styled.em`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-top: 20px;
`
