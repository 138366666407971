import { toast } from "react-toastify";

import api from "../../api";
import { formatBody } from "../../formatBody/veterinaryCare";
import { formatResponses } from "../../formatResponses/veterinaryCare";

export default class VeterinaryRecordModel {
  constructor(orderId) {
    this._orderId = orderId;
  }

  findRecord = async () => {
    try {
      const { data } = await api.get(
        `service-order/appointment/${this._orderId}/anamnesis`
      );

      const formattedRecord = formatResponses.record(data.anamnesis);
      return formattedRecord;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveWeight = async (weight) => {
    if (!weight) return;

    try {
      const { data } = await api.put(
        `service-order/appointment/${this._orderId}/pet-weight`,
        { weight: weight.toString() }
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveAnamnese = async (reportValues) => {
    if (!reportValues) return;
    const formattedValues = formatBody.report(reportValues);
    const anamneseValues = _.pick(formattedValues, ["mainComplaint"]);

    try {
      const { data } = await api.post(
        `service-order/appointment/${this._orderId}/anamnesis`,
        anamneseValues
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveVitalSigns = async (reportValues) => {
    if (!reportValues) return;
    const formattedValues = formatBody.report(reportValues);
    const vitalSignsValues = _.pick(formattedValues, [
      "exam.heartRate",
      "exam.respiratoryRate",
      "exam.TPC",
      "exam.temperature",
    ]);

    try {
      const { data } = await api.post(
        `service-order/appointment/${this._orderId}/anamnesis`,
        vitalSignsValues
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveGeneralPhysicalExamination = async (reportValues) => {
    const formattedValues = formatBody.report(reportValues);
    const generalPhysicalExaminationValues = _.pick(formattedValues, [
      "exam.earsCondition",
      "exam.mucosa",
      "exam.hydration",
      "exam.lymphNode",
      "exam.skinFur",
    ]);

    try {
      const { data } = await api.post(
        `service-order/appointment/${this._orderId}/anamnesis`,
        generalPhysicalExaminationValues
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveBodyExamination = async (reportValues) => {
    const formattedValues = formatBody.report(reportValues);
    const bodyExamination = _.pick(formattedValues, [
      "exam.thoracicLimbs",
      "exam.chest",
      "exam.pelvicLimbs",
      "exam.abdomen",
    ]);

    try {
      const { data } = await api.post(
        `service-order/appointment/${this._orderId}/anamnesis`,
        bodyExamination
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  saveDiagnosis = async (reportValues) => {
    const formattedValues = formatBody.report(reportValues);
    const diagnosis = _.pick(formattedValues, ["diagnosisAndTreatment"]);

    try {
      const { data } = await api.post(
        `service-order/appointment/${this._orderId}/anamnesis`,
        diagnosis
      );

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
