import styled from "styled-components";
// import { animated } from "@react-spring/web";
import { motion } from "framer-motion";

export const Container = styled(motion.span)`
  background: linear-gradient(
    90deg,
    ${(props) => !!props.bg ? props.bg[0] : "transparent"} 40%,
    ${(props) => !!props.bg ? props.bg[1] : "transparent"} 60%
  );
  transition: background ${(props) => props.theme.animation.delay.fast} linear;
  position: absolute;
  left: 0;
  top: 0;
  width: 300%;
  height: 100%;
`;
