import styled, { keyframes } from "styled-components";
import {
  Content as RadixContent,
  Overlay as RadixOverlay,
  Title as RadixTitle,
  Description as RadixDescription,
} from "@radix-ui/react-alert-dialog";

import {
  Root as RadixScrollAreaRoot,
  Viewport as RadixScrollAreaViewport,
  Scrollbar as RadixScrollAreaScrollbar,
  Thumb as RadixScrollAreaThumb,
} from '@radix-ui/react-scroll-area';

const overlayShow = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const Overlay = styled(RadixOverlay)`
  background: ${(props) => props.theme.colors.overlay};
  position: fixed;
  z-index: 110;
  inset: 0;
  animation: ${overlayShow} ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.16, 1, 0.3, 1);
`;

export const Content = styled(RadixContent)`
  background: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  position: fixed;
  z-index: 111;
  left: 50%;
  top: 50%;
  width: 90vw;
  max-width: 500px;
  height: fit-content;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
`;

export const ScrollAreaRoot = styled(RadixScrollAreaRoot)`
  width: 100%;
  max-height: 90vh;
  height: ${(props) => props.height};
  border-radius: 4;
  overflow: hidden;
`;

export const ScrollAreaViewport = styled(RadixScrollAreaViewport)`
  width: 100%;
  height: 100%;
  borderRadius: inherit;
  padding: 20px;
`;

export const ScrollAreaScrollbar = styled(RadixScrollAreaScrollbar)`
  display: flex;
  userSelect: none;
  touchAction: none;
  padding: 2;
  background: ${(props) => props.theme.colors.gray[50]};
  transition: background 160ms ease-out;
  &:hover {
    background: ${(props) => props.theme.colors.gray[200]};
  };
  &[data-orientation="vertical"]{
   width:10px;
  };
`;

export const ScrollAreaThumb = styled(RadixScrollAreaThumb)`
  display:flex;
  flex: 1;
  background: ${(props) => props.theme.colors.scale[400]};
  border-radius: 9999px;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%; -50%);
    width: 100%;
    height: 100%;
    minWidth: 44px;
    minHeight: 44px;
  };
`;
export const Title = styled(RadixTitle)`
  font-size: 28px;
  margin: 0 0 16px 0;
  text-align: center;
  color: ${(props) => props.theme.colors.scale[600]};
`;
export const Description = styled(RadixDescription)`
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0;
  font-size: 14px;
  width: 100%;
  color: ${(props) => props.theme.colors.gray[700]};
  gap: 12px;
  span: {
    width: 100%;
  }
  .highlight {
    color: ${(props) => props.theme.colors.scale[600]};
  }
`;

export const ActionBox = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
