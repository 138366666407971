
import { twMerge } from 'tailwind-merge'

export function CardSeparator({ className, ...rest }) {
  return (
    <u
      className={twMerge(
        'flex w-full h-[1px] my-4',
        className,
      )}
      {...rest}
    />
  )
}
