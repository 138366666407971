import { CallRounded } from '@material-ui/icons'
import {
  Avatar,
  Buttons,
  Container,
  Customer,
  Badge,
  Pets,
  Phone,
} from './styles'
import { formatCellPhoneNumber } from '../../../../../services/functions'
import { useCrm } from '../../../../../hooks/CrmContext'
import { Action } from '../Action'

export function Card({
  index,
  cardId,
  cellphone,
  optout,
  customerFirstname,
  customerLastname,
  hasAppointment,
  pets,
  stageActions,
}) {
  const { viewDetails } = useCrm()

  return (
    <Container
      delay={index * 500 * 0.3}
      onClick={() => {
        viewDetails(cardId)
      }}
      className={optout ? 'optout' : ''}
    >
      <Avatar>{customerFirstname.charAt(0)}</Avatar>
      <Customer>
        {customerFirstname} {customerLastname}
      </Customer>
      <Pets title={pets.join(', ')}>{pets.join(', ')}</Pets>
      <Buttons>
        {hasAppointment && <Badge>agendado</Badge>}
        {stageActions.map((action) => (
          <Action key={action.id} {...action} cardId={cardId} />
        ))}
      </Buttons>
      <Phone>
        <span>
          <CallRounded /> {formatCellPhoneNumber(cellphone)}
        </span>
      </Phone>
    </Container>
  )
}
