import React from "react";
import {
  formatCpf,
  formatMoney,
  formatName,
} from "../../../../services/functions";

import { SubscriberController } from "../../../../services/controllers/subscription";
import { useSubscribers } from "../../../../store/subscription/useSubscribers";

import { Table } from "../../../../components/ui/Table";
import { Pagination } from "../../../../components/ui/Pagination";

import { Status } from "../Status";

import { SubscriberActions } from "./SubscriberActions";
import { TwoColuns } from "./styles";

export function SubscribersTable() {
  const CSubscriber = SubscriberController();

  const page = useSubscribers((st) => st.data.pagination.page);
  const isWaiting = useSubscribers((st) => st.data.isWaiting);
  const paginationData = useSubscribers((st) => st.data.pagination);

  const subscribers = useSubscribers((st) => st.data.subscribers);
  return (
    <Table.Root name={CSubscriber.tableName} config customActions>
      <Table.Header
        columns={[
          "Data:100px",
          "CPF:130px:true",
          "Cliente:minmax(70px, 1fr)",
          "Pet:minmax(50px, 1fr)",
          "Porte:150px:true",
          "Vendedor:1fr:true",
          "Plano:100px",
          "Preço:80px",
          "Disponíveis:105px",
          "Cobrança:minmax(0px, 110px)",
          "Cancelamento:140px",
          "Válido até:130px",
          "Status:120px",
          ":210px"
        ]}
      />
      <Table.Content isLoading={isWaiting}>
        {!!subscribers[page] &&
          subscribers[page].map((subscriber) => (
            <Table.Line key={subscriber.id}>
              <Table.Item>{subscriber.createdAt}</Table.Item>
              <Table.Item>{formatCpf(subscriber.customer.cpf)}</Table.Item>
              <Table.Item>{formatName(subscriber.customer.name.fullName)}</Table.Item>
              <Table.Item>{formatName(subscriber.pet.name)}</Table.Item>
              <Table.Item>{subscriber.pet.size.join(', ')}</Table.Item>
              <Table.Item>{formatName(subscriber.saller || '-')}</Table.Item>
              <Table.Item>{formatName(subscriber.plan.type)}</Table.Item>
              <Table.Item>{formatMoney(subscriber.plan.price)}</Table.Item>
              <Table.Item>
                <strong>{subscriber.plan.avaliableBath} </strong>
                {subscriber.plan.avaliableBathText}
              </Table.Item>
              <Table.Item>{subscriber.nextBillingAt}</Table.Item>
              <Table.Item>{subscriber.canceledAt}</Table.Item>
              <Table.Item>{subscriber.validUntilDate}</Table.Item>
              <Table.Item><Status>{subscriber.status}</Status></Table.Item>
              <TwoColuns>
                <SubscriberActions subscriber={subscriber} />
              </TwoColuns>
            </Table.Line>
          ))}
      </Table.Content>
      <Pagination.Root
        name={CSubscriber.paginationName}
        totalPages={paginationData.totalPages - 1}
        currentPage={paginationData.page}
        onChange={(page) => CSubscriber.setPage(page)}
      >
        <Pagination.Back />
        <Pagination.Pages />
        <Pagination.Forward />
      </Pagination.Root>
    </Table.Root>
  );
}
