import styled, { keyframes } from 'styled-components'

const enterPet = keyframes`
    from {
        opacity: 0;
        transform: translate(0,-60px);    
    }
    to {
        opacity: 1;
        transform: translate(0, 0px);    
    }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  opacity: 0;
  transition: all 150ms linear;
  transform: translate(0, -60px);
  animation: ${enterPet} 200ms cubic-bezier(0, 0.26, 0, 0.94)
    ${(p) => p.delay}ms forwards;
  &:hover {
    margin-top: -5px;
  }
`
export const PetAvatar = styled.span`
  background: #cccccc;
  color: #ffffff;
  width: 40px;
  height: 40px;
  font-size: 25px;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  text-transform: uppercase;
  &.active {
    background: #d2d735;
  }
`

export const PetName = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #cccccc;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 50px;
  height: 1.2em;
  white-space: nowrap;
  &.active {
    color: #d2d735;
  }
`
