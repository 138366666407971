import API from './api'

export const getSellers = async (text = '') => {
  const {
    data: { results = [] },
  } = await API.get(`employees/sellers/search${text ? `?text=${text}` : ''}`)

  return results
}

export const postSeller = async (id, sellerId) => {
  const results = await API.post(
    `service-order/appointment/${id}/update-seller`,
    {
      sellerId,
    },
  )

  return results
}
