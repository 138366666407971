import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

// Material ui - style
import { makeStyles } from "@material-ui/core/styles";

// Material ui - core

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import {
  Button,
  Grid,
  Snackbar,
  TablePagination,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// Material ui - lab
import Skeleton from "@material-ui/lab/Skeleton";

// moment
import moment from "moment";
import { MockAvailablesTimes } from "../../../../components/atoms/AvailableTimes/mock";
import api from "../../../../services/api";
import { getVets } from "../../../../services/vets";

export function OldDrawer({ title, vaccinationId, recordId, drawerState }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      maxWidth: 280,
      marginBottom: "20px",
      marginTop: "20px",
      border: "1px solid #ccd219",
    },
    input: {
      marginLeft: theme.spacing(1),
      padding: 5,
      flex: 1,
    },
    table: {
      minWidth: 300,
      border: "1px solid #ccc",
    },
    footer: {
      position: "fixed",
      bottom: "0",
      background: "#D7D7D7",
      padding: "20px",
      width: "624px",
    },
    contentButton: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonEnd: {
      backgroundColor: "#CCD219",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#fff",
        border: "1px solid #CCD219",
        color: "#CCD219",
      },
    },
    sms: {
      height: "45px",
    },
    inputSms: {
      "& input": {
        padding: "7px",
      },
    },
    inputClient: {
      "& input": {
        padding: "12px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        top: "1px !important",
      },
    },
    titleClient: {
      paddingBottom: "0",
      marginBottom: "-21px",
      fontSize: "14px",
      fontWeight: "700",
      color: "rgba(0, 0, 0, 0.56)",
    },
    titleDrawer: {
      paddingBottom: "0",
      fontSize: "14px",
      fontWeight: "700",
      color: "rgba(0, 0, 0, 0.56)",
    },
    subTitleDrawer: {
      paddingBottom: "0",
      fontSize: "14px",
      fontWeight: "300",
      color: "#666666",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    height: "50px",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00567e",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const date = new Date();
  const [protocol, setProtocol] = useState();
  const [infoDrawer, setInfoDrawer] = useState({});

  const [drawerOpen, setDrawerOpen] = drawerState;
  const [disabledButton, setDisabledButton] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(false);
  const [vacinas, setVacinas] = useState();
  const [vacinasFiltrada, setVacinasFiltrada] = useState();
  const [firstData, setFirstData] = useState(
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "YYYY-MM-DD"
    )
  );
  const [lastData, setLastData] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [page, setPage] = React.useState(0);
  const [vaccines, setVaccines] = useState([]);
  const [vaccineType, setVaccineType] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [vets, setVets] = useState([]);
  const [hours, setHours] = useState("");
  const [profi, setProfi] = useState("");
  const [dateEdit, setDateEdit] = useState("");
  const [roomSelected, setRoomSelected] = useState("");
  const [informationId, setInformationId] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const { control, handleSubmit } = useForm({});
  const mockAvailablesTimes = MockAvailablesTimes?.availableTimes;
  const classes = useStyles();

  // Status filter
  const [statusFilter, setStatusFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [petFilter, setPetFilter] = useState("");
  const [protocolFilter, setProtocolFilter] = useState("");

  const [vaccineFilter, setVaccineFilter] = useState("");

  function clickDrawer() {
    setDrawerOpen(!drawerOpen);
    if (!drawerOpen) setInfoDrawer({});
  }

  async function getVacinas() {
    try {
      const { data } = await api.get(
        `vaccinations?fromDate=${firstData}&toDate=${lastData}`
      );
      setVacinas(data.vaccinations);
      setVacinasFiltrada(data.vaccinations);
    } catch (err) {
      console.log("Erro:", err);
    }
  }

  const hendleSubmitDrawer = async () => {
    setDisabledButton(true);
    const postProfi = vets.filter((item) => item.id === profi);


    try {
      const result = await api.post(
        `record/${recordId}/vaccination/${vaccinationId}`,
        {
          vaccinationData: {
            date: dateEdit,
          },
          serviceOrderData: {
            isScheduled: true,
            startsAt: hours,
            durationInMinutes: 30,
            employeeId: profi,
            employeeName: postProfi[0].name,

          },
        }
      );

      if (result?.status === 201) {
        setMessage(result?.data?.message);
        setSnackbar(true);
        setDisabledButton(false);
        clickDrawer();
        getVacinas();
      }
    } catch (err) {
      setSnackbar(true);
      setMessage("error");
      setDisabledButton(false);
      setErrMsg(err?.response?.data?.friendly_message);
      clickDrawer();
    }
  };

  async function getDrawer() {
    try {
      const { data } = await api.get(
        `record/${recordId}/vaccination/${vaccinationId}`
      );
      setInfoDrawer(data);
      setProfi(data?.serviceOrderData?.employeeId);
      setRoomSelected(data?.serviceOrderData?.roomId);
    } catch (err) {
      console.log("Erro:", err);
    }
  }

  useEffect(() => {
    if (drawerOpen) {
      getDrawer();
    }
  }, [drawerOpen]);

  useEffect(() => {
    async function vetsFunctionGet() {
      try {
        const result = await getVets();
        setVets(result);
      } catch (err) {
        console.log("Erro:", err);
      }
    }
    if (drawerOpen) {
      vetsFunctionGet();
    }
  }, [drawerOpen]);

  useEffect(() => {
    async function roomGet() {
      try {
        const result = await api.get("rooms");
        setRooms(result?.data?.result.filter((item) => item.active) || []);
      } catch (err) {
        console.log("Erro:", err);
      }
    }
    if (drawerOpen) {
      roomGet();
    }
  }, [drawerOpen]);

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => null}>
      <div
        style={{
          background: "#00567e",
          color: "#fff",
          padding: "10px",
          fontSize: "18px",
        }}
      >
        {title}

        {/* ?.vaccinationData?.status === "ORÇAMENTO ENVIADO" && "VER "}
        {infoDrawer?.vaccinationData?.status === "VACINA PROGRAMADA" &&
          "CRIAR "}
        {infoDrawer?.vaccinationData?.status === "AGENDADO" && "EDITAR "}
        AGENDAMENTO */}
      </div>

      <form onSubmit={handleSubmit(hendleSubmitDrawer)}>
        <div className={classes.content} style={{ padding: "20px" }}>
          <Grid container spacing={2} style={{ width: "600px" }}>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                component="h3"
                className={classes.titleDrawer}
              >
                Cliente
              </Typography>
              <Typography
                variant="h6"
                component="h4"
                className={classes.subTitleDrawer}
              >
                {infoDrawer?.vaccinationData?.customerFirstName ? (
                  <>
                    {infoDrawer?.vaccinationData?.customerFirstName}{" "}
                    {infoDrawer?.vaccinationData?.customerLastName}
                  </>
                ) : (
                  <Skeleton />
                )}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                variant="h6"
                component="h3"
                className={classes.titleDrawer}
              >
                Pet
              </Typography>
              <Typography
                variant="h6"
                component="h4"
                className={classes.subTitleDrawer}
              >
                {infoDrawer?.vaccinationData?.petName ? (
                  <>{infoDrawer?.vaccinationData?.petName}</>
                ) : (
                  <Skeleton />
                )}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="h6"
                component="h3"
                className={classes.titleDrawer}
              >
                {infoDrawer?.vaccinationData?.status === "VACINA PROGRAMADA" ? (
                  <span
                    style={{
                      float: "right",
                      fontWeight: "300",
                      fontSize: "14px",
                      background: "#65CF72",
                      padding: "8px",
                      color: "#fff",
                      borderRadius: "25px",
                    }}
                  >
                    {infoDrawer?.vaccinationData?.status}
                  </span>
                ) : (
                  <span
                    style={{
                      float: "right",
                      fontWeight: "300",
                      fontSize: "14px",
                      background: "#CCD219",
                      padding: "8px",
                      color: "#fff",
                      borderRadius: "25px",
                    }}
                  >
                    {infoDrawer?.vaccinationData?.status}
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ width: "600px" }}>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                component="h3"
                className={classes.titleDrawer}
                style={{ marginTop: "30px" }}
              >
                Vacina
              </Typography>
              <Typography
                variant="h6"
                component="h4"
                className={classes.subTitleDrawer}
              >
                {infoDrawer?.vaccinationData?.vaccineName ? (
                  <>{infoDrawer?.vaccinationData?.vaccineName}</>
                ) : (
                  <Skeleton />
                )}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                variant="h6"
                component="h3"
                className={classes.titleDrawer}
                style={{ marginTop: "30px" }}
              >
                Protocolo
              </Typography>
              <Typography
                variant="h6"
                component="h4"
                className={classes.subTitleDrawer}
              >
                {infoDrawer?.vaccinationData?.protocolName ? (
                  <>{infoDrawer?.vaccinationData?.protocolName}</>
                ) : (
                  <Skeleton />
                )}
              </Typography>
            </Grid>
          </Grid>

          <Divider style={{ marginBottom: "15px", marginTop: "15px" }} />

          {infoDrawer?.vaccinationData && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
              style={{ width: "600px" }}
            >
              {infoDrawer?.vaccinationData && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="dateEdit"
                    defaultValue={moment(infoDrawer?.vaccinationData?.date)
                      .add(1, "days")
                      .format("YYYY-MM-DD")}
                    as={({ onChange, value }) => (
                      <TextField
                        id="dateEdit"
                        label="Data"
                        type="date"
                        value={value}
                        required
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          onChange(e.target.value);
                          setDateEdit(e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="hours"
                  defaultValue={infoDrawer?.serviceOrderData?.startsAt}
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      className={classes.hours}
                      select
                      value={value}
                      required
                      label="Horário"
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setHours(e.target.value);
                      }}
                    >
                      {mockAvailablesTimes?.map((item) => (
                        <MenuItem key={item?.time} value={item?.time}>
                          {item.time}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="profissional"
                  defaultValue={infoDrawer?.serviceOrderData?.employeeId}
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      className={classes.profissional}
                      select
                      value={value}
                      required
                      label="Profisional"
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        onChange(e.target.value);
                        setProfi(e.target.value);
                      }}
                    >
                      {vets?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          )}

          {infoDrawer?.protocolVaccinations?.length > 0 && (
            <Grid container style={{ marginTop: "25px" }}>
              <Grid xs={12}>
                <p
                  style={{
                    color: "#666666",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  VACINAS PROGRAMADAS
                </p>
              </Grid>

              <Grid xs={12}>
                <TableContainer elevation={0}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Data</StyledTableCell>
                        <StyledTableCell>Vacina</StyledTableCell>
                        <StyledTableCell>Laboratório</StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {infoDrawer?.protocolVaccinations?.map((row) => (
                        <StyledTableRow
                          key={row?.vaccineName}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>{row?.friendlyDate}</TableCell>
                          <TableCell>{row?.vaccineName}</TableCell>
                          <TableCell>{row?.laboratoryName}</TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </div>

        <div className={classes.footer}>
          <Grid container className={classes.contentButton}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                color="primary"
                onClick={() => setDrawerOpen(false)}
                style={{
                  fontSize: "13px",
                  color: "#ccd219",
                  border: "1px solid #ccd219",
                  backgroundColor: "#fff",
                  padding: "10px 50px",
                  marginRight: "22px",
                }}
              >
                {infoDrawer?.vaccinationData?.status === "ORÇAMENTO ENVIADO"
                  ? "Voltar"
                  : "Cancelar"}
              </Button>
              {infoDrawer?.vaccinationData?.status !== "ORÇAMENTO ENVIADO" && (
                <Button
                  color="primary"
                  disabled={disabledButton}
                  type="submit"
                  style={{
                    fontSize: "13px",
                    color: "#fff",
                    border: "0",
                    backgroundColor: "#ccd219",
                    padding: "10px 50px",
                  }}
                >
                  {infoDrawer?.vaccinationData?.status ===
                    "VACINA PROGRAMADA" && "CRIAR AGENDAMENTO"}

                  {infoDrawer?.vaccinationData?.status === "AGENDADO" &&
                    "EDITAR AGENDAMENTO"}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </form>
    </Drawer>
  );
}
