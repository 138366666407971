import styled, { css } from "styled-components";
import { Resizable as ResizableBox } from "react-resizable-element";
import { rgba } from "polished";

const horizontal = css`
  top: 0;
  height: 100%;
  width: 685px;
  min-width: 495px;
  max-width: calc(100vw - 100px);
`;
const vertical = css`
  left: 0;
  height: 300px;
  width: 100%;
  min-height: 280px;
  max-height: calc(100vh - 100px);
`;

export const Resizable = styled(ResizableBox)`
  background: ${(props) => props.theme.colors.background};
  position: fixed;
  z-index: 30;
  box-shadow: 0px 0 10px 5px ${(props) => props.theme.colors.overlay};
  display: flex;
  flex-direction: column;
  transition: transform ${(props) => props.theme.animation.delay.normal}
    ease-in-out;
  &.left {
    right: 0;
    transform: translateX(150%);
    border-left: 1px solid ${(props) => rgba(props.theme.colors.gray[800], 0.5)};
    ${horizontal}
  }

  &.right {
    left: 0;
    border-right: 1px solid
      ${(props) => rgba(props.theme.colors.gray[800], 0.5)};
    ${horizontal}
  }
  &.top {
    bottom: 0;
    border-top: 1px solid ${(props) => rgba(props.theme.colors.gray[800], 0.5)};
    ${vertical}
  }
  &.bottom {
    top: 0;
    border-bottom: 1px solid
      ${(props) => rgba(props.theme.colors.gray[800], 0.5)};
    ${vertical}
  }

  &.open {
    &.top {
      bottom: 0;
    }
    &.left {
      transform: translateX(0);
    }
    &.right {
      left: 0;
    }
    &.bottom {
      top: 0;
    }
  }
`;

export const Header = styled.header`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  margin: 0;
  background: ${(props) => props.theme.colors.scale[500]};
  color: ${(props) => props.theme.colors.background};
  font-size: 18px;
  font-weight: normal;
  div {
    z-index: 2;
    position: relative;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  flex: 1;
  gap: 20px;
  padding: 10px;
  overflow: hidden;
  position: relative;
  overflow-y: auto;
`;

export const Footer = styled.footer`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 10px 20px;
  min-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  background: ${(props) => props.theme.colors.scale[500]};
  color: ${(props) => props.theme.colors.background};
  div {
    z-index: 2;
    position: relative;
    a {
      color: ${(props) => props.theme.colors.highlights[500]};
    }
  }
`;
export const Controls = styled.div`
  position: absolute;
  right: calc(100% + 1px);
  top: 16px;
  display: flex;
  align-items: flex-end;
  gap: 4px;
  flex-direction: column;
  overflow-x: hidden;
`;
export const ControlAction = styled.button`
  --default-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px
    5px;
  --hover-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 6px 6px 8px;
  --active-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 1px 1px 4px;
  --base-color: ${(props) => props.theme.colors.scale[400]};
  ${(props) =>
    props.status === "danger" &&
    css`
      --base-color: ${(props) => props.theme.colors.error};
    `}
  ${(props) =>
    props.status === "warning" &&
    css`
      --base-color: ${(props) => props.theme.colors.warning};
    `}
  ${(props) =>
    props.status === "success" &&
    css`
      --base-color: ${(props) => props.theme.colors.success};
    `}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  padding: 8px 12px;
  font-size: 16px;
  width: 56px;
  outline: none;
  background: var(--base-color);
  color: ${(props) => props.theme.colors.background};
  border-color: ${(props) => props.theme.colors.gray[200]};
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  box-shadow: var(--default-shadow);
  border-radius: 5px 0 0 5px;
  border-right: none;
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in;
  transform: translateX(15px);
  &[disabled] {
    background: ${(props) => props.theme.colors.gray[300]};
    cursor: not-allowed;
    width: 48px;
  }
  &:hover:not([disabled]) {
    width: 68px;
    transform: translateX(15px);
  }

  &:active:not([disabled]) {
    width: 48px;
  }
  ${(props) =>
    props.active &&
    css`
      cursor: default;
      &:hover:not([disabled]),
      &:active:not([disabled]) {
        transform: scale(1);
        box-shadow: var(--default-shadow);
      }
    `}

  ${(props) =>
    props.outline &&
    css`
      background: ${(props) => props.theme.colors.background};
      color: var(--base-color);
      border-color: var(--base-color);
      ${(props) =>
        props.active &&
        css`
          background: var(--base-color);
          color: ${(props) => props.theme.colors.background};
        `}
      &[disabled] {
        color: ${(props) => props.theme.colors.gray[300]};
        background: ${(props) => props.theme.colors.background};
        border-color: ${(props) => props.theme.colors.gray[300]};
      }
    `}
`;
