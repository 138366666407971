import styled from 'styled-components'

import { Grid } from '@material-ui/core'

export const Container = styled(Grid)`
  background: #fff;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &.MuiGrid-grid-xs-6 {
    flex-basis: calc(50% - 24px);
  }
`
