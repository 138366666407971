import moment from "moment";

import { formatName, getArticles, getStoreName, mountAddress } from "../functions";
import { requestTemplates } from "../templates/request";

function formatSendAttachment(data) {
  const petData = data.pet;
  const ownerData = data.pet.owner;
  const veterinarian = data.veterinarian;
  const attachmentData = data.attachment;

  const store = getStoreName();
  const articles = getArticles(petData.petAttributes.gender);

  return {
    tutorName: encodeURIComponent(formatName(ownerData.name.firstName)),
    veterinaryName: encodeURIComponent(formatName(veterinarian.name)),
    storeName: encodeURIComponent(store.fullName),
    attachmentPath: encodeURIComponent(attachmentData.path),
    petArticle: encodeURIComponent(articles[1]),
    petName: encodeURIComponent(formatName(petData.name)),
    date: encodeURIComponent(moment(attachmentData.date).format("DD/MM/YYYY")),
  };
}

function formatSendRequest(data) {
  const petData = data.pet;
  const ownerData = data.pet.owner;
  const veterinarian = data.veterinarian;
  const requestData = data.request;

  const requestContent = _.get(requestTemplates, requestData.contentType);

  const store = getStoreName();
  const petArticles = getArticles(petData.petAttributes.gender);
  const requestArticles = getArticles(requestContent.gender);

  return {
    tutorName: encodeURIComponent(formatName(ownerData.name.firstName)),
    veterinaryName: encodeURIComponent(formatName(veterinarian.name)),
    storeName: encodeURIComponent(store.fullName),
    petArticle: encodeURIComponent(petArticles[1]),
    petName: encodeURIComponent(formatName(petData.name)),
    date: encodeURIComponent(moment(requestData.date).format("DD/MM/YYYY")),
    requestName: encodeURIComponent(requestContent.name),
    requestArticle1: encodeURIComponent(requestArticles[0]),
    requestArticle2: encodeURIComponent(requestArticles[2]),
    requestPath: encodeURIComponent(requestData.path),
  };
}

function formatSendPrescription(data) {
  const petData = data.pet;
  const ownerData = data.pet.owner;
  const veterinarian = data.veterinarian;
  const prescriptionData = data.prescription;

  const store = getStoreName();
  const petArticles = getArticles(petData.petAttributes.gender);

  return {
    tutorName: encodeURIComponent(formatName(ownerData.name.firstName)),
    veterinaryName: encodeURIComponent(formatName(veterinarian.name)),
    storeName: encodeURIComponent(store.fullName),
    petArticle: encodeURIComponent(petArticles[1]),
    petName: encodeURIComponent(formatName(petData.name)),
    date: encodeURIComponent(moment(prescriptionData.date).format("DD/MM/YYYY")),
    prescriptionName: encodeURIComponent(prescriptionData.name),
    prescriptionPath: encodeURIComponent(prescriptionData.path),
  };
}

function formatSendCareSummary(data) {
  const care = data.care;
  const petData = data.pet;
  const merchant = data.merchant;
  const ownerData = data.pet.owner;
  const veterinarian = data.veterinarian;

  const store = getStoreName();
  const storeAddress = mountAddress(merchant.location);
  const petArticles = getArticles(petData.petAttributes.gender);


  return {
    tutorName: encodeURIComponent(formatName(ownerData.name.firstName)),
    veterinaryName: encodeURIComponent(formatName(veterinarian.name)),
    storeName: encodeURIComponent(store.fullName),
    storeAddress: encodeURIComponent(storeAddress.full),
    petArticle: encodeURIComponent(petArticles[1]),
    petName: encodeURIComponent(formatName(petData.name)),
    careDate: encodeURIComponent(moment(care.date).format("DD/MM/YYYY")),
    careHour: encodeURIComponent(care.hour),
    veterinaryName: encodeURIComponent(formatName(veterinarian.name)),
  };

}

function formatRegistrationWarnings(pet) {
  const petData = pet;
  const ownerData = pet.owner;
  const petArticles = getArticles(petData.petAttributes.gender);

  return {
    petName: formatName(petData.name),
    petArticles: petArticles,
    customerID: ownerData.id,
  };
}

export const formatTemplateData = {
  sendAttachment: (data) => formatSendAttachment(data),
  sendRequest: (data) => formatSendRequest(data),
  sendPrescription: (data) => formatSendPrescription(data),
  sendCareSummary: (data) => formatSendCareSummary(data),
  registrationWarnings: (data) => formatRegistrationWarnings(data),
};
