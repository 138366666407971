import _ from "lodash";

import { useContext } from "../Context";
import { useListPreview } from "../useListPreview";

import { Info, InfoLine } from "./styles";
import { ItemContent } from "../ListContent/ItemContent";

export function ListPreviewInfoData({ children, ...rest }) {
  const { listPreviewName } = useContext();

  const setPreview = useListPreview((st) => st.actions.setPreview);

  const selected = useListPreview(
    (st) => st.data.lists[listPreviewName].selected
  );

  setPreview(listPreviewName);

  return (
    <Info {...rest}>
      {_.keys(selected?.informationList).map((information) => (
        <InfoLine key={information}>
          <strong>{information}</strong>
          <ItemContent content={selected?.informationList[information]} />
        </InfoLine>
      ))}
    </Info>
  );
}
