import { rgba } from "polished";
import styled from "styled-components";

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
`;

export const Number = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  line-height: 20px;
  transform-origin: cente;
  color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.gray[300]};
  transition: all linear ${(props) => props.theme.animation.delay.fast};
  box-shadow: 1px 1px 5px ${(props) => rgba(props.theme.colors.black, 0.3)};
`;

export const Label = styled.p`
  font-size: 14px;
  max-width: 90px;
  text-align: center;
  margin: 0;
  transform-origin: cente;
  color: ${(props) => props.theme.colors.gray[600]};
  transition: all linear ${(props) => props.theme.animation.delay.fast};
`;

export const Step = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  transition: all linear ${(props) => props.theme.animation.delay.fast};
  min-height: fit-content;
  &::after {
    content: "";
    width: 2px;
    height: 100%;
    flex: 1;
    background: ${(props) => props.theme.colors.gray[200]};
  }

  &.active {
    flex: 1;
    height: 100%;
    gap: 5px;
    ${Number} {
      transform: scale(1);
      background: ${(props) => props.theme.colors.scale[300]};
    }
    ${Label} {
      transform: scale(1);
    }
  }
  &.inactive {
    gap: 0px;
    ${Number} {
      transform: scale(0.6);
      background: ${(props) => props.theme.colors.gray[400]};
    }
    ${Label} {
      transform: scale(0.7);
    }
  }
  &.complete {
    gap: 0px;
    ${Number} {
      cursor: pointer;
      transform: scale(0.6);
      background: ${(props) => props.theme.colors.scale[500]};
    }
    ${Label} {
      transform: scale(0.7);
    }
    &:hover {
      ${Number} {
        box-shadow: 3px 3px 8px
          ${(props) => rgba(props.theme.colors.black, 0.3)};
        transform: scale(0.7);
      }
    }
    &:active {
      ${Number} {
        box-shadow: 0px 0px 4px
          ${(props) => rgba(props.theme.colors.black, 0.3)};
        transform: scale(0.65);
      }
    }
  }
`;

export const StepContentBox = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  position: relative;
  transition: all ease-in-out ${(props) => props.theme.animation.delay.normal};

  &.active {
    left: 0;
    opacity: 1;
  }
  &.inactive {
    left: 100%;
    opacity: 0;
    position: absolute;
    max-height: 100%;
    height: 0;
    overflow: hidden;
  }
`;
