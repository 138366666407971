import { lastVaccineStatus } from '../../../../../store/protocols/protocolFuncions'
import { useProtocols } from '../../../../../store/protocols/useProtocols'
import { useVaccines } from '../../../../../store/protocols/useVaccines'

import {
  Container,
  VaccineName,
  VaccineStatus,
  VaccineDescription,
  VaccineDate,
} from './styles'

export function VaccineListItem({ id, name, description, interrupted }) {
  const {
    active,
    actions: { setActiveProtocol },
  } = useProtocols()

  const { vaccinesList } = useVaccines()

  if (!vaccinesList) return null

  const statusLastVaccine = lastVaccineStatus(vaccinesList, id, interrupted)

  return (
    <Container
      className={active === id ? 'active' : ''}
      onClick={() => {
        setActiveProtocol(id)
      }}
    >
      <div>
        <VaccineName>{name}</VaccineName>
        <VaccineStatus className={statusLastVaccine?.value}>
          {statusLastVaccine?.name}
        </VaccineStatus>
        <VaccineDescription>{description}</VaccineDescription>
        <VaccineDate>{statusLastVaccine?.dateFormated}</VaccineDate>
      </div>
    </Container>
  )
}
