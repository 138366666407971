import { create } from 'zustand'
import { produce } from 'immer'

export const usePopover = create((set) => ({
  data: {
    popovers: [],
    status: [],
  },
  actions: {
    isOpen: (popover) =>
      set((state) =>
        produce(state, (draft) => {
          if (!popover) {
            draft.data.status = state.data.status.map(() => false)
            return
          }

          const { name, open } = popover
          const index = draft.data.popovers.indexOf(name)

          if (!~index) {
            draft.data.popovers.push(name)
            draft.data.status.push(open)
            return
          }

          draft.data.status[index] = popover.open
        }),
      ),
    toogle: (name) =>
      set((state) =>
        produce(state, (draft) => {
          const index = draft.data.popovers.indexOf(name)
          draft.data.status[index] = !draft.data.status[index]
        }),
      ),
    setPopover: (name) =>
      set((state) =>
        produce(state, (draft) => {
          const index = draft.data.popovers.indexOf(name)
          if (!~index) {
            draft.data.popovers.push(name)
            draft.data.status.push(false)
          }
        }),
      ),
  },
}))
