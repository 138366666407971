import { ScheduleController } from "../../../../services/controllers/scheduling";
import { useSchedule } from "../../../../store/schedule/useSchedule";
import { Action } from "../../../ui/Action";
import { Container, Continue } from "./styles";

export function Actions({
  show = ["back", "cancel", "next"],
  back = "Voltar",
  cancel = "Cancelar",
  next = "Próximo",
  backAction = () => null,
  nextAction = () => null,
}) {
  const CLScheduling = ScheduleController();
  const isLoading = useSchedule(st=>st.data.loading);

  return (
    <Container>
      {show.includes("back") && (
        <Action.Root type="button" outline status="danger" onClick={backAction} disabled={isLoading}>
          {back}

        </Action.Root>
      )}
      <Continue>
        {show.includes("cancel") && (
          <Action.Root
            type="button"
            status="danger"
            onClick={() => CLScheduling.drawerClose()}
            disabled={isLoading}
          >
            {cancel}
          </Action.Root>
        )}
        {show.includes("next") && (
          <Action.Root onClick={nextAction} disabled={isLoading}>
            {isLoading && <Action.Icon isLoading />}
            {next}
          </Action.Root>
        )}
      </Continue>
    </Container>
  );
}
