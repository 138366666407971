
import { twMerge } from 'tailwind-merge'

export function CardHeader({ className, ...rest }) {
  return (
    <div
      className={twMerge('flex w-full flex-col', className)}
      {...rest}
    />
  )
}
