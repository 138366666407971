import { Children, cloneElement } from 'react'

import { StepsStep } from './StepsStep'
import { Container } from './styles'
import { useSteps } from './useSteps'

export function StepsRoot({ children, name, steps }) {
  const setSteps = useSteps((st) => st.actions.setSteps)

  setSteps(name, steps)

  const allStepsList = useSteps((st) => st.data.steps)
  const stepsList = allStepsList[name]

  const childrenProps = stepsList.map((step) => (
    <StepsStep key={step.id} status={step.status} name={name}>
      {Children.map(children, (child) => cloneElement(child, { step, name }))}
    </StepsStep>
  ))

  return <Container>{childrenProps}</Container>
}
