import { View } from '@react-pdf/renderer';

import { container } from './styles';

export function FooterRoot({ children }) {
  return (
    <View style={container} fixed>
      {children}
    </View>
  );
}
