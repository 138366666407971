import { useSchedule } from "../../../../store/schedule/useSchedule";

import { PetCard } from "../../../ui/PetCard";
import { PetInformations, SelectedPetInformations } from "./styles";

export function SelectedPetCard() {
  const pet = useSchedule((st) => st.data.pet);

  return (
    <SelectedPetInformations>
      <PetInformations>
        <PetCard.Root
          noAction
          size="small"
          direction="column"
          style={{
            maxWidth: "fit-content",
            minWidth: "initial",
          }}
        >
          <PetCard.Attributes full attributes={pet?.attributes} show={["Nome"]} />
          <PetCard.Avatar url={pet?.photo} attributes={pet?.attributes} />
        </PetCard.Root>
        <PetCard.Root
          data-full
          noAction
          direction="row"
          size="small"
          title="Informações"
          style={{
            minWidth: "400px",
          }}
        >
          <PetCard.Badges show={pet?.badges} absolute />
          <PetCard.Attributes
            attributes={pet?.attributes}
            show={[
              "Raça",
              "PetPeso",
              "Castrado",
              "Sexo",
              "Nascimento",
              "Idade",
            ]}
          />
        </PetCard.Root>
      </PetInformations>

      <PetCard.Root data-full noAction size="small" title="Tutor">
        <PetCard.Attributes
          direction="column"
          attributes={pet?.attributes}
          show={["Tutor", "TutorCpf", "TutorPhone"]}
        />
      </PetCard.Root>
    </SelectedPetInformations>
  );
}
