import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TipContent = styled.div`
  position: relative;
  color: ${(props) => props.theme.colors.warning};
  font-size: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  &:hover {
    cursor: help;
  }
`;

export const TipMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  padding: 12px;
  width: 400px;
  color: ${(props) => props.theme.colors.gray[800]};
`;
