export const PartialDoctorSignature = {
  name: "Assinatura do(a) Médico(a) Veterinário(a)",
  gender: "female",
  content: `
    <div class="center column">
      <span class="line"></span>
      <p>Assinatura do(a) Médico(a) Veterinário(a)</p>
    </div>
  `.trim(),
};
