import { Children, cloneElement } from "react";
import cn from "classnames";

import { useDrawer } from "./useDrawer";
import { Resizable } from "./styles";

export function DrawerRoot({ children, direction, name, colors, ...rest }) {

  const { setDrawer } = useDrawer((st) => st.actions);

  setDrawer(name, {
    direction,
    colors,
  });

  const drawer = useDrawer((st) => st.data.drawers[name]);

  const childrenProps = Children.map(children, (child) =>
    cloneElement(child, { name })
  );

  return (
    <Resizable
      {...rest}
      resizable={drawer.status}
      direction={drawer.direction}
      className={cn({ open: drawer.open }, drawer.direction)}
    >
      {childrenProps}
    </Resizable>
  );
}
