import { TimeLineItem as Container, TimeLineTitle } from "./styles";

export function TimeLineItem({ children, title }) {
  return (
    <Container>
      <TimeLineTitle>{title}</TimeLineTitle>
      {children}
    </Container>
  );
}
