import { toast } from "react-toastify";

import api from "../../api";

export default class VeterinaryVaccineModel {

  constructor(orderId) {
    this._orderId = orderId;
  }

  sendVaccineAction = async (vaccineId, body) => {
    if (typeof body === "string") body = { action: body };

    const bodyData = body?.data?{...body?.data,appointmentOrderId:this._orderId}:{appointmentOrderId:this._orderId}

    try {
      const { data } = await api.put(`vaccination/${vaccineId}/action`, {
        ...body,
        data: bodyData
      });

      return data;
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
