import { rgba } from "polished";
import styled from "styled-components";

export const Container = styled.aside`
  grid-area: preview;

  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 30px 0;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    height: 90%;
    width: 1px;
    transform: translateY(-50%);
    background: linear-gradient(
      transparent,
      ${(props) => props.theme.colors.scale[400]},
      transparent
    );
    transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;
  }
  & > * {
    min-width: 448px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  width: 100%;
  padding: 0 24px;
`;

export const HeaderTitle = styled.h4`
  font-size: 20px;
  margin: 0;
  color: ${(props) => props.theme.colors.scale[700]};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 12px;
  margin-bottom: 12px;
  width: 100%;
  padding: 0 24px;
`;

export const InfoContent = styled.div`
  border-radius: 10px;
  overflow: hidden;
  width: calc(100% - 48px);
  margin: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  border: 1px solid ${(props) => props.theme.colors.scale[300]};
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px 5px;
`;

export const Info = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 6px;
  width: 100%;
  padding: 0 24px;
`;

export const InfoLine = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  strong {
    color: ${(props) => props.theme.colors.gray[300]};
    font-size: 14px;
  }
  span {
    color: ${(props) => props.theme.colors.gray[700]};
    font-size: 16px;
    font-weight: bold;
  }
`;
