import { certificateDeath } from "./certificate/death";
import { certificateHealth } from "./certificate/health";
import { certificateVaccination } from "./certificate/vaccination";
import { declarationProcedureNoAuthorization } from "./declaration/procedureNoAuthorization";
import { termAdmissionConsent } from "./term/admissionConsent";
import { termBodyDonationConsent } from "./term/bodyDonationConsent";
import { termEndoscopyAuthorization } from "./term/endoscopyAuthorization";
import { termEuthanasiaConsent } from "./term/euthanasiaConsent";
import { termRemovalDeadBodyConsent } from "./term/removalDeadBodyConsent";

export const requestTemplates = {
  attestation: {
    death: certificateDeath,
    health: certificateHealth,
    vaccination: certificateVaccination,
  },
  declaration: {
    procedureNoAuthorization: declarationProcedureNoAuthorization,
  },

  term: {
    endoscopyAuthorization: termEndoscopyAuthorization,
    admissionConsent: termAdmissionConsent,
    euthanasiaConsent: termEuthanasiaConsent,
    bodyDonationConsent: termBodyDonationConsent,
    removalDeadBodyConsent: termRemovalDeadBodyConsent,
  },
};
