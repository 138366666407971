import { useAssessments } from '../../../hooks/AssessmentsContext'
import { Container, Item, Option, Select } from './styles'

export function Filter() {
  const { getFilter, updateFilter } = useAssessments()
  const { period } = getFilter()

  return (
    <Container item xs={12}>
      <Item xs={2}>
        <Select
          fullWidth
          select
          label="PERÍODO"
          variant="outlined"
          value={period}
          onChange={(e) => {
            updateFilter({ period: e.target.value })
          }}
        >
          <Option value="all-period">TODO PERÍODO</Option>
          <Option value="last-90-days">ÚLTIMOS 90 DIAS</Option>
          <Option value="last-30-days">ÚLTIMOS 30 DIAS</Option>
          <Option value="last-7-days">ÚLTIMOS 7 DIAS</Option>
        </Select>
      </Item>
    </Container>
  )
}
