import { rgba } from "polished";
import styled, { keyframes } from "styled-components";

const showThumb = keyframes`
  to{
    opacity: 1;
    filter: blur(0px);
  }
`
export const Container = styled.main`
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  padding: 12px 12px 12px 22px;
  height: fit-content;
  width: 100%;
  gap: 12px;

  &:has(button:hover),
  &:has(button[data-selected="true"]) {
    & > button {
      opacity: 0.5;
      filter: grayscale(.7) blur(3px);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 8px 0 8px;
  position: relative;
  height: 450px;
  overflow: hidden;
  border-radius: 8px;
  background: url('/img/Dra.Mei/logo-dark.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  border: 1px solid ${(props) => rgba(props.theme.colors.gray[300], .4)};
    box-shadow: ${(props) => rgba(props.theme.colors.black, 0.05)} 5px 5px 5px;
`;

export const Image = styled.img`
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  filter: blur(100px);
  background: ${(props) => props.theme.colors.background};
  animation: ${showThumb} ${(props) => props.theme.animation.delay.slow} forwards;
`;


export const Action = styled.div`
  display: flex;
  padding-right: 12px;
  position: absolute;
  z-index: 3;
  top: 28px;
  right: 8px;
`;

export const InformationBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  width: 100%;
`;

export const Title = styled.span`
  display: flex;
  max-width: 100%;
  height: fit-content;
  font-size: 20px;
  padding: 0 12px;
  margin: 0;
  font-weight: bold;
  color: ${(props) => props.theme.colors.scale[700]};
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
  width: 100%;
`;

export const BulletMenu = styled.span`
  position: relative;
  border-radius: 100px;
  width: 6px;
  height: 6px;
  color: ${(props) => props.theme.colors.scale[200]};
  background: currentColor;
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  transform-origin: right;
  &::before {
    content: "";
    right: 0;
    position: absolute;
    top: calc(100% + 5px);
    border-radius: 100px;
    width: 6px;
    height: 6px;
    background: currentColor;
    transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  }
  &::after {
    content: "";
    right: 0;
    position: absolute;
    bottom: calc(100% + 5px);
    border-radius: 100px;
    width: 6px;
    height: 6px;
    background: currentColor;
    transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  }
`;

export const Item = styled.button`
  all: unset;
  padding: 0;
  padding-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: space-between;
  gap: 12px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.scale[300]};
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.1)} 5px 5px 10px;
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  opacity: 0.5;
  &:hover {
    z-index: 1;
    opacity: 1 !important;
    transform: scale(1.05) !important;
    filter: grayscale(0) !important;
    ${BulletMenu} {
      width: 12px;
      &::before {
        width: 18px;
      }
    }
  }
  &[data-selected="true"] {
    opacity: 1 !important;
    filter: grayscale(0) !important;
    ${BulletMenu} {
      width: 12px;
      &::before {
        width: 18px;
      }
    }
  }
`;
