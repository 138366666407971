import React from "react";
import moment from "moment";
import { FileDown, Filter as FilterIcon } from "lucide-react";

import { InvoiceController } from "../../../../services/controllers/subscription";

import { useInvoice } from "../../../../store/subscription/useInvoice";

import { Input } from "../../../../components/ui/Form/Input";
import { Select } from "../../../../components/ui/Form/Select";
import { Filter } from "../../../../components/ui/Filter";

export function ExtratoFilter() {
  const CInvoice = InvoiceController();

  const filter = useInvoice((st) => st.data.filter);
  const isLoading = useInvoice((st) => st.data.isLoading);

  return (
    <Filter.Root onSubmit={CInvoice.filterInvoices}>
      <Filter.Container>
        <Input.Root
          label="Início"
          pickDate
          style={{ maxWidth: "300px" }}
          value={moment(filter.dateFrom).format("DD/MM/YYYY")}
          onChange={(dateFrom) => {
            CInvoice.setFilter({ dateFrom });
          }}
        />
        <Input.Root
          label="Fim"
          pickDate
          style={{ maxWidth: "300px" }}
          value={moment(filter.dateTo).format("DD/MM/YYYY")}
          onChange={(dateTo) => {
            CInvoice.setFilter({ dateTo });
          }}
        />
        <Select.Root
          label="Tipo"
          value={filter.type}
          style={{ maxWidth: "160px" }}
          onChange={(type) => {
            CInvoice.setFilter({ type });
          }}
        >
          <Select.Option value={"Todos"}>Todos</Select.Option>
          <Select.Option value={"Mensalidade"}>Mensalidade</Select.Option>
          <Select.Option value={"Estorno"}>Estorno</Select.Option>
        </Select.Root>
        <Input.Root
          label="CPF"
          mask="999.999.999-99"
          style={{ maxWidth: "200px" }}
          value={filter.cpf}
          onChange={(cpf) => {
            CInvoice.setFilter({ cpf });
          }}
        />
      </Filter.Container>
      <Filter.Actions>
        <Filter.Action isLoading={isLoading}>
          <Filter.ActionIcon icon={FilterIcon} isLoading={isLoading} />
          Filtrar
        </Filter.Action>
        <Filter.Action outline type="button" onClick={CInvoice.reportExport}>
          <Filter.ActionIcon icon={FileDown} />
          Exportar
        </Filter.Action>
      </Filter.Actions>
    </Filter.Root>
  );
}
