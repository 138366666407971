import { formatCellPhoneNumber, formatCpf, formatName } from '../../../../../../services/functions'
import { usePrinter } from '../../../../../../store/schedule/usePrinter'
import { Card } from '../components/Card'
import { IdentificationBadge, Phone } from '@phosphor-icons/react'

export function Customer() {
  const customer = usePrinter(st => st.data.customer)
  return (
    <>
      <Card.Content>
        <Card.ContentTitle>{formatName(customer.name)}</Card.ContentTitle>
        <Card.ContentList>
          <Card.ContentListItem>
            <IdentificationBadge className="h-4 w-4" weight="bold" />
            <span className="leading-none">{formatCpf(customer.cpf)}</span>
          </Card.ContentListItem>
          <Card.ContentListItem>
            <Phone className="h-4 w-4" weight="bold" />
            <span className="leading-none">{formatCellPhoneNumber(customer.phoneNumber)}</span>
          </Card.ContentListItem>
        </Card.ContentList>
      </Card.Content>
      <Card.Separator className="bg-black" />
    </>
  )
}
