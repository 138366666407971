import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px 10px;
  & > button {
    max-width: fit-content;
  }
`

export const FormContent = styled.div`
  min-width: 100%;
  flex: 0 1 0%;
  flex-wrap: wrap;
  display: flex;
  gap: 20px 10px;

  align-items: flex-start;
  justify-content: flex-start;
`
