import { Buttons, Container, Label } from "./styles";

export function ToggleGroupRoot({
  children,
  label = false,
  type = "single",
  ...rest
}) {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Buttons {...rest} type={type}>
        {children}
      </Buttons>
    </Container>
  );
}
