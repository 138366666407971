import { Check } from 'lucide-react'

import { formatMoney } from "../../../../../../services/functions";

import { useSubscribers } from '../../../../../../store/subscription/useSubscribers';

import { Action } from "../../../../../../components/ui/Action";

export function ChangePlanActionsCurrent({plan}){
  const isCanceled = useSubscribers(st => st.data.details.isCanceled)

  return (
    <Action.Root
      full
      outline
      noHover
      as='span'
      status="success"
      disabled={isCanceled}
      title={`R$ ${formatMoney(plan.price)}`}
      name={`change-plan-${plan.id}`}
      style={{ height: '42px' }}
    >
      <Action.Icon icon={Check} size={18} />
      <strong>{plan.description}</strong>
    </Action.Root>
  )
}
