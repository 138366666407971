import colors from'tailwindcss/colors'

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid
} from 'recharts';
import { BarChart2 } from "lucide-react";
import { useIndicators } from '../../../../store/subscription/useIndicators';
import _ from 'lodash';
import { formatMoney } from '../../../../services/functions';

export function Frequency(){
  const frequencies = useIndicators(st => st.data.frequencies)


  const [plans, others] = _.partition(frequencies, item =>
    ['Ilimitado', 'Básico', 'Essencial'].includes(item.grupo)
  );

  const packages = _.find(others, { grupo: 'Pacote' });
  const singles = _.find(others, { grupo: 'Avulso' });


  const frequenciesData = _.reduce(frequencies, (acc, value) => {
    acc[value.grupo] = value.frequencia;
    return acc;
  }, {name: 'Frequência'});

  const totalFrequencyPlans = _.sumBy(plans, 'frequencia');

  const packagesFrequency =  packages?.frequencia || 1
  const singleFrequency =  singles?.frequencia || 1

  const percentageRelationToPackage = ((totalFrequencyPlans/3) / packagesFrequency) * 100
  const percentageRelationToSingle = ((totalFrequencyPlans/3) / singleFrequency) * 100


  return (
      <div className="border rounded-md overflow-hidden flex flex-col flex-[2] w-full min-w-[350px] shadow-md space-y-4 h-full py-2 px-4">
        <div className="flex flex-col gap-4 w-full px-4 pt-2">
          <h4 className="text-xl text-scale-500 w-full font-bold flex items-center"><BarChart2 className="mr-2 w-6 h-6" />Frequência</h4>
          <p className="flex flex-col w-full">
            <span className="text-base text-zinc-400">Aumento em {formatMoney(percentageRelationToPackage,0)}% em relação a frequência média de pacotes e {formatMoney(percentageRelationToSingle,0)}% em relação a um cliente avulso.</span>
          </p>
        </div>
        <div className="flex w-full h-[300px]">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={[frequenciesData]} style={{ fontSize:12 }}>
              <XAxis dataKey="name" stroke='#888' axisLine={false} tickLine={false}  />
              <YAxis stroke='#888' axisLine={false} tickLine={false} />
              <Tooltip />
              <Legend verticalAlign="top" height={36}/>
              <CartesianGrid vertical={false} className='stroke-zinc-300/50'/>
              <Bar dataKey="Ilimitado" fill={colors.orange[500]} barSize={30} />
              <Bar dataKey="Essencial" fill={colors.blue[500]} barSize={30} />
              <Bar dataKey="Básico" fill={colors.purple[500]} barSize={30} />
              <Bar dataKey="Avulso" fill={colors.zinc[500]} barSize={30} />
              <Bar dataKey="Pacote" fill={colors.pink[500]} barSize={30} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
  )
}
