import { create } from "zustand";
import { produce } from "immer";
import _ from "lodash";

const defaultData = {
  isLoading: false,
  subscriberEstimate: 0,
  results: false,
};

export const useCalculator = create((set) => ({
  data: defaultData,
  actions: {
    setLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isLoading = status;
        })
      ),
    setSubscriberEstimate: (subscriber) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.subscriberEstimate = subscriber;
        })
      ),
    setResult: (results) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.results = results
        })
      ),
  },
}));
