import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import Option from './Option'
import styles from './styles'
import { useSchedule } from '../../../hooks/ScheduleContext'

function CalendarOptions({
  hiddenScheduleType = false,
  hiddenProfessionalFilter = false,
  hiddenScheduleView = false,
  hiddenStatusFilter = false,
  useContext,
  closeOptionsOpen,
}) {
  const { updateFilter } = useSchedule()
  const filerOptions = JSON.parse(localStorage.getItem('@PETLAND/filerOptions'))

  const optionsState = useState({
    agenda: filerOptions ? filerOptions.agenda : 'agenda',
    profissionais: filerOptions ? filerOptions.profissionais : 'false',
    agendamentos: filerOptions ? filerOptions.agendamentos : 'false',
    status: filerOptions ? filerOptions.status : '*',
  })

  const [options] = optionsState

  const { grid, footer, cancel, save } = styles()

  const setFilter = () => {
    if (useContext) {
      updateFilter({
        employeesWorkingOnly: options.profissionais,
        statusFilter: options.status,
        visualByAppointments: options.agendamentos,
      })
    }
    localStorage.setItem('@PETLAND/filerOptions', JSON.stringify(options))
    closeOptionsOpen()
  }

  return (
    <Grid container spacing={3} className={grid}>
      <Grid item xs={12} spacing={3} style={{ padding: '40px' }}>
        {!hiddenScheduleType && (
          <Option
            title="Visualização da agenda"
            optionsState={optionsState}
            name="agenda"
            option={[
              {
                item: 'Calendário',
                value: 'agenda',
              },
              {
                item: 'Lista',
                value: 'vizuAgenda',
              },
            ]}
          />
        )}
        {!hiddenProfessionalFilter && (
          <Option
            title="Filtro de profissionais"
            optionsState={optionsState}
            name="profissionais"
            option={[
              {
                item: 'Profissionais trabalhando no dia',
                value: 'true',
              },
              {
                item: 'Todos os profissionais',
                value: 'false',
              },
            ]}
          />
        )}
        {!hiddenScheduleView && (
          <Option
            title="Visualização dos agendamentos"
            optionsState={optionsState}
            name="agendamentos"
            option={[
              {
                item: 'Visualização por agendamentos',
                value: 'true',
              },
              {
                item: 'Visualização por serviços',
                value: 'false',
              },
            ]}
          />
        )}
        {!hiddenStatusFilter && (
          <Option
            title="Filtro de status"
            optionsState={optionsState}
            name="status"
            option={[
              {
                item: 'Todos os status',
                value: '*',
              },
              {
                item: 'Agendado',
                value: 'Agendado',
              },
              {
                item: 'Em andamento',
                value: 'Em andamento',
              },
              {
                item: 'Finalizado',
                value: 'Finalizado',
              },
            ]}
          />
        )}
      </Grid>
      <div className={footer}>
        <button
          className={cancel}
          onClick={() => {
            closeOptionsOpen()
          }}
        >
          Cancelar
        </button>
        <button
          className={save}
          onClick={() => {
            setFilter()
          }}
        >
          Salvar
        </button>
      </div>
    </Grid>
  )
}

export default CalendarOptions
