import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  align-self: flex-end;
  padding: 20px;
  width: 100%;
`
export const ActionButton = styled.button`
  border-radius: 5px;
  border: none;
  color: #ffffff;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 15px;
  color: #fdfcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  &.whatsapp {
    background: #1bd741;
    &:hover {
      background: #30c04d;
    }
  }
  &.other-channels {
    background: #0090b8;
    &:hover {
      background: #1385a4;
    }
  }
`

export const ActionCheck = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
`
