import { Columns } from "./Columns";

export function Line({ title, ...rest }){
  return(
    <div className="border-b border-gray-300 py-2 px-4 last:border-0 flex items-center flex-1 hover:bg-scale-500/10 hover:text-scale-700">
      <p className="text-base tracking-wide flex items-center mr-2 min-w-[250px] flex-1">{title}</p>
      <Columns {...rest} />
    </div>
  )
}
