import {  rgba } from 'polished'
import styled from 'styled-components'

export const SummaryTitle = styled.h3`
    margin: 0;
    color: ${(props) => props.theme.colors.scale[700]};
`


export const SummaryServicesTable = styled.div`
    margin-top: 4px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.colors.scale[300]};
`

export const SummaryServicesTableHeadLine = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: ${(props) => props.theme.colors.scale[500]};
  padding: 4px 2px;
  font-size: 14px;
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[300]};
`

export const SummaryServicesTableLine = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 14px;
  padding: 6px 8px;
  color: ${(props) => props.theme.colors.gray[800]};
  &:nth-child(odd){
    background: ${(props) => rgba(props.theme.colors.scale[100], .3)};
  }
`
export const SummaryServicesTablBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`
