import { PawPrint } from "@phosphor-icons/react";
import { getMiddleArray } from "../../../services/functions";
import { PaginationPageBox as Container, PaginationPage } from "./styles";
import { usePagination } from "./usePagination";
import { usePaginationContext } from "./PaginationContext";

export function PaginationPages() {
  const { paginationName } = usePaginationContext();
  const setCurrentPage = usePagination((st) => st.actions.setCurrentPage);

  const pagination = usePagination((st) => st.data.pagination[paginationName]);

  if (!pagination) return null;

  const pageList = getMiddleArray(
    pagination.totalPages,
    pagination.currentPage,
    pagination.show
  );

  return (
    <Container>
      {!pageList && (
        <PaginationPage>
          <PawPrint size={20} />
        </PaginationPage>
      )}
      {!!pageList &&
        pageList.map((page) => (
          <PaginationPage
            key={page}
            onClick={() => {
              setCurrentPage(paginationName, page);
            }}
            active={page === pagination.currentPage}
            outline
          >
            {page + 1}
          </PaginationPage>
        ))}
    </Container>
  );
}
