import { StyleSheet, } from "@react-pdf/renderer";
import { getCurrentThemeStyle } from "../../../../services/functions";

const theme = getCurrentThemeStyle()

export const container = StyleSheet.create({
  display: 'flex',
  justifyContent: "flex-start",
  alignItems: "flex-start",
  alignContent: 'stretch',
  flexDirection: 'row',
  gap: "8px",
  width: "100%",
})

export const bubble = StyleSheet.create({
  display: "flex",
  flex: "1",
  position: 'relative',
  flexDirection: 'column',
  justifyContent: "start",
  alignItems: "center",
  width: "100%",
  height: "100%",
  gap: "6px 16px",
  maxWidth: "100%",
  backgroundColor: theme.colors.background,
  border: `1px solid ${theme.colors.gray[50]}`,
  borderRadius: "5px",
  padding: "8px 10px",
  fontSize: "16px"
})

export const title = StyleSheet.create({
  display: 'flex',
  position: 'absolute',
  top: '-6px',
  left: '10px',
  merging: '0 4px',
  color: theme.colors.gray[600]
})

export const titleBg = StyleSheet.create({
  position: 'absolute',
  height: '20px',
  width: '110%',
  top: '5px',
  left: '-5%',
  display: 'flex',
  borderTop: `1px solid ${theme.colors.background}`,
  backgroundColor: theme.colors.background,
})

export const highlight = StyleSheet.create({
  fontSize: '8px',
  fontFamily: "Helvetica-Bold",
  color: theme.colors.scale[500]
})

export const attributes = StyleSheet.create({
  width: "100%",
  display: 'flex',
  flexWrap: "wrap",
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: "row",
  gap: '6px 8px'
})

export const attributesLine = StyleSheet.create({
  width: "100%",
  display: 'flex',
  flexWrap: "wrap",
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: "row",
  gap: '6px 2px'
})

export const attribute = StyleSheet.create({
  fontSize: '8px',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '2px',
  color: theme.colors.gray[800],
})

export const attributeAddress = StyleSheet.create({
  fontSize: '8px',
  display: 'flex',
  with: '100%',
  flex: 1,
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: '2px',
  color: theme.colors.gray[800],
})

export const addressLine = StyleSheet.create({
  fontSize: '8px',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0px',
  color: theme.colors.gray[800],
})



export const attributeIcon = StyleSheet.create({
  width: '10px',
  height: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fill: theme.colors.scale[800],
  color: theme.colors.scale[800]
})

