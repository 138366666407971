import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const declarationProcedureNoAuthorization = {
  name: "Declaração de não autorização de procedimento",
  title: "Termo de Consentimento Livre e Esclarecido para Realização de Exames",
  gender: "female",
  fields: {
    procedure: {
      label: "Procedimento",
      type: "text",
      required: true,
      defaultPreview: _.range(10).fill("_").join(""),
    },
    details: {
      label: "Resenha detalhada",
      type: "textarea",
      required: true,
      defaultPreview: _.range(50).fill("_").join(""),
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
  },
  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },
  content: `
    <p>Declaro que estou ciente de que a não realização do procedimento cirúrgico/exame <span>{{procedure}}</span> poderá prejudicar o diagnóstico e a indicação do correto tratamento do meu animal pelo médico-veterinário, sendo de minha total responsabilidade as consequências dessa decisão, não havendo o que possa reclamar em qualquer oportunidade.</p>
    <hr />
    <br />
    <p><strong>Resenha detalhada:</strong></p>
    <p>{{{details}}}</p>
    <br />
    <p>Declaro, ainda, ter sido esclarecido(a) acerca dos possíveis riscos inerentes a não realização do procedimento.</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}
  `.trim(),
};
