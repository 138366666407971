import React from 'react'
import { Controller } from 'react-hook-form'
import moment from 'moment'

// material ui
import { MenuItem, IconButton, TextField, Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteForever from '@material-ui/icons/DeleteForever'
import { MockAvailablesTimes } from '../../atoms/AvailableTimes/mock'
import { getVets } from '../../../services/vets'

moment.locale('pt-BR')

function Scheduling({
  time,
  services,
  availableTimes,
  professional,
  setSchedulings,
  schedulings,
  register,
  control,
  item,
  index,
  customer,
  serviceDuration,
  serviceId,
  segment,
  setVet,
  setVets,
  vets,
}) {
  const mockAvailablesTimes = MockAvailablesTimes?.availableTimes
  const handleDuration = (value, id) => {
    const newSchedulings = schedulings.map((item) => {
      if (item.id === id) {
        return { ...item, duration: value }
      }
      return item
    })
    setSchedulings(newSchedulings)
  }

  React.useEffect(() => {
    handleDuration(serviceDuration, serviceId)
  }, [serviceDuration])

  const handleTime = (value, id) => {
    const newSchedulings = schedulings.map((item) => {
      if (item.id === id) {
        return { ...item, time: value, start: moment(value, 'HH:mm').toDate() }
      }
      return item
    })

    setSchedulings(newSchedulings)
  }

  const handleService = (value, id) => {
    const filterService = services.filter((item) => item.id === value)
    const newSchedulings = schedulings.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          service: value,
          duration: filterService[0].defaultDurationInMinutes,
        }
      }

      return item
    })
    setSchedulings(newSchedulings)
  }

  const handleDelete = (val) => {
    const newSchedulings = schedulings.filter((value) => value.id !== val)
    setSchedulings(newSchedulings)
  }

  const handleVets = async () => {
    const result = await getVets()
    setVets(result)
  }

  const handleVet = (event, value) => {
    setVet(value?.id)
  }

  React.useEffect(() => {

    const intiVets = async () => {
      const result = await getVets()
      setVets(result)
    }

    if (segment === 'clinicaveterinaria') intiVets()
  }, [])

  return (
    <>
      {availableTimes && time && (
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12} key={item.id} spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name={`scheduling[${index}].service`}
                as={({ onChange, value }) => {
                  return (
                    <TextField
                      fullWidth
                      select
                      disabled={!customer}
                      value={value}
                      label="Serviço"
                      variant="outlined"
                      onChange={(e) => {
                        handleService(e.target.value, item.id)
                        onChange(e.target.value)
                      }}
                    >
                      {services
                        .filter(({ status }) => status === 'ATIVO')
                        .map((service) => (
                          <MenuItem key={service.id} value={service.id}>
                            {service.internalCode &&
                              `${service.internalCode} - `}
                            {service.name}
                            {service.price && ` - R$ ${service.price}`}
                          </MenuItem>
                        ))}
                    </TextField>
                  )
                }}
              />
            </Grid>

            {segment === 'banhoetosa' ? (
              <>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    disabled={!customer}
                    name={`scheduling[${index}].professional`}
                    placeholder="Profissional"
                    value={professional?.resourceTitle}
                    inputRef={register}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`scheduling[${index}].time`}
                    as={({ onChange }) => (
                      <TextField
                        fullWidth
                        select
                        defaultValue={time}
                        disabled={!customer}
                        label="HORA DE INICIO"
                        variant="outlined"
                        onChange={(e) => {
                          handleTime(e.target.value, item.id)
                          onChange(e.target.value)
                        }}
                      >
                        {availableTimes
                          // .filter((time) => time.available)
                          .map((option) => (
                            <MenuItem key={option?.time} value={option?.time}>
                              {option.time}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>

                {serviceDuration && (
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name={`scheduling[${index}].duration`}
                      as={({ onChange }) => {
                        return (
                          <TextField
                            fullWidth
                            select
                            defaultValue={serviceDuration}
                            disabled={!customer}
                            label="DURAÇÃO"
                            variant="outlined"
                            onChange={(e) => {
                              handleDuration(e.target.value, item.id)
                              onChange(e.target.value)
                            }}
                          >
                            {services[0]?.durationOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option} min
                              </MenuItem>
                            ))}
                          </TextField>
                        )
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={1}>
                  {index > 0 && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      disabled={!customer}
                      onClick={() => handleDelete(item.id)}
                    >
                      <DeleteForever />
                    </IconButton>
                  )}
                </Grid>
              </>
            ) : (
              <>
                {/* CAMPOS DA CLINICA */}
                <Grid item xs={6}>
                  <Autocomplete
                    disabled={!customer}
                    required
                    options={vets}
                    loading
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        style={{ margin: '0px' }}
                        {...params}
                        label="Profissional"
                        placeholder="Digite o nome do profissional"
                        margin="normal"
                        variant="outlined"
                        onChange={handleVets}
                      />
                    )}
                    onChange={handleVet}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    defaultValue={time.toString()}
                    name={`scheduling[${index}].time`}
                    as={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        select
                        value={value}
                        disabled={!customer}
                        label="HORA DE INICIO"
                        variant="outlined"
                        defaultChecked={time.toString()}
                        onChange={(e) => {
                          handleTime(e.target.value, item.id)
                          onChange(e.target.value)
                        }}
                      >
                        {mockAvailablesTimes
                          // .filter((time) => time.available)
                          .map((option) => (
                            <MenuItem
                              key={option?.time}
                              value={option?.time}
                              selected={option?.time === time.toString()}
                            >
                              {option.time}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>

                {serviceDuration && (
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      defaultValue={serviceDuration}
                      name={`scheduling[${index}].duration`}
                      as={({ onChange }) => (
                        <TextField
                          fullWidth
                          select
                          defaultValue={serviceDuration}
                          disabled={!customer}
                          label={`DURAÇÃO-${serviceDuration}`}
                          variant="outlined"
                          onChange={(e) => {
                            handleDuration(e.target.value, item.id)
                            onChange(e.target.value)
                          }}
                        >
                          {services[0]?.durationOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option} min
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              {' '}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Scheduling
