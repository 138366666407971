import { useRef, useState } from 'react'
import { useCrm } from '../../../../hooks/CrmContext'
import {
  Container,
  Label,
  TextArea,
  ActionBox,
  ActionButton,
  ObsBox,
} from './styles'

export function Obs({ petId }) {
  const obsRef = useRef(null)
  const [open, setOpen] = useState(false)
  const { sendEvent, updateActivities } = useCrm()

  const sendObs = async () => {
    const comment = obsRef.current.value
    if (comment.replaceAll(/\s/g, '') === '') return

    await sendEvent({
      eventName: 'comment-card',
      data: { comment, petId },
    })
    updateActivities()
    obsRef.current.value = ''
    setOpen(false)
  }

  return (
    <Container>
      <Label>Observações:</Label>
      <ObsBox className={open ? 'active' : ''}>
        <TextArea
          ref={obsRef}
          onFocus={() => {
            setOpen(true)
          }}
          onBlur={(e) => {
            setOpen(e.target.value !== '')
          }}
        />
        <ActionBox className={open ? 'active' : ''}>
          <ActionButton onClick={sendObs}>Salvar </ActionButton>
        </ActionBox>
      </ObsBox>
    </Container>
  )
}
