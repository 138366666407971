import { toast } from "react-toastify";

import { useSchedule } from "../../../store/schedule/useSchedule";

import { HmsToseconds } from "../../functions";
import { ScheduleModel } from "../../models";

export default class ProcedureController {
  constructor() {
    this.MSchedule = ScheduleModel();
  }

  setProcedure = (id) => {
    const { procedures } = useSchedule.getState().data.customer;
    const { procedure } = useSchedule.getState().data;
    if (!procedures.length) return;

    const data = procedures.find((procedure) => procedure.id === id);

    const duration = data.defaultDurationInMinutes.toString() || null;
    const durationInSeconds = data?.defaultDurationInMinutes * 60 || 3600;
    const startsAt = HmsToseconds(procedure.time);
    const finishesAt = startsAt + durationInSeconds;

    this.MSchedule.setProcedure({
      ...procedure,
      data,
      duration,
      startsAt,
      finishesAt,
    });
  };

  setDuration = (duration) => {
    const { procedure } = useSchedule.getState().data;

    const startsAt = HmsToseconds(procedure.time);
    const durationInSeconds = duration * 60;
    const finishesAt = startsAt + durationInSeconds;

    this.MSchedule.setProcedure({
      ...procedure,
      duration,
      startsAt,
      finishesAt,
    });
  };

  setDate = (date) => {
    const { procedure } = useSchedule.getState().data;
    this.MSchedule.setProcedure({ ...procedure, date });
  };

  setTime = (time) => {
    const { procedure } = useSchedule.getState().data;

    this.MSchedule.setProcedure({ ...procedure, time });
  };

  setServices = (services) => {
    const { procedure } = useSchedule.getState().data;

    this.MSchedule.setProcedure({ ...procedure, services });
  };

  addNewService = (id) => {
    const { procedure } = useSchedule.getState().data;
    const allServices = useSchedule.getState().data.customer.services;

    const service = allServices.find((service) => service.serviceId === id);

    const services = [...procedure.services, service];
    this.MSchedule.updateServices(services);
  };

  removeService = (id) => {
    const { procedure } = useSchedule.getState().data;
    const services = procedure.services.filter(
      (service) => id !== service.serviceId
    );

    this.MSchedule.updateServices(services);
  };

  updateService = (id, data) => {
    const { procedure } = useSchedule.getState().data;
    const services = procedure.services.map((service) => {
      if (id !== service.serviceId) return service;
      return {
        ...service,
        ...data,
      };
    });

    this.MSchedule.setProcedure({ ...procedure, services });
  };

  setEmployee = (id) => {
    if (!id) return;

    const { employees } = useSchedule.getState().data.customer;

    if (!employees.length) {
      throw new Error("ProcedureController: List of professionals is empty!");
    }

    const employee = employees.find((employee) => employee.id === id);
    if (!employee) {
      toast.error("Profissional não encontrado!", { theme: "colored" });
    }

    this.MSchedule.setProcedure({ employee });
  };
}
