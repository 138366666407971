import { useEffect } from 'react'
import { useAssessments } from '../../../hooks/AssessmentsContext'
import { useComments } from '../../../hooks/CommentsContext'
import { dateFormat } from '../../../services/functions'
import { StartsAverageStats } from '../Stars/StartsAverage/styles'
import { Header, Item, Option, Select, Title } from '../styles'
import {
  Comment,
  CommentBox,
  CommentList,
  CommentTags,
  CommentTitle,
  Container,
} from './styles'

export function Comments() {
  const { getComments, updateFilter, getFilter } = useComments()
  const { getFilter: getAssessmentsFilter } = useAssessments()

  const comments = getComments()

  const assessmentsFilter = getAssessmentsFilter()
  const { period } = getFilter()

  useEffect(() => {
    updateFilter({ period: assessmentsFilter.period })
  }, [assessmentsFilter, updateFilter])

  return (
    <Container item xs={12}>
      <Header>
        <Title>
          Comentários
          {assessmentsFilter.period !== period && (
            <em className="alert">
              (⚠️ Comentários fora do período das avaliações)
            </em>
          )}
        </Title>
        <Item xs={2}>
          <Select
            fullWidth
            select
            label="PERÍODO"
            variant="outlined"
            value={period}
            onChange={(e) => {
              updateFilter({ period: e.target.value })
            }}
          >
            <Option value="all-period">TODO PERÍODO</Option>
            <Option value="last-90-days">ÚLTIMOS 90 DIAS</Option>
            <Option value="last-30-days">ÚLTIMOS 30 DIAS</Option>
            <Option value="last-7-days">ÚLTIMOS 7 DIAS</Option>
          </Select>
        </Item>
      </Header>

      <CommentList>
        {comments &&
          comments.map((comment) => (
            <CommentBox key={comment.comment}>
              <StartsAverageStats
                average={comment.reviewStars ? 20 * comment.reviewStars : 0}
              />
              <CommentTitle> {dateFormat(comment.date)}</CommentTitle>
              <Comment>{comment.comment}</Comment>
              <CommentTags>
                {comment.tags.map((tag) => (
                  <li key={tag}>{tag}</li>
                ))}
              </CommentTags>
            </CommentBox>
          ))}
      </CommentList>
    </Container>
  )
}
