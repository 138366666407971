import { Search } from 'lucide-react'

import { useCustomer } from '../../../../../../../store/customer/useCustomer'
import { usePet } from '../../../../../../../store/customer/usePet'
import {
  CustomerController,
  PetController,
} from '../../../../../../../services/controllers/scheduling'

import { Input } from '../../../../../../ui/Form/Input'
import { Action } from '../../../../../../ui/Action'

import { Container, FormContent } from './styles'

export function FindClient() {
  const CLCustomer = CustomerController()
  const CLPet = PetController()

  const customer = useCustomer((st) => st.data.customer)
  const pet = usePet((st) => st.data.pet)

  return (
    <Container onSubmit={CLCustomer.findPets}>
      <FormContent>
        <Input.Root
          label="CPF"
          mask="999.999.999-99"
          value={customer.cpf}
          onChange={(cpf) => {
            CLCustomer.setCustomerData({ cpf })
          }}
        />
        <Input.Root
          label="Celular"
          mask="(99) 99999-9999"
          value={customer.cellphone}
          onChange={(cellphone) => {
            CLCustomer.setCustomerData({ cellphone })
          }}
        />
        <Input.Root
          label="Tutor"
          value={customer.name}
          onChange={(name) => {
            CLCustomer.setCustomerData({ name })
          }}
        />
        <Input.Root
          label="Pet"
          value={pet.name}
          onChange={(name) => {
            CLPet.setPetData({ name })
          }}
        />
      </FormContent>
      <Action.Root onClick={CLCustomer.findPets}>
        <Action.Icon icon={Search} />
      </Action.Root>
    </Container>
  )
}
