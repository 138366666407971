import { SubscriptionCalculatorModel } from "../../models/subscription";
import { useCalculator } from "../../../store/subscription/useCalculator";


export default class SubscriptionCalculatorController {
  _calculateEstimateResultDebounce = false

  setResult = useCalculator.getState().actions.setResult
  setLoading = useCalculator.getState().actions.setLoading
  setSubscriberEstimateValue = useCalculator.getState().actions.setSubscriberEstimate

  constructor() {
    this.name = "subscription-calculator";

    this.MSubscriptionCalc = SubscriptionCalculatorModel();
  }

  setSubscriberEstimate = (subscribers) => {
    if(this._calculateEstimateResultDebounce) clearTimeout(this._calculateEstimateResultDebounce)

    this.setLoading(true)

    this.setSubscriberEstimateValue(subscribers)
    this._calculateEstimateResultDebounce = setTimeout(this.calculateEstimateResult, 500)
  };

  calculateEstimateResult = async () => {
    const subscriberEstimate = useCalculator.getState().data.subscriberEstimate

    if(subscriberEstimate <= 0) {
      this.setResult({ expenses:0, result: 0, revenue: 0 })
      this.setLoading(false)
      return
    }

    const results = await this.MSubscriptionCalc.calculate(subscriberEstimate);

    this.setResult(results)

    this.setLoading(false)
  };
}
