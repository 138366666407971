import React from 'react'
import Grid from '@material-ui/core/Grid'
import { usePets } from '../../../providers/pets'
import { useFilterPet } from '../../../hooks/FilterPets'

import styles from './styles'

function PetLIst() {
  const { petList } = usePets()
  const { filterList, setFilterList } = useFilterPet()

  const { grid, pet, petImage } = styles()

  return (
    <Grid item xs={12} className={grid}>
      {petList &&
        petList.map(({ name, petImg }) => (
          <button
            key={name}
            className={pet}
            style={{
              borderColor: filterList.pet === name ? '#cf5d09' : '#ffffff',
            }}
            onClick={() => {
              if (filterList.pet) {
                setFilterList({ ...filterList, pet: '' })
              } else {
                setFilterList({ ...filterList, pet: name })
              }
            }}
          >
            {petImg && <img src={petImg} alt={name} className={petImage} />}
            {!petImg && name.charAt(0)}
          </button>
        ))}
    </Grid>
  )
}

export default PetLIst
