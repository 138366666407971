import _ from "lodash";

import { getCurrentUserID } from "../../functions";

import { useUser } from "../../../store/global/useUser";

import { UserModel } from "../../models/global";

export default class UserController {
  _ID = getCurrentUserID()

  setUser = useUser.getState().actions.setUser
  updateUser = useUser.getState().actions.updateUser
  updateUserRole = useUser.getState().actions.updateUserRole
  setUserGroups = useUser.getState().actions.setUserGroups

  constructor() {
    this.MUser = UserModel();
  }

  init = _.once(async () => {
    if (this._ID === 'master') {
      this.setRole('master')
      return
    }

    if (!this._ID) return
    await this.getGroups();
    this.getUser();
  });

  getUser = async () => {
    const user = await this.MUser.find(this._ID);
    if (!user) return

    this.setUser(user);
    this.updateUserGroup()
  };

  updateUserGroup = () => {
    const groups = useUser.getState().data.groups
    const user = useUser.getState().data.user

    const group = _.find(groups, { 'id': user.groupId });

    if (!!group) this.updateUser({ group });
  };

  setRole = (role) => {
    this.updateUserRole(role);
  };

  getGroups = async () => {
    const groups = await this.MUser.findGroup();
    if (!groups) return

    this.setUserGroups(groups);
  };
}
