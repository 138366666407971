import { Number as Container } from './styles'
import { useSteps } from './useSteps'

export function StepsBullet({ step, name, status }) {
  const setActive = useSteps((st) => st.actions.setActive)

  const updateStep = () => {
    if (status === 2) {
      setActive(name, step.title)
    }
  }

  return <Container onClick={updateStep}>{step.number}</Container>
}
