import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    background: '#FFFFFF',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '7px',
    marginBottom: '20px',
    padding: '16px 20px',
    overflow: 'auto',
    maxWidth: '100%',
  },
  drawerBox: {
    maxWidth: '1020px',
    overflowX: 'hidden',
    padding: '0 40px',
    height: '100%',
  },
  petBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  petImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  petNew: {
    width: '90px',
    height: '90px',
    borderRadius: '50%',
    border: 'none',
    margin: '8px',
    background: '#DFDFDF',
    color: '#FFFFFF',
    fontSize: '90px;',
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    alignItems: 'center',
    cursor: 'pointer',
  },
  pet: {
    width: '90px',
    cursor: 'pointer',
    textDecoration: 'none',
    height: '90px',
    borderRadius: '50%',
    border: 'none',
    background: '#CCD219',
    margin: '8px',
    fontSize: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 150ms linear',
  },

  petEdit: {
    position: 'absolute',
    background: '#000',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0',
    opacity: '0',
    transition: 'all 150ms linear',
    '&:hover': {
      opacity: '.5',
    },
  },
}))
