import API from './api'

export const createCheckin = async (
  id = 81,
  checklist = [],
  valueDescription,
) => {
  const checkin = { checklist }
  try {
    const { data } = await API.post(
      `service-order/appointment/${id}/check-in`,
      {
        checkin,
        comment: valueDescription,
      },
    )

    return data
  } catch (error) {
    return console.log(error)
  }
}
