import React, { useContext } from 'react'

import { TextField } from '@mui/material'
import { BlockEmployeeContext } from '../../../../../providers/blockEmployee'
import { Container } from './styles'

function OfficeHours({ validation }) {
  const { horarioInicio, setHorarioInicio, horarioFim, setHorarioFim } =
    useContext(BlockEmployeeContext)

  return (
    <Container>
      <TextField
        type="time"
        disabled={validation}
        id="horario-inicio"
        label="HORA INÍCIO"
        name="horario-inicio"
        style={{ width: '48%', marginRight: '4%' }}
        value={horarioInicio}
        onChange={(event) => setHorarioInicio(event.target.value)}
      />

      <TextField
        type="time"
        disabled={validation}
        id="horario-fim"
        label="HORA DE FIM"
        name="horario-fim"
        style={{ width: '48%' }}
        value={horarioFim}
        onChange={(event) => setHorarioFim(event.target.value)}
      />
    </Container>
  )
}

export default OfficeHours
