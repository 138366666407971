// import { Container } from './styles';

import { Syringe } from "lucide-react";
import { Accordion } from "../../../../../../components/ui/Accordion";

export function HistoryViewVaccines() {
  return (
    <Accordion.Item
      title="Vacinas"
      animate="dance"
      icon={Syringe}
      disabled
    >
      <Accordion.Content>
        HistoryViewVaccines
      </Accordion.Content>
    </Accordion.Item>
  );
}
