import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const termEuthanasiaConsent = {
  name: "Termo de Consentimento para Realização de Eutanásia",
  title:
    "Termo de Consentimento Livre e Esclarecido para Realização de Eutanásia",
  gender: "male",
  fields: {
    doctorName: {
      label: "Médico(a)/Veterinário(a)",
      type: "text",
      required: true,
      defaultPreview: _.range(20).fill("_").join(""),
    },
    doctorCRV: {
      label: "CRMV",
      type: "text",
      required: true,
      defaultPreview: _.range(5).fill("_").join(""),
    },
    details: {
      label: "Resenha detalhada",
      type: "textarea",
      required: true,
      defaultPreview: _.range(50).fill("_").join(""),
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
    doctorNote: {
      label: "Observações do(a) Médico(a)-Veterinário(a)",
      type: "textarea",
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
    tutorNote: {
      label: "Observações do tutor(a)/proprietário(a)/responsável",
      type: "textarea",
      props: {
        rows: 4,
        style: { minWidth: "100%" },
      },
    },
  },
  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },
  content: `
    <p>Declaro estar ciente dos motivos que levam à necessidade de realização da eutanásia (morte sem dor), reconheço que esta é a opção escolhida por mim para cessar definitivamente o sofrimento e, portanto, autorizo a realização da eutanásia do meu animal, a ser realizado pelo(a) Médico(a) Veterinário(a) <span>{{doctorName}}</span> CRMV-{{doctorCRV}}</p>
    <hr />
    <br />
    <p><strong>Resenha detalhada:</strong></p>
    <p>{{{details}}}</p>
    <br />
    <p>Declaro, ainda, que fui devidamente esclarecido(a) do método que será utilizado, assim como de que este é um processo irreversível.</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}

    {{#doctorNote}}
      <br />
      <br />
      <p><strong>Observações do(a) Médico(a)-Veterinário(a):</strong></p>
      <p>{{{.}}}</p>
    {{/doctorNote}}
    {{#tutorNote}}
      <br />
      <br />
      <p><strong>Observações do tutor(a)/proprietário(a)/responsável:</strong></p>
      <p>{{{.}}}</p>
    {{/tutorNote}}
  `.trim(),
};
