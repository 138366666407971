import { Root, Portal, Provider } from "@radix-ui/react-tooltip";
import { Slot } from "@radix-ui/react-slot";

import { useTooltip } from "./useTooltip";

export function TooltipTrigger({ children, tooltipName, asChild }) {
  const Component = asChild ? Slot : "span";

  const isOpen = useTooltip((st) => st.actions.isOpen);

  return (
    <Component
      onMouseEnter={() => {
        isOpen({ name: tooltipName, open: true });
      }}
      onMouseLeave={() => {
        isOpen({ name: tooltipName, open: false });
      }}
    >
      {children}
    </Component>
  );
}
