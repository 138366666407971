import { Text } from "@react-pdf/renderer";

import { autoFormatMedicineDosageInformation } from "../../../../../../services/functions";

import { self } from "./styles";

export function Self(medicine) {
  return (
    <Text style={self}>{autoFormatMedicineDosageInformation(medicine)}</Text>
  );
}
