import CountUp from 'react-countup'

import { useAssessments } from '../../../../hooks/AssessmentsContext'
import { getNumber } from '../../../../services/functions'
import {
  Container,
  StartsChartBar,
  StartsChartItem,
  StartsChartText,
  StartsChartTotal,
} from './styles'

export function StartsChartList() {
  const { getStars } = useAssessments()
  const stars = getStars()

  return (
    <Container>
      <StartsChartItem>
        <StartsChartText>5 estrela</StartsChartText>
        <StartsChartBar
          percent={stars ? getNumber(stars.fiveStars.percentage) : 0}
        />
        <StartsChartTotal>
          <CountUp end={stars && getNumber(stars.fiveStars.percentage)} />%
        </StartsChartTotal>
      </StartsChartItem>
      <StartsChartItem>
        <StartsChartText>4 estrela</StartsChartText>
        <StartsChartBar
          percent={stars ? getNumber(stars.fourStars.percentage) : 0}
        />
        <StartsChartTotal>
          <CountUp end={stars && getNumber(stars.fourStars.percentage)} />%
        </StartsChartTotal>
      </StartsChartItem>
      <StartsChartItem>
        <StartsChartText>3 estrela</StartsChartText>
        <StartsChartBar
          percent={stars ? getNumber(stars.threeStars.percentage) : 0}
        />
        <StartsChartTotal>
          <CountUp end={stars && getNumber(stars.threeStars.percentage)} />%
        </StartsChartTotal>
      </StartsChartItem>
      <StartsChartItem>
        <StartsChartText>2 estrela</StartsChartText>
        <StartsChartBar
          percent={stars ? getNumber(stars.twoStars.percentage) : 0}
        />
        <StartsChartTotal>
          <CountUp end={stars && getNumber(stars.twoStars.percentage)} />%
        </StartsChartTotal>
      </StartsChartItem>
      <StartsChartItem>
        <StartsChartText>1 estrela</StartsChartText>
        <StartsChartBar
          percent={stars ? getNumber(stars.oneStar.percentage) : 0}
        />
        <StartsChartTotal>
          <CountUp end={stars && getNumber(stars.fourStars.percentage)} />%
        </StartsChartTotal>
      </StartsChartItem>
    </Container>
  )
}
