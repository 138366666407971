import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  Container: {
    width: '30vw',
    padding: '30px',
  },
  input: {
    marginBottom: '20px',
  },
  saveButton: {
    background: '#CCD219',
    borderRadius: '3px',
    border: '1px solid #CCD219',
    color: '#ffffff',
  },
  cancelButton: {
    background: 'transparent',
    borderRadius: '3px',
    border: '1px solid #CCD219',
    color: '#666666',
  },
}))
