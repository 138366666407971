import { ScheduleController } from "../../../services/controllers/scheduling";

import { Steps } from "../../ui/Steps";

import { SelectCustomer } from "./Steps/SelectCustomer";
import { SelectProcedure } from "./Steps/SelectProcedure";
import { CheckData } from "./Steps/CheckData";
import { Drawer } from "../../ui/Drawer";

export function NewScheduling() {
  const CLScheduling = ScheduleController();

  const drawer = Drawer.store(
    (st) => st.data.drawers[CLScheduling.drawerName]
  );

  return (
    <>
      <Steps.Root
        name={CLScheduling.stepsName}
        steps={[
          "Selecione o cliente",
          "Selecione o procedimento",
          "Confira os dados",
        ]}
      >
        <Steps.Bullet />
        <Steps.Text />
      </Steps.Root>
      {!!drawer && drawer.open && (
        <>
          <SelectCustomer />
          <SelectProcedure ScheduleController={CLScheduling} />
          <CheckData ScheduleController={CLScheduling} />
        </>
      )}
    </>
  );
}
