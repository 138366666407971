import { VACCINATION_STATUS_FIELDS } from "../../../../../../../store/service/veterinary/constants";

import { VeterinaryVaccineController } from "../../../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../../../components/ui/Form/Input";

export function VaccineBatch({ vaccine }) {
  const CLVetVaccine = VeterinaryVaccineController();

  if (!VACCINATION_STATUS_FIELDS[vaccine.status].includes("batch")) {
    return vaccine.batch || "";
  }
  return (
    <Input.Root
      label="Lote"
      value={vaccine.batch}
      onChange={(batch) => {
        CLVetVaccine.setAssignedVaccineFieldsValue(vaccine.id, {
          batch,
        });
      }}
    />
  );
}
