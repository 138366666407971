import moment from "moment";
import slugify from "slugify";

import {
  convertFileSize,
  formatFileName,
  formatName,
  getPDFGenerateStatusBadge,
  getUniqType,
  getVaccinationProtocolStatusBadge,
  truncateString,
} from "../functions";

import { thumbnail } from "../thumbnail";

async function formatThumbnail(item) {
  const thumbs = await thumbnail.from(item.type, item.path, [300, 400])

  return {
    ...item,
    thumb: thumbs[400],
    bigThumb: thumbs[300],
  }
}

function formatAttachment(attachment) {
  const formattedSize = convertFileSize(attachment.size);
  const nameData = formatFileName(attachment.name);

  const type = getUniqType(attachment.type || nameData.type)

  const thumb = '';
  const bigThumb = '';

  return {
    thumb,
    bigThumb,
    type,
    id: attachment.id,
    slug: slugify(nameData.name),
    fullName: nameData.name,
    path: attachment.path,
    date: attachment.date,
    name: truncateString(nameData.name),
    informationList: {
      Data: moment(attachment.date).format("DD/MM/YYYY, hh:mm"),
      Tamanho: `${formattedSize.size} ${formattedSize.unit}`,
    },
  };
}

function formatPrescription(prescription) {
  const status = !!prescription.path ? "generated" : "notGenerated"


  const medicines = prescription.medicines.map(medicine => ({
    id: medicine._id,
    name: medicine.medicineName,
    presentation: medicine.medicineVariation,
    fillStyle: medicine.fillStyle,
    freeText: medicine.posology || '',
    dose: medicine.dose,
    measure: medicine.measure,
    frequency: medicine.frequency,
    frequencyPeriod: medicine.frequencyPeriod,
    duration: medicine.duration,
    durationPeriod: medicine.durationPeriod,
    note: medicine.note || '',
    pharmacyType: medicine.pharmacyType,
    usage: medicine.usage,
    quantity: medicine.quantity,
    new: false,
  }))


  return {
    type: "pdf",
    thumb: false,
    bigThumb: false,
    medicines,
    id: prescription.id,
    path: prescription.path,
    slug: slugify(prescription.name || ''),
    name: prescription.name,
    informationList: {
      Status: getPDFGenerateStatusBadge(status),
      'Criada em': moment(prescription.createdDate).format("DD/MM/YYYY [às] hh:mm"),
      'Atualizada em': moment(prescription.updatedDate).format("DD/MM/YYYY [às] hh:mm"),
    },
  };
}

function formatRequest(request) {
  const status = !!request.path ? "generated" : "notGenerated"
  return {
    status,
    thumb: false,
    bigThumb: false,
    type: "pdf",
    id: request.id,
    path: request.path,
    name: request.name,
    contentType: request.contentType,
    slug: slugify(request.name),
    informationList: {
      Status: getPDFGenerateStatusBadge(status),
      Data: moment(request.date).format("DD/MM/YYYY [às] hh:mm"),
    },
  };
}

export function formatProtocol(protocol) {
  const name = formatName(protocol.vaccineName);

  return {
    id: protocol.id,
    type: "vaccine",
    slug: slugify(name),
    fullName: name,
    name: truncateString(name),
    status: protocol.status,
    protocolName: protocol.protocolName,
    interrupted: protocol.interrupted,
    actionState: "",
    informationList: {
      Status: getVaccinationProtocolStatusBadge(protocol.status),
      Protocolo: formatName(protocol.protocolName),
      Programação: protocol.scheduledDate,
    },
  };
}

export const formatPreviewList = {
  protocol: (protocol) => formatProtocol(protocol),
  protocols: (protocols) => {
    if (!protocols.length) return [];
    return protocols.map(formatProtocol);
  },
  attachment: (attachment) => formatAttachment(attachment),
  attachments: (attachments) => {
    if (!attachments.length) return [];
    return attachments.map(formatAttachment)
  },
  request: (request) => formatRequest(request),
  requests: (requests) => {
    if (!requests.length) return [];
    return requests.map(formatRequest)
  },

  prescription: (prescription) => formatPrescription(prescription),
  prescriptions: (prescriptions) => {
    if (!prescriptions.length) return [];
    return prescriptions.map(formatPrescription)
  },

  thumb: async (item) => await formatThumbnail(item),
  thumbs: async (items) => {
    if (!items.length) return [];
    return await Promise.all(items.map(formatThumbnail))
  },
};
