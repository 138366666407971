import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 650px;
  gap: 14px;
`
export const Main = styled.main`
  max-width: 100%;
`
export const Side = styled.aside`
  max-width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: calc(100vh - 210px);
  gap: 14px;
`

export const Title = styled.h2`
  margin: 0;
  background: ${(props) => props.theme.colors.scale[500]};
  color: ${(props) => props.theme.colors.background};
  padding: 8px 18px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  ${(props) =>
    props.cols &&
    css`
      display: grid;
      grid-template-columns: ${props.cols};
    `}
`
