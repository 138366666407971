import { useState } from 'react'

import DrawerType from '../../../../components/atoms/Drawer'
import CalendarOptions from '../../../../components/organisms/CalendarOptions'

import { ActionButton } from '../styles'

export function CalendarOptionsButton() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DrawerType
        open={isOpen}
        setDrowerOpen={setIsOpen}
        titleHeader="Opções do calendário"
        content={
          <CalendarOptions
            useContext
            hiddenScheduleType
            closeOptionsOpen={() => {
              setIsOpen(false)
            }}
          />
        }
      />

      <ActionButton onClick={() => setIsOpen(true)}>Filtro</ActionButton>
    </>
  )
}
