import { memo } from "react";
import { useHistory } from "../../../../../../store/service/veterinary/useHistory";

import { formatMoney } from "../../../../../../services/functions";

import { InformationBlock as Container, Line, Title, Highlight, HtmlContent } from "../styles";

export const GeneralInformation = memo(() => {
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const procedureData = useHistory((st) => st.data.historyProcedureData);

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedureRecord = selectedProcedure?.record

  const weight = !!procedureRecord?.weight ? `${formatMoney(procedureRecord.weight, 3)} Kg` : '-';

  return (
    <Container>
      <Title>Informações Gerais</Title>
      <Line>
        <p>
          <Highlight>Peso: </Highlight> {weight}
        </p>
      </Line>
      <Line>
        <p>
          <Highlight>Anamnese: </Highlight>
        </p>
        <HtmlContent dangerouslySetInnerHTML={{ __html: procedureRecord?.anamnesis || '-' }} />
      </Line>
    </Container>
  );
})
