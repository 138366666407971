import { memo } from "react";

import { Alert } from "../../../../../components/ui/Alert";

import { formatName } from "../../../../../services/functions";

import { VeterinaryVaccineController } from "../../../../../services/controllers/VeterinaryCare";
import { useVaccine } from "../../../../../store/service/veterinary/useVaccine";

export const IncompleteProtocol = memo(() => {
  const CLVetVaccine = VeterinaryVaccineController();

  const vaccineValues = useVaccine((st) => st.data.vaccineValues);
  const vaccinesList = useVaccine((st) => st.data.vaccinesList);

  const vaccine = _.find(vaccinesList, { id: vaccineValues.vaccineId });

  return (
    <Alert.Root name={CLVetVaccine._incompleteNewProtocolAlertName}>
      <Alert.Content>
        <Alert.Title>Atenção! Dados não Salvos</Alert.Title>
        <Alert.Description>
          <p>
            Atenção! o protocolo de <strong>{formatName(vaccine?.name)}</strong>{" "}
            ainda não foi salvo. Se você sair agora, todas as modificações
            realizadas serão perdidas. Tem certeza de que deseja sair sem
            salvar?
          </p>
        </Alert.Description>
        <Alert.Actions>
          <Alert.Cancel>Voltar</Alert.Cancel>
          <Alert.Confirm onClick={() => CLVetVaccine.closeNewProtocol(true)}>
            Sim, sair
          </Alert.Confirm>
        </Alert.Actions>
      </Alert.Content>
    </Alert.Root>
  );
});
