import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  grid: {
    padding: '0 16px',
    background: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '7px',
    marginBottom: '20px',
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: '3px solid #F4F4F4',
    alignItems: 'center',
    marginRight: '22px',
  },
  avatar: {
    width: '90px',
    height: '90px',
    fontSize: '50px',
    backgroundColor: '#CCD219',
    color: '#ffff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userName: {
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: '16px 0',
  },
  register: {
    color: '#B4B4B4',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '13px',
  },
  wpp: {
    color: '#ffff',
    background: '#25d366',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '6px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '180px',
    fontSize: '12px',
    lineHeight: '13px',
    marginTop: '16px',
    textDecoration: 'none',
  },
  wppIcon: {
    margin: '0 0 0 10px',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  infoItem: {
    marginBottom: '16px',
    width: '100%',
  },

  infoItem3G: {
    marginBottom: '16px',
    width: '33.33%',
  },

  infoTitle: {
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '13px',
    color: '#666666',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  DrawerBox: {
    maxWidth: '1020px',
    overflowX: 'hidden',
    padding: '0 40px',
  },
  EditLink: {
    textDecoration: 'none',
    color: '#666666',
    cursor: 'pointer',
  },
  infoItemTitle: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '13px',
    color: '#B4B4B4',
  },
  infoDescription: {
    color: '#666666',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '13px',
  },
}))
