import React, { useEffect, useState } from 'react'
import API from '../services/api'

// Componentes
import Vacina from '../components/organisms/Vacinas'

function Vacinas() {
  const [vacinas, setVacinas] = useState()

  const getVacinas = async () => {
    try {
      const { data } = await API.get('vaccines')
      setVacinas(data.vaccines)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getVacinas()
  }, [])

  return <Vacina service={vacinas} getServices={getVacinas} />
}

export default Vacinas
