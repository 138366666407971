import { useSpring } from '@react-spring/web'

import { useEffect } from 'react'
import { ControlAction as Container } from '../styles'
import { useDrawer } from '../useDrawer'

export function DrawerControl({
  children,
  name,
  delay,
  disabled = false,
  ...rest
}) {
  const drawers = useDrawer((st) => st.data.drawers)
  // const drawer = drawers[name]

  // const [props, api] = useSpring(() => ({
  //   from: { x: 100 },
  //   config: { tension: 400, mass: 2, velocity: 2 },
  // }))

  // console.log(props)
  // useEffect(() => {
  //   api({
  //     from: { x: 100 },
  //     to: { x: drawer.open ? 20 : 100 },
  //     delay,
  //   })
  // }, [drawer.open])

  return (
    <Container {...rest} disabled={disabled}>
      {children}
    </Container>
  )
}
