import { useRanking } from '../../../../hooks/RankingContext'
import { abbreviate } from '../../../../services/functions'
import { Employee } from '../Employee'
import { Title } from '../styles'
import {
  Container,
  Months,
  Month,
  MonthHeader,
  MonthHeaderItens,
  MonthHeaderItem,
  MonthWinner,
  MonthWinnerTotalGoal,
  MonthWinnerAward,
} from './styles'

export function History() {
  const { getHistoryRanking } = useRanking()
  const historys = getHistoryRanking()

  return (
    <Container>
      <Title>Histórico de Premiações</Title>
      <Months>
        {!!historys &&
          historys.map((history) => (
            <Month key={history.id}>
              <MonthHeader>
                <MonthHeaderItens cols="225px 70px 1fr">
                  <MonthHeaderItem style={{ textAlign: 'left' }}>
                    {history.fromDate} -{history.toDate}
                  </MonthHeaderItem>
                  <MonthHeaderItem>%</MonthHeaderItem>
                  <MonthHeaderItem>vendedor ganhou</MonthHeaderItem>
                </MonthHeaderItens>
              </MonthHeader>
              {history.winners.map((winner, i) => (
                <MonthWinner key={winner.id} cols="225px 70px 1fr">
                  <Employee.Root size="small">
                    <Employee.Avatar name={winner.sellerName}>
                      <Employee.Position value={i + 1} />
                    </Employee.Avatar>
                    <Employee.Informations>
                      <Employee.InformationTitle>
                        {abbreviate(winner.sellerName)}
                      </Employee.InformationTitle>
                      <Employee.InformationSubTitle>
                        {winner.storeName}
                      </Employee.InformationSubTitle>
                    </Employee.Informations>
                  </Employee.Root>
                  <MonthWinnerTotalGoal>
                    {Math.round(winner.accomplishedPercentage * 100)}%{' '}
                    <span>da Meta</span>
                  </MonthWinnerTotalGoal>
                  <MonthWinnerAward>{winner.awardSeller}</MonthWinnerAward>
                </MonthWinner>
              ))}
              {Array(2 - history.winners.length)
                .fill('')
                .map((e, i) => (
                  <MonthWinner key={e} cols="170px 70px 1fr">
                    <Employee.Root size="small">
                      <Employee.Avatar name="Pet Land">
                        <Employee.Position
                          value={history.winners.length + i + 1}
                        />
                      </Employee.Avatar>
                      <Employee.Informations>
                        <Employee.InformationTitle>-</Employee.InformationTitle>
                        <Employee.InformationSubTitle>
                          -
                        </Employee.InformationSubTitle>
                      </Employee.Informations>
                    </Employee.Root>
                    <MonthWinnerTotalGoal>
                      0% <span>da Meta</span>
                    </MonthWinnerTotalGoal>
                    <MonthWinnerAward> - </MonthWinnerAward>
                  </MonthWinner>
                ))}
            </Month>
          ))}
      </Months>
    </Container>
  )
}
