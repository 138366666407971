import { memo } from "react";
import {
  VACCINATION_PROTOCOL_STATUS_NAME,
  VACCINATION_PROTOCOL_STATUS_COLOR,
  VACCINATION_PROTOCOL_STATUS_ICON,
} from "../../../../../../store/service/veterinary/constants";

import { Badge } from "../../../../../../components/ui/Badge";

export const VaccinationStatusBadges = memo(({ assignedProtocol }) => {
  if (!assignedProtocol?.status) return null;

  const vaccinationProtocolStatus =
    VACCINATION_PROTOCOL_STATUS_NAME[assignedProtocol?.status][0];

  return (
    <Badge.Root
      status={VACCINATION_PROTOCOL_STATUS_COLOR[assignedProtocol.status]}
    >
      <Badge.Icon
        icon={VACCINATION_PROTOCOL_STATUS_ICON[assignedProtocol.status]}
      />
      <Badge.Text>{vaccinationProtocolStatus}</Badge.Text>
    </Badge.Root>
  );
});
