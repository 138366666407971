import { rgba, lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div``;

export const TimeLine = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 0;
  padding: 0 0 0 20px;
  width: 100%;
  position: relative;
  &::after {
    content: "";
    width: 2px;
    height: 100%;
    background: ${(props) => props.theme.colors.scale[600]};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const TimeLineItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

export const TimeLineTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.scale[600]};
  position: relative;
  z-index: 2;
  &::after {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.scale[500]};
    position: absolute;
    left: -26px;
    top: 50%;
    transform: translateY(-50%);
  }
  &::before {
    content: "";
    width: 14px;
    height: 28px;
    background: ${(props) => props.theme.colors.background};
    position: absolute;
    left: -26px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const TimeLineContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100px;
  padding-right: 30px;
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px 5px;
  background: ${(props) => lighten(0.05, props.theme.colors.scale[100])};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.scale[400]};
`;

export const TimeLineType = styled.div`
  padding: 10px 30px;
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.scale[400]} 60%,
    transparent
  );
`;

export const TimeLineTypeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TimeLineTypeTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const TimeLineDescription = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 20px;
`;

export const TimeLineDescriptionTitle = styled.span`
  color: ${(props) => props.theme.colors.gray[400]};
  font-size: 18px;
`;

export const TimeLineDescriptionText = styled.p`
  font-size: 18px;
  margin: 0;
  flex: 1;
  width: 100%;
  color: ${(props) => props.theme.colors.gray[800]};
`;

export const TimeLineBadges = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`;

export const TimeLineBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.scale[500]};
  position: relative;
`;

export const TimeLineBadgeTipContent = styled.span`
  padding: 3px 6px;
  color: ${(props) => props.theme.colors.scale[600]};
`;

export const TimeLineAction = styled.div`
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
