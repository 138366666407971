import { ContentLabel } from "./styles";

export function ItemContentLabel({ content, label }) {
  if (!label) return <span>{content}</span>

  return (
    <ContentLabel>
      <strong>{label}:</strong> {content}
    </ContentLabel>
  )
}
