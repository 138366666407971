import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react'

import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'

import API from '../services/api'
import { RANKING } from '../styles/constant'
import { Faker } from '../services/faker'

const fakerGlobalRankingList = Faker.globalRankingList(20)
const RankingContext = createContext({})

const config = {
  pagination: RANKING.pagination,
}

function RankingProvider({ children }) {
  const [rankingInfo, setRankingInfo] = useState(false)
  const [storeRanking, setStoreRanking] = useState([])
  const [globalRanking, setGlobalRanking] = useState([])
  const [historyRanking, setHistoryRanking] = useState(false)

  const [pagination, setPagination] = useState(false)

  const formatStoreRanking = useCallback((data) => {
    if (!data) return

    const storeRankingPages = data.map((page) =>
      page.ranking.map((player) => ({
        id: player._id,
        quantity: player.accomplishedQuantity,
        position: player.position,
        percentage: player.accomplishedPercentage * 100,
        name: player.sellerName
          .toLowerCase()
          .replace(/(^|\s)\S/g, (char) => char.toLocaleUpperCase()),
      })),
    )
    const newStoreRankingData = [].concat.apply([], storeRankingPages)

    setStoreRanking(newStoreRankingData)
    setRankingInfo((st) => ({ ...st, storeGoal: data[0].ranking[0].goal }))
    setPagination((st) => ({ ...st, store: data.pagination }))
  }, [])

  const formatGlobalRanking = useCallback((data) => {
    if (!data) return
    const globalRankingPages = data.map((page) =>
      page.ranking.map((player) => ({
        id: player._id,
        percentage: player.accomplishedPercentage * 100,
        quantity: player.accomplishedQuantity,
        store: player.storeName
          ? player.storeName.replace('Petland ', '')
          : player.storeName,
        name: player.sellerName
          .toLowerCase()
          .replace(/(^|\s)\S/g, (char) => char.toLocaleUpperCase()),
      })),
    )

    const newGlobalRankingData = [].concat.apply([], globalRankingPages)
    setGlobalRanking(newGlobalRankingData)
  }, [])

  const {
    data: swrGlobalRanking,
    size: globalRankingPageSize,
    setSize: setGlobalRankingPage,
  } = useSWRInfinite(
    (page, prevData) => {
      if (prevData && !prevData.ranking.length) return null
      return `/subscriptions/ranking/geral?page=${page}&perPage=${config.pagination.global.perPage}`
    },
    (url) => API.get(url).then((res) => res.data),
    {
      refreshInterval: 1000 * 60 * 22,
    },
  )

  const {
    data: swrStoreRanking,
    size: storeRankingPageSize,
    setSize: setStoreRankingPage,
  } = useSWRInfinite(
    (page, prevData) => {
      if (prevData && !prevData.ranking.length) return null
      return `/subscriptions/ranking/store?page=${page}&perPage=${config.pagination.store.perPage}`
    },
    (url) => API.get(url).then((res) => res.data),
    {
      refreshInterval: 1000 * 60 * 22,
    },
  )

  const { data: swrHistoryRanking, isLoading: historyRankingIsLoading } =
    useSWR('/subscriptions/ranking/award?page=0&perPage=10', (url) =>
      API.get(url).then((res) => res.data.winnersByWeek),
    )

  useEffect(() => {
    formatGlobalRanking(swrGlobalRanking, globalRankingPageSize - 1)
    setPagination((st) => ({ ...st, global: globalRankingPageSize }))
  }, [swrGlobalRanking])

  useEffect(() => {
    formatStoreRanking(swrStoreRanking)
    setPagination((st) => ({ ...st, store: storeRankingPageSize }))
  }, [swrStoreRanking])

  useEffect(() => {
    setHistoryRanking(swrHistoryRanking)
  }, [swrHistoryRanking])

  useEffect(() => {
    console.log(swrHistoryRanking)
    if (!historyRankingIsLoading) {
      setHistoryRanking(swrHistoryRanking)
    }
  }, [historyRankingIsLoading, swrHistoryRanking])

  const getRankingInfo = useCallback(
    (type) => {
      if (type === 'mock') {
        return {
          globalGoal: 0,
          storeGoal: 50,
        }
      }
      return rankingInfo
    },
    [rankingInfo],
  )

  const getStoreRanking = useCallback(
    (type) => {
      if (type === 'mock') {
        return Faker.storeRankingList(fakerGlobalRankingList, 10)
      }
      return storeRanking
    },
    [storeRanking],
  )

  const getHistoryRanking = useCallback(() => historyRanking, [historyRanking])

  const getGlobalRanking = useCallback(
    (type) => {
      if (type === 'mock') {
        return fakerGlobalRankingList
      }
      return globalRanking
    },
    [globalRanking],
  )

  const getPagination = useCallback(() => pagination, [pagination])

  const updatePagination = useCallback(
    (page, group) => {
      if (group === 'global') setGlobalRankingPage(page)
      if (group === 'store') setStoreRankingPage(page)
    },
    [setGlobalRankingPage, setStoreRankingPage],
  )

  return (
    <RankingContext.Provider
      value={{
        getRankingInfo,
        getStoreRanking,
        getGlobalRanking,
        getHistoryRanking,
        getPagination,
        updatePagination,
      }}
    >
      {children}
    </RankingContext.Provider>
  )
}

function useRanking() {
  const context = useContext(RankingContext)
  if (!context) {
    throw new Error('useRanking must be used within an RankingProvider')
  }
  return context
}

export { useRanking, RankingProvider }
