import { useCallback } from "react";

import { VeterinaryRequestController } from "../../../../../../../services/controllers/VeterinaryCare";
import { Switch } from "../../../../../../../components/ui/Form/Switch";

import { useContext } from "./Context";

import { Field } from "./Field";

import { PartialContainer, PartialFields, PartialSwitchBox } from "./styles";

export function PartialSwitch({ partials }) {
  const CLVetRequest = VeterinaryRequestController();

  const { fieldsValues } = useContext();

  const changeValue = useCallback((field, value) => {
    const newValue = {};
    newValue[field] = value;
    CLVetRequest.setRequestFieldsValue(newValue);
  }, []);

  if (!partials) return null;
  return Object.keys(partials).map((key) => (
    <PartialContainer key={key}>
      <PartialSwitchBox>
        <Switch.Root
          label={partials[key].partial.name}
          name={`request-partial-${key}`}
          checked={fieldsValues[key]}
          onChange={(value) => changeValue(key, value)}
        />
      </PartialSwitchBox>
      {fieldsValues[key] && partials[key].partial?.fields && (
        <PartialFields>
          <Field fields={partials[key].partial.fields} />
        </PartialFields>
      )}
    </PartialContainer>
  ));
}
