import { SubscriberController } from "../../../../services/controllers/subscription";

import { Drawer } from "../../../../components/ui/Drawer";

import { SubscriptionDetailsHeader } from "./SubscriptionDetailsHeader";
import { SubscriptionDetailsTitle } from "./SubscriptionDetailsTitle";
import { SubscriptionDetailsCycles } from "./SubscriptionDetailsCycles";
import { SubscriptionDetailsPlans } from "./SubscriptionDetailsPlans";

import { Container, Title } from "./styles";

export function SubscriptionDetails() {
  const CSubscriber = SubscriberController();

  return (
    <>
      <Drawer.Root name={CSubscriber.subscriptionDetailsDrawerName} direction="left" style={{ minWidth: ' 800px' }}>
        <Drawer.Controls controls={{ close: CSubscriber.closeDetailsDrawer }} />
        <Drawer.Header>
          <SubscriptionDetailsTitle />
        </Drawer.Header>
        <Drawer.Content style={{ flexDirection: 'column', flexWrap: 'nowrap' }}>
          <SubscriptionDetailsHeader />
          <Container>
            <div>
              <Title>Alteraração de plano</Title>
              <SubscriptionDetailsPlans />
            </div>
            <div>
              <Title>Detalhes de Pagamento</Title>
              <SubscriptionDetailsCycles />
            </div>
          </Container>
        </Drawer.Content>
        <Drawer.Footer />
      </Drawer.Root>
    </>
  )
}
