import RoyaltyStatementControllerClass from "./RoyaltyStatementController";

let CRoyaltyStatement = null;

export function RoyaltyStatementController() {
  if (CRoyaltyStatement) {
    return CRoyaltyStatement;
  }

  CRoyaltyStatement = new RoyaltyStatementControllerClass();
  return CRoyaltyStatement;
}
