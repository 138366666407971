import React, { useContext } from 'react'
import { BlockEmployeeContext } from '../../../../../providers/blockEmployee'

import { Container, Label } from './styles'

function Options() {
  const { selectedOption, setSelectedOption } = useContext(BlockEmployeeContext)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <Container>
      <Label>
        <input
          type="radio"
          name="specific-date"
          value="specific-date"
          checked={selectedOption === 'specific-date'}
          onChange={handleOptionChange}
        />
        DATA ESPECÍFICA
      </Label>
      <Label>
        <input
          type="radio"
          name="specific-period"
          value="specific-period"
          checked={selectedOption === 'specific-period'}
          onChange={handleOptionChange}
        />
        PERIODO ESPECÍFICO
      </Label>
      <Label>
        <input
          type="radio"
          name="week-day"
          value="week-day"
          checked={selectedOption === 'week-day'}
          onChange={handleOptionChange}
        />
        DIA DA SEMANA
      </Label>
    </Container>
  )
}

export default Options
