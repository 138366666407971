import { useSchedule } from '../../../../hooks/ScheduleContext'
import { secondsToHms } from '../../../../services/functions'

import { Container, ScheduleHoursLine } from './styles'

export function Hours() {
  const { getHours } = useSchedule()
  const hours = getHours()

  return (
    <Container>
      {!!hours &&
        hours.displayGaps.map(({ display, gaps }) => (
          <ScheduleHoursLine key={display} totalGaps={gaps.length}>
            {secondsToHms(display).formated}
          </ScheduleHoursLine>
        ))}
    </Container>
  )
}
