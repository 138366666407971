import { Tooth, Bandaids } from "@phosphor-icons/react";
import {
  ActivitySquare,
  Bone,
  Brain,
  Dna,
  HeartPulse,
  History,
  Microscope,
  Stethoscope,
  Syringe,
} from "lucide-react";

export function ProcedureIcon(name) {
  name = name.toLowerCase();

  switch (name) {
    case "retorno":
      return History;
    case "consulta genérica":
      return Stethoscope;

    case "avaliação cirúrgica":
      return HeartPulse;

    case "cirurgia":
      return ActivitySquare;

    case "raio x":
      return Microscope;

    case "consulta cardiologista":
      return HeartPulse;

    case "consulta neurologista":
      return Brain;

    case "consulta oncologista":
      return Dna;

    case "consulta odontológica":
      return Tooth;

    case "vacinação":
    case "coleta de exames":
      return Syringe;

    case "consulta fisioterapeuta":
    case "consulta ortopédica":
      return Bone;

    default:
      return Bandaids;
  }
}
