import { getAcronymName, getPetAttribute } from '../../../services/functions'
import {
  PetAvatar as Container,
  PetAvatarBox,
  PetAvatarAcronym,
  PetAvatarImage,
} from './styles'

export function PetCardAvatar({ url = false, attributes }) {
  if (!attributes) return null

  const status = getPetAttribute(attributes, 'Status')
  const name = getPetAttribute(attributes, 'Nome')

  return (
    <Container className={status?.value||''}>
      <PetAvatarBox>
        {url && <PetAvatarImage src={url} alt="" />}
        <PetAvatarAcronym className="relative z-2 uppercase">
          {getAcronymName(name.value)}
        </PetAvatarAcronym>
      </PetAvatarBox>
    </Container>
  )
}
