import { Invoice } from "./invoice";
import { Subscriber } from "./subscriber";
import SubscriptionDRE from "./subscriptionDRE";
import SubscriptionCalculator from "./subscriptionCalculator";
import SubscriptionPricing from "./subscriptionPricing";
import SubscriptionIndicator from "./subscriptionIndicator";

let MInvoice = null;
let MSubscriber = null;
let MSubscriptionDRE = null;
let MSubscriptionCalculator = null;
let MSubscriptionPricing = null;
let MSubscriptionIndicator = null;

export function InvoiceModel() {
  if (MInvoice) {
    return MInvoice;
  }

  MInvoice = new Invoice();
  return MInvoice;
}

export function SubscriberModel() {
  if (MSubscriber) {
    return MSubscriber;
  }

  MSubscriber = new Subscriber();
  return MSubscriber;
}

export function SubscriptionDREModel() {
  if (MSubscriptionDRE) {
    return MSubscriptionDRE;
  }

  MSubscriptionDRE = new SubscriptionDRE();
  return MSubscriptionDRE;
}

export function SubscriptionCalculatorModel() {
  if (MSubscriptionCalculator) {
    return MSubscriptionCalculator;
  }

  MSubscriptionCalculator = new SubscriptionCalculator();
  return MSubscriptionCalculator;
}

export function SubscriptionPricingModel() {
  if (MSubscriptionPricing) {
    return MSubscriptionPricing;
  }

  MSubscriptionPricing = new SubscriptionPricing();
  return MSubscriptionPricing;
}
export function SubscriptionIndicatorModel() {
  if (MSubscriptionIndicator) {
    return MSubscriptionIndicator;
  }

  MSubscriptionIndicator = new SubscriptionIndicator();
  return MSubscriptionIndicator;
}
