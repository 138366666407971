import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import { TextField, Grid, Button, Checkbox, Divider } from '@material-ui/core'
import API from '../../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  bottom: {
    margin: theme.spacing(2),
  },
}))

function dateSaveFormat(date) {
  const splitDate = date.split('/')
  return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
}

function Feriados() {
  const classes = useStyles()
  const { register, control, handleSubmit } = useForm()
  const [shoppingHollidays, setShoppingHollidays] = useState(false)
  const [repeat, setRepeat] = useState(false)

  const getHollidays = async () => {
    const result = await API.get('store/hollidays/next')
    setShoppingHollidays(result.data.nextHollidays)
  }

  const onSubmit = async (data) => {
    const newholliday = {
      date: dateSaveFormat(data.date),
      description: data.description,
      repeat,
    }
    await API.post('store/hollidays/new', newholliday)
    getHollidays()
  }

  const deleteHolliday = async (id) => {
    await API.delete(`store/holliday/${id}`)
    getHollidays()
  }

  useEffect(() => {
    if (!shoppingHollidays) {
      getHollidays()
    }
  }, [])

  if (!shoppingHollidays) {
    return null
  }

  return (
    <div className={classes.root}>
      <Grid spacing={3} container xs={12}>
        <Grid item xs={12}>
          <h3>PRÓXIMOS FERIADOS</h3>
        </Grid>
      </Grid>
      <Grid container spacing={3} xs={12}>
        <Grid item xs={1}>
          <h3>Data</h3>
        </Grid>
        <Grid item xs={8}>
          <h3>Descrição</h3>
        </Grid>
        <Grid item xs={3}>
          <h3>Repete todo ano</h3>
        </Grid>
      </Grid>
      {shoppingHollidays?.map((holliday) => (
        <Grid container spacing={3} key={holliday.id} xs={12}>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={1}>
            <p>{holliday.dateStr}</p>
          </Grid>
          <Grid item xs={8}>
            <p>{holliday.description}</p>
          </Grid>
          <Grid item xs={2}>
            {holliday.repeat ? <CheckIcon /> : null}
          </Grid>
          <Grid item xs={1}>
            <DeleteIcon
              style={{ color: '#f33838', cursor: 'pointer' }}
              onClick={() => {
                deleteHolliday(holliday.id)
              }}
            />
          </Grid>
        </Grid>
      ))}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={1}>
            <Controller
              control={control}
              name="date"
              as={
                <InputMask mask="99/99/9999">
                  {() => (
                    <TextField
                      fullWidth
                      id="date"
                      name="date"
                      inputRef={register}
                      variant="outlined"
                      placeholder="__/__/____"
                    />
                  )}
                </InputMask>
              }
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="description"
              name="description"
              inputRef={register}
              variant="outlined"
              placeholder=""
            />
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              edge="start"
              name="repet"
              onClick={() => {
                setRepeat(!repeat)
              }}
              defaultChecked={repeat}
              tabIndex={-1}
              disableRipple
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              style={{
                background: '#CCD219',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '700',
                width: '138px',
                marginRight: '20px',
              }}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default Feriados
