import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { login, getToken } from '../../../services/auth'
import logo from '../../../assets/logo.png'
import backgroundImagem from '../../../assets/petland-banho-tosa.png'

// icon material
import { useAuth } from '../../../providers/auth'
import { useThemes } from '../../../providers/theme'

const useStyles = makeStyles(() => ({
  logo: {
    minWidth: '150px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    height: '100vh',
    backgroundImage: `url(${backgroundImagem})`,
    backgroundSize: 'cover',
    margin: '-8px',
  },
  boxForm: {
    background: '#fff',
    borderRadius: '7px',
    boxShadow: '0 0 60px 0 rgb(94 92 154 / 12%)',
  },
}))

const CustomButton = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#ccd219',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#a0a510',
      border: 0,
      color: '#fff',
    },
  },
}))(Button)

function FormLogin() {
  const classes = useStyles()
  const { setTheme } = useThemes()
  const { setModules } = useAuth()
  const [logged, setLogged] = useState(null)
  const { handleSubmit, register } = useForm()
  const [loginClik, setLoginClik] = useState(false)
  const modules = localStorage.getItem('modules')
  const vetUser = localStorage.getItem('VET')
  const modulesPerse = !!modules?JSON.parse(modules):null

  const onSubmit = async ({ email, password }) => {
    const logged = await login(email, password, setLoginClik)
    setLogged(logged)
  }

  useEffect(() => {
    if (getToken()) {
      setLogged(true)
    }
  }, [])

  useEffect(() => {
    if (modulesPerse) {
      setModules(modulesPerse)
    }
  }, [modulesPerse])

  useEffect(() => {
    if (modulesPerse) {
      if (modulesPerse?.bath?.schedule && vetUser !== 'Veterinário') {
        setTheme('petland')
        localStorage.setItem('theme', 'petland')
        window.dispatchEvent(new Event('storage'))

      } else {
        setTheme('draMei')
        localStorage.setItem('theme', 'draMei')
        window.dispatchEvent(new Event('storage'))

      }
    }
  }, [modulesPerse])

  return typeof logged === 'boolean' ? (
    modulesPerse?.bath?.schedule && vetUser !== 'Veterinário' ? (
      <Redirect to="/agenda" />
    ) : (
      <Redirect to="/agenda" />
    )
  ) : (
    <Grid
      className={classes.form}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={3} alignContent="center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6} className={classes.boxForm}>
            <Grid item xs={12} className={classes.logoContainer}>
              <img
                src={logo}
                alt="Petland"
                title="Petland"
                className={classes.logo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="email"
                label="Usuário"
                inputRef={register}
                variant="outlined"
                justify="center"
                alignItems="center"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="password"
                label="Senha"
                inputRef={register}
                variant="outlined"
                type="password"
                justify="center"
                alignItems="center"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                fullWidth
                type="submit"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
              >
                {!loginClik ? (
                  'Entrar'
                ) : (
                  <CircularProgress
                    style={{ color: '#fff', width: '30px', height: '30px' }}
                  />
                )}
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              {logged && logged}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default FormLogin
