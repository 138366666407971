import { useState } from 'react'
import { useSchedule } from '../../../../hooks/ScheduleContext'

import DrawerType from '../../../../components/atoms/Drawer'

import { jsonToQuery } from '../../../../services/functions'
import { BlockEmployeeProvider } from '../../../../providers/blockEmployee'
import TemporaryBlock from '../../../../components/organisms/Schedule/TemporaryBlock'

import { ActionButton } from '../styles'

export function AddBlockButton() {
  const { revalidate, getConfig } = useSchedule()
  const config = getConfig()

  const [isOpen, setIsOpen] = useState(false)

  const updateBlockList = (date) => {
    if (config.date === date) {
      revalidate(`calendar/employees-blocked-hours?${jsonToQuery(config)}`)
    }
    setIsOpen(false)
  }
  return (
    <>
      <DrawerType
        open={isOpen}
        setDrowerOpen={setIsOpen}
        titleHeader="Adicionar Bloqueio Temporário"
        anchor="left"
        content={
          <BlockEmployeeProvider>
            <TemporaryBlock
              setBlock={setIsOpen}
              getCalendar={updateBlockList}
            />
          </BlockEmployeeProvider>
        }
      />
      <ActionButton onClick={() => setIsOpen(true)}>Bloqueio</ActionButton>
    </>
  )
}
