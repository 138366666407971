
import { twMerge } from 'tailwind-merge'

export function CardContentTitle({
  className,
  ...rest
}) {
  return (
    <strong
      className={twMerge(
        'flex items-end gap-2 text-sm font-semibold text-black',
        className,
      )}
      {...rest}
    />
  )
}
