import { isAbleByStatus } from "../../../../../services/functions";

import { useCare } from "../../../../../store/service/veterinary/useCare";
import { useRecord } from "../../../../../store/service/veterinary/useRecord";

import { VeterinaryRecordController } from "../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../components/ui/Form/Input";

import { Container } from "./styles";

export function RecordsContentsVitalSigns() {
  const careStatus = useCare(st => st.data.care.status)

  const fc = useRecord((st) => st.data.record?.fc);
  const fr = useRecord((st) => st.data.record?.fr);
  const tpc = useRecord((st) => st.data.record?.tpc);
  const temperature = useRecord((st) => st.data.record?.temperature);

  const CLVetRecord = VeterinaryRecordController();

  const isAble = isAbleByStatus(careStatus,CLVetRecord._name)

  return (
    <Container>
      <Input.Root
        money
        noSuffix
        decimals={0}
        label="FC (bpm)"
        value={fc}
        disabled={!isAble}
        onChange={(fc) => CLVetRecord.changeRecordValue({ fc })}
        onBlur={CLVetRecord.saveVitalSigns}
      />
      <Input.Root
        money
        noSuffix
        decimals={0}
        label="FR (mpm)"
        value={fr}
        disabled={!isAble}
        onChange={(fr) => CLVetRecord.changeRecordValue({ fr })}
        onBlur={CLVetRecord.saveVitalSigns}
      />
      <Input.Root
        money
        noSuffix
        decimals={1}
        label="Temperatura (ºC)"
        value={temperature}
        disabled={!isAble}
        onChange={(temperature) =>
          CLVetRecord.changeRecordValue({ temperature })
        }
        onBlur={CLVetRecord.saveVitalSigns}
      />
      <Input.Root
        money
        noSuffix
        decimals={0}
        label="TPC (seg)"
        value={tpc}
        disabled={!isAble}
        onChange={(tpc) => CLVetRecord.changeRecordValue({ tpc })}
        onBlur={CLVetRecord.saveVitalSigns}
      />
    </Container>
  );
}
