import { Container, Icon, Text } from './styles'

export function EmptyCard({ iconWhenListEmpty, message }) {
  return (
    <Container>
      <Icon>
        {iconWhenListEmpty === 'check' && (
          <picture>
            <source
              srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f609/512.webp"
              type="image/webp"
            />
            <img
              src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f609/512.gif"
              alt="😉"
              width="90"
              height="90"
            />
          </picture>
        )}
        {iconWhenListEmpty === 'sad-face' && (
          <picture>
            <source
              srcSet="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.webp"
              type="image/webp"
            />
            <img
              src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.gif"
              alt="😢"
              width="90"
              height="90"
            />
          </picture>
        )}
      </Icon>
      <Text>{message}</Text>
    </Container>
  )
}
