import { useMemo } from "react";
import moment from "moment";
import _ from "lodash";

import { formatName } from "../../../../../services/functions";

import { useVaccine } from "../../../../../store/service/veterinary/useVaccine";

import { VeterinaryVaccineController } from "../../../../../services/controllers/VeterinaryCare";

import { Drawer } from "../../../../../components/ui/Drawer";
import { Table } from "../../../../../components/ui/Table";

import { RemoveVaccine } from "../Alerts/RemoveVaccine";
import { CancelVaccine } from "../Alerts/CancelVaccine";
import { RemoveVaccinationProtocol } from "../Alerts/RemoveVaccinationProtocol";
import { ApplyVaccineAfterToday } from "../Alerts/ApplyVaccineAfterToday";
import { ReprogrammingVaccineBeforeToday } from "../Alerts/ReprogrammingVaccineBeforeToday";

import { VaccinationStatusBadges } from "./VaccinationStatusBadges";
import { VaccinationActions } from "./VaccinationActions";
import { Vaccine } from "./Vaccine";

import { Container, Header, Title } from "./styles";

export function VaccineProtocolDrawer() {
  const CLVetVaccine = VeterinaryVaccineController();

  const viewProtocolId = useVaccine((st) => st.data.showVaccinationProtocol);
  const assignedVaccines = useVaccine((st) => st.data.assignedVaccines);
  const assignedProtocols = useVaccine((st) => st.data.assignedProtocols);

  const assignedProtocol = useMemo(
    () =>
      !!assignedProtocols &&
      assignedProtocols.find((protocol) => protocol.id === viewProtocolId),
    [assignedProtocols, viewProtocolId]
  );

  const vaccinesList = useMemo(() => {
    return _.orderBy(
      assignedVaccines[viewProtocolId],
      (vaccine) => moment(vaccine.scheduledDate, "DD/MM/YYYY"),
      "desc"
    );
  }, [viewProtocolId, assignedVaccines]);

  return (
    <>
      <Drawer.Root
        name={CLVetVaccine._drawerVaccinationProtocolName}
        style={{ width: "90vw" }}
        direction="left"
      >
        <Drawer.Controls
          controls={{ close: CLVetVaccine.closeVaccinationProtocolDrawer }}
        />
        <Drawer.Header>
          Protocolo de Vacinação de {assignedProtocol?.name}
        </Drawer.Header>
        <Drawer.Content>
          <Container>
            <Header>
              <Title>
                <h2>{formatName(assignedProtocol?.protocolName)}</h2>
                <VaccinationStatusBadges assignedProtocol={assignedProtocol} />
              </Title>
              <VaccinationActions assignedProtocol={assignedProtocol} />
            </Header>

            <Table.Root
              name="veterinary-service-vaccines-protocol-table"
              style={{ minWidth: "100%", height: "auto", maxHeight: "100%" }}
            >
              <Table.Header
                columns={[
                  "#:50px",
                  "Programação:200px",
                  "Aplicação:200px",
                  "Laboratório:250px",
                  "Lote:200px",
                  "#:1fr",
                ]}
              />
              <Table.Content
                style={{
                  overflowY: "auto",
                  minHeight: "50px",
                }}
              >
                {vaccinesList.map((vaccine) => (
                  <Vaccine key={vaccine.id} vaccineData={vaccine} />
                ))}
              </Table.Content>
            </Table.Root>
          </Container>
        </Drawer.Content>
        <Drawer.Footer />
      </Drawer.Root>
      <RemoveVaccine />
      <CancelVaccine />
      <ApplyVaccineAfterToday />
      <ReprogrammingVaccineBeforeToday />
      <RemoveVaccinationProtocol />
    </>
  );
}
