import React, { useState } from 'react'
import { CalendarToday, Edit } from '@material-ui/icons'

import Grid from '@material-ui/core/Grid'

import moment from 'moment'
import DrawerType from '../../../../components/atoms/Drawer'
import EditPackage from '../EditPackage'

// moment
import 'moment/locale/pt-br'

import styles from './styles'

moment.locale('pt-br')

function Box({ packageData, getPackages }) {
  const statusColors = {
    ATIVO: '#00A1A4',
    INTEGRADO: '#59602C',
    FINALIZADO: '#CF6565',
    CANCELADO: '#A2A2A0',
  }

  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)

  const {
    avatar,
    grid,
    item,
    itemOpen,
    badge,
    infoList,
    info,
    content,
    contentOpen,
    badgeContent,
    pet,
    petName,
    tabela,
    linhaHeader,
    linha,
    buttonEdit,
    buttonArrow,
    buttonArrowOpen,
    drawerBox,
  } = styles()

  const {
    petName: name,
    promotionName,
    createdAt,
    services,
    status,
    packageId,
  } = packageData

  const closeDrawer = () => {
    getPackages()
    setOpenDrawer(false)
  }

  return (
    <Grid item xs={12} className={grid}>
      <DrawerType
        open={openDrawer}
        setDrowerOpen={closeDrawer}
        titleHeader="Editar Pet"
        content={
          <div className={drawerBox}>
            <EditPackage id={packageId} />
          </div>
        }
      />
      <Grid item xs={12} className={open ? itemOpen : item}>
        <button
          className={buttonEdit}
          onClick={() => {
            setOpenDrawer(true)
          }}
        >
          <Edit />
        </button>
        <button
          className={open ? buttonArrow : buttonArrowOpen}
          onClick={() => {
            setOpen(!open)
          }}
        />
        <span className={badge} style={{ background: statusColors[status] }}>
          <dd className={badgeContent}>{status}</dd>
          <dd className={badgeContent}>
            <CalendarToday />
            {moment(createdAt).format('DD/MM/YYYY')} às{' '}
            {moment(createdAt).format('HH:ss')}
          </dd>
        </span>
        <div className={pet}>
          <div className={avatar}>{name.charAt(0)}</div>
        </div>
        <div className={infoList}>
          <p className={petName}>{name}</p>
          <p className={info}>{promotionName}</p>
        </div>

        <div className={open ? contentOpen : content}>
          <table className={tabela}>
            <thead>
              <tr>
                <th className={linhaHeader}>SERVIÇO</th>
                <th className={linhaHeader}>VENDIDO</th>
                <th className={linhaHeader}>DISPONÍVEL</th>
              </tr>
            </thead>
            <tbody>
              {services.map(({ quantity, serviceName, quantityAvailable }) => (
                <tr key={serviceName}>
                  <td className={linha}>{serviceName}</td>
                  <td className={linha}>{quantity}</td>
                  <td className={linha}>{quantityAvailable}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Grid>
    </Grid>
  )
}

export default Box
