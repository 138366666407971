import { VeterinaryPrescriptionController } from "../../../../../services/controllers/VeterinaryCare";

import { Drawer } from "../../../../../components/ui/Drawer";

import { PrescriptionForm } from "./PrescriptionForm";
import { PrescriptionPreview } from "./PrescriptionPreview";

import { Container } from "./styles";

import { usePet } from "../../../../../store/global/usePet";
import { usePrescription } from "../../../../../store/service/veterinary/usePrescription";

export function NewPrescriptionDrawer() {
  const CLVetPrescription = VeterinaryPrescriptionController();

  const prescriptionID = usePrescription(st => st.data.prescription.id);
  const pet = usePet(st => st.data.pet)

  const drawerTitle = !prescriptionID ? "Nova Receita" : "Editar Receita";
  if (!pet.id) return null

  return (
    <>
      <Drawer.Root
        name={CLVetPrescription._drawerName}
        style={{ width: "90vw" }}
        direction="left"
      >
        <Drawer.Controls controls={{ close: CLVetPrescription.closeDrawer }} />
        <Drawer.Header>{drawerTitle}</Drawer.Header>
        <Drawer.Content>
          <Container>
            <PrescriptionForm />
            <PrescriptionPreview />
          </Container>
        </Drawer.Content>
        <Drawer.Footer />
      </Drawer.Root>
    </>
  );
}
