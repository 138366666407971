import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  record: null,
};

export const useRecord = create((set) => ({
  data: defaultData,

  actions: {
    setRecord: (record = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.record = record;
        })
      ),
    clearRecord: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        })
      ),
    setRecordValues: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!values) return;

          Object.keys(values).forEach((key) => {
            draft.data.record[key] = values[key];
          });
        })
      ),
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),
  },
}));
