import { useMemo } from 'react';
import { tv } from 'tailwind-variants';

import {Dog, PiggyBank, SlidersHorizontal, HandCoins } from 'lucide-react';

import { Columns } from './Columns';

export function Header({ value, ...rest }){
  const Icon = useMemo(()=>{
    switch(rest.type){
      case 'inflow' : return PiggyBank
      case 'outflow' : return HandCoins
      case 'settings' : return SlidersHorizontal
      case 'neutral' : return null
      default: return Dog
    }
  },[rest.type])

  const headerBox = tv({
    base: 'w-full py-2 px-4 flex',
    variants: {
      type: {
        inflow: 'bg-emerald-300/40 text-green-700',
        outflow: 'bg-rose-300/40 text-red-700',
        settings: 'bg-zinc-300/40 text-gray-700',
        neutral: 'bg-zinc-200/40 text-gray-700'
      },
    },
    defaultVariants: {
      type: 'neutral'
    }
  });

  return(
      <div className={headerBox(rest)}>
        <p className="font-bold text-lg tracking-wide flex items-center mr-2 min-w-[250px]  flex-1">
          {!!Icon && <Icon className="h-6 w-6 mr-2"/>}
          {value || ''}
        </p>
       <Columns {...rest} />
      </div>
  )
}
