import { AccessAlarm, Block as BlockIcon } from '@mui/icons-material'
import moment from 'moment'
import { secondsToHms } from '../../../../../../../services/functions'
import { useSchedule } from '../../../../../../../hooks/ScheduleContext'
import { CALENDAR } from '../../../../../../../styles/constant'
import { Confirmation } from '../../../../../../../components/Alerts/Confirmation'
import { Button, Container, Description, ErrorIcon, Icon, Time } from './styles'
import { BubbleIcon } from './BubbleIcon'

export function Block({ id }) {
  const {
    getBubbleById,
    getBubbleInformation,
    getEmployeeByBubbleId,
    removeBlock,
    getExit,
    getConfig,
  } = useSchedule()

  const config = getConfig()
  const { exitBlocks } = getExit()
  const bubble = getBubbleById(id)

  const { bubbleType, bubbleGaps } = getBubbleInformation(bubble)

  const employeeBubble = getEmployeeByBubbleId(bubble.id)

  return (
    <Container
      className={`${bubble.type} ${bubbleType} ${exitBlocks ? 'exit' : ''}`}
      status={CALENDAR.status[bubble.status].code}
      style={{ height: `${bubble.cardSize}px` }}
    >
      <Time>
        <AccessAlarm />
        {secondsToHms(bubble.startsAt).formated} -{' '}
        {secondsToHms(bubble.finishesAt).formated}
      </Time>
      <Description>{bubble.description}</Description>
      <Confirmation
        style={{ maxWidth: '500px' }}
        buttonDirection="row-reverse"
        title={
          <>
            <ErrorIcon>
              <BlockIcon style={{ fontSize: '40px' }} />
            </ErrorIcon>
            Bloqueio
          </>
        }
        description={
          <>
            Deseja cancelar o bloqueio de <strong>{employeeBubble.name}</strong>
            , no dia <strong>{moment(config.date).format('DD/MM/YYYY')}</strong>{' '}
            das{' '}
            <strong>
              {secondsToHms(bubble.startsAt).formated} às{' '}
              {secondsToHms(bubble.finishesAt).formated}
            </strong>
            ?
          </>
        }
        cancelText="Não"
        acceptText="Sim"
        acceptAction={() => {
          removeBlock(bubble)
        }}
      >
        <Icon size={bubbleGaps}>
          <Button color="error">
            <BubbleIcon status={bubble.status} hiddenDescription />
          </Button>
        </Icon>
      </Confirmation>
    </Container>
  )
}
