import { Container, Root, Thumb, Legend } from "./styles";

export function SwitchRoot({
  name,
  label = false,
  onChange: onChangeTrigger = () => null,
  ...rest
}) {
  return (
    <Container>
      <Root {...rest} id={name} onCheckedChange={(e) => onChangeTrigger(e)}>
        <Thumb />
      </Root>
      {label && <Legend htmlFor={name}>{label}</Legend>}
    </Container>
  );
}
