import styled, { css, keyframes } from "styled-components";
import {
  Icon,
  Root as SelectRoot,
  Trigger as SelectTrigger,
  Content as SelectContent,
  Viewport as SelectViewport,
  Group as SelectGroup,
  Label as SelectTitle,
  Separator as SelectSeparator,
  Item,
  ScrollUpButton,
  ScrollDownButton,
} from "@radix-ui/react-select";
import { rgba } from "polished";

const arrowHover = keyframes`
  0% {
    transform: translateY(0px);
  }
  33.33% {
    transform: translateY(-4px);
  }
  66.66% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0px);
  }
`;
export const Container = styled(SelectRoot)``;

export const Fieldset = styled.fieldset`
  position: relative;
  width: 100%;
  min-width: fit-content;
  max-width: 100%;
  flex: 1;
  display: flex;
  padding: 0;
  margin: 0;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
  &.open {
    border-radius: 5px 5px 0 0;
  }
  &[disabled] {
    border-color: ${(props) => props.theme.colors.gray[200]};
    pointer-events: none;
    cursor: default;
    background: ${(props) => rgba(props.theme.colors.gray[50], 0.2)};
    background: ${(props) =>
      `linear-gradient(180deg, ${rgba(
        props.theme.colors.gray[50],
        0
      )} 0%, ${rgba(props.theme.colors.gray[100], 0.8)} 100%)`};
  }
`;
export const Trigger = styled(SelectTrigger)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 8px 8px 16px;
  z-index: 1;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  min-width: fit-content;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray[600]};
`;

export const Legend = styled.span`
  position: absolute;
  padding: 0;
  font-size: 14px;
  top: 8px;
  left: 14px;
  transform: scale(1.12);
  transform-origin: left center;
  color: ${(props) => props.theme.colors.gray[400]};
  cursor: pointer;
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
  &.hidden {
    position: initial;
    visibility: hidden;
  }
  &[data-selected] {
    cursor: default;
    transform: scale(1);
    color: ${(props) => props.theme.colors.gray[500]};
    top: -12px;
    left: 17px;
  }
  &.active {
    color: ${(props) => props.theme.colors.scale[500]};
  }
`;
export const Space = styled.legend`
  visibility: hidden;
  padding: 0;
  max-width: 0;
  font-size: 14px;
  width: fit-content;
  margin-left: 13px;
  height: 0px;
  line-height: 1;
  transition: all ${(props) => props.theme.animation.delay.fast}
    cubic-bezier(0.4, 0, 0.2, 1);
  &[data-selected] {
    padding: 0 5px;
    max-width: fit-content;
  }
`;

export const TriggerIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ${(props) => props.theme.animation.delay.normal}
    cubic-bezier(0.4, 0, 0.2, 1);
  &.open {
    transform: rotate(180deg);
    color: ${(props) => props.theme.colors.scale[500]};
  }
`;

export const ScrollButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.scale[300]};
  background: ${(props) => props.theme.colors.background};
  cursor: n-resize;
  padding: 8px 0;
  &:hover {
    color: ${(props) => props.theme.colors.scale[500]};
    animation: ${arrowHover} ${(props) => props.theme.animation.delay.slow}
      linear infinite;
  }
`;
export const ToUp = styled(ScrollUpButton)`
  ${ScrollButton}
  cursor: n-resize;
`;
export const ToDown = styled(ScrollDownButton)`
  ${ScrollButton}
  cursor: s-resize;
`;

export const Content = styled(SelectContent)`
  overflow: hidden;
  width: calc(var(--radix-select-trigger-width) + 2px);
  max-height: var(--radix-select-content-available-height);
  background: ${(props) => props.theme.colors.background};
  border-radius: 0 0 5px 5px;
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  border-top: none;
  box-shadow: ${(props) => props.theme.colors.background} 0px -10px 0 -1px,
    rgba(22, 23, 24, 0.35) 0px 8px 13px 0px;
`;

export const Viewport = styled(SelectViewport)`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const Title = styled(SelectTitle)`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 8px 8px 16px;
  margin-top: -8px;
  border-radius: 5px 5px 0;
  color: ${(props) => props.theme.colors.scale[600]};
  background: linear-gradient(
    0deg,
    ${(props) => rgba(props.theme.colors.scale[100], 0)} 0%,
    ${(props) => props.theme.colors.scale[100]} 45%
  );
`;

export const Separator = styled(SelectSeparator)`
  min-height: 1px;
  width: 100%;
  background: ${(props) => props.theme.colors.scale[500]};
  margin: 5px 0;
`;

export const Option = styled(Item)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.scale[500]};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  background: transparent;
  min-width: fit-content;
  padding: 8px 20px;
  position: relative;
  user-select: none;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  transition: all ${(props) => props.theme.animation.delay.normal} ease;

  &[data-highlighted] {
    outline: none;
    background: ${(props) => props.theme.colors.scale[400]};
    color: ${(props) => props.theme.colors.background};
  }
  &[data-state="checked"] {
    cursor: default;
    background: ${(props) => props.theme.colors.scale[600]};
    color: ${(props) => props.theme.colors.background};
  }
  &[data-disabled] {
    color: ${(props) => props.theme.colors.gray[200]};
    cursor: not-allowed;
  }
`;

export const Group = styled(SelectGroup)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  width: calc(100% - 12px);
  margin-left: 12px;
  position: relative;
  padding: 8px 0;
  margin: 4px;
  background: ${(props) => rgba(props.theme.colors.scale[100], 0.3)};

  ${Option} {
    margin-left: 8px;
    width: calc(100% - 16px);
    padding: 8px 16px;
  }
`;
