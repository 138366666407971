import { Gift } from "@phosphor-icons/react";
import { Card } from "../components/Card";
import { usePrinter } from "../../../../../../store/schedule/usePrinter";
import { formatMoney } from "../../../../../../services/functions";

export function Cashback() {
  const customer = usePrinter(st => st.data.customer)

  if (!customer?.cashback || !customer?.cashback.status) return null
  return (
    <>
      <Card.Highlight>
        <Gift className="h-5 w-5" />
        Cashback:
        <span className='flex-1 flex justify-end'>R$ {formatMoney(customer?.cashback?.total_bonus || 0)}</span>
      </Card.Highlight>
      <Card.Separator />
    </>
  )
}
