import { useMemo, useRef } from 'react'
import _ from "lodash";

import { MedicineController } from "../../../../../../../services/controllers/global";
import { VeterinaryPrescriptionController } from "../../../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../../../components/ui/Form/Input";
import { useMedicine } from "../../../../../../../store/global/useMedicine";

import { CustomMedicine } from './CustomMedicne';

export function FindMedicine() {
  const searchValue = useRef('')

  const CLVetPrescription = VeterinaryPrescriptionController();
  const CLMedicine = MedicineController(CLVetPrescription._medicinesId);

  const isLoading = useMedicine((st) => st.data.isLoading);
  const foundMedicines = useMedicine((st) => st.data.foundMedicines);
  const medicineData = useMedicine((st) => st.data.medicineData);

  const showCustomMedicine = useMemo(()=> !!searchValue.current && foundMedicines.length < 5,[searchValue,foundMedicines])

  if (medicineData) return null;

  return (
    <Input.Root
      label={isLoading ? "Carregando..." : "Medicamento"}
      disabled={isLoading}
      suggestions={foundMedicines}
      parser={CLMedicine._searchSuggestionParser}
      onSelected={(selected) => {
        const selectedMedicine = CLMedicine.selectMedicine(selected)
        CLVetPrescription.setMedicineDefaults(selectedMedicine)
      }}
      onChange={(name) => {
        searchValue.current = name
        CLVetPrescription.setNewMedicineProps({ name })
        CLMedicine.searchMedicine(name);
      }}
      custom={showCustomMedicine ? <CustomMedicine name={searchValue.current} /> : null}
    />
  );
}
