import _ from "lodash";
import moment from "moment";
import slugify from "slugify";
import Mustache from "mustache";
import { toast } from "react-toastify";

import { WHATSAPP_SEND_MESSAGE_BASE } from "../../../styles/constant";

import { wppTemplates } from "../../templates/wpp";
import { partials } from "../../templates/partials";
import { requestTemplates } from "../../templates/request";
import { veterinaryCareWarnings } from "../../templates/warnings/veterinaryCare";

import { clearNumber, getArticles } from "../../functions";

import { usePet } from "../../../store/global/usePet";
import { useUser } from "../../../store/global/useUser";
import { useMerchant } from "../../../store/global/useMerchant";
import { useCare } from "../../../store/service/veterinary/useCare";
import { useRequest } from "../../../store/service/veterinary/useRequest";

import { formatTemplateData } from "../../formatTemplateData/veterinay";
import { formatPreviewList } from "../../formatPreviewList/veterinaryCare";

import { VeterinaryRequestModel } from "../../models/VeterinaryCare";

import { PDF } from "../../../components/PDF";
import { Alert } from "../../../components/ui/Alert";
import { Drawer } from "../../../components/ui/Drawer";

export default class VeterinaryRequestController {
  _name = 'veterinary-request';
  _label = 'Requisições';

  _previewListName = `${this._name}-list`;

  _drawerName = `${this._name}-drawer`;
  _incompleteAlertName = `${this._name}-incomplete-alert`;

  _requiredValues = {};
  _defaultRequest = {};
  _partialsContent = {};

  _templates = requestTemplates;

  PDFRequest = PDF.request

  drawerIsOpen = Drawer.store.getState().actions.isOpen;
  alertIsOpen = Alert.store.getState().actions.isOpen;


  isUpdated = useRequest.getState().actions.isUpdated;
  setLoadedThumbnailsStatus = useRequest.getState().actions.setLoadedThumbnailsStatus;
  setLoadingStatus = useRequest.getState().actions.setLoadingStatus;

  setRequestName = useRequest.getState().actions.setRequestName;
  setRequestPath = useRequest.getState().actions.setRequestPath;
  setRequestData = useRequest.getState().actions.setRequestData;
  setRequestValues = useRequest.getState().actions.setRequestValues;
  setRequestDefaultValues = useRequest.getState().actions.setRequestDefaultValues;

  setSelectedRequest = useRequest.getState().actions.setSelectRequest;
  changeRequestPath = useRequest.getState().actions.changeRequestPath;
  changeRequestStatus = useRequest.getState().actions.changeRequestStatus;

  setRequestsList = useRequest.getState().actions.setRequests;
  updateRequestsList = useRequest.getState().actions.updateRequests;

  removeRequest = useRequest.getState().actions.removeRequest;
  returnRequest = useRequest.getState().actions.returnRequest;
  excludeRequest = useRequest.getState().actions.deleteRequest;

  constructor(orderId, saveInStore = true, label = false) {
    this._orderId = orderId;
    this._partialsContent = _.mapValues(partials, "content");

    this._saveInStore = saveInStore

    this._label = label || this._label

    this._warnings = veterinaryCareWarnings.request
    this._sendRequestTemplate = wppTemplates.draMei.sendRequest;

    this.MVetRequest = VeterinaryRequestModel(this._orderId);
  }

  init = _.once(() => {
    this.getRequests();
  });

  setName = (name) => {
    this.setRequestName(name);
  };

  selectRequest = (request) => {
    this.setSelectedRequest(request);
  };

  openDrawer = () => {
    this.drawerIsOpen(this._drawerName, true);
  };

  closeDrawer = (force = false) => {
    const isUpdated = useRequest.getState().data.request.updated;

    if (!isUpdated && !force) {
      this.alertIsOpen(this._incompleteAlertName, true);
      return;
    }

    this.clearRequest();
    this.drawerIsOpen(this._drawerName, false);
  };

  validateRequest = () => {
    const fieldsValues = useRequest.getState().data.request.requestValues;
    const requestData = useRequest.getState().data.request.requestData;
    const requestName = useRequest.getState().data.request.name;

    const article = getArticles(requestData.gender);

    if (!requestName) {
      toast.error(`Adicione um Nome para ${article[0]} ${requestData.name}`, {
        theme: "colored",
      });
      return false;
    }

    const invalidFields = _.filter(
      _.keys(this._requiredValues),
      (item) =>
        _.isNull(fieldsValues[item]) ||
        _.isUndefined(fieldsValues[item]) ||
        fieldsValues[item] === ""
    );

    invalidFields.forEach((field, i) => {
      setTimeout(() => {
        toast.error(
          `O campo ${this._requiredValues[field]} é obrigatório para criar ${article[3]} ${requestData.name}`,
          { theme: "colored" }
        );
      }, 200 * (i + 1));
    });

    return !invalidFields.length;
  };

  getRequestWarningsContent = (warningName) => {
    const pet = usePet.getState().data.pet;
    if (!pet.id) return

    const warningData = formatTemplateData.registrationWarnings(pet);

    return Mustache.render(
      this._warnings[warningName],
      warningData
    );
  }

  getThumbs = async (force = false) => {
    const isLoadedThumbnails = useRequest.getState().data.loadedThumbnails;
    const requests = useRequest.getState().data.requests;

    const requestsWithoutThumb = _.filter(requests, request => !request.thumb && request.path);

    if (!force && isLoadedThumbnails && !requestsWithoutThumb.length) return
    this.setLoadedThumbnailsStatus(true)

    const withThumbs = await formatPreviewList.thumbs(_.values(requestsWithoutThumb));

    if (this._saveInStore) this.updateRequestsList(withThumbs);
  };

  getRequests = async () => {
    const response = await this.MVetRequest.findRequests();

    if (!response) return;

    const formattedRequests = formatPreviewList.requests(response);

    if (this._saveInStore) this.setRequestsList(formattedRequests);

    return formattedRequests
  };

  clearRequest = () => {
    this.setName("");

    this.isUpdated(true);
    this.setRequestPath();
    this.setRequestData();
    this.setRequestValues();
    this.setRequestDefaultValues();
  };

  resetRequestFieldsValue = () => {
    const data = useRequest.getState().data.request.requestData;
    const partialsDefaultValue = _.mapValues(data.partials, "defaultValue");

    this.setRequestValues();
    this.setRequestValues(partialsDefaultValue);
  };

  setDefaultPreview = () => {
    const data = useRequest.getState().data.request.requestData;
    if (!data) return;

    const fieldsDefaultPreviewValues = _.mapValues(
      data.fields,
      "defaultPreview"
    );

    Object.keys(data.partials).forEach((key) => {
      _.merge(
        fieldsDefaultPreviewValues,
        _.mapValues(data.partials[key].partial.fields, "defaultPreview")
      );
    });

    this._defaultRequest = _.omitBy(fieldsDefaultPreviewValues, _.isEmpty);
  };

  setRequiredValues = () => {
    const data = useRequest.getState().data.request.requestData;
    const values = useRequest.getState().data.request.requestValues;

    if (!data) return;

    this._requiredValues = _.mapValues(
      _.pickBy(data.fields, { required: true }),
      "label"
    );

    Object.keys(data.partials).forEach((key) => {
      if (!values[key]) return;
      _.merge(
        this._requiredValues,
        _.mapValues(
          _.pickBy(data.partials[key].partial.fields, { required: true }),
          "label"
        )
      );
    });
  };


  setRequestType = (requestPath) => {
    const requestData = _.get(this._templates, requestPath);

    this.isUpdated(false);

    this.setRequestData(requestData);
    this.resetRequestFieldsValue();
    this.setRequiredValues();
    this.setDefaultPreview();
    this.setRequestPath(requestPath);
    this.setRequestDefaultValue();

  };


  setRequestDefaultValue = () => {
    const user = useUser.getState().data.user
    const merchant = useMerchant.getState().data.merchant

    const requestDefaultValue = {
        doctorCRV: user.crmv || '',
        doctorName: user.name.full,
        documentoDateDate: moment().format('DD/MM/YYYY'),
        documentoDateCity: merchant.location.city || ''
    }

    this.setRequestFieldsValue(requestDefaultValue)
  }

  setRequestFieldsValue = (values) => {
    this.setRequestValues(values);
    this.setRequiredValues();
    this.isUpdated(false);
  };

  saveRequest = async () => {
    if (!this.validateRequest()) return;
    this.setLoadingStatus(true);

    const newRequest = useRequest.getState().data.request;

    const response = await this.MVetRequest.saveRequest(newRequest);

    this.setLoadingStatus(false);
    if (!response) return;

    const formattedRequest = formatPreviewList.request(response);

    this.setRequestsList(formattedRequest, false);
    this.sendRequestFile(response.id)
    this.closeDrawer(true)

    toast.success(`A Requisição ${formattedRequest.name} foi cadastrada!`, { theme: "colored" });
  };

  sendMessage = (that = false) => {
    const care = useCare.getState().data.care
    const pet = usePet.getState().data.pet;
    const request = that || useRequest.getState().data.selectRequest;

    const ownerPhoneNumber = clearNumber(pet.owner.phone);

    const requestMessageData = formatTemplateData.sendRequest({
      pet,
      request,
      veterinarian: care.veterinarian,
    });

    const message = Mustache.render(
      this._sendRequestTemplate.content,
      requestMessageData
    );

    const wppMessageUrl = WHATSAPP_SEND_MESSAGE_BASE.replace(
      "[PHONE_NUMBER]",
      ownerPhoneNumber
    ).replace("[MESSAGE]", message);

    window.open(wppMessageUrl, "_blank");
  };

  sendRequestFile = async (id = false) => {
    const newRequest = useRequest.getState().data.request;
    const requestId = id || useRequest.getState().data.selectRequest.id;

    this.changeRequestStatus(requestId, 'generating')

    const fileName = slugify(newRequest.name)
    const file = await PDF.request.getFormData(fileName)

    const filePath = await this.MVetRequest.sendRequestFile(requestId, file);

    if (!filePath) {
      this.changeRequestStatus(requestId, 'error')
      return
    }

    this.changeRequestStatus(requestId, 'generated')
    this.changeRequestPath(requestId, filePath)

  }

  deleteRequest = async () => {
    const selectRequest = useRequest.getState().data.selectRequest;
    this.removeRequest(selectRequest.id);

    const response = await this.MVetRequest.deleteRequest(selectRequest.id);

    if (!response) {
      this.returnRequest(selectRequest.id);
      return;
    }

    this.excludeRequest(selectRequest.id);
  };
}
