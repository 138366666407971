import { TimeLineRoot } from "./TimeLineRoot";
import { TimeLineBadges } from "./TimeLineBadges";
import { TimeLineDescription } from "./TimeLineDescription";
import { TimeLineContent } from "./TimeLineContent";
import { TimeLineHeadboard } from "./TimeLineHeadboard";
import { TimeLineItem } from "./TimeLineItem";
import { TimeLineActions } from "./TimeLineActions";

export const TimeLine = {
  Root: TimeLineRoot,
  Actions: TimeLineActions,
  Badges: TimeLineBadges,
  Description: TimeLineDescription,
  Content: TimeLineContent,
  Headboard: TimeLineHeadboard,
  Item: TimeLineItem,
};
