import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  isWaiting: false,
  subscribers: [],
  details: false,
  availablePlans: false,
  cycleAppointments: false,
  filter: {
    status: null,
    size: null,
    cpf: null,
  },
  summary: {
    totalSubscribers: 0,
    totalActives: 0,
    totalOverdue: 0,
    totalCanceled: 0,
  },
  pagination: {
    page: 0,
    totalPages: 0,
    perPage: 10,
    totalDocuments: 0,
  },
};

export const useSubscribers = create((set) => ({
  data: defaultData,
  actions: {
    setFilter: (filterProps) =>
      set((state) =>
        produce(state, (draft) => {
          const filter = state.data.filter;
          draft.data.filter = { ...filter, ...filterProps };
        })
      ),
    setLoading: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isLoading = status;
          draft.data.isWaiting = status;
        })
      ),
    setWaiting: (status) =>
      set((state) =>
        produce(state, (draft) => {
          if (status === state.data.isLoading) return;
          draft.data.isWaiting = status;
        })
      ),
    setSubscribers: (subscribers, pagination) =>
      set((state) =>
        produce(state, (draft) => {
          const page =
            typeof pagination === "number" ? pagination : pagination.page;

          draft.data.subscribers[page] = subscribers;
          draft.data.pagination = pagination;

          draft.data.isLoading = false;
          draft.data.isWaiting = false;
        })
      ),
    setDetails: (subscriber = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.details = subscriber;
        })
      ),
    setCycleAppointments: (cycleAppointments = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.cycleAppointments = cycleAppointments;
        })
      ),
    setAvailablePlans: (availablePlans = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.availablePlans = availablePlans;
        })
      ),
    setSummary: (summaryData) =>
      set((state) =>
        produce(state, (draft) => {
          const summary = state.data.summary;
          draft.data.summary = { ...summary, ...summaryData };
        })
      ),

    setPaginationPage: (page) =>
      set((state) =>
        produce(state, (draft) => {

          draft.data.pagination.page = page;
        })
      ),

    clearSubscribers: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.subscribers = [];
        })
      ),
    clear: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData;
        })
      ),
  },
}));
