import {
  CustomerController,
  ScheduleController,
} from "../../services/controllers/scheduling";

import { Drawer } from "../ui/Drawer";
import { Gradient } from "../ui/Animation/Gradient";

import { IncompleteSchedule } from "./components/Alerts/IncompleteSchedule";
import { SchedulingContent } from "./SchedulingContent";
import { Title } from "./Title";

export function Scheduling() {
  const filerOptions = localStorage.getItem('@PETLAND/calendar-filter')

  const ClScheduling = ScheduleController(
    "veterinary-scheduling",
    "Clínica Veterinária"
  );

  ClScheduling.setCalendarFilter(filerOptions)
  CustomerController(ClScheduling.department);

  return (
    <>
      <Drawer.Root name={ClScheduling.drawerName} direction="left">
        <Drawer.Controls controls={{ close: ClScheduling.drawerClose }} />
        <Drawer.Header>
          <Gradient.Root name={ClScheduling.drawerGradientHeaderName} />
          <Title />
        </Drawer.Header>
        <Drawer.Content>
          <SchedulingContent />
        </Drawer.Content>
        <Drawer.Footer>
          <Gradient.Root name={ClScheduling.drawerGradientFooterName} />
        </Drawer.Footer>
      </Drawer.Root>
      <IncompleteSchedule />
    </>
  );
}
