import { ArrowUp, ArrowDown, Minus } from 'lucide-react'
import { SummaryStatusIcon as Container } from './styles'

export function SummaryStatusIcon({ status, ...rest }) {
  switch(status){
    case 'up':
      return <Container as={ArrowUp} size={35} {...rest} />
    case 'down':
      return <Container as={ArrowDown} size={35} {...rest} />
    case 'middle':
      return <Container as={Minus} size={35} {...rest} />
    default:
      return null
  }
}
