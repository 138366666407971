import { Legend, Space } from "./styles";

export function EditorLabel({ children }) {
  return (
    <>
      <Space>{children}</Space>
      <Legend>{children}</Legend>
    </>
  );
}
