import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

// moment
import moment from 'moment'
import 'moment/locale/pt-br'

import { WhatsApp, Edit } from '@material-ui/icons'

import Grid from '@material-ui/core/Grid'

import { usePets } from '../../../providers/pets'

import API from '../../../services/api'
import DrawerType from '../../../components/atoms/Drawer'
import EditCliente from '../../EditCliente'

import styles from './styles'

function User() {
  const { setCustomer: setCustomerId } = usePets()
  const { id: customerId } = useParams()
  setCustomerId(customerId)

  const [openDrawer, setOpenDrawer] = useState(false)
  const [personalData, setPersonalData] = useState(false)
  const [customer, setCustomer] = useState(null)

  const getCustomer = useCallback(async () => {
    try {
      const { data } = await API.get(`customer/${customerId}`)
      setCustomer(data)
      setPersonalData(data.personalData)
    } catch (err) {
      console.log('Erro:', err)
    }
  }, [customerId])

  const closeDrawerCustomer = () => {
    getCustomer()
    setOpenDrawer(false)
  }

  useEffect(() => {
    getCustomer()
  }, [getCustomer])

  const {
    grid,
    user,
    EditLink,
    avatar,
    userName,
    register,
    wpp,
    wppIcon,
    info,
    infoTitle,
    infoItemTitle,
    infoDescription,
    infoItem3G,
    infoItem,
    DrawerBox,
  } = styles()

  return (
    <Grid item xs={12} className={grid}>
      <DrawerType
        open={openDrawer}
        setDrowerOpen={setOpenDrawer}
        titleHeader="Editar Cliente"
        content={
          <div className={DrawerBox}>
            <EditCliente
              customerData={{
                customerId,
                customer,
                callBack: closeDrawerCustomer,
              }}
            />
          </div>
        }
      />
      <Grid item xs={3} className={user}>
        <div className={avatar}>
          {personalData && personalData.firstname.charAt(0)}
        </div>
        <span className={userName}>
          {personalData && personalData.firstname}{' '}
          {personalData && personalData.lastname}
        </span>
        <span className={register}>
          Cadastro:{' '}
          {personalData && moment(personalData.createdAt).format('DD/MM/YYYY')}{' '}
        </span>
        <a
          href={`https://api.whatsapp.com/send?phone=55${personalData.cellphone}&text=`}
          rel="noreferrer"
          target="_blank"
          className={wpp}
        >
          Chamar no WhatsApp
          <WhatsApp className={wppIcon} />
        </a>
      </Grid>
      <Grid item xs={9} className={info}>
        <h1 className={infoTitle}>
          Informações Básicas
          <span
            onClick={() => {
              setOpenDrawer(true)
            }}
            className={EditLink}
          >
            <Edit />
          </span>
        </h1>
        <div className={infoItem3G}>
          <span className={infoItemTitle}>CPF</span>
          <p className={infoDescription}>{personalData && personalData.cpf}</p>
        </div>
        <div className={infoItem3G}>
          <span className={infoItemTitle}>Telefone</span>
          <p className={infoDescription}>
            {personalData && personalData.cellphone}
          </p>
        </div>
        <div className={infoItem3G}>
          <span className={infoItemTitle}>E-mail</span>
          <p className={infoDescription}>
            {personalData && personalData.email}
          </p>
        </div>
        <div className={infoItem}>
          <span className={infoItemTitle}>Endereço</span>
          <p className={infoDescription}>
            {personalData.address?.street
              ? `${personalData?.address?.street}, ${personalData.address.number} - ${personalData.address.complement} - ${personalData.address.neighborhood}, ${personalData.address.city} - ${personalData.address.uf}`
              : 'Sem endereço cadastrado'}
          </p>
        </div>
        <div className={infoItem}>
          <span className={infoItemTitle}>Conta no Aplicativo</span>
          <p className={infoDescription}>
            {personalData && personalData.hasAccount}
          </p>
        </div>
      </Grid>
    </Grid>
  )
}

export default User
