import styled from "styled-components";
import { rgba } from "polished";

import {
  Root as SwitchRoot,
  Thumb as SwitchThumb,
} from "@radix-ui/react-switch";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Legend = styled.label`
  min-width: fit-content;
  font-size: 14px;
  padding-left: 8px;
  color: ${(props) => props.theme.colors.gray[600]};
`;

export const Root = styled(SwitchRoot)`
  all: unset;
  width: 70px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background: ${(props) => props.theme.colors.scale[100]};
  border: 1px solid ${(props) => rgba(props.theme.colors.gray[800], 0.4)};
  box-shadow: inset ${(props) => rgba(props.theme.colors.black, 0.2)} 2px 2px
    5px;
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  &[data-state="checked"] {
    background: ${(props) => props.theme.colors.scale[400]};
  }
  &[data-disabled] {
    background: ${(props) => props.theme.colors.gray[50]};
    cursor: default;
    &[data-state="checked"] {
      background: ${(props) => props.theme.colors.gray[300]};
    }
  }
`;

export const Thumb = styled(SwitchThumb)`
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translate(0px, calc(-50% - 1px));
  background: ${(props) => props.theme.colors.background};
  transition: all ${(props) => props.theme.animation.delay.fast} ease-in-out;
  box-shadow: ${(props) => rgba(props.theme.colors.black, 0.2)} 5px 5px 5px;
  border: 1px solid ${(props) => rgba(props.theme.colors.gray[800], 0.4)};
  &[data-state="checked"] {
    border-color: ${(props) => props.theme.colors.scale[500]};
    left: calc(100% - 24px);
  }
  &[data-disabled] {
    border-color: ${(props) => props.theme.colors.gray[300]};
  }
`;
