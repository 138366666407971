import _ from "lodash";
import { PartialDocumentoDate } from "../../partials/documentDate";
import { PartialDoctorSignature } from "../../partials/doctorSignature";
import { PartialResponsibleSignature } from "../../partials/responsibleSignature";

export const certificateDeath = {
  name: "Atestado de Óbito",
  title: "Atestado de Óbito",
  gender: "male",
  fields: {
    deathTime: {
      label: "Hora do Óbito",
      type: "text",
      required: true,
      defaultPreview: "__:__",
      props: {
        mask: "99:99",
      },
    },
    deathDate: {
      label: "Data do Óbito",
      type: "text",
      required: true,
      defaultPreview: "__/__/____",
      props: {
        pickDate: true,
      },
    },
    deathCause: {
      label: "Causa Mortis",
      type: "textarea",
      required: true,
      defaultPreview: _.range(50).fill("_").join(""),
      props: {
        rows: 5,
        style: { minWidth: "100%" },
      },
    },
    note: {
      label: "Observações",
      type: "textarea",
      props: {
        rows: 2,
        style: { minWidth: "100%" },
      },
    },
    removalOrientations: {
      label: "Observações de Remoção",
      type: "textarea",
      props: {
        rows: 2,
        style: { minWidth: "100%" },
      },
    },
  },
  partials: {
    documentoDate: {
      defaultValue: true,
      partial: PartialDocumentoDate,
    },
    doctorSignature: {
      defaultValue: true,
      partial: PartialDoctorSignature,
    },
    responsibleSignature: {
      defaultValue: true,
      partial: PartialResponsibleSignature,
    },
  },
  content: `
    <p>Atesto para os devidos fins que o animal identificado neste documento veio a óbito aproximadamente às <span>{{deathTime}}</span>, do dia <span>{{deathDate}}</span>, sendo a provável causa mortis:</p>
    <br />
    <p>{{{deathCause}}}</p>

    {{#responsibleSignature}}
      <br />
      <br />
      <br />
      {{>responsibleSignature}}
    {{/responsibleSignature}}

    {{#doctorSignature}}
      <br />
      <br />
      <br />
      {{>doctorSignature}}
    {{/doctorSignature}}

    {{#documentoDate}}
      <br />
      <br />
      <br />
      {{>documentoDate}}
    {{/documentoDate}}

    {{#note}}
      <br />
      <br />
      <p><strong>Outras informações complementares à provável causa mortis e informação de ter sido feita a notificação obrigatória quando for o caso:</strong></p>
      <p>{{{.}}}</p>
    {{/note}}
    {{#removalOrientations}}
      <br />
      <br />
      <p><strong>Orientações para destinação do corpo animal (aspectos sanitários e ambientais):</strong></p>
      <p>{{{.}}}</p>
    {{/removalOrientations}}
  `.trim(),
};
