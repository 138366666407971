import { darken } from 'polished'
import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  transition: all 250ms linear;
  position: sticky;
  left: 0;
  width: calc(100% - 32px);
  &.hidden {
    height: 0px;
    overflow: hidden;
    margin-bottom: 0;
  }
`

export const Controls = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;
  flex: 1;
  width: 100%;
  min-height: 70px;
  &.fixed {
    position: fixed;
    z-index: 30;
    background: #fafafa;
    width: calc(100% - 142px);
    margin: 20px 0 0 110px;
    height: initial;
    padding: 10px;
    left: 0;
    top: 0;
  }
`
export const DateBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-right: 50px;
  width: 100%;
  flex: 1;
`
export const Title = styled.h1`
  color: ${(props) => props.theme.colors.gray[400]};
  font-size: 23px;
  margin: 0;
  white-space: nowrap;
`

export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1;
`

export const ActionButton = styled.button`
  background: ${(props) => props.theme.colors.scale[400]};
  color: ${(props) => props.theme.colors.background};
  height: 40px;
  width: 80px;
  display: flex;
  max-width: ${(props) => props.size || '100%'};
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background 150ms linear;
  &:hover {
    background: ${(props) => darken(0.1, props.theme.colors.scale[500])};
  }
  &:active {
    transform: scale(0.97);
  }
  &:focus {
    position: relative;
  }
`
