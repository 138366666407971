import React from "react";
import { FileDown, Filter as FilterIcon } from "lucide-react";

import { SubscriberController } from "../../../../services/controllers/subscription";

import { useSubscribers } from "../../../../store/subscription/useSubscribers";

import { Input } from "../../../../components/ui/Form/Input";
import { Select } from "../../../../components/ui/Form/Select";
import { Filter } from "../../../../components/ui/Filter";

export function SubscribersFilter() {
  const CSubscriber = SubscriberController();

  const filter = useSubscribers((st) => st.data.filter);
  const isLoading = useSubscribers((st) => st.data.isLoading);

  const status = _.isString(filter.status) && !filter.status?'*':filter.status

  return (
    <Filter.Root onSubmit={CSubscriber.filterSubscribers}>
      <Filter.Container>
        <Select.Root
          label="Status"
          value={status}
          style={{ maxWidth: "160px" }}
          onChange={(status) => {
            CSubscriber.setFilter({ status });
          }}
        >
          <Select.Option value={"*"}>Todas</Select.Option>
          <Select.Option value={"Ativo"}>Ativas</Select.Option>
          <Select.Option value={"Cancelado"}>Canceladas</Select.Option>
          <Select.Option value={"Em atraso"}>Atrasadas</Select.Option>
        </Select.Root>

        <Select.Root
          label="Porte"
          value={filter.size}
          style={{ maxWidth: "200px" }}
          onChange={(size) => {
            CSubscriber.setFilter({ size });
          }}
        >
          <Select.Option value={"*"}>Todos</Select.Option>
          <Select.Option value={"Mini"}>Mini</Select.Option>
          <Select.Option value={"Pequeno"}>Pequeno</Select.Option>
          <Select.Option value={"Médio"}>Médio</Select.Option>
          <Select.Option value={"Grande"}>Grande</Select.Option>
          <Select.Option value={"Gigante"}>Gigante</Select.Option>
        </Select.Root>
        <Input.Root
          label="CPF"
          mask="999.999.999-99"
          style={{ maxWidth: "200px" }}
          value={filter.cpf}
          onChange={(cpf) => {
            CSubscriber.setFilter({ cpf });
          }}
        />
      </Filter.Container>
      <Filter.Actions>
        <Filter.Action isLoading={isLoading}>
          <Filter.ActionIcon icon={FilterIcon} isLoading={isLoading} />
          Filtrar
        </Filter.Action>
        <Filter.Action outline type="button" onClick={CSubscriber.reportExport}>
          <Filter.ActionIcon icon={FileDown} />
          Exportar
        </Filter.Action>
      </Filter.Actions>
    </Filter.Root>
  );
}
