import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { SCHEDULES_STATUS_MESSAGE } from "../../../store/schedule/constants";

import { ScheduleController } from "../../../services/controllers/scheduling";
import { useSchedule } from "../../../store/schedule/useSchedule";

import { formatName, getEmployeeTypeName } from "../../../services/functions";

import { Input } from "../../ui/Form/Input";
import { Select } from "../../ui/Form/Select";

import { Actions } from "../components/Actions";
import { SelectedPetCard } from "../components/SelectedPetCard";
import { Waiting } from "../components/Waiting";

import { Container, Content, FieldsBox } from "./styles";

export function InService() {
  const CLScheduling = ScheduleController();

  const appointmentId = useSchedule((st) => st.data.appointmentId);
  const details = useSchedule((st) => st.data.details);
  const sellers = useSchedule((st) => st.data.customer.sellers);
  const procedure = useSchedule((st) => st.data.procedure);
  const disabledEdit = useSchedule((st) => st.data.disabledEdit);

  const toAttending = useCallback(() => { window.location.pathname =`/clinica/atendimento/${appointmentId}`}, [appointmentId])

  return (
    <Container>
      <SelectedPetCard />
      <Content onSubmit={CLScheduling.update}>
        <FieldsBox>
          <Waiting
            color={SCHEDULES_STATUS_MESSAGE[CLScheduling.status].color}
            style={{ minWidth: "100%" }}
          >
            {SCHEDULES_STATUS_MESSAGE[CLScheduling.status].value || ""}
          </Waiting>
          <Input.Root
            label="Data"
            value={procedure.date}
            style={{ maxWidth: "300px" }}
            disabled
          />
          <Input.Root
            label="Hora de início"
            mask="99:99"
            value={procedure.time}
            style={{ minWidth: "130px" }}
            disabled
          />
          <Input.Root
            label={getEmployeeTypeName()}
            value={formatName(procedure.employee?.name)}
            style={{ minWidth: "325px" }}
            disabled
          />
          <Input.Root
            label="Procedimento"
            value={formatName(procedure.data?.name)}
            style={{ minWidth: "240px" }}
            disabled
          />
          <Select.Root
            label="Vendedor(a)"
            value={details?.seller || ''}
            styles={{ minWidth: "140px" }}
            onChange={(seller) => {
              CLScheduling.setDetails({ seller });
            }}
          >
            {sellers.map((seller) => (
              <Select.Option key={seller.id} value={seller.id}>
                {formatName(seller.name)}
              </Select.Option>
            ))}
          </Select.Root>
        </FieldsBox>
        <Actions
          reset
          actions={[{
            text: "Ver atendimento",
            onClick: toAttending
          }]}
          submit={{
            disabled: disabledEdit,
            status: "success",
            text: "Salvar Edição",
          }}
        />
      </Content>
    </Container>
  );
}
