import { toast } from "react-toastify";
import api from "../../api";

import { formatResponses } from "../../formatResponses/veterinaryCare";
export default class VeterinaryCareModel {
  _care = false

  constructor(orderId) {
    this._orderId = orderId;
  }

  findCare = async () => {
    try {
      const { data } = await api.get(`dra-mei/appointment/${this._orderId}`);

      this._care = data
      const formattedCare = formatResponses.care(data)

      return formattedCare
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  sendBudget = async () => {
    try {
      const { data } = await api.get(`dra-mei/appointment/${this._orderId}`);

      this._care = data
      const formattedCare = formatResponses.care(data)

      return formattedCare
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };

  changeCareStatus = async (status) => {
    if (!this._care) return false

    try {
      switch (status) {
        case 'start-atttending':
          await api.post(`dra-mei/appointment/${this._orderId}/start-attending`, {
            "serviceId": this._care.id
          })
          break
        case 'finish-atttending':
          await api.post(`dra-mei/appointment/${this._orderId}/finish-attending`)
          break
        case 'cancel':
          await api.post(`dra-mei/appointment/${this._orderId}/cancel`)
          break
        case 'reset':
          await api.post(`/appointments/reset-status/${this._orderId}`)
          break
        case 'send-budget':
          await api.post(`/dra-mei/appointment/${this._orderId}/send-to-vo`)
          break
        default:
          return false
      }

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  };
}
