import React from 'react'
import { Divider } from '@material-ui/core'
import { useStyles } from './style'

import { usePackge } from '../../../../hooks/EditPackageContext'

function Resumo() {
  const { Container, Title, Line, StrongLine } = useStyles()
  const {
    getPackageFinalValue,
    getPackageTotalServicesAmont,
    getPackageTotalDiscount,
  } = usePackge()

  const totalServices = getPackageTotalServicesAmont()
  const totalDeconto = getPackageTotalDiscount()
  const finalValue = getPackageFinalValue()

  return (
    <div className={Container}>
      <h1 className={Title}>Resumo do Pacote</h1>
      <p className={Line}>
        <span>Serviços</span>{' '}
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(totalServices)}
        </span>
      </p>
      <p className={Line}>
        <span>Descontos</span>{' '}
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(totalDeconto)}
        </span>
      </p>
      <Divider />
      <p className={StrongLine}>
        <span>Total</span>{' '}
        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(finalValue)}
        </span>
      </p>
    </div>
  )
}

export default Resumo
