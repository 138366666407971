import React from 'react'

// Material ui
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import API from '../../../services/api'

// Componentes
import Steps1 from './steps/step1'
import Steps2 from './steps/step2'
import Steps3 from './steps/step3'
import Steps4 from './steps/step4'

function CommissionSetting() {
  const modules = localStorage.getItem('modules')
  const modulesPerse = JSON.parse(modules)
  const [activeStep, setActiveStep] = React.useState(0)
  const completed = {}
  const [informationStep, setInformationStep] = React.useState(false)
  const [firstDay, setFirstDay] = React.useState(false)
  const [lastDay, setLastDay] = React.useState(false)
  const [resultSearchService, setResultSearchService] = React.useState([])
  const [resultSearchEmployees, setResultSearchEmployees] = React.useState([])
  const steps = ['Configurações gerais', 'Comissões']

  const handleStep = (step) => {
    setActiveStep(step)
  }

  const getSearchServices = async () => {
    try {
      const { data } = await API.get('services/search')
      setResultSearchService(data?.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const getSearchEmployees = async () => {
    try {
      const { data } = await API.get('employees/search')
      setResultSearchEmployees(data?.results)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  React.useEffect(() => {
    getSearchServices()
    getSearchEmployees()
  }, [])

  React.useEffect(() => {
    if (modulesPerse?.commission?.schedule) setActiveStep(2)
  }, [])

  return (
    <>
      {(activeStep === 0 || activeStep === 1) && (
        <Box
          style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}
        >
          <Stepper nonLinear activeStep={activeStep} style={{ width: '38%' }}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit">{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}

      {activeStep === 0 && <Steps1 handleStep={handleStep} />}

      {activeStep === 1 && (
        <Steps2
          handleStep={handleStep}
          resultSearchService={resultSearchService}
          resultSearchEmployees={resultSearchEmployees}
          activeStep={activeStep}
        />
      )}

      {activeStep === 2 && (
        <Steps3
          handleStep={handleStep}
          setInformationStep={setInformationStep}
          setFirstDay={setFirstDay}
          setLastDay={setLastDay}
          resultSearchService={resultSearchService}
          resultSearchEmployees={resultSearchEmployees}
          activeStep={activeStep}
        />
      )}

      {activeStep === 3 && (
        <Steps4
          handleStep={handleStep}
          employeeId={informationStep}
          firstDay={firstDay}
          lastDay={lastDay}
          setFirstDay={setFirstDay}
          setLastDay={setLastDay}
        />
      )}
    </>
  )
}

export default CommissionSetting
