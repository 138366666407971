import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  height: 50px;
  border-radius: 5px;
  padding: 15px 10px;
  gap: 3px;
  border: 1px dashed #d9d8d8;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Icon = styled.span`
  font-size: 70px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
`

export const Text = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  margin: 0;
`
