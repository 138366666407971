import { FilterActions } from './FilterActions'
import { FilterAction } from './FilterAction'
import { FilterActionIcon } from './FilterActionIcon'
import { FilterContainer } from './FilterContainer'
import { FilterRoot } from './FilterRoot'

export const Filter = {
  Root: FilterRoot,
  Container: FilterContainer,
  Actions: FilterActions,
  Action: FilterAction,
  ActionIcon: FilterActionIcon,
}
