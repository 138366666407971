import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Material ui - style
import { makeStyles } from '@material-ui/core/styles'

// Material ui - lab

// Material.ui
import {
    Grid,
    TextField,
    Button,
    Box,
    MenuItem
} from '@material-ui/core'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'

// moment
import moment from 'moment'
import API from '../../../services/api'
import { ReportContext } from '../../../providers/report'
import 'moment/locale/pt-br'

import { Table } from '../../ui/Table'


import {
    formatMoney,
    formatName,
    getDepartmentName,
    stringDateFormat,
} from "../../../services/functions"
import { Pagination } from '../../ui/Pagination'

moment.locale('pt-br')

// CSS
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  table: {
    minWidth: 700,
    border: '1px solid #ccd219',
  },
  tableContainer: {
    marginTop: '20px',
  },
  pagination: {
    marginTop: '20px',
  },
  filter: {
    margin: '30px 0',
    background: '#fff',
    width: '100%',
    borderRadius: '7px',
    padding: '15px',
    border: '1px solid #ccd219',
  },
  paper: {
    padding: theme.spacing(2),
    border: '1px solid #ccd219',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30px',
    '& h6': {
      marginBottom: '-8px',
      color: '#000',
    },
    '& p': {
      margin: '0px',
      fontSize: '12px',
    },
  },
}))

function RelatorioAgenda({ primeiroDia, date }) {
  const {
    deadLine,
    firstData,
    setFirstData,
    setLastData,
    setDepartment,
    getReports,
    infoRepoart,
    lastData,
    department,
    getDownloads,
    profissional,
    setEmployeeId,
    setCpf,
    setStatus,
    status,
    employeeId,
    cpf,
    paginationAgenda
  } = useContext(ReportContext)

  const classes = useStyles()
  const { control } = useForm()
  const [open, setOpen] = useState(false)
  const [messageExport, setMessageExport] = useState(null)
  const [disabledBtn, setDisabledBtn] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setDisabledBtn(false)
  }

  async function reportsExport() {
    try {
      const {
        data: { message },
      } = await API.post('reports/export', {
        filters: {
          dateFrom: firstData
            ? moment(firstData).format('YYYY-MM-DD')
            : primeiroDia,
          dateTo: lastData || moment(date).format('YYYY-MM-DD'),
          status: status === 'TODOS' ? null : status,
          cpf,
          employee: employeeId,
          department,
        },
        type: {
          name: 'appointments',
          format: 'xlxs',
        },
      })
      setMessageExport(message)
      if (message) {
        setOpen(true)
        getDownloads()
      }
    } catch (err) {
      console.log('Erro:', err)
    }
  }


  const profissionalByDepartment = !!profissional && profissional.filter((item)=>{
      if(department === "Clínica Veterinária"){
        return ["Veterinário"].includes(item.group)
      }

      if(department === "Banho e tosa"){
        return ["Tosador 1", "Banhista", "Tosador 2", "Groomer"].includes(item.group)
      }
   })

  return (
    <>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {messageExport}
          </Alert>
        </Stack>
      </Snackbar>

      {primeiroDia && (
        <Grid container spacing={3} className={classes.filter}>
          <Grid container item xs={8} spacing={2}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  defaultValue={primeiroDia}
                  name="dateInicio"
                  as={({ onChange, value }) => (
                    <TextField
                      id="date"
                      label="Inicio"
                      type="date"
                      value={value}
                      className={classes.textField}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        onChange(e.target.value)
                        setFirstData(e.target.value)
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  defaultValue={moment(date).format('YYYY-MM-DD')}
                  name="dateFim"
                  as={({ onChange, value }) => (
                    <TextField
                      id="date"
                      label="Fim"
                      type="date"
                      value={value}
                      className={classes.textField}
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        onChange(e.target.value)
                        setLastData(e.target.value)
                      }}
                      inputProps={{
                        min: firstData,
                        max: deadLine,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  defaultValue={department}
                  name="department"
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      className={classes.department}
                      select
                      value={value}
                      label="Departamento"
                      size="small"
                      variant="outlined"
                      defaultChecked={department}
                      onChange={(e) => {
                        onChange(e.target.value)
                        setDepartment(e.target.value)
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem key="Banho e Tosa" value="Banho e tosa">
                        Banho e Tosa
                      </MenuItem>

                      <MenuItem
                        key="Clínica Veterinária"
                        value="Clínica Veterinária"
                      >
                        Clínica Veterinária
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="name"
                  select
                  fullWidth
                  className={classes.department}
                  label="Profissionais"
                  variant="outlined"
                  size="small"
                  onChange={({ target }) => setEmployeeId(target.value)}
                >
                  <MenuItem key="TODOS" value="TODOS">
                    Todos
                  </MenuItem>
                  {!!profissionalByDepartment && profissionalByDepartment.map((option) => (
                    <MenuItem
                      key={option?.employeeId}
                      value={option?.employeeId}
                      selected={option?.employeeId}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  defaultValue="TODOS"
                  name="status"
                  as={({ onChange, value }) => (
                    <TextField
                      fullWidth
                      className={classes.department}
                      select
                      value={value}
                      label="Status"
                      size="small"
                      variant="outlined"
                      defaultChecked="TODOS"
                      onChange={(e) => {
                        onChange(e.target.value)
                        setStatus(e.target.value)
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem key="TODOS" value="TODOS">
                        Todos
                      </MenuItem>

                      <MenuItem key="AGENDADO" value="AGENDADO">
                        Agendado
                      </MenuItem>

                      <MenuItem key="CANCELADO" value="CANCELADO">
                        Cancelado
                      </MenuItem>

                      <MenuItem
                        key="ORÇAMENTO ENVIADO"
                        value="ORÇAMENTO ENVIADO"
                      >
                        Orçamento Enviado
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className={classes.teste}
                  fullWidth
                  name="cpf"
                  label="CPF"
                  variant="outlined"
                  size="small"
                  onChange={({ target }) => setCpf(target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: '100%' }}
              >
                <Box justifyContent="flex-end">
                  <Button
                    variant="contained"
                    onClick={() => getReports()}
                    color="primary"
                    disabled={!infoRepoart}
                    style={{
                      background: '#CCD219',
                      fontSize: '14px',
                      textTransform: 'capitalize',
                      fontWeight: '700',
                      width: '138px',
                      marginRight: '20px',
                    }}
                  >
                    Filtrar
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={disabledBtn}
                    onClick={() => {
                      reportsExport()
                      setDisabledBtn(true)
                    }}
                    style={{
                      border: '1px solid #CCD219',
                      color: '#CCD219',
                      textTransform: 'capitalize',
                      fontSize: '14px',
                      width: '120px',
                    }}
                  >
                    {disabledBtn ? 'Exportando...' : 'Exportar'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}


      <Table.Root name='table-schedules-report'>
        <Table.Header
          columns={[
            "Data:100px",
            "Profissional:minmax(100px, 1fr)",
            "Tutor:minmax(100px, 1fr)",
            "Pet:minmax(100px, 1fr)",
            "Serviço:minmax(100px, 1fr)",
            "Categoria:minmax(0px, 160px)",
            "Status:minmax(0px, 170px)",
            "Valor Original:minmax(0px, 120px)",
            "Valor Original:minmax(0px, 120px)",
            "Pacote?:minmax(0px, 80px)",
          ]}
        />
        <Table.Content>
        {!!infoRepoart && infoRepoart.map((row) => (
            <Table.Line>
              <Table.Item>{stringDateFormat(row.date)}</Table.Item>
              <Table.Item>{formatName(row.employee || '-')}</Table.Item>
              <Table.Item>{formatName(row.tutor || '-')}</Table.Item>
              <Table.Item>{formatName(row.pet || '-')}</Table.Item>
              <Table.Item>{formatName(row.serviceName || '-')}</Table.Item>
              <Table.Item>{formatName(row.serviceCategory || '-')}</Table.Item>
              <Table.Item>{formatName(row.orderStatus || '-')}</Table.Item>
              <Table.Item>R$ {formatMoney(row.servicePrice || '0')}</Table.Item>
              <Table.Item>R$ {formatMoney(row.serviceChargedPrice || '0')}</Table.Item>
              <Table.Item>{formatName(row.packagePrice || '-')}</Table.Item>
            </Table.Line>
          ))}
        </Table.Content>
        {!!paginationAgenda &&
          <Pagination.Root
            name='pagination-schedules-report'
            totalPages={paginationAgenda.totalPages - 1}
            currentPage={paginationAgenda.currentPage}
            onChange={getReports}
          >
            <Pagination.Back />
            <Pagination.Pages />
            <Pagination.Forward />
          </Pagination.Root>
        }
      </Table.Root>
    </>
  )
}

export default RelatorioAgenda
