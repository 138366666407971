import BasePage from "../../basePage";
import { ReportVaccinationsController } from "../../../services/controllers/veterinary/reports";

import { VaccinationList } from "./VaccinationList";
import { VaccinationsFilter } from "./VaccinationsFilter";

import { Container } from "./styles";

export function ReportsVaccinations() {
  const CLReportVaccinations = ReportVaccinationsController();
  CLReportVaccinations.init();

  return (
    <BasePage
      pageTitle="Vacinações"
      extendStyles={{
        main: {
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
      <Container>
        <VaccinationsFilter />
        <VaccinationList />
      </Container>
    </BasePage>
  );
}
