import styled, { keyframes, css } from 'styled-components'
import { rgba, darken } from 'polished'

const enter = keyframes`
    to{
        opacity: 1;
    }
`
const exit = keyframes`
    to{
        opacity: 0;
    }
`

export const Icon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all ease 250ms;
  grid-area: i;
  font-size: 17px;
`

export const Time = styled.div`
  grid-area: a;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  flex: 1;
  svg {
    font-size: 16px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`

export const PetInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 15px;
  flex-wrap: wrap;
`

export const Pet = styled.div`
  grid-area: b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 10px;
  flex: 1;
  width: 100%;
  color: inherit;
  &:first-letter {
    text-transform: uppercase;
  }
  span {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 5px;
    font-size: 14px;
    text-transform: capitalize;
  }
  em {
    text-transform: capitalize;
    font-style: normal;
  }
  svg {
    font-size: 16px;
    line-height: 0px;
    transform: translateY(2px);
  }
`

export const Badge = styled.div`
  grid-area: c;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  svg {
    color: ${(props) => props.theme.colors.background};
    filter: drop-shadow(
      0px 0px 2px ${(props) => props.theme.colors.scheduleSteps[props.status]}
    );
    font-size: inherit;
  }
`

export const Status = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 120%;
  grid-area: i;
  svg {
    font-size: 16px;
    margin-right: 5px;
    line-height: 0px;
  }
`

export const ErrorIcon = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.error};
`

export const Button = styled.button`
  border: none;
  cursor: pointer;
  font-size: inherit;
  background: none;
  color: ${(props) => props.theme.colors[props.color]};
`

export const Services = styled.div`
  width: 100%;
  grid-column: span 3 / span 3;
  grid-area: d;
  p {
    margin: 5px 0 0 0;
    padding-left: 10px;
  }
`

export const Description = styled.p`
  width: 100%;
  margin: 0;
  grid-column: span 3 / span 3;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px 10px;
  min-height: 20px;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 55px;
  grid-template-rows: 20px 20px 1fr;
  grid-template-areas:
    'a c '
    'b b '
    'd d ';
  padding: 0 10px;
  overflow: hidden;
  border-radius: 5px;
  background: ${(props) => rgba(props.theme.colors.error, 0.3)};
  border: 2px solid ${(props) => props.theme.colors.error};
  color: ${(props) => props.theme.colors.error};
  //--
  background: ${(props) => props.theme.colors.scheduleSteps[props.status]};
  border-color: ${(props) => props.theme.colors.scheduleSteps[props.status]};
  color: ${(props) =>
    darken(0.2, props.theme.colors.scheduleSteps[props.status])};
  box-shadow: ${(props) =>
      rgba(props.theme.colors.scheduleSteps[props.status], 0.65)}
    1.95px 1.95px 2.6px;
  opacity: 0;
  animation: ${enter} 150ms linear forwards;
  color: #fff;
  border: 0px;
  box-shadow:
    0 0 10px rgb(0 0 0 / 10%),
    inset 0 0 0 1px rgb(0 0 0 / 10%);

  &.exit {
    opacity: 1;
    animation: ${exit} 250ms linear forwards;
  }

  transition: all 500ms linear;

  &[data-disabled="true"]{
    filter: grayscale(.6);
    pointer-events: none;
    opacity: 0.75 !important;
  }

  ${(props) =>
    props.width <= 35 &&
    css`
      grid-template-rows: 20px 40px 1fr;
      ${Time} {
        svg {
          display: none;
        }
        span {
          &:last-child {
            display: none;
          }
        }
      }
      ${Pet} {
        flex-wrap: wrap;
      }
    `}
  ${(props) =>
    props.width <= 25 &&
    css`
      grid-template-columns: 1fr;
      grid-template-rows: 20px 40px 20px;
      grid-template-areas:
        'a '
        'b '
        'd ';
      ${Badge} {
        display: none;
      }
    `}
    &.sm {
  }
  &.md {
  }
  &.lg {
  }
`
