import { useContext } from "../Context";
import { MenuFormat } from "./MenuFormat";

import { MenuHeading } from "./MenuHeading";
import { MenuList } from "./MenuList";
import { MenuText } from "./MenuText";
import { MenuTextStyle } from "./MenuTextStyle";
import { MenuUndoRedo } from "./MenuUndoRedo";

import { Menu } from "./styles";

export function BubbleMenu() {
  const { editor } = useContext();
  if (!editor) return null;

  return (
    <Menu editor={editor}>
      <MenuText />
      <MenuFormat />
      <MenuTextStyle />
      <MenuList />
      <MenuHeading />
      <MenuUndoRedo />
    </Menu>
  );
}
