import styled, { css } from "styled-components";
import { rgba } from "polished";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const Box = styled.div`
  position: relative;
  width: 40px;
`;

export const ConfigContent = styled.div`
  margin: 15px 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
`;

export const ConfigAction = styled.button`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.background};
  transition: all ${(props) => props.theme.animation.delay.fast} ease;
  cursor: pointer;
  svg {
    transition: all ${(props) => props.theme.animation.delay.fast} ease;
    filter: ${(props) =>
      `drop-shadow(5px 5px 5px ${rgba(props.theme.colors.black, 0.2)})`};
  }
  &:hover {
    transform: scale(1.1);
    svg {
      transform: rotate(45deg);
      filter: ${(props) =>
        `drop-shadow(6px 6px 8px ${rgba(props.theme.colors.black, 0.2)})`};
    }
  }
  &:active {
    transform: scale(0.95);
    transform: rotate(135deg);
    svg {
      filter: ${(props) =>
        `drop-shadow( 1px 1px 4px ${rgba(props.theme.colors.black, 0.2)})`};
    }
  }
  ${(props) =>
    props.inverse &&
    css`
      color: ${(props) => props.theme.colors.scale[500]};
    `}
`;
