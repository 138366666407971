import { useMemo } from "react";
import { Eye, EyeOff } from "lucide-react";
import slugify from "slugify";

import { Table } from "../../../Table";
import { Action } from "../../../Action";

import { useContext } from "../../Context";
import { useListPreview } from "../../useListPreview";

import { ListPreviewIcon } from "../../ListPreviewIcon";

import { ItemContent } from "../ItemContent";

import {
  Container,
  IconContainer,
  Name,
  ActionContainer,
} from "./styles";

export function ListContentTable() {
  const { listPreviewName, customAction } = useContext();
  const toggleSelected = useListPreview((st) => st.actions.toggleSelected);


  const viewAction = !!customAction.open
    ? ({ item, name }) => customAction.open({ item, name }, toggleSelected)
    : toggleSelected;

  const selected = useListPreview(
    (st) => st.data.lists[listPreviewName].selected
  );

  const listData = useListPreview((st) => st.data.lists[listPreviewName].data);

  const columns = useMemo(
    () => _.union(..._.map(listData, (obj) => _.keys(obj?.informationList))),
    [listData]
  );


  return (
    <Container>
      <Table.Root name={`${listPreviewName}-table`}>
        <Table.Header columns={["#:70px", "Nome", ...columns, "##:70px"]} />
        <Table.Content>
          {!!listData &&
            listData.map((item) => (
              <Table.Line
                key={`${listPreviewName}-table-${item.id}`}
                removed={item.removed || false}
                returned={item.returned || false}
              >
                <IconContainer>
                  <ListPreviewIcon type={item.type} size={30} />
                </IconContainer>
                <Name title={item.fullName || item.name}>{item.name}</Name>
                {columns.map((column) => (
                  <Table.Item
                    key={slugify(`${listPreviewName}-table-${column}-${item.informationList[column]}`)}
                  >
                    <ItemContent content={item.informationList[column]} />
                  </Table.Item>
                ))}

                <ActionContainer>
                  <Action.Root
                    outline={selected?.id !== item.id}
                    onClick={() => viewAction({ item, name: listPreviewName })}
                    style={{ maxWidth: "fit-content" }}
                  >
                    {selected?.id === item.id && <Action.Icon icon={EyeOff} />}
                    {selected?.id !== item.id && <Action.Icon icon={Eye} />}
                  </Action.Root>
                </ActionContainer>
              </Table.Line>
            ))}
        </Table.Content>
      </Table.Root>
    </Container>
  );
}
