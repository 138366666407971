import { Grid } from '@material-ui/core'
import { TaxiDogProvider } from '../../../../hooks/TaxiDogContext'

import { useStyles } from './styles'
import { Lines } from './Lines'
import { Filter } from './Filter'

function TaxiDog({ date }) {
  const { Container } = useStyles()

  return (
    <Grid spacing={2} className={Container}>
      <TaxiDogProvider>
        <Filter date={date} />
        <Lines />
      </TaxiDogProvider>
    </Grid>
  )
}

export default TaxiDog
