import qs from "qs";
import _ from "lodash";
import { toast } from "react-toastify";

import API from "../../api";
import { Report } from "../report";

import { format } from "../../formatResponses/subscriptions";
import { useSubscribers } from "../../../store/subscription/useSubscribers";

export class Subscriber {
  setDetails = useSubscribers.getState().actions.setDetails;
  setCycleAppointments = useSubscribers.getState().actions.setCycleAppointments;
  setAvailablePlans = useSubscribers.getState().actions.setAvailablePlans;
  setSubscribers = useSubscribers.getState().actions.setSubscribers;
  setPaginationPage = useSubscribers.getState().actions.setPaginationPage;

  setLoading = useSubscribers.getState().actions.setLoading;
  setWaiting = useSubscribers.getState().actions.setWaiting;

  setFilterData = useSubscribers.getState().actions.setFilter;
  setSummary = useSubscribers.getState().actions.setSummary;

  clearSubscribers = useSubscribers.getState().actions.clearSubscribers;

  constructor() {
    this.name = "subscriptions-subscribers";

    this.dashboardType = "subscribers";
    this.reportName = `${this.name}-report`;

    this.MReport = new Report(this.reportName);


    this.setFilterData({
      summaryType: this.dashboardType,
    });
    this.setReportFilter();
  }

  setFilter(filterProps) {
    this.setFilterData(filterProps);
    this.setReportFilter();
  }

  setReportFilter() {
    const filter = useSubscribers.getState().data.filter;

    this.MReport.setFilter({
      cpf: filter.cpf || '',
      size: filter.size || '',
      status: filter.status || '',
    });
  }

  export() {
    this.MReport.download();
  }

  async setPage(page) {
    const pageExists = useSubscribers.getState().data.subscribers[page];
    this.setPaginationPage(page);

    if (!pageExists) await this.findSubscribers();
  }

  async findSummary() {
    try {
      const query = this.queryParams();
      const { data } = await API.get(`/subscriptions/store/dashboard?${query}`);

      this.setSummary({
        totalSubscribers: data.dashboard.totalSubscribers,
        totalActives: data.dashboard.totalActiveSubscribers,
        totalOverdue: data.dashboard.totalOverdueSubscribers,
        totalCanceled: data.dashboard.totalInactiveSubscribers,
      });
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async findSubscription(id) {
    try {
      const { data } = await API.get(`subscriptions/store/list/${id}`);

      const formattedSubscriber = format.subscriber(data.subscription);
      this.setDetails(formattedSubscriber)

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }
  async findAvailablePlans(petId) {
    try {
      const { data } = await API.get(`subscriptions/store/checkout/available-plans?petId=${petId}&newPlan=true`);


      const formattedAvailablePlans = format.availablePlans(data.availablePlan);

      this.setAvailablePlans(formattedAvailablePlans)

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async findCycleDetails(id) {
    try {
      const { data } = await API.get(`/subscriptions/store/realized-service/cycle/${id}`);

      const formattedCycles = format.cycles(data.cycleAppointments);
      this.setCycleAppointments(formattedCycles)

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return false;
    }
  }

  async changeCreditCard(subscriptionId, petId) {
    try {
      await API.post(`subscriptions/customer/subscriptions/card/token`, {
        subscriptionId,
        petId,
      });

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async changePlan(subscriptionId, newPlan) {
    try {
      await API.post('subscriptions/change-plan/token/new', {
        subscriptionId,
        newPlan,
      })

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async cancelCreditCard(subscriptionId) {
    try {
      await API.post(`subscriptions/cancel/${subscriptionId}/token`);

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }
  async pausePlan(subscriptionId) {
    try {
      await API.post(`subscriptions/pause/token/new`,{
        subscriptionId
      });

      return true
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      return null;
    }
  }

  async findSubscribers() {
    await this.getSubscribers();
  }

  async filterSubscribers() {
    this.setLoading(true);
    this.setPaginationPage(0);
    this.clearSubscribers();
    await this.getSubscribers();
    await this.findSummary();
    this.setLoading(false);
  }

  async getSubscribers() {
    try {
      this.setWaiting(true);
      const query = this.queryParams();

      const { data } = await API.get(`subscriptions/store/list?${query}`);

      const formattedSubscribers = format.subscribers(data.storeSubscriptions);
      const pagination = format.pagination(data.pagination);

      this.setSubscribers(formattedSubscribers, pagination);
      this.setWaiting(false);
    } catch (error) {
      toast.error(error.response.data.friendly_message, { theme: "colored" });
      this.setWaiting(false);
      return null;
    }
  }

  queryParams() {
    const filter = useSubscribers.getState().data.filter;
    const pagination = useSubscribers.getState().data.pagination;


    const objectParams = _.pickBy(
      {
        cpf: filter.cpf || null,
        type: filter.summaryType,
        size: filter.size || null,
        status: filter.status || null,
        skip: pagination.page || 0,
        limit: pagination.perPage || 10,
      },
      _.identity
    );
    return qs.stringify(objectParams);
  }
}
