import React from "react";

import { RoyaltyStatementFilter } from "./RoyaltyStatementFilter";
import { RoyaltyStatementTable } from "./RoyaltyStatementTable";

import { Container } from "./styles";

export function RoyaltyStatement() {
  return (
    <Container>
      <RoyaltyStatementFilter />
      <RoyaltyStatementTable />
    </Container>
  );
}
