import { createContext, useContext } from "react";

const TableContext = createContext({});

function TableProvider({ children, ...rest }) {
  return <TableContext.Provider value={rest}>{children}</TableContext.Provider>;
}

function useTableContext() {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error("useTable must be used within an TableProvider");
  }
  return context;
}

export { useTableContext, TableProvider };
