
import { usePet } from "../../../store/global/usePet";

import { PetModel } from "../../models/global";


export default class PetController {
  _ID = null

  setPet = usePet.getState().actions.setPet;
  updatePet = usePet.getState().actions.updatePet;

  setPetServicesPackage = usePet.getState().actions.setPetServicesPackage;

  constructor(petId) {
    if (!petId) return;
    this._ID = petId

    this.MPet = PetModel();
  }

  init = _.once(() => {
    this.startPet()
  });

  startPet = () => {
    this.getPet();
    this.getWeightHistory();
    this.getServiceHistory();
  }

  getPet = async () => {
    const pet = await this.MPet.find(this._ID);

    if (!!pet) this.setPet(pet);
  };

  getServiceHistory = async () => {
    const serviceHistory = await this.MPet.serviceHistory(this._ID);

    this.updatePet({ serviceHistory: false });
    if (serviceHistory) this.updatePet({ serviceHistory });
  };

  getWeightHistory = async () => {
    const weightHistory = await this.MPet.findWeightHistory(this._ID);

    this.updatePet({ weightHistory: false });

    if (weightHistory) this.updatePet({ weightHistory });
  };


  setServicesPackage = (department, services) => {
    this.setPetServicesPackage(department, services);
  };
}
