import _ from "lodash";

import {
  CustomerController,
  ScheduleController,
} from "../../../../../../services/controllers/scheduling";

import { Tabs } from "../../../../../ui/Tabs";
import { Steps } from "../../../../../ui/Steps";

import { Actions } from "../../../Actions";

import { Client } from "./Client";
import { Pet } from "./Pet";

import { Container, Separator } from "./styles";

export function NewClient() {
  const CLCustomer = CustomerController();
  const CLScheduling = ScheduleController();

  const setTab = Tabs.store((st) => st.actions.setActive);
  const setActive = Steps.store((st) => st.actions.setActive);

  const sendNewClient = async (e) => {
    const created = await CLCustomer.sendNewClient(e);

    if (!created) return;
    setActive(CLScheduling.stepsName, 1);
    _.delay(() => setTab(`${CLScheduling.schedulingName}-tabs`, 0), 50);
  };

  return (
    <Container onSubmit={sendNewClient}>
      <Client />
      <Separator />
      <Pet />
      <Actions show={["cancel", "next"]} next="Cadastrar" />
    </Container>
  );
}
