import React, { useContext } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Grid } from "@mui/material";
import { DashboardContext } from "../../../providers/dashboard";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

function ChartDashboard() {
  const { chart } = useContext(DashboardContext);
  const labels = chart?.map((item) => item.period.name);

  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "Agendamentos Finalizados",
        borderColor: "#518df5",
        backgroundColor: "#518df5",
        borderWidth: 2,
        fill: false,
        data: chart?.map((item) => item.metrics.appointmentsFinished),
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <Grid xs={12} item>
      <Chart type="bar" width={100} height={50} data={data} options={options} />
    </Grid>
  );
}

export default ChartDashboard;
