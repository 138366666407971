import { create } from "zustand";
import { produce } from "immer";

const defaultData = {
  isLoading: false,
  care: false,
};

export const useCare = create((set) => ({
  data: defaultData,
  actions: {
    setLoadingStatus: (status = false) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.isLoading = status;
        })
      ),
    setCare: (care = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!care) return;

          draft.data.care = care
        })
      ),
    clearCare: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.data = defaultData
        })
      ),
    setCareValues: (values = false) =>
      set((state) =>
        produce(state, (draft) => {
          if (!values) return;

          Object.keys(values).forEach((key) => {
            draft.data.care[key] = values[key];
          });
        })
      ),
  },
}));
