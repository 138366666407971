import { memo } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Download, Pill, Trash2 } from "lucide-react";

import { useHistory } from "../../../../../../store/service/veterinary/useHistory";

import { VeterinaryHistoryController } from "../../../../../../services/controllers/VeterinaryCare";

import { Accordion } from "../../../../../../components/ui/Accordion";
import { ListPreview } from "../../../../../../components/ui/ListPreview";
import { Action } from "../../../../../../components/ui/Action";


export const HistoryViewPrescriptions = memo(() => {
  const procedureData = useHistory((st) => st.data.historyProcedureData);
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const selectedProcedurePrescription = useHistory((st) => st.data.selectedProcedurePrescription[selectHistoryProcedure]);

  const CLVetHistory = VeterinaryHistoryController();

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedurePrescriptions = selectedProcedure?.prescriptions || []

  const isEnabled = procedurePrescriptions.length

  const listPreviewName = CLVetHistory._prescriptionListPreviewName + '-' + selectHistoryProcedure

  return (
    <Accordion.Item
      title="Receituário"
      animate="dance"
      icon={Pill}
      disabled={!isEnabled}
    >
      <Accordion.Content>
        <ListPreview.Root
          name={listPreviewName}
          list={procedurePrescriptions}
          onSelected={(prescription) => CLVetHistory.selectProcedurePrescription(prescription)}
          options={{
            selected: null,
            listStyle: "list",
          }}
        >
          <ListPreview.Header />
          <ListPreview.Info style={{ height: '700px' }}>
            <ListPreview.InfoActions style={{ justifyContent: "flex-end" }}>
              <Action.Root
                type="button"
                title="Baixar Receita"
                name={`download-prescription-${selectHistoryProcedure}`}
                download={{
                  path: selectedProcedurePrescription?.path,
                  name: selectedProcedurePrescription?.slug,
                }}
              >
                <Action.Icon icon={Download} size={22} />
              </Action.Root>

              <Action.Root
                type="button"
                status="success"
                title="Enviar por WhatsApp"
                name={`send-prescription-${selectHistoryProcedure}`}
                onClick={() => CLVetHistory.runProcedureAction('CPrescription', 'sendMessage', selectedProcedurePrescription)}
              >
                <Action.Icon icon={FaWhatsapp} size={22} />
              </Action.Root>
              <Action.Root
                type="button"
                status="danger"
                title="Excluir Receita"
                name={`delete-prescription-${selectHistoryProcedure}`}
                disabled
              >
                <Action.Icon icon={Trash2} size={22} />
              </Action.Root>
            </ListPreview.InfoActions>
            <ListPreview.InfoContent fit='cover' />
            <ListPreview.InfoData />
          </ListPreview.Info>
        </ListPreview.Root>
      </Accordion.Content>
    </Accordion.Item>
  );
})
