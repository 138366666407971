import { createContext, useContext as useReactContext } from "react";

const BlockTableContext = createContext({});

function BlockTableProvider({ children, ...rest }) {
  return (
    <BlockTableContext.Provider value={rest}>
      {children}
    </BlockTableContext.Provider>
  );
}

function useContext() {
  const context = useReactContext(BlockTableContext);
  if (!context) {
    throw new Error("use must be used within an Provider");
  }
  return context;
}

export { useContext, BlockTableProvider };
