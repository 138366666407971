import { twMerge } from 'tailwind-merge'
import _ from 'lodash'

export function CardRoot({ className, children, ...rest }) {
  const endSpace = localStorage.getItem('@PETLAND/printer-endSpace') ?? 2;

  return (
    <div className={twMerge('w-full space-y-4', className)} {...rest} >
      {children}
      {_.range(endSpace).map(() => <br />)}
      {!!endSpace && (
        <div className='w-full flex items-center justify-center h-[1px]'>
          <div className='w-[1px] h-[1px] border-t border-zinc-500' />
        </div>
      )}
    </div>)
}
