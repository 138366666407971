import moment from "moment";
import uuid from "react-uuid";
import {
  abbreviate,
  dateFormatedToDB,
  formatCellPhoneNumber,
  formatCpf,
  formatName,
  getAcronymName,
  getAgeByBirth,
  splitName,
} from "../functions";
import { ATTRIBUTES } from "../../styles/constant";

function formatService(service) {
  return {
    serviceId: service.serviceId || service.id,
    serviceName: service.serviceName || service.name,

    originalPrice: service.originalPrice || service.price,
    totalPrice: service.totalPrice || service.price,
    discount: service.discount || 0,

    price: service.totalPrice || service.price,

    hasPackage: service.hasPackage || false,
  };
}
function formatPetWeights(weightsDates) {
  const dates = [];
  const weights = [];

  const formattedWeights = weightsDates.map((weightData) => ({ ...weightData, id: uuid() }))

  formattedWeights.forEach((weightDate) => {
    dates.push(weightDate.date), weights.push(weightDate.weight);
  });

  return { history: formattedWeights, chartData: { dates, weights } };
}

function formatPetData(pet) {
  console.log(pet)
  const birthDates = moment(`${dateFormatedToDB(pet.birthdate)} 00:00:00`);
  const age = getAgeByBirth(birthDates);

  const customerCpf = pet.alreadyClient
    ? formatCpf(pet.customer.cpf)
    : pet.customer.cpf;

  const customerPhone = pet.alreadyClient
    ? formatCellPhoneNumber(pet.customer.phoneNumber)
    : pet.customer.phoneNumber;

  const petData = {
    petPhoto: pet.petPhoto,
    id: pet.id,
    note: pet.comments,
    tutor: {
      id: pet.customer.id,
      cpf: pet.customer.cpf,
      email: pet.customer.email,
      fullName: pet.customer.fullName,
      firstname: pet.customer.firstname,
      lastname: pet.customer.lastname,
      phoneNumber: pet.customer.phoneNumber,
    },
    badges: {
      alreadyClient: !!pet.alreadyClient,
      subscription: !!pet.subscription?.petSubscription,
      packages: !!pet.packages.services.length,
    },
    attributes: [
      { label: "Nome", value: formatName(pet.petName) },
      { label: "Nascimento", value: birthDates.format("DD/MM/YYYY") },
      { label: "Idade", value: age.formattedAge.full },
      { label: "Tutor", value: abbreviate(formatName(pet.customer.fullName)) },
      {
        label: "TutorCpf",
        value: customerCpf,
      },
      {
        label: "TutorPhone",
        value: customerPhone,
      },
      ...pet.attributes,
    ],
  };

  petData.formattedAttributes = _.reduce(
    petData.attributes,
    (result, { label, value }) => {
      const attributeName = ATTRIBUTES[label][0];

      const ownerName = formatName(pet.customer.fullName).trim();
      const ownerSplitted = splitName(ownerName);

      console.log(result.name);
      switch (attributeName) {
        case "breed":
        case "name":
          result[attributeName] = formatName(value);
          break;
        case "owner":
          result[attributeName] = {
            full: ownerName,
            first: ownerSplitted.firstname,
            last: ownerSplitted.lastname,
            abbreviate: abbreviate(ownerName),
            acronym: getAcronymName(ownerName),
          };
          break;
        case "status":
          result[attributeName] = value === "Vivo";
          break;
        case "castrated":
          result[attributeName] = value === "Sim";
          break;
        case "gender":
          result[attributeName] = value === "Macho" ? "male" : "female";
          break;

        default:
          result[attributeName] = _.toLower(value);
          break;
      }

      return result;
    },
    {}
  );

  return petData;
}

export const format = {
  pet: (pet) => formatPetData(pet),
  pets: (pets) => {
    if (!pets.length) return [];
    return pets.map((petData) => {
      const pet = {
        id: petData.pet.id,
        petPhoto: petData.pet.petPhoto,
        petName: petData.pet.name,
        alreadyClient: petData.alreadyClient,
        packages: petData.packages,
        subscription: petData.subscription,
        attributes: petData.pet.attributes,
        customer: petData.customer,
      };
      return formatPetData(pet);
    });
  },
  petWeightHistory: (weightHistory) => formatPetWeights(weightHistory),
  service: (service) => formatService(service),
  services: (services) => {
    if (!services || !services.length) return [];
    return services.map(formatService);
  },
};
