import slugify from "slugify";
import { AccordionHeader } from "./AccordionHeader";
import { useContext } from "./Context";

import { Item } from "./styles";

export function AccordionItem({
  children,
  title,
  icon,
  animate,
  disabled = false
}) {
  const context = useContext();

  const itemValue = `${context.accordionName}/${slugify(title.toLowerCase())}`;

  return (
    <Item value={itemValue} disabled={disabled}>
      <AccordionHeader.Root disabled={disabled} itemValue={itemValue}>
        {icon && <AccordionHeader.Icon icon={icon} animate={animate} />}
        {title}
      </AccordionHeader.Root>
      {children}
    </Item>
  );
}
