import styled from 'styled-components'

export const Container = styled.div``
export const Title = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(660px, 1fr));
  grid-auto-flow: dense;
  gap: 40px;
`

export const PlanTable = styled.div`
  border: 1px solid ${(props) => props.theme.colors.scale[500]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  flex: 1;
  height: 100%;
  min-width: 600px;
`
export const PlanTableHead = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[500]};
  flex: 1;
  width: 100%;
  display: grid;
  font-weight: bold;
  grid-template-columns: 100px repeat(${(props) => props.totalPlans}, 1fr);
`

export const PlanTableHeadItem = styled.div`
  flex-wrap: wrap;
  border: none;
  display: flex;
  width: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.scale[500]};
  border-left: 1px solid transparent;
  &:last-child {
    border-right: none;
    padding-right: 1px;
  }
`
export const PlanTableHeadItemTitle = styled.h3`
  padding: 8px;
  width: 100%;
  margin: 0;
  padding: 10px 10px 4px 10px;
  text-align: center;
`

export const PlanTableHeadItemDescription = styled.div`
  border: none;
  padding: 0px 8px 4px 8px;
  display: flex;
  flex: 1;
  width: 100%;
  &:last-child {
    border: none;
  }
`

export const PlanTableBody = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: 100px repeat(${(props) => props.totalPlans}, 1fr);
  flex-wrap: wrap;
`

export const PlanTableLine = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0;
  border-right: 1px solid ${(props) => props.theme.colors.scale[500]};
  &:last-child {
    border: none;
  }
`

export const PlanTableItem = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.scale[500]};
  &:last-child {
    border: none;
  }
  & > h3 {
    padding: 8px;
    margin: 0;
    font-size: 16px;
  }
  & > p {
    padding: 8px;
    margin: 0;
    flex: 1;
    border-right: 1px solid ${(props) => props.theme.colors.scale[500]};
    &:last-child {
      border: none;
    }
    strong {
      font-weight: 500;
    }
  }
`

export const PlanConfiguration = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`

export const PlanConfigurationDescription = styled.p`
  margin: 0;
`

export const PlanConfigurationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 350px;
  padding: 0;
`
export const PlanConfigurationItem = styled.li`
  list-style: none;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  min-width: fit-content;
  width: 100%;
  .fix {
    min-width: 170px;
  }
`

export const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
`

export const Warning = styled.em`
  color: ${(props) => props.theme.colors.error};
  font-size: 12px;
`
