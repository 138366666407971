
import { View } from "@react-pdf/renderer";
import Html from 'react-pdf-html';

import { autoFormatMedicineDosageInformation } from "../../../../../../services/functions";

export function Free(medicine) {
  const freeTextContent = autoFormatMedicineDosageInformation(medicine);

  const freeDosageContent = `
    <style>
    *{ margin-top: 0; margin-bottom: 0;}
    p{ font-size: 10px; }
    h1{ font-size: 16px; }
    h2{ font-size: 14px; }
    h3{ font-size: 12px; }
    ul{ list-style: circle; }
  </style>
    ${freeTextContent.replace(/<\/?ol>/g, tag => tag.replace('ol', 'ul'))}
  `;

  return (
    <View>
      <Html>
        {freeDosageContent}
      </Html>
    </View>
  );
}
