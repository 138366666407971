import styled, { css } from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-rows: 45px 1fr;
  gap: 12px;
  grid-template-areas:
    "header preview"
    "content preview";
  height: 100%;
  width: 100%;

  transition: all ${(props) => props.theme.animation.delay.normal} ease-in-out;
  ${(props) =>
    props.showFileInfo &&
    css`
      grid-template-columns: 1fr 500px;
    `}
`;
