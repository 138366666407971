import { create } from 'zustand'
import { produce } from 'immer'

export const useCalendar = create((set) => ({
  calendar: {
    dragging: false,
  },
  actions: {
    setDragging: (status) =>
      set((state) =>
        produce(state, (draft) => {
          draft.calendar.dragging = status
        }),
      ),
  },
}))
