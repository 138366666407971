import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-content: flex-start;
`;

export const Title = styled.h3`
  width: 100%;
  display: flex;
  gap: 8px;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.scale[500]};
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PreviewContent = styled.div`
  display: flex;
  width: 1px;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;

export const PreviewRequest = styled.div`
  padding: 0 20px;
  width: 100%;
  color: ${(props) => props.theme.colors.gray[900]};
  * {
    padding: 0;
    margin: 0;
  }
  p {
    margin-bottom: 12px;
  }
  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.scale[600]};
  }
  em {
    font-style: normal;
    color: ${(props) => props.theme.colors.gray[900]};
  }
  hr {
    border: none;
    height: 4px;
  }

  strong {
    color: ${(props) => props.theme.colors.scale[800]};
  }
  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .column {
    flex-direction: column;
  }
  .line {
    width: 60%;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray[800]};
  }
`;
