import { Children, cloneElement } from "react";
import { Trash2 } from "lucide-react";

import { get, useTable } from "./useTable";

import { useTableContext } from "./TableContext";

import { Config } from "../Config";
import { Action } from "../Action";

import { TableItem } from "./TableItem";

import { TableLine as Container } from "./styles";

export function TableLine({ children, id, ...rest }) {
  const { tableName } = useTableContext();

  const data = useTable((st) => st.data);

  if (!children) return null;

  const table = get.table(data.tables, tableName);
  const columnsList = get.columns(data.columns[tableName], "*");

  const columnsSize = columnsList
    .map((column) => (!column.hidden ? column.size : "0fr"))
    .join(" ");

  const columnsStyle = `${columnsSize} ${table?.config && table?.customActions ? "minmax(40px, 1fr)" : table?.config?"40px" : ""} ${table?.action ? "70px" : ""}`;

  const childrenProps = Children.map(children, (child) =>
    cloneElement(child, { tableName })
  );

  return (
    <Container columns={columnsStyle} {...rest}>
      {childrenProps}
      {!!table?.config && <Config empty />}
      {!!table?.action && (
        <TableItem>
          <Action.Root
            outline
            disabled={!table?.action.remove}
            type="button"
            status="danger"
            onClick={() =>table?.action.remove(id)}
          >
            <Action.Icon icon={Trash2} />
          </Action.Root>
        </TableItem>
      )}
    </Container>
  );
}
