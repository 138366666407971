import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import api from "../../api";

import {
  VACCINE_FIELDS,
  VACCINE_PROTOCOL_FIELDS,
} from "../../../store/service/veterinary/constants";

import {
  getArticles,
  getProtocolByVaccinesStatus,
  getScheduleStatus,
} from "../../functions";

import { useVaccine } from "../../../store/service/veterinary/useVaccine";
import { usePet } from "../../../store/global/usePet";

import { formatBody } from "../../formatBody/veterinaryCare";
import { formatPreviewList } from "../../formatPreviewList/veterinaryCare";

import { Drawer } from "../../../components/ui/Drawer";
import { Alert } from "../../../components/ui/Alert";

import {
  VeterinaryProtocolModel,
  VeterinaryVaccineModel,
} from "../../models/VeterinaryCare";


export default class VeterinaryVaccineController {
  _name = 'veterinary-vacines';
  _previewListName = `${this._name}-list`;

  _drawerNewVaccinationProtocolName = `${this._name}-new-protocol-drawer`;
  _drawerVaccinationProtocolName = `${this._name}-vaccination-protocol-drawer`;
  _drawerVaccinationProps = {};
  _drawerVaccinationToggle = () => null;

  _incompleteNewVaccinationProtocolAlertName = `${this._name}-incomplete-alert`;
  _incompleteNewProtocolAlertName = `${this._name}-incomplete-protocol-alert`;
  _removeVaccinationProtocolAlertName = `${this._name}-remove-vaccination-protocol-alert`;

  _applyVaccineAfterTodayAlertName = `${this._name}-apply-vaccine-before-today-alert`;
  _reprogrammingVaccineBeforeTodayAlertName =
    `${this._name}-reprogramming-vaccine-before-today-alert`;
  _removeVaccineAlertName = `${this._name}-remove-vaccination-of-vaccination-protocol-alert`;
  _cancelVaccinationAlertName =
    `${this._name}-cancel-vaccination-of-vaccination-protocol-alert`;

  _defaultVaccine = {
    date: moment().format("DD/MM/YYYY"),
    batch: "",
    lab: null,
    protocolId: null,
    vaccineId: null,
  };

  _requiredProtocolValues = [
    "name",
    "species",
    "frequency",
    "frequencyPeriod",
    "duration",
    "durationPeriod",
  ];

  _requiredVaccinationProtocolValues = {
    save: ["date", "protocolId", "vaccineId"],
    apply: ["batch", "lab"],
  };

  _requiredApplyVaccineValues = ["applicationDate", "lab", "batch"];

  drawerIsOpen = Drawer.store.getState().actions.isOpen;
  alertIsOpen = Alert.store.getState().actions.isOpen;

  setVaccinesList = useVaccine.getState().actions.setVaccinesList;
  setVaccineLabList = useVaccine.getState().actions.setVaccineLabList;
  setVaccineProtocolList = useVaccine.getState().actions.setVaccineProtocolList;

  setVaccineValues = useVaccine.getState().actions.setVaccineValues;
  setVaccineUpdated = useVaccine.getState().actions.isUpdated;
  setVaccineLoading = useVaccine.getState().actions.isLoading;
  setVaccineApplyLoading = useVaccine.getState().actions.isApplyLoading;

  setProtocolValues = useVaccine.getState().actions.setProtocolValues;
  setShowNewProtocol = useVaccine.getState().actions.setShowNewProtocol;
  setProtocolUpdated = useVaccine.getState().actions.setProtocolUpdated;
  setProtocolLoading = useVaccine.getState().actions.setProtocolLoading;

  setAssignedVaccines = useVaccine.getState().actions.setAssignedVaccines;
  setAssignedVaccineValues = useVaccine.getState().actions.setAssignedVaccineValues;
  updateAssignedOldVaccineValues = useVaccine.getState().actions.updateAssignedOldVaccineValues;

  setShowVaccineAlert = useVaccine.getState().actions.setShowVaccineAlert;
  setShowVaccinationProtocol = useVaccine.getState().actions.setShowVaccinationProtocol;

  setAssignedProtocols = useVaccine.getState().actions.setAssignedProtocols;
  setAssignedProtocolStatus = useVaccine.getState().actions.setAssignedProtocolStatus;
  removeAssignedProtocol = useVaccine.getState().actions.removeAssignedProtocol;

  setVaccineStatus = useVaccine.getState().actions.setVaccineStatus;
  isUpdatedVaccine = useVaccine.getState().actions.isUpdatedVaccine;

  constructor(orderId) {
    this._orderId = orderId;


    this.MVetProtocol = VeterinaryProtocolModel();
    this.MVetVaccine = VeterinaryVaccineModel(this._orderId);
  }

  init = _.once(() => {
    this.getVaccines();
    this.resetVaccineFieldsValue();
    this.getAssignedVaccinationProtocols();
  });

  setName = (name) => {
    this.setVaccineName(name);
  };

  setVaccinationProtocol = (protocolId) => {
    this.setShowVaccinationProtocol(protocolId);
  };

  openNewVaccinationProtocolDrawer = () => {
    this.drawerIsOpen(this._drawerNewVaccinationProtocolName, true);
    this.setVaccineUpdated(true);
  };

  closeNewVaccinationProtocolDrawer = (force = false) => {
    const isUpdated = useVaccine.getState().data.updated;

    if (!isUpdated && !force) {
      this.alertIsOpen(this._incompleteNewVaccinationProtocolAlertName, true);
      return;
    }

    this.resetVaccineFieldsValue();
    this.drawerIsOpen(this._drawerNewVaccinationProtocolName, false);
  };

  openVaccinationProtocolDrawer = (props, cb) => {
    this._drawerVaccinationProps = props;
    this._drawerVaccinationToggle = cb;

    this.setVaccinationProtocol(this._drawerVaccinationProps.item.id);

    this._drawerVaccinationToggle(this._drawerVaccinationProps);
    this.drawerIsOpen(this._drawerVaccinationProtocolName, true);
  };

  closeVaccinationProtocolDrawer = () => {
    this.drawerIsOpen(this._drawerVaccinationProtocolName, false);

    this._drawerVaccinationToggle(this._drawerVaccinationProps);
    this.setVaccinationProtocol(false);
  };

  openNewProtocol = () => {
    this.setShowNewProtocol(true);
    this.setProtocolUpdated(true);
  };

  closeNewProtocol = (force = false) => {
    const isUpdated = useVaccine.getState().data.newProtocolValues.updated;

    if (!isUpdated && !force) {
      this.alertIsOpen(this._incompleteNewProtocolAlertName, true);
      return;
    }

    this.resetProtocolFieldsValue();
    this.setVaccineValues({ protocolId: null });
  };

  getVaccines = async () => {
    const { data } = await api.get("vaccines");

    this.setVaccinesList(data.vaccines);
  };

  getVaccinesLabs = _.once(async (vaccineId = false) => {
    if (!vaccineId) {
      vaccineId = useVaccine.getState().data.vaccineValues.vaccineId;
    }

    const { data } = await api.get(`vaccine/${vaccineId}/labs`);

    this.setVaccineLabList(data.labs);
  });

  getVaccinesProtocols = async (vaccineId = false) => {
    const vaccineProtocolList = useVaccine.getState().data.vaccineProtocolList;

    if (!vaccineId) {
      vaccineId = useVaccine.getState().data.vaccineValues.vaccineId;
    }

    if (!vaccineId || !!vaccineProtocolList[vaccineId]) return;

    const protocols = await this.MVetProtocol.findVaccinesProtocols(vaccineId);

    this.setVaccineValues({ protocolId: null });
    this.setVaccineProtocolList(vaccineId, protocols);
  };

  getAssignedVaccinationProtocols = async (options = {}) => {
    const config = {
      protocols: true,
      vaccines: true,
      ...options,
    };

    const pet = usePet.getState().data.pet;

    const assignedVaccinationProtocols =
      await this.MVetProtocol.findAssignedVaccinationProtocol(pet.id);

    const previewListProtocols = formatPreviewList.protocols(
      assignedVaccinationProtocols.assignedProtocol
    );

    if (config.protocols) this.setAssignedProtocols(previewListProtocols);

    if (config.vaccines) {
      this.setAssignedVaccines(
        assignedVaccinationProtocols.assignedVaccinations
      );
    }
  };

  validateProtocol = () => {
    const newProtocolValues = useVaccine.getState().data.newProtocolValues;

    const invalidFields = _.filter(
      this._requiredProtocolValues,
      (item) => !newProtocolValues[item]
    );

    invalidFields.forEach((error, i) => {
      setTimeout(() => {
        toast.error(
          `O campo ${VACCINE_PROTOCOL_FIELDS[error][1]} é obrigatório para criar um protocolo de vacinação`,
          { theme: "colored" }
        );
      }, 200 * (i + 1));
    });

    return !invalidFields.length;
  };

  validateVaccinationProtocol = () => {
    const pet = usePet.getState().data.pet;
    const vaccineValues = useVaccine.getState().data.vaccineValues;

    const articles = getArticles(pet.petAttributes.gender);

    const invalidFields = _.filter(
      this._requiredVaccinationProtocolValues.save,
      (item) => !vaccineValues[item]
    );

    invalidFields.forEach((error, i) => {
      setTimeout(() => {
        toast.error(
          `O campo ${VACCINE_FIELDS[error][1]} é obrigatório para incluir um novo Protocolo de vacinação para ${articles[1]} ${pet.name}`,
          { theme: "colored" }
        );
      }, 200 * (i + 1));
    });

    return !invalidFields.length;
  };

  validateVaccinationProtocolApply = () => {
    const vaccineValues = useVaccine.getState().data.vaccineValues;

    const invalidFields = _.filter(
      this._requiredVaccinationProtocolValues.apply,
      (item) => !vaccineValues[item]
    );

    invalidFields.forEach((error, i) => {
      setTimeout(() => {
        toast.error(
          `O campo ${VACCINE_FIELDS[error][1]} é obrigatório para aplicar uma vacina`,
          { theme: "colored" }
        );
      }, 200 * (i + 1));
    });

    return !invalidFields.length;
  };

  validateVaccineApply = (vaccineId) => {
    const protocolId = useVaccine.getState().data.showVaccinationProtocol;
    const vaccines = useVaccine.getState().data.assignedVaccines[protocolId];
    const vaccine = _.find(vaccines, { id: vaccineId });

    const invalidFields = _.filter(
      this._requiredApplyVaccineValues,
      (item) => !vaccine[item]
    );

    invalidFields.forEach((error, i) => {
      setTimeout(() => {
        toast.error(
          `O campo ${VACCINE_FIELDS[error][1]} é obrigatório para aplicar uma vacina`,
          { theme: "colored" }
        );
      }, 200 * (i + 1));
    });

    return !invalidFields.length;
  };

  resetVaccineFieldsValue = () => {
    this.setVaccineValues(this._defaultVaccine);
  };

  resetProtocolFieldsValue = () => {
    this.setProtocolValues();
  };

  setVaccineFieldsValue = (values) => {
    this.setVaccineValues(values);

    this.setVaccineUpdated(false);
  };

  setProtocolFieldsValue = (values) => {
    this.setProtocolValues(values);
    this.setProtocolUpdated(false);
  };

  setAssignedVaccineFieldsValue = (vaccineId, values) => {
    this.setAssignedVaccineValues(vaccineId, values);
    this.checkUpdatedVaccine(vaccineId);
  };

  saveVaccineProtocol = async (e) => {
    if (e) e.preventDefault();
    if (!this.validateProtocol()) return;

    this.setProtocolLoading(true);

    const vaccineId = useVaccine.getState().data.vaccineValues.vaccineId;
    const newProtocol = useVaccine.getState().data.newProtocolValues;
    const currentProtocols =
      useVaccine.getState().data.vaccineProtocolList[vaccineId];

    const createdProtocol = await this.MVetProtocol.saveVaccinationProtocol(
      vaccineId,
      newProtocol
    );

    if (!createdProtocol) {
      this.setProtocolLoading(false);
      return;
    }

    const newProtocolsList = [...currentProtocols, createdProtocol];

    this.setVaccineProtocolList(vaccineId, newProtocolsList);
    this.setVaccineValues({ protocolId: createdProtocol.id });

    this.resetProtocolFieldsValue();
    this.setProtocolLoading(false);
  };

  assignVaccinationProtocol = async (e) => {
    if (e) e.preventDefault();
    if (!this.validateVaccinationProtocol()) return;

    this.setVaccineLoading(true);

    const vaccinationProtocol = useVaccine.getState().data.vaccineValues;
    const vaccinationProtocolBody =
      formatBody.addVaccinationProtocol(vaccinationProtocol);

    const data = await this.MVetProtocol.assignVaccinationProtocol(
      this._orderId,
      vaccinationProtocolBody
    );

    if (!data) {
      this.setVaccineLoading(false);
      return false;
    }

    this.getAssignedVaccinationProtocols();
    this.setVaccineLoading(false);

    if (e) this.closeNewVaccinationProtocolDrawer(true);

    return data;
  };

  assignAndApplyVaccine = async (e) => {
    if (e) e.preventDefault();
    if (!this.validateVaccinationProtocolApply()) return;

    this.setVaccineApplyLoading(true);

    const response = await this.assignVaccinationProtocol();

    if (!response) {
      this.setVaccineApplyLoading(false);

      toast.error("Não foi possível aplicar a primeira dose", {
        theme: "colored",
      });

      return false;
    }

    setTimeout(() => {
      toast.success("Salvo mas é teste", { theme: "colored" });

      this.setVaccineApplyLoading(false);
      this.closeNewVaccinationProtocolDrawer(true);
    }, 5000);
  };

  resumeVaccinationProtocol = async () => {
    this.updateProtocolStatus();

    const petId = usePet.getState().data.pet.id;
    const protocolId = useVaccine.getState().data.showVaccinationProtocol;

    const response = await this.MVetProtocol.resumeVaccinationProtocol({
      petId,
      protocolId,
    });

    if (!response) {
      this.setAssignedProtocolStatus(protocolId, "interrupted");
      return;
    }
  };

  interruptVaccinationProtocol = async (e) => {
    if (e) e.preventDefault();

    const petId = usePet.getState().data.pet.id;
    const protocolId = useVaccine.getState().data.showVaccinationProtocol;

    this.setAssignedProtocolStatus(protocolId, "interrupted");

    const response = await this.MVetProtocol.interruptVaccinationProtocol({
      petId,
      protocolId,
    });

    if (!response) {
      this.setAssignedProtocolStatus(protocolId, "scheduled");
      return;
    }
  };

  removeVaccinationProtocol = async (force = false) => {
    const petId = usePet.getState().data.pet.id;
    const protocolId = useVaccine.getState().data.showVaccinationProtocol;

    if (!force) {
      this.alertIsOpen(this._removeVaccinationProtocolAlertName, true);
      return;
    }

    const response = await this.MVetProtocol.removeVaccinationProtocol({
      petId,
      protocolId,
    });

    if (!response) return;

    this.removeAssignedProtocol(protocolId);
    this.closeVaccinationProtocolDrawer();
  };

  updateProtocolStatus = () => {
    const protocolId = useVaccine.getState().data.showVaccinationProtocol;
    const assignedVaccinationProtocolVaccine =
      useVaccine.getState().data.assignedVaccines[protocolId];

    const protocolStatus = getProtocolByVaccinesStatus(
      assignedVaccinationProtocolVaccine
    );

    this.setAssignedProtocolStatus(protocolId, protocolStatus);
  };

  changeVaccineStatus = (vaccineId, status, back = false) => {
    this.setVaccineStatus(vaccineId, status, back);
    this.checkUpdatedVaccine(vaccineId);

    this.updateProtocolStatus();
  };

  checkUpdatedVaccine = (vaccineId) => {
    if (!vaccineId) return;

    const protocolId = useVaccine.getState().data.showVaccinationProtocol;
    const vaccines = useVaccine.getState().data.assignedVaccines[protocolId];
    const oldFullData =
      useVaccine.getState().data.assignedOldVaccineValues[vaccineId];

    const vaccine = _.find(vaccines, { id: vaccineId });

    const oldValues = _.omit(oldFullData, ["back", "status", "updated"]);
    const vaccineValues = _.omit(vaccine, ["back", "status", "updated"]);

    if (!oldFullData) {
      console.log("sem valor antigo", true);
      this.isUpdatedVaccine(vaccineId, true);
      return;
    }

    if (_.isEqual(oldValues, vaccineValues)) {
      console.log("valores iguais", true);
      this.isUpdatedVaccine(vaccineId, true);
      return;
    }

    console.log("fim", false);
    this.isUpdatedVaccine(vaccineId, false);
  };

  reprogramVaccinePrepare = (vaccine, force = false) => {
    if (vaccine.status !== "reprogramming") return true;

    const scheduleStatus = getScheduleStatus(vaccine.scheduledDate);

    if (!force && scheduleStatus.daysGap < 0) {
      this.setShowVaccineAlert(vaccine.id);
      this.alertIsOpen(this._reprogrammingVaccineBeforeTodayAlertName, true);
      return false;
    }

    this.changeVaccineStatus(vaccine.id, "scheduled");
    this.setShowVaccineAlert();
    return true;
  };

  applyVaccinePrepare = (vaccine, force = false) => {
    if (vaccine.status !== "applying") return true;

    const scheduleStatus = getScheduleStatus(vaccine.scheduledDate);

    if (!force && scheduleStatus.daysGap > 0) {
      this.setShowVaccineAlert(vaccine.id);
      this.alertIsOpen(this._applyVaccineAfterTodayAlertName, true);
      return false;
    }

    if (!this.validateVaccineApply(vaccine.id)) return false;

    this.setShowVaccineAlert();
    this.changeVaccineStatus(vaccine.id, "applied");
    this.applyVaccine(vaccine.id);
    return true;
  };

  applyVaccine = async (vaccineId) => {
    this.changeVaccineStatus(vaccineId, "applied");

    const response = await this.MVetVaccine.sendVaccineAction(
      vaccineId,
      "apply"
    );

    if (!response) {
      this.changeVaccineStatus(vaccineId, "scheduled");
      return;
    }
  };

  cancelVaccine = async (vaccineId, force = false) => {
    if (!force) {
      this.setShowVaccineAlert(vaccineId);
      this.alertIsOpen(this._cancelVaccinationAlertName, true);
      return;
    }

    this.setShowVaccineAlert();
    this.changeVaccineStatus(vaccineId, "removed");

    const response = await this.MVetVaccine.sendVaccineAction(
      vaccineId,
      "cancel"
    );

    if (!response) {
      this.changeVaccineStatus(vaccineId, "scheduled");
      return;
    }
  };

  removeVaccine = async (vaccineId, force = false) => {
    if (!force) {
      this.setShowVaccineAlert(vaccineId);
      this.alertIsOpen(this._removeVaccineAlertName, true);
      return;
    }

    this.setShowVaccineAlert();
    this.isUpdatedVaccine(vaccineId, false);
    this.changeVaccineStatus(vaccineId, "scheduled");

    const response = await this.MVetVaccine.sendVaccineAction(
      vaccineId,
      "remove"
    );

    if (!response) {
      this.isUpdatedVaccine(vaccineId, true);
      this.changeVaccineStatus(vaccineId, "applied");
      return;
    }
  };

  saveChangesVaccineValues = async (vaccineId, force = false) => {
    const protocolId = useVaccine.getState().data.showVaccinationProtocol;
    const assignedVaccines = useVaccine.getState().data.assignedVaccines;

    const newData = _.find(assignedVaccines[protocolId], { id: vaccineId });

    if (newData.updated && newData.status !== "applying") {
      this.changeVaccineStatus(vaccineId, newData.back);
      return;
    }

    if (!this.reprogramVaccinePrepare(newData, force)) return;
    if (!this.applyVaccinePrepare(newData, force)) return;

    if (newData.status === "editing") {
      this.changeVaccineStatus(vaccineId, newData.back);
    }

    const actionBody = {
      action: "edit",
      data: formatBody.changeVaccine(newData),
    };

    this.isUpdatedVaccine(vaccineId, true);
    this.updateAssignedOldVaccineValues(vaccineId);

    const response = await this.MVetVaccine.sendVaccineAction(
      vaccineId,
      actionBody
    );

    if (!response) {
      this.changeVaccineStatus(vaccineId, newData.back);
      this.isUpdatedVaccine(vaccineId, false);
    }
  };
}
