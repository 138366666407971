import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Material ui - style
import { makeStyles } from '@material-ui/core/styles'

// Material ui - core

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Drawer from '@mui/material/Drawer'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { DeleteForever } from '@material-ui/icons'
import EditIcon from '@mui/icons-material/Edit'
import Alert from '@mui/material/Alert'
import {
  Button,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  Tab,
  Tabs,
} from '@material-ui/core'

// Material ui - lab
import Skeleton from '@material-ui/lab/Skeleton'
import ModalType from '../../atoms/Modal'
import sms from '../../../assets/sms.png'
import BasePage from '../../../pages/basePage'
import API from '../../../services/api'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 280,
    marginBottom: '20px',
    marginTop: '20px',
    border: '1px solid #ccd219',
  },
  input: {
    marginLeft: theme.spacing(1),
    padding: 5,
    flex: 1,
  },
  table: {
    minWidth: 300,
    border: '1px solid #ccc',
  },
  footer: {
    position: 'fixed',
    bottom: '0',
    background: '#D7D7D7',
    padding: '20px',
    width: '824px',
  },
  contentButton: {
    width: '800px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonEnd: {
    backgroundColor: '#CCD219',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #CCD219',
      color: '#CCD219',
    },
  },
  sms: {
    height: '45px',
  },
  inputSms: {
    '& input': {
      padding: '7px',
    },
  },
}))

const arrayNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '10']

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '50px',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#00567e',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

function Vacina({ service }) {
  const value = '0'
  const [valueInfo, setValueInfo] = useState(0)
  const [protocol, setProtocol] = useState()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [statusDrawer, setStatusDrawer] = useState('')
  const [infoDrawer, setInfoDrawer] = useState(null)
  const [sw, setSwitch] = useState(false)
  const [idVacina, setIdvacina] = useState(false)
  const [vacinaSelected, setVacinaSelected] = useState('')
  const [disabledButton, setDisabledButton] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(null)
  const [message, setMessage] = React.useState(false)
  const [snackbar, setSnackbar] = React.useState(false)
  const [errMsg, setErrMsg] = React.useState(false)

  const { control, getValues } = useForm({})

  const getProtocols = async (id) => {
    setValueInfo(1)
    setIdvacina(id)
    setVacinaSelected(service.find((s) => s.id === id)?.name)
    try {
      const { data } = await API.get(`vaccine/${id}/protocols`)
      setProtocol(data.protocols)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const postProtocols = async () => {
    const getValue = getValues()
    setDisabledButton(true)
    const payload = {
      name: getValue.Nome,
      specie: getValue.Espécie,
      doses: getValue.Protocolo,
      intervalBetweenDoses: getValue.Dias,
      sendSms: sw,
      daysBeforeApplicationToSendSMS: getValue.numberSms,
    }

    try {
      const result = await API.post(`vaccine/${idVacina}/protocol`, payload)
      if (result?.status === 201) {
        getProtocols(idVacina)
        setMessage(result?.data?.message)
        setSnackbar(true)
        setDisabledButton(false)
      }
    } catch (err) {
      setSnackbar(true)
      setMessage('error')
      setDisabledButton(false)
      setErrMsg(err?.response?.data?.friendly_message)
      console.log('Erro:', err)
    }
  }

  const postProtocolsEdit = async () => {
    setDisabledButton(true)
    const getValue = getValues()
    const payload = {
      name: getValue.Nome,
      specie: getValue.Espécie,
      doses: getValue.Protocolo,
      intervalBetweenDoses: getValue.Dias,
      sendSms: sw,
      daysBeforeApplicationToSendSMS: getValue.numberSms,
    }

    try {
      const result = await API.post(
        `vaccines/update-protocol/${infoDrawer._id}`,
        payload,
      )
      if (result?.status === 201) {
        getProtocols(idVacina)
        setMessage(result?.data?.message)
        setSnackbar(true)
        setDisabledButton(false)
      }
    } catch (err) {
      setSnackbar(true)
      setMessage('error')
      setErrMsg(err?.response?.data?.friendly_message)
      setDisabledButton(false)
      console.log('Erro:', err)
    }
  }

  const handleDelete = async () => {
    try {
      const { data } = await API.delete(
        `vaccines/delete-protocol/${infoDrawer._id}`,
      )
      if (data?.message === 'Protocolo removido') {
        setOpenModal(false)
        getProtocols(idVacina)
      }
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const handleChangeSw = (event) => {
    setSwitch(event.target.checked)
  }

  function clickDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const classes = useStyles()

  return (
    <BasePage
      pageTitle={
        valueInfo === 0 ? 'Protocolos' : `Protocolo - ${vacinaSelected}`
      }
    >
      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={() => {
          setSnackbar(false)
        }}
      >
        <Alert severity={message === 'error' ? 'error' : 'success'}>
          {message === 'error' ? errMsg : message}
        </Alert>
      </Snackbar>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
        style={{
          boxShadow: '0 6px 20px rgb(0 0 0 / 10%)',
          borderRadius: '7px',
        }}
      >
        <Tab label="Protocolos" value="0" />
      </Tabs>

      {valueInfo === 0 && (
        <TableContainer elevation={0}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
            style={{ marginTop: '30px' }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Vacinas</StyledTableCell>
                <StyledTableCell align="center" />
              </TableRow>
            </TableHead>

            {/* SKELETON */}
            {service ? (
              <TableBody>
                {service.map((row) => (
                  <StyledTableRow
                    key={row.name}
                    onClick={() => getProtocols(row.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">Ver Protocolos</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}

      {valueInfo === 1 && (
        <>
          <Drawer anchor="right" open={drawerOpen} onClose={clickDrawer}>
            <div
              style={{
                background: 'rgb(204, 210, 25)',
                color: '#fff',
                padding: '10px',
                fontSize: '18px',
              }}
            >
              {statusDrawer}
            </div>
            <div className={classes.content} style={{ padding: '20px' }}>
              <Grid container spacing={2} style={{ width: '800px' }}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="Nome"
                    defaultValue={infoDrawer?.name}
                    as={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        type="text"
                        name="Nome"
                        value={value}
                        label="Nome"
                        variant="outlined"
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="Espécie"
                    defaultValue={infoDrawer?.specie}
                    as={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        select
                        value={value}
                        label="Espécie"
                        variant="outlined"
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                      >
                        <MenuItem key="Cão" value="Cão">
                          Cão
                        </MenuItem>
                        <MenuItem key="Gato" value="Gato">
                          Gato
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ width: '800px', marginTop: '25px' }}
              >
                <Grid
                  item
                  xs={5}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Controller
                    control={control}
                    name="Protocolo"
                    defaultValue={infoDrawer?.doses}
                    as={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        select
                        value={value}
                        label="Protocolo"
                        variant="outlined"
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                      >
                        {arrayNumber.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <span style={{ paddingLeft: '15px', marginRight: '20px' }}>
                    Doses
                  </span>
                </Grid>

                <Grid
                  item
                  xs={5}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Controller
                    control={control}
                    name="Dias"
                    defaultValue={infoDrawer?.intervalBetweenDoses}
                    as={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        type="number"
                        name="Dias"
                        value={value}
                        label="Aplicar a cada"
                        variant="outlined"
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                      />
                    )}
                  />
                  <span style={{ paddingLeft: '15px', marginRight: '20px' }}>
                    Dias
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ width: '800px', marginTop: '25px' }}
              >
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img
                    src={sms}
                    alt="SMS"
                    title="SMS"
                    className={classes.sms}
                  />
                  <span style={{ paddingLeft: '15px' }}>
                    Enviar SMS/Email para o cliente
                  </span>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Controller
                    control={control}
                    name="numberSms"
                    defaultValue={
                      infoDrawer?.alertRules[0]
                        ?.daysBeforeApplicationToSendMessage
                    }
                    as={({ onChange, value }) => (
                      <TextField
                        fullWidth
                        className={classes.inputSms}
                        type="number"
                        name="numberSms"
                        value={value}
                        variant="outlined"
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span style={{ paddingLeft: '15px' }}>
                    dias antes da próxima aplicação
                  </span>
                </Grid>

                <Grid
                  item
                  xs={3}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        defaultValue={infoDrawer?.alertRules[0]?.active}
                        defaultChecked={infoDrawer?.alertRules[0]?.active}
                        onChange={handleChangeSw}
                        color="primary"
                        name="ativo"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.footer}>
              <Grid container spacing={2} className={classes.contentButton}>
                <Grid item xs={8} style={{ textAlign: 'center' }}>
                  <Button
                    color="primary"
                    onClick={() => setDrawerOpen(false)}
                    style={{
                      fontSize: '13px',
                      color: '#ccd219',
                      border: '1px solid #ccd219',
                      backgroundColor: '#fff',
                      padding: '10px 50px',
                      marginRight: '22px',
                    }}
                  >
                    Cancelar
                  </Button>

                  <Button
                    color="primary"
                    disabled={disabledButton}
                    style={{
                      fontSize: '13px',
                      color: '#fff',
                      border: '0',
                      backgroundColor: '#ccd219',
                      padding: '10px 50px',
                    }}
                    onClick={() =>
                      statusDrawer === 'Editar Protocolo'
                        ? postProtocolsEdit()
                        : postProtocols()
                    }
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Drawer>

          <ModalType
            open={openModal}
            close={closeModal}
            titleDescription={`Deseja excluir o protocolo ${infoDrawer?.name}?`}
            handleClick={handleDelete}
            err
            verification
            oneButton={false}
          />
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px 0px',
            }}
          >
            <Grid item xs={9}>
              <Button
                className={classes.buttonEnd}
                variant="contained"
                color="primary"
                onClick={() => setValueInfo(0)}
              >
                Voltar
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Button
                className={classes.buttonEnd}
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                justify="center"
                alignItems="center"
                onClick={() => {
                  setStatusDrawer('Adicionar Protocolo')
                  setInfoDrawer(null)
                  setDrawerOpen(true)
                }}
              >
                + Adicionar protocolo
              </Button>
            </Grid>
          </Grid>

          <TableContainer elevation={0}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                {protocol?.length > 0 ? (
                  <TableRow>
                    <StyledTableCell>Vacinas</StyledTableCell>
                    <StyledTableCell>Espécie</StyledTableCell>
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center" />
                  </TableRow>
                ) : (
                  <TableRow>
                    <StyledTableCell
                      align="center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setStatusDrawer('Adicionar Protocolo')
                        setInfoDrawer(null)
                        setDrawerOpen(true)
                      }}
                    >
                      Opss... Sem protocolo cadastrado, clique aqui para
                      adicionar
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableHead>

              {/* SKELETON */}
              {protocol ? (
                <>
                  {protocol?.length > 0 && (
                    <TableBody>
                      {protocol.map((row) => (
                        <StyledTableRow key={row.name}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ width: 200 }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.specie}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ width: 60, cursor: 'pointer' }}
                            onClick={() => {
                              setDrawerOpen(true)
                              setStatusDrawer('Editar Protocolo')
                              setInfoDrawer(row)
                            }}
                          >
                            <EditIcon style={{ color: '#00567e' }} />
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ width: 60, cursor: 'pointer' }}
                          >
                            <DeleteForever
                              style={{ color: '#C62C2C' }}
                              onClick={() => {
                                setInfoDrawer(row)
                                setOpenModal(true)
                              }}
                            />
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                </>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </BasePage>
  )
}

export default Vacina
