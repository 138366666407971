import { isAbleByStatus } from "../../../../../services/functions";

import { useCare } from "../../../../../store/service/veterinary/useCare";
import { useRecord } from "../../../../../store/service/veterinary/useRecord";

import { VeterinaryRecordController } from "../../../../../services/controllers/VeterinaryCare";

import { Editor } from "../../../../../components/ui/Form/Editor";

import { DIAGNOSIS_DEFAULT_CONTENT } from "./editorValues";

import { Container } from "./styles";

export function RecordsContentsDiagnosis() {
  const careStatus = useCare(st => st.data.care.status)

  const diagnosis = useRecord((st) => st.data.record?.diagnosis);
  const isLoading = useRecord((st) => st.data.isLoading);

  const CLVetRecord = VeterinaryRecordController();

  const isAble = isAbleByStatus(careStatus,CLVetRecord._name)

  return (
    <Container>
      <Editor
        label="Diagnóstico"
        content={diagnosis}
        disabled={isLoading || !isAble}
        placeholder={DIAGNOSIS_DEFAULT_CONTENT}
        onChange={(diagnosis) => {
          CLVetRecord.changeRecordValue({ diagnosis });
          CLVetRecord.saveDiagnosis()
        }}
      />
    </Container>
  );
}
