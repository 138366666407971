import { useState } from 'react'

import DrawerType from '../../../../components/atoms/Drawer'
import PreScheduling from '../../../../components/organisms/PreScheduling'

import { ActionButton } from '../styles'

export function PreSchedulingButton() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <DrawerType
        open={isOpen}
        setDrowerOpen={setIsOpen}
        content={<PreScheduling />}
      />
      <ActionButton style={{ width: '150px' }} onClick={() => setIsOpen(true)}>
        Pré Agendamento
      </ActionButton>
    </>
  )
}
