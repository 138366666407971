import { StartsChartList } from './StartsChartList'
import { StartsAverage } from './StartsAverage'
import { StartsTag } from './StartsTag'

import { Title } from '../styles'
import { Container, StartsBox } from './styles'

export function Stars() {
  return (
    <Container xs={6}>
      <Title>Estrelas</Title>
      <StartsBox>
        <StartsAverage />
        <StartsChartList />
        <StartsTag />
      </StartsBox>
    </Container>
  )
}
