import React from "react";

import { SubscribersFilter } from "./SubscribersFilter";
import { SubscribersSummary } from "./SubscribersSummary";
import { SubscribersTable } from "./SubscribersTable";

import { SubscriptionDetails } from "./Drawer/SubscriptionDetails";

import { Container } from "./styles";
import { Alerts } from "./Alerts";

export function Subscribers() {
  return (
    <>
      <Container>
        <SubscribersFilter />
        <SubscribersSummary />
        <SubscribersTable />
      </Container>
      <Alerts />
      <SubscriptionDetails />
    </>
  );
}
