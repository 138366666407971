import { Save, Undo2 } from "lucide-react";

import { VeterinaryRequestController } from "../../../../../../services/controllers/VeterinaryCare";
import { useRequest } from "../../../../../../store/service/veterinary/useRequest";

import { Input } from "../../../../../../components/ui/Form/Input";
import { Action } from "../../../../../../components/ui/Action";

import { RequestSelect } from "./RequestSelect";
import { Container, FormActions } from "./styles";
import { RequestFields } from "./RequestFields";

export function RequestForm() {
  const CLVetRequest = VeterinaryRequestController();

  const name = useRequest((st) => st.data.request.name);
  const isLoading = useRequest((st) => st.data.isLoading);
  const isUpdated = useRequest((st) => st.data.request.updated);

  return (
    <Container>
      <Input.Root label="Nome" value={name} onChange={CLVetRequest.setName} />
      <RequestSelect />
      <RequestFields />
      <FormActions>
        <Action.Root
          outline
          status="danger"
          type="button"
          onClick={() => CLVetRequest.closeDrawer()}
        >
          <Action.Icon icon={Undo2} />
          Cancelar
        </Action.Root>
        <Action.Root
          status="success"
          type="button"
          disabled={isUpdated}
          isLoading={isLoading}
          onClick={CLVetRequest.saveRequest}
        >
          <Action.Icon icon={Save} isLoading={isLoading} />
          Salvar Requisição
        </Action.Root>
      </FormActions>
    </Container>
  );
}
