import { Input } from "../../../../../../../components/ui/Form/Input";

import { useDre } from "../../../../../../../store/subscription/useDre";
import { SubscriptionDREController } from "../../../../../../../services/controllers/subscription";
import { clearNumber, formatMoney } from "../../../../../../../services/functions";
import _ from "lodash";

export function Expense({ id, data }){
  const CLDre = SubscriptionDREController()

  const period = useDre(st => st.data.config.period)
  const isLoading = useDre(st => st.data.config.loading)
  const isSaving = useDre(st => st.data.config.saving)
  const expenses = useDre(st => st.data.config.expenses)

  const validDate = _.size(clearNumber(period)) === 6

  const parseValue = (value = '') =>{
    if(isLoading) return 'Carregando...'
    if(!validDate) return ''
    if(!value) return ''
    return value
  }

  if(!data.id){
    return (
      <div className="flex flex-wrap gap-y-4 gap-x-2 w-full">
        {Object.entries(data).map(([key, expenseData]) =>(
         <div key={key} className="flex-1 w-full min-w-[300px]">
            <Input.Root
              disabled={isLoading || isSaving || !expenses[period] || !validDate}
              value={parseValue(!!expenses[period] && expenses[period][key]?.value)}
              onChange={(value) => CLDre.setConfigExpense(key, value)}
              {...expenseData}
            />
            <span className="text-sm text-zinc-400 pl-1">Valor padrão: {expenseData.prefix || ''}{!!expenses[period] && formatMoney(expenses[period][key]?.default, expenseData.decimals)}{expenseData.suffix || ''}</span>
          </div>
        ))}
      </div>
    )
  }


  return (
    <div className="flex-1 w-full min-w-[300px]">
      <Input.Root
        disabled={isLoading || isSaving || !expenses[period] || !validDate}
        value={parseValue(!!expenses[period] && expenses[period][id]?.value)}
        onChange={(value) => CLDre.setConfigExpense(id, value)}
        {...data}
      />
      <span className="text-sm text-zinc-400 pl-1">Valor padrão: {data.prefix || ''}{!!expenses[period] && formatMoney(expenses[period][id]?.default, data.decimals)}{data.suffix || ''}</span>
    </div>
  )
}
