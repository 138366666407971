import { rgba } from "polished";
import styled, { css } from "styled-components";

export const PetAvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const PetAvatarAcronym = styled.span`
  font-size: 50px;
  font-weight: bold;
  color: #ffff;
`;

export const PetAvatarBox = styled.div`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  border: 4px solid ${(props) => props.theme.colors.gray[300]};
`;

export const PetAvatar = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: ${(props) => props.theme.colors.scale[200]};
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 5px;
  border: 1px solid ${(props) => props.theme.colors.gray[300]};
  transition: all ease ${(props) => props.theme.animation.delay.normal};
  &.Morto {
    filter: grayscale(1);
  }
`;

export const PetAttribute = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  min-width: min-content;
  color: ${(props) => props.theme.colors.gray[600]};
  margin: 0px;
  transform-origin: left;
  transition: all ease ${(props) => props.theme.animation.delay.normal};
  position: relative;
  &::after {
    content: "";
    width: 0%;
    height: 1px;
    background: currentColor;
    position: absolute;
    left: 0;
    transform-origin: left;
    transition: all ease ${(props) => props.theme.animation.delay.normal};
    top: 100%;
  }
  &.Nome{
    font-weight: bold;
    color: ${(props) => props.theme.colors.gray[600]};
  }
  &.Tutor {
    font-weight: normal;
    max-width: initial;
    color: ${(props) => props.theme.colors.gray[800]};
  }
`;

export const PetAttributes = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 10px;
  flex: 1;
  flex-direction: ${(props) => props.direction || "row"};
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};
`;

export const PetAttributeValue = styled.span`
  display: flex;
  align-items: baseline;
  gap: 2px;
`;
export const PetAttributeSuffix = styled.em`
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.gray[400]};
`;

export const PetAttributeIconBox = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.gray[700]};
  flex-direction: column;
  & > *:not(:first-child) {
    margin-top: -14px;
  }
`;
export const PetBadges = styled.div`
  width: 100%;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  color: ${(props) => props.theme.colors.scale[500]};
  flex-direction: ${(props) => props.direction || "row"};

  ${(props) =>
    props.direction === "column" &&
    css`
      width: fit-content;
    `}
  ${(props) =>
    props.absolute &&
    css`
     position: absolute;;
    `}
`;

export const PetNote = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 250px;
  min-width: 150px;
  color: ${(props) => props.theme.colors.gray[600]};
  font-size: 100%;
`;

export const PetTitle = styled.strong`
  font-size: 110%;
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.scale[500]};
`;

export const Container = styled.button`
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 0%;
  align-content: flex-start;
  position: relative;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 6px 16px;
  min-width: 340px;
  max-width: 600px;
  background: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => rgba(props.theme.colors.gray[100], 0.3)};
  border-radius: 10px;
  padding: 16px 18px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  font-size: 16px;
  &[data-full] {
    max-width: 100%;
  }
  flex-direction: ${(props) => props.direction || "row"};
  ${(props) =>
    props.noBubble &&
    css`
      box-shadow: none;
      background: none;
      border: none;
    `}
  ${(props) =>
    props.fit &&
    css`
      min-width: min-content !important;
    `}
  ${(props) =>
    props.min &&
    css`
      max-width: fit-content !important;
      min-width: min-content !important;
    `}
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${(props) =>
    props.direction === "column" &&
    css`
      align-content: initial;
    `}

  ${(props) =>
    props.as !== "div" &&
    css`
      cursor: pointer;
      &:hover {
        ${PetAvatar} {
          transform: scale(1.1);
        }
      }
    `}
  &.small {
    min-width: 300px;
    font-size: 14px;
    ${PetAvatarBox} {
      border-width: 2px;
    }
    ${PetAvatar} {
      width: 80px;
      height: 80px;
    }
    ${PetAvatarAcronym} {
      font-size: 30px;
    }
    ${PetAttributes} {
      gap: 5px 10px;
    }
  }

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;
