import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'

// Material ui - core
import {
  MenuItem,
  Grid,
  Backdrop,
  Modal,
  Fade,
  Divider,
} from '@material-ui/core'

// Material ui - icone
import RepeatIcon from '@material-ui/icons/Repeat'
import CancelIcon from '@material-ui/icons/Cancel'

// Time
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ptBR from 'date-fns/locale/pt-BR'

moment.locale('pt-br')

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '40%',
  },
  repeatButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '17px',
    cursor: 'pointer',

    '& span': {
      paddingLeft: '10px',
    },
  },
  button: {
    backgroundColor: '#a0a510',
    margin: '8px',
  },
  buttonOutlined: {
    margin: '8px',
    color: '#a0a510',
    border: '1px solid #a0a510',
  },
}))

const frequency = {
  frequency: [
    { name: 'Não se repete', intervalBase: null, occurrences: null },
    { name: 'Diariamente', intervalBase: 'days', occurrences: 1 },
    { name: 'A cada 2 dias', intervalBase: 'days', occurrences: 2 },
    { name: 'A cada 3 dias', intervalBase: 'days', occurrences: 3 },
    { name: 'A cada 4 dias', intervalBase: 'days', occurrences: 4 },
    { name: 'A cada 5 dias', intervalBase: 'days', occurrences: 5 },
    { name: 'A cada 6 dias', intervalBase: 'days', occurrences: 6 },
    { name: 'A cada 7 dias', intervalBase: 'days', occurrences: 7 },
    { name: 'Semanalmente', intervalBase: 'weeks', occurrences: 1 },
    { name: 'A cada 2 semanas', intervalBase: 'weeks', occurrences: 2 },
    { name: 'A cada 3 semanas', intervalBase: 'weeks', occurrences: 3 },
    { name: 'A cada 4 semanas', intervalBase: 'weeks', occurrences: 4 },
    { name: 'A cada 5 semanas', intervalBase: 'weeks', occurrences: 5 },
    { name: 'A cada 6 semanas', intervalBase: 'weeks', occurrences: 6 },
    { name: 'A cada 7 semanas', intervalBase: 'weeks', occurrences: 7 },
    { name: 'A cada 8 semanas', intervalBase: 'weeks', occurrences: 8 },
    { name: 'A cada 9 semanas', intervalBase: 'weeks', occurrences: 9 },
    { name: 'A cada 10 semanas', intervalBase: 'weeks', occurrences: 10 },
    { name: 'Mensalmente', intervalBase: 'months', occurrences: 1 },
    { name: 'A cada 2 meses', intervalBase: 'months', occurrences: 2 },
    { name: 'A cada 3 meses', intervalBase: 'months', occurrences: 3 },
    { name: 'A cada 4 meses', intervalBase: 'months', occurrences: 4 },
    { name: 'A cada 5 meses', intervalBase: 'months', occurrences: 5 },
    { name: 'A cada 6 meses', intervalBase: 'months', occurrences: 6 },
    { name: 'A cada 7 meses', intervalBase: 'months', occurrences: 7 },
    { name: 'A cada 8 meses', intervalBase: 'months', occurrences: 8 },
    { name: 'A cada 9 meses', intervalBase: 'months', occurrences: 9 },
    { name: 'A cada 10 meses', intervalBase: 'months', occurrences: 10 },
    { name: 'A cada 11 meses', intervalBase: 'months', occurrences: 11 },
  ],
  finish: [
    {
      name: 'Depois de 2 vezes',
      intervalMultiplier: 2,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 3 vezes',
      intervalMultiplier: 3,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 4 vezes',
      intervalMultiplier: 4,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 5 vezes',
      intervalMultiplier: 5,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 6 vezes',
      intervalMultiplier: 6,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 7 vezes',
      intervalMultiplier: 7,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 8 vezes',
      intervalMultiplier: 8,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 9 vezes',
      intervalMultiplier: 9,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 10 vezes',
      intervalMultiplier: 10,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 11 vezes',
      intervalMultiplier: 11,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 12 vezes',
      intervalMultiplier: 12,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 13 vezes',
      intervalMultiplier: 13,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 14 vezes',
      intervalMultiplier: 14,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 15 vezes',
      intervalMultiplier: 15,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 20 vezes',
      intervalMultiplier: 20,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 25 vezes',
      intervalMultiplier: 25,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Depois de 30 vezes',
      intervalMultiplier: 30,
      byOccurrences: 'by_occurrences',
    },
    {
      name: 'Data específica',
      intervalMultiplier: null,
      byOccurrences: 'by_date',
    },
  ],
}

function RepeatAppointment({ setPayload, payload }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [byOccurrences, setByOccurrences] = React.useState('by_occurrences')
  const [intervalBase, setIntervalBase] = React.useState(null)
  const [occurrences, setOccurrences] = React.useState(null)
  const [intervalMultiplier, setIntervalMultiplier] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState(new Date())

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIntervalBase(null)
  }

  const handleCancel = () => {
    setPayload('')
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const postRepeat = () => {
    if (intervalBase) {
      const repeatPayload = {
        recurring: {
          endType: byOccurrences,
          endsAt:
            byOccurrences === 'by_date'
              ? moment(selectedDate).format('YYYY-MM-DD')
              : null,
          intervalBase,
          intervalMultiplier: occurrences,
          occurrences: intervalMultiplier,
        },
      }
      setPayload(repeatPayload)
      handleClose()
    } else setPayload('')
  }

  return (
    <>
      {payload ? (
        <div
          id="repeatButton"
          className={classes.repeatButton}
          onClick={() => handleCancel()}
        >
          <CancelIcon /> <span>Repetição adicionada</span>
        </div>
      ) : (
        <div
          id="repeatButton"
          className={classes.repeatButton}
          onClick={handleClick}
        >
          <RepeatIcon /> <span>Repetir</span>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backdropFilter: 'blur(3px)' },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p
              style={{ color: '#666666', fontSize: '16px', fontWeight: 'bold' }}
            >
              Repetidor de serviço agendado
            </p>
            <Divider style={{ marginBottom: '15px' }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  select
                  fullWidth
                  label="Frequência"
                  defaultValue={frequency?.frequency[0].name}
                  variant="outlined"
                >
                  {frequency?.frequency.map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.name}
                      onClick={() => {
                        setOccurrences(item.occurrences)
                        setIntervalBase(item.intervalBase)
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {intervalBase && (
                <>
                  <Grid item xs={byOccurrences === 'by_date' ? 6 : 12}>
                    <TextField
                      id="termina"
                      select
                      fullWidth
                      label="Termina"
                      defaultValue={frequency?.finish[0].name}
                      variant="outlined"
                    >
                      {frequency?.finish.map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          onClick={() => {
                            setByOccurrences(item.byOccurrences)
                            setIntervalMultiplier(item.intervalMultiplier)
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {byOccurrences === 'by_date' && (
                    <Grid item xs={6} style={{ marginTop: '-10px' }}>
                      <MuiPickersUtilsProvider
                        locale={ptBR}
                        utils={DateFnsUtils}
                        style={{ margin: '5px 0px', padding: '0' }}
                      >
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="Data"
                          format="yyyy-MM-dd"
                          value={selectedDate}
                          fullWidth
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <Button
                  className={classes.buttonOutlined}
                  aria-describedby={id}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleClose()}
                >
                  Fechar
                </Button>
                <Button
                  className={classes.button}
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={() => postRepeat()}
                >
                  Aplicar alterações
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default RepeatAppointment
