import { InputText } from "../styles";

export function InputTypeText({
  name,
  onChange: onChangeTrigger = () => null,
  ...rest
}) {
  return (
    <InputText
      {...rest}
      onChange={(e) => onChangeTrigger(e.target.value)}
      placeholder=" "
    />
  );
}
