import { useCallback } from "react";

import { VeterinaryRequestController } from "../../../../../../../services/controllers/VeterinaryCare";

import { Input } from "../../../../../../../components/ui/Form/Input";
import { TextArea } from "../../../../../../../components/ui/Form/TextArea";
import { useContext } from "./Context";

export function Field({ fields }) {
  const CLVetRequest = VeterinaryRequestController();

  const { fieldsValues } = useContext();

  const changeValue = useCallback((field, value) => {
    const newValue = {};
    newValue[field] = value;
    CLVetRequest.setRequestFieldsValue(newValue);
  }, []);

  if (!fields) return null;

  return Object.keys(fields).map((key) => {
    switch (fields[key].type) {
      case "text":
        return (
          <Input.Root
            label={fields[key].label}
            value={fieldsValues[key] || ""}
            {...fields[key].props}
            onChange={(value) => changeValue(key, value)}
          />
        );
      case "textarea":
        return (
          <TextArea.Root
            label={fields[key].label}
            value={fieldsValues[key] || ""}
            {...fields[key].props}

            onChange={(value) => changeValue(key, value)}
          />
        );
      default:
        return null;
    }
  });
}
