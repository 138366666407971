import { Content as Container } from "./styles";
import { useTabs } from "./useTabs";

export function TabsContent({ children, name, tabName, ...rest }) {
  const allTabsList = useTabs((st) => st.data.tabs);
  const tabsList = allTabsList[name];
  const tabData = tabsList.find((tab) => tab.title === tabName);

  return (
    <Container {...rest} className={tabData.active ? "active" : ""}>
      {children}
    </Container>
  );
}
