import { toast } from 'react-toastify'
import { useSocket } from '../../store/global/useSocket'
import { promiseDelay } from '../functions'

const socketActions = useSocket.getState().actions

export default class SocketBuilder {
  _timer = null
  _errorStatus = 0

  constructor(props) {
    this._protocol = props.protocol || 'wss'
    this._host = props.host || 'localhost'
    this._port = props.port || null


    this._adds = `${this._protocol}://${this._host}`
    if (this._port) this._adds += `:${this._port}`
  }

  connect() {
    this._socket = new WebSocket(this._adds)
    this._socket.onopen = () => {
      socketActions.changeStatus('open')

      if (this._errorStatus !== 0) return
      this._errorStatus = 1
      toast.success("Assistente de impressão conectado!", {
        autoClose: 3000,
      })
    };

    this._socket.onmessage = (event) => {
      socketActions.setMessage({ data: event.data })
      socketActions.setError(null)
    };

    this._socket.onerror = (error) => {
      socketActions.setMessage(null)
      socketActions.setError(error)

      if (this._errorStatus !== 1) return
      this._errorStatus = 2
      toast.error("Assistente de impressão desconectado!", {
        autoClose: 3000,
      })
    };

    this._socket.onclose = () => {
      socketActions.changeStatus('close')
      this.reconnect()
    };

  }

  reconnect() {
    clearTimeout(this._timer)

    const status = useSocket.getState().data.status
    if (status === 'open') return

    this._timer = setTimeout(() => {
      this.connect()
    }, 5000)
  }


  async send(data) {
    this._socket.send(JSON.stringify(data))
    await promiseDelay(1000);
  }
}
