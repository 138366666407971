import { useHistory } from "../../../../../../store/service/veterinary/useHistory";
import { InformationBlock as Container, HtmlContent, Line, Title } from "../styles";

export function Diagnosis() {
  const selectHistoryProcedure = useHistory((st) => st.data.selectHistoryProcedure);
  const procedureData = useHistory((st) => st.data.historyProcedureData);

  const selectedProcedure = procedureData[selectHistoryProcedure]
  const procedureRecord = selectedProcedure?.record

  return (
    <Container>
      <Title>Diagnóstico e Tratamento</Title>
      <Line>
        <HtmlContent dangerouslySetInnerHTML={{ __html: procedureRecord?.diagnosis || '-' }} />
      </Line>
    </Container>
  );
}
