import styled, { css } from 'styled-components'
import {
  Root,
  Trigger as RadixTrigger,
  Portal as RadixPortal,
  Overlay as RadixOverlay,
  Content as RadixContent,
  Title as RadixTitle,
  Description as RadixDescription,
  Cancel as RadixCancel,
  Action as RadixAction,
} from '@radix-ui/react-alert-dialog'
import { darken } from 'polished'

export const Container = styled(Root)``
export const Trigger = styled(RadixTrigger)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Portal = styled(RadixPortal)``

export const Overlay = styled(RadixOverlay)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  position: fixed;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.5);
`

export const Content = styled(RadixContent)`
  background: ${(props) => props.theme.colors.background};
  padding: 30px 20px;
  border-radius: 5px;
  position: absolute;
  z-index: 101;
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
`

export const Title = styled(RadixTitle)`
  margin: 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const Description = styled(RadixDescription)`
  margin: 10px 0;
  padding: 20px 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray[400]};
`

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-top: 10px;
`

const button = css`
  color: ${(props) => props.theme.colors.background};
  height: 40px;
  padding: 0 20px;
  display: flex;
  max-width: ${(props) => props.size || '100%'};
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background 150ms linear;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  &:active {
    transform: scale(0.97);
  }
  &:focus {
    position: relative;
  }
`
export const Cancel = styled(RadixCancel)`
  ${button}
  background: ${(props) => props.theme.colors.error};
  &:hover {
    background: ${(props) => darken(0.1, props.theme.colors.error)};
  }
`

export const Action = styled(RadixAction)`
  ${button}
  background: ${(props) => props.theme.colors.scale[400]};
  &:hover {
    background: ${(props) => darken(0.1, props.theme.colors.scale[500])};
  }
`
