
import { twMerge } from 'tailwind-merge'

export function CardContentListItem({
  className,
  ...rest
}) {
  return (
    <li
      className={twMerge('flex items-end gap-1 text-black w-full', className)}
      {...rest}
    />
  )
}
